import React, { Component } from "react";
import axios from "axios";
import { AxiosApplicationInterceptor } from "../../common/axios_interceptor";
import Spinner from "../../common/Spinner";
import Alert from "../../common/components/alert/Alert";
import lockIcon from "images/lock.png";
import creditCardIcon from "images/Icons/CreditCard.png";
import MPBrand from "images/brand/MP.png";
import { constants, OptionsMercadoPagoIdType } from "../../common/constants";
import SelectWithRadio from "../../common/components/select/SelectWithRadio";
axios.interceptors.response.use(undefined, AxiosApplicationInterceptor);

export default class PaymentData extends Component {
  state = {
    data: {},
    id_number: this.props.user.id_number || "",
    idType: OptionsMercadoPagoIdType.find((x) => x.value === constants.RUT),
    isSubmitting: false,
    alert: {
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined,
    },
    payment: {
      oneclick: {
        register_init: false,
        register_finish: false,
        tbk_user: false,
        token: "",
        url: "",
      },
    },
  };

  modalRemoveCard = null;
  modalRemoveCardOC = null;

  onFormMounted = (error) => {
    this.setState(
      { isSubmitting: false },
      () => {
        if (error) {
          console.warn("Form mounted handling error: ", error);
          return this.showMessage("primary", "Error", [error.message]);
        }
        console.log("Form mounted");
      },
      () => {
        selectTags = document.getElementById("form-checkout__installments");
        if (selectTags != null) {
          selectTags.selectedIndex = 0;
        }
      }
    );
  };

  showMessage = (messageType, messageTitle, messages) =>
    this.setState(
      (state) => {
        state.alert.showMessage = true;
        state.alert.messageTitle = messageTitle;
        state.alert.messages = messages;
        state.alert.messageType = messageType;
        return { alert: state.alert };
      },
      () => setTimeout(this.handleAlertClose, 3000)
    );

  handleAlertClose = () =>
    this.setState((state) => {
      state.alert.showMessage = false;
      return { alert: state.alert };
    });

  onFormSubmit = (e, cardForm) => {
    this.setState({ isSubmitting: true });
    e.preventDefault();
    const csrftoken = document.querySelector("[name=csrf-token]");
    if (csrftoken) {
      axios.defaults.headers.common["X-CSRF-TOKEN"] = csrftoken.content;
    }
    let {
      paymentMethodId: payment_method_id,
      issuerId: issuer_id,
      cardholderEmail: email,
      amount,
      token,
      installments,
      identificationNumber,
      identificationType,
    } = cardForm.getCardFormData();

    if (payment_method_id == "debvisa") {
      this.showMessage("primary", "Alerta", [
        "No es posible registrar una tarjeta VISA débito",
      ]);
      this.setState({ isSubmitting: false });
      return;
    }

    const formatedID = identificationNumber.replace(/\.|\-/g, "");

    axios({
      method: "POST",
      url: `/owners/${this.props.user.id}/create_mercadopago`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        token,
        issuer_id,
        payment_method_id,
        transaction_amount: Number(amount),
        installments: Number(installments),
        description: "Arriendo Appcar",
        payer: {
          email,
          identification: {
            type: identificationType,
            number: formatedID,
          },
        },
      }),
    })
      .then((response) => {
        if (!response.data.success) {
          this.showMessage("primary", "Error", [
            response.data.message || "Ha ocurrido un error inesperado",
          ]);
        } else {
          window.location.reload();
        }
      })
      .catch((e) => {
        console.log(e);
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ]);
      })
      .finally(() => this.setState({ isSubmitting: false }));
  };

  onCardTokenReceived = (error, token) => {
    if (error) {
      console.warn("Token handling error: ", error);
      let err = undefined;
      switch (typeof error) {
        case "string":
          err = error;
          break;
        case "object":
          if (Array.isArray(error))
            err = error[0].code + " - " + error[0].message;
          else err = error.code + " - " + error.message;
          break;
      }
      return this.showMessage("primary", "Error", [err]);
    }
    console.log("Token available");
  };

  onFetching = (resource) => {
    console.log("Fetching resource: ", resource);
  };

  componentDidMount() {
    this.setState({ isSubmitting: false });
    this.modalRemoveCard = new bootstrap.Modal(
      document.getElementById("modalRemoveCard")
    );
    this.modalRemoveCardOC = new bootstrap.Modal(
      document.getElementById("modalRemoveCardOC")
    );

    // TBK2MP Retomar MP
    if (
      !this.props.user.mercadopago ||
      this.props.user.mercadopago.cards.length == 0
    ) {
      this.setState({ isSubmitting: true });
      const mp = new MercadoPago(this.props.mpPublicKey, {
        locale: "es-CL",
      });
      const cardForm = mp.cardForm({
        amount: "100",
        autoMount: true,
        form: {
          id: "form-checkout",
          cardholderName: {
            id: "form-checkout__cardholderName",
            placeholder: "Titular de la tarjeta",
          },
          cardholderEmail: {
            id: "form-checkout__cardholderEmail",
            placeholder: "E-mail",
          },
          cardNumber: {
            id: "form-checkout__cardNumber",
            placeholder: "Número de la tarjeta",
          },
          cardExpirationMonth: {
            id: "form-checkout__cardExpirationMonth",
            placeholder: "MM",
          },
          cardExpirationYear: {
            id: "form-checkout__cardExpirationYear",
            placeholder: "YY",
          },
          securityCode: {
            id: "form-checkout__securityCode",
            placeholder: "Código de seguridad",
          },
          installments: {
            id: "form-checkout__installments",
            placeholder: "Cuotas",
          },
          identificationType: {
            id: "form-checkout__identificationType",
            placeholder: "Tipo de documento",
          },
          identificationNumber: {
            id: "form-checkout__identificationNumber",
            placeholder: "Número de documento",
          },
          issuer: {
            id: "form-checkout__issuer",
            placeholder: "Banco emisor de tarjeta",
          },
        },
        callbacks: {
          onFormMounted: this.onFormMounted,
          onSubmit: (event) => this.onFormSubmit(event, cardForm),
          onCardTokenReceived: this.onCardTokenReceived,
          onFetching: this.onFetching,
          onPaymentMethodsReceived: (event) => { console.log(event)}
        },
      });
    }
  }

  maskCardInput = (value) => {
    if (value && value.length > 3) {
      const regex = /\d{4}/g;
      const res = value
        .replace(/ /g, "")
        .replace(regex, (maths) => (maths === 12 ? maths : maths + " "))
        .trim();
      let input = document.getElementById("form-checkout__cardNumber");
      input.value = res;
    }
  };

  oneclickRegisterInit = () => {
    this.setState({ isSubmitting: true });
    axios({
      method: "POST",
      url: `/owners/${this.props.user.id}/oneclick_register_init.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: {},
    })
      .then((response) => {
        if (response.data.success) {
          let oneclick = { ...this.state.payment.oneclick };
          oneclick.register_init = true;
          oneclick.token = response.data.tbk_response.token;
          oneclick.url = response.data.tbk_response.url;
          this.setState({ payment: { oneclick: oneclick } });
        } else {
          this.showMessage(
            "primary",
            "Alerta",
            response.data.messages || ["Ha ocurrido un error inesperado"]
          );
        }
      })
      .catch((error) => {
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ]);
      })
      .finally(() => this.setState({ isSubmitting: false }));
  };

  render() {
    const { id_number, idType } = this.state;
    const {
      messageTitle,
      messageType,
      messages,
      showMessage,
    } = this.state.alert;

    const user = this.props.user;

    const modalRemoveMP = (
      <div
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="confirm-titlemp"
        aria-hidden="true"
        id="modalRemoveCard"
      >
        <div className="modal-dialog modal-dialog-centered modal-appcar modal-confirmation">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-m-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                x
              </button>
              <h5 className="modal-title" id="confirm-titlemp">
                Confirmar eliminación
              </h5>
            </div>
            <div className="modal-body">
              <p
                className="fw-700 text-center fs-15"
                style={{ maxWidth: 280, margin: "24px auto" }}
              >
                ¿Estás seguro? Esta acción no se puede deshacer
              </p>
              <div className="row justify-content-center">
                <div className="col-auto">
                  <button
                    type="button"
                    className="btn modal-btn-cancel"
                    data-dismiss="modal"
                    data-bs-dismiss="modal"
                  >
                    No
                  </button>
                  <a
                    href={`/owners/${user.id}/remove_mercadopago`}
                    className="btn modal-btn-confirm"
                    onClick={() => this.setState({ isSubmitting: true })}
                    data-turbolinks="false"
                  >
                    Si
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    const modalRemoveOC = (
      <div
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="confirm-titleoc"
        aria-hidden="true"
        id="modalRemoveCardOC"
      >
        <div className="modal-dialog modal-dialog-centered modal-appcar modal-confirmation">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-m-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                x
              </button>
              <h5 className="modal-title" id="confirm-titleoc">
                Confirmar eliminación
              </h5>
            </div>
            <div className="modal-body">
              <p
                className="fw-700 text-center fs-15"
                style={{ maxWidth: 280, margin: "24px auto" }}
              >
                ¿Estás seguro? Esta acción no se puede deshacer
              </p>
              <div className="row justify-content-center">
                <div className="col-auto">
                  <button
                    type="button"
                    className="btn modal-btn-cancel"
                    data-dismiss="modal"
                    data-bs-dismiss="modal"
                  >
                    No
                  </button>
                  <a
                    href={`/owners/${user.id}/oneclick_remove`}
                    className="btn modal-btn-confirm"
                    onClick={() => this.setState({ isSubmitting: true })}
                    data-turbolinks="false"
                  >
                    Si
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    let oneClickButton = (
      <button
        className="btn btn-appcar-primary w-100"
        type="button"
        onClick={() => this.oneclickRegisterInit()}
      >
        Registrar medio de pago en Transbank
      </button>
    );

    if (
      this.state.payment.oneclick.register_init &&
      !this.state.payment.oneclick.tbk_user
    ) {
      oneClickButton = (
        <form action={this.state.payment.oneclick.url} method="post">
          <input
            type="hidden"
            name="TBK_TOKEN"
            value={this.state.payment.oneclick.token}
          />
          <input
            type="submit"
            value="Continuar a Transbank >>"
            className="btn btn-appcar-primary w-100"
          />
        </form>
      );
    }

    const oneclickCardContainer =
      user.oneclick && user.oneclick.last_4_card_digits ? (
        <>
          <div className="credit-card-container my-3">
            <img src={creditCardIcon} className="cc-icon" />
            <div className="cc-description">
              <span className="d-block">
                OneClick ({user.oneclick.credit_card_type})
              </span>
              <span className="d-block fw-900">
                **** {user.oneclick.last_4_card_digits}
              </span>
            </div>
            <a
              href="#modalRemoveCardOC"
              data-bs-toggle="modal"
              className="remove-card"
            >
              <i className="mdi mdi-close-thick" />
            </a>
          </div>
        </>
      ) : // <>
      //   <h5>Transbank OneClick</h5>
      //   <div className="my-3">{oneClickButton}</div>
      // </>
      null;

    const cardFormContainer =
      !user.mercadopago || user.mercadopago.cards.length == 0 ? (
        <form id="form-checkout">
          <div className="form-group">
            <label htmlFor="form-checkout__cardNumber" className="label-inside">
              Número de tarjeta
            </label>
            <input
              required
              type="text"
              name="cardNumber"
              id="form-checkout__cardNumber"
              className="form-control appcar-input"
              pattern="[0-9 ]+"
              onChange={(e) => this.maskCardInput(e.target.value)}
              title="Sólo números y espacios"
              maxLength="19"
              tabIndex="1000000"
            />
          </div>
          <div className="expiration-date-input-group">
            <div className="form-group">
              <label
                htmlFor="form-checkout__cardExpirationMonth"
                className="label-inside"
              >
                Caducidad
              </label>
              <div className="expiration-date-input">
                <input
                  required
                  type="text"
                  name="cardExpirationMonth"
                  id="form-checkout__cardExpirationMonth"
                  maxLength="2"
                  tabIndex="1000001"
                  className="month"
                  onKeyPress={(e) => {
                    if (
                      isNaN(parseFloat(e.key.trim())) ||
                      !isFinite(e.key.trim())
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
                <span className="text-appcar-primary">/</span>
                <input
                  required
                  type="text"
                  name="cardExpirationYear"
                  id="form-checkout__cardExpirationYear"
                  maxLength="2"
                  tabIndex="1000002"
                  className="year"
                  onKeyPress={(e) => {
                    if (
                      isNaN(parseFloat(e.key.trim())) ||
                      !isFinite(e.key.trim())
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <label
                htmlFor="form-checkout__securityCode"
                className="label-inside"
              >
                CVV
              </label>
              <input
                required
                type="text"
                name="securityCode"
                id="form-checkout__securityCode"
                className="form-control appcar-input ccv"
                maxLength="3"
                tabIndex="1000003"
              />
            </div>
          </div>
          <div className="form-group">
            <label
              htmlFor="form-checkout__cardholderName"
              className="label-inside"
            >
              Nombre en la tarjeta
            </label>
            <input
              required
              type="text"
              name="cardholderName"
              id="form-checkout__cardholderName"
              defaultValue={`${this.props.user.name} ${this.props.user.last_name}`}
              className="form-control appcar-input"
              tabIndex="1000004"
            />
          </div>
          <div className="form-group">
            <label
              htmlFor="form-checkout__cardholderEmail"
              className="label-inside"
            >
              E-mail
            </label>
            <input
              required
              type="email"
              name="cardholderEmail"
              id="form-checkout__cardholderEmail"
              defaultValue={this.props.user.email}
              className="form-control appcar-input"
              tabIndex="1000005"
            />
          </div>
          <div className="form-group"></div>
          <div className="form-group">
            <label htmlFor="form-checkout__issuer" className="label-inside">
              Tipo tarjeta
            </label>
            <select
              name="issuer"
              id="form-checkout__issuer"
              className="form-control appcar-input"
              tabIndex="1000006"
              aria-readonly={true}
              readOnly
              onClick={() => null}
            ></select>
          </div>
          <div className="form-group">
            <label
              htmlFor="form-checkout__identificationType"
              className="label-inside"
            >
              Tipo de documento
            </label>
            <SelectWithRadio
              value={idType}
              options={OptionsMercadoPagoIdType}
              placeholder="Tipo de documento"
              noOptionsMessage={() => "No hay tipos de documento"}
              onChange={(opt) => this.setState({ idType: opt })}
              classNamePrefix="RS-FIX"
              className="react-select-fix appcar-input"
              hideSelectedOptions={false}
              backspaceRemovesValue={false}
              isSearchable={false}
              isClearable={false}
              tabIndex="1000007"
            />
            <input
              type="hidden"
              name="identificationType"
              id="form-checkout__identificationType"
              value={idType?.value}
            />
          </div>
          <div className="form-group">
            <label className="label-inside">Número de{" "}{(idType?.value === constants.RUT ? "RUT" : "documento")}</label>
            <input
              required
              type="text"
              value={id_number}
              className="form-control appcar-input"
              onChange={(e) => this.setState({ id_number: e.target.value })}
              placeholder="Número de documento"
            />
            <input
              type="hidden"
              name="identificationNumber"
              id="form-checkout__identificationNumber"
              value={id_number.replace(/\./g, "").replace(/\-/g, "")}
            />
          </div>
          <div className="form-group d-none">
            <select
              name="installments"
              id="form-checkout__installments"
              className="form-control appcar-input"
              tabIndex="1000009"
            ></select>
          </div>
          <div className="text-center my-2">
            <button
              type="submit"
              className="btn btn-appcar-primary"
              id="form-checkout__submit"
            >
              Agregar tarjeta
            </button>
          </div>
        </form>
      ) : (
        <>
          <div className="credit-card-container">
            <img src={creditCardIcon} className="cc-icon" />
            <div className="cc-description">
              <span className="d-block">
                Mercado Pago ({user.mercadopago.cards[0].payment_method.name})
              </span>
              <span className="d-block fw-900">
                **** {user.mercadopago.cards[0].last_four_digits}
              </span>
            </div>
            <a
              href="#modalRemoveCard"
              data-bs-toggle="modal"
              className="remove-card"
            >
              <i className="mdi mdi-close-thick" />
            </a>
          </div>
        </>
      );
    return (
      <div className="edit-box">
        <Alert
          alertClass={messageType}
          isShowing={showMessage}
          messages={messages}
          title={messageTitle}
          onClose={this.handleAlertClose}
        />
        <Spinner loading={this.state.isSubmitting} />
        <div className="photo-container">
          <div className="image-loader">
            <h2>Mis medios de pago</h2>
            <img src={MPBrand} alt="Mercado Pago" width={195} />
          </div>
          <div className="photo-advice">
            <div className="icon">
              <img src={lockIcon} width={36} />
            </div>
            <div className="advice">
              Tu información de pago se guardará de forma segura
            </div>
          </div>
        </div>
        <div className="form">
          {oneclickCardContainer}
          {cardFormContainer}
        </div>
        {modalRemoveMP}
        {modalRemoveOC}
      </div>
    );
  }
}
