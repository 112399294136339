import React, { Component } from "react";
import { numeral } from "../../common/constants";
import carPriceIcon from "images/CarPrice.png";
import calendarIcon from "images/Calendar.png";

class IncomeCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 10000000,
      days: 20
    };
    this.sliderValueChange = this.sliderValueChange.bind(this);
    this.sliderDaysChange = this.sliderDaysChange.bind(this);
  }
  sliderValueChange(e) {
    this.setState({ value: e.target.value });
  }
  sliderDaysChange(e) {
    this.setState({ days: e.target.value });
  }
  render() {
    const { value, days } = this.state;
    const car_value = numeral(value);
    const total = ((value * .085) / 30) * days;
    return (
      <div className="income-calculator">
        <p className="title">Tú pones el precio</p>
        <div className="calculator-content">
          <div className="item">
            <p className="desc">
              <img src={carPriceIcon} />
              VALOR
              <br />
              DEL
              <br />
              AUTO
            </p>
            <p className="value">{car_value.format("$0,0")}</p>
            <div className="form-group range">
              <input
                className="form-range"
                id="range-value"
                type="range"
                name="range"
                min="0"
                max="50000000"
                onChange={this.sliderValueChange}
                value={car_value.value()}
              />
            </div>
          </div>
          <div className="item calendar">
            <p className="desc">
              <img src={calendarIcon} />
              DíAS DE
              <br />
              ARRIENDO
              <br />
              AL MES
            </p>
            <p className="value">{this.state.days}</p>
            <div className="form-group range">
              <input
                className="form-range"
                id="range-days"
                type="range"
                name="range"
                min="0"
                max="30"
                onChange={this.sliderDaysChange}
                value={this.state.days}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center income-value">
            {numeral(total * 12).format("$0,0")}
          </div>
          <div className="col-12 text-center income-desc">
            INGRESO ESTIMADO ANUAL
          </div>
        </div>
      </div>
    );
  }
}

export default IncomeCalculator;
