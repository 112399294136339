import React, { Component } from "react";
import { BookingStatus } from "../../../common/constants";
import AdminQualification from "../../../common/qualification/AdminQualification";

class UserInfo extends Component {
  rate = () => {
    $("#modal_admin_eval").modal("show");
  };

  hideModal = () => {
    $("#modal_admin_eval").modal("hide");
    if (this.props.callbackRate) {
      this.props.callbackRate();
    }
  };

  render() {
    const { owner, booking, isBooking } = this.props;
    const photo = owner.photo ? owner.photo + "/thumb" : "/user-icon.png";
    // const photo = owner.photo ? owner.photo : "/user-icon.png";
    const image_style = {
      backgroundImage: `url("${photo}")`
    };
    const modal = isBooking && booking.status.id == BookingStatus.COMPLETADO && (booking.review_status === null || !booking.review_status.owner) ? (
      <div
        id="modal_admin_eval"
        className="modal fade"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body p-4">
              <AdminQualification
                adminId={owner.id}
                callback={this.hideModal}
                bookingId={booking.id}
              />
            </div>
          </div>
        </div>
      </div>
    ) : null;
    const is_certified = owner.super_user ? (
      <div className="certified-user-badge">
        <i className="mdi mdi-certificate" />
      </div>
    ) : null;
    const user_name = owner.name + " " + owner.last_name.charAt(0) + ".";
    const nickname = owner.nickname;
    let user_rating = [];
    for (let i = 1; i <= 5; i++) {
      const r_icon =
        i <= owner.admin_rating ? (
          <i className="mdi mdi-star" key={i} />
        ) : (
            <i className="mdi mdi-star-outline" key={i} />
          );
      user_rating.push(r_icon);
    }
    const evaluations = (
      <div
        className="vehicle-stats-badge-ratings"
        key="badge"
        style={{ width: "fit-content" }}
      >
        {owner.admin_evaluations || 0}{" "}
        {owner.admin_evaluations == 1 ? "evaluación" : "evaluaciones"}
      </div>
    );
    user_rating.push(evaluations);
    const badgeRate = isBooking && booking.status.id == BookingStatus.COMPLETADO && (booking.review_status === null || !booking.review_status.owner) ? (
      <div
        className="vehicle-stats-badge-ratings mt-1"
        key="rate"
        onClick={this.rate}
        style={{
          width: "fit-content",
          backgroundColor: "#203453",
          color: "#fff",
          cursor: "pointer"
        }}
      >
        Evaluar
      </div>
    ) : null;
    return (
      <div className="vehicle-info-user-info">
        <div className="vehicle-info-user-image" style={image_style}>
          {is_certified}
        </div>
        <div className="vehicle-info-user-data">
          <p className="user-name">{nickname}</p>
          <div className="user-rating">{user_rating}</div>
          {badgeRate}
        </div>
        {modal}
      </div>
    );
  }
}

export default UserInfo;
