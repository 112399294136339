import React, { Component, Fragment } from "react";
import Spinner from "../../../common/Spinner";
import axios from "axios";
import { DatePicker } from "../../../common/CustomInputs";
import moment from "moment";
import Alert from "../../../common/components/alert/Alert";
import { AxiosBackofficeInterceptor } from "../../../common/axios_interceptor";
import SelectWithRadio from "../../../common/components/select/SelectWithRadio";
import { BookingStatus, constants } from "../../../common/constants";
import Table from "../../../common/components/table/Table";
import debounce from "lodash/debounce";
moment.locale("es");

class BookingChecklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      booking: {},
      form_data: {
        from: null,
        until: null,
        status: undefined,
      },
      search_filter: "",
      bookings: this.props.bookings,
      isSubmitting: false,
      alert: {
        showMessage: false,
        messageType: undefined,
        messages: [],
        messageTitle: undefined,
      },
      search_info: {
        has_searched: false,
        from: "",
        until: "",
        status: "",
      },
      new_status: undefined,
      orderStatus: {
        name: "id",
        direction: "desc",
      },
      paginationInformation: this.props.paginationInformation || {
        limit: constants.DEFAULT_RESULTS_PER_PAGE,
        last: 0,
        total: this.props.bookings.length,
      },
      currentPage: 1,
      lastIds: [this.props.paginationInformation.last],
    };
    this.willUnmount = false;
    this.cancelToken = null;
    this.processRunning = 0;
    this.handleSubmit = debounce(this.handleSubmit, 400, { leading: true });
  }

  componentWillUnmount() {
    this.willUnmount = true;
  }

  componentDidMount() {
    const this_ = this;
    this_.willUnmount = false;
    this.cancelToken = axios.CancelToken.source();
    axios.interceptors.response.use(undefined, AxiosBackofficeInterceptor);
    const options = {
      showDropdowns: true,
      minYear: 2018,
      maxYear: constants.MAX_YEAR,
      autoUpdateInput: false,
      locale: {
        format: "DD/MM/YYYY",
        cancelLabel: "Limpiar",
        applyLabel: "Aplicar",
        fromLabel: "Desde",
        toLabel: "Hasta",
        daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        firstDay: 1,
      },
    };

    const datePickerId =
      "#search_dates" + (this.props.objectType === "driver" ? "_driver" : "");

    $(datePickerId).on("cancel.daterangepicker", function(ev, picker) {
      picker.setStartDate(moment());
      picker.setEndDate(moment());
      this_.setState((state) => {
        state.form_data.from = null;
        state.form_data.until = null;
        return { form_data: state.form_data };
      });
    });

    $(datePickerId).daterangepicker(options, (start, end) => {
      let from = moment(start)
        .startOf("day")
        .format();
      let to = moment(end)
        .endOf("day")
        .format();
      this.setState((state) => {
        state.alert.showMessage = false;
        state.form_data.from = from;
        state.form_data.until = to;
        return { form_data: state.form_data, alert: state.alert };
      });
    });
  }

  showMessage = (messageType, messageTitle, messages) =>
    this.setState(
      (state) => {
        state.alert.showMessage = true;
        state.alert.messageTitle = messageTitle;
        state.alert.messages = messages;
        state.alert.messageType = messageType;
        return { alert: state.alert };
      },
      () => setTimeout(this.handleAlertClose, 3000)
    );

  handleAlertClose = () =>
    this.setState((state) => {
      state.alert.showMessage = false;
      return { alert: state.alert };
    });

  handleSubmit = (e = null, direction = "after", resetPagination = false) => {
    if (e) e.preventDefault();
    this.processRunning += 1;
    this.setState({ isSubmitting: true });
    const { form_data, paginationInformation, search_filter } = this.state;
    let search_info = {
      has_searched: true,
      status: form_data.status,
    };
    const from = moment(form_data.from);
    let fromParam = "";
    let untilParam = "";
    let last = paginationInformation.last;
    let lastIds = this.state.lastIds;
    if (direction == "before") {
      lastIds.pop();
      lastIds.pop();
      last = lastIds.slice(this.state.lastIds.length - 1);
    }
    if (resetPagination) {
      lastIds = [];
      last = 0;
    }
    let paginationParams = `limit=${paginationInformation.limit}`;
    const { objectType, objectId } = this.props;
    let objectIdParams = "";
    if (objectType && objectId) {
      objectIdParams = `&${objectType}_id=${objectId}`;
    }

    if (!resetPagination) paginationParams += `&last=${last}`;
    if (from.isValid()) {
      search_info.from = from.format("DD/MM/YYYY");
      fromParam = `&from=${encodeURIComponent(
        from.startOf("d").format("YYYY-MM-DDTHH:mm:ssZ")
      )}`;
    }
    const until = moment(form_data.until);
    if (until.isValid()) {
      search_info.until = until.format("DD/MM/YYYY");
      untilParam = `&until=${encodeURIComponent(
        until.endOf("d").format("YYYY-MM-DDTHH:mm:ssZ")
      )}`;
    }
    const statusParam =
      form_data.status || form_data.status === BookingStatus.PENDIENTE
        ? `&status=${form_data.status}`
        : "";
    const filterParam = search_filter ? `&filter=${search_filter}` : "";

    axios({
      method: "get",
      url: `/backoffice/bookings.json?${paginationParams}${fromParam}${untilParam}${statusParam}${filterParam}${objectIdParams}`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        const bookings = response.data.bookings;
        const paginationInformation = response.data.paginationInformation;
        lastIds.push(paginationInformation.last);
        if (!this.willUnmount)
          this.setState({
            bookings,
            search_info,
            paginationInformation,
            lastIds,
          });
      })
      .catch((error) =>
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ])
      )
      .then(() => {
        this.processRunning -= 1;
        if (this.processRunning == 0) {
          this.setState({ isSubmitting: false });
        }
      });
  };

  handleChangeStatus = (option) => {
    const new_status = option ? option.value : "";
    this.setState(
      (state) => {
        state.form_data.status = new_status;
        return { form_data: state.form_data, currentPage: 1 };
      },
      () => this.handleSubmit(null, "last", true)
    );
  };

  onSearchChange = (search_filter) => {
    this.setState({ search_filter, currentPage: 1 }, () => {
      this.handleSubmit(null, "last", true);
    });
  };

  onLimitChange = (limit) => {
    this.setState(
      (state) => {
        state.paginationInformation.limit = limit;
        return {
          paginationInformation: state.paginationInformation,
          currentPage: 1,
        };
      },
      () => {
        this.handleSubmit(null, "last", true);
      }
    );
  };

  onPageChange = (currentPage, direction) => {
    this.setState({ currentPage }, () => {
      this.handleSubmit(null, direction);
    });
  };

  onOrderChange = (orderStatus) => {
    this.setState({ orderStatus }, () => {
      // TODO: Acá se debe llamar la función para buscar resultados
    });
  };

  render() {
    const {
      bookings,
      form_data,
      search_info,
      orderStatus,
      paginationInformation,
      currentPage,
      alert,
    } = this.state;
    const { messageTitle, messageType, messages, showMessage } = alert;
    const bookingStatuses = this.props.bookingStatuses;

    const rows = bookings.sort((a, b) => {
      // Ordenamiento por id
      const aId = parseInt(a.id);
      const bId = parseInt(b.id);

      // Mayor a menor (Más futuro a más pasado)
      return bId - aId;
    });

    const { last, limit, total } = paginationInformation;

    const selected_status = bookingStatuses.find(
      (status) => status.id === this.state.form_data.status
    );
    const selected_option_status = selected_status
      ? {
          value: selected_status.id,
          label: selected_status.description,
        }
      : undefined;
    const status_options = bookingStatuses.map((status) => {
      return {
        value: status.id,
        label: status.description,
      };
    });

    const showingText = `Mostrando ${rows.length} resultados de un total de ${total}`;
    let statusText = "";
    let datesText = "";
    if (search_info.status || search_info.status === BookingStatus.PENDIENTE) {
      statusText = `, con estado ${
        bookingStatuses.find((x) => x.id === search_info.status).description
      }`;
    }
    if (search_info.from) {
      datesText = `, entre fechas ${search_info.from} y ${search_info.until}`;
    }

    const searchInfo = (
      <div className="alert light mw-100">
        {showingText}
        {statusText}
        {datesText}
      </div>
    );

    let inputDatesText = "";
    const from = moment(form_data.from);
    const until = moment(form_data.until);
    if (from.isValid()) {
      inputDatesText = from.format("LL") + " - " + until.format("LL");
    }

    const columnDefinitions = [
      {
        name: "id",
        label: "ID",
        orderable: false,
      },
      {
        name: "driver",
        label: "Driver",
        orderable: false,
      },
      {
        name: "owner",
        label: "Owner",
        orderable: false,
      },
      {
        name: "vehicle",
        label: "Vehículo",
        orderable: false,
      },
      {
        name: "plate_number",
        label: "Patente",
        orderable: false,
      },
      {
        name: "status",
        label: "Estado",
        orderable: false,
      },
      {
        name: "dates",
        label: "Duración de arriendo",
        orderable: false,
      },
      {
        name: "parent_id",
        label: "Reserva padre",
        orderable: false,
      },
      {
        name: "actions",
        label: "",
        orderable: false,
      },
    ];

    const data = rows.map((bkng) => {
      const driver = bkng.user[0];
      const vehicle = bkng.vehicle;
      const owner = vehicle.associated_users[0];
      return {
        id: JSON.stringify(bkng),
        driver: driver.name + " " + driver.last_name,
        owner: owner.name + " " + owner.last_name,
        vehicle:
          vehicle.vehicle_type.brand +
          " " +
          vehicle.vehicle_type.model +
          " " +
          vehicle.year,
        plate_number: vehicle.plate_number,
        status: bkng.status.description,
        dates:
          moment(bkng.date_from).format("DD/MM/Y HH:mm") +
          " - " +
          moment(bkng.date_until).format("DD/MM/Y HH:mm"),
        parent_id: bkng.base_booking_id || bkng.owner_base_booking_id || "-",
        actions: (
          <a
            href={`/backoffice/bookings/${bkng.id}`}
            className="btn btn-appcar-primary btn-sm"
            rel="noreferrer noopener"
            target="_blank"
          >
            Detalle
          </a>
        ),
      };
    });
    const table = (
      <Table
        id="table-guarantees"
        noResults={
          this.state.isSubmitting
            ? "Realizando Búsqueda"
            : "No hay resultados para mostrar..."
        }
        columns={columnDefinitions}
        data={data}
        hasFooter={true}
        tableClass="table dataTable table-striped"
        searchable={true}
        onSearchChange={this.onSearchChange}
        orderStatus={orderStatus}
        paginationInformation={paginationInformation}
        onLimitChange={this.onLimitChange}
        onPageChange={this.onPageChange}
        onOrderChange={this.onOrderChange}
        currentPage={currentPage}
        showPageSelector={false}
      />
    );
    const fromParam = from.isValid()
      ? `&from=${encodeURIComponent(
          from.startOf("d").format("YYYY-MM-DDTHH:mm:ssZ")
        )}`
      : "";
    const untilParam = until.isValid()
      ? `&until=${encodeURIComponent(
          until.endOf("d").format("YYYY-MM-DDTHH:mm:ssZ")
        )}`
      : "";
    const statusParam =
      form_data.status || form_data.status === BookingStatus.PENDIENTE
        ? `&status=${form_data.status}`
        : "";
    const datePickerId =
      "search_dates" + (this.props.objectType === "driver" ? "_driver" : "");
    return (
      <Fragment>
        <Spinner loading={this.state.isSubmitting} />
        <Alert
          alertClass={messageType}
          isShowing={showMessage}
          messages={messages}
          title={messageTitle}
          onClose={this.handleAlertClose}
        />
        <div className="row my-3 align-items-end">
          <div className="col-sm-12 col-md">
            <DatePicker
              id={datePickerId}
              label="Selecciona rango de fechas"
              onChange={(e) => this.changeFecha(e.target.value)}
              name={datePickerId}
              disabled={true}
              classNameInput="form-control appcar-input"
              value={inputDatesText}
            />
          </div>
          <div className="col">
            <div className="form-group">
              <label>Selecciona el estado</label>
              <SelectWithRadio
                value={selected_option_status}
                options={status_options}
                placeholder="Selecciona..."
                noOptionsMessage={() => "No existen estados aún..."}
                onChange={this.handleChangeStatus}
                classNamePrefix="RS-FIX"
                className="react-select-fix"
                isClearable={true}
                backspaceRemovesValue={false}
                isSearchable={false}
              />
            </div>
          </div>

          <div className="col-auto pl-md-0 pr-1">
            <button
              className="btn btn-appcar-primary btn-sm py-0 mb-1"
              onClick={() => this.handleSubmit(null, "last", true)}
            >
              <i className="mdi mdi-chevron-right fs-22" />
            </button>
          </div>
        </div>
        {
          // MA1-T403 Comentar botón "Exportar a excel" en tablas de backoffice
          /* <div className="row justify-content-end my-2">
          <div className="col-auto">
            <a
              href={`/backoffice/bookings/export_to_xlsx?${fromParam}${untilParam}${statusParam}`}
              className="btn btn-success"
            >
              <i className="mdi mdi-microsoft-excel"></i> Exportar a excel
            </a>
          </div>
        </div> */
        }
        {searchInfo}
        <div className="mb-3">{table}</div>
      </Fragment>
    );
  }
}

export default BookingChecklist;
