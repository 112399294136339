import React, { Component, Fragment } from "react";
import Spinner from "../../../common/Spinner";
import Axios from "axios";
import Search from "./Search";
import Alert from "../../../common/components/alert/Alert";
import { constants } from "../../../common/constants";
import List from "./List";
import moment from "moment";
import orderBy from "lodash/orderBy";
import mapValues from "lodash/mapValues";
import groupBy from "lodash/groupBy";
import omit from "lodash/omit";
import cloneDeep from "lodash/cloneDeep";
import Map from "../../../common/components/map/Map";
import ModalPricing from "../show/modals/ModalPricing";

class RentalsSearch extends Component {
  state = {
    rentals: this.props.rentals || [],
    mapRentals: [],
    data: {
      address: this.props.address || constants.RENTAL_SEARCH_DEFAULT_ADDRESS,
      lat: parseFloat(this.props.lat) || constants.RENTAL_SEARCH_LATITUDE,
      lon: parseFloat(this.props.lon) || constants.RENTAL_SEARCH_LONGITUDE,
      fromDate: moment(constants.RENTAL_SEARCH_FROM).format("DD/MM/YYYY"),
      fromTime: moment(constants.RENTAL_SEARCH_FROM).format("HH:mm"),
      untilDate: moment(constants.RENTAL_SEARCH_UNTIL).format("DD/MM/YYYY"),
      untilTime: moment(constants.RENTAL_SEARCH_UNTIL).format("HH:mm"),
      selectedExtras: undefined,
      selectedFeatures: undefined,
      selectedCategories: undefined,
      selectedTransmissions: undefined,
      selectedOrder: {
        value: 2,
        label: "Precio de menor a mayor",
      },
      selectedSeats: undefined,
      selectedKm: undefined, // TODO - JUAN: Recuerda q esta variable ahora corresponde al unlimited_distance
      minPrice: constants.MIN_PRICE,
      maxPrice: constants.MAX_PRICE,
      checkAirport: this.props.subfilter
        ? this.props.subfilter.toString().indexOf("aeropuerto") > -1
        : false,
    },
    paginationInfo: {
      last: this.props.last,
      oldCount: 0,
      newCount: (this.props.rentals || []).length,
    },
    newFrom: moment().format(""),
    newTo: moment().format(""),
    isSubmitting: false,
    isLoadingMoreRentals: false,
    highlightRentalId: undefined,
    rentalID: null,
    latLng: null,
    searchedLatLong: {
      lat: parseFloat(this.props.lat) || constants.RENTAL_SEARCH_LATITUDE,
      lng: parseFloat(this.props.lon) || constants.RENTAL_SEARCH_LONGITUDE,
    },
    shouldUpdateMap: false,
    radius: constants.RENTAL_SEARCH_RADIUS,
    firstSearch: true,
    alert: {
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined,
    },
    isMobile: window.innerWidth < 576,
    showMobileMap: false,
    pricing: {
      total: 0,
      details: [],
      extra_charges: [],
      commission: 0,
      insurance_charge: 0,
      payment_method_fee: 0,
      value_added_tax: 0,
      pick_up_charge: null,
      drop_off_charge: null,
      clf_value: 0.0,
      owner_total: 0.0,
      discount: 0.0,
    },
    searchType: constants.SEARCH_BY_BUTTON,
  };
  willUnmount = false;
  dataLayer = dataLayer;
  modalFilters = null;
  modalPricingDetails = null;

  componentWillUnmount() {
    this.willUnmount = true;
  }

  componentDidMount() {
    moment.locale("es");

    let from;
    let until;
    let fromDate = "";
    let fromTime = "";
    let untilDate = "";
    let untilTime = "";
    const propsDateFrom = !!this.props.dateFrom
      ? moment(this.props.dateFrom)
      : moment.invalid();
    const propsDateUntil = !!this.props.dateUntil
      ? moment(this.props.dateUntil)
      : moment.invalid();
    const storedDateFrom = moment(localStorage.getItem("user_date_from"));
    const storedDateUntil = moment(localStorage.getItem("user_date_until"));
    const defaultDateFrom = moment(constants.RENTAL_SEARCH_FROM);
    const defaultDateUntil = moment(constants.RENTAL_SEARCH_UNTIL);
    const now = moment().valueOf();

    if (propsDateFrom.isValid() && propsDateFrom.valueOf() > now) {
      from = propsDateFrom;
    } else if (
      storedDateFrom.isValid() &&
      storedDateFrom.valueOf() > defaultDateFrom.valueOf()
    ) {
      from = storedDateFrom;
    } else {
      from = defaultDateFrom;
    }

    if (propsDateUntil.isValid() && propsDateUntil.valueOf() > from.valueOf()) {
      until = propsDateUntil;
    } else if (
      storedDateUntil.isValid() &&
      storedDateUntil.valueOf() > defaultDateUntil.valueOf() &&
      storedDateUntil.valueOf() > from.valueOf()
    ) {
      until = storedDateUntil;
    } else {
      until = defaultDateUntil;
    }

    fromDate = from.format("DD/MM/YYYY");
    fromTime = from.format("HH:mm");
    untilDate = until.format("DD/MM/YYYY");
    untilTime = until.format("HH:mm");
    this.setState(
      (state) => {
        state.data.fromDate = fromDate;
        state.data.fromTime = fromTime;
        state.data.untilDate = untilDate;
        state.data.untilTime = untilTime;
        return { data: state.data };
      },
      () => {
        localStorage.setItem("user_date_from", from.format());
        localStorage.setItem("user_date_until", until.format());
        this.modalFilters = new bootstrap.Modal(
          document.getElementById("filtersModal")
        );
        this.modalPricingDetails = new bootstrap.Modal(
          document.getElementById("pricingDetailsModal")
        );
        this.handleSubmit({ searchType: constants.SEARCH_BY_BUTTON });
      }
    );
    window.addEventListener("resize", this.onResize, true);
    // document.addEventListener("wheel", this.mouseHandler, { passive: false });
    // document.addEventListener("DOMMouseScroll", this.mouseHandler, { passive: false });
  }

  onResize = () => {
    const isMobile = this.state.isMobile;
    if (isMobile && window.innerWidth >= 576)
      this.setState({ isMobile: false }, () => {
        document.body.classList.remove("overflow-hidden");
      });
    else if (!isMobile && window.innerWidth < 576)
      this.setState({ isMobile: true });
  };

  mouseHandler = (e) => {
    if (
      (e.target.previousSibling &&
        e.target.previousSibling.className == "gm-style-pbc") ||
      (e.target.parentElement &&
        e.target.parentElement.className == "map-price-marker") ||
      e.target.className == "gm-svpc" ||
      e.target.className == "gm-control-active" ||
      e.target.tagName == "AREA" ||
      e.target.className == "map-price-marker" ||
      e.target.className == "gm-control-active gm-fullscreen-control" ||
      e.target.src == "https://maps.gstatic.com/mapfiles/transparent.png"
    ) {
      e.preventDefault();
      let element = document.getElementById("vehicleResultsContainer");
      const uAgent = navigator.userAgent.toLowerCase();
      const scroll = uAgent.indexOf("firefox") > -1 ? e.deltaY * 33 : e.deltaY;
      element.scrollTop = element.scrollTop + scroll;
    }
  };

  enterCard = (id) => this.setState({ highlightRentalId: id });

  pinClick = () => this.setState({ highlightRentalId: undefined });

  goToLocation = (latLng, rentalID) => this.setState({ latLng, rentalID });

  reportChange = () =>
    this.setState({
      latLng: null,
      rentalID: null,
      // customZoom: false,
      shouldUpdateMap: false,
    });

  handleSubmit = ({
    latLng = null,
    submittingObj = "isSubmitting",
    loadingMore = false,
    searchType = constants.SEARCH_BY_BUTTON,
  }) => {
    const oldCount = loadingMore ? this.state.paginationInfo.newCount : 0;
    this.setState((state) => {
      state.paginationInfo.oldCount = oldCount;
      return { [submittingObj]: true, paginationInfo: state.paginationInfo };
    });

    const {
      selectedExtras,
      selectedFeatures,
      selectedCategories,
      selectedTransmissions,
      selectedSeats,
      selectedKm,
      selectedOrder,
      minPrice,
      maxPrice,
      checkAirport,
      fromDate,
      fromTime,
      untilDate,
      untilTime,
      lat,
      lon,
      // lastNWBorderBounds,
      // lastSEBorderBounds,
    } = this.state.data;

    const firstSearch = this.state.firstSearch;

    // Paginación
    const last = loadingMore ? this.state.paginationInfo.last : 0;

    let features = (selectedFeatures || []).map((x) => x.value);
    let URIunlimited = selectedKm && selectedKm.value ? "&unlimited_distance=" + selectedKm.value : "";
    let radius =
      searchType === constants.SEARCH_BY_BUTTON ? constants.RENTAL_SEARCH_RADIUS : this.state.radius;
    let order = selectedOrder && selectedOrder.value;
    let limit = 30;
    let URIorder = "";
    if (order == 1) {
      URIorder = "&sort_by=" + "distance" + "&asc=true";
    } else if (order == 2) {
      URIorder = "&sort_by=" + "price" + "&asc=true";
    } else if (order == 3) {
      URIorder = "&sort_by=" + "price" + "&asc=false";
    }

    const URIfrom =
      "from=" +
      encodeURIComponent(
        moment(fromDate + fromTime, "DD/MM/YYYYHH:mm").format(
          "YYYY-MM-DDTHH:mm:ssZ"
        )
      );
    const URIuntil =
      "&until=" +
      encodeURIComponent(
        moment(untilDate + untilTime, "DD/MM/YYYYHH:mm").format(
          "YYYY-MM-DDTHH:mm:ssZ"
        )
      );
    const URIsubfilter = checkAirport ? "&subfilter=aeropuerto" : "";
    const URIradius = "&radius=" + encodeURIComponent(radius);
    const URIlat = "&lat=" + encodeURIComponent(latLng ? latLng.lat : lat);
    const URIlon = "&lon=" + encodeURIComponent(latLng ? latLng.lng : lon);
    // let URIlat = "";
    // let URIlon = "";
    // let URIBbTopLeft = "";
    // let URIBbBottomRight = "";
    // if (searchType === constants.SEARCH_BY_BUTTON) {
    //   URIlat = "&lat=" + encodeURIComponent(lat);
    //   URIlon = "&lon=" + encodeURIComponent(lon);
    // } else if (searchType === constants.SEARCH_BY_MAP) {
    //   URIBbTopLeft = "&bb_top_left=" + encodeURIComponent(lastNWBorderBounds);
    //   URIBbBottomRight =
    //     "&bb_bottom_right=" + encodeURIComponent(lastSEBorderBounds);
    // }
    const URIminPrice =
      !!minPrice || minPrice != constants.MIN_PRICE
        ? "&price_min=" + encodeURIComponent(minPrice)
        : "";
    const URImaxPrice =
      maxPrice && maxPrice != constants.MAX_PRICE
        ? "&price_max=" + encodeURIComponent(maxPrice)
        : "";
    const URISeats =
      selectedSeats && selectedSeats.value
        ? "&capacity=" + encodeURIComponent(selectedSeats.value)
        : "";
    const URIextras =
      selectedExtras && selectedExtras.length > 0
        ? "&extras=" + encodeURIComponent(selectedExtras.map((x) => x.value))
        : "";
    const URIfeatures =
      features.length > 0 ? "&features=" + encodeURIComponent(features) : "";
    const URIcategories =
      selectedCategories && selectedCategories.length > 0
        ? "&vehicle_category=" +
          encodeURIComponent(selectedCategories.map((x) => x.value))
        : "";
    const URItransmissions =
      selectedTransmissions && selectedTransmissions.value
        ? "&vehicle_transmission=" +
          encodeURIComponent(selectedTransmissions.value)
        : "";

    // Paginación
    const URIpagination = `&last=${last}&limit=${limit}`;

    const encodedParams =
      URIfrom +
      URIuntil +
      URIsubfilter +
      URIradius +
      URIlat +
      URIlon +
      URIminPrice +
      URImaxPrice +
      URIextras +
      URIfeatures +
      URIcategories +
      URItransmissions +
      URISeats +
      URIorder +
      URIunlimited + 
      // Paginación && bb_top_left && bb_bottom_right
      // URIpagination +
      // URIBbTopLeft +
      // URIBbBottomRight;
      // Paginación
      URIpagination;
    const shouldUpdateMap = searchType === constants.SEARCH_BY_BUTTON;
    Axios({
      method: "get",
      url: `/appcar/rentals.json?${encodedParams}`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((resp) => {
        if (!this.willUnmount) {
          // Paginación
          const rentalsResp = resp.data.rentals;
          let rentals = this.state.rentals;
          if (loadingMore) {
            rentals.push(...rentalsResp);
          } else {
            rentals = rentalsResp;
          }
          const last = resp.data.last || this.state.paginationInfo.last;
          if (Array.isArray(rentals)) {
            rentals = rentals
              .filter(
                (r) =>
                  Array.isArray(r.current_location) &&
                  r.current_location.length > 0 &&
                  !!r.vehicle.vehicle_type
              )
              .map((r) => {
                r.location = r.current_location[0];
                return r;
              });
          }

          const mapRentals = this.disperseRentals(rentals);
          if (this.dataLayer != undefined) {
            if (Array.isArray(this.dataLayer) && firstSearch) {
              const impressions = rentals.map((x) => {
                const vehicle = x.vehicle;
                const name = vehicle.vehicle_type && vehicle.vehicle_type.brand;
                const id = vehicle.id;
                const model =
                  vehicle.vehicle_type && vehicle.vehicle_type.model;
                const year = vehicle.year;
                const brand = model + " | " + year;
                return {
                  name: name,
                  id: id,
                  brand: brand,
                };
              });
              this.dataLayer.push({
                event: "productImpression",
                ecommerce: {
                  currencyCode: "CLP", // Opcional
                  impressions: impressions,
                },
              });
            }
          }
          this.setState((state) => {
            state.paginationInfo.last = last;
            state.paginationInfo.newCount = rentals.length;
            return {
              rentals,
              mapRentals,
              paginationInfo: state.paginationInfo,
              shouldUpdateMap,
              firstSearch: false,
              searchType
            };
          });
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ]);
      })
      .finally(() => this.setState({ [submittingObj]: false }));
  };

  disperseRentals = (rentals) => {
    let grouped_rentals = rentals.flatMap((rental) => {
      let rentals_ar = [];
      if (Array.isArray(rental.current_location)) {
        rental.current_location.forEach((loc, i) => {
          let rental_t = cloneDeep(rental);
          rental_t.location.latitude = loc.latitude;
          rental_t.location.longitude = loc.longitude;
          rental_t.location.name = loc.name;
          rental_t.latlon_key =
            loc.latitude.toString() + loc.longitude.toString();
          rental_t.index = i;
          rentals_ar.push(rental_t);
        });
      }
      return rentals_ar;
    });
    grouped_rentals = orderBy(grouped_rentals, ["latlon_key"], ["asc", "asc"]);
    let mapRentals = [];
    grouped_rentals = mapValues(groupBy(grouped_rentals, "latlon_key"), (x) =>
      x.map((y) => omit(y, "latlon_key"))
    );
    for (let rentals_ in grouped_rentals) {
      for (let rental_ of grouped_rentals[rentals_]) {
        let latLng = new google.maps.LatLng(
          rental_.location.latitude,
          rental_.location.longitude
        );
        if (rentals.length > 0 && grouped_rentals[rentals_].length > 1) {
          for (let i = 0; i < rentals.length; i++) {
            let currentRental = rentals[i];
            let latLng_ = new google.maps.LatLng(
              currentRental.location.latitude,
              currentRental.location.longitude
            );
            if (latLng.equals(latLng_)) {
              let factor = 10;
              if (grouped_rentals[rentals_].length > 5) factor = 20;
              else if (grouped_rentals[rentals_].length > 12) factor = 40;
              else if (grouped_rentals[rentals_].length > 20) factor = 100;
              //Math.round(Math.random() * 400) / 100 = Un número con dos decimales entre 0 y 4
              //El número al azar obtenido luego se suma o resta con un número de 0 al 8 (suma o resta = Math.random() < .5 ? -1 : 1)
              //Finalmente se divide por 100000 para obtener un número con al menos 5 decimales (se incluyen los dos decimales anteriores)
              //La variación final es entre -0.00008 y 0.00012
              //Es posible controlar esto, por ejemplo si queremos que la variación sea menor, disminuyendo los valores de 400 y 8
              let random_factor_lat =
                (Math.round(Math.random() * 100 * factor) / 100 +
                  Math.round(Math.random() * 2 * factor) *
                    (Math.random() < 0.5 ? -1 : 1) -
                  0.5 * factor) /
                100000;
              let random_factor_lng =
                (Math.round(Math.random() * 100 * factor) / 100 +
                  Math.round(Math.random() * 2 * factor) *
                    (Math.random() < 0.5 ? -1 : 1) -
                  0.5 * factor) /
                100000;
              //Finalmente se realiza una última operación para volver a decidir si se suma o resta de forma aleatoria el número final
              //Entonces se sumará o restará el resultado a la latitud/longitud actuales
              const newLat =
                latLng_.lat() +
                (random_factor_lat == 0 ? 0.00002 : random_factor_lat) *
                  (Math.random() < 0.5 ? -1 : 1);
              const newLng =
                latLng_.lng() +
                (random_factor_lng == 0 ? 0.00002 : random_factor_lng) *
                  (Math.random() < 0.5 ? -1 : 1);
              rental_.location.latitude = newLat;
              rental_.location.longitude = newLng;
              latLng = new google.maps.LatLng(newLat, newLng);
            }
          }
        }
        mapRentals.push(rental_);
      }
    }
    return mapRentals;
  };

  showMessage = (messageType, messageTitle, messages) =>
    this.setState(
      (state) => {
        state.alert.showMessage = true;
        state.alert.messageTitle = messageTitle;
        state.alert.messages = messages;
        state.alert.messageType = messageType;
        return { alert: state.alert };
      },
      () => setTimeout(this.handleAlertClose, 3000)
    );

  handleAlertClose = () =>
    this.setState((state) => {
      state.alert.showMessage = false;
      return { alert: state.alert };
    });

  handleChange = (key, value) =>
    this.setState(
      (state) => {
        state.data[key] = value;
        return { data: state.data };
      },
      () => this.handleSubmit({ searchType: this.state.searchType })
    );

  handleChangeLocation = (success, address, lat, lon) => {
    if (success) {
      this.setState(
        (state) => {
          state.data.address = address;
          state.data.lat = lat;
          state.data.lon = lon;
          state.searchedLatLong = {
            lat: lat,
            lng: lon,
          };
          return { data: state.data };
        },
        () => this.handleSubmit({ searchType: constants.SEARCH_BY_BUTTON })
      );
    } else this.setState({ isSubmitting: false });
  };

  handleChangeDate = ({ fromDate, fromTime, untilDate, untilTime }) =>
    this.setState(
      (state) => {
        state.data.fromDate = fromDate;
        state.data.fromTime = fromTime;
        state.data.untilDate = untilDate;
        state.data.untilTime = untilTime;
        return { data: state.data };
      },
      () => this.handleSubmit({ searchType: this.state.searchType })
    );

  resetFilters = () =>
    this.setState(
      (state) => {
        state.data.selectedExtras = [];
        state.data.selectedFeatures = [];
        state.data.selectedCategories = [];
        state.data.selectedTransmissions = [];
        state.data.minPrice = constants.MIN_PRICE;
        state.data.maxPrice = constants.MAX_PRICE;
        state.data.selectedSeats = null;
        state.data.selectedKm = null;
        return { data: state.data };
      },
      () => this.handleSubmit({ searchType: constants.SEARCH_BY_BUTTON })
    );

  loadSpinner = () => this.setState({ isSubmitting: true });

  changeRange = (values) => {
    const minPrice = values[0];
    const maxPrice = values[1];
    this.setState(
      (state) => {
        state.data.minPrice = minPrice;
        state.data.maxPrice = maxPrice;
        return { data: state.data };
      },
      () => this.handleSubmit({ searchType: this.state.searchType })
    );
  };

  openFullMap = () => {
    this.setState({ showMobileMap: true }, () => {
      if (this.state.isMobile) document.body.classList.add("overflow-hidden");
    });
  };

  closeFullMap = () => {
    this.setState({ showMobileMap: false }, () => {
      document.body.classList.remove("overflow-hidden");
    });
  };

  openModalPricing = (location, rentalId) => {
    this.calculatePrice(location, rentalId);
  };

  createParams = (location, rentalId) => {
    this.setState((state) => {
      state.alert.showMessage = false;
      state.alert.messageType = undefined;
      state.alert.messages = [];
      state.alert.messageTitle = undefined;
      return { isSubmitting: true, alert: state.alert };
    });
    const { fromDate, fromTime, untilDate, untilTime } = this.state.data;
    const newFrom = moment(fromDate + fromTime, "DD/MM/YYYYHH:mm").format();
    const newTo = moment(untilDate + untilTime, "DD/MM/YYYYHH:mm").format();

    let rental_pricing = {
      rental_id: rentalId,
      start: newFrom,
      end: newTo,
      extras_ids: [],
    };
    if (location) {
      rental_pricing.special_pickup = location;
      rental_pricing.special_dropoff = location;
    }
    return rental_pricing;
  };

  calculatePrice = (location, rentalId) => {
    const rental_pricing = this.createParams(location, rentalId);
    Axios({
      method: "POST",
      url: `/rentals/${rentalId}/calculate_pricing.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: { rental_pricing },
    })
      .then((response) => {
        if (response.data.success) {
          const pricing = response.data.pricing;
          this.setState(
            {
              pricing,
              newFrom: rental_pricing.start,
              newTo: rental_pricing.end,
            },
            () => this.modalPricingDetails.show()
          );
        } else {
          this.showMessage(
            "primary",
            "Alerta",
            response.data.messages || ["Ha ocurrido un error inesperado"]
          );
        }
      })
      .catch((error) => {
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ]);
      })
      .finally(() => this.setState({ isSubmitting: false }));
  };

  updateRadius = ({ radius, lat, lng }) =>
    this.setState(
      (state) => {
        state.data.lat = lat;
        state.data.lon = lng;
        return {
          data: state.data,
          radius,
          searchType: constants.SEARCH_BY_MAP,
        };
      },
      () => this.handleSubmit({ searchType: constants.SEARCH_BY_MAP })
    );

  render() {
    const {
      data,
      alert,
      rentals,
      isSubmitting,
      mapRentals,
      latLng,
      rentalID,
      // customZoom,
      shouldUpdateMap,
      highlightRentalId,
      isLoadingMoreRentals,
      isMobile,
      showMobileMap,
      pricing,
      searchType,
      searchedLatLong,
    } = this.state;
    const { messageTitle, messageType, messages, showMessage } = alert;
    const {
      vehicleExtras,
      vehicleFeatures,
      vehicleCategories,
      vehicleTransmissions,
      shouldShowFilters,
      sharingUrl,
    } = this.props;

    const { oldCount, newCount } = this.state.paginationInfo;
    const spinner = <Spinner loading={isSubmitting} />;

    const { fromDate, fromTime, untilDate, untilTime } = data;

    const from = moment(fromDate + fromTime, "DD/MM/YYYYHH:mm");
    const untilString = moment(
      untilDate + untilTime,
      "DD/MM/YYYYHH:mm"
    ).format();
    const dayOfWeek = from.day();
    const linkSuffix = `?from=${from.format()}&to=${untilString}`;

    const visitLink = (rental, position, location) => {
      const rentalId = rental.parent_id || rental.id;
      const link = `/rentals/${rentalId}${linkSuffix}&address=${encodeURIComponent(
        location
      )}`;
      if (this.dataLayer != undefined) {
        if (Array.isArray(this.dataLayer)) {
          const vehicle = rental.vehicle;
          const vehicleType = vehicle.vehicle_type;
          const brand = vehicleType.brand;
          const model = vehicleType.model;
          const year = vehicle.year;
          this.dataLayer.push({
            event: "productClick",
            ecommerce: {
              click: {
                products: [
                  {
                    name: brand, // Name o id es requerido.
                    id: vehicle.id,
                    brand: model + " | " + year,
                    position: position, // Posición del producto en la lista de resultados.
                  },
                ],
              },
            },
          });
        }
      }
      window.open(link, "_blank", "noopener,noreferrer");
    };

    const pricingDetailsModal = (
      <ModalPricing pricing={pricing} from={from.format()} to={untilString} />
    );

    return (
      <Fragment>
        {spinner}
        {pricingDetailsModal}
        <div>
          <Alert
            alertClass={messageType}
            isShowing={showMessage}
            messages={messages}
            title={messageTitle}
            onClose={this.handleAlertClose}
          />
          <Search
            data={data}
            vehicleExtras={vehicleExtras}
            vehicleFeatures={vehicleFeatures}
            vehicleCategories={vehicleCategories}
            vehicleTransmissions={vehicleTransmissions}
            shouldShowFilters={shouldShowFilters}
            handleChange={this.handleChange}
            handleSelectLocation={this.handleChangeLocation}
            handleChangeDate={this.handleChangeDate}
            loadSpinner={this.loadSpinner}
            callbackSubmit={() =>
              this.handleSubmit({ searchType: constants.SEARCH_BY_BUTTON })
            }
            changeRange={this.changeRange}
            textColorClass={"text-black rentals-search-page"}
            shouldUpdateLocalDates={true}
            modalFilters={this.modalFilters}
            resetFilters={this.resetFilters}
            openFullMap={this.openFullMap}
            closeFullMap={this.closeFullMap}
            showMobileMap={showMobileMap}
          />
          <div className="row flex-column-reverse flex-sm-row">
            <div className="col-12 col-sm-auto pr-0">
              <List
                rentals={rentals}
                loadMoreResults={(params) =>
                  this.handleSubmit({ ...params, searchType: searchType })
                }
                enterCard={this.enterCard}
                isLoadingMoreRentals={isLoadingMoreRentals}
                oldCount={oldCount}
                newCount={newCount}
                dayOfWeek={dayOfWeek}
                visitLink={visitLink}
                sharingUrl={sharingUrl}
                linkSuffix={linkSuffix}
                openModalPricing={this.openModalPricing}
              />
            </div>
            <div className="col-12 col-sm rentals-map">
              <Map
                rentals={mapRentals}
                customLatLng={latLng}
                reportChange={this.reportChange}
                selectedRental={rentalID}
                countries={["cl"]}
                searchedLatLong={searchedLatLong}
                handleSubmit={(params) =>
                  this.handleSubmit({
                    ...params,
                    searchType: constants.SEARCH_BY_MAP,
                  })
                }
                // customZoom={customZoom}
                shouldUpdate={shouldUpdateMap}
                visitLink={visitLink}
                spinner={spinner}
                highlightedRental={highlightRentalId}
                pinClick={this.pinClick}
                dayOfWeek={dayOfWeek}
                originalRentals={rentals}
                isMobile={isMobile}
                showMobileMap={showMobileMap}
                openModalPricing={this.openModalPricing}
                updateRadius={this.updateRadius}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default RentalsSearch;
