import React, { Fragment } from "react";

const Policies = ({ fuelPolicy, cancellationPolicy, isOwner, isDriver }) => {
  const f_policy_name =
    (fuelPolicy && fuelPolicy.name) ||
    "EL VEHÍCULO NO TIENE POLÍTICAS DE USO DE COMBUSTIBLES";
  const policy_name =
    (cancellationPolicy && cancellationPolicy.name) ||
    "EL VEHÍCULO NO TIENE POLÍTICAS DE CANCELACIÓN";
  const rules =
    cancellationPolicy && cancellationPolicy.rules
      ? cancellationPolicy.rules.map((rule, i) => {
          return <li key={i}>{rule.description}</li>;
        })
      : null;
  let f_rule = "";
  let f_owner_rule = null;
  let f_driver_rule = null;
  if (fuelPolicy && fuelPolicy.description) {
    f_rule = fuelPolicy.description;
    f_owner_rule = isOwner ? (
      <p style={{ whiteSpace: "pre-line" }}>
        {isDriver ? "Owner: " : ""}
        {fuelPolicy.owner_description}
      </p>
    ) : null;
    f_driver_rule = isDriver ? (
      <p style={{ whiteSpace: "pre-line" }}>
        {isOwner ? "Driver: " : ""}
        {fuelPolicy.driver_description}
      </p>
    ) : null;
  }
  return (
    <Fragment>
      <h4>Política de cancelación</h4>
      <div className="row">
        <div className="col">
          <div className="included-distance my-2">
            <div className="charge-text">{policy_name.toUpperCase()}</div>
            <div className="charge-text-row mb-2">
              <ul>{rules}</ul>
            </div>
            <a href="/legal/rental_policy#cancellation_policies" target="_blank" className="text-appcar-primary">Más detalles</a>
          </div>
        </div>
      </div>
      <h4>Política de combustible</h4>
      <div className="row">
        <div className="col">
          <div className="included-distance my-2">
            <div className="charge-text">{f_policy_name.toUpperCase()}</div>
            <div className="charge-text-row mb-2">
              <p>{f_rule}</p>
              {f_owner_rule}
              {f_driver_rule}
            </div>
            <a href="/legal/rental_policy#fuel_policies" target="_blank" className="text-appcar-primary">Más detalles</a>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Policies;
