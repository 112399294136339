import React from "react";

const ImageContainer = ({ addImage, removeImage, imageBase64, fullPath, start, index, url = null }) => {
  const action_button = imageBase64 || url ? (
    <button
      className="btn btn-danger btn-lg btn-rounded"
      style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      onClick={() => {
        fullPath
          ? removeImage(index, fullPath)
          : removeImage(index);
      }}
    >
      <i
        className="mdi mdi-image-off"
      />
    </button>
  ) : (
    <button
      className="btn btn-appcar-primary btn-lg btn-rounded"
      style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      onClick={() => {
        fullPath
          ? addImage(fullPath)
          : addImage();
      }}
    >
      <i
        className="mdi mdi-image-plus"
      />
    </button>
  );
  const label = imageBase64 || url ? null : (
    <p className="image-container-label">Añadir imagen</p>
  );
  return (
    <div
      className="image-container-wizard"
      style={{ backgroundImage: `url("${imageBase64 ? start + "," + imageBase64 : url}")` }}
    >
      {label}
      {action_button}
    </div>
  );
};

export default ImageContainer;
