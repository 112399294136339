import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Axios from "axios";
// import { AxiosApplicationInterceptor } from "../../common/axios_interceptor";
// Axios.interceptors.response.use(undefined, AxiosApplicationInterceptor);

class DestinationsSlide extends Component {
  // state = {
  //   locations: []
  // };

  // componentDidMount() {
  //   this.willUmount = false;
  //   this.cancelToken = Axios.CancelToken.source();
  //   Axios(
  //     {
  //       method: "get",
  //       url: `/backoffice/locations.json`,
  //       responseType: "json",
  //       headers: {
  //         "Content-Type": "application/json"
  //       }
  //     },
  //     { cancelToken: this.cancelToken.token }
  //   )
  //     .then(response => {
  //       if (!this.willUmount) {
  //         const locations = response.data.map(loc => {
  //           loc.id = parseInt(loc.id);
  //           return loc;
  //         });
  //         this.setState({ locations });
  //       }
  //     })
  //     .catch(error => {
  //       if (Axios.isCancel(error)) {
  //         console.log("canceled");
  //       } else {
  //         console.log(error);
  //       }
  //     });
  // }

  redirectToSearch = (location_id, latitude, longitude) => {
    if (false) {
      // TODO: Tenemos que definir qué se hará cuando se seleccione una ciudad.
      const from = moment()
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ssZ");
      const until = moment()
        .endOf("day")
        .format("YYYY-MM-DDTHH:mm:ssZ");
      window.location.assign(
        `/portada/map?location_id=${location_id}&lat=${latitude}&lon=${longitude}&radius=25&from=${from}&until=${until}`
      );
    }
  };

  componentWillUnmount() {
    this.cancelToken && this.cancelToken.cancel();
    this.willUmount = true;
  }

  render() {
    const settings = {
      infinite: true,
      speed: 500,
      dots: false,
      swipeToSlide: true,
      slidesToShow: 6,
      initialSlide: 0,
      arrows: true,
      responsive: [
        {
          breakpoint: 1240,
          settings: {
            slidesToShow: 5,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 860,
          settings: {
            slidesToShow: 3,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 2,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 512,
          settings: {
            slidesToShow: 1,
            infinite: true,
            dots: false,
            fade: true
          }
        }
      ]
    };
    const slides = this.props.locations.map((location, i) => {
      return (
        <div className="destination-slide" key={i}>
          <div
            className="destination-image"
            style={{
              backgroundImage: `url("${location.url_image ||
                "/picture-icon.png"}")`,
              cursor: "pointer"
            }}
            onClick={() =>
              this.redirectToSearch(
                location.id,
                location.latitude,
                location.longitude
              )
            }
          />
          <div
            className="destination-name"
            style={{ cursor: "pointer" }}
            onClick={() =>
              this.redirectToSearch(
                location.id,
                location.latitude,
                location.longitude
              )
            }
          >
            {location.name}
          </div>
        </div>
      );
    });
    return (
      <React.Fragment>
        <div className="row mb-4">
          <div className="col-12">
            <h1 className="popular-destinations-title">
              Destinos más frecuentes
            </h1>
          </div>
        </div>
        <div className="row mx-2">
          <div className="col-12">
            <Slider {...settings}>{slides}</Slider>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DestinationsSlide;
