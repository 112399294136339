import React from "react";
import { InfoWindow } from "react-google-maps";
import { formatedPricing } from "../../constants";
import SuperOwnerBadge from "images/Icons/super_owner.svg";

const RentalInfoWindow = ({
  name,
  photoUrl,
  visitLink,
  position,
  closeWindow,
  highlighted,
  rental,
  openModalPricing,
  dayOfWeek = 0,
  hasImage = true,
}) => {
  const vehicle = rental.vehicle;
  const brand =
    vehicle.vehicle_type && vehicle.vehicle_type.brand ? (
      <span className="fw-900 fs-16">{vehicle.vehicle_type.brand}</span>
    ) : null;
  const model = vehicle.vehicle_type?.model;
  const year = vehicle.year;
  let modelYear = "";
  if (model) modelYear += model;
  if (year && model) modelYear += " | ";
  if (year) modelYear += year;
  let minLength = 0;
  if (rental.availability && rental.availability.booking_length) {
    if (dayOfWeek == 5) {
      minLength =
        rental.availability.booking_length.minimum_length_saturday || 0;
    } else if (dayOfWeek == 6) {
      minLength = rental.availability.booking_length.minimum_length_sunday || 0;
    } else {
      minLength = rental.availability.booking_length.minimum_length || 0;
    }
  }
  const minBooking =
    minLength > 0 ? (
      <span className="minimum-days fw-600">
        Mín. <span className="fw-900">{minLength}</span> día
        {minLength > 1 ? "s" : ""} de arriendo
      </span>
    ) : null;
  let infoWindowClass = "rental-info-window";
  if (highlighted) infoWindowClass += " rental-info-window-highlighted";
  const superOwnerBadge = rental.super ? (
    <div className="info-window-super-owner">
      <img src={SuperOwnerBadge} height={20} width={20} />
    </div>
  ) : null;
  return (
    <InfoWindow position={position}>
      <div className={infoWindowClass}>
        <div className="rental-info-window-plate" onClick={() => visitLink()}>
          <i className="mdi mdi-car"></i> ** ** {vehicle.plate_number}
        </div>
        {superOwnerBadge}
        <div className="rental-info-window-body">
          {photoUrl && (
            <img
              src={photoUrl}
              className={`vehicle-pic ${!hasImage ? "no-image" : ""}`}
              onClick={() => visitLink()}
            />
          )}
          <div className="vehicle-info">
            <div className="brand-model-year">
              {brand}
              <span className="fs-13 fw-400">{modelYear}</span>
            </div>
            <div className="price">
              <span className="fw-900 fs-16">
                {formatedPricing(rental.current_daily_price)}/día
              </span>
              <span className="fw-400 fs-12">
                Total estimado {formatedPricing(rental.current_price)}{" "}
                <i
                  className="mdi mdi-information fs-16 text-appcar-primary cursor-pointer"
                  onClick={() => openModalPricing()}
                ></i>
              </span>
            </div>
            {/* {minBooking} */}
            {/* <a
        href={link}
        style={{ display: "block" }}
        className="btn btn-appcar-primary btn-sm mt-2"
        rel="noopener noreferrer"
        target="_blank"
      >
        Ir a publicación &gt;
      </a> */}
          </div>
        </div>
      </div>
    </InfoWindow>
  );
};

export default RentalInfoWindow;
