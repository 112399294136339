import React, { Component } from "react";
import moment from "moment";
import { BookingStatus, numeral } from "../../../common/constants";
import StartedIcon from "images/Icons/StartedIconInverted.png";
import PendingIcon from "images/Icons/PendingIconInverted.png";
import ConfirmedIcon from "images/Icons/ConfirmedIconInverted.png";

export default class BookingStatusCard extends Component {


  render() {
    moment.locale("es");

    const { booking } = this.props;
    const pricing = booking.pricing;

    const from = moment(booking.date_from);
    const until = moment(booking.date_until);
    const bookingStatus = booking.status.id;


    const datesContainer = (
      <div className="dates">
        <div className="from">
          <span className="day-month">{from.format("DD MMM").replace(/\./g, "")}</span>
          <span className="hour-minute">{from.format("HH:mm")}</span>
        </div>
        <div className="to">
          <span className="day-month">{until.format("DD MMM").replace(/\./g, "")}</span>
          <span className="hour-minute">{until.format("HH:mm")}</span>
        </div>
      </div>
    );

    let bookingIcon = null;
    let bookingStatusDescription = "";
    switch (bookingStatus) {
      case BookingStatus.PENDIENTE || BookingStatus.EN_REVISION:
        bookingIcon = PendingIcon;
        break;
      case BookingStatus.INICIADO:
        bookingIcon = StartedIcon;
        break;
      default:
        bookingIcon = ConfirmedIcon;
    }
    if (bookingStatus == BookingStatus.CANCELADO) bookingStatusDescription = "cancelada";
    else if (bookingStatus == BookingStatus.COMPLETADO) bookingStatusDescription = "completada";
    else if (bookingStatus == BookingStatus.REEMPLAZADO) bookingStatusDescription = "reemplazada"

    const cancelLink = ![BookingStatus.CANCELADO, BookingStatus.COMPLETADO, BookingStatus.REEMPLAZADO].includes(bookingStatus) ? (
      <a className="edit-dates" data-bs-toggle="modal" href="#modalCancel">Cancelar reserva</a>
    ) : <a className="edit-dates disabled" data-bs-toggle="modal" href="#" disabled>No se puede cancelar una reserva {bookingStatusDescription}</a>

    const editLink = bookingStatus == BookingStatus.ACTIVO || bookingStatus == BookingStatus.INICIADO ? (
      <a className="edit-dates" href={`/backoffice/bookings/${booking.id}/edit`}>Modificar reserva</a>
    ) : <a className="edit-dates disabled" data-bs-toggle="modal" href="#">No se puede modificar esta reserva</a>
    return (
      <>
        <div className="scheduler-component">
          <div className="row align-items-center justify-content-around">
            <div className="col text-center">
              <img src={bookingIcon} width={60} />
            </div>
            <div className="col booking-status text-center">
              {booking.status.description}
            </div>
          </div>
          <hr className="text-white" />
          <div className="row align-items-center">
            <div className="col-6 scheduler-separator text-center">
              <span className="d-block fs-12 fw-400 mx-2">Nº reserva <span className="fs-21 fw-900">{booking.id}</span></span>
              <span className="d-block fs-12 fw-400 mx-2">Valor <span className="fs-21 fw-900">{numeral(pricing.total_price).format("$0,0")}</span></span>
            </div>
            <div className="col-6 text-center">
              {datesContainer}
            </div>
          </div>
          <hr className="text-white" />
          <div className="row align-items-center">
            <div className="col-6 text-center">
              {editLink}
            </div>
            <div className="col-6 text-center">
              {cancelLink}
            </div>
          </div>
        </div>
      </>
    )
  }
}