import React, { useEffect } from "react";
import FixedMap from "../../../common/components/fixed_map/FixedMap";
import LocationSelector from "../../../common/search_components/AppcarLocationSelector";
import ToolTip, { LoadToolTips } from "../../../common/components/ToolTip";
import PromotionalMiniCard from "./PromotonialMiniCard";
import markerUrl from "images/Icons/PickupIcon.png";
import iconInfo from "images/Icons/ico-info.png";
import iconPricing from "images/Icons/icon_pricing.svg";
import car1 from "images/products/car1.png";
import car2 from "images/products/car2.png";
import car3 from "images/products/car3.png";
import LogoSura from "images/Sura.png";

const VehicleLocation = ({
  location,
  handleChangeLocation,
  handleUpdateRental,
  isSubmitting,
  loadSpinner,
  closeSpinner,
}) => {
  useEffect(() => {
    LoadToolTips();
  }, []);

  const changeLocation = ({ success, lat, lon, address }) => {
    if (success) {
      handleChangeLocation({
        latitude: lat,
        longitude: lon,
        name: address,
      });
    }
    closeSpinner();
  };

  const mapContainer =
    location.latitude && location.longitude ? (
      <div className="my-3 vehicle-location-map">
        <FixedMap
          lat={location.latitude}
          lng={location.longitude}
          markerUrl={markerUrl}
          height={190}
          canZoom={false}
          gestureHandling="none"
        />
      </div>
    ) : null;
  let disableButton = true;
  if (location.latitude && location.longitude && location.name) disableButton = false;
    return (
      <>
        <div className="mb-0 row align-items-center">
          <div className="col">
            <label className="fs-13 fw-800 text-appcar-primary">
              Punto de recogida y devolución
            </label>
          </div>
          <div className="col-auto ml-2 pb-1">
            <ToolTip
              title="Puntos de recogida y devolución"
              tooltipHTML="Registra la dirección exacta donde entregarás el auto.<br/><br/>Podrás agregar direcciones adicionales, con un cobro extra por cada tramo de recogida y/o devolución para el Driver, al personalizar tu publicación más adelante en el proceso."
              containerHTML={<img src={iconInfo} width={20} className="ml-1" />}
              id="ttip_vehicle_location"
            />
          </div>
        </div>
        <div className="form-group">
          <LocationSelector
            countries={["cl"]}
            handleSelectLocation={changeLocation}
            placeholder="Buscar dirección..."
            label="Dirección principal"
            airportsFirst={false}
            loadSpinner={loadSpinner}
            labelClass="label-inside"
            address={location.name}
            inputClass="input-autocomplete-places fw-400"
            mainInputClass="form-control input-location-search appcar-input"
            iconOrder="right"
          />
        </div>

        {mapContainer}
        <p className="text-appcar-primary fs-16 fw-900 text-center mt-5">
          ¿Quieres saber cuánto ganan
          <br /> algunos de nuestros Owners?
        </p>
        <div className="row justify-content-between mb-3">
          <div className="col-auto pr-0">
            <PromotionalMiniCard
              image={car1}
              daysNumber={12}
              ownerName="María José M."
              qty={156000}
              vehicleType="Kía Morning 2018"
            />
          </div>
          <div className="col-auto px-0">
            <PromotionalMiniCard
              image={car2}
              daysNumber={10}
              ownerName="Cristian M."
              qty={168000}
              vehicleType="Chevrolet Sail 2021"
            />
          </div>
          <div className="col-auto pl-0">
            <PromotionalMiniCard
              image={car3}
              daysNumber={8}
              ownerName="Alejandro L."
              qty={208000}
              vehicleType="Mazda CX5 2017"
            />
          </div>
        </div>
        <div className="promotional-info">
          <p className="pricing-title">
            <img src={iconPricing} alt="Precio" /> No te preocupes por definir
            un precio.
          </p>
          <div className="row">
            <div className="col">
              <p className="pricing-quote">
                Nosotros nos encargaremos de maximizar tus ingresos
              </p>
            </div>
            <div className="col-auto">
              <ToolTip
                title="Tarifa dinámica"
                tooltipHTML="Aplicaremos una tarifa dinámica para maximizar tus ingresos según temporada, día de semana, anticipación de reserva y cualquier otra variable que afecte la demanda"
                containerHTML={
                  <img src={iconInfo} width={20} className="ml-1" />
                }
                id="ttip_pricing_promotional"
              />
            </div>
          </div>
        </div>
        <p className="text-center fs-13 mb-0">
          Recuerda que todos tus arriendos estarán <br />
          asegurados por:
        </p>
        <img src={LogoSura} alt="SuraLogo" width={320} className="mb-3" />
        <div className="text-center my-4">
          <button
            type="button"
            className="btn btn-appcar-primary pt-2 pb-2"
            onClick={() => handleUpdateRental()}
            disabled={isSubmitting || disableButton}
          >
            Publicar
          </button>
        </div>
      </>
    );
};

export default VehicleLocation;
