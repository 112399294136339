import React from "react";

const GetCodeForm = ({
  email,
  setEmail,
  getCode,
  showModalRecovery,
  canBack,
  onBack,
}) => (
  <div className="modal-dialog modal-dialog-centered modal-appcar">
    <div className="modal-content">
      <div className="modal-header">
        <button
          type="button"
          className="btn-m-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          x
        </button>
        <h5 className="modal-title" id="passwordRecoveryModalLabel">
          Cambia tu contraseña
        </h5>
      </div>
      <div
        className="modal-body mx-auto pb-3"
        style={{ maxWidth: 350, paddingTop: 36 }}
      >
        <div className="text-center mb-2">
          <h4 className="fs-25 fw-900 mb-4">Te ayudamos</h4>
          <h5 className="fs-13 fw-700 mb-3">
            Por favor verifica tu email con el cual te registraste, te
            enviaremos un código para cambiarla.
          </h5>
          <div className="form-group text-left mb-4">
            <label className="label-inside">Tu email</label>
            <input
              type="email"
              className="form-control appcar-input"
              required
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </div>
          <button
            type="button"
            className="btn btn-appcar-primary btn-slim"
            disabled={!email}
            onClick={() => {
              if (!!email) getCode();
            }}
          >
            Solicitar código
          </button>
          <div className="text-center foot-link my-3">
            <span
              className="fs-11 fw-900 text-appcar-primary cursor-pointer"
              onClick={() => {
                const myModalEl = document.getElementById(
                  "passwordRecoveryModal"
                );
                const modal = bootstrap.Modal.getInstance(myModalEl);
                modal.hide();
                showModalRecovery();
              }}
            >
              Ya tengo un código
            </span>
          </div>
        </div>
      </div>
      {canBack ? (
        <div
          className="back-button"
          onClick={() => {
            if (typeof onBack === "function") {
              const myModalEl = document.getElementById(
                "passwordRecoveryModal"
              );
              const modal = bootstrap.Modal.getInstance(myModalEl);
              modal._isShown = true;
              modal.hide();
              onBack();
            }
          }}
        >
          <i className="mdi mdi-chevron-left fs-20" /> Volver
        </div>
      ) : null}
    </div>
  </div>
);

export default GetCodeForm;
