import React from "react";
import carLocationIcon from "images/Icons/PickupIcon.png";
import FixedMap from "../../../../common/components/fixed_map/FixedMap";

const ModalLocation = ({ lat, lng, address, markerUrl }) => (
  <div
    className="modal fade"
    id="modalLocation"
    aria-hidden="true"
    aria-labelledby="modalLocationLabel"
    tabIndex={-1}
  >
    <div className="modal-dialog modal-dialog-centered modal-appcar modal-map">
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="btn-m-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            x
          </button>
          <h5 className="modal-title" id="mapModalLabel">
            Ubicación
          </h5>
        </div>
        <div className="modal-body" style={{ padding: 20 }}>
          <div className="d-flex align-items-end" style={{ paddingBottom: 20 }}>
            <img src={carLocationIcon} width="22" className="mr-2" />
            <div className="text-appcar-primary fs-15 fw-800">{address}</div>
          </div>
          <FixedMap lat={lat} lng={lng} markerUrl={markerUrl} />
        </div>
      </div>
    </div>
  </div>
);

export default ModalLocation;
