import React, { Component } from "react";
import { numeral } from "../../common/constants";
import LocationContainer from "../rentals/show/containers/LocationContainer";
import ModalLocation from "../rentals/show/modals/ModalLocation";

export default class BookingShowLocation extends Component {
  state = {
    mapInfo: {
      lat: 0,
      lng: 0,
      locationAddress: "",
    },
  };
  modalLocationMap = null;
  componentDidMount() {
    this.modalLocationMap = new bootstrap.Modal(
      document.getElementById("modalLocation")
    );
  }
  showLocationMap = ({ lat, lng, locationAddress }) => {
    this.setState(
      (state) => {
        state.mapInfo.lat = lat;
        state.mapInfo.lng = lng;
        state.mapInfo.locationAddress = locationAddress;
        return { mapInfo: state.mapInfo };
      },
      () => this.modalLocationMap.show()
    );
  };

  render() {
    const rental = this.props.rental;
    const booking = this.props.booking;
    const extraLocations = rental.pricing.extra_locations || [];
    const pickup = booking.pricing?.selected_pickup_location;
    const dropoff = booking.pricing?.selected_dropoff_location;
    const selectedPickup = { label: `${numeral(pickup?.price || 0).format("0,0")} - ` + pickup?.location?.name, value: pickup?.location?.name };
    const selectedDropoff = { label: `${numeral(dropoff?.price || 0).format("0,0")} - ` + dropoff?.location?.name, value: dropoff?.location?.name };
    const mapInfo = this.state.mapInfo;
    const modalMap = (
      <ModalLocation
        lat={mapInfo.lat}
        lng={mapInfo.lng}
        address={mapInfo.locationAddress}
        markerUrl={this.props.markerIconURL}
      />
    );
    return (
      <>
        <LocationContainer
          extraLocations={extraLocations}
          location={rental.location}
          selectedDropoff={selectedDropoff}
          selectedPickup={selectedPickup}
          showLocationMap={this.showLocationMap}
          canEdit={false}
          changeDropOff={() => {}}
          changePickUp={() => {}}
        />
        {modalMap}
      </>
    );
  }
}
