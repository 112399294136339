import React from "react";

const FlashMessage = props => {
  const messages = props.msg.map((msg, i) => <li key={i} className="mb-0">{msg}</li>);
  return (
    <div className="container">
      <div className={`alert ${props.type} fade show`} role="alert" >
        {/* <button type="button" className="close" data-dismiss="alert" data-bs-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> */}
        <label className="mb-0">{props.title}</label>
        <ul className="list-unstyled mb-0">{messages}</ul>
      </div>
    </div>
  );
};

export default FlashMessage;
