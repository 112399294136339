import React, { Children } from "react";
import { components } from "react-select";

const IndicatorsContainer = ({ children, ...props }) => {
  const allowedChildren = Children.map(children, (child) => {
    return child && child.type.name !== "DropdownIndicator" ? child : null;
  });

  return (
    <components.IndicatorsContainer {...props}>
      <components.DropdownIndicator {...props} />
      {allowedChildren}
    </components.IndicatorsContainer>
  );
};

export default IndicatorsContainer;