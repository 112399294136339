import React, { Fragment } from "react";
import DriverQualification from "../../common/qualification/DriverQualification";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { numeral, BookingStatus } from "../../common/constants";
import CardCarousel from "../../common/CardCarousel";
const moment = extendMoment(Moment);

const BookingListItem = ({
  booking,
  handleCancel,
  handleAccept,
  compareBookings,
}) => {
  const hideModal = () => {
    $(`#modal_driver_eval_list_${booking.id}`).modal("hide");
  };
  const rateDriver = () => {
    $(`#modal_driver_eval_list_${booking.id}`).modal("show");
  };
  const driver = booking.user[0];
  const photo = driver.photo ? driver.photo : "/user-icon.png";
  const image_style = {
    backgroundImage: `url("${photo}")`,
    float: "none",
    margin: "auto",
  };

  const edit_booking_text =
    booking.status.id === BookingStatus.PENDIENTE ||
    booking.status.id === BookingStatus.EN_REVISION
      ? "Ofrecer otro auto"
      : "Solicitar cambio de auto";

  let bookingEditable = false;

  if (
    booking.owner_base_booking_id &&
    booking.status.id == BookingStatus.EN_REVISION
  )
    bookingEditable = false;
  else if (
    booking.status.id === BookingStatus.PENDIENTE ||
    booking.status.id === BookingStatus.EN_REVISION ||
    booking.status.id === BookingStatus.ACTIVO
  ) {
    if (moment(booking.date_until).valueOf() > moment().valueOf()) {
      bookingEditable = true;
    }
  }

  const edit_btn = bookingEditable ? (
    <div className="col-auto">
      <a
        href={`/bookings/${booking.id}/owner_edit`}
        className="btn btn-appcar-primary mb-2 pt-2 pb-2"
      >
        {edit_booking_text}
      </a>
    </div>
  ) : null;

  let driver_rating = [];
  const driver_evaluation_modal =
    booking.status.id === BookingStatus.COMPLETADO &&
    (booking.review_status === null || !booking.review_status.driver) ? (
      <div
        id={`modal_driver_eval_list_${booking.id}`}
        className="modal fade"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body p-4">
              <DriverQualification driverId={driver.id} callback={hideModal} />
            </div>
          </div>
        </div>
      </div>
    ) : null;
  for (let i = 1; i <= 5; i++) {
    const r_icon =
      i <= driver.driver_rating ? (
        <i className="mdi mdi-star" key={i} />
      ) : (
        <i className="mdi mdi-star-outline" key={i} />
      );
    driver_rating.push(r_icon);
  }
  const evaluations = (
    <div
      className="calendar-driver-badge-ratings"
      key="badge"
      style={{ width: "fit-content", float: "none", margin: "auto" }}
    >
      {driver.driver_evaluations || 0}{" "}
      {driver.driver_evaluations == 1 ? "evaluación" : "evaluaciones"}
    </div>
  );
  const vehicle = booking.vehicle;

  let vehicle_pictures = vehicle.vehicle_pictures;

  const v_type = vehicle.vehicle_type;
  const range = moment.range(`${booking.date_from}/${booking.date_until}`);
  const booking_length_in_days = range.diff("days");
  let cancel_text = "";
  if (booking.status.id === BookingStatus.PENDIENTE) {
    cancel_text = "Rechazar reserva";
  } else if (
    booking.status.id === BookingStatus.EN_REVISION &&
    booking.base_booking_id
  ) {
    cancel_text = "Rechazar modificación";
  } else {
    cancel_text = "Cancelar reserva";
  }
  const btn_accept_booking =
    booking?.status?.id === BookingStatus.PENDIENTE ||
    (booking?.status?.id === BookingStatus.EN_REVISION &&
      !booking?.owner_base_booking_id) ? (
      <div className="col-md-6">
        <button
          type="button"
          className="btn btn-appcar-secondary mb-2 pt-2 pb-2 w-100"
          onClick={() => handleAccept(booking.id)}
        >
          {booking.status.id == BookingStatus.EN_REVISION
            ? "Aceptar modificación"
            : "Aceptar reserva"}
        </button>
      </div>
    ) : null;
  const btn_cancel_booking =
    booking && booking.status.id != BookingStatus.CANCELADO ? (
      <div className="col-md-6">
        <button
          type="button"
          className="btn btn-appcar-light mb-2 pt-2 pb-2 w-100"
          onClick={() => handleCancel(booking.id)}
        >
          {cancel_text}
        </button>
      </div>
    ) : null;
  const btn_compare_bookings = booking.base_booking_id ? (
    <div className="col-md-12">
      <button
        className="btn btn-appcar-primary mb-2 pt-2 pb-2 w-100"
        type="button"
        onClick={() => compareBookings(booking.base_booking_id, booking.id)}
      >
        Comparar con reserva original
      </button>
    </div>
  ) : null;
  const btns =
    !!btn_accept_booking || !!btn_cancel_booking || !!btn_compare_bookings ? (
      <div className="row" style={{ width: 420 }}>
        {btn_accept_booking}
        {btn_cancel_booking}
        {btn_compare_bookings}
        {edit_btn}
      </div>
    ) : null;
  const alert_edited_booking =
    booking.base_booking_id &&
    booking.status.id == BookingStatus.EN_REVISION ? (
      <div className="alert primary">
        <p className="mb-0">Información: Esta es una reserva modificada</p>
      </div>
    ) : null;
  return (
    <Fragment>
      <div className="booking-card">
        <div
          className="row justify-content-center"
          style={{ maxWidth: "960px" }}
        >
          <div
            className="col-md-auto my-1"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              maxWidth: "100%", 
              width: 420,
            }}
          >
            <div style={{ maxWidth: "400px" }}>
              <h4 className="fs-14">
                {range.start.locale("es").format("LL")} -{" "}
                {range.end.locale("es").format("LL")}
              </h4>
              <h4 className="fs-18">
                {v_type.brand} {v_type.model} {vehicle.year} |{" "}
                {vehicle.plate_number}
              </h4>
              <h4 className="fs-14">
                {numeral(booking.price.total).format("$ 0,0")} &gt;{" "}
                {booking_length_in_days} día
                {booking_length_in_days > 1 ? "s" : ""}
              </h4>
              <h4 className="fs-14">
                Estado:{` ${booking.status && booking.status.description}`}
              </h4>
              {alert_edited_booking}
            </div>
            {btns}
          </div>
          <div className="col-md-auto text-center my-1" style={{ width: 280 }}>
            <div className="carousel-container">
              <CardCarousel images={vehicle_pictures} />
            </div>
          </div>
          <div
            className="col-md-auto text-center my-1"
            style={{
              maxWidth: "100%",
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              justifyContent: "flex-start",
              width: 220,
            }}
          >
            <div className="calendar-driver-image" style={image_style} />
            <div className="calendar-driver-info" style={{ width: "auto" }}>
              <p className="driver-name">
                {driver.name + " " + driver.last_name}
              </p>
              <div
                className="driver-rating"
                onClick={() => {
                  if (
                    booking.status.id === BookingStatus.COMPLETADO &&
                    (booking.review_status === null ||
                      !booking.review_status.driver)
                  )
                    rateDriver();
                }}
              >
                {driver_rating}
              </div>
              {evaluations}
            </div>
          </div>
        </div>
      </div>
      <hr />
      {driver_evaluation_modal}
    </Fragment>
  );
};

export default BookingListItem;
