import React, { Fragment } from "react";
import { formatedPricing } from "../../../../common/constants";

const RentalsList = ({ rentals, handleSelectRental }) => {
  let rows = rentals.map((rental, i) => {
    const vehicle = rental.vehicle;
    const { vehicle_type, plate_number, color } = vehicle;
    const vehicle_name = vehicle_type ? vehicle_type.brand + " " + vehicle_type.model + " " + vehicle.year : vehicle.year.toString();
    const rental_pricing = formatedPricing(rental.pricing.base_price) + "/día > IVA Inc.";
    const rental_id = rental.parent_id || rental.id;
    return (
      <tr key={i}>
        <td>{vehicle_name}</td>
        <td>{plate_number}</td>
        <td>{color}</td>
        <td>{rental_pricing}</td>
        <td>
          <a className="btn btn-appcar-primary btn-sm mr-2 mb-2" href={`/rentals/${rental_id}`} target="_blank" rel="noopener noreferrer">Ver publicación</a>
          <button type="button" onClick={() => handleSelectRental(rental)} className="btn btn-appcar-light btn-sm mb-2">Seleccionar vehículo</button>
        </td>
      </tr>
    )
  });
  if (rows.length === 0) rows.push(<tr key="nodata"><td colSpan="5" className="text-center">No existen vehículos disponibles con los criterios seleccionados</td></tr>)
  return (
    <Fragment>
      <h4>Lista de vehículos</h4>
      <table className="table dt-bootstrap4 dt-responsive table-striped nowrap">
        <thead>
          <tr>
            <th>Vehículo</th>
            <th>Patente</th>
            <th>Color</th>
            <th>Precio diario aprox</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    </Fragment>
  )
}

export default RentalsList;