import React, { Component } from "react";
import { numeral } from "../../../common/constants";
import LocationSelector from "../../../common/search_components/AppcarLocationSelector";
import iconInfo from "images/Icons/ico-info.png";
import ToolTip, { LoadToolTips } from "../../../common/components/ToolTip";
import FixedMap from "../../../common/components/fixed_map/FixedMap";
import markerUrl from "images/Icons/PickupIcon.png";

export default class RentalInfo extends Component {
  state = {
    addMoreLocations:
      (this.props.data.pricing.extra_locations || []).length > 0,
    newKey: 0,
  };

  componentDidUpdate() {
    LoadToolTips();
  }

  componentDidMount() {
    LoadToolTips();
  }

  handleChangeLocation = ({ success, lat, lon, address }) => {
    if (success) {
      this.props.handleChangeLocation({
        latitude: lat,
        longitude: lon,
        name: address,
      });
    }
    this.props.closeSpinner();
  };

  loadMoreLocations = (location) => {
    if (location && location.name) this.setState({ addMoreLocations: true });
  };

  handleChangeExtraLocation = (index, { success, lat, lon, address }) => {
    if (success) {
      let extraLocations = this.props.data.pricing.extra_locations || [];
      const isAlreadyIn = extraLocations.find(
        (extraLocation) => extraLocation.location.name == address
      );
      if (!isAlreadyIn) {
        let actualValue = extraLocations[index];
        if (actualValue && actualValue.location) {
          actualValue.location.name = address;
          actualValue.location.latitude = lat;
          actualValue.location.longitude = lon;
          // } else {
          //   this.setState({ newKey: newKey + 1 });
          //   actualValue = {
          //     price: 0,
          //     location: {
          //       name: address,
          //       latitude: lat,
          //       longitude: lon,
          //     },
          //   };
        }
        extraLocations[index] = actualValue;
        extraLocations = extraLocations.filter((x) => x);
        this.props.handleChangePricing("extra_locations", extraLocations);
      }
    }
    this.props.closeSpinner();
  };

  handleChangePriceToExtraLocation = (index, value) => {
    let extraLocations = this.props.data.pricing.extra_locations || [];
    let actualValue = extraLocations[index];
    if (actualValue && actualValue.location && actualValue.location.name) {
      const cleanValue = value.replace(/\D/g, "") || "0";
      actualValue.price = parseInt(cleanValue);
      extraLocations[index] = actualValue;
    }
    this.props.handleChangePricing("extra_locations", extraLocations);
  };

  removeExtraLocations = (index) => {
    let extraLocations = this.props.data.pricing.extra_locations || [];
    extraLocations.splice(index, 1);
    this.props.handleChangePricing("extra_locations", extraLocations);
  };

  render() {
    const {
      data,
      isSubmitting,
      handleSubmit,
      handleChangeInformation,
      handleChangePricing,
      loadSpinner,
      handleChangeSecurityBox,
      boxCode,
    } = this.props;
    const description = data.information && data.information.description;
    const location = data.location
      ? data.location
      : {
          name: "",
          latitude: 0.0,
          longitude: 0.0,
        };
    const extraLocations = data.pricing.extra_locations || [];
    let extraLocationsContainer = null;
    const moreLocations =
      location && location.name ? (
        <p
          className="mt-3 mb-2 fs-12 fw-700 cursor-pointer"
          onClick={() => this.loadMoreLocations(location)}
        >
          <span className="fs-16 text-appcar-primary fw-900">+</span>
          <span className="text-appcar-primary fw-900 ml-2">
            Agregar ubicaciones adicionales
          </span>
        </p>
      ) : null;
    if (this.state.addMoreLocations) {
      if (
        extraLocations.length == 0 ||
        extraLocations[extraLocations.length - 1].location.name != ""
      )
        extraLocations.push({
          price: 0,
          location: {
            name: "",
            latitude: 0,
            longitude: 0,
          },
        });
      extraLocationsContainer = extraLocations.map((extraLocation, i) => {
        const exprice = extraLocation.price;
        const exlocation = extraLocation.location;
        return (
          <div className="row align-items-end" key={i}>
            <div className="col pr-1">
              <div className="form-group">
                <LocationSelector
                  countries={["cl"]}
                  handleSelectLocation={(location) =>
                    this.handleChangeExtraLocation(i, location)
                  }
                  placeholder="Buscar dirección..."
                  label="Dirección adicional"
                  airportsFirst={false}
                  loadSpinner={loadSpinner}
                  labelClass="label-inside"
                  address={exlocation.name}
                  inputClass="input-autocomplete-places fw-400"
                  mainInputClass="form-control input-location-search appcar-input"
                  iconOrder="right"
                />
              </div>
            </div>
            <div className="col-3 pl-0 pr-1">
              <div className="form-group">
                <label className="label-inside">Precio</label>
                <input
                  type="text"
                  value={numeral(exprice).format("$0,0")}
                  className="form-control appcar-input no-min-width"
                  onChange={(e) =>
                    this.handleChangePriceToExtraLocation(i, e.target.value)
                  }
                />
              </div>
            </div>
            <div className="col-auto pl-0">
              <i
                className={`mdi mdi-trash-can fs-20 text-appcar-${
                  i == extraLocations.length - 1
                    ? "light cursor-not-allowed"
                    : "primary cursor-pointer"
                }`}
                style={{ position: "relative", bottom: 5 }}
                onClick={() => {
                  if (i != extraLocations.length - 1)
                    this.removeExtraLocations(i);
                }}
              ></i>
            </div>
          </div>
        );
      });
    }

    const mapContainer =
    location.latitude && location.longitude ? (
      <div className="my-3 vehicle-location-map">
        <FixedMap
          lat={location.latitude}
          lng={location.longitude}
          markerUrl={markerUrl}
          height={190}
          canZoom={false}
          gestureHandling="none"
        />
      </div>
    ) : null;

    return (
      <>
        <div className="row align-items-center">
          <div className="col">
            <label
              htmlFor="information_description"
              className="fs-13 fw-800 text-appcar-primary"
            >
              Describe tu auto
            </label>
          </div>
          <div className="col-auto ml-2 pb-1">
            <ToolTip
              title="Describe tu auto"
              tooltipHTML="Aquí puedes incorporar un resumen de las cualidades que quieras destacar de tu auto. Por ejemplo, “Auto familiar”, “Auto seguro”, “Especial para viajes en carretera”, “Auto de fácil manejo” o lo que prefieras."
              containerHTML={<img src={iconInfo} width={20} className="ml-1" />}
              id="ttip_rental_information"
            />
          </div>
        </div>
        <div className="form-group mb-4">
          <textarea
            name="information[description]"
            id="information_description"
            className="form-control appcar-input"
            style={{ resize: "none", height: 140 }}
            maxLength={350}
            value={description}
            onChange={(e) =>
              handleChangeInformation(e.target.value, "description")
            }
            cols={7}
          />
          <div className="row justify-content-end">
            <div className="col-auto">
              <span className="fs-9 fw-400">(Máx. 350 caracteres)</span>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col">
            <label className="fs-13 fw-800 text-appcar-primary">
              Puntos de recogida y devolución
            </label>
          </div>
          <div className="col-auto ml-2">
            <ToolTip
              title="Puntos de recogida y devolución"
              tooltipHTML="Registra la dirección exacta donde entregarás el auto<br/><br/>Agrega direcciones adicionales con un cobro extra por cada tramo de recogida y/o devolución para el Driver.<br/><br/>Te sugerimos agregar entrega y devolución en aeropuertos o terminales. Es comúnmente requerido por Drivers."
              containerHTML={<img src={iconInfo} width={20} className="ml-1" />}
              id="ttip_rental_location"
            />
          </div>
        </div>
        <div className="form-group">
          <LocationSelector
            countries={["cl"]}
            handleSelectLocation={this.handleChangeLocation}
            placeholder="Buscar dirección..."
            label="Dirección principal"
            airportsFirst={false}
            loadSpinner={loadSpinner}
            labelClass="label-inside"
            address={location.name}
            inputClass="input-autocomplete-places fw-400"
            mainInputClass="form-control input-location-search appcar-input"
            iconOrder="right"
          />
        </div>
        {mapContainer}
        {moreLocations}
        {extraLocationsContainer}
        <div className="mt-3 row align-items-center">
          <div className="col">
            <label className="fs-13 fw-800 text-appcar-primary">
              Caja de seguridad
            </label>
          </div>
          <div className="col-auto ml-2">
            <ToolTip
              title="Caja de seguridad"
              tooltipHTML="Si quieres hacer entregas no presenciales de tu auto, puedes dejar las llaves en una caja de seguridad que te proporcionaremos, acá tienes que elegir qué clave (de 4 dígitos diferentes) quieres usar."
              containerHTML={<img src={iconInfo} width={20} className="ml-1" />}
              id="ttip_rental_security_box"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="label-inside">Clave caja</label>
          <input
            type="text"
            maxLength={4}
            value={boxCode}
            className="form-control appcar-input"
            onChange={(e) => {
              const cleanValue = e.target.value.replace(/\D/g, "");
              handleChangeSecurityBox(cleanValue);
            }}
          />
        </div>
        <div className="text-center my-4">
          <button
            type="button"
            className="btn btn-appcar-primary pt-2 pb-2"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Continuar
          </button>
        </div>
      </>
    );
  }
}
