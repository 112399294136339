import React from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import markerIcon from "images/MapMarkerActive.png";
import styles from "../map/AppcarStyles.json";

const Map = ({ onIdle, children, style, ...options }) => {
  const ref = React.useRef(null);
  const [map, setMap] = React.useState();

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  React.useEffect(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  React.useEffect(() => {
    if (map) {
      window.google.maps.event.clearListeners(map, "idle");

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onIdle]);

  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

const Marker = (options) => {
  const [marker, setMarker] = React.useState();
  const contentRef = React.useRef(null);

  React.useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};

const FixedMap = ({
  lat,
  lng,
  height = 420,
  canZoom = true,
  gestureHandling = "greedy",
}) => (
  <Wrapper apiKey="AIzaSyCcDU72koQ3MVM433K4amLbHAiGgzlRRjs">
    <Map
      center={{ lat: lat, lng: lng }}
      zoom={16}
      style={{ height }}
      styles={styles}
      mapTypeControl={false}
      fullscreenControl={false}
      streetViewControl={false}
      zoomControl={canZoom}
      gestureHandling={gestureHandling}
      keyboardShortcuts={false}
      scrollwheel={canZoom}
      disableDoubleClickZoom={true}
    >
      <Marker
        position={{ lat, lng }}
        icon={{
          url: markerIcon,
          scaledSize: new google.maps.Size(37, 42),
          anchor: new google.maps.Point(19, 42),
        }}
      />
    </Map>
  </Wrapper>
);

export default FixedMap;
