import React, { Component } from "react";

class ImageCarousel extends Component {
  state = {
    images: this.props.images,
    currentIndex: 0,
    translateValue: 0,
    modalIndex: 0
  };

  interv = null;

  componentDidMount() {
    if (this.props.autoChange) this.interv = setInterval(this.auto, 5000);
  }

  auto = () => {
    const el = document.getElementsByClassName("next-arrow image-carousel-arrow")[0];
    if (!!el) el.click();
  }

  goToPrevSlide = e => {
    if (this.props.images.length == 0) return;
    const parent = e.currentTarget.parentElement;
    clearInterval(this.interv);
    this.interv = setInterval(this.auto, 5000);
    if (this.state.currentIndex == 0) {
      return this.setState({
        currentIndex: this.props.images.length - 1,
        translateValue: -this.slideWidth(parent) * (this.props.images.length - 1)
      });
    }

    this.setState(state => ({
      currentIndex: state.currentIndex - 1,
      translateValue: state.translateValue + this.slideWidth(parent)
    }));
  };

  goToNextSlide = e => {
    if (this.props.images.length == 0) return;
    const parent = e.currentTarget.parentElement;
    clearInterval(this.interv);
    this.interv = setInterval(this.auto, 5000);
    if (this.state.currentIndex == this.props.images.length - 1) {
      return this.setState(
        {
          currentIndex: 0,
          translateValue: 0
        },
        () => {
          if (!!this.props.canDownload) {
            this.props.reportIndex(0, this.props.name);
          }
        }
      );
    }

    this.setState(
      state => ({
        currentIndex: state.currentIndex + 1,
        translateValue: state.translateValue + -this.slideWidth(parent)
      }),
      () => {
        if (!!this.props.canDownload) {
          this.props.reportIndex(this.state.currentIndex, this.props.name);
        }
      }
    );
  };

  goToPrevSlideModal = () => {
    if (this.props.images.length == 0) return;
    if (this.state.modalIndex == 0) {
      return this.setState({
        modalIndex: this.props.images.length - 1
      });
    }

    this.setState(state => ({
      modalIndex: state.modalIndex - 1
    }));
  };

  goToNextSlideModal = () => {
    if (this.props.images.length == 0) return;
    if (this.state.modalIndex == this.props.images.length - 1) {
      return this.setState({
        modalIndex: 0
      });
    }

    this.setState(state => ({
      modalIndex: state.modalIndex + 1
    }));
  };

  showModal = () => {
    $(`#${this.props.modalName}`).modal("show");
  };

  slideWidth = el => {
    return el ? el.clientWidth : 0;
  };

  render() {
    const { images, canViewFullSize, modalName, slideChildren } = this.props;
    const arrChildren = slideChildren || [];
    const modalImage =
      images.length > 0 && canViewFullSize ? (
        <div
          className="modal fade"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
          id={modalName}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-center py-0">
                <img
                  src={this.props.images[this.state.modalIndex].url}
                  className="img-fluid"
                />
                <LeftArrow goToPrevSlide={this.goToPrevSlideModal} />
                <RightArrow goToNextSlide={this.goToNextSlideModal} />
                {<ImageCounter changeState={true} actual={this.props.images.length > 0 ? this.state.modalIndex + 1 : 0} total={this.props.images.length} />}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light"
                  data-dismiss="modal"
                  data-bs-dismiss="modal"
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null;
    return (
      <div
        className={
          this.props.changeState
            ? "image-carousel-change-state"
            : "image-carousel"
        }
      >
        <div
          className="image-carousel-wrapper"
          style={{
            transform: `translate(${this.state.translateValue}px)`,
            transition: "transform ease-out 0.5s",
            height: this.props.height || "100%"
          }}
          onClick={this.showModal}
        >
          {this.props.images.map((image, i) => (
            <Slide key={i} image={image.url} modalName={modalName} slideClass={this.props.slideClass} slideChild={arrChildren[i] || null} bgPosition={this.props.bgPosition} />
          ))}
        </div>
        <LeftArrow goToPrevSlide={this.goToPrevSlide} />
        <RightArrow goToNextSlide={this.goToNextSlide} />
        {this.props.imageCounterHide ?
          null : (
            <ImageCounter
              changeState={this.props.changeState}
              actual={
                this.props.images.length > 0 ? this.state.currentIndex + 1 : 0
              }
              total={this.props.images.length}
            />)}
        {modalImage}
      </div>
    );
  }
}

const Slide = ({ image, slideClass, slideChild, bgPosition = "50% 50%" }) => {
  const styles = {
    backgroundImage: `url("${image}")`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: bgPosition
  };
  return <div className={"image-carousel-slide " + slideClass} style={styles}>{slideChild}</div>;
};

const LeftArrow = props => {
  return (
    <div
      className="back-arrow image-carousel-arrow"
      onClick={props.goToPrevSlide}
    >
      <i className="arrow-left" />
    </div>
  );
};

const RightArrow = props => {
  return (
    <div
      className="next-arrow image-carousel-arrow"
      onClick={props.goToNextSlide}
    >
      <i className="arrow-right" />
    </div>
  );
};

const ImageCounter = ({ actual, total, changeState }) => {
  const styles = {
    backgroundColor: "#000"
  };
  return (
    <div
      className={
        changeState ? "image-carousel-counter-cs" : "image-carousel-counter"
      }
    >
      {actual} / {total}
    </div>
  );
};

export default ImageCarousel;
