import React, { Component, Fragment } from "react";
import Spinner from "../../../common/Spinner";
import axios from "axios";
import Select from "react-select";
import FlashMessage from "../../../common/FlashMessage";
import { AxiosBackofficeInterceptor } from "../../../common/axios_interceptor";
import { constants } from "../../../common/constants";
import Table from "../../../common/components/table/Table";
import debounce from "lodash/debounce";
import Alert from "../../../common/components/alert/Alert";

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state: "",
      filter: "",
      users: this.props.users,
      user_states: this.props.userStates,
      isSubmitting: false,
      alert: {
        showMessage: false,
        messageType: undefined,
        messages: [],
        messageTitle: undefined,
      },
      search_info: {
        state: "",
      },
      orderStatus: {
        name: "id",
        direction: "desc",
      },
      paginationInformation: this.props.paginationInformation || {
        limit: constants.DEFAULT_RESULTS_PER_PAGE,
        last: 0,
        total: this.props.users.length,
      },
      currentPage: 1,
      lastIds: [this.props.paginationInformation.last],
    };
    this.willUnmount = false;
    this.cancelToken = null;
    this.processRunning = 0;
    this.handleSubmit = debounce(this.handleSubmit, 400, { leading: true });
  }

  componentWillUnmount() {
    this.willUnmount = true;
  }

  componentDidMount() {
    const this_ = this;
    this_.willUnmount = false;
    this.cancelToken = axios.CancelToken.source();
    axios.interceptors.response.use(undefined, AxiosBackofficeInterceptor);
  }

  showMessage = (messageType, messageTitle, messages) =>
    this.setState((state) => {
      state.alert.showMessage = true;
      state.alert.messageTitle = messageTitle;
      state.alert.messages = messages;
      state.alert.messageType = messageType;
      return { alert: state.alert };
    },
    () => setTimeout(this.handleAlertClose, 3000)
  );

  handleAlertClose = () =>
    this.setState(state => {
      state.alert.showMessage = false;
      return { alert: state.alert };
    });

  handleSubmit = (e = null, direction = "after", resetPagination = false) => {
    if (e) e.preventDefault();
    this.processRunning += 1;
    this.setState({ isSubmitting: true });
    const { state, paginationInformation, filter } = this.state;
    let search_info = {
      state: state || "",
    };
    let last = paginationInformation.last;
    let lastIds = this.state.lastIds;
    if (direction == "before") {
      lastIds.pop();
      lastIds.pop();
      last = lastIds.slice(this.state.lastIds.length - 1);
    }
    if (resetPagination) {
      lastIds = [];
      last = 0;
    }
    let paginationParams = `limit=${paginationInformation.limit}`;
    if (!resetPagination) paginationParams += `&last=${last}`;

    const stateParams = state || state === 0 ? `&state=${state}` : "";
    const filterParam = filter ? `&filter=${filter}` : "";

    axios({
      method: "get",
      url: `/backoffice/owners.json?${paginationParams}${stateParams}${filterParam}`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        const users = response.data.users;
        const paginationInformation = response.data.paginationInformation;
        lastIds.push(paginationInformation.last);
        if (!this.willUnmount)
          this.setState({ users, search_info, paginationInformation, lastIds });
      })
      .catch((error) => {
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ]);
      })
      .then(() => {
        this.processRunning -= 1;
        if (this.processRunning == 0) {
          this.setState({ isSubmitting: false });
        }
      });
  };

  handleChangeState = (option) => {
    const state = option ? option.value : "";
    this.setState({ state, currentPage: 1 }, () => {
      this.handleSubmit(null, "last", true);
    });
  };

  onSearchChange = (filter) => {
    this.setState({ filter, currentPage: 1 }, () => {
      this.handleSubmit(null, "last", true);
    });
  };

  onLimitChange = (limit) => {
    this.setState(
      (state) => {
        state.paginationInformation.limit = limit;
        return {
          paginationInformation: state.paginationInformation,
          currentPage: 1,
        };
      },
      () => {
        this.handleSubmit(null, "last", true);
      }
    );
  };

  onPageChange = (currentPage, direction) => {
    this.setState({ currentPage }, () => {
      this.handleSubmit(null, direction);
    });
  };

  onOrderChange = (orderStatus) => {
    this.setState({ orderStatus }, () => {
      // TODO: Acá se debe llamar la función para buscar resultados
    });
  };

  render() {
    moment.locale("es");

    const {
      users,
      search_info,
      orderStatus,
      paginationInformation,
      currentPage,
      state,
      alert,
    } = this.state;
    const { messageTitle, messageType, messages, showMessage } = alert;

    let rows = users.sort((a, b) => {
      // Ordenamiento por id
      const aId = parseInt(a.id);
      const bId = parseInt(b.id);

      // Mayor a menor (Más futuro a más pasado)
      return bId - aId;
    });

    const { last, limit, total } = paginationInformation;

    const searchInfo = (
      <div className="alert light mw-100">
        {`Mostrando ${rows.length} resultados de un total de ${total}`}
        {search_info.state !== ""
          ? ` con estado ${
              this.state.user_states.find((x) => x.id == search_info.state)
                .description
            }`
          : ""}
      </div>
    );

    const selected_state = this.state.user_states.find(
      (state) => state.id === this.state.state
    );
    const selected_option_state = selected_state
      ? {
          value: selected_state.id,
          label: selected_state.description,
        }
      : undefined;
    const state_options = this.state.user_states.map((state) => {
      return {
        value: state.id,
        label: state.description,
      };
    });

    const columnDefinitions = [
      {
        name: "id",
        label: "ID",
        orderable: false,
      },
      {
        name: "name",
        label: "Nombre",
        orderable: false,
      },
      {
        name: "email",
        label: "Email",
        orderable: false,
      },
      {
        name: "phone",
        label: "Teléfono",
        orderable: false,
      },
      {
        name: "state",
        label: "Estado",
        orderable: false,
      },
      {
        name: "actions",
        label: "",
        orderable: false,
      },
    ];

    const data = rows.map((user) => {
      return {
        id: user.id,
        name: user.name + " " + user.last_name,
        email: user.email,
        phone: user.phone_number,
        state: user.state && user.state.description,
        actions: (
          <a
            href={`/backoffice/owners/${user.id}`}
            className="btn btn-appcar-primary btn-sm"
            rel="noreferrer noopener"
            target="_blank"
          >
            Detalle
          </a>
        ),
      };
    });
    const table = (
      <Table
        id="table-users"
        noResults={
          this.state.isSubmitting
            ? "Realizando Búsqueda"
            : "No hay resultados para mostrar..."
        }
        columns={columnDefinitions}
        data={data}
        hasFooter={true}
        tableClass="table dataTable table-striped"
        searchable={true}
        onSearchChange={this.onSearchChange}
        orderStatus={orderStatus}
        paginationInformation={paginationInformation}
        onLimitChange={this.onLimitChange}
        onPageChange={this.onPageChange}
        onOrderChange={this.onOrderChange}
        currentPage={currentPage}
        showPageSelector={false}
      />
    );
    const stateParams = state ? `&state=${state}` : "";
    return (
      <Fragment>
        <Spinner loading={this.state.isSubmitting} />
        <Alert
          alertClass={messageType}
          isShowing={showMessage}
          messages={messages}
          title={messageTitle}
          onClose={this.handleAlertClose}
        />
        <div className="row my-3 align-items-end">
          <div className="col">
            <div className="form-group">
              <label>Selecciona el estado</label>
              <Select
                value={selected_option_state}
                options={state_options}
                placeholder="Selecciona..."
                noOptionsMessage={() => "No existen estados aún..."}
                onChange={this.handleChangeState}
                classNamePrefix="RS-FIX"
                className="react-select-fix"
                isClearable={true}
              />
            </div>
          </div>

          <div className="col-auto">
            <button
              className="btn btn-appcar-primary btn-sm py-0 mb-1"
              onClick={() => this.handleSubmit(null, "last", true)}
            >
              <i className="mdi mdi-chevron-right fs-22" />
            </button>
          </div>
        </div>
        {
          // MA1-T403 Comentar botón "Exportar a excel" en tablas de backoffice
          /* <div className="row justify-content-end my-2">
          <div className="col-auto">
            <a
              href={`/backoffice/owners/export_to_xlsx?${stateParams}`}
              className="btn btn-success"
            >
              <i className="mdi mdi-microsoft-excel"></i> Exportar a excel
            </a>
          </div>
        </div> */
        }
        {searchInfo}
        <div className="mb-3">{table}</div>
      </Fragment>
    );
  }
}

export default UserList;
