import React, { Component, Fragment } from "react";
import RangeSlider from "../../../common/components/price_range/RangeSlider";
import {
  constants,
  OptionsDistance,
  OptionsSeats,
  numeral,
  OptionsOrder
} from "../../../common/constants";
import SelectWithCheckbox from "../../../common/components/select/SelectWithCheckbox";
import SelectWithRadio from "../../../common/components/select/SelectWithRadio";
import FiltersMobile from "./FiltersMobile";

const Filters = ({
  selectedExtras,
  selectedFeatures,
  selectedCategories,
  selectedTransmissions,
  selectedSeats,
  selectedKm,
  minPrice,
  minPriceUpdate,
  maxPrice,
  maxPriceUpdate,
  optionsExtras,
  optionsFeatures,
  optionsCategories,
  optionsTransmissions,
  isShowingRange,
  showRange,
  changeRange,
  updateRange,
  handleChange,
  resetFilters,
  selectedOrder,
  showMobileMap
}) => {
  const selectExtras = (
    <div className="col-12 col-md-3 col-xl px-1 px-lg-2">
      <div className="form-group">
        <label className="label-inside">Extras</label>
        <SelectWithCheckbox
          value={selectedExtras}
          isMulti
          options={optionsExtras}
          isClearable={true}
          placeholder="Selecciona"
          onChange={(option) => handleChange("selectedExtras", option)}
          closeMenuOnSelect={false}
          classNamePrefix="RS-FIX"
          className="react-select-fix"
          hideSelectedOptions={false}
          backspaceRemovesValue={false}
          isSearchable={false}
        />
      </div>
    </div>
  );

  const selectFeatures = (
    <div className="col-12 col-md-3 col-xl px-1 px-lg-2">
      <div className="form-group">
        <label className="label-inside">Características</label>
        <SelectWithCheckbox
          value={selectedFeatures}
          isMulti
          options={optionsFeatures}
          isClearable={true}
          placeholder="Selecciona"
          onChange={(option) => handleChange("selectedFeatures", option)}
          closeMenuOnSelect={false}
          classNamePrefix="RS-FIX"
          className="react-select-fix"
          hideSelectedOptions={false}
          backspaceRemovesValue={false}
          isSearchable={false}
        />
      </div>
    </div>
  );

  const selectCategories = (
    <div className="col-12 col-md-3 col-xl px-1 px-lg-2">
      <div className="form-group">
        <label className="label-inside">Categoría</label>
        <SelectWithCheckbox
          value={selectedCategories}
          isMulti
          options={optionsCategories}
          isClearable={true}
          placeholder="Selecciona"
          onChange={(option) => handleChange("selectedCategories", option)}
          closeMenuOnSelect={false}
          classNamePrefix="RS-FIX"
          className="react-select-fix"
          hideSelectedOptions={false}
          backspaceRemovesValue={false}
          isSearchable={false}
        />
      </div>
    </div>
  );

  const selectOrder = (
    <div className="col-12 col-md-3 col-xl px-1 px-lg-2">
      <div className="form-group">
        <label className="label-inside">Ordenar por</label>
        <SelectWithRadio
          value={selectedOrder}
          options={OptionsOrder}
          isClearable={true}
          placeholder="Selecciona"
          onChange={(option) => handleChange("selectedOrder", option)}
          closeMenuOnSelect={true}
          classNamePrefix="RS-FIX"
          className="react-select-fix"
          hideSelectedOptions={false}
          backspaceRemovesValue={false}
          isSearchable={false}
        />
      </div>
    </div>
  );

  const selectTransmissions = (
    <div className="col-12 col-md-3 col-xl px-1 px-lg-2">
      <div className="form-group">
        <label className="label-inside">Transmisión</label>
        <SelectWithRadio
          value={selectedTransmissions}
          options={optionsTransmissions}
          isClearable={true}
          placeholder="Ambas"
          onChange={(option) => handleChange("selectedTransmissions", option)}
          closeMenuOnSelect={true}
          classNamePrefix="RS-FIX"
          className="react-select-fix"
          hideSelectedOptions={false}
          backspaceRemovesValue={false}
          isSearchable={false}
        />
      </div>
    </div>
  );

  const selectSeats = (
    <div className="col-12 col-md-3 col-xl px-1 px-lg-2">
      <div className="form-group">
        <label className="label-inside">Asientos</label>
        <SelectWithRadio
          value={selectedSeats}
          options={OptionsSeats}
          isClearable={true}
          placeholder="Todos"
          onChange={(option) => handleChange("selectedSeats", option)}
          closeMenuOnSelect={true}
          classNamePrefix="RS-FIX"
          className="react-select-fix"
          hideSelectedOptions={false}
          backspaceRemovesValue={false}
          isSearchable={false}
        />
      </div>
    </div>
  );

  const selectKm = (
    <div className="col-12 col-md-3 col-xl px-1 px-lg-2">
      <div className="form-group">
        <label className="label-inside">Kilometraje diario</label>
        <SelectWithRadio
          value={selectedKm}
          options={OptionsDistance}
          isClearable={true}
          placeholder="Selecciona"
          onChange={(option) => handleChange("selectedKm", option)}
          closeMenuOnSelect={true}
          classNamePrefix="RS-FIX"
          className="react-select-fix"
          hideSelectedOptions={false}
          backspaceRemovesValue={false}
          isSearchable={false}
        />
      </div>
    </div>
  );

  const rangeContainer = isShowingRange ? (
    <div className="price-range-container" id="range-container">
      <div className="label-price">
        <span>{numeral(minPriceUpdate).format("$0,0")}</span>
        <span>{numeral(maxPriceUpdate).format("$0,0")}</span>
      </div>
      <RangeSlider
        domain={[constants.MIN_PRICE, constants.MAX_PRICE]}
        step={constants.RANGE_STEPS}
        defaultValues={[constants.MIN_PRICE, constants.MAX_PRICE]}
        values={[minPrice, maxPrice]}
        onChange={changeRange}
        onUpdate={updateRange}
      />
    </div>
  ) : null;

  const priceRange = (
    <div className="col-12 col-md-3 col-xl px-1 px-lg-2">
      <div className="form-group">
        <label className="label-inside">Precio diario</label>
        <div
          className="appcar-input-light price-range"
          id="range-input"
          onClick={showRange}
        >
          <span>
            {numeral(minPriceUpdate).format("$0,0")} a{" "}
            {numeral(maxPriceUpdate).format("$0,0")}
            {maxPriceUpdate == constants.MAX_PRICE ? "+" : ""}
          </span>
          <i className="mdi mdi-chevron-down" />
        </div>
        {rangeContainer}
      </div>
    </div>
  );
  return (
    <Fragment>
      <div className="row d-none d-sm-flex">
        {selectOrder}
        {priceRange}
        {selectCategories}
        {selectSeats}
        {selectTransmissions}
        {selectKm}
        {selectFeatures}
        {selectExtras}
      </div>
      <FiltersMobile
        updateRange={updateRange}
        changeRange={changeRange}
        minPrice={minPrice}
        minPriceUpdate={minPriceUpdate}
        maxPrice={maxPrice}
        maxPriceUpdate={maxPriceUpdate}
        selectedExtras={selectedExtras}
        selectedFeatures={selectedFeatures}
        selectedCategories={selectedCategories}
        selectedTransmissions={selectedTransmissions}
        selectedSeats={selectedSeats}
        selectedKm={selectedKm}
        optionsExtras={optionsExtras}
        optionsFeatures={optionsFeatures}
        optionsCategories={optionsCategories}
        optionsTransmissions={optionsTransmissions}
        handleChange={handleChange}
        resetFilters={resetFilters}
        selectedOrder={selectedOrder}
        showMobileMap={showMobileMap}
      />
    </Fragment>
  );
};

export default Filters;
