import React from "react";

const ShareButtons = ({ url, isShowing }) => (
  <div className={`share-social ${isShowing ? "" : "d-none"}`}>
    <div
      className="fb-share-button"
      data-href={url}
      data-layout="button"
      data-size="small"
    >
      <a
        target="_blank"
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          url
        )}&amp;src=sdkpreparse"`}
        className="fb-xfbml-parse-ignore"
      >
        Share
      </a>
    </div>
  </div>
);

export default ShareButtons;
