import React, { Component } from "react";
import TableBody from "./TableBody";
import TableHead from "./TableHead";
import TableLimitSelector from "./TableLimitSelector";
import TablePageNavigation from "./TablePageNavigation";
import TableSearch from "./TableSearch";

export default class Table extends Component {
  state = {};

  changeOrder = orderStatus => {
    changeOrder(orderStatus);
  }

  render() {
    const {
      id,
      columns,
      data,
      hasFooter,
      tableClass,
      paginationInformation,
      searchable,
      noResults,
      onSearchChange,
      orderStatus,
      onLimitChange,
      onPageChange,
      onOrderChange,
      showPageSelector,
      currentPage
    } = this.props;

    const search = searchable ? <TableSearch onTextChange={onSearchChange} /> : null;
    const footer = hasFooter ? <TableHead columnDefinitions={columns} orderStatus={orderStatus} onOrderClick={onOrderChange} /> : null;
    return (
      <>
        <div className="row mb-2">
          <div className="col-sm-12">
            {search}
          </div>
        </div>
        <table id={id} className={tableClass}>
          <TableHead columnDefinitions={columns} orderStatus={orderStatus} onOrderClick={onOrderChange} />
          <TableBody columns={columns} data={data} noResults={noResults} />
          {footer}
        </table>
        <TablePageNavigation 
          paginationInformation={paginationInformation} 
          onPageChange={onPageChange} 
          showPageSelector={showPageSelector} 
          currentPage={currentPage}
        />
        <div className="row mb-2">
          <div className="col-sm-12 col-md-6">
            <TableLimitSelector onLimitChange={onLimitChange} paginationInformation={paginationInformation} />
          </div>
        </div>
      </>
    )
  }
}
