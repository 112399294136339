import React from "react";
import ToolTip, { LoadToolTips } from "../../common/components/ToolTip";
import SuperOwnerBadge from "images/Icons/super_owner.svg";

const SuperOwnerButton = () => {
  LoadToolTips();
  const buttonTooltip = (
    <div className="btn-tooltip-super-vehicle ml-3">
      <img src={SuperOwnerBadge} width={25} className="ml-1" style={{marginTop: "-5px"}} /> Super-Owner
    </div>
  );
  return (
    <>
      <ToolTip
        title="Super-Owner"
        tooltipHTML="Este auto pertenece a un Super-Owner. Estos dueños de auto tienen mucha experiencia, cuentan con calificaciones excelentes, se esfuerzan y comprometen al máximo para ofrecer un excelente servicio, ¡te lo recomendamos!"
        containerHTML={buttonTooltip}
        id="ttip_rental_max_km_"
        placement="right"
      />
    </>
  );
};

export default SuperOwnerButton;
