import React from "react";
import iconWink from "images/IconWink.png";

const ThanksModalRental = (action) => (
  <div
    className="modal fade"
    id="thanksModal"
    aria-hidden="true"
    aria-labelledby="thanksModalLabel"
    tabIndex={-1}
  >
    <div className="modal-dialog modal-dialog-centered modal-appcar">
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="btn-m-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            x
          </button>
          <h5 className="modal-title" id="thanksModalLabel">
            &nbsp;
          </h5>
        </div>
        <div className="modal-body" style={{ marginBottom: 10 }}>
          <div className="row justify-content-center">
            <div className="col-auto text-center">
              <img src={iconWink} width={72} />
              <h5 className="mt-2 mb-4 fs-30 fw-900">¡Gracias!</h5>
              <p
                className="fs-15 fw-600"
                style={{ maxWidth: 270, marginTop: 36, marginBottom: 36 }}
              >
                La publicación de tu auto ha sido
                <br />
                actualizada.
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-auto">
              <a
                href="/vehicles?success=1"
                className="btn btn-appcar-primary pt-2 pb-2"
                style={{ width: 240 }}
              >
                Aceptar
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ThanksModalRental;
