import React, { Component } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { AxiosApplicationInterceptor } from "../../common/axios_interceptor";
import LandingPageVehicleCard from "../../common/LandingPageVehicleCard";
import Spinner from "../../common/Spinner";
axios.interceptors.response.use(undefined, AxiosApplicationInterceptor);

class FeaturedRentals extends Component {
  state = {
    rentals: [],
    ui: {
      isSubmitting: false,
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined
    },
    old_count: 0,
    new_count: 0,
    last: 0
  };

  willUnmount = false;

  componentDidMount() {
    this.willUnmount = false;
    this.setState(state => {
      state.ui.isSubmitting = true;
      state.old_count = state.new_count;
      return { ui: state.ui, old_count: state.old_count };
    });
    this.cancelToken = axios.CancelToken.source();
    const from = dayjs()
      .add(10, "second")
      .format();
    const until = dayjs()
      .add(1, "hour")
      .format();
    const last = this.state.last;
    axios(
      {
        method: "get",
        url: `/rentals.json?from=${encodeURIComponent(
          from
        )}&until=${encodeURIComponent(until)}&limit=${encodeURIComponent(
          4
        )}&last=${encodeURIComponent(last)}`,
        responseType: "json",
        headers: {
          "Content-Type": "application/json"
        }
      },
      { cancelToken: this.cancelToken.token }
    )
      .then(response => {
        if (!this.willUnmount)
          this.setState(state => {
            state.rentals = response.data[0][1];
            state.new_count = (state.rentals || []).length;
            state.last = response.data[2][1];
            return {
              rentals: state.rentals,
              new_count: state.new_count,
              last: state.last
            };
          });
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log(error);
        } else if (!this.willUnmount)
          this.showMessage({
            showMessage: true,
            messageType: "alert-danger",
            messages: ["Ha ocurrido un error inesperado"],
            messageTitle: "Error"
          });
      })
      .finally(() => {
        if (!this.willUnmount)
          this.setState(state => {
            state.ui.isSubmitting = false;
            return { ui: state.ui };
          });
      });
  }

  componentWillUnmount() {
    this.cancelToken && this.cancelToken.cancel();
    this.willUnmount = true;
  }

  showMessage = msg => {
    this.setState(state => {
      state.ui.showMessage = msg.showMessage;
      state.ui.messageType = msg.messageType;
      state.ui.messages = msg.messages;
      state.ui.messageTitle = msg.messageTitle;
      return { ui: state.ui };
    });
  };

  loadMore = () => {
    if (this.state.rentals.length < 16) {
      const limit = 4;
      const last = this.state.last;
      if (!this.willUnmount)
        this.setState(state => {
          state.ui.isSubmitting = true;
          return { ui: state.ui, old_count: state.new_count };
        });
      const from = dayjs()
        .add(10, "second")
        .format();
      const until = dayjs()
        .add(1, "hour")
        .format();
      axios({
        method: "get",
        url: `/rentals.json?from=${encodeURIComponent(
          from
        )}&until=${encodeURIComponent(until)}&limit=${encodeURIComponent(
          limit
        )}&last=${encodeURIComponent(last)}`,
        responseType: "json",
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          if (!this.willUnmount) {
            const data = response.data;
            this.setState(state => {
              const rentals = response.data[0][1] || [];
              state.rentals.push(...rentals);
              state.new_count = (state.rentals || []).length;
              state.last = response.data[2][1];
              return {
                rentals: state.rentals,
                new_count: state.new_count,
                last: state.last
              };
            });
          }
        })
        .catch(error => {
          if (!this.willUnmount)
            this.showMessage({
              showMessage: true,
              messageType: "alert-danger",
              messages: ["Ha ocurrido un error inesperado"],
              messageTitle: "Error"
            });
        })
        .finally(() => {
          if (!this.willUnmount)
            this.setState(state => {
              state.ui.isSubmitting = false;
              return { ui: state.ui };
            });
        });
    }
    // } else {
    //   window.location.assign("/portada/search_rentals");
    // }
  };

  render() {
    let rentals = this.state.rentals.map((rental, i) => {
      rental.vehicle.associated_users = rental.vehicle.associated_users.map(
        user => {
          user.admin_rating = user.admin_rating || 0;
          user.admin_evaluations = user.admin_evaluations || 0;
          return user;
        }
      );
      rental.vehicle.vehicle_rating = rental.vehicle.vehicle_rating || 0;
      rental.vehicle.trips = rental.vehicle.trips || 0;
      rental.isFavorite = Math.random() >= 0.5;
      return rental;
    });
    const rental_count = rentals.length;
    const vehicleCards = rentals.map((rental, i) => {
      return (
        <div
          className="col-12 col-md-6 mb-3"
          key={i}
        >
          <LandingPageVehicleCard rental={rental} />
        </div>
      );
    });
    const empty_message = is_submitting => (
      <div className="col-8">
        <div
          className="fill alert alert-info fade show pr-5"
          style={{ display: "inline-block" }}
        >
          {is_submitting
            ? "Cargando publicaciones..."
            : "No se han encontrado resultados"}
        </div>
      </div>
    );
    const button_more = (
      <button
        className="btn search-button font-1-2 px-3 py-1"
        style={{ borderRadius: 8 }}
        disabled={
          this.state.ui.isSubmitting ||
          this.state.old_count == this.state.new_count
        }
        onClick={() => this.loadMore()}
      >
        {this.state.ui.isSubmitting
          ? "Cargando..."
          : this.state.old_count != this.state.new_count
            ? "Ver más publicaciones"
            : "No hay más publicaciones cercanas que mostrar"}
      </button>
    );
    const load_more =
      this.state.rentals.length < 16 ? (
        <div className="row justify-content-center">
          <div className="col-6 text-center">{button_more}</div>
        </div>
      ) : null;
    return (
      <React.Fragment>
        <div className="text-center">
          <Spinner loading={this.state.ui.isSubmitting} />
          <p className="slogan" style={{ lineHeight: 1.1 }}>
            Los usuarios de Appcar
          <br />
            <b>han preferido estos autos</b>
          </p>
          <p className="font-1-2">Mira una lista de nuestras publicaciones destacadas</p>
        </div>
        <div className="row justify-content-center my-3">
          {rental_count > 0
            ? vehicleCards
            : empty_message(this.state.ui.isSubmitting)}
        </div>
        {load_more}
      </React.Fragment>
    );
  }
}

export default FeaturedRentals;
