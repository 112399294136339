import React from "react";
import Select from "react-select";
import moment from "moment";

const DateTimePicker = ({ changeTime, pickerId, date, time, isDisabled = false }) => {
  let times = [];
  let start_time = 0;
  for (let i = 0; start_time < 24 * 60; i++) {
    let hour = Math.floor(start_time / 60);
    var minutes = (start_time % 60);
    times[i] = ("0" + hour).slice(-2) + ":" + ("0" + minutes).slice(-2);
    start_time = start_time + 15;
  }

  let options = times.map(tt => ({ label: tt, value: tt }));
  const ms = 1000 * 60 * 15;
  const rounded_moment = moment(new Date(Math.round(moment(time, "HH:mm").toDate().getTime() / ms) * ms));
  const rounded_time = rounded_moment.format("HH:mm");
  const selected_time = rounded_time ? options[options.findIndex(x => x.value === rounded_time)] : undefined;
  return (
    <div className={"search-date-picker form-control" + (isDisabled? " disabled" : "")}> 
      <i className="mdi mdi-calendar-month" style={{ fontSize: 16 }}></i>
      <div
        id={pickerId + (isDisabled ? "disabled" : "")}
        className="input-search_cars ml-0 mr-1 font-weight-bold"
        type="text"
      >{date} <i className="mdi mdi-chevron-down" style={{ fontSize: 16 }}></i></div>
      <i className="mdi mdi-clock-outline" style={{ fontSize: 16 }}></i>
      <Select
        value={selected_time}
        options={options}
        onChange={changeTime}
        classNamePrefix="RS-Time"
        className="react-select-fix-time"
        isDisabled={isDisabled}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => (<i className="mdi mdi-chevron-down" style={{ fontSize: 16 }}></i>)
        }}
        isSearchable={false}
      />
    </div>
  );
}


export default DateTimePicker;
