import React, { Component } from "react";
import ModalHeader from "./ModalHeader";
import ModalBody from "./ModalBody";
import ModalFooter from "./ModalFooter";

const Modal = ({
  title = "",
  id = "",
  hasHeader = true,
  hasBody = true,
  hasFooter = true,
  body = null,
  footer = null,
  closeable = true,
  dismissOnOutsideClick = true,
  labelledBy = "",
  modalClass = "",
  headerClass = "",
  bodyClass = "",
  footerClass = "",
  ...props
}) => {
  const backdrop = dismissOnOutsideClick ? "true" : "static";
  const modalHeader = hasHeader ?
    (
      <ModalHeader
        title={title}
        closeable={closeable}
        id={labelledBy}
        className={headerClass}
      />
    ) : null;
  const modalBody = hasBody ?
    (
      <ModalBody
        className={bodyClass}
        body={body}
      />
    ) : null;
  const modalFooter = hasFooter ?
    (
      <ModalFooter
        className={footerClass}
        footer={footer}
      />
    ) : null;
  return (
    <div
      id={id}
      data-backdrop={backdrop}
      aria-hidden="true"
      aria-labelledby={labelledBy}
      role="dialog"
      tabIndex={-1}
      key={id}
      {...props}
      className="modal fade"
    >
      <div className={"modal-dialog " + modalClass}>
        <div className="modal-content">
          {modalHeader}
          {modalBody}
          {modalFooter}
        </div>
      </div>
    </div>
  )
}

export default Modal;