import React from "react";
import VehicleWizard from "./form/VehicleWizard";

const VehiclesEdit = ({
  vehicle,
  fuelTypes,
  vehicleTypes,
  vehicleFeatures,
  vehicleCategories,
  vehicleTransmissions,
  activeStep,
}) => {
  const redirectToDashboard = () => {
    window.location.assign(`/vehicles`);
  };
  return (
    <>
      <VehicleWizard
        vehicle={vehicle}
        fuelTypes={fuelTypes}
        vehicleTypes={vehicleTypes}
        vehicleFeatures={vehicleFeatures}
        vehicleCategories={vehicleCategories}
        vehicleTransmissions={vehicleTransmissions}
        formAction={redirectToDashboard}
        activeStep={activeStep}
      />
    </>
  );
};

export default VehiclesEdit;
