import React from "react";

const ClearIndicator = (props) => {
  const {
    children = (
      <div className="fs-10 fw-700 text-appcar-primary">LIMPIAR</div>
    ),
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
      className="pl-0"
    >
      {children}
    </div>
  );
};

export default ClearIndicator;
