import React from "react";

const PageSelector = ({ number, onPageChange, isActualPage, limit }) => (
  <li 
    className={`table-page-selector${isActualPage ? " active" : ""}`}
    onClick={() => !isActualPage ? onPageChange((number - 1) * limit) : null}
  >
    {number}
  </li>
)

export default PageSelector;