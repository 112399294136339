import React, { Fragment, Component } from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";

class LocationSelector extends Component {
  state = {
    address: "",
    clearSuggestions: "",
    selectedByClick: false,
    stateSuggestions: []
  }

  handleChange = address => this.setState({ address, selectedByClick: false, clearSuggestions: address.length > 5 ? false : true });

  handleSelect = address => {
    this.props.loadSpinner();
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        this.props.handleSelectLocation({
          success: true,
          lat,
          lon: lng,
          address
        });
      })
      .catch(() => this.props.handleSelectLocation({ success: false, address: "", lat: 0.0, lon: 0.0 }));
  }

  handleBlur = suggestions_ => {
    if (!this.state.selectedByClick) {
      const suggestions = suggestions_.map(x => x.description);
      if (suggestions.length === 0 && this.state.address.length > 5) {
        this.setState({ address: "" });
        return;
      }
      if (!suggestions.includes(this.state.address) && this.state.address.length > 5) {
        this.setState({ clearSuggestions: true, address: suggestions[0] }, () => this.handleSelect(suggestions[0]))
      }
    } else {
      this.setState({ selectedByClick: false })
    }
  }

  // updateSuggestions = stateSuggestions => this.setState({ stateSuggestions });

  render() {
    const {
      address,
      clearSuggestions,
      stateSuggestions
    } = this.state;
    const {
      airportsFirst,
      countries,
      label,
      placeholder
    } = this.props;
    return (
      <PlacesAutocomplete
        value={address}
        shouldFetchSuggestions={address.length > 4}
        onChange={this.handleChange}
        onSelect={address => {
          this.setState({ address: address, selectedByClick: true, clearSuggestions: true }, this.handleSelect(address))
        }}
        searchOptions={{
          componentRestrictions: {
            country: countries
          }
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          // if (suggestions != stateSuggestions) {
          //   this.updateSuggestions(suggestions);
          // }
          let suggestions_ = [];
          if (!clearSuggestions) {
            if (airportsFirst) {
              const temp_airports = suggestions.filter(s => s.types.includes("airport"));
              const temp_establishments = suggestions.filter(s => s.types.includes("establishment") && !s.types.includes("airport"));
              const temp_else = suggestions.filter(s => !s.types.includes("airport") && !s.types.includes("establishment"));
              suggestions_.push(...temp_airports);
              suggestions_.push(...temp_establishments);
              suggestions_.push(...temp_else);
            } else {
              suggestions_ = suggestions;
            }
          }
          const containerClass = suggestions_.length > 0 || loading ? "has-results" : "";
          const labelTag = label ? <label>{label}</label> : null;
          return (
            <Fragment>
              {labelTag}
              <div className="input-icon-search">
                <i className="mdi mdi-map-marker"></i>
                <input
                  id="address"
                  name="address"
                  {...getInputProps({
                    placeholder: placeholder,
                    className: "input-autocomplete-places"
                  })}
                  onBlur={() => this.handleBlur(suggestions_)}
                />
              </div>
              <div className={`autocomplete-dropdown-container ${containerClass}`} >
                {loading && <div className="suggestion-item">Cargando...</div>}
                {suggestions_.map((suggestion, i) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className
                      })}
                      key={i}
                      onClick={() => {
                        this.setState({ address: suggestion.description, selectedByClick: true, clearSuggestions: true }, () => this.handleSelect(suggestion.description))
                      }}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </Fragment>
          )
        }}
      </PlacesAutocomplete>
    )
  }
}

export default LocationSelector;
