import React from "react";
import Calendar from "./Calendar";

const CalendarContext = ({ vehicleId, vehicles, categories }) => (
  <>
    <Calendar
      vehicleId={vehicleId}
      vehicles={vehicles}
      categories={categories}
      updateRentalLink={(rental_id, vehicle_id) =>
        `/calendar/${rental_id}/update_rental.json?vehicle_id=${vehicle_id}`
      }
      getRentalDataLink={(vehicle_id) =>
        `/calendar/${vehicle_id}/get_rental_data.json`
      }
      confirmBookingLink={(booking_id) => `/bookings/${booking_id}/confirm.json`}
      cancelBookingLink={(booking_id) => `/bookings/${booking_id}/owner_cancel.json`}
      canOfferNewCar={true}
    />
  </>
);

export default CalendarContext;
