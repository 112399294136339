import React, { Component, Fragment } from "react";
import RentalsSearch from "../../../../common/search_components/RentalsSearch";
import RentalsList from "./RentalsList";

class SearchRentals extends Component {
  state = {};

  handleSelectRental = rental => {
    this.props.handleSelectRental(rental, this.props.jumpToStep);
  }

  render() {
    const {
      address,
      handleSelectLocation,
      fromDate,
      fromTime,
      handleChangeTimeFrom,
      untilDate,
      untilTime,
      handleChangeTimeUntil,
      showFilters,
      handleChangeShowFilters,
      handleSearch,
      vehicleCategories,
      vehicleTransmissions,
      vehicleFeatures,
      vehicleExtras,
      selectedCategories,
      selectedTransmissions,
      selectedFeatures,
      selectedExtras,
      handleChangeCategories,
      handleChangeTransmissions,
      handleChangeFeatures,
      handleChangeExtras,
      priceMin,
      priceMinUpdate,
      priceMax,
      priceMaxUpdate,
      handleChangePriceRange,
      handleUpdatePriceRange,
      resetFilters,
      rentals,
      loadSpinner
    } = this.props;
    return (
      <Fragment>
        <div className="my-0 step-names">
          <div className="step-title">
            <span>Selección de auto</span>
          </div>
          <div className="step-title">
            <span>Confirmar datos</span>
          </div>
        </div>
        <div className="my-3">
          <h3 className="py-3">Seleccionar vehículo</h3>
          <RentalsSearch
            address={address}
            handleSelectLocation={handleSelectLocation}
            fromDate={fromDate}
            fromTime={fromTime}
            handleChangeTimeFrom={handleChangeTimeFrom}
            untilDate={untilDate}
            untilTime={untilTime}
            handleChangeTimeUntil={handleChangeTimeUntil}
            showCheckMap={false}
            showMap={false}
            handleChangeShowMap={() => { }}
            showCheckFilters={true}
            showFilters={showFilters}
            handleChangeShowFilters={handleChangeShowFilters}
            chkAirport={false}
            showCheckAirport={false}
            handleCheckAirport={() => { }}
            handleSearch={handleSearch}
            vehicleCategories={vehicleCategories}
            vehicleTransmissions={vehicleTransmissions}
            vehicleFeatures={vehicleFeatures}
            vehicleExtras={vehicleExtras}
            selectedCategories={selectedCategories}
            selectedTransmissions={selectedTransmissions}
            selectedFeatures={selectedFeatures}
            selectedExtras={selectedExtras}
            handleChangeCategories={handleChangeCategories}
            handleChangeTransmissions={handleChangeTransmissions}
            handleChangeFeatures={handleChangeFeatures}
            handleChangeExtras={handleChangeExtras}
            priceMin={priceMin}
            priceMinUpdate={priceMinUpdate}
            priceMax={priceMax}
            priceMaxUpdate={priceMaxUpdate}
            handleChangePriceRange={handleChangePriceRange}
            handleUpdatePriceRange={handleUpdatePriceRange}
            resetFilters={resetFilters}
            loadSpinner={loadSpinner}
          />
        </div>
        <div className="row">
          <div className="col">
            <RentalsList rentals={rentals} handleSelectRental={this.handleSelectRental} />
          </div>
        </div>
      </Fragment>
    )
  }
}

export default SearchRentals;