import React from "react";
import ReactDOM from "react-dom";

class DetectMobile extends React.Component {
  componentDidMount() {
    let width = window.outerWidth;
    let height = window.outerHeight;
    if (height < 720 || width < 720) {
      $("#modal-mobile").modal("show");
    }
  }

  render() {
    return (
      <div
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
        id="modal-mobile"
      >
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Para ser parte de Appcar, debes bajar la APP</p>
              {/*TODO: Hay que poner los links correspondientes acá*/}
              <div className="store-badges" style={{ height: "auto" }}>
                <a href="https://apps.apple.com/us/app/appcar/id1486648200" target="_blank" rel="noopener noreferrer">
                  <img className="app-badge" src="/app-store-badge.png" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=cl.mercanding.airncars" target="_blank" rel="noopener noreferrer">
                  <img className="app-badge" src="/google-play-badge.png" />
                </a>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-appcar-primary"
                data-dismiss="modal"
                data-bs-dismiss="modal"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DetectMobile;
