import React, { Component } from "react";
import iconLock from "images/lock.png";
import iconStare from "images/IconStare.png";
import MPBrand from "images/brand/MP.png";
import Axios from "axios";
import { AxiosApplicationInterceptor } from "../../../../common/axios_interceptor";
import {
  constants,
  OptionsMercadoPagoIdType,
} from "../../../../common/constants";
import SelectWithRadio from "../../../../common/components/select/SelectWithRadio";
Axios.interceptors.response.use(undefined, AxiosApplicationInterceptor);

export default class PaymentMethodModal extends Component {
  state = {
    data: {},
    id_number: this.props.user.id_number || "",
    idType: OptionsMercadoPagoIdType.find((x) => x.value === constants.RUT),
  };

  onFormMounted = (error) => {
    this.props.hideSpinner();
    () => {
      if (error) {
        console.warn("Form mounted handling error: ", error);
        return this.props.showMessage("primary", "Error", [error.message]);
      }
      console.log("Form mounted");
    };
    () => {
      selectTags = document.getElementById("form-checkout__installments");
      if (selectTags != null) {
        selectTags.selectedIndex = 0;
      }
    };
  };

  onFormSubmit = (e, cardForm) => {
    this.props.showSpinner();
    e.preventDefault();
    const csrftoken = document.querySelector("[name=csrf-token]");
    if (csrftoken) {
      Axios.defaults.headers.common["X-CSRF-TOKEN"] = csrftoken.content;
    }
    let {
      paymentMethodId: payment_method_id,
      issuerId: issuer_id,
      cardholderEmail: email,
      amount,
      token,
      installments,
      identificationNumber,
      identificationType,
    } = cardForm.getCardFormData();

    if (payment_method_id == "debvisa") {
      this.props.showMessage("primary", "Alerta", [
        "Por favor asegurate de registrar una tarjeta de crédito. Por el momento no aceptamos tarjetas de débito",
      ]);
      this.props.hideSpinner();
      return;
    }

    const formatedID = identificationNumber.replace(/\.|\-/g, "");

    Axios({
      method: "POST",
      url: `/owners/${this.props.user.id}/create_mercadopago`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        token,
        issuer_id,
        payment_method_id,
        transaction_amount: Number(amount),
        installments: Number(installments),
        description: "Arriendo Appcar",
        payer: {
          email,
          identification: {
            type: identificationType,
            number: formatedID,
          },
        },
      }),
    })
      .then((response) => {
        if (!response.data.success) {
          this.props.showMessage("primary", "Error", [
            response.data.message || "Ha ocurrido un error inesperado",
          ]);
        } else {
          this.props.callback();
        }
      })
      .catch((e) => {
        console.log(e);
        this.props.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ]);
      })
      .finally(() => this.props.hideSpinner());
  };

  onCardTokenReceived = (error, token) => {
    if (error) {
      console.warn("Token handling error: ", error);
      let err = undefined;
      switch (typeof error) {
        case "string":
          err = error;
          break;
        case "object":
          if (Array.isArray(error))
            err = error[0].code + " - " + error[0].message;
          else err = error.code + " - " + error.message;
          break;
      }
      return this.props.showMessage("primary", "Error", [err]);
    }
    console.log("Token available");
  };

  onFetching = (resource) => {
    console.log("Fetching resource: ", resource);
  };

  componentDidMount() {
    if (
      this.props.reloadMPForm ||
      !this.props.user.mercadopago ||
      this.props.user.mercadopago.cards.length == 0
    ) {
      // this.loadMPForm();
    }
  }

  loadMPForm = () => {
    const mp = new MercadoPago(this.props.mpPublicKey, {
      locale: "es-CL",
    });
    const cardForm = mp.cardForm({
      amount: "100",
      autoMount: true,
      form: {
        id: "form-checkout",
        cardholderName: {
          id: "form-checkout__cardholderName",
          placeholder: "",
        },
        cardholderEmail: {
          id: "form-checkout__cardholderEmail",
          placeholder: "",
        },
        cardNumber: {
          id: "form-checkout__cardNumber",
          placeholder: "",
        },
        cardExpirationMonth: {
          id: "form-checkout__cardExpirationMonth",
          placeholder: "MM",
        },
        cardExpirationYear: {
          id: "form-checkout__cardExpirationYear",
          placeholder: "YY",
        },
        securityCode: {
          id: "form-checkout__securityCode",
          placeholder: "",
        },
        installments: {
          id: "form-checkout__installments",
          placeholder: "",
        },
        identificationType: {
          id: "form-checkout__identificationType",
          placeholder: "",
        },
        identificationNumber: {
          id: "form-checkout__identificationNumber",
          placeholder: "",
        },
        issuer: {
          id: "form-checkout__issuer",
          placeholder: "",
        },
      },
      callbacks: {
        onFormMounted: this.onFormMounted,
        onSubmit: (event) => this.onFormSubmit(event, cardForm),
        onCardTokenReceived: this.onCardTokenReceived,
        onFetching: this.onFetching,
      },
    });
  };

  componentDidUpdate() {
    if (
      !this.props.user.mercadopago ||
      this.props.user.mercadopago.cards.length == 0
    ) {
      if (this.props.loadMP) {
        this.props.MPLoaded();
        this.loadMPForm();
      }
    }
  }

  maskCardInput = (value) => {
    if (value && value.length > 3) {
      const regex = /\d{4}/g;
      const res = value
        .replace(/ /g, "")
        .replace(regex, (maths) => (maths === 12 ? maths : maths + " "))
        .trim();
      let input = document.getElementById("form-checkout__cardNumber");
      input.value = res;
    }
  };

  render() {
    const { id_number, idType } = this.state;
    return (
      <div
        className="modal fade"
        id="PaymentMethodModal"
        aria-hidden="true"
        aria-labelledby="PaymentMethodModalLabel"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered modal-appcar">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-m-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                x
              </button>
              <h5 className="modal-title" id="PaymentMethodModalLabel">
                Agregar tarjeta de crédito
              </h5>
            </div>
            <div
              className="modal-body mx-auto"
              style={{ maxWidth: 350, paddingTop: 30, paddingBottom: 40 }}
            >
              <div className="alert-advice mb-3">
                <div className="alert-icon">
                  <img src={iconLock} height={22} />
                </div>
                <div className="alert-text fs-13 ml-2">
                  Tu información de pago se guardará de forma segura.
                </div>
              </div>
              <form id="form-checkout">
                <div className="form-group">
                  <label
                    htmlFor="form-checkout__cardholderName"
                    className="label-inside"
                  >
                    Nombre impreso en la tarjeta
                  </label>
                  <input
                    required
                    type="text"
                    name="cardholderName"
                    id="form-checkout__cardholderName"
                    defaultValue={`${this.props.user?.name} ${this.props.user?.last_name}`}
                    className="form-control appcar-input"
                    tabIndex="1000004"
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor="form-checkout__cardNumber"
                    className="label-inside"
                  >
                    Número de tarjeta
                  </label>
                  <input
                    required
                    type="text"
                    name="cardNumber"
                    id="form-checkout__cardNumber"
                    className="form-control appcar-input"
                    pattern="[0-9 ]+"
                    onChange={(e) => this.maskCardInput(e.target.value)}
                    title="Sólo números y espacios"
                    maxLength="19"
                    tabIndex="1000000"
                  />
                </div>
                <select
                  name="issuer"
                  id="form-checkout__issuer"
                  className="border-none text-appcar-primary font-italic"
                  tabIndex="1000006"
                  aria-readonly={true}
                  readOnly
                  onClick={() => null}
                  style={{
                    all: "unset",
                    color: "#FF543E",
                    marginLeft: 12,
                    height: "fit-content",
                    fontStyle: "italic",
                    fontSize: 10,
                  }}
                ></select>
                <div className="expiration-date-input-group">
                  <div className="form-group">
                    <label
                      htmlFor="form-checkout__cardExpirationMonth"
                      className="label-inside"
                    >
                      Fecha de expiración
                    </label>
                    <div className="expiration-date-input">
                      <input
                        required
                        type="text"
                        name="cardExpirationMonth"
                        id="form-checkout__cardExpirationMonth"
                        maxLength="2"
                        tabIndex="1000001"
                        className="month fs-16"
                        style={{ marginTop: "-1px", marginBottom: "-1px" }}
                        onKeyPress={(e) => {
                          if (
                            isNaN(parseFloat(e.key.trim())) ||
                            !isFinite(e.key.trim())
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <span className="text-appcar-primary">/</span>
                      <input
                        required
                        type="text"
                        name="cardExpirationYear"
                        id="form-checkout__cardExpirationYear"
                        maxLength="2"
                        tabIndex="1000002"
                        className="year fs-16"
                        style={{ marginTop: "-1px", marginBottom: "-1px" }}
                        onKeyPress={(e) => {
                          if (
                            isNaN(parseFloat(e.key.trim())) ||
                            !isFinite(e.key.trim())
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="form-checkout__securityCode"
                      className="label-inside"
                    >
                      CVV
                    </label>
                    <input
                      required
                      type="text"
                      name="securityCode"
                      id="form-checkout__securityCode"
                      className="form-control appcar-input ccv"
                      maxLength="3"
                      tabIndex="1000003"
                    />
                  </div>
                </div>
                <input
                  required
                  type="hidden"
                  name="cardholderEmail"
                  id="form-checkout__cardholderEmail"
                  value={this.props.user.email}
                />
                <div className="form-group">
                  <label
                    htmlFor="form-checkout__identificationType"
                    className="label-inside"
                  >
                    Tipo de documento
                  </label>
                  <SelectWithRadio
                    value={idType}
                    options={OptionsMercadoPagoIdType}
                    placeholder="Tipo de documento"
                    noOptionsMessage={() => "No hay tipos de documento"}
                    onChange={(opt) => this.setState({ idType: opt })}
                    classNamePrefix="RS-FIX"
                    className="react-select-fix appcar-input"
                    hideSelectedOptions={false}
                    backspaceRemovesValue={false}
                    isSearchable={false}
                    isClearable={false}
                    tabIndex="1000007"
                  />
                  <input
                    type="hidden"
                    name="identificationType"
                    id="form-checkout__identificationType"
                    value={idType?.value}
                  />
                </div>
                <div className="form-group">
                  <label className="label-inside">N° de {" "}{(idType?.value === constants.RUT ? "RUT" : "documento")}</label>
                  <input
                    required
                    type="text"
                    value={id_number}
                    className="form-control appcar-input"
                    onChange={(e) =>
                      this.setState({ id_number: e.target.value })
                    }
                    placeholder="Número de documento"
                  />
                  <input
                    type="hidden"
                    name="identificationNumber"
                    id="form-checkout__identificationNumber"
                    value={id_number.replace(/\./g, "").replace(/\-/g, "")}
                  />
                </div>
                <div className="form-group d-none">
                  <select
                    name="installments"
                    id="form-checkout__installments"
                    className="form-control appcar-input"
                    tabIndex="1000009"
                  ></select>
                </div>
                <div className="row justify-content-between align-items-center my-3">
                  <div className="col-auto pr-0">
                    <img src={MPBrand} alt="Mercado Pago" width={130} />
                  </div>
                  <div className="col-auto pl-0">
                    <button
                      type="submit"
                      className="btn btn-appcar-primary btn-slim"
                      id="form-checkout__submit"
                    >
                      Agregar tarjeta
                    </button>
                  </div>
                </div>
              </form>
              <div className="alert-advice mb-3">
                <div className="alert-icon pl-0 pr-2">
                  <img src={iconStare} height={15} />
                </div>
                <div className="alert-text fs-10 text-italic fw-700">
                  Se realizará un cargo de <b>$100 pesos</b> para validar tu
                  medio de pago, que será devuelto automáticamente.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
