import React, { Component, Fragment } from "react";
import Spinner from "../../common/Spinner";
import axios from "axios";
import moment from "moment";
import { AxiosBackofficeInterceptor } from "../../common/axios_interceptor";
import { constants } from "../../common/constants";
import Select from "react-select";
import Table from "../../common/components/table/Table";
import debounce from "lodash/debounce";
import Alert from "../../common/components/alert/Alert";
import { DatePicker } from "../../common/CustomInputs";

class QualificationsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form_data: {
        from: null,
        until: null,
      },
      search_filter: "",
      qualifications: this.props.qualifications,
      isSubmitting: false,
      alert: {
        showMessage: false,
        messageType: undefined,
        messages: [],
        messageTitle: undefined,
      },
      search_info: {
        has_searched: false,
        from: "",
        until: "",
      },
      orderStatus: {
        name: "id",
        direction: "desc",
      },
      objTypes: [
        {
          label: "Driver",
          value: "driver",
        },
        {
          label: "Owner",
          value: "admin",
        },
        {
          label: "Vehículo",
          value: "vehicle",
        },
      ],
      selectedObj: {
        label: "Owner",
        value: "admin",
      },
      paginationInformation: this.props.paginationInformation || {
        limit: constants.DEFAULT_RESULTS_PER_PAGE,
        last: 0,
        total: this.props.qualifications.length,
      },
      currentPage: 1,
      lastIds: [this.props.paginationInformation.last],
    };
    this.willUnmount = false;
    this.cancelToken = null;
    this.processRunning = 0;
    this.handleSubmit = debounce(this.handleSubmit, 400, { leading: true });
  }

  componentWillUnmount() {
    this.willUnmount = true;
  }

  componentDidMount() {
    const this_ = this;
    this_.willUnmount = false;
    this.cancelToken = axios.CancelToken.source();
    axios.interceptors.response.use(undefined, AxiosBackofficeInterceptor);
    const options = {
      showDropdowns: true,
      minYear: 2018,
      maxYear: constants.MAX_YEAR,
      autoUpdateInput: false,
      locale: {
        format: "DD/MM/YYYY",
        cancelLabel: "Limpiar",
        applyLabel: "Aplicar",
        fromLabel: "Desde",
        toLabel: "Hasta",
        daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        firstDay: 1,
      },
    };

    $("#search_dates").on("cancel.daterangepicker", function(ev, picker) {
      picker.setStartDate(moment());
      picker.setEndDate(moment());
      this_.setState((state) => {
        state.form_data.from = null;
        state.form_data.until = null;
        return { form_data: state.form_data };
      });
    });

    $("#search_dates").daterangepicker(options, (start, end) => {
      let from = moment(start)
        .startOf("day")
        .format();
      let to = moment(end)
        .endOf("day")
        .format();
      this.setState((state) => {
        state.alert.showMessage = false;
        state.form_data.from = from;
        state.form_data.until = to;
        return { form_data: state.form_data, alert: state.alert };
      });
    });
  }

  showMessage = (messageType, messageTitle, messages) =>
    this.setState((state) => {
      state.alert.showMessage = true;
      state.alert.messageTitle = messageTitle;
      state.alert.messages = messages;
      state.alert.messageType = messageType;
      return { alert: state.alert };
    },
    () => setTimeout(this.handleAlertClose, 3000)
  );

  handleAlertClose = () =>
    this.setState(state => {
      state.alert.showMessage = false;
      return { alert: state.alert };
    });

  handleSubmit = (e = null, direction = "after", resetPagination = false) => {
    if (e) e.preventDefault();
    this.processRunning += 1;
    this.setState({ isSubmitting: true });
    moment.locale("es");
    const {
      form_data,
      paginationInformation,
      search_filter,
      selectedObj,
    } = this.state;
    let search_info = {
      has_searched: true,
    };
    const from = moment(form_data.from);
    let fromParam = "";
    let untilParam = "";
    let last = paginationInformation.last;
    let lastIds = this.state.lastIds;
    if (direction == "before") {
      lastIds.pop();
      lastIds.pop();
      last = lastIds.slice(this.state.lastIds.length - 1);
    }
    if (resetPagination) {
      lastIds = [];
      last = 0;
    }
    let paginationParams = `limit=${paginationInformation.limit}`;
    if (!resetPagination) paginationParams += `&last=${last}`;
    if (from.isValid()) {
      search_info.from = from.format("DD/MM/YYYY");
      fromParam = `&from=${encodeURIComponent(
        from.startOf("d").format("YYYY-MM-DDTHH:mm:ssZ")
      )}`;
    }
    const until = moment(form_data.until);
    if (until.isValid()) {
      search_info.until = until.format("DD/MM/YYYY");
      untilParam = `&until=${encodeURIComponent(
        until.endOf("d").format("YYYY-MM-DDTHH:mm:ssZ")
      )}`;
    }
    const filterParam = search_filter ? `&filter=${search_filter}` : "";
    const objParam = selectedObj ? `&object_type=${selectedObj.value}` : "";
    axios({
      method: "get",
      url: `/backoffice/qualifications.json?${paginationParams}${fromParam}${untilParam}${filterParam}${objParam}`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        const qualifications = response.data.qualifications;
        const paginationInformation = response.data.paginationInformation;
        lastIds.push(paginationInformation.last);
        if (!this.willUnmount)
          this.setState({
            qualifications,
            search_info,
            paginationInformation,
            lastIds,
          });
      })
      .catch((error) => {
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ]);
      })
      .then(() => {
        this.processRunning -= 1;
        if (this.processRunning == 0) {
          this.setState({ isSubmitting: false });
        }
      });
  };

  onSearchChange = (search_filter) => {
    this.setState({ search_filter, currentPage: 1 }, () => {
      this.handleSubmit(null, "last", true);
    });
  };

  onLimitChange = (limit) => {
    this.setState(
      (state) => {
        state.paginationInformation.limit = limit;
        return {
          paginationInformation: state.paginationInformation,
          currentPage: 1,
        };
      },
      () => {
        this.handleSubmit(null, "last", true);
      }
    );
  };

  onPageChange = (currentPage, direction) => {
    this.setState({ currentPage }, () => {
      this.handleSubmit(null, direction);
    });
  };

  onOrderChange = (orderStatus) => {
    this.setState({ orderStatus }, () => {});
  };

  handleChangeObjType = (selectedObj) => {
    this.setState({ selectedObj, currentPage: 1 }, () =>
      this.handleSubmit(null, "last", true)
    );
  };
  render() {
    moment.locale("es");

    const {
      qualifications,
      form_data,
      search_info,
      orderStatus,
      paginationInformation,
      currentPage,
      selectedObj,
      objTypes,
      search_filter,
      alert,
    } = this.state;
    const { messageTitle, messageType, messages, showMessage } = alert;

    // AHORA EL ORDENAMIENTO ES POR ENDPOINT
    const rows = qualifications.sort((a, b) => {
      // Ordenamiento por id
      const aId = parseInt(a.id);
      const bId = parseInt(b.id);

      // Mayor a menor (Más futuro a más pasado)
      return bId - aId;
    });

    const { total } = paginationInformation;

    const searchInfo = (
      <div className="alert light mw-100">
        {`Mostrando ${rows.length} resultados de un total de ${total}`}
        {search_info.from
          ? ` entre fechas ${search_info.from} y ${search_info.until}`
          : ""}
      </div>
    );

    let inputDatesText = "";
    const from = moment(form_data.from);
    const until = moment(form_data.until);
    if (from.isValid()) {
      inputDatesText = from.format("LL") + " - " + until.format("LL");
    }

    const columnDefinitions = [
      {
        name: "author",
        label: "Evaluador",
        orderable: false,
      },
      {
        name: "entity_name",
        label: "Evaluado",
        orderable: false,
      },
      {
        name: "date",
        label: "Fecha",
        orderable: false,
      },
      {
        name: "rating",
        label: "Calificación",
        orderable: false,
      },
      {
        name: "comment",
        label: "Comentario",
        orderable: false,
      },
      {
        name: "actions",
        label: "",
        orderable: false,
      },
    ];

    const data = rows.map((q) => ({
      author: q.author,
      entity_name: q.entity_name,
      date: moment(q.date).format("DD/MM/Y HH:mm"),
      rating: q.rating,
      comment: q.content,
      actions:
        q.booking_id !== 0 ? (
          <a
            href={`/backoffice/bookings/${q.booking_id}`}
            className="btn btn-appcar-primary btn-sm"
            rel="noreferrer noopener"
            target="_blank"
          >
            Detalle
          </a>
        ) : null,
    }));
    const table = (
      <Table
        id="table-qualifications"
        noResults={
          this.state.isSubmitting
            ? "Realizando Búsqueda"
            : "No hay resultados para mostrar..."
        }
        columns={columnDefinitions}
        data={data}
        hasFooter={true}
        tableClass="table dataTable table-striped"
        searchable={true}
        onSearchChange={this.onSearchChange}
        orderStatus={orderStatus}
        paginationInformation={paginationInformation}
        onLimitChange={this.onLimitChange}
        onPageChange={this.onPageChange}
        onOrderChange={this.onOrderChange}
        currentPage={currentPage}
      />
    );
    const fromParam = from.isValid()
      ? `&from=${encodeURIComponent(
          from.startOf("d").format("YYYY-MM-DDTHH:mm:ssZ")
        )}`
      : "";
    const untilParam = until.isValid()
      ? `&until=${encodeURIComponent(
          until.endOf("d").format("YYYY-MM-DDTHH:mm:ssZ")
        )}`
      : "";
    const paginationParams = `limit=${paginationInformation.limit}&last=${paginationInformation.last}&direction=last`;
    const filterParam = search_filter ? `&filter=${search_filter}` : "";
    const objParam = selectedObj ? `&object_type=${selectedObj.value}` : "";
    return (
      <Fragment>
        <Spinner loading={this.state.isSubmitting} />
        <Alert
          alertClass={messageType}
          isShowing={showMessage}
          messages={messages}
          title={messageTitle}
          onClose={this.handleAlertClose}
        />
        <div className="row my-3 align-items-end">
          <div className="col-sm-12 col-md-6 col-lg">
            <DatePicker
              id="search_dates"
              label="Selecciona rango de fechas"
              onChange={(e) => this.changeFecha(e.target.value)}
              name="date_from"
              disabled={true}
              classNameInput="form-control appcar-input"
              value={inputDatesText}
            />
          </div>
          <div className="col">
            <div className="form-group">
              <label>Desplegar calificaciones de:</label>
              <Select
                value={selectedObj}
                options={objTypes}
                placeholder="Selecciona..."
                onChange={this.handleChangeObjType}
                classNamePrefix="RS-FIX"
                className="react-select-fix"
              />
            </div>
          </div>
          <div className="col-auto pl-md-0 pr-1">
            <button
              className="btn btn-appcar-primary btn-sm py-0 mb-1"
              onClick={() => this.handleSubmit(null, "last", true)}
            >
              <i className="mdi mdi-chevron-right fs-22" />
            </button>
          </div>
        </div>
        <div className="row justify-content-end my-2">
          <div className="col">
            <h4>Calificaciones al {selectedObj.label}</h4>
          </div>
          {
            // MA1-T403 Comentar botón "Exportar a excel" en tablas de backoffice
            /* <div className="col-auto">
            <a
              href={`/backoffice/qualifications/export_to_xlsx?${fromParam}${untilParam}${paginationParams}${filterParam}${objParam}`}
              className="btn btn-success"
            >
              <i className="mdi mdi-microsoft-excel"></i> Exportar a excel
            </a>
          </div> */
          }
        </div>
        {/* {searchInfo} */}
        <div className="mb-3">{table}</div>
      </Fragment>
    );
  }
}

export default QualificationsList;
