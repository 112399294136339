import React, { Fragment } from "react";
import BookingListItem from "./BookingListItem";
import { BookingStatus } from "../../common/constants";

const ListView = ({ bookings, handleCancel, handleAccept, compareBookings }) => {
  //Sanitize
  const bookings_ = (Array.isArray(bookings) ? bookings : [])
    .filter(
      booking => {
        // Primer filtro si no son cancelados o reemplazados
        if (booking.status.id != BookingStatus.CANCELADO && booking.status.id != BookingStatus.REEMPLAZADO && Date.parse(booking.date_until) > Date.now()) {
          // Filtro de flujo normal, en caso de que la reserva sea en el futuro, ahora se considera futuro como la fecha de término del rental.
          return true
        }
        else return false;
      }
    )
    .sort((a, b) => new Date(b.date_from) - new Date(a.date_from));
  const booking_list =
    bookings_.length > 0 ? (
      bookings_.map((booking, i) => {
        return (
          <BookingListItem
            booking={booking}
            handleCancel={handleCancel}
            handleAccept={handleAccept}
            compareBookings={compareBookings}
            key={i}
          />
        )
      })
    ) : (
        <div className="alert light">
          No hay reservas agendadas próximamente
      </div>
      );
  return (
    <Fragment>
      <h3>Próximos arriendos</h3>
      <hr />
      {booking_list}
    </Fragment>
  );
};

export default ListView;
