import React, { Component } from "react";
import ThanksModalRental from "../../common/legal_modals/ThanksModalRental";
import RentalsWizard from "./form/RentalsWizard";

export default class RentalsNew extends Component {
  modalThanks = null;
  componentDidMount() {
    const modalEl = document.getElementById("thanksModal");
    this.modalThanks = new bootstrap.Modal(modalEl);
    modalEl.addEventListener("hidden.bs.modal", (ev) => {
      this.redirectToDashboard();
    });
  }
  redirectToDashboard = () => {
    window.location.assign("/vehicles?success=1");
  };
  openModal = () => {
    this.modalThanks.show();
  };
  render() {
    const modal = <ThanksModalRental action={this.redirectToDashboard} />;
    const rental = this.props.rental;
    return (
      <>
        <RentalsWizard
          vehicle={this.props.vehicle}
          rental={rental}
          extras={this.props.extras}
          formAction={this.openModal}
          firstAttempt={true}
          title="Crea tu publicación"
          saveLink={`/`}
          editVehicleLink={`/vehicles/${rental.vehicle.id}/edit`}
          recommendedPrice={this.props.recommendedPrice}
          appraisal={this.props.appraisal}
        />
        {modal}
      </>
    );
  }
}
