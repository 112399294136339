import React, { useState, useEffect } from "react";
import Alert from "../../common/components/alert/Alert";
import Spinner from "../../common/Spinner";
import VehicleDocuments from "../../appcar/vehicles/form/VehicleDocuments";
import { generateFilename } from "../../common/constants";
import Axios from "axios";
import { AxiosBackofficeInterceptor } from "../../common/axios_interceptor";
Axios.interceptors.response.use(undefined, AxiosBackofficeInterceptor);

const DocumentsUploader = ({ vehicle }) => {
  const [objectUploading, setObjectUploading] = useState();
  const [data, setData] = useState({
    registry: vehicle.registry || { name: "", id_number: "" },
    registration_document: {
      registration_document: Array.isArray(
        vehicle.registration_document?.registration_document
      )
        ? vehicle.registration_document.registration_document.map((x) => ({
            url: x,
          }))
        : [],
      vehicular_technical_revision: Array.isArray(
        vehicle.registration_document?.vehicular_technical_revision
      )
        ? vehicle.registration_document.vehicular_technical_revision.map(
            (x) => ({ url: x })
          )
        : [],
      circulation_permit: Array.isArray(
        vehicle.registration_document?.circulation_permit
      )
        ? vehicle.registration_document.circulation_permit.map((x) => ({
            url: x,
          }))
        : [],
    },
    insurance_policy: {
      soap: Array.isArray(vehicle.insurance_policy?.soap)
        ? vehicle.insurance_policy.soap.map((x) => ({ url: x }))
        : [],
    },
  });
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    showMessage: false,
    messageType: undefined,
    messages: [],
    messageTitle: undefined,
  });

  const openFileDialog = (obj) => setObjectUploading(obj);
  useEffect(() => {
    if (!!objectUploading)
      document.getElementById("input_document_uploader").click();
  }, [objectUploading]);

  const uploadPicture = (e) => {
    let file = e.target.files;
    const fullPath = objectUploading;
    if (file.length > 0) {
      const filename = generateFilename(file[0].name);
      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onloadend = function(e) {
        var base64result = reader.result;
        addPicture(base64result, filename, fullPath);
      }.bind(this);
    }
  };

  const addPicture = (picture, filename, full_path) => {
    const path = full_path.split(".");
    const file = picture.split(",");
    const start = file[0];
    const image_base_64 = file[1];
    const mime = picture.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    const mime_type = mime && mime.length ? mime[1] : "";
    filename = encodeURI(filename);
    let newData = { ...data };
    newData[path[0]][path[1]].push({
      filename,
      start,
      image_base_64,
      mime_type,
    });
    setData(newData);
  };

  const removePicture = (index, full_path) => {
    const path = full_path.split(".");
    let newData = { ...data };
    newData[path[0]][path[1]].splice(index, 1);
    setData(newData);
  };

  const showMessage = (messageType, messageTitle, messages) => {
    setAlert((prev) => {
      prev.showMessage = true;
      prev.messageType = messageType;
      prev.messageTitle = messageTitle;
      prev.messages = messages;
      return { ...prev };
    });
    setTimeout(closeAlert, 3000);
  };

  const closeAlert = () =>
    setAlert((prevAlert) => ({ ...prevAlert, showMessage: false }));

  const handleSubmit = () => {
    setLoading(true);
    let shouldCloseSpinner = true;
    Axios({
      method: "PATCH",
      url: `/backoffice/vehicles/${vehicle.id}/update_vehicle_documents.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: { data },
    })
      .then((response) => {
        if (response.data.success) {
          shouldCloseSpinner = false;
          showMessage("primary", "Éxito", ["Documentos actualizados"]);
          location.reload();
        } else {
          showMessage(
            "primary",
            "Alerta",
            response.data.messages || ["Ha ocurrido un error inesperado"]
          );
        }
      })
      .catch((error) => {
        showMessage("primary", "Error", [
          "Ocurrió un error al actualizar los datos",
        ]);
      })
      .finally(() => {
        if (shouldCloseSpinner) setLoading(false);
      });
  };

  const handleChange = (value, name) =>
    setData((prevData) => ({ ...prevData, [name]: value }));
  const handleChangeObj = (value, name, obj) => {
    let newData = { ...data };
    newData[obj][name] = value;
    setData(newData);
  };

  return (
    <>
      <Alert
        alertClass={alert.messageType}
        isShowing={alert.showMessage}
        messages={alert.messages}
        title={alert.messageTitle}
        onClose={closeAlert}
      />
      <Spinner loading={loading} />
      <VehicleDocuments
        data={data}
        handleChange={handleChange}
        openFileDialog={openFileDialog}
        isSubmitting={loading}
        handleChangeObj={handleChangeObj}
        handleSubmit={handleSubmit}
        removePicture={removePicture}
        buttonText="Guardar cambios"
        needMotorData={false}
      />
      <input
        type="file"
        id="input_document_uploader"
        className="d-none"
        onChange={(e) => uploadPicture(e)}
        accept="image/jpeg, image/png, image/jpg, application/pdf"
        onClick={(e) => {
          e.target.value = null;
        }}
      />
    </>
  );
};

export default DocumentsUploader;
