import React from "react";
import classNames from "classnames";

export const DatePicker = ({
  id,
  label,
  name,
  error,
  value,
  onChange,
  onBlur,
  className,
  disabled,
  classNameInput,
  ...props
}) => {
  const classes = classNames("form-group my-2", className);
  const classInput = classNameInput
    ? classNameInput
    : "form-control appcar-input" + classNames({ " is-invalid": error });
  return (
    <div className={classes}>
      <label htmlFor={id}>{label}</label>
      <input
        id={id}
        className={classInput}
        name={name}
        type="text"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={!disabled}
        autoComplete="off"
        {...props}
      />
      <InputFeedback error={error} />
    </div>
  );
};

export const CustomSelect = ({
  id,
  label,
  labelKey,
  valueKey,
  name,
  error,
  options,
  className,
  hasPlaceholder,
  ...props
}) => {
  const classes = classNames("form-group mb-1", className);
  let optValues = options.map(opt => {
    if (typeof opt === "object")
      return (
        <option key={opt[valueKey]} value={opt[valueKey]}>
          {opt[labelKey]}
        </option>
      );
    else
      return (
        <option key={opt} value={opt}>
          {opt}
        </option>
      );
  });
  if (hasPlaceholder) optValues.push(<option key="000" />);
  return (
    <div className={classes}>
      <label htmlFor={id}>{label}</label>
      <select
        id={id}
        className={
          "form-control select2 select2-hidden-accessible " +
          classNames({
            " error-select2": error
          })
        }
        name={name}
        {...props}
      >
        {optValues}
      </select>
      <InputFeedback error={error} />
    </div>
  );
};

export const CustomCheckBox = ({
  id,
  label,
  name,
  error,
  checked,
  handleChange,
  onBlur,
  className,
  ...props
}) => {
  const classes = classNames("form-group mb-3", className);
  return (
    <div className={classes}>
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id={id}
          name={name}
          checked={checked}
          onChange={handleChange}
          onBlur={onblur}
          {...props}
        />
        <label className="form-check-label text-black" htmlFor={id}>
          {label}
        </label>
        <InputFeedback error={error} />
      </div>
    </div>
  );
};

export const InputFeedback = ({ error }) =>
  error ? (
    <div className="invalid-feedback" style={{ display: "block" }}>
      {error}
    </div>
  ) : null;
