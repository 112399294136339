import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Toast = ({ Text, ToastClass, ToastId, showButtonClose = true }) => (
  <div
    className={"toast align-items-center " + ToastClass}
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    id={ToastId}
  >
    <div className="d-flex">
      <div className="toast-body">{Text}</div>
      {showButtonClose ? (
        <button
          type="button"
          className="btn-close me-2 m-auto"
          data-bs-dismiss="toast"
          aria-label="Close"
        ></button>
      ) : null}
    </div>
  </div>
);

Toast.propTypes = {
  Text: PropTypes.string,
  ToastClass: PropTypes.string,
  ToastId: PropTypes.string,
};

export default Toast;
