import Axios from "axios";
import uniqBy from "lodash/uniqBy";
import React, { Component } from "react";
import Alert from "../../../common/components/alert/Alert";
import { constants, generateFilename } from "../../../common/constants";
import Spinner from "../../../common/Spinner";
import VehicleData from "./VehicleData";
import VehicleDocuments from "./VehicleDocuments";
import VehicleFeatures from "./VehicleFeatures";
import VehiclePictures from "./VehiclePictures";
import { AxiosApplicationInterceptor } from "../../../common/axios_interceptor";
import iconWink from "images/IconWink.png";
import iconStare from "images/IconStare.png";
import VehicleLocation from "./VehicleLocation";
import moment from "moment";
Axios.interceptors.response.use(undefined, AxiosApplicationInterceptor);

export default class NewVehicleWizard extends Component {
  state = {
    data: {
      market_value: 0.0,
      plate_number: "",
      year: moment().year(),
      engine: "",
      chassis: "",
      color: "",
      capacity: 5,
      efficiency: "",
      vehicle_category: null,
      vehicle_transmission:
        this.props.vehicleTransmissions.find(
          (x) => x.description == "Manual" || x.id === 1
        ) || null,
      vehicle_type: null,
      fuel_type: null,
      cancellation_policy: null,
      fuel_policy: null,
      vehicle_features: [],
      vehicle_pictures: [],
      photos: {
        front: [],
        back: [],
        left: [],
        right: [],
        inside: [],
      },
      registration_document: {
        vehicular_technical_revision: [],
        circulation_permit: [],
        registration_document: [],
      },
      insurance_policy: {
        soap: [],
      },
      registry: {
        name: "",
        id_number: "",
      },
    },
    rentalId: null,
    location: {
      country: null,
      id: null,
      latitude: 0.0,
      longitude: 0.0,
      name: "",
      url_image: null,
    },
    isSubmitting: false,
    activeStep: this.props.activeStep || 0,
    alert: {
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined,
    },
  };

  modalAlertFields = null;

  componentDidMount() {
    $("body").click(function(e) {
      if (!$(e.target).hasClass("anc-tooltip")) {
        $(".tooltiptext").removeClass("tooltip-visible");
      } else {
        var tooltipFor = "#" + e.target.dataset.toggleTooltipFor;
        $(".tooltiptext")
          .not($(tooltipFor))
          .removeClass("tooltip-visible");
        $(tooltipFor).toggleClass("tooltip-visible");
      }
    });

    const defaultVehicleTransmission = this.props.vehicleTransmissions.find(
      (x) => x.description === "Manual"
    );

    let data = this.state.data;
    data.vehicle_transmission = defaultVehicleTransmission;
    this.setState({ data });

    this.modalAlertFields = new bootstrap.Modal(
      document.getElementById("modal-save-changes")
    );
  }

  checkStep = (step) => {
    let ok = false;
    const { data, location } = this.state;
    switch (step) {
      case 0:
        if (
          !!data.plate_number &&
          !!data.year &&
          !!data.vehicle_type?.model &&
          !!data.vehicle_category?.id &&
          !!data.color &&
          !!data.fuel_type?.id &&
          !!data.capacity &&
          !!data.market_value &&
          !!data.vehicle_transmission?.id
        ) {
          ok = true;
        }
        break;
      case 1:
        if (
          !!location?.name.trim() &&
          !!location?.latitude &&
          !!location?.longitude
        ) {
          ok = true;
        }
        break;
      default:
        ok = true;
    }
    return ok;
  };

  createVehicle = () => {
    const { data: vehicle, activeStep } = this.state;
    if (!this.checkStep(activeStep)) {
      this.modalAlertFields.show();
      return;
    }
    this.setState({ isSubmitting: true });
    const isEdition = !!vehicle.id;
    const method = isEdition ? "PUT" : "POST";
    const url = isEdition ? `/vehicles/${vehicle.id}.json` : "/vehicles.json";
    Axios({
      method,
      url,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: { vehicle },
    })
      .then((response) => {
        if (response.data.success) {
          this.showMessage("primary", "Éxito", ["Datos guardados"]);
          const rentalId = !isEdition
            ? response.data.rental_id
            : this.state.rentalId;
          vehicle.id ??= response.data.vehicle_id;
          this.setState({
            rentalId,
            activeStep: 1,
            data: vehicle,
          });
        } else {
          $("html, main").animate({ scrollTop: 0 }, "slow");
          this.showMessage(
            "primary",
            "Alerta",
            response.data.messages || ["Ha ocurrido un error inesperado"]
          );
        }
      })
      .catch((error) => {
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ]);
      })
      .finally(() => this.setState({ isSubmitting: false }));
  };

  handleUpdateRental = () => {
    const { location: rental_location, rentalId, activeStep } = this.state;
    if (!this.checkStep(activeStep)) {
      this.modalAlertFields.show();
      return;
    }
    this.setState({ isSubmitting: true });
    Axios({
      method: "PATCH",
      url: `/rentals/${rentalId}/update_location.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: { rental_location },
    })
      .then((response) => {
        if (response.data.success) {
          this.showMessage("primary", "Éxito", ["Datos guardados"]);
          this.props.formAction();
        } else {
          $("html, main").animate({ scrollTop: 0 }, "slow");
          this.showMessage(
            "primary",
            "Alerta",
            response.data.messages || ["Ha ocurrido un error inesperado"]
          );
        }
      })
      .catch((error) => {
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ]);
      })
      .finally(() => this.setState({ isSubmitting: false }));
  };
  W;

  handleChange = (value, name) => {
    this.setState((state) => {
      state.data[name] = value;
      return { data: state.data };
    });
  };

  handleChangeObj = (value, name, obj) => {
    this.setState((state) => {
      state.data[obj] = {
        ...state.data[obj],
        [name]: value,
      };
      return { data: state.data };
    });
  };

  handleChangeModel = (option) => {
    const vehicle_type = this.props.vehicleTypes.find(
      (x) => x.id === option.value
    );
    this.handleChange(vehicle_type, "vehicle_type");
  };

  showMessage = (messageType, messageTitle, messages) =>
    this.setState(
      (state) => {
        state.alert.showMessage = true;
        state.alert.messageTitle = messageTitle;
        state.alert.messages = messages;
        state.alert.messageType = messageType;
        return { alert: state.alert };
      },
      () => setTimeout(this.handleAlertClose, 3000)
    );

  handleAlertClose = () =>
    this.setState((state) => {
      state.alert.showMessage = false;
      return { alert: state.alert };
    });

  addFeature = (feature) => {
    this.setState((state) => {
      state.data.vehicle_features.push(feature);
      return { data: state.data };
    });
  };

  removeFeature = (feature) => {
    const index = this.state.data.vehicle_features.findIndex(
      (x) => x.id === feature.id
    );
    this.setState((state) => {
      state.data.vehicle_features.splice(index, 1);
      return { data: state.data };
    });
  };

  handleChangeLocation = (location) => this.setState({ location });
  loadSpinner = () => this.setState({ isSubmitting: true });
  closeSpinner = () => this.setState({ isSubmitting: false });

  render() {
    const { activeStep, alert, data, isSubmitting, location } = this.state;
    const { messageTitle, messageType, messages, showMessage } = alert;
    const {
      vehicleCategories,
      fuelTypes,
      vehicleTransmissions,
      vehicleFeatures,
    } = this.props;

    const vehicleTypes = this.props.vehicleTypes.sort((a, b) => {
      if (a.brand > b.brand) {
        return 1;
      } else if (a.brand < b.brand) {
        return -1;
      } else if (a.model > b.model) {
        return 1;
      } else if (a.model < b.model) {
        return -1;
      } else return 0;
    });

    const selectedBrand = data.vehicle_type && data.vehicle_type.brand;

    const brandOptions = uniqBy(vehicleTypes, "brand").map((type) => ({
      label: type.brand,
      value: type.brand,
    }));

    const modelOptions = vehicleTypes
      .filter((type) => type.brand === selectedBrand)
      .map((type) => ({
        label: type.model,
        value: type.id,
      }));

    const categoriesOptions = vehicleCategories.map((category) => ({
      label: category.description,
      value: category.id,
    }));

    const fuelTypesOptions = fuelTypes.map((fuel) => ({
      label: fuel.description,
      value: fuel.id,
    }));

    const modalSave = (
      <div
        className="modal fade"
        id="modal-save-changes"
        aria-hidden="true"
        aria-labelledby="saveChangesLabel"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered modal-appcar">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-m-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                x
              </button>
              <h5 className="modal-title" id="saveChangesLabel">
                Atención
              </h5>
            </div>
            <div className="modal-body">
              <div className="row justify-content-center">
                <div className="col-auto text-center">
                  <img src={iconStare} width="66px" className="mt-3 mb-3" />
                  <h5 className="mb-4 fs-30 fw-900">¡Ojo!</h5>
                  <p className="mb-4 fs-15 fw-600" style={{ maxWidth: 342 }}>
                    Debes completar los campos antes de continua
                  </p>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-auto">
                  <button
                    type="button"
                    className="btn modal-btn"
                    data-dismiss="modal"
                    data-bs-dismiss="modal"
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    const menuTexts = ["Datos", "Ubicación"];
    const menuList = menuTexts.map((x, i) => (
      <li
        className={`list-group-item  ${i === activeStep ? "active" : ""}`}
        role="button"
        onClick={() => {
          if (i !== activeStep) {
            if (activeStep === 0) this.createVehicle();
            else this.handleUpdateRental();
          }
        }}
        key={i}
      >
        <span className="list-number">{i + 1}</span>
        {x}
      </li>
    ));

    let activeComponent = null;

    switch (activeStep) {
      case 0:
        activeComponent = (
          <VehicleData
            data={data}
            handleChange={this.handleChange}
            handleChangeObj={this.handleChangeObj}
            handleChangeModel={this.handleChangeModel}
            brands={brandOptions}
            models={modelOptions}
            categories={categoriesOptions}
            fuelTypes={fuelTypesOptions}
            transmissions={vehicleTransmissions}
            features={vehicleFeatures}
            isSubmitting={isSubmitting}
            handleSubmit={this.createVehicle}
            addFeature={this.addFeature}
            removeFeature={this.removeFeature}
          />
        );
        break;
      case 1:
        activeComponent = (
          <VehicleLocation
            location={location}
            handleChangeLocation={this.handleChangeLocation}
            handleUpdateRental={this.handleUpdateRental}
            isSubmitting={isSubmitting}
            loadSpinner={this.loadSpinner}
            closeSpinner={this.closeSpinner}
          />
        );
        break;
    }

    return (
      <>
        <Spinner loading={isSubmitting} />
        <Alert
          alertClass={messageType}
          isShowing={showMessage}
          messages={messages}
          title={messageTitle}
          onClose={this.handleAlertClose}
        />
        <div className="edit-box">
          <div className="photo-container">
            <div
              className="image-loader pl-md-5 pb-0"
              style={{ marginBottom: 200 }}
            >
              <h2 className="fs-30">Agrega tu auto</h2>
              <h3 className="fs-12">Parte {activeStep + 1} de 2</h3>
              <ul className="list-group appcar-list pt-4">{menuList}</ul>
            </div>
          </div>
          <div className="form">{activeComponent}</div>
        </div>
        {modalSave}
      </>
    );
  }
}
