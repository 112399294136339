import React, { Component, Fragment } from "react";
import { Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import Spinner from "../../common/Spinner";
import FlashMessage from "../../common/FlashMessage";
import axios from "axios";
import { AxiosBackofficeInterceptor } from "../../common/axios_interceptor";
axios.interceptors.response.use(undefined, AxiosBackofficeInterceptor);

const VehicleExtrasSchema = Yup.object().shape({
  a_name: Yup.string().required("Campo requerido"),
  icon_class: Yup.string()
});

class VehicleFeaturesForm extends Component {
  state = {
    data: this.props.vehicleFeature || {
      a_name: "",
      icon_class: ""
    },
    ui: {
      isSubmitting: false,
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined
    }
  };

  showMessage = msg => {
    this.setState(state => {
      state.ui.showMessage = msg.showMessage;
      state.ui.messageType = msg.messageType;
      state.ui.messages = msg.messages;
      state.ui.messageTitle = msg.messageTitle;
      return { ui: state.ui };
    });
  };

  render() {
    const flash_message = this.state.ui.showMessage ? (
      <FlashMessage
        type={this.state.ui.messageType}
        msg={this.state.ui.messages}
        title={this.state.ui.messageTitle}
      />
    ) : null;
    return (
      <Fragment>
        {flash_message}
        <Formik
          showMessage={this.showMessage}
          enableReinitialize={true}
          initialValues={this.state.data}
          validationSchema={VehicleExtrasSchema}
          onSubmit={(values, { setSubmitting }) => {
            // const token = document.getElementsByName("csrf-token")[0].content;
            const token = null;
            let url = "";
            let method = "";
            if (this.props.vehicleFeature) {
              url = `/backoffice/vehicle_features/${this.props.vehicleFeature.id}.json`;
              method = "PUT";
              values.id = parseInt(values.id);
            } else {
              url = `/backoffice/vehicle_features.json`;
              method = "POST";
            }
            axios(url, {
              method,
              url,
              responseType: "json",
              headers: {
                "Content-Type": "application/json"
              },
              data: { vehicle_feature: values }
            })
              .then(response => {
                if (response.data.success) {
                  window.location.assign(
                    `/backoffice/vehicle_features?success=1`
                  );
                } else {
                  this.showMessage({
                    showMessage: true,
                    messageType: "alert-warning",
                    messages: response.data.messages || [
                      "Ha ocurrido un error inesperado"
                    ],
                    messageTitle: "Alerta"
                  });
                }
              })
              .catch(error =>
                this.showMessage({
                  showMessage: true,
                  messageType: "alert-danger",
                  messages: ["Ha ocurrido un error inesperado"],
                  messageTitle: "Error"
                })
              )
              .then(() => setSubmitting(false));
          }}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
            /* and other goodies verde: #28a745 rojo: #dc3545 */
          }) => {
            const button_label = this.props.vehicleFeature
              ? "Editar"
              : "Agregar nueva";
            return (
              <Fragment>
                <Spinner loading={isSubmitting || this.state.ui.isSubmitting} />
                <form onSubmit={handleSubmit} className="">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label htmlFor="a_name">Nombre de la característica</label>
                        <Field
                          type="text"
                          name="a_name"
                          className={
                            "form-control appcar-input " +
                            classNames({
                              "is-invalid": touched.a_name && errors.a_name
                            })
                          }
                        />
                        <ErrorMessage name="a_name">
                          {msg => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label htmlFor="icon_class">Clase del ícono</label>
                        <Field
                          type="text"
                          name="icon_class"
                          className={
                            "form-control appcar-input " +
                            classNames({
                              "is-invalid":
                                touched.icon_class && errors.icon_class
                            })
                          }
                        />
                        <ErrorMessage name="icon_class">
                          {msg => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-appcar-primary"
                    disabled={isSubmitting}
                  >
                    {`${button_label} característica de vehículo`}
                  </button>
                </form>
              </Fragment>
            );
          }}
        </Formik>
      </Fragment>
    );
  }
}

export default VehicleFeaturesForm;
