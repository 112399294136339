import React, { Component } from "react";
import classNames from "classnames";
import { InputFeedback } from "../../common/CustomInputs";

class VehicleInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.vehicle,
      errors: {
        description: false,
        features: false,
        extras: false,
        restrictions: false
      },
      touched: {
        description: false,
        features: false,
        extras: false,
        restrictions: false
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.vehicle != prevProps.vehicle) {
      this.setState(state => {
        state.data = this.props.vehicle;
        return { data: state.data };
      });
    }
  }

  handleChange = e => {
    const target = e.target;
    let value = target.value;
    this.props.handleChange(target.name, value);
  };

  handlePrev = e => {
    e.preventDefault();
    $("html, main").animate({ scrollTop: 0 }, "slow");
    this.props.jumpToStep(4);
  };

  handleBlur = e => {
    const target = e.target;
    this.setState(state => {
      state.touched[target.name] = true;
      return { touched: state.touched };
    });
  };

  render() {
    const { data, errors, touched } = this.state;
    const btn_label = this.props.isEditing
    ? "Editar vehículo"
    : "Registrar vehículo";
    return (
      <React.Fragment>
        <div className="my-0 step-names">
          <div className="step-title">
            <span>Datos del vehículo</span>
          </div>
          <div className="step-title">
            <span>Fotos del vehículo</span>
          </div>
          <div className="step-title">
            <span>Documentos</span>
          </div>
          <div className="step-title">
            <span>Accesorios</span>
          </div>
          {/* <div className="step-title">
            <span>Políticas</span>
          </div> */}
        </div>
        <h1 className="my-4" style={{ textAlign: "center" }}>
          Por favor completa la siguiente información:
        </h1>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group mb-2">
                  <label htmlFor="description">Descripción</label>
                  <textarea
                    name="description"
                    rows="5"
                    className={
                      "form-control appcar-input " +
                      classNames({
                        "is-invalid":
                          touched.description && errors.description
                      })
                    }
                    onChange={this.handleChange}
                    value={data.description}
                    onBlur={this.handleBlur}
                  />
                  <InputFeedback
                    error={touched.description && errors.description}
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group mb-2">
                  <label htmlFor="features">Características</label>
                  <textarea
                    name="features"
                    rows="5"
                    className={
                      "form-control appcar-input " +
                      classNames({
                        "is-invalid":
                          touched.features && errors.features
                      })
                    }
                    onChange={this.handleChange}
                    value={data.features}
                    onBlur={this.handleBlur}
                  />
                  <InputFeedback
                    error={touched.features && errors.features}
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group mb-2">
                  <label htmlFor="extras">Extras</label>
                  <textarea
                    name="extras"
                    rows="5"
                    className={
                      "form-control appcar-input " +
                      classNames({
                        "is-invalid":
                          touched.extras && errors.extras
                      })
                    }
                    onChange={this.handleChange}
                    value={data.extras}
                    onBlur={this.handleBlur}
                  />
                  <InputFeedback
                    error={touched.extras && errors.extras}
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group mb-2">
                  <label htmlFor="restrictions">Restricciones</label>
                  <textarea
                    name="restrictions"
                    rows="5"
                    className={
                      "form-control appcar-input " +
                      classNames({
                        "is-invalid":
                          touched.restrictions && errors.restrictions
                      })
                    }
                    onChange={this.handleChange}
                    value={data.restrictions}
                    onBlur={this.handleBlur}
                  />
                  <InputFeedback
                    error={touched.restrictions && errors.restrictions}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-buttons">
          <button
            className="btn btn-prev btn-appcar-primary btn-lg pull-left"
            onClick={e => this.handlePrev(e)}
          >
            Anterior
          </button>
          <button
            className="btn btn-next btn-appcar-primary btn-lg pull-right"
            onClick={e => this.props.handleSubmit(e)}
          >
            {btn_label}
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default VehicleInformation;
