import React from "react";
import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import SuperOwnerBadge from "images/Icons/super_owner_badge.png";
import iconNoImage from "images/Icons/NoImagePinkBG.svg";

const VehicleCarousel = ({ images, isSuperOwner = false }) => {
  const hasImages = Array.isArray(images) && typeof images[0] == "string";
  if (!hasImages) images.push(iconNoImage);
  const slides = (images || []).map((x, i) => (
    <SwiperSlide key={i}>
      <img src={x} className={`image-vehicle-slide ${!hasImages ? "no-image" : ""}`} />
    </SwiperSlide>
  ));
  const superOwnerBadge = isSuperOwner ? (
    <div className="badge-super-owner">
      <img src={SuperOwnerBadge} />
    </div>
  ) : null;
  return (
    <div className="vehicle-carousel-container">
      {superOwnerBadge}
      <Swiper
        modules={[Navigation, Pagination, A11y]}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        className="vehicle-carousel"
      >
        {slides}
      </Swiper>
    </div>
  );
};

export default VehicleCarousel;
