import React from "react";
import moment from "moment";
import StartedIcon from "images/Icons/StartedIcon.png";
import PendingIcon from "images/Icons/PendingIcon.png";
import ConfirmedIcon from "images/Icons/ConfirmedIcon.png";
import { BookingStatus } from "../../common/constants";
import iconNoImage from "images/Icons/NoImageWhiteBG.svg";
moment.locale("es");

const BookingCard = ({ booking }) => {
  const vehicle = booking.vehicle;
  const brand = vehicle.vehicle_type && vehicle.vehicle_type.brand;
  const model = vehicle.vehicle_type && vehicle.vehicle_type.model;
  const year = vehicle.year;
  const hasImages =
    Array.isArray(vehicle.vehicle_pictures) &&
    typeof vehicle.vehicle_pictures[0] == "string";
  const vehicleImage = hasImages
    ? vehicle.vehicle_pictures[0] + "/thumb"
    : iconNoImage;
  const owner = vehicle.associated_users[0];
  const from = moment(booking.date_from);
  const until = moment(booking.date_until);
  const bookingStatus = booking.status.id;
  const bookingStatusDesc = booking.status.description;
  let bookingIcon = null;
  switch (bookingStatus) {
    case BookingStatus.PENDIENTE || BookingStatus.EN_REVISION:
      bookingIcon = PendingIcon;
      break;
    case BookingStatus.INICIADO:
      bookingIcon = StartedIcon;
      break;
    default:
      bookingIcon = ConfirmedIcon;
  }
  const userRating = [];
  for (let i = 1; i <= 5; i++) {
    const icon =
      i <= owner.admin_rating ? (
        <i className="mdi mdi-star" key={i} />
      ) : (
        <i className="mdi mdi-star-outline" key={i} />
      );
    userRating.push(icon);
  }
  return (
    <>
      <div
        className="booking-card"
        onClick={() => window.open(`/bookings/${booking.id}`, "_blank")}
      >
        <div className="vehicle-plate">
          <i className="mdi mdi-car text-appcar-secondary fs-12" />
          <span className="plate">{vehicle.plate_number}</span>
        </div>
        <div className="vehicle-profile-info">
          <div className="vehicle-main">
            <img
              src={vehicleImage}
              className={`vehicle-image ${!hasImages ? "no-image" : ""}`}
            />
            <div className="vehicle-title">
              <span className="d-block fw-800">{brand}</span>
              <span className="d-block">{model}</span>
              <span className="d-block">{year}</span>
            </div>
          </div>
          <div className="booking-info">
            <div className="dates">
              <i className="mdi mdi-calendar-clock fs-18 text-appcar-secondary" />
              <div className="from-to">
                <div className="item">
                  <span className="d-block">
                    <span className="fw-700">
                      {from.format("DD MMM").replace(/\./g, "")}
                    </span>
                    &nbsp;
                    <span>{from.format("HH:mm")}</span>
                  </span>
                </div>
                <div className="item">
                  <span className="d-block">
                    <span className="fw-700">
                      {until.format("DD MMM").replace(/\./g, "")}
                    </span>
                    &nbsp;
                    <span>{until.format("HH:mm")}</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="owner-data">
              <span className="d-block owner-nickname">{owner.nickname}</span>
              <div className="owner-rating">{userRating}</div>
            </div>
            <div className="desk booking-status">
              <img src={bookingIcon} className="booking-status-icon" />
              <span className="d-block booking-status-description">
                {bookingStatusDesc}
              </span>
            </div>
          </div>
        </div>
        <div className="mobile">
          <div className="booking-status">
            <img src={bookingIcon} className="booking-status-icon" />
            <span className="d-block booking-status-description">
              {bookingStatusDesc}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingCard;
