import React, { Fragment } from "react";
import { constants, numeral } from "../constants";
import Select from "react-select";
import RangeSlider from "../RangeSlider";
import LocationSelector from "./LocationSelector";
import DateTimePicker from "./DateTimePicker";

const RentalsSearch = ({
  //Principal filters
  address,
  handleSelectLocation,
  fromDate,
  fromTime,
  handleChangeTimeFrom,
  untilDate,
  untilTime,
  handleChangeTimeUntil,
  showCheckMap,
  showMap,
  handleChangeShowMap,
  showCheckFilters,
  showFilters,
  handleChangeShowFilters,
  chkAirport,
  showCheckAirport,
  handleCheckAirport,
  handleSearch,
  loadSpinner,

  //Secondary Filters
  vehicleCategories,
  vehicleTransmissions,
  vehicleFeatures,
  vehicleExtras,
  selectedCategories,
  selectedTransmissions,
  selectedFeatures,
  selectedExtras,
  handleChangeCategories,
  handleChangeTransmissions,
  handleChangeFeatures,
  handleChangeExtras,
  priceMin,
  priceMax,
  priceMinUpdate,
  priceMaxUpdate,
  handleChangePriceRange,
  handleUpdatePriceRange,
  resetFilters
}) => {
  //options
  const vehicle_features_options = vehicleFeatures.map(v => ({
    label: v.a_name,
    value: v.id
  }));

  const vehicle_extras_options = vehicleExtras.map(v => ({
    label: v.a_name,
    value: v.id
  }));

  const vehicle_transmissions_options = vehicleTransmissions.map(
    v => ({
      label: v.description,
      value: v.id
    })
  );

  const vehicle_categories_options = vehicleCategories.map(v => ({
    label: v.description,
    value: v.id
  }));

  const filtersClass = showFilters ? "show-anc-filters" : "";

  let selectedFilters = [];
  if (priceMin != constants.MIN_PRICE || priceMax != constants.MAX_PRICE) {
    selectedFilters.push(
      <Fragment key={0}>
        <strong>Rango de precios:</strong> {numeral(priceMin).format("$0,0")}{" "}
        - {numeral(priceMax).format("$0,0")}
        {priceMax == constants.MAX_PRICE ? "+" : ""}
      </Fragment>
    );
  }
  if (selectedCategories && selectedCategories.length > 0) {
    selectedFilters.push(
      <Fragment key={1}>
        <strong>Categorías:</strong>{" "}
        {selectedCategories.map(x => x.label).join(", ")}
      </Fragment>
    );
  }
  if (selectedTransmissions && selectedTransmissions.length > 0) {
    selectedFilters.push(
      <Fragment key={2}>
        <strong>Transmisiones:</strong>{" "}
        {selectedTransmissions.map(x => x.label).join(", ")}
      </Fragment>
    );
  }
  if (selectedFeatures && selectedFeatures.length > 0) {
    selectedFilters.push(
      <Fragment key={3}>
        <strong>Características:</strong>{" "}
        {selectedFeatures.map(x => x.label).join(", ")}
      </Fragment>
    );
  }
  if (selectedExtras && selectedExtras.length > 0) {
    selectedFilters.push(
      <Fragment key={4}>
        <strong>Extras:</strong>{" "}
        {selectedExtras.map(x => x.label).join(", ")}
      </Fragment>
    );
  }
  let containerFilterText =
    !showFilters && selectedFilters.length > 0 ? (
      <div className="row">
        <div className="col">
          <p>{selectedFilters.reduce((prev, curr) => [prev, "; ", curr])}</p>
        </div>
      </div>
    ) : null;

  const checkAirportContainer = showCheckAirport
    ? (
      <div className="custom-control custom-checkbox mt-2">
        <input
          type="checkbox"
          className="custom-control-input"
          id="chkAirport"
          name="chkAirport"
          checked={chkAirport}
          onChange={handleCheckAirport}
        />
        <label className="custom-control-label" htmlFor="chkAirport">
          Entrega / Devolución en Aeropuerto
        </label>
      </div>
    ) : null
  const showMapContainer = showCheckMap
    ? (
      <div className="mr-2 d-none d-sm-block">
        <label className="d-block">Mapa</label>
        <input
          type="checkbox"
          id="show_map"
          name="show_map"
          checked={showMap}
          onChange={e => handleChangeShowMap(e.target.checked)}
          data-switch="primary"
        />
        <label
          htmlFor="show_map"
          data-on-label=""
          data-off-label=""
          className="mb-0"
        />
      </div>
    ) : null;
  const showFiltersContainer = showCheckFilters
    ? (
      <div className="mr-2">
        <label className="d-block">+ Filtros</label>
        <input
          type="checkbox"
          id="show_filters"
          name="show_filters"
          checked={showFilters}
          onChange={e => handleChangeShowFilters(e.target.checked)}
          data-switch="primary"
        />
        <label
          htmlFor="show_filters"
          data-on-label=""
          data-off-label=""
          className="mb-0"
        />
      </div>
    ) : null;
  const countries = ["cl"];
  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-6 col-lg-3">
          <div className="form-group">
            <LocationSelector
              countries={countries}
              handleSelectLocation={handleSelectLocation}
              placeholder="Dónde quieres recoger tu auto"
              label="Dirección de entrega"
              airportsFirst={chkAirport}
              loadSpinner={loadSpinner}
            />
            {checkAirportContainer}
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="form-group">
            <label>Desde</label>
            <DateTimePicker
              changeTime={handleChangeTimeFrom}
              date={fromDate}
              pickerId="search_from_"
              time={fromTime}
            />
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="form-group">
            <label>Hasta</label>
            <DateTimePicker
              changeTime={handleChangeTimeUntil}
              date={untilDate}
              pickerId="search_until_"
              time={untilTime}
            />
          </div>
        </div>
        <div
          className="col-lg-3 col-sm-6"
          style={{ display: "flex", justifyContent: "start" }}
        >
          <button
            className="btn-airncar-yellow mr-2"
            onClick={handleSearch}
          >
            Ir
          </button>
          {showMapContainer}
          {showFiltersContainer}
        </div>
      </div>
      {containerFilterText}
      <div className={"anc-filters " + filtersClass}>
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="form-group mb-3 pb-4 pb-lg-0">
              <label htmlFor="range-price" className="mb-2">
                Rango de precios (
                      {numeral(priceMinUpdate).format("$0,0")} -{" "}
                {numeral(priceMaxUpdate).format("$0,0")}
                {priceMaxUpdate == constants.MAX_PRICE ? "+" : ""})
                    </label>
              <RangeSlider
                domain={[constants.MIN_PRICE, constants.MAX_PRICE]}
                step={constants.RANGE_STEPS}
                defaultValues={[constants.MIN_PRICE, constants.MAX_PRICE]}
                values={[priceMin, priceMax]}
                onChange={handleChangePriceRange}
                onUpdate={handleUpdatePriceRange}
              />
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="categories">Categoría</label>
              <Select
                value={selectedCategories}
                options={vehicle_categories_options}
                clearable={true}
                isMulti={true}
                placeholder="Todas las categorías"
                noOptionsMessage={() => "No existen categorías"}
                onChange={handleChangeCategories}
                closeMenuOnSelect={false}
                classNamePrefix="RS-FIX"
                className="react-select-fix"
                id="categories"
              />
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="transmissions">Transmisión</label>
              <Select
                value={selectedTransmissions}
                options={vehicle_transmissions_options}
                clearable={true}
                isMulti={true}
                placeholder="Todo tipo"
                noOptionsMessage={() => "No existen tipos de transmisión"}
                onChange={handleChangeTransmissions}
                closeMenuOnSelect={false}
                classNamePrefix="RS-FIX"
                className="react-select-fix"
                id="transmissions"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="features">Características</label>
              <Select
                value={selectedFeatures}
                options={vehicle_features_options}
                clearable={true}
                isMulti={true}
                placeholder="Todas las características"
                noOptionsMessage={() => "No existen características"}
                onChange={handleChangeFeatures}
                closeMenuOnSelect={false}
                classNamePrefix="RS-FIX"
                className="react-select-fix"
                id="features"
              />
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="extras">Extras</label>
              <Select
                value={selectedExtras}
                options={vehicle_extras_options}
                clearable={true}
                isMulti={true}
                placeholder="Todos los extras"
                noOptionsMessage={() => "No existen extras"}
                onChange={handleChangeExtras}
                closeMenuOnSelect={false}
                classNamePrefix="RS-FIX"
                className="react-select-fix"
                id="extras"
              />
            </div>
          </div>
          <div
            className="col-lg-3 col-sm-6"
            style={{ display: "flex", alignItems: "center" }}
          >
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => resetFilters()}
            >
              Restablecer filtros
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default RentalsSearch;