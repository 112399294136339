import React from "react";
import PaymentFailedIcon from "images/Icons/PaymentFailedIcon.png";

const FailedPaymentModal = () => (
  <div
    className="modal fade"
    id="failedPaymentModal"
    aria-hidden="true"
    aria-labelledby="FailedPaymentModalLabel"
    tabIndex={-1}
  >
    <div className="modal-dialog modal-dialog-centered modal-appcar">
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="btn-m-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            x
          </button>
          <h5 className="modal-title" id="FailedPaymentModalLabel">
            Problema en pago
          </h5>
        </div>
        <div className="modal-body">
          <div className="row justify-content-center">
            <div className="col-auto text-center">
              <img
                src={PaymentFailedIcon}
                height="48px"
                className="mt-3 mb-3"
              />
              <h5 className="mb-4 fs-25 fw-900">¡Oh oh!</h5>
              <p className="fs-16 fw-600 px-3">
                Tuvimos un problema con tu medio de pago. Alguien de nuestro
                equipo de soporte te contactará por WhatsApp para poder
                continuar.
              </p>
            </div>
          </div>
          <div className="row mx-auto" style={{ width: 274 }}>
            <div className="col-12">
              <button
                type="button"
                className="btn btn-appcar-primary btn-slim w-100"
                data-bs-dismiss="modal"
                data-dismiss="modal"
              >
                Aceptar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default FailedPaymentModal;
