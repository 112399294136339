import React, { Component } from "react";
import dayjs from "dayjs";
import "dayjs/locale/es";

class VehicleReviews extends Component {
  render() {
    const reviews = this.props.reviews.map((review, i) => {
      const rating = [];
      for (let i = 1; i <= 5; i++) {
        const r_icon =
          i <= review.rating ? (
            <i className="mdi mdi-star" key={i} />
          ) : (
            <i className="mdi mdi-star-outline" key={i} />
          );
        rating.push(r_icon);
      }
      const reviewer_info = (
        <div className="reviewer-info">
          <div className="reviewer-name">{review.user.name} {review.user.last_name.charAt(0)}.</div>
          <div className="review-date">
            {dayjs(review.date)
              .locale("es")
              .format("MMM DD, YYYY")}
          </div>
        </div>
      );
      return (
        <div className="review-container my-3" key={i}>
          <div className="row">
            <div className="col-4">
              <div
                className="reviewer-image"
                style={{
                  backgroundImage: `url("${review.user.user_image.url}")`
                }}
              />
            </div>
            <div className="col-8">
              <div className="vehicle-info-review">
                <p>{review.review}</p>
              </div>
              <div className="review-rating">{rating}</div>
              {reviewer_info}
            </div>
          </div>
        </div>
      );
    });
    return (
      <div className="vehicle-info-reviews mb-4">
        <h2 style={{ textAlign: "center" }}>REVIEWS</h2>
        {reviews}
      </div>
    );
  }
}

export default VehicleReviews;
