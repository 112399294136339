import React, { Component } from "react";
import { numeral } from "../constants";
import Modal from "../components/modal/Modal";

class LegalModal extends Component {
  state = {
    checked: false,
    error: false,
    secondChecked: false,
    secondError: false
  };

  changeAccept = checked => this.setState({ checked });
  changeSecondAccept = secondChecked => this.setState({ secondChecked });

  render() {
    const InputFeedback = ({ error }) =>
      error ? (
        <div className="invalid-feedback" style={{ display: "block" }}>
          {error}
        </div>
      ) : null;
    const {
      body,
      title,
      checkboxLabel,
      checkboxError,
      btnText,
      elementId,
      secondCheckboxLabel,
      secondCheckboxError,
      isSubleaseAgreement
    } = this.props;
    const modal_content = body;
    const body_separator = body ? <hr /> : null;

    const total_price = isSubleaseAgreement ?
      (
        <div
          className="card d-inline d-lg-none"
          style={{
            borderStyle: "solid",
            borderWidth: 1,
            marginBottom: 0,
          }}
        >
          <div className="card-body px-2 py-0">
            <h4>TOTAL: {numeral(this.props.totalPrice).format("$ 0,0")}</h4>
          </div>
        </div>
      ) : null;

    const secondCheckBox = secondCheckboxLabel ? (
      <div className="col-12">
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="second_check"
              checked={this.state.secondChecked}
              onChange={e => this.changeSecondAccept(e.target.checked)}
            />
            <label className="custom-control-label" htmlFor="second_check">
              {secondCheckboxLabel}
            </label>
            <InputFeedback error={this.state.error} />
          </div>
        </div>
      </div>
    ) : null;

    let check = false;
    if (!!secondCheckboxLabel) {
      check = this.state.checked && this.state.secondChecked;
    } else check = this.state.checked;

    const modalBody = (
      <>
        <div id="anchor" />
        {modal_content}
        {body_separator}
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="chk_terms_and_conditions"
                  checked={this.state.checked}
                  onChange={e => this.changeAccept(e.target.checked)}
                />
                <label
                  className="custom-control-label"
                  htmlFor="chk_terms_and_conditions"
                >
                  {checkboxLabel}
                </label>
                <InputFeedback error={this.state.error} />
              </div>
            </div>
          </div>
          {secondCheckBox}
        </div>
      </>
    );

    const modalFooter = (
      <>
        {total_price}
        <button
          type="button"
          className="btn btn-appcar-primary"
          id="confirm-btn"
          disabled={!check}
          onClick={e => {
            e.preventDefault();
            if (check) {
              this.props.action();
              $(`#${elementId}`).modal("hide");
              this.setState({
                error: false,
                secondError: false,
                checked: false,
                secondChecked: false
              });
              window.location.hash = "#anchor";
            } else {
              this.setState(state => {
                const error = checkboxError;
                const secondError =
                  !!secondCheckboxLabel && secondCheckboxError;
                return {
                  error,
                  secondError
                };
              });
            }
          }}
        >
          {btnText}
        </button>
      </>
    )
    return (
      <Modal
        title={title}
        id={elementId}
        hasHeader={true}
        hasBody={true}
        hasFooter={true}
        body={modalBody}
        footer={modalFooter}
        closeable={true}
        dismissOnOutsideClick={true}
        labelledBy="confirm-title"
        modalClass="modal-lg modal-dialog-centered modal-lg-bigger"
        bodyClass="px-4"
      />
    );
  }
}

export default LegalModal;
