import React, { Component } from "react";
import RentalInfoWindow from "./RentalInfoWindow";
import { Marker } from "react-google-maps";
import markerActiveIcon from "images/MapMarkerActive.png";
import markerIcon from "images/MapMarker.png";

const RentalMarker = ({
  lat,
  lng,
  name,
  photoUrl,
  id,
  showInfo,
  mapNotLoaded,
  visitLink,
  closeMarker,
  markerClick,
  highlighted,
  rental,
  openModalPricing,
  dayOfWeek = 0,
  hasImage = true
}) => {
  const scaledSize =
    highlighted || showInfo
      ? new google.maps.Size(48, 53)
      : new google.maps.Size(31, 35);
  const anchor =
    highlighted || showInfo
      ? new google.maps.Point(24, 53)
      : new google.maps.Point(15.5, 35);
  const marker = !mapNotLoaded ? (
    <Marker
      icon={{
        url: highlighted || showInfo ? markerActiveIcon : markerIcon,
        scaledSize,
        anchor,
      }}
      zIndex={highlighted || showInfo ? 3 : 1}
      position={{
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      }}
      onClick={() => markerClick(rental.location.name, id)}
    />
  ) : null;
  return (
    <React.Fragment>
      {marker}
      {showInfo && (
        <RentalInfoWindow
          rental={rental}
          photoUrl={photoUrl}
          visitLink={visitLink}
          highlighted={highlighted}
          closeWindow={() => closeMarker()}
          position={{
            lat: parseFloat(lat),
            lng: parseFloat(lng),
          }}
          dayOfWeek={dayOfWeek}
          openModalPricing={openModalPricing}
          hasImage={hasImage}
        />
      )}
    </React.Fragment>
  );
};

export default RentalMarker;
