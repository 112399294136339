import React from "react";
import DateTimePicker from "../../../../common/search_components/AppcarDateTimePicker";

const ModalDates = ({
  changeFromTime,
  changeUntilTime,
  fromDate,
  fromTime,
  untilDate,
  untilTime,
  alertOverlap,
  isBookingStarted = false
}) => (
  <div
    className="modal fade"
    id="datesModal"
    aria-hidden="true"
    aria-labelledby="datesModalLabel"
    tabIndex={-1}
  >
    <div className="modal-dialog modal-dialog-centered modal-appcar">
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="btn-m-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            x
          </button>
          <h5 className="modal-title" id="datesModalLabel">
            Modificar fechas de arriendo
          </h5>
        </div>
        <div className="modal-body modal-body-less-padding">
          <div className="modal-dates-body">
            <div className="form-group mb-3">
              <label>Desde</label>
              <DateTimePicker
                changeTime={(option) => changeFromTime(option.value)}
                date={fromDate}
                pickerId="date_from"
                time={fromTime}
                isDisabled={isBookingStarted}
              />
            </div>
            <div className="form-group mt-3">
              <label>Hasta</label>
              <DateTimePicker
                changeTime={(option) => changeUntilTime(option.value)}
                date={untilDate}
                pickerId="date_until"
                time={untilTime}
              />
            </div>
            {alertOverlap}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ModalDates;
