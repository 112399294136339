import React, { Component } from "react";
import Workspace from "./Workspace";
import Scheduler from "./Scheduler";
import Spinner from "../../common/Spinner";
import FlashMessage from "../../common/FlashMessage";
import axios from "axios";
import Moment from "moment";
import { extendMoment } from "moment-range";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/core/main.css";
import "@fullcalendar/list/main.css";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import LegalModal from "../../common/legal_modals/LegalModal";
import {
  leaseAgreementText,
  BookingStatus,
  constants,
} from "../../common/constants";
import ListView from "./ListView";
import { AxiosApplicationInterceptor } from "../../common/axios_interceptor";
import CompareBookings from "./CompareBookings";
import Select from "react-select";
axios.interceptors.response.use(undefined, AxiosApplicationInterceptor);
const moment = extendMoment(Moment);
moment.locale("es");

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicle: {},
      rental: {},
      vehicles: this.props.vehicles,
      bookings: [],
      events: [],
      fecha: null,
      event: {},
      selected_booking: null,
      cancelling_booking: null,
      original_booking: undefined,
      can_edit_booking: this.props.vehicles.length > 1,
      new_booking: undefined,
      ui: {
        isSubmitting: false,
        showMessage: false,
        messageType: undefined,
        messages: [],
        messageTitle: undefined,
      },
      workspace_ui: {
        showMessage: false,
        messageType: undefined,
        messages: [],
        messageTitle: undefined,
      },
      accepting_booking: null,
      all_bookings: [],
      selectedCategories: [],
      blockedCategoriesRange: {
        start: moment().format(),
        end: moment().format(),
      },
    };
    this.changeFecha = this.changeFecha.bind(this);
  }

  changeBooking = (selected_booking) => {
    const booking = this.state.bookings.find(
      (booking) => booking.id == selected_booking
    );
    this.setState(
      {
        selected_booking,
        selectedCategories: [],
        blockedCategoriesRange: {
          start: booking
            ? moment(booking.date_from).format()
            : moment().format(),
          end: booking
            ? moment(booking.date_until).format()
            : moment().format(),
        },
      },
      () => {
        if (booking) {
          const el = $("#dateRangeCategories").data("daterangepicker");
          el.minDate = moment(booking.date_from)
            .add(-5, "d")
            .startOf("d");
          el.maxDate = moment(booking.date_until)
            .add(5, "d")
            .endOf("d");
          el.setStartDate(moment(booking.date_from));
          el.setEndDate(moment(booking.date_until));
          this.changeFecha(moment(booking.date_from).toDate(), true);
        }
      }
    );
  };

  compareBookings = (original_booking_id, booking_id) => {
    const original_booking = this.state.all_bookings.find(
      (booking) => booking.id === original_booking_id
    );
    const new_booking = this.state.bookings.find(
      (booking) => booking.id === booking_id
    );
    this.setState({ original_booking, new_booking });
    $("#modal-compare-bookings").modal("show");
  };

  changeFecha = (fecha, refresh_booking_id) => {
    if (!!this.state.vehicle.id && !!this.state.rental.date_from) {
      const range = moment().range(
        this.state.rental.date_from,
        this.state.rental.date_until
      );
      let booked = false;
      const bookings = this.state.bookings;
      let booking = null;
      for (let k = 0; k < bookings.length; k++) {
        const range_b = moment().range(
          moment(bookings[k].date_from)
            .startOf("day")
            .format(),
          moment(bookings[k].date_until)
            .endOf("day")
            .format()
        );
        if (range_b.contains(fecha)) {
          booked = true;
          booking = bookings[k];
          break;
        }
      }

      if (!refresh_booking_id) {
        this.setState({ selected_booking: null });
        if (booking) {
          this.setState({
            selectedCategories: [],
            blockedCategoriesRange: {
              start: moment(booking.date_from).format(),
              end: moment(booking.date_until).format(),
            },
          });
        }
      }

      if (range.contains(fecha) || booked) {
        this.setState({ fecha }, () => {
          this.showMessage({
            obj: "workspace_ui",
            showMessage: false,
            messageType: "",
            messages: [],
            messageTitle: "",
          });
        });
      } else {
        this.setState({ fecha: null }, () => {
          this.showMessage({
            obj: "workspace_ui",
            showMessage: true,
            messageType: "alert-info",
            messages: [
              "La fecha seleccionada no está dentro del rango de la publicación",
            ],
            messageTitle: "Información",
          });
        });
      }
    } else {
      $("html, main").animate({ scrollTop: 0 }, "slow");
      this.showMessage({
        obj: "ui",
        showMessage: true,
        messageType: "alert-info",
        messages: ["Primero selecciona un vehículo publicado"],
        messageTitle: "Información",
      });
    }
  };

  loadEvents = (vehicle_id, vehicle) => {
    this.setState((state) => {
      state.ui.isSubmitting = true;
      // state.ui.showMessage = false;
      return { ui: state.ui };
    });
    axios({
      method: "get",
      url: this.props.getRentalDataLink(vehicle_id),
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.data.success && response.data.rental) {
          let rental = response.data.rental;
          const all_bookings = response.data.all_bookings;
          const bookings = (response.data.bookings || []).filter(
            (b) =>
              b.status.id != BookingStatus.REEMPLAZADO &&
              b.status.id != BookingStatus.CANCELADO
          );
          rental.pricing.custom_days = rental.pricing.custom_days || [];
          rental.availability.blackout_days =
            rental.availability.blackout_days || [];
          let events = bookings.map((booking, id) => {
            return {
              id: id,
              title: `${
                booking.status.id == BookingStatus.EN_REVISION &&
                booking.base_booking_id
                  ? "Modificación de reserva de "
                  : "Reserva de"
              } ${booking.user[0].name} ${booking.user[0].last_name}`,
              start: moment(booking.date_from).format(),
              end: moment(booking.date_until)
                // .add(1, "s")
                .format(),
              // allDay: true,
              backgroundColor:
                booking.status.id == BookingStatus.PENDIENTE ||
                booking.status.id == BookingStatus.EN_REVISION
                  ? "#ffa722"
                  : "#FF543E",
              textColor: "#FFF",
              opacity: 1,
              extendedProps: {
                bookingId: booking.id,
              },
            };
          });
          let custom_days = rental.pricing.custom_days.map((c_day, i) => {
            return {
              id: i + events.length,
              title: `Precio especial, razón: ${c_day.reason}`,
              start: moment(c_day.start).format(),
              end: moment(c_day.end)
                // .add(1, "s")
                .format(),
              // allDay: true,
              backgroundColor: "#db931b",
              textColor: "#325283",
            };
          });
          events.push(...custom_days);
          let blackout_days = rental.availability.blackout_days.map(
            (b_day, i) => {
              return {
                id: i + events.length,
                title: `Reserva bloqueada, razón: ${b_day.reason}`,
                start: moment(b_day.start).format(),
                end: moment(b_day.end)
                  // .add(1, "s")
                  .format(),
                // allDay: true,
                backgroundColor: "#6c757d",
                textColor: "#FFF",
              };
            }
          );
          events.push(...blackout_days);
          const rental_range = {
            id: 1 + events.length,
            start: moment(rental.date_from).format(),
            end: moment(rental.date_until)
              .add(1, "s")
              .format(),
            allDay: true,
            rendering: "inverse-background",
            backgroundColor: "#c8c9cc",
            title:
              rental.pricing.weekend_price + "%%%%" + rental.pricing.base_price,
          };
          events.push(rental_range);
          this.setState({ rental, vehicle, bookings, events, all_bookings });
        } else {
          const all_bookings = response.data.all_bookings || [];
          this.showMessage({
            obj: "ui",
            showMessage: true,
            messageType: "light",
            messages: response.data.success
              ? ["Error: No existe publicación para este vehículo"]
              : response.data.messages,
            messageTitle: "",
          });
          this.setState({
            rental: {},
            events: [],
            bookings: [],
            all_bookings,
            fecha: null,
          });
          $("html, main").animate({ scrollTop: 0 }, "slow");
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          rental: {},
          events: [],
          bookings: [],
          fecha: null,
        });
        this.showMessage({
          obj: "ui",
          showMessage: true,
          messageType: "primary",
          messages: ["Error: Ha ocurrido un error inesperado"],
          messageTitle: "",
        });
      })
      .finally(() => {
        this.setState((state) => {
          state.ui.isSubmitting = false;
          return { ui: state.ui };
        });
      });
  };

  handleAcceptBooking = (booking_id) => {
    this.setState({ accepting_booking: booking_id }, () => {
      $("html, main").animate({ scrollTop: 0 }, "slow");
      $("#modal-lease-agreement").modal("show");
    });
  };

  confirmBooking = () => {
    this.setState((state) => {
      state.ui.isSubmitting = true;
      return { ui: state.ui };
    });
    const booking_id = this.state.accepting_booking;
    const vehicle = this.state.vehicle;
    axios({
      method: "post",
      url: this.props.confirmBookingLink(booking_id),
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.data.result.success) {
          this.showMessage({
            obj: "ui",
            showMessage: true,
            messageType: "alert-success",
            messages: [""],
            messageTitle: "Reserva aceptada",
          });
          this.loadEvents(vehicle.id, vehicle);
        } else {
          this.setState(
            (state) => {
              state.ui.isSubmitting = false;
              return { ui: state.ui };
            },
            () => {
              this.showMessage({
                obj: "ui",
                showMessage: true,
                messageType: "alert-danger",
                messages: response.data.result.messages,
                messageTitle: "Error",
              });
            }
          );
        }
      })
      .catch((error) => {
        this.setState(
          (state) => {
            state.ui.isSubmitting = false;
            return { ui: state.ui };
          },
          () => {
            this.showMessage({
              obj: "ui",
              showMessage: true,
              messageType: "alert-danger",
              messages: ["Ha ocurrido un error inesperado"],
              messageTitle: "Error",
            });
          }
        );
      })
      .finally(() => this.setState({ accepting_booking: null }));
  };

  cancelBooking = (booking_id) => {
    this.setState((state) => {
      state.ui.isSubmitting = true;
      return { ui: state.ui };
    });

    const vehicle = this.state.vehicle;
    let categories = (this.state.selectedCategories || []).map((x) => x.value);
    let block_start = this.state.blockedCategoriesRange.start;
    let block_end = this.state.blockedCategoriesRange.end;
    axios({
      method: "post",
      url: this.props.cancelBookingLink(booking_id),
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        categories,
        block_start,
        block_end,
      },
    })
      .then((response) => {
        if (response.data.result.success) {
          this.showMessage({
            obj: "ui",
            showMessage: true,
            messageType: "alert-success",
            messages: [""],
            messageTitle: "Reserva cancelada",
          });
          this.loadEvents(vehicle.id, vehicle);
        } else {
          this.setState(
            (state) => {
              state.ui.isSubmitting = false;
              return { ui: state.ui };
            },
            () => {
              this.showMessage({
                obj: "ui",
                showMessage: true,
                messageType: "alert-danger",
                messages: response.data.result.messages,
                messageTitle: "Error",
              });
            }
          );
        }
      })
      .catch((error) => {
        this.setState(
          (state) => {
            state.ui.isSubmitting = false;
            return { ui: state.ui };
          },
          () => {
            this.showMessage({
              obj: "ui",
              showMessage: true,
              messageType: "alert-danger",
              messages: ["Ha ocurrido un error inesperado"],
              messageTitle: "Error",
            });
          }
        );
      })
      .finally(() => {
        this.setState((state) => {
          state.cancelling_booking = null;
          return { cancelling_booking: state.cancelling_booking };
        });
      });
  };

  handleCancelBooking = (booking_id) => {
    this.setState(
      (state) => {
        state.cancelling_booking = booking_id;
        return { cancelling_booking: state.cancelling_booking };
      },
      () => {
        $("html, main").animate({ scrollTop: 0 }, "slow");
        const booking = this.state.all_bookings.find((x) => x.id == booking_id);
        if (booking && booking.status.id == BookingStatus.EN_REVISION) {
          $("#modal-reject-driver-edition").modal("show");
        } else {
          $("#modal-confirm-cancellation").modal("show");
        }
      }
    );
  };

  componentDidMount() {
    $("body").click(function(e) {
      if (!$(e.target).hasClass("anc-tooltip")) {
        $(".tooltiptext").removeClass("tooltip-visible");
      } else {
        var tooltipFor = "#" + e.target.dataset.toggleTooltipFor;
        $(".tooltiptext")
          .not($(tooltipFor))
          .removeClass("tooltip-visible");
        $(tooltipFor).toggleClass("tooltip-visible");
      }
    });

    const dateRangeOptions = {
      showDropdowns: true,
      timePicker: false,
      maxYear: constants.MAX_YEAR,
      locale: {
        format: "LL",
        cancelLabel: "Cancelar",
        applyLabel: "Aplicar",
        fromLabel: "Desde",
        toLabel: "Hasta",
        daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        firstDay: 1,
      },
    };

    $("#dateRangeCategories").daterangepicker(
      dateRangeOptions,
      (start, end) => {
        const start_ = moment(start)
          .startOf("day")
          .format();
        const end_ = moment(end)
          .endOf("day")
          .format();
        this.setState((state) => {
          state.blockedCategoriesRange.start = start_;
          state.blockedCategoriesRange.end = end_;
          return { blockedCategoriesRange: state.blockedCategoriesRange };
        });
      }
    );

    $("#select_vehicle")
      .select2({
        placeholder: "Selecciona un vehículo",
      })
      .on("change", (e) => {
        this.setState((state) => {
          state.ui.isSubmitting = false;
          state.ui.isSubmitting = false;
          state.ui.showMessage = false;
          state.ui.messageType = undefined;
          state.ui.messages = [];
          state.ui.messageTitle = undefined;
          state.workspace_ui.showMessage = false;
          state.workspace_ui.messageType = undefined;
          state.workspace_ui.messages = [];
          state.workspace_ui.messageTitle = undefined;
          return { ui: state.ui, workspace_ui: state.workspace_ui };
        });
        const vehicle_id = parseInt(e.target.value) || this.props.vehicleId;
        const vehicle = find(this.state.vehicles, { id: vehicle_id });
        this.loadEvents(vehicle_id, vehicle);
      });

    const vehicleId = this.props.vehicleId;
    if (vehicleId) {
      $("#select_vehicle")
        .val(vehicleId)
        .trigger("change");
    }
  }

  showMessage = (msg) => {
    this.setState((state) => {
      state[msg.obj].showMessage = msg.showMessage;
      state[msg.obj].messageType = msg.messageType;
      state[msg.obj].messages = msg.messages;
      state[msg.obj].messageTitle = msg.messageTitle;
      return { [msg.obj]: state[msg.obj] };
    });
  };

  handleConfirm = (event) => {
    this.setState({ event }, () => {
      $("#modal-confirm").modal("show");
    });
  };

  handleSubmit = (event, is_deleting = false) => {
    this.setState((state) => {
      state.ui.isSubmitting = true;
      return { ui: state.ui };
    });

    let cancel_update = false;
    // console.log(event);
    let rental = this.state.rental;
    if (event.object) {
      const object = event.object.split(".");
      if (object[1] === "blackout_days") {
        if (is_deleting) {
          rental.availability.blackout_days.splice(event.index, 1);
        } else {
          if (event.is_disabled) {
            rental.availability.blackout_days[event.index] = {
              start: event.start,
              end: event.end,
              reason: event.reason,
            };
          } else {
            if (
              rental.pricing.base_price == event.base_price &&
              rental.pricing.weekend_price == event.weekend_price
            ) {
              rental.availability.blackout_days.splice(event.index, 1);
            } else {
              rental.availability.blackout_days.splice(event.index, 1);
              const new_custom_day = {
                base_price: event.base_price,
                weekend_price: event.weekend_price,
                start: event.start,
                end: event.end,
                reason: event.reason,
              };
              rental.pricing.custom_days.push(new_custom_day);
            }
          }
        }
      } else {
        if (is_deleting) {
          rental.pricing.custom_days.splice(event.index, 1);
        } else {
          if (event.is_disabled) {
            rental.pricing.custom_days.splice(event.index, 1);
            const new_blackout_day = {
              start: event.start,
              end: event.end,
              reason: event.reason,
            };
            rental.availability.blackout_days.push(new_blackout_day);
          } else {
            if (
              rental.pricing.base_price == event.base_price &&
              rental.pricing.weekend_price == event.weekend_price
            ) {
              rental.pricing.custom_days.splice(event.index, 1);
            } else {
              rental.pricing.custom_days[event.index] = {
                base_price: event.base_price,
                weekend_price: event.weekend_price,
                start: event.start,
                end: event.end,
                reason: event.reason,
              };
            }
          }
        }
      }
    } else if (event.is_disabled) {
      const new_blackout_day = {
        start: event.start,
        end: event.end,
        reason: event.reason,
      };
      rental.availability.blackout_days.push(new_blackout_day);
    } else {
      if (
        rental.pricing.base_price != event.base_price ||
        rental.pricing.weekend_price != event.weekend_price
      ) {
        const new_custom_day = {
          base_price: event.base_price,
          weekend_price: event.weekend_price,
          start: event.start,
          end: event.end,
          reason: event.reason,
        };
        rental.pricing.custom_days.push(new_custom_day);
      } else cancel_update = true;
    }
    if (cancel_update) {
      this.setState(
        (state) => {
          state.ui.isSubmitting = false;
          return { ui: state.ui };
        },
        () => {
          this.showMessage({
            obj: "workspace_ui",
            showMessage: true,
            messageType: "alert-info",
            messages: ["No has realizado ningún cambio"],
            messageTitle: "Información",
          });
        }
      );
    } else {
      axios({
        method: "post",
        url: this.props.updateRentalLink(rental.id, rental.vehicle.id),
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
        },
        data: { rental },
      })
        .then((response) => {
          if (response.data.success && response.data.rental) {
            const all_bookings = response.data.all_bookings;
            rental = response.data.rental;
            const bookings = (response.data.bookings || []).filter(
              (b) =>
                b.status.id != BookingStatus.REEMPLAZADO &&
                b.status.id != BookingStatus.CANCELADO
            );
            rental.pricing.custom_days = rental.pricing.custom_days || [];
            rental.availability.blackout_days =
              rental.availability.blackout_days || [];
            let events = bookings.map((booking, id) => {
              return {
                id: id,
                title: `${
                  booking.status.id == BookingStatus.EN_REVISION &&
                  booking.base_booking_id
                    ? "Modificación de reserva de "
                    : "Reserva de"
                } ${booking.user[0].name} ${booking.user[0].last_name}`,
                start: moment(booking.date_from).format(),
                end: moment(booking.date_until)
                  // .add(1, "s")
                  .format(),
                // allDay: true,
                backgroundColor:
                  booking.status.id == BookingStatus.PENDIENTE ||
                  booking.status.id == BookingStatus.EN_REVISION
                    ? "#0acf97"
                    : "#FF543E",
                textColor: "#FFF",
                opacity: 1,
                extendedProps: {
                  bookingId: booking.id,
                },
              };
            });
            let custom_days = rental.pricing.custom_days.map((c_day, i) => {
              return {
                id: i + events.length,
                title: `Precio especial, razón: ${c_day.reason}`,
                start: moment(c_day.start).format(),
                end: moment(c_day.end)
                  // .add(1, "s")
                  .format(),
                // allDay: true,
                backgroundColor: "#db931b",
                textColor: "#325283",
              };
            });
            events.push(...custom_days);
            let blackout_days = rental.availability.blackout_days.map(
              (b_day, i = events.length) => {
                return {
                  id: i + events.length,
                  title: `Reserva bloqueada, razón: ${b_day.reason}`,
                  start: moment(b_day.start).format(),
                  end: moment(b_day.end)
                    // .add(1, "s")
                    .format(),
                  // allDay: true,
                  backgroundColor: "#6c757d",
                  textColor: "#FFF",
                };
              }
            );
            events.push(...blackout_days);
            const rental_range = {
              id: 1 + events.length,
              start: moment(rental.date_from).format(),
              end: moment(rental.date_until)
                .add(1, "s")
                .format(),
              allDay: true,
              rendering: "inverse-background",
              backgroundColor: "#c8c9cc",
            };
            events.push(rental_range);
            this.setState({ rental, bookings, events, all_bookings });
          } else {
            this.showMessage({
              obj: "ui",
              showMessage: true,
              messageType: "alert-warning",
              messages: response.data.success
                ? ["No existe publicación para este vehículo"]
                : response.data.messages,
              messageTitle: "Error",
            });
            this.setState({
              rental: {},
              events: [],
              bookings: [],
              all_bookings: [],
              fecha: null,
            });
            $("html, main").animate({ scrollTop: 0 }, "slow");
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            rental: {},
            events: [],
            bookings: [],
            fecha: null,
            all_bookings: [],
          });
          this.showMessage({
            obj: "ui",
            showMessage: true,
            messageType: "alert-danger",
            messages: ["Ha ocurrido un error inesperado"],
            messageTitle: "Error",
          });
        })
        .finally(() => {
          this.setState((state) => {
            state.ui.isSubmitting = false;
            return { ui: state.ui };
          });
        });
    }
  };

  changeCategories = (options) => {
    this.setState({ selectedCategories: options });
  };

  render() {
    const modal_confirm = (
      <div
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="confirm-title"
        aria-hidden="true"
        id="modal-confirm"
      >
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="confirm-title">
                Confirmar eliminación
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-appcar-primary"
                id="confirm-btn"
                onClick={(e) => {
                  e.preventDefault();
                  if (!isEmpty(this.state.event)) {
                    this.handleSubmit(this.state.event, true);
                  }
                  $("#modal-confirm").modal("hide");
                }}
              >
                Si
              </button>
              <button
                type="button"
                className="btn btn-appcar-light"
                data-dismiss="modal"
                data-bs-dismiss="modal"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    const vehicleCategoriesOptions = this.props.categories.map((v) => ({
      label: v.description,
      value: v.id,
    }));

    const modal_confirm_cancellation = (
      <div
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="cancellation-title"
        aria-hidden="true"
        id="modal-confirm-cancellation"
      >
        <div className="modal-dialog modal-dialog-centered modal-appcar modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-m-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                x
              </button>
              <h5 className="modal-title" id="modal-weekend-pricing-label">
                Detalle del precio
              </h5>
            </div>
            <div
              className="modal-body"
              id="body-cancellation"
              style={{ display: "none" }}
            >
              <h4>Bloquear por categoría</h4>
              <div className="alert light">
                <i className="mdi mdi-information-outline mr-2" /> Con esto
                podrás bloquear todos tus vehículos que caigan en las categorías
                seleccionadas
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label htmlFor="dateRangeCategories">
                      Rango de fechas del bloqueo
                    </label>
                    <table
                      id="dateRangeCategories"
                      style={{ width: "100%", fontWeight: "bolder" }}
                    >
                      <tbody>
                        <tr>
                          <td style={{ color: "#203453" }}>INICIO</td>
                          <td>:</td>
                          <td>
                            {moment(
                              this.state.blockedCategoriesRange.start
                            ).format("LL")}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ color: "#203453" }}>FIN</td>
                          <td>:</td>
                          <td>
                            {moment(
                              this.state.blockedCategoriesRange.end
                            ).format("LL")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="categories">Categorías a bloquear</label>
                    <Select
                      value={this.state.selectedCategories}
                      options={vehicleCategoriesOptions}
                      clearable={true}
                      isMulti={true}
                      placeholder="Selecciona..."
                      noOptionsMessage={() => "No existen categorías"}
                      onChange={this.changeCategories}
                      closeMenuOnSelect={false}
                      classNamePrefix="RS-FIX"
                      className="react-select-fix"
                      name="categories"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-appcar-secondary pt-2 pb-2 mr-2"
                onClick={(e) => {
                  e.preventDefault();
                  $("#body-cancellation").toggle(300);
                }}
              >
                <i className="mdi mdi-chevron-up" /> Bloquear vehículos por
                categoría
              </button>
              <button
                type="button"
                className="btn btn-appcar-primary pt-2 pb-2 px-3"
                id="confirm-btn"
                onClick={(e) => {
                  e.preventDefault();
                  if (this.state.cancelling_booking) {
                    this.cancelBooking(this.state.cancelling_booking);
                  }
                  $("#modal-confirm-cancellation").modal("hide");
                }}
              >
                Si
              </button>
              <button
                type="button"
                className="btn btn-appcar-light pt-2 pb-2 px-3"
                data-dismiss="modal"
                data-bs-dismiss="modal"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    const modal_reject_driver_edition = (
      <div
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="reject-title"
        aria-hidden="true"
        id="modal-reject-driver-edition"
      >
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="reject-title">
                Rechazar modificación
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-appcar-primary"
                id="confirm-btn"
                onClick={(e) => {
                  e.preventDefault();
                  if (this.state.cancelling_booking) {
                    this.cancelBooking(this.state.cancelling_booking);
                  }
                  $("#modal-reject-driver-edition").modal("hide");
                }}
              >
                Si
              </button>
              <button
                type="button"
                className="btn btn-appcar-light"
                data-dismiss="modal"
                data-bs-dismiss="modal"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    const modalBody = leaseAgreementText(this.state.accepting_booking);
    const modalLeaseAgreement = (
      <LegalModal
        action={this.confirmBooking}
        title="Lee atentamente antes de continuar"
        checkboxLabel="Estoy de acuerdo con los términos y condiciones."
        checkboxError="Debes aceptar los términos y condiciones"
        btnText="Continuar"
        body={modalBody}
        elementId="modal-lease-agreement"
        secondCheckboxLabel="Estoy de acuerdo con el tratamiento de mis datos personales como se describe en la “Política de Privacidad I”."
        secondCheckboxError="Debes aceptar la Política de Privacidad I para continuar"
        refId={(this.state.accepting_booking || "").toString()}
      />
    );

    const modal_compare_bookings = (
      <CompareBookings
        booking={this.state.new_booking}
        originalBooking={this.state.original_booking}
      />
    );

    const flash_message = this.state.ui.showMessage ? (
      <FlashMessage
        type={this.state.ui.messageType}
        msg={this.state.ui.messages}
        title={this.state.ui.messageTitle}
      />
    ) : null;
    let vehicle_options = this.state.vehicles.map((v, i) => (
      <option value={v.id} key={i}>
        {v.vehicle_type.brand +
          " " +
          v.vehicle_type.model +
          " " +
          v.year +
          " - " +
          v.plate_number}
      </option>
    ));
    vehicle_options.unshift(<option key="000" />);

    return (
      <div className="calendar-container pb-5" style={{ marginTop: "96px" }}>
        <Spinner loading={this.state.ui.isSubmitting} />
        <div className="card" style={{ border: "none" }}>
          <div className="card-body">
            {flash_message}
            <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
              <li className="nav-item">
                <a
                  href="#calendar-view"
                  data-toggle="tab"
                  aria-expanded="false"
                  className="nav-link text-appcar-primary fw-600 active show"
                >
                  <i className="mdi mdi-calendar mr-1" />
                  <span>Calendario</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#list-view"
                  data-toggle="tab"
                  aria-expanded="false"
                  className="nav-link text-appcar-primary fw-600 "
                >
                  <i className="mdi mdi-list-bulleted mr-1" />
                  <span>Lista</span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active show" id="calendar-view">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group mb-3">
                      <label htmlFor="select_vehicle">
                        Selecciona un vehículo para consultar eventos y realizar
                        configuraciones
                      </label>
                      <select
                        id="select_vehicle"
                        name="select_vehicle"
                        // value={this.state.vehicle.id}
                        // onChange={this.changeVehicle}
                        style={{ display: "block" }}
                        className="search-cars-select"
                      >
                        {vehicle_options}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="my-2"></div>
                <div className="calendar-separator">
                  <Scheduler
                    fecha={this.state.fecha}
                    changeFecha={this.changeFecha}
                    events={this.state.events || []}
                    rental={this.state.rental}
                    changeBooking={this.changeBooking}
                    bookingId={this.state.selected_booking}
                  />
                  <Workspace
                    fecha={this.state.fecha}
                    rental={this.state.rental}
                    vehicle={this.state.vehicle}
                    bookings={this.state.bookings}
                    allBookings={this.state.all_bookings}
                    ui={this.state.workspace_ui}
                    handleSubmit={this.handleSubmit}
                    handleConfirm={this.handleConfirm}
                    handleAcceptBooking={this.handleAcceptBooking}
                    handleCancelBooking={this.handleCancelBooking}
                    compareBookings={this.compareBookings}
                    bookingId={this.state.selected_booking}
                    reload={this.loadEvents}
                    canEditBooking={this.state.can_edit_booking}
                    canOfferNewCar={this.props.canOfferNewCar}
                  />
                </div>
              </div>
              <div className="tab-pane" id="list-view">
                <ListView
                  bookings={this.state.all_bookings}
                  handleCancel={this.handleCancelBooking}
                  handleAccept={this.handleAcceptBooking}
                  compareBookings={this.compareBookings}
                />
              </div>
            </div>
          </div>
        </div>
        {modal_confirm}
        {modal_confirm_cancellation}
        {modalLeaseAgreement}
        {modal_compare_bookings}
        {modal_reject_driver_edition}
      </div>
    );
  }
}

export default Calendar;
