import React from "react";
import iconWink from "images/IconWink.png";
import appcarBrand from "images/brand/AppcarLogoVarianteB1.png";
import googlePlayBadge from "images/google-play.png";
import appStoreBadge from "images/app-store.png";

const AfterBookingModal = ({ user }) => (
  <div
    className="modal fade"
    id="AfterBookingModal"
    aria-hidden="true"
    aria-labelledby="AfterBookingModalLabel"
    tabIndex={-1}
  >
    <div className="modal-dialog modal-dialog-centered modal-appcar">
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="btn-m-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            x
          </button>
          <h5 className="modal-title" id="AfterBookingModalLabel">
            Reserva recibida
          </h5>
        </div>
        { new Date() - new Date(user.created_at) > 1000 * 60 * 60 * 24 ?
          <div className="modal-body">
            <div className="row justify-content-center">
              <div className="col-auto text-center">
                <img src={iconWink} height="42px" className="mt-3 mb-3" />
                <h5 className="mb-4 fs-25 fw-900">
                  ¡Hemos recibido <br/> tu solicitud de reserva!
                </h5>
                <p className="fs-16 fw-600 px-3">
                  Ya le avisamos al Owner y estamos a la espera de su confirmación
                  para darte una respuesta lo antes posible. <br/>
                  Es necesario tener descargada la app para contactar al Owner y
                  activar el seguro del auto el día que comienza el arriendo.
                </p>
              </div>
            </div>
            <div className="row my-4 mx-auto" style={{ width: 274 }}>
              <div className="col-12 d-flex align-items-center justify-content-between">
                <img src={appcarBrand} width={50} />
                <span className="fs-22 fw-900 text-appcar-primary">
                  Encuéntranos en:
                </span>
              </div>
            </div>
            <div className="row my-4 justify-content-between align-items-center mx-auto" style={{ width: 274 }} >
              <div className="col-auto">
                <a href="https://play.google.com/store/apps/details?id=cl.mercanding.airncars" target="_blank" >
                  <img src={googlePlayBadge} height={32} />
                </a>
              </div>
              <div className="col-auto">
                <a href="https://apps.apple.com/us/app/appcar/id1486648200" target="_blank" >
                  <img src={appStoreBadge} height={32} />
                </a>
              </div>
            </div>
            <div className="row mx-auto" style={{ width: 274 }}>
              <div className="col-12">
                <a href={`/owners/${user.id}/driver_dashboard`} className="btn btn-appcar-primary btn-slim w-100">
                  Ver mis reservas
                </a>
              </div>
            </div>
          </div>
          :
          <div className="modal-body">
            <div className="row justify-content-center">
              <div className="col-auto text-center">
                <img src={iconWink} height="42px" className="mt-3 mb-3" />
                <h5 className="mb-4 fs-25 fw-900">
                  ¡Hemos recibido <br/> tu solicitud de reserva!
                </h5>
                <p className="fs-16 fw-600 px-3">
                  Ya le avisamos al Owner y estamos a la espera de su confirmación
                  para darte una respuesta lo antes posible. <br/><br/>
                  Para poder hacer efectiva tu reserva, es necesario que completes
                  tu registro. Ten a mano tu licencia de conducir y tu cédula de identidad.
                </p>
              </div>
            </div>
            <div className="row mx-auto" style={{ width: 274 }}>
              <div className="col-12">
                <a href={`/owners/${user.id}/edit_driver`} className="btn btn-appcar-primary btn-slim w-100">
                  Completa tu registro
                </a>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
);

export default AfterBookingModal;
