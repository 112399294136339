import React from "react";
import { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

const ImageCarousel = ({ images, reportIndex, name, canDownload = false }) => {
  const slides = (images || []).map((x, i) =>
    <SwiperSlide key={i}><img src={x} className="image-vehicle-slide" /></SwiperSlide>
  )
  return (
    <Swiper
      modules={[Navigation, Pagination, A11y]}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      className="backoffice-carousel"
      slideChangeTransitionEnd={swiper => {
        if (typeof reportIndex === "function" && canDownload)
          reportIndex(swiper.realIndex, name)
      }}
    >
      {slides}
    </Swiper>
  )
}

export default ImageCarousel;