import React from "react";

export default SubleaseAgreementText = ({
  bookingId,
  rentalId,
  userId,
  from,
  to,
  total_price,
  pickup,
  dropoff,
  insuranceCharge,
  clfValue,
  ownerTotal,
  discount,
  cancellationPolicy,
  fuelPolicy,
  selectedExtras = []
}) => {
  const pickupParam = pickup ? "&pickup=" + encodeURIComponent(pickup) : "";
  const dropoffParam = dropoff ? "&dropoff=" + encodeURIComponent(dropoff) : "";
  const url = bookingId ?
    //Si tiene bookingId
    // "/legal/sublease_agreement?booking_id=" + encodeURIComponent(bookingId)
    "/legal/booking_certificate?booking_id=" + encodeURIComponent(bookingId)
    //Si no, es porque es una modificación de parte del owner
    // : "/legal/sublease_agreement?rental_id=" + encodeURIComponent(rentalId)
    : "/legal/booking_certificate?rental_id=" + encodeURIComponent(rentalId)
    + "&user_id=" + encodeURIComponent(userId)
    + "&from=" + encodeURIComponent(from)
    + "&to=" + encodeURIComponent(to)
    + "&total_price=" + encodeURIComponent(totalPrice)
    + "&insurance_charge=" + encodeURIComponent(insuranceCharge)
    + "&clf_value=" + encodeURIComponent(clfValue)
    + "&owner_total=" + encodeURIComponent(ownerTotal)
    + "&discount=" + encodeURIComponent(discount)
    + pickupParam + dropoffParam;
  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-6 mb-2 mb-lg-0">
          <div
            className="card"
            style={{
              borderStyle: "solid",
              borderWidth: 1,
              height: "100%",
              marginBottom: 0
            }}
          >
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="fechas">Rango de fechas del arriendo</label>
                <table
                  id="fechas"
                  style={{ width: "100%", fontWeight: "bolder" }}
                >
                  <tbody>
                    <tr>
                      <td style={{ color: "#203453" }}>INICIO</td>
                      <td>:</td>
                      <td align="right">{dayjs(from).locale("es").format("LLL")}</td>
                    </tr>
                    <tr>
                      <td style={{ color: "#203453" }}>DEVOLUCIÓN</td>
                      <td>:</td>
                      <td align="right">{dayjs(to).locale("es").format("LLL")}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="row mt-2">
                  <div className="col-lg-12">
                    <div className="form-group mb-1">
                      <label>Punto de recogida</label>
                      <input
                        type="text"
                        readOnly
                        value={pickup || ""}
                        onChange={() => false}
                        className="form-control disabled"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-0">
                      <label>Punto de devolución</label>
                      <input
                        type="text"
                        readOnly
                        value={dropoff || ""}
                        onChange={() => false}
                        className="form-control appcar-input"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div
            className="card"
            style={{
              borderStyle: "solid",
              borderWidth: 1,
              height: "100%",
              marginBottom: 0
            }}
          >
            <div className="card-body">
              <h5>Garantía</h5>
              <p className="mb-3">Se autorizará una garantía de tu tarjeta de crédito de <b>$&nbsp;400.000</b></p>
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <th className="py-1" align="left">Política de cancelación</th>
                    <td
                      align="right"
                      style={{
                        borderTop: "1px solid #484848",
                        borderBottom: "1px solid #484848"
                      }}
                    >
                      {cancellationPolicy}
                      <br />
                      <a href="/legal/rental_policy#cancellation_policies" target="_blank" style={{ fontSize: 12 }}>Más detalles</a>
                    </td>
                  </tr>
                  <tr>
                    <th className="py-1" align="left">Política de combustible</th>
                    <td
                      align="right"
                      style={{
                        borderTop: "1px solid #484848",
                        borderBottom: "1px solid #484848"
                      }}
                    >
                      {fuelPolicy}
                      <br />
                      <a href="/legal/rental_policy#fuel_policies" target="_blank" style={{ fontSize: 12 }}>Más detalles</a>
                    </td>
                  </tr>
                  <tr>
                    <th className="py-1" align="left">Extras</th>
                    <td
                      align="right"
                      style={{
                        borderTop: "1px solid #484848",
                        borderBottom: "1px solid #484848"
                      }}
                    >
                      {selectedExtras.length > 0 ? selectedExtras.reduce((prev, curr) => [prev, "; ", curr]) : "No hay extras seleccionados"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg">
          <p className="mt-2">Documentos asociados:</p>
          <ul>
            <li>
              <a
                href={url + "#start"}
                target="_blank"
                rel="noopener noreferrer"
                className="font-weight-bold"
              >
                Contrato de subarrendamiento, Anexo 1 y 2.
          </a>
            </li>
            <li>
              <a
                href={url + "#privacy"}
                target="_blank"
                rel="noopener noreferrer"
                className="font-weight-bold"
              >
                Política de privacidad II.
          </a>

            </li>
          </ul>
        </div>
        <div className="d-none d-lg-block col-lg-auto">
          <div
            className="card"
            style={{
              borderStyle: "solid",
              borderWidth: 1,
              marginBottom: 0,
              marginTop: "1rem"
            }}
          >
            <div className="card-body p-2">
              <h4>TOTAL: {numeral(total_price).format("$ 0,0")}</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};