import React, { Component, Fragment } from "react";

class VehicleStats extends Component {
  handleChange = (e, extra) => {
    if (e.target.checked) {
      this.props.addExtra(extra);
    } else {
      this.props.removeExtra(extra);
    }
  };

  render() {
    const {
      extras,
      features,
      isBooking,
      extrasIds,
      vehicle,
      extraPlateInfo,
    } = this.props;
    const vehicle_title =
      vehicle.vehicle_type.brand +
      " " +
      vehicle.vehicle_type.model +
      " " +
      vehicle.year;
    let car_rating = [];
    for (let i = 0; i < 5; i++) {
      let r_icon = null;
      if (i < vehicle.vehicle_rating) {
        r_icon = <i className="mdi mdi-car-sports" key={i} />;
      } else {
        r_icon = <i className="mdi mdi-car-sports icon-gray" key={i} />;
      }
      car_rating.push(r_icon);
    }
    const evaluations = (
      <div className="vehicle-stats-badge-ratings mr-1" key="badge">
        {vehicle.trips || 0} viaje
        {vehicle.trips == 1 ? "" : "s"}
      </div>
    );
    car_rating.push(evaluations);
    const vehicle_features = features.map((v, i) => {
      return (
        <div className="col-6 centered-stats mb-1 px-0" key={i}>
          <i className={v.icon_class + " mr-1"} />
          <p>{v.a_name}</p>
        </div>
      );
    });
    const vehicle_extras = isBooking
      ? extras.map((extra, i) => {
          return (
            <div className="col-6 centered-stats mb-1 px-0" key={i}>
              <i className={extra.icon_class + " mr-1"} />
              <p>{extra.a_name}</p>
            </div>
          );
        })
      : extras.map((extra, i) => {
          const checked = extrasIds.indexOf(extra.vehicle_extra.id) > -1;
          return (
            <div
              className="col-md-6 centered-stats vehicle-extra-checkbox px-0"
              key={i}
            >
              <div className="ml-1 custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={"extra_" + extra.vehicle_extra.id}
                  checked={checked}
                  onChange={(e) => this.handleChange(e, extra.vehicle_extra.id)}
                  data-extra-description={extra.vehicle_extra.a_name}
                />
                <label
                  className="custom-control-label"
                  htmlFor={"extra_" + extra.vehicle_extra.id}
                >
                  &nbsp;
                </label>
              </div>
              <i className={extra.vehicle_extra.icon_class + " mr-1"} />
              <p>{extra.vehicle_extra.a_name}</p>
            </div>
          );
        });
    const features_container =
      features.length > 0 ? (
        <div className="mb-3 vehicle-props">
          <h4>ADEMÁS INCLUYE</h4>
          <div className="row ml-1 ml-md-0">{vehicle_features}</div>
        </div>
      ) : null;
    const extras_container =
      extras.length > 0 ? (
        <div className="mb-3 vehicle-props">
          <h4>CARGOS ADICIONALES</h4>
          <div className="row ml-1 ml-md-0">{vehicle_extras}</div>
        </div>
      ) : null;
    const transmission =
      vehicle.vehicle_transmission &&
      vehicle.vehicle_transmission.description ? (
        <div className="col-6 centered-stats mb-1 px-0">
          <i className="mdi mdi-car-shift-pattern mr-1" />
          <p>Transmisión {vehicle.vehicle_transmission.description}</p>
        </div>
      ) : null;
    const category =
      vehicle.vehicle_category && vehicle.vehicle_category.description ? (
        <div className="col-6 centered-stats mb-1 px-0">
          <i className="mdi mdi-car-pickup mr-1" />
          <p>{vehicle.vehicle_category.description}</p>
        </div>
      ) : null;
    return (
      <div className="vehicle-stats ml-1 ml-md-0">
        <p className="vehicle-stats-title">
          {vehicle_title}
          {extraPlateInfo}
        </p>
        <div className="vehicle-stats-rating">{car_rating}</div>
        <div className="vehicle-props my-1 my-sm-2 ml-1 ml-md-0">
          <div className="row ml-1 ml-md-0">
            <div className="col-6 centered-stats mb-1 px-0">
              <i className="mdi mdi-gas-station mr-1" />
              <p>{vehicle.efficiency} Km/L</p>
            </div>
            <div className="col-6 centered-stats mb-1 px-0">
              <i className="mdi mdi-fuel mr-1" />
              <p>{vehicle.fuel_type.description}</p>
            </div>
            <div className="col-6 centered-stats mb-1 px-0">
              <i className="mdi mdi-seat-recline-normal mr-1" />
              <p>{vehicle.capacity} pasajero(s)</p>
            </div>
            {transmission}
            {category}
          </div>
        </div>
        {features_container}
        {extras_container}
      </div>
    );
  }
}

export default VehicleStats;
