import React, { Component } from "react";
import { numeral } from "../../../common/constants";

export default class RentalExtras extends Component {
  render() {
    const {
      isSubmitting,
      handleSubmit,
      addExtra,
      removeExtra,
      updateExtraPrice,
      extrasList,
      updateExtraDaily,
    } = this.props;
    const extrasContainer = extrasList.map((extra, i) => (
      <div key={i}>
        <div className="row align-items-center" style={{ marginTop: "-8px"}}>
          <div className="col-7 pr-1">
            <div className="form-group">
              <div className="form-check extras-check d-flex align-items-center pl-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={"check_" + extra.extra.id}
                  checked={extra.checked}
                  onChange={(e) => {
                    if (e.target.checked) {
                      addExtra(i);
                    } else {
                      removeExtra(i);
                    }
                  }}
                />
                <label
                  className="form-check-label fw-400 fs-13 text-black"
                  htmlFor={"check_" + extra.extra.id}
                >
                  {extra.extra.a_name}
                </label>
              </div>
            </div>
          </div>
          <div className="col-3 pl-0 pr-0">
            <div className={`form-group ${!extra.checked ? "d-none" : ""}`}>
              <label className="label-inside">Precio</label>
              <input
                disabled={!extra.checked}
                type="text"
                className="form-control appcar-input no-min-width"
                value={numeral(extra.price).format("$0,0")}
                onChange={(e) => {
                  const cleanValue = e.target.value.replace(/\D/g, "") || "0";
                  updateExtraPrice(i, parseInt(cleanValue));
                }}
              />
            </div>
          </div>
          <div className="col-1">
            <div className="form-group">
              <div className="form-check extras-check d-flex align-items-center pl-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={"check_daily_" + extra.extra.id}
                  checked={extra.charge_per_day}
                  onChange={(e) => updateExtraDaily(i, e.target.checked)}
                  disabled={!extra.checked}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
    return (
      <>
        <div className="row">
          <div className="col">
            <h5 className="fs-13 fw-800">Agrega servicios y su valor</h5>
          </div>
          <div className="col-auto">
            <h5 className="fs-11 fw-800">¿Diario?</h5>
          </div>
        </div>
        {extrasContainer}
        <div className="text-center mt-3 mb-4">
          <button
            type="button"
            className="btn btn-appcar-primary pt-2 pb-2"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Finalizar
          </button>
        </div>
      </>
    );
  }
}
