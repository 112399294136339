import React, { Component, Fragment } from "react";
import Spinner from "../../../common/Spinner";
import FlashMessage from "../../../common/FlashMessage";
import axios from "axios";
import { AxiosApplicationInterceptor } from "../../../common/axios_interceptor";
import { constants, leaseAgreementText, BookingStatus } from "../../../common/constants";
import moment from "moment";
import Stepzilla from "react-stepzilla";
import SearchRentals from "./components/SearchRentals";
import ConfirmEdit from "./components/ConfirmEdit";
moment.locale("es");
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import LegalModal from "../../../common/legal_modals/LegalModal";
axios.interceptors.response.use(undefined, AxiosApplicationInterceptor);

class BookingEdit extends Component {
  state = {
    rentals: [],
    form_data: {
      lat: this.props.booking.pricing.selected_pickup_location.location.latitude || constants.RENTAL_SEARCH_LATITUDE,
      lon: this.props.booking.pricing.selected_pickup_location.location.longitude || constants.RENTAL_SEARCH_LONGITUDE,
      radius: constants.RENTAL_SEARCH_RADIUS,
      fromDate: moment(this.props.booking.date_from).format("DD/MM/YYYY"),
      untilDate: moment(this.props.booking.date_until).format("DD/MM/YYYY"),
      fromTime: moment(this.props.booking.date_from).format("HH:mm"),
      untilTime: moment(this.props.booking.date_until).format("HH:mm")
    },
    address: this.props.booking.pricing.selected_pickup_location.location.name,
    vehicleExtras: this.props.vehicleExtras,
    vehicleFeatures: this.props.vehicleFeatures,
    vehicleCategories: this.props.vehicleCategories,
    vehicleTransmissions: this.props.vehicleTransmissions,

    selectedExtras: undefined,
    selectedFeatures: undefined,
    selectedCategories: undefined,
    selectedTransmissions: undefined,
    priceMin: constants.MIN_PRICE,
    priceMax: constants.MAX_PRICE,
    priceMinUpdate: constants.MIN_PRICE,
    priceMaxUpdate: constants.MAX_PRICE,
    showFilters: false,
    ui: {
      isSubmitting: false,
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined
    },
    selectedRental: undefined,
    newBookingData: {
      fromDate: moment(this.props.booking.date_from).format("DD/MM/YYYY"),
      untilDate: moment(this.props.booking.date_until).format("DD/MM/YYYY"),
      fromTime: moment(this.props.booking.date_from).format("HH:mm"),
      untilTime: moment(this.props.booking.date_until).format("HH:mm"),
      rental_id: this.props.rental.id,
      vehicle_id: this.props.booking.vehicle.id,
      extras_ids: this.props.booking.pricing.extra_charges.map(x => (x.vehicle_extra.id)),
      selected_pickup: {
        label: this.props.booking.pricing.selected_pickup_location.location.name,
        value: this.props.booking.pricing.selected_pickup_location.location.name
      },
      selected_dropoff: {
        label: this.props.booking.pricing.selected_dropoff_location.location.name,
        value: this.props.booking.pricing.selected_dropoff_location.location.name
      },
      from_formatted: moment(this.props.booking.date_from).format(),
      until_formatted: moment(this.props.booking.date_until).format()
    },
    pricing: undefined
  };
  cancelToken = null;
  willUnmount = false;

  componentWillUnmount() {
    this.willUnmount = true;
  }

  componentDidMount() {

    $("body").click(function (e) {
      if (!$(e.target).hasClass("anc-tooltip")) {
        $(".tooltiptext").removeClass("tooltip-visible");
      } else {
        var tooltipFor = "#" + e.target.dataset.toggleTooltipFor;
        $(".tooltiptext")
          .not($(tooltipFor))
          .removeClass("tooltip-visible");
        $(tooltipFor).toggleClass("tooltip-visible");
      }
    });

    this.willUnmount = false;
    this.cancelToken = axios.CancelToken.source();
    const booking = this.props.booking;
    this.initializeCalendars();
    // const selectedCategories = booking.vehicle.vehicle_category ? [{ value: booking.vehicle.vehicle_category.id, label: booking.vehicle.vehicle_category.description }] : undefined;
    // const selectedTransmissions = booking.vehicle.vehicle_transmission ? [{ value: booking.vehicle.vehicle_transmission.id, label: booking.vehicle.vehicle_transmission.description }] : undefined;
    // const selectedFeatures = booking.vehicle.vehicle_features ? booking.vehicle.vehicle_features.map(feature => ({
      // value: feature.id,
      // label: feature.a_name
    // })) : undefined;
    // const selectedExtras = booking.pricing.extra_charges ? booking.pricing.extra_charges.map(extra => ({
    //   value: extra.vehicle_extra.id,
    //   label: extra.vehicle_extra.a_name
    // })) : undefined;
    // this.setState({
    //   selectedCategories,
    //   selectedTransmissions,
    //   selectedFeatures,
    //   selectedExtras
    // }, () => this.handleSearch());
    this.handleSearch();
  }

  handleChangeShowFilters = showFilters => this.setState({ showFilters });

  initializeCalendars = () => {
    const this_ = this;
    const options = {
      showDropdowns: true,
      maxYear: constants.MAX_YEAR,
      singleDatePicker: true,
      minDate: new Date(),
      locale: {
        format: "DD/MM/YYYY",
        cancelLabel: "Cancelar",
        applyLabel: "Aplicar",
        fromLabel: "Desde",
        toLabel: "Hasta",
        daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre"
        ],
        firstDay: 1
      }
    };

    $("#search_from_").daterangepicker(options, (start) => {
      const from_ = moment(start);
      const until_ = moment(this_.state.form_data.untilDate, "DD/MM/YYYY");
      let fromDate = from_.format("DD/MM/YYYY");
      let untilDate = until_.format("DD/MM/YYYY");
      if (until_.valueOf() < from_.valueOf()) {
        untilDate = from_.clone().format("DD/MM/YYYY");
        $("#search_until_").data("daterangepicker").setStartDate(untilDate);
        $("#search_until_").data("daterangepicker").setEndDate(untilDate);
      }
      if (!this_.willUnmount) {
        this_.setState(state => {
          state.form_data.fromDate = fromDate;
          state.form_data.untilDate = untilDate;
          return { form_data: state.form_data };
        });
      }
    });

    $("#search_until_").daterangepicker(options, (start) => {
      const until_ = moment(start);
      const from_ = moment(this_.state.form_data.fromDate, "DD/MM/YYYY");
      let fromDate = from_.format("DD/MM/YYYY");
      let untilDate = until_.format("DD/MM/YYYY");
      if (from_.valueOf() > until_.valueOf()) {
        fromDate = until_.format("DD/MM/YYYY");
        $("#search_from_").data("daterangepicker").setStartDate(fromDate);
        $("#search_from_").data("daterangepicker").setEndDate(fromDate);
      }
      if (!this_.willUnmount) {
        this_.setState(state => {
          state.form_data.untilDate = untilDate;
          state.form_data.fromDate = fromDate;
          return { form_data: state.form_data };
        });
      }
    });

    $("#search_from_").data("daterangepicker").setStartDate(moment(this.props.booking.date_from));
    $("#search_from_").data("daterangepicker").setEndDate(moment(this.props.booking.date_from));
    $("#search_until_").data("daterangepicker").setStartDate(moment(this.props.booking.date_until));
    $("#search_until_").data("daterangepicker").setEndDate(moment(this.props.booking.date_until));
  };

  handleChangeTimeFrom = option => {
    this.setState(state => {
      state.form_data.fromTime = option.value;
      return { form_data: state.form_data };
    })
  };

  handleChangeTimeUntil = option => {
    this.setState(state => {
      state.form_data.untilTime = option.value;
      return { form_data: state.form_data };
    })
  };

  handleChangeConfirmTimeFrom = option => {
    this.setState(state => {
      state.newBookingData.fromTime = option.value;
      return { newBookingData: state.newBookingData };
    })
  };

  handleChangeConfirmTimeUntil = option => {
    this.setState(state => {
      state.newBookingData.untilTime = option.value;
      return { newBookingData: state.newBookingData };
    })
  };

  handleChangeConfirmDateFrom = fromDate => {
    this.setState(state => {
      state.newBookingData.fromDate = fromDate;
      return { newBookingData: state.newBookingData };
    })
  };

  handleChangeConfirmDateUntil = untilDate => {
    this.setState(state => {
      state.newBookingData.untilDate = untilDate;
      return { newBookingData: state.newBookingData };
    })
  };

  loadSpinner = () => {
    this.setState(state => {
      state.ui.isSubmitting = true;
      return { ui: state.ui }
    })
  }

  handleSelectLocation = ({ success, lat, lon, address }) => {
    if (success) {
      this.setState(state => {
        state.form_data.lat = lat;
        state.form_data.lon = lon;
        return { form_data: state.form_data, address }
      });
    } else {
      this.setState(state => {
        state.ui.isSubmitting = false;
        return { ui: state.ui };
      });
    }
  };

  showMessage = msg => {
    this.setState(state => {
      state.ui.showMessage = msg.showMessage;
      state.ui.messageType = msg.messageType;
      state.ui.messages = msg.messages;
      state.ui.messageTitle = msg.messageTitle;
      return { ui: state.ui };
    });
  };

  handleCalculatePrice = (callback = () => { }) => {
    this.setState(state => {
      state.ui.isSubmitting = true;
      state.ui.showMessage = false;
      state.ui.messageType = undefined;
      state.ui.messages = [];
      state.ui.messageTitle = undefined;
      return { ui: state.ui };
    });
    let newBookingData = this.state.newBookingData;
    let rental = this.state.selectedRental;
    const from_formatted = moment(newBookingData.fromDate + newBookingData.fromTime, "DD/MM/YYYYHH:mm").format();
    const until_formatted = moment(newBookingData.untilDate + newBookingData.untilTime, "DD/MM/YYYYHH:mm").format();
    let rental_pricing = {
      rental_id: newBookingData.rental_id,
      start: from_formatted,
      end: until_formatted,
      extras_ids: newBookingData.extras_ids
    };
    if (rental.location.name != newBookingData.selected_pickup.value) {
      rental_pricing.special_pickup = newBookingData.selected_pickup.value;
    }
    if (rental.location.name != newBookingData.selected_dropoff.value) {
      rental_pricing.special_dropoff = newBookingData.selected_dropoff.value;
    }
    newBookingData.from_formatted = from_formatted;
    newBookingData.until_formatted = until_formatted;
    const rental_id = rental.parent_id || rental.id;
    axios({
      method: "POST",
      url: `/rentals/${this.props.rental.id}/calculate_pricing.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json"
      },
      data: { rental_pricing }
    })
      .then(response => {
        if (response.data.success) {
          let pricing = response.data.pricing;
          if (this.props.booking.status.id == BookingStatus.ACTIVO) {
            const actualPrice = this.props.booking.price;
            if (pricing.total > actualPrice.total) {
              pricing.owner_discount = (pricing.total - pricing.value_added_tax) - (actualPrice.total - actualPrice.value_added_tax);
              pricing.value_added_tax = actualPrice.value_added_tax;
              pricing.total = actualPrice.total;
            }
          }
          this.setState({ pricing, newBookingData }, () => callback(1));
        } else {
          this.showMessage({
            showMessage: true,
            messageType: "alert-warning",
            messages: response.data.messages || [
              "Ha ocurrido un error inesperado"
            ],
            messageTitle: "Alerta"
          });
        }
      })
      .catch(error => {
        this.showMessage({
          showMessage: true,
          messageType: "alert-danger",
          messages: ["Ha ocurrido un error inesperado"],
          messageTitle: "Error"
        });
      })
      .finally(() => {
        this.setState(state => {
          state.ui.isSubmitting = false;
          return { ui: state.ui };
        });
      });
  }

  handleChangeTransmissions = options => {
    this.setState({ selectedTransmissions: options });
  };

  handleChangeExtras = options => {
    this.setState({ selectedExtras: options });
  };

  handleChangeFeatures = options => {
    this.setState({ selectedFeatures: options });
  };

  handleChangeCategories = options => {
    this.setState({ selectedCategories: options });
  };

  handleChangePriceRange = values => {
    const priceMin = values[0];
    const priceMax = values[1];
    this.setState({ priceMin, priceMax });
  };

  handleUpdatePriceRange = values => {
    const priceMinUpdate = values[0];
    const priceMaxUpdate = values[1];
    this.setState({ priceMinUpdate, priceMaxUpdate });
  };

  handleChangeConfirmExtras = options => {
    const extras_ids = (options || []).map(x => (x.value));
    this.setState(state => {
      state.newBookingData.extras_ids = extras_ids;
      return { newBookingData: state.newBookingData };
    });
  }

  handleChangePickupLocation = selected_pickup => {
    this.setState(state => {
      state.newBookingData.selected_pickup = selected_pickup;
      return { newBookingData: state.newBookingData };
    });
  }

  handleChangeDropoffLocation = selected_dropoff => {
    this.setState(state => {
      state.newBookingData.selected_dropoff = selected_dropoff;
      return { newBookingData: state.newBookingData };
    });
  }

  resetFilters = () =>
    this.setState({
      selectedExtras: [],
      selectedFeatures: [],
      selectedCategories: [],
      selectedTransmissions: [],
      priceMin: constants.MIN_PRICE,
      priceMax: constants.MAX_PRICE
    });

  handleSearch = () => {
    this.setState(state => {
      state.ui.isSubmitting = true;
      return { ui: state.ui };
    });
    const {
      selectedCategories,
      selectedTransmissions,
      selectedFeatures,
      selectedExtras,
      priceMin,
      priceMax,
      form_data
    } = this.state;
    const from = moment(form_data.fromDate + form_data.fromTime, "DD/MM/YYYYHH:mm").format();
    const until = moment(form_data.untilDate + form_data.untilTime, "DD/MM/YYYYHH:mm").format();
    const subfilter = this.props.subfilter ? `&subfilter=${encodeURIComponent(this.props.subfilter)}` : "";
    let encodedUri = `lat=${encodeURIComponent(
      form_data.lat
    )}&lon=${encodeURIComponent(form_data.lon)}&radius=${encodeURIComponent(
      form_data.radius
    )}&from=${encodeURIComponent(from)}&until=${encodeURIComponent(until)}${subfilter}`;
    encodedUri =
      !!priceMin || priceMin != constants.MIN_PRICE
        ? encodedUri + "&price_min=" + encodeURIComponent(priceMin)
        : encodedUri;
    encodedUri =
      priceMax && priceMax != constants.MAX_PRICE
        ? encodedUri + "&price_max=" + encodeURIComponent(priceMax)
        : encodedUri;
    encodedUri =
      !!selectedFeatures && selectedFeatures.length > 0
        ? encodedUri +
        "&features=" +
        encodeURIComponent(selectedFeatures.map(x => x.value))
        : encodedUri;
    encodedUri =
      !!selectedExtras && selectedExtras.length > 0
        ? encodedUri +
        "&extras=" +
        encodeURIComponent(selectedExtras.map(x => x.value))
        : encodedUri;
    encodedUri =
      !!selectedCategories && selectedCategories.length > 0
        ? encodedUri +
        "&vehicle_category=" +
        encodeURIComponent(selectedCategories.map(x => x.value))
        : encodedUri;
    encodedUri =
      !!selectedTransmissions && selectedTransmissions.length > 0
        ? encodedUri +
        "&vehicle_transmission=" +
        encodeURIComponent(selectedTransmissions.map(x => x.value))
        : encodedUri;
    const rentals_promise = axios({
      method: "get",
      url: `/rentals/owner_available_rentals.json?${encodedUri}`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json"
      }
    });
    Promise.all([rentals_promise])
      .then(values => {
        const [rentalsData] = values;
        const rentals = (rentalsData.data || [])

        if (!this.willUnmount) this.setState({ rentals });
      })
      .catch(error => {
        this.showMessage({
          showMessage: true,
          messageType: "alert-danger",
          messages: ["Ha ocurrido un error inesperado"],
          messageTitle: "Error"
        });
      })
      .then(() => {
        this.setState(state => {
          state.ui.isSubmitting = false;
          return { ui: state.ui };
        });
      });
  }

  handleSelectRental = (selectedRental, callback) => {
    const booking = this.props.booking;
    const booking_pickup = booking.pricing.selected_pickup_location.location.name;
    const booking_dropoff = booking.pricing.selected_dropoff_location.location.name;
    let newBookingData = this.state.newBookingData;
    let rental_locations = [selectedRental.location.name];

    const extra_locations = (selectedRental.pricing.extra_locations || []).map(x => x.location.name);
    rental_locations.push(...extra_locations);
    newBookingData.selected_pickup = rental_locations.includes(booking_pickup)
      ? { value: booking_pickup, label: booking_pickup }
      : { value: rental_locations[0], label: rental_locations[0] };
    newBookingData.selected_dropoff = rental_locations.includes(booking_dropoff)
      ? { value: booking_dropoff, label: booking_dropoff }
      : { value: rental_locations[0], label: rental_locations[0] };
    const available_extras = (selectedRental.pricing.extra_charges || []).map(x => x.vehicle_extra.id);
    newBookingData.extras_ids = available_extras.filter(extra_id => booking.pricing.extra_charges.map(x => x.vehicle_extra.id).includes(extra_id));
    newBookingData.rental_id = selectedRental.parent_id || selectedRental.id;
    this.setState({ selectedRental, newBookingData }, () => this.handleCalculatePrice(callback));
  }

  handleLegalPopUp = () => {
    $("html, main").animate({ scrollTop: 0 }, "slow");
    $("#modal-lease-agreement").modal("show");
  }

  handleUpdateBooking = () => {
    this.setState(state => {
      state.ui.isSubmitting = true;
      return { ui: state.ui };
    });
    let newBookingData = this.state.newBookingData;
    let rental = this.state.selectedRental;
    const from_formatted = moment(newBookingData.fromDate + newBookingData.fromTime, "DD/MM/YYYYHH:mm").format();
    const until_formatted = moment(newBookingData.untilDate + newBookingData.untilTime, "DD/MM/YYYYHH:mm").format();
    const rental_id = rental.parent_id || rental.id;
    let booking = {
      rental_id: rental_id,
      vehicle_id: rental.vehicle.id,
      date_from: from_formatted,
      date_until: until_formatted,
      extras_ids: newBookingData.extras_ids
    };
    if (rental.location.name != newBookingData.selected_pickup.value) {
      booking.special_pickup = newBookingData.selected_pickup.value;
    }
    if (rental.location.name != newBookingData.selected_dropoff.value) {
      booking.special_dropoff = newBookingData.selected_dropoff.value;
    }
    axios({
      method: "PUT",
      url: `/bookings/${this.props.booking.id}/owner_update.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json"
      },
      data: { booking }
    })
      .then(response => {
        if (response.data.success) {
          Turbolinks.visit("/calendar?vehicle_id=" + this.props.booking.vehicle.id + "&success=1")
        } else {
          this.showMessage({
            showMessage: true,
            messageType: "alert-warning",
            messages: response.data.messages || [
              "Ha ocurrido un error inesperado"
            ],
            messageTitle: "Alerta"
          });
        }
      })
      .catch(error => {
        this.showMessage({
          showMessage: true,
          messageType: "alert-danger",
          messages: ["Ha ocurrido un error inesperado"],
          messageTitle: "Error"
        });
      })
      .finally(() => {
        this.setState(state => {
          state.ui.isSubmitting = false;
          return { ui: state.ui };
        });
      });
  }

  render() {
    const {
      address,
      showFilters,
      vehicleCategories,
      vehicleTransmissions,
      vehicleFeatures,
      vehicleExtras,
      selectedCategories,
      selectedTransmissions,
      selectedFeatures,
      selectedExtras,
      priceMin,
      priceMinUpdate,
      priceMax,
      priceMaxUpdate,
      rentals,
      selectedRental,
      ui,
      newBookingData,
      pricing
    } = this.state;
    const { fromDate, fromTime, untilDate, untilTime } = this.state.form_data;
    const steps = [
      {
        name: "",
        component: (
          <SearchRentals
            address={address}
            handleSelectLocation={this.handleSelectLocation}
            fromDate={fromDate}
            fromTime={fromTime}
            handleChangeTimeFrom={this.handleChangeTimeFrom}
            untilDate={untilDate}
            untilTime={untilTime}
            handleChangeTimeUntil={this.handleChangeTimeUntil}
            showFilters={showFilters}
            handleChangeShowFilters={this.handleChangeShowFilters}
            handleSearch={this.handleSearch}
            vehicleCategories={vehicleCategories}
            vehicleTransmissions={vehicleTransmissions}
            vehicleFeatures={vehicleFeatures}
            vehicleExtras={vehicleExtras}
            selectedCategories={selectedCategories}
            selectedTransmissions={selectedTransmissions}
            selectedFeatures={selectedFeatures}
            selectedExtras={selectedExtras}
            handleChangeCategories={this.handleChangeCategories}
            handleChangeTransmissions={this.handleChangeTransmissions}
            handleChangeFeatures={this.handleChangeFeatures}
            handleChangeExtras={this.handleChangeExtras}
            priceMin={priceMin}
            priceMinUpdate={priceMinUpdate}
            priceMax={priceMax}
            priceMaxUpdate={priceMaxUpdate}
            handleChangePriceRange={this.handleChangePriceRange}
            handleUpdatePriceRange={this.handleUpdatePriceRange}
            resetFilters={this.resetFilters}
            rentals={rentals}
            handleSelectRental={this.handleSelectRental}
            loadSpinner={this.loadSpinner}
          />
        )
      },
      {
        name: "",
        component: (
          <ConfirmEdit
            booking={this.props.booking}
            selectedRental={selectedRental}
            newBookingData={newBookingData}
            handleChangeConfirmTimeFrom={this.handleChangeConfirmTimeFrom}
            handleChangeConfirmTimeUntil={this.handleChangeConfirmTimeUntil}
            handleChangeConfirmDateFrom={this.handleChangeConfirmDateFrom}
            handleChangeConfirmDateUntil={this.handleChangeConfirmDateUntil}
            handleChangeConfirmExtras={this.handleChangeConfirmExtras}
            handleChangePickupLocation={this.handleChangePickupLocation}
            handleChangeDropoffLocation={this.handleChangeDropoffLocation}
            pricing={pricing}
            handleCalculatePrice={this.handleCalculatePrice}
            handleLegalPopUp={this.handleLegalPopUp}
          />
        )
      }
    ];
    const flash_message = this.state.ui.showMessage ? (
      <FlashMessage
        type={this.state.ui.messageType}
        msg={this.state.ui.messages}
        title={this.state.ui.messageTitle}
      />
    ) : null;

    const user_ = this.props.booking.user[0];
    let modalBody = null;
    let modalLeaseAgreement = null;
    if (selectedRental) {
      modalBody = leaseAgreementText(
        null,
        selectedRental.parent_id || selectedRental.id,
        user_ && user_.id,
        moment(newBookingData.fromDate + newBookingData.fromTime, "DD/MM/YYYYHH:mm").format(),
        moment(newBookingData.untilDate + newBookingData.untilTime, "DD/MM/YYYYHH:mm").format(),
        pricing && pricing.total,
        newBookingData.selected_pickup.value,
        newBookingData.selected_dropoff.value,
        pricing && pricing.insurance_charge,
        pricing && pricing.clf_value,
        pricing && pricing.owner_total,
        pricing && pricing.discount
      );

      modalLeaseAgreement = (
        <LegalModal
          action={this.handleUpdateBooking}
          title="Lee atentamente antes de continuar"
          checkboxLabel="Estoy de acuerdo con los términos y condiciones"
          checkboxError="Debes aceptar los términos y condiciones"
          btnText="Continuar"
          body={modalBody}
          elementId="modal-lease-agreement"
          secondCheckboxLabel="Estoy de acuerdo con el tratamiento de mis datos personales como se describe en la “Política de Privacidad I”."
          secondCheckboxError="Debes aceptar la Política de Privacidad I para continuar"
          refId=""
        />
      );
    }
    return (
      <Fragment>
        <Spinner loading={ui.isSubmitting} />
        {flash_message}
        <div className="row">
          <div className="col">
            <div className="step-progress">
              <Stepzilla
                steps={steps}
                nextButtonText="Siguiente"
                backButtonText="Atrás"
                showNavigation={false}
                stepsNavigation={false}
                startAtStep={this.props.step || 0}
                preventEnterSubmission={true}
              />
            </div>
          </div>
        </div>
        {modalLeaseAgreement}
      </Fragment>
    )
  }
}


export default BookingEdit;