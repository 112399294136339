import React from "react";
import PricingDetails from "../../../../common/components/pricing_details/PricingDetails";

const ModalPricing = ({ price, modalId }) => (
  <div
    className="modal fade"
    id={modalId}
    aria-hidden="true"
    aria-labelledby={`${modalId}-label`}
    tabIndex={-1}
  >
    <div className="modal-dialog modal-dialog-centered modal-appcar">
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="btn-m-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            x
          </button>
          <h5 className="modal-title" id={`${modalId}-label`}>
            Detalle del precio
          </h5>
        </div>
        <div className="modal-body modal-body-less-padding pb-0">
          <div className="modal-dates-body">
            <PricingDetails
              price={price}
              fromEdit={true}
              showTitle={false}
              dateFrom={moment()
                .startOf("week")
                .add(1, "day")
                .format()}
              dateUntil={moment()
                .startOf("week")
                .add(2, "day")
                .format()}
            />
            <div className="row justify-content-center mt-3">
              <div className="col-auto">
                <button
                  type="button"
                  className="btn modal-btn"
                  data-dismiss="modal"
                  data-bs-dismiss="modal"
                >
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ModalPricing;
