import React from "react";
import { Navigation, Pagination, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

const Testimonials = () => {
  const texts = [
    <div className="testimonial">
      <p className="text">
        Hola, me llamo Margarita Torres y me declaro “App Lover”, el servicio es
        súper seguro, nunca he tenido un problema y todo ha sido muy fluido. Mi
        auto lo pongo en arriendo todo el tiempo en la aplicación… El
        teletrabajo me generó el problema de tener el auto estacionado y sin
        uso, así que aproveché la situación y empecé a generar ingresos de una
        manera fácil y rápida.”
      </p>
      <div className="owner-testimonial">
        <div className="name">Margarita Torres</div>
        <div className="">Owner de Appcar</div>
      </div>
    </div>,
    <div className="testimonial">
      <p className="text">
        Hola, mi nombre es Rafael Buriel, descubrí la aplicación a través de
        Instagram, hice un par de llamadas al equipo Appcar y me inspiraron
        mucha confianza para rentabilizar mi auto."
      </p>
      <div className="owner-testimonial">
        <div className="name">Rafael Buriel</div>
        <div className="">Owner de Appcar</div>
      </div>
    </div>,
    <div className="testimonial">
      <p className="text">
        Hola, mi nombre es Sebastian Figueroa y mi historia es la siguiente: yo
        venía de otra aplicación para arrendar autos pero me di cuenta que
        Appcar era mucho mejor, al ingresar me di cuenta que son profesionales
        en esto. Tengo un Rent a Car y es muy fácil e intuitivo usar la app para
        arrendar mis autos."
      </p>
      <div className="owner-testimonial">
        <div className="name">Sebastián Figueroa</div>
        <div className="">Owner de Appcar</div>
      </div>
    </div>,
    <div className="testimonial">
      <p className="text">
        Hola, me llamo Juan Pablo Salinas y lo que más me llamó la atención de
        la aplicación Appcar fue el seguro SURA, me dio confianza y seguridad
        para poner en arriendo mi auto por primera vez. El proceso resultó ser
        práctico y fácil, además de tener la opción de generar ingresos extras."
      </p>
      <div className="owner-testimonial">
        <div className="name">Juan Pablo Salinas</div>
        <div className="">Owner de Appcar</div>
      </div>
    </div>,
  ];
  const slides = texts.map((x, i) => <SwiperSlide key={i}>{x}</SwiperSlide>);
  return (
    <div className="carousel-testimonials">
      <h2 className="text-center fw-800 font-montserrat">¿Qué opinan nuestros Owners?</h2> 
      <Swiper
        modules={[Navigation, Pagination, A11y, Autoplay]}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        className="vehicle-carousel testimonials"
        autoplay={{
          delay: 3000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
      >
        {slides}
      </Swiper>
    </div>
  );
};

export default Testimonials;
