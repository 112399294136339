import React from "react";

const DatesContainer = ({ datesOverlaped, from, until, showModalDates }) => {
  const iconDates = datesOverlaped ? (
    <i
      className="mdi mdi-calendar-alert"
      title="Hay un conflicto en las fechas"
    ></i>
  ) : null;
  return (
    <div className="dates">
      <div className="from">
        <span className="day-month">
          {from.format("DD MMM").replace(/\./g, "")}
        </span>
        <span className="hour-minute">{from.format("HH:mm")}</span>
      </div>
      <div className="to">
        <span className="day-month">
          {until.format("DD MMM").replace(/\./g, "")}
        </span>
        <span className="hour-minute">{until.format("HH:mm")}</span>
      </div>
      <span
        className="edit-dates cursor-pointer text-underline"
        onClick={() => showModalDates()}
      >
        Editar fechas {iconDates}
      </span>
    </div>
  );
};

export default DatesContainer;
