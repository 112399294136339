import React from "react";

const FavoriteBadge = ({ handleClick, isFavorite }) => {
  const favorite_class = isFavorite ? "mdi mdi-heart" : "mdi mdi-heart-outline";
  return (
    <div className="favorite-circle">
      <i className={favorite_class} onClick={handleClick} />
    </div>
  );
};

export default FavoriteBadge;
