import Axios from "axios";
import moment from "moment";
import React, { Component } from "react";
import Alert from "../../../common/components/alert/Alert";
import {
  constants,
  DefaultPrice,
  DefaultRentalAvailability,
  DefaultRentalHours,
  DefaultRentalPricing,
  generateFilename,
} from "../../../common/constants";
import Spinner from "../../../common/Spinner";
import RentalAvailability from "./RentalAvailability";
import RentalExtras from "./RentalExtras";
import RentalInfo from "./RentalInfo";
import RentalPolicies from "./RentalPolicies";
import RentalPricing from "./RentalPricing";
import iconStare from "images/IconStare.png";
import PricingDetails from "../../../common/components/pricing_details/PricingDetails";
import RentalPreview from "./RentalPreview";
import cloneDeep from "lodash/cloneDeep";
import ModalPricing from "./modals/ModalPricing";
import ModalVehicleData from "./modals/ModalVehicleData";
import VehiclePictures from "../../vehicles/form/VehiclePictures";
import VehicleDocuments from "../../vehicles/form/VehicleDocuments";
import VehicleFeatures from "../../vehicles/form/VehicleFeatures";
import uniqBy from "lodash/uniqBy";

export default class RentalsWizard extends Component {
  state = {
    data: {
      id: "",
      date_from: moment(this.props.rental.date_from).format(),
      date_until: moment(this.props.rental.date_until).format(),
      location: {
        name: this.props.rental.location?.name || "",
        latitude: this.props.rental.location?.latitude || 0.0,
        longitude: this.props.rental.location?.longitude || 0.0,
      },
      availability:
        this.props.rental.availability || cloneDeep(DefaultRentalAvailability),
      pricing: this.props.rental.pricing || cloneDeep(DefaultRentalPricing),
      information: this.props.rental.information || {
        description: "",
        features: "",
        extras: "",
        restrictions: "",
      },
    },
    vehicle: {
      id: "",
      market_value: 0.0,
      plate_number: "",
      year: 2000,
      engine: "",
      chassis: "",
      color: "",
      capacity: "",
      efficiency: "",
      vehicle_category: null,
      vehicle_transmission: null,
      vehicle_type: null,
      fuel_type: null,
      cancellation_policy: null,
      fuel_policy: null,
      // unlimited_distance: false, // TODO - JUAN: Descomentar en caso de que quieran que lo de los 250km vaya acá también
      vehicle_features: [],
      vehicle_pictures: [],
      photos: {
        front: [],
        back: [],
        left: [],
        right: [],
        inside: [],
      },
      registration_document: {
        vehicular_technical_revision: [],
        circulation_permit: [],
        registration_document: [],
      },
      insurance_policy: {
        soap: [],
      },
      registry: {
        name: "",
        id_number: "",
      },
    },
    has_box: this.props.vehicle.has_box || false,
    box_code: this.props.vehicle.box_code || "",
    selectedFuelPolicy:
      (this.props.vehicle.fuel_policy && this.props.vehicle.fuel_policy.id) ||
      2,
    selectedCancellationPolicy:
      (this.props.vehicle.cancellation_policy &&
        this.props.vehicle.cancellation_policy.id) ||
      0,
    isSubmitting: false,
    unlimitedDistance: this.props.vehicle.unlimited_distance,
    activeStep: this.props.activeStep || 0,
    base_price: cloneDeep(DefaultPrice),
    weekend_price: cloneDeep(DefaultPrice),
    extrasList: [],
    renderKey: 0,
    advancedAvailabilityOn: false,
    appraisal: this.props.appraisal,
    recommendedPrice: this.props.recommendedPrice,
    justLoadedAdditionalPicture: false,
    objectUploading: null,
    priceAlert: {
      base_price: "",
      weekend_price: "",
    },
    alert: {
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined,
    },
  };

  modalBasePricing = null;
  modalWeekendPricing = null;
  modalVehData = null;

  componentDidMount() {
    let data = this.props.rental;
    let vehicle = cloneDeep(this.props.vehicle);
    data.date_from ??= moment()
      .startOf("day")
      .format();
    data.date_until ??= moment()
      .add(9, "months")
      .endOf("day")
      .format();
    data.availability ??= cloneDeep(DefaultRentalAvailability);
    data.pricing ??= cloneDeep(DefaultRentalPricing);
    data.pricing.extra_charges ??= [];
    const selectedFuelPolicy =
      (vehicle.fuel_policy && vehicle.fuel_policy.id) || 2;
    const selectedCancellationPolicy =
      (vehicle.cancellation_policy && vehicle.cancellation_policy.id) || 0;
    data.location ??= {
      name: "",
      latitude: 0.0,
      longitude: 0.0,
    };
    data.location.name ??= "";
    const extras = this.props.extras;
    const selectedExtras = data.pricing.extra_charges;
    const extrasList = extras.map((extra) => {
      const sExtra = selectedExtras.find(
        (x) => x.vehicle_extra.id === extra.id
      );
      const isChecked =
        !!sExtra && !!sExtra.vehicle_extra && !!sExtra.vehicle_extra.id;
      const isDaily =
        !!sExtra &&
        !!sExtra.vehicle_extra &&
        !!sExtra.vehicle_extra.charge_per_day;
      return {
        checked: isChecked,
        price: isChecked ? sExtra.price : 0,
        charge_per_day: isDaily,
        extra,
      };
    });
    let advancedAvailabilityOn = this.state.advancedAvailabilityOn;
    const hours = data.availability.hours;
    if (
      !!hours.mon.opening_time ||
      !!hours.tue.opening_time ||
      !!hours.wed.opening_time ||
      !!hours.thu.opening_time ||
      !!hours.fri.opening_time ||
      !!hours.sat.opening_time ||
      !!hours.sun.opening_time
    ) {
      advancedAvailabilityOn = true;
    }
    data.pricing.base_price =
      data.pricing.base_price || this.props.recommendedPrice;
    data.pricing.weekend_price =
      data.pricing.weekend_price || this.props.recommendedPrice;
    data.availability.booking_length.maximum_length = 30;
    data.availability.reservation_preferences.booking_window =
      data.availability.reservation_preferences.booking_window > 0 ? 120 : 0;

    if (this.props.vehicle) {
      // Pictures
      vehicle.photos ??= {};
      vehicle.photos.front ??= [];
      vehicle.photos.back ??= [];
      vehicle.photos.left ??= [];
      vehicle.photos.right ??= [];
      vehicle.photos.inside ??= [];
      vehicle.vehicle_pictures ??= [];
      vehicle.photos.front = vehicle.photos.front.map((url) => ({ url }));
      vehicle.photos.back = vehicle.photos.back.map((url) => ({ url }));
      vehicle.photos.left = vehicle.photos.left.map((url) => ({ url }));
      vehicle.photos.right = vehicle.photos.right.map((url) => ({ url }));
      vehicle.photos.inside = vehicle.photos.inside.map((url) => ({ url }));

      // Documents
      vehicle.registration_document ??= {};
      vehicle.registration_document.circulation_permit ??= [];
      vehicle.registration_document.registration_document ??= [];
      vehicle.registration_document.vehicular_technical_revision ??= [];
      vehicle.insurance_policy ??= {};
      vehicle.insurance_policy.soap ??= [];
      vehicle.vehicle_pictures = vehicle.vehicle_pictures.map((url) => ({
        url,
      }));
      vehicle.registration_document.circulation_permit = vehicle.registration_document.circulation_permit.map(
        (url) => ({ url })
      );
      vehicle.registration_document.registration_document = vehicle.registration_document.registration_document.map(
        (url) => ({ url })
      );
      vehicle.registration_document.vehicular_technical_revision = vehicle.registration_document.vehicular_technical_revision.map(
        (url) => ({ url })
      );
      vehicle.insurance_policy.soap = vehicle.insurance_policy.soap.map(
        (url) => ({
          url,
        })
      );
      // Data
      vehicle.engine ??= "";
      vehicle.efficiency ??= "";
      vehicle.chassis ??= "";
      vehicle.market_value ??= 0.0;
      vehicle.registry ??= {
        name: "",
        id_number: "",
      };
      vehicle.vehicle_features ??= [];
    }
    this.setState(
      {
        data,
        selectedFuelPolicy,
        selectedCancellationPolicy,
        advancedAvailabilityOn,
        extrasList,
        vehicle,
      },
      () => {
        this.modalBasePricing = new bootstrap.Modal(
          document.getElementById("modal-base-pricing")
        );
        this.modalWeekendPricing = new bootstrap.Modal(
          document.getElementById("modal-weekend-pricing")
        );
        this.modalVehData = new bootstrap.Modal(
          document.getElementById("modal-vehicle-data"),
          { backdrop: "static" }
        );
        this.calcBothPrices();
      }
    );
  }

  handleSubmit = (nextStep, fromModal = false) => {
    let {
      data,
      box_code,
      has_box,
      selectedCancellationPolicy,
      selectedFuelPolicy,
      renderKey,
      unlimitedDistance,
      advancedAvailabilityOn,
      activeStep,
      vehicle,
    } = this.state;
    renderKey++;
    const firstAttempt = this.props.firstAttempt;
    const isLastStep = nextStep === 7;
    const newStep = isLastStep ? 6 : nextStep;
    const body = {};
    if (
      data.location &&
      data.location.latitude == 0.0 &&
      data.location.longitude == 0.0 &&
      data.location.name == ""
    ) {
      data.location = null;
    }
    data.pricing.extra_locations = (data.pricing.extra_locations || []).filter(
      (x) => !!x.location.name
    );
    if (!advancedAvailabilityOn) {
      data.availability.hours = {
        base: data.availability.hours.base,
        ...cloneDeep(DefaultRentalHours),
      };
    }
    this.setState({ isSubmitting: true });
    let url = this.props.saveLink;
    let dataName = "rental";
    if ([2, 3, 4].includes(activeStep) || fromModal) {
      url += `vehicles/${vehicle.id}.json`;
      body.vehicle = vehicle;
    } else {
      url += `rentals/${data.id}.json`;
      body.rental = data;
      body.has_box = has_box;
      body.box_code = box_code;
      body.fuel_policy_id = selectedFuelPolicy;
      body.cancel_policy_id = selectedCancellationPolicy;
      body.step = activeStep;
    }
    if (isLastStep) {
      url += "?finishing=true";
      if (firstAttempt) url += "&first_attempt=true";
    }
    Axios({
      method: "PUT",
      url,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: body,
    })
      .then((response) => {
        if (response.data.success) {
          this.showMessage("primary", "Éxito", ["Datos guardados"]);
          if (isLastStep && !fromModal) {
            this.props.formAction();
          } else if (fromModal) {
            this.modalVehData.hide();
          }
        } else {
          $("html, main").animate({ scrollTop: 0 }, "slow");
          this.showMessage(
            "primary",
            "Alerta",
            response.data.messages || ["Ha ocurrido un error inesperado"]
          );
        }
      })
      .catch((error) => {
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ]);
      })
      .finally(() =>
        this.setState({ isSubmitting: false, activeStep: newStep, renderKey })
      );
  };

  handleChangeAvailability = (value, name, obj) => {
    this.setState((state) => {
      state.data.availability[obj] = {
        ...state.data.availability[obj],
        [name]: value,
      };
      return { data: state.data };
    });
  };

  handleChangeDates = (months) => {
    const from = moment()
      .utcOffset(0)
      .format("YYYY-MM-DDTHH:mm:ssZZ");
    const to = moment()
      .add(months, "months")
      .utcOffset(0)
      .format("YYYY-MM-DDTHH:mm:ssZZ");
    this.setState((state) => {
      state.data.date_from = from;
      state.data.date_until = to;
      return { data: state.data };
    });
  };

  handleChangePricing = (value, name) => {
    this.setState((state) => {
      state.data.pricing[name] = value;
      return { data: state.data };
    });
  };

  checkPrice = (obj) => {
    const proposedPrice = this.state.data.pricing[obj];
    return Axios({
      method: "POST",
      url: `/rentals/${this.state.data.id}/check_price`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        proposed_price: proposedPrice,
      },
    });
  };

  handleCheckPrice = (obj) => {
    this.loadSpinner();
    this.checkPrice(obj)
      .then((response) => {
        const {
          warning,
          message,
          appraisal,
          recommended_price,
        } = response.data;
        this.setState((state) => {
          state.priceAlert[obj] = warning ? message : "";
          return {
            priceAlert: state.priceAlert,
            appraisal,
            recommendedPrice: recommended_price,
          };
        });
      })
      .catch((error) => {
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ]);
      })
      .finally(() => this.setState({ isSubmitting: false }));
  };

  handleChangeInformation = (value, name) => {
    this.setState((state) => {
      state.data.information[name] = value;
      return { data: state.data };
    });
  };

  handleChangeLocation = (location) => {
    this.setState((state) => {
      state.data.location = location;
      return { data: state.data };
    });
  };

  showMessage = (messageType, messageTitle, messages) =>
    this.setState(
      (state) => {
        state.alert.showMessage = true;
        state.alert.messageTitle = messageTitle;
        state.alert.messages = messages;
        state.alert.messageType = messageType;
        return { alert: state.alert };
      },
      () => {
        setTimeout(this.handleAlertClose, 3000);
      }
    );

  handleAlertClose = () =>
    this.setState((state) => {
      state.alert.showMessage = false;
      return { alert: state.alert };
    });

  loadSpinner = () => this.setState({ isSubmitting: true });
  closeSpinner = () => this.setState({ isSubmitting: false });

  handleChangeSecurityBox = (value) => {
    this.setState({ has_box: !!value, box_code: value });
  };

  openModalWeekendPrice = () => this.modalWeekendPricing.show();
  openModalBasePrice = () => this.modalBasePricing.show();

  addExtra = (index) => {
    this.setState(
      (state) => {
        state.extrasList[index].checked = true;
        return { data: state.data };
      },
      () => this.updateExtras()
    );
  };

  removeExtra = (index) => {
    this.setState(
      (state) => {
        state.extrasList[index].checked = false;
        return { data: state.data };
      },
      () => this.updateExtras()
    );
  };

  updateExtraPrice = (index, price) => {
    this.setState(
      (state) => {
        state.extrasList[index].price = price;
        return { data: state.data };
      },
      () => this.updateExtras()
    );
  };

  updateExtraDaily = (index, checked) => {
    this.setState(
      (state) => {
        state.extrasList[index].charge_per_day = checked;
        return { data: state.data };
      },
      () => this.updateExtras()
    );
  };

  updateExtras = () => {
    const extrasList = this.state.extrasList;
    const extras = extrasList
      .filter((x) => x.checked)
      .map((extra) => ({
        price: extra.price,
        vehicle_extra: {
          ...extra.extra,
          charge_per_day: extra.charge_per_day,
        },
      }));
    this.setState((state) => {
      state.data.pricing.extra_charges = extras;
      return { data: state.data };
    });
  };

  handleChangeFuelPolicy = (selectedFuelPolicy) =>
    this.setState({ selectedFuelPolicy });

  handleChangeCancellationPolicy = (selectedCancellationPolicy) =>
    this.setState({ selectedCancellationPolicy });

  handleChangeUnlimitedDistance = (unlimitedDistance) =>
    this.setState({ unlimitedDistance });

  createParams = (obj) => {
    const pricing = this.state.data.pricing;
    const appraisal = this.props.recommendedPrice;
    let start = "";
    let end = "";
    if (obj == "base_price") {
      start = moment()
        .startOf("week")
        .add(1, "day")
        .format();
      end = moment()
        .startOf("week")
        .add(2, "day")
        .format();
    } else if (obj == "weekend_price") {
      start = moment()
        .endOf("week")
        .startOf("day")
        .add(-1, "day")
        .format();
      end = moment()
        .endOf("week")
        .startOf("day")
        .format();
    }
    return {
      estimate_price: {
        weekend_value: pricing.weekend_price,
        base_value: pricing.base_price,
        vehicle_appraisal: appraisal,
        start,
        end,
      },
    };
  };

  calcBothPrices = () => {
    this.loadSpinner();
    const pricing = this.state.data.pricing;
    let priceAlert = this.state.priceAlert;
    if (pricing?.base_price < 1 && pricing?.weekend_price < 1) {
      this.closeSpinner();
      return;
    }
    const basePriceData = this.createParams("base_price");
    const weekendPriceData = this.createParams("weekend_price");
    const basePricePromise = this.calcPrice(basePriceData);
    const weekendPricePromise = this.calcPrice(weekendPriceData);
    Promise.all([
      basePricePromise,
      weekendPricePromise,
      this.checkPrice("base_price"),
      this.checkPrice("weekend_price"),
    ])
      .then((values) => {
        const [
          { data: basePriceData },
          { data: weekendPriceData },
          { data: checkBasePrice },
          { data: checkWeekendPrice },
        ] = values;
        priceAlert.base_price = checkBasePrice.warning
          ? checkBasePrice.message
          : "";
        priceAlert.weekend_price = checkWeekendPrice.warning
          ? checkWeekendPrice.message
          : "";
        const base_price = basePriceData?.price;
        const weekend_price = weekendPriceData?.price;
        this.setState({ base_price, weekend_price, priceAlert });
      })
      .catch((error) => console.error(error))
      .finally(() => this.closeSpinner());
  };

  calcPrice = (data) =>
    Axios({
      method: "POST",
      url: `/rentals/${this.props.rental.id}/estimate_price.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data,
    });

  handleCalcPrice = (obj) => {
    this.loadSpinner();
    const pricing = this.state.data.pricing;
    if (
      (obj == "base_price" && pricing.base_price < 1) ||
      (obj == "weekend_price" && pricing.weekend_price < 1)
    ) {
      this.closeSpinner();
      return;
    }
    const data = this.createParams(obj);
    this.calcPrice(data)
      .then((response) => {
        if (response.data.success) {
          this.setState({ [obj]: response.data.price });
        } else {
          this.showMessage(
            "primary",
            "Alerta",
            response.data.messages || ["Ha ocurrido un error inesperado"]
          );
        }
      })
      .catch((error) => {
        this.showMessage("primary", "Alerta", [
          "Ha ocurrido un error inesperado",
        ]);
      })
      .finally(() => this.closeSpinner());
  };

  clearPrice = (obj) =>
    this.setState({
      [obj]: cloneDeep(DefaultPrice),
      priceAlert: { base_price: "", weekend_price: "" },
    });

  handleChangeAdvancedAvailabilityCheck = (val) =>
    this.setState({ advancedAvailabilityOn: val });

  addFeature = (feature) => {
    this.setState((state) => {
      state.vehicle.vehicle_features.push(feature);
      return { vehicle: state.vehicle };
    });
  };

  removeFeature = (feature) => {
    const index = this.state.vehicle.vehicle_features.findIndex(
      (x) => x.id === feature.id
    );
    this.setState((state) => {
      state.vehicle.vehicle_features.splice(index, 1);
      return { vehicle: state.vehicle };
    });
  };

  openFileDialog = (objectUploading) =>
    this.setState({ objectUploading }, () =>
      document.getElementById("image_uploader").click()
    );

  openFileDialogDocuments = (objectUploading) =>
    this.setState({ objectUploading }, () =>
      document.getElementById("image_uploader_documents").click()
    );

  uploadPicture = (e) => {
    let file = e.target.files;
    const fullPath = this.state.objectUploading;
    if (file.length > 0) {
      const filename = generateFilename(file[0].name);
      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onloadend = function(e) {
        var base64result = reader.result;
        this.addPicture(base64result, filename, fullPath);
      }.bind(this);
    }
  };

  addPicture = (picture, filename, full_path) => {
    const path = full_path.split(".");
    const file = picture.split(",");
    const start = file[0];
    const image_base_64 = file[1];
    const mime = picture.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    const mime_type = mime && mime.length ? mime[1] : "";
    filename = encodeURI(filename);
    this.setState((state) => {
      if (full_path === "firstVehiclePicture") {
        state.vehicle.vehicle_pictures[0] = {
          filename,
          start,
          image_base_64,
          mime_type,
        };
      } else {
        if (path.length == 1) {
          state.vehicle[path[0]].push({
            filename,
            start,
            image_base_64,
            mime_type,
          });
          if (path[0] == "vehicle_pictures") {
            state.justLoadedAdditionalPicture = true;
          }
        } else if (path.length == 2) {
          state.vehicle[path[0]][path[1]].push({
            filename,
            start,
            image_base_64,
            mime_type,
          });
        }
      }
      return {
        vehicle: state.vehicle,
        justLoadedAdditionalPicture: state.justLoadedAdditionalPicture,
      };
    });
  };

  reportUpdatedLoadingMore = () =>
    this.setState({ justLoadedAdditionalPicture: false });

  removePicture = (index, full_path) => {
    const path = full_path.split(".");
    this.setState((state) => {
      if (path.length == 1) {
        state.vehicle[path[0]].splice(index, 1);
      } else if (path.length == 2) {
        state.vehicle[path[0]][path[1]].splice(index, 1);
      }
      return { vehicle: state.vehicle };
    });
  };

  handleChange = (value, name) => {
    this.setState((state) => {
      state.vehicle[name] = value;
      return { vehicle: state.vehicle };
    });
  };

  handleChangeObj = (value, name, obj) => {
    this.setState((state) => {
      state.vehicle[obj] = {
        ...state.vehicle[obj],
        [name]: value,
      };
      return { vehicle: state.vehicle };
    });
  };

  handleChangeModel = (option) => {
    const vehicle_type = this.props.vehicleTypes.find(
      (x) => x.id === option.value
    );
    this.handleChange(vehicle_type, "vehicle_type");
  };

  render() {
    const {
      activeStep,
      alert,
      data,
      isSubmitting,
      box_code,
      base_price,
      weekend_price,
      renderKey,
      advancedAvailabilityOn,
      extrasList,
      unlimitedDistance,
      selectedCancellationPolicy,
      selectedFuelPolicy,
      recommendedPrice,
      appraisal,
      priceAlert,
      vehicle,
      justLoadedAdditionalPicture,
    } = this.state;
    const { messageTitle, messageType, messages, showMessage } = alert;
    const {
      title,
      vehicleFeatures,
      vehicleCategories,
      fuelTypes,
      vehicleTransmissions,
    } = this.props;
    const vehicleTypes = this.props.vehicleTypes.sort((a, b) => {
      if (a.brand > b.brand) {
        return 1;
      } else if (a.brand < b.brand) {
        return -1;
      } else if (a.model > b.model) {
        return 1;
      } else if (a.model < b.model) {
        return -1;
      } else return 0;
    });

    const selectedBrand = vehicle.vehicle_type && vehicle.vehicle_type.brand;

    const brandOptions = uniqBy(vehicleTypes, "brand").map((type) => ({
      label: type.brand,
      value: type.brand,
    }));

    const modelOptions = vehicleTypes
      .filter((type) => type.brand === selectedBrand)
      .map((type) => ({
        label: type.model,
        value: type.id,
      }));

    const categoriesOptions = vehicleCategories.map((category) => ({
      label: category.description,
      value: category.id,
    }));

    const fuelTypesOptions = fuelTypes.map((fuel) => ({
      label: fuel.description,
      value: fuel.id,
    }));

    let menuList = [];
    const menuTexts = [
      "Info",
      "Disponibilidad",
      "Fotos",
      "Documentos",
      "Características",
      "Políticas",
      "Extras",
    ];
    for (let i = 0; i < 7; i++) {
      const item = (
        <li
          className={`list-group-item  ${i === activeStep ? "active" : ""}`}
          role="button"
          onClick={() => {
            if (i !== activeStep) {
              this.handleSubmit(i);
            }
          }}
          key={i}
        >
          <span className="list-number">{i + 1}</span>
          {menuTexts[i]}
        </li>
      );
      menuList.push(item);
    }

    const modalBasePricing = (
      <ModalPricing price={base_price} modalId="modal-base-pricing" />
    );

    const modalWeekendPricing = (
      <ModalPricing price={weekend_price} modalId="modal-weekend-pricing" />
    );

    const modalVehicleData = (
      <ModalVehicleData
        vehicle={vehicle}
        onCancel={() => {
          this.setState({ vehicle: cloneDeep(this.props.vehicle) });
          this.modalVehData.hide();
        }}
        handleSubmit={() => this.handleSubmit(activeStep, true)}
        addFeature={this.addFeature}
        brandOptions={brandOptions}
        categoriesOptions={categoriesOptions}
        fuelTypesOptions={fuelTypesOptions}
        handleChange={this.handleChange}
        handleChangeModel={this.handleChangeModel}
        handleChangeObj={this.handleChangeObj}
        isSubmitting={isSubmitting}
        modelOptions={modelOptions}
        removeFeature={this.removeFeature}
        vehicleFeatures={vehicleFeatures}
        vehicleTransmissions={vehicleTransmissions}
      />
    );

    let activeComponent = null;
    switch (activeStep) {
      case 0:
        activeComponent = (
          <RentalInfo
            data={data}
            isSubmitting={isSubmitting}
            handleSubmit={() => this.handleSubmit(1)}
            handleChangeInformation={this.handleChangeInformation}
            handleChangeLocation={this.handleChangeLocation}
            handleChangePricing={this.handleChangePricing}
            loadSpinner={this.loadSpinner}
            closeSpinner={this.closeSpinner}
            handleChangeSecurityBox={this.handleChangeSecurityBox}
            boxCode={box_code}
            key={renderKey}
          />
        );
        break;
      case 1:
        activeComponent = (
          <RentalAvailability
            data={data}
            isSubmitting={isSubmitting}
            handleSubmit={() => this.handleSubmit(2)}
            advancedAvailabilityOn={advancedAvailabilityOn}
            handleChangeAvailability={this.handleChangeAvailability}
            handleChangeAdvancedAvailabilityCheck={
              this.handleChangeAdvancedAvailabilityCheck
            }
            handleChangeDates={this.handleChangeDates}
            key={renderKey}
          />
        );
        break;
      case 2:
        activeComponent = (
          <VehiclePictures
            openFileDialog={this.openFileDialog}
            data={vehicle}
            handleSubmit={() => this.handleSubmit(3)}
            isSubmitting={isSubmitting}
            removePicture={this.removePicture}
            justLoadedAdditionalPicture={justLoadedAdditionalPicture}
            reportUpdatedLoadingMore={this.reportUpdatedLoadingMore}
          />
        );
        break;
      case 3:
        activeComponent = (
          <VehicleDocuments
            openFileDialog={this.openFileDialogDocuments}
            data={vehicle}
            handleSubmit={() => this.handleSubmit(4)}
            isSubmitting={isSubmitting}
            handleChangeObj={this.handleChangeObj}
            handleChange={this.handleChange}
            removePicture={this.removePicture}
          />
        );
        break;
      case 4:
        activeComponent = (
          <VehicleFeatures
            data={vehicle}
            addFeature={this.addFeature}
            removeFeature={this.removeFeature}
            isSubmitting={isSubmitting}
            handleSubmit={() => this.handleSubmit(5)}
            vehicleFeatures={vehicleFeatures}
            handleChange={this.handleChange}
          />
        );
        break;
      case 5:
        activeComponent = (
          <RentalPolicies
            isSubmitting={isSubmitting}
            handleSubmit={() => this.handleSubmit(6)}
            handleChangeFuelPolicy={this.handleChangeFuelPolicy}
            handleChangeCancellationPolicy={this.handleChangeCancellationPolicy}
            unlimitedDistance={unlimitedDistance}
            handleChangeUnlimitedDistance={this.handleChangeUnlimitedDistance}
            selectedCancellationPolicy={selectedCancellationPolicy}
            selectedFuelPolicy={selectedFuelPolicy}
          />
        );
        break;
      case 6:
        activeComponent = (
          <RentalExtras
            isSubmitting={isSubmitting}
            handleSubmit={() => this.handleSubmit(7)}
            key={renderKey}
            addExtra={this.addExtra}
            removeExtra={this.removeExtra}
            updateExtraPrice={this.updateExtraPrice}
            updateExtraDaily={this.updateExtraDaily}
            extrasList={extrasList}
          />
        );
        break;
    }
    return (
      <>
        <Spinner loading={isSubmitting} />
        <Alert
          alertClass={messageType}
          isShowing={showMessage}
          messages={messages}
          title={messageTitle}
          onClose={this.handleAlertClose}
        />
        <div className="edit-box">
          <div className="photo-container">
            <div className="image-loader pl-md-5 mb-1">
              <h2 className="fs-30">{title}</h2>
              <h3 className="fs-12">Parte {activeStep + 1} de 5</h3>
              <ul className="list-group appcar-list pt-4">{menuList}</ul>
            </div>
            <RentalPreview
              vehicle={vehicle}
              action={() => {
                this.modalVehData.show();
              }}
              title={
                this.props.firstAttempt
                  ? "Estás creando la publicación de tu auto:"
                  : "Estás editando la publicación de tu auto:"
              }
            />
          </div>
          <div className="form">{activeComponent}</div>
          <input
            type="file"
            id="image_uploader"
            className="d-none"
            onChange={(e) => this.uploadPicture(e)}
            accept="image/jpeg, image/png, image/jpg"
            onClick={(e) => {
              e.target.value = null;
            }}
          />
          <input
            type="file"
            id="image_uploader_documents"
            className="d-none"
            onChange={(e) => this.uploadPicture(e)}
            accept="image/jpeg, image/png, image/jpg, application/pdf"
            onClick={(e) => {
              e.target.value = null;
            }}
          />
        </div>
        {modalBasePricing}
        {modalWeekendPricing}
        {modalVehicleData}
      </>
    );
  }
}
