import React, { useEffect } from "react";
import ToolTip, { LoadToolTips } from "../../common/components/ToolTip";
import iconInfo from "images/Icons/ico-info.png";
import {
  cancelPolicyDictionary,
  fuelPolicyDictionary,
} from "../../common/constants";

const VehiclePolicies = ({ policyId, type }) => {
  useEffect(() => {
    LoadToolTips();
  }, []);
  let policy = null;
  if (type == "fuel_policy") {
    policy = fuelPolicyDictionary(false).find((x) => x.id == policyId);
  } else if (type == "cancellation_policy") {
    policy = cancelPolicyDictionary(false).find((x) => x.id == policyId);
  }
  return (
    <>
      <ToolTip
        title={policy.title}
        tooltipHTML={policy.content}
        containerHTML={<img src={iconInfo} width={12} className="ml-1 pb-1" />}
        id={`${type}_ttip`}
        key={`${type}_ttip`}
      />
    </>
  );
};

export default VehiclePolicies;
