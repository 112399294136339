import numeral_ from "numeral";
import React, { Fragment } from "react";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { clone } from "lodash";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

numeral_.register("locale", "es-cl", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  abbreviations: {
    thousand: "k",
    million: "m",
    billion: "b",
    trillion: "t",
  },
  ordinal: function(number) {
    return number === 1 ? "" : "s";
  },
  currency: {
    symbol: "$",
  },
});

numeral_.locale("es-cl");

export const formateaRut = (rut) => {
  let rutPuntos = "";
  const actual = rut.replace(/^0+/, "");
  if (actual != "" && actual.length > 1) {
    const sinPuntos = actual.replace(/\./g, "");
    const actualLimpio = sinPuntos.replace(/-/g, "");
    const inicio = actualLimpio.substring(0, actualLimpio.length - 1);
    let i = 0;
    let j = 1;
    for (i = inicio.length - 1; i >= 0; i--) {
      const letra = inicio.charAt(i);
      rutPuntos = letra + rutPuntos;
      if (j % 3 == 0 && j <= inicio.length - 1) {
        rutPuntos = "." + rutPuntos;
      }
      j++;
    }
    const dv = actualLimpio.substring(actualLimpio.length - 1).toUpperCase();
    rutPuntos = rutPuntos + "-" + dv;
  }
  return rutPuntos;
};

export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const calculatePricing = (pricing) => pricing * 1.5416 + 5550;

export const formatedPricing = (pricing) =>
  // numeral_(calculatePricing(pricing)).format("$0,0");
  numeral_(pricing).format("$0,0");

export const numeral = numeral_;

export const formattedWaMeURL = (number) => {
  const url = "https://wa.me/";
  const formattedNumber = number.toString().replace("+", "");
  return url + formattedNumber;
};

export const constants = {
  RENTAL_SEARCH_FROM: dayjs()
    .add(1, "day")
    .startOf("day")
    .add(13, "hour")
    .format(),
  RENTAL_SEARCH_UNTIL: dayjs()
    .add(2, "day")
    .startOf("day")
    .add(13, "hour")
    .format(),
  RENTAL_SEARCH_RADIUS: 5,
  RENTAL_SEARCH_LATITUDE: -33.4189034,
  RENTAL_SEARCH_LONGITUDE: -70.6028601,
  RENTAL_SEARCH_DEFAULT_ADDRESS:
    "Metro Tobalaba - Avenida Providencia, Providencia, Chile",
  MIN_YEAR: dayjs().year(),
  MAX_YEAR: dayjs()
    .add(5, "years")
    .year(),
  MIN_PRICE: 0,
  MAX_PRICE: 100000,
  RANGE_STEPS: 5000,
  RESULTS_PER_PAGE: [10, 25, 50, 100],
  DEFAULT_RESULTS_PER_PAGE: 10,
  SEARCH_BY_BUTTON: "button",
  SEARCH_BY_MAP: "map",
  MAX_250: 22,
  SMOKERS: 18,
  RUT: "RUT",
  COMISSION_PERCENTAGE: 0.1,
};

export const BookingStatus = {
  PENDIENTE: 0,
  ACTIVO: 1,
  CANCELADO: 2,
  INICIADO: 3,
  COMPLETADO: 4,
  EN_REVISION: 5,
  REEMPLAZADO: 6,
};

export const MercadoPagoPaymentStatus = {
  APPROVED: "approved",
  REFUNDED: "refunded",
  PENDING: "pending",
  AUTHORIZED: "authorized",
  IN_PROCESS: "in_process",
  IN_MEDIATION: "in_mediation",
  REJECTED: "rejected",
  CANCELLED: "cancelled",
  CHARGED_BACK: "charged_back",
};

export const OptionsMercadoPagoIdType = [
  {
    value: "RUT",
    label: "RUT",
  },
  {
    value: "Otro",
    label: "Otro",
  },
];

export const OptionsDistance = [
  {
    value: "true",
    label: "Ilimitado",
  },
  {
    value: "false",
    label: "Máx. 250 kms.",
  },
];

export const OptionsSeats = [
  {
    value: 5,
    label: "5 o más",
  },
  {
    value: 6,
    label: "6 o más",
  },
  {
    value: 7,
    label: "7 o más",
  },
];

export const OptionsOrder = [
  {
    value: 1,
    label: "Cercanía",
  },
  {
    value: 2,
    label: "Precio de menor a mayor",
  },
  {
    value: 3,
    label: "Precio de mayor a menor",
  },
];

export const DefaultRentalAvailability = {
  reservation_preferences: {
    advance_notice: 0,
    preparation_time: 1,
    booking_window: 120,
  },
  booking_length: {
    minimum_length: 0,
    minimum_length_saturday: 0,
    minimum_length_sunday: 0,
    maximum_length: 30,
  },
  checkin_checkout: {
    checkin_start_time: "07:00",
    checkin_end_time: "23:45",
    checkout_time: "23:45",
  },
  hours: DefaultRentalHours,
};

export const DefaultRentalPricing = {
  base_price: 0.0,
  weekend_price: 0.0,
  length_discount: {
    weekly_discount: 0,
    monthly_discount: 0,
  },
  early_bird_discount: {
    discount_end: 0,
    discount: 0,
  },
  extra_charges: [],
  extra_locations: [],
};

export const DefaultRentalHours = {
  mon: {
    no_rental: false,
    closed: false,
    opening_time: null,
    closing_time: null,
  },
  tue: {
    no_rental: false,
    closed: false,
    opening_time: null,
    closing_time: null,
  },
  wed: {
    no_rental: false,
    closed: false,
    opening_time: null,
    closing_time: null,
  },
  thu: {
    no_rental: false,
    closed: false,
    opening_time: null,
    closing_time: null,
  },
  fri: {
    no_rental: false,
    closed: false,
    opening_time: null,
    closing_time: null,
  },
  sat: {
    no_rental: false,
    closed: false,
    opening_time: null,
    closing_time: null,
  },
  sun: {
    no_rental: false,
    closed: false,
    opening_time: null,
    closing_time: null,
  },
};

export const DefaultImageObj = {
  url: "",
  start: "",
  mime_type: "",
  filename: "",
  image_base_64: "",
};

export const PhotoAngleDictionary = {
  vehicle_pictures: "Vehículo",
  front_pictures: "Frontal",
  left_pictures: "Costado izquierdo",
  right_pictures: "Costado derecho",
  back_pictures: "Traseras",
  chassis_pictures: "Chassis",
  user_vehicle_pictures: "Driver",
  owner_vehicle_pictures: "Owner",
  additional_pictures: "Adicionales",
  vehicle_odometer_pictures: "Odómetro",
  spare_wheel_pictures: "Rueda repuesto",
  fuel_level_pictures: "Nivel de combustible",
  radio_pictures: "Radio",
};

export const DefaultInsurancePics = {
  start_location: {
    front_pictures: [clone(DefaultImageObj)],
    right_pictures: [clone(DefaultImageObj)],
    left_pictures: [clone(DefaultImageObj)],
    back_pictures: [clone(DefaultImageObj)],
    spare_wheel_pictures: [clone(DefaultImageObj)],
    fuel_level_pictures: [clone(DefaultImageObj)],
    user_vehicle_pictures: [clone(DefaultImageObj)],
  },
  end_location: {
    front_pictures: [clone(DefaultImageObj)],
    right_pictures: [clone(DefaultImageObj)],
    left_pictures: [clone(DefaultImageObj)],
    back_pictures: [clone(DefaultImageObj)],
    spare_wheel_pictures: [clone(DefaultImageObj)],
    vehicle_odometer_pictures: [clone(DefaultImageObj)],
    vehicle_pictures: [clone(DefaultImageObj)],
  },
  insurance_pictures: {
    front_pictures: [clone(DefaultImageObj)],
    right_pictures: [clone(DefaultImageObj)],
    left_pictures: [clone(DefaultImageObj)],
    back_pictures: [clone(DefaultImageObj)],
    vehicle_odometer_pictures: [clone(DefaultImageObj)],
    spare_wheel_pictures: [clone(DefaultImageObj)],
    additional_pictures: [clone(DefaultImageObj)],
  },
  insurance_pictures_post: {
    additional_pictures: [clone(DefaultImageObj)],
  },
};

export const advanceNoticeOptions = [
  {
    label: "Me pueden reservar para el mismo día",
    value: 0,
  },
  {
    label: "Al menos un día antes de la reservación",
    value: 1,
  },
  {
    label: "Al menos dos días antes de la reservación",
    value: 2,
  },
  {
    label: "Al menos tres días antes de la reservación",
    value: 3,
  },
  {
    label: "Al menos siete días antes de la reservación",
    value: 7,
  },
];

export const preparationTimeOptions = [
  {
    value: 0,
    label: "0 horas",
  },
  {
    value: 1,
    label: "1 hora",
  },
  {
    value: 2,
    label: "2 horas",
  },
  {
    value: 3,
    label: "3 horas",
  },
  {
    value: 4,
    label: "4 horas",
  },
  {
    value: 5,
    label: "5 horas",
  },
];

export const reservationPreferencesOptions = [
  {
    label: "Fechas no disponibles por defecto",
    value: 0,
  },
  { label: "3 meses a partir de ahora", value: 3 },
  { label: "6 meses a partir de ahora", value: 6 },
  { label: "9 meses a partir de ahora", value: 9 },
  { label: "12 meses a partir de ahora", value: 12 },
  { label: "Todas las fechas futuras", value: 120 },
];

export const fuelPolicyDictionary = (shortDescription = true) => [
  {
    id: 1,
    title: "Full - Full",
    content: shortDescription
      ? "El auto debe entregarse con el estanque lleno. " +
        "El Driver también debe devolverlo con el estanque lleno. " +
        "De no cumplirse hay multas asociadas para ambos. Más información en tu menú."
      : "El vehículo estará disponible con el estanque lleno al momento de tomarlo. Se debe devolver con el estanque lleno." +
        "<br/><br/><b>Multas Driver</b>:<br/>- $ 15.000 : Si se devuelve el vehículo sin el estanque lleno + el diferencial en 1/8s con respecto al estanque como venía." +
        "<br/>- $ 10.000 : Multa adicional si el Driver devuelve el vehículo con menos de 1/4 de estanque lleno." +
        "<br/><br/><b>Multas Owner</b><br/>- $ 10.000 : Si entrega el vehículo sin estanque lleno (además, pasa a ser política Igual - Igual por ese arriendo)." +
        "<br/>- $ 10.000 : Multa adicional si la entrega fue con menos de 1/2 estanque lleno.",
  },
  {
    id: 2,
    title: "Igual - Igual",
    content: shortDescription
      ? "El auto debe entregarse con al menos ½ estanque. " +
        "El Driver debe devolverlo con el mismo nivel o más. " +
        "De no cumplirse hay multas asociadas para ambos Más información en tu menú."
      : "El vehículo se debe devolver con el estanque con el mismo nivel o más que el entregado." +
        "<br/><br/><b>Multas Driver</b>:<br/>- $ 10.000 : Si entrega el vehículo con menos combustible que el recibido + el diferencial consumido en 1/8s" +
        "<br/>- $ 10.000 : Multa adicional si el Driver devuelve el vehículo con menos de 1/4 de estanque lleno." +
        "<br/><br/><b>Multas Owner</b><br/>- $ 10.000 : Si la entrega fue con menos de 1/2 estanque lleno.",
  },
  {
    id: 3,
    title: "Da Igual",
    content: shortDescription
      ? "El Owner se compromete a entregar el auto con al menos ½ estanque. " +
        "El Driver puede devolverlo con hasta ¼ de estanque sin cobro de multa adicional al combustible. " +
        "De no cumplirse hay multas asociadas para ambos Más información en tu menú."
      : "El Owner se compromete a entregar el vehículo con al menos ½ estanque." +
        "<br/>El Driver puede devolverlo con hasta ¼ de estanque sin cobro de multa adicional al combustible." +
        "<br/><br/><b>Multas Driver</b>:<br/>- Si se devuelve el vehículo con menos combustible de lo recibido, se cobra el diferencial en 1/8s con respecto al estanque como venía." +
        "<br/>- $ 5.000 : Multa adicional si el Driver devuelve el vehículo con menos de 1/4 de estanque lleno." +
        "<br/><br/><b>Multas Owner</b><br/>- $ 5.000 : Si entrega el vehículo con menos de ½ estanque (además, pasa a ser política Igual - Igual por ese arriendo)." +
        "<br/>- $ 5.000 : Multa adicional si entrega con menos de 1/4 estanque lleno.",
  },
];
export const cancelPolicyDictionary = (shortDescription = false) => [
  {
    id: 0,
    title: "Relax (6h)",
    content: shortDescription
      ? "<ul>" +
        "<li class='mb-2'>Si el Driver cancela dentro del mismo día de la solicitud de reserva o durante el día siguiente a la solicitud, se le reembolsará 100%.</li>" +
        "<li class='mb-2'>Si el Driver cancela al menos 6 horas antes del inicio del arriendo, se le reembolsará 100%.</li>" +
        "<li class='mb-2'>Si el Driver cancela con menos de 6 horas de anticipación, se le reembolsará 100% menos un día.</li>" +
        "<li class='mb-2'>Si el Driver utiliza el auto, pero lo devuelve antes de tiempo se le reembolsará 100% de lo no utilizado.</li>" +
        "</ul>"
      : "<b>Reembolso 100%</b>" +
        "<br/>- Cancelaciones de arriendos solicitados para el mismo día, cancelado dentro de la primera hora desde que se acepta el arriendo." +
        "<br/>- Si cancela al menos 6 horas antes del inicio del arriendo." +
        "<br/><br/><b>Reembolso parcial</b>" +
        "<br/>- Si cancela con menos de 6 horas de anticipación, se cobra un día de arriendo." +
        "<br/>- Si llega al vehículo y lo utiliza, devolución de 100% de lo no utilizado.",
  },
  {
    id: 1,
    title: "Semi Relax (2 días)",
    content: shortDescription
      ? "<ul>" +
        "<li class='mb-2'>Si el Driver cancela dentro del mismo día de la solicitud de reserva, se le reembolsará 100%.</li>" +
        "<li class='mb-2'>Si el Driver cancela al menos 2 días antes del inicio del arriendo, se le reembolsará 100%.</li>" +
        "<li class='mb-2'>Si el Driver cancela con menos de 2 días de anticipación, se le reembolsará 75% menos un día.</li>" +
        "<li class='mb-2'>Si el Driver utiliza el auto, pero lo devuelve antes de tiempo se le reembolsará 75% de lo no utilizado.</li>" +
        "</ul>"
      : "<b>Reembolso 100%</b>" +
        "<br/>- Cancelaciones de arriendos solicitados para el mismo día, cancelado dentro de la primera hora desde que se acepta el arriendo." +
        "<br/>- Cancelaciones dentro de las 24 horas desde que es aceptado el arriendo y al menos 6 horas antes del inicio del arriendo." +
        "<br/>- Si cancela al menos 2 días antes del inicio del arriendo." +
        "<br/><br/><b>Reembolso 85%, con un cobro mínimo de 1 día de arriendo.</b>" +
        "<br/>- Si cancela con menos de 2 días de anticipación." +
        "<br/>- Si llega al vehículo y lo utiliza, devolución de 85% de lo no utilizado.",
  },
  {
    id: 2,
    title: "Estricta (5 días)",
    content: shortDescription
      ? "<ul>" +
        "<li class='mb-2'>Si el Driver cancela dentro del mismo día de la solicitud de reserva, se le reembolsará 100%.</li>" +
        "<li class='mb-2'>Si el Driver cancela al menos 5 días antes del inicio del arriendo, se le reembolsará 100%.</li>" +
        "<li class='mb-2'>Si el Driver cancela con menos de 5 días de anticipación del inicio del arriendo, se le reembolsará 60% menos un día.</li>" +
        "<li class='mb-2'>Si el Driver utiliza el auto, pero lo devuelve antes de tiempo se le reembolsará 60% de lo no utilizado</li>" +
        "</ul>"
      : "<b>Reembolso 100%</b>" +
        "<br/>- Cancelaciones de arriendos solicitados para el mismo día, cancelado dentro de la primera hora desde que se acepta el arriendo." +
        "<br/>- Cancelaciones dentro de las 24 horas desde que es aceptado el arriendo y al menos 6 horas antes del inicio del arriendo." +
        "<br/>- Si cancela al menos 5 días antes del inicio del arriendo." +
        "<br/><br/><b>Reembolso 70%, con un cobro mínimo de 1 día de arriendo.</b>" +
        "<br/>- Si cancela con menos de 5 días de anticipación." +
        "<br/>- Si llega al vehículo y lo utiliza, devolución de 70% de lo no utilizado.",
  },
];

export const DefaultPrice = {
  total: 0,
  details: [],
  extra_charges: [],
  commission: 0,
  insurance_charge: 0,
  payment_method_fee: 0,
  value_added_tax: 0,
  pick_up_charge: null,
  drop_off_charge: null,
  clf_value: 0.0,
  owner_total: 0.0,
  discount: 0.0,
};

export const MasterKeyRequestMotives = {
  NEW_CAR: "new_car",
  UPDATE_CAR_AVAILABILITY: "update_car_availability",
  USER_EDIT: "user_edit",
  RENTAL_EDIT: "rental_edit",
  RESOLVE_BOOKING: "resolve_booking",
  BOOKING_EDIT: "booking_edit",
  BOOKING_OWNER_EDIT: "booking_owner_edit",
  OTHER: "other",
};

export const termsAndConditionsText = (
  <Fragment>
    <p>Documentos asociados:</p>
    <ul>
      <li>
        <a
          href="/legal/terms_and_conditions"
          target="_blank"
          rel="noopener noreferrer"
          className="font-weight-bold"
        >
          Términos y Condiciones.
        </a>
      </li>
    </ul>
  </Fragment>
);
export const subleaseAgreementText = (
  booking_id,
  rental_id,
  user_id,
  from,
  to,
  total_price,
  pickup,
  dropoff,
  insurance_charge,
  clf_value,
  owner_total,
  discount,
  cancellation_policy,
  fuel_policy,
  selected_extras = []
) => {
  const pickupParam = pickup ? "&pickup=" + encodeURIComponent(pickup) : "";
  const dropoffParam = dropoff ? "&dropoff=" + encodeURIComponent(dropoff) : "";
  const url = booking_id
    ? //Si tiene booking_id
      // "/legal/sublease_agreement?booking_id=" + encodeURIComponent(booking_id)
      "/legal/booking_certificate?booking_id=" + encodeURIComponent(booking_id)
    : //Si no, es porque es una modificación de parte del owner
      // "/legal/sublease_agreement?rental_id=" +
      "/legal/booking_certificate?rental_id=" +
      encodeURIComponent(rental_id) +
      "&user_id=" +
      encodeURIComponent(user_id) +
      "&from=" +
      encodeURIComponent(from) +
      "&to=" +
      encodeURIComponent(to) +
      "&total_price=" +
      encodeURIComponent(total_price) +
      "&insurance_charge=" +
      encodeURIComponent(insurance_charge) +
      "&clf_value=" +
      encodeURIComponent(clf_value) +
      "&owner_total=" +
      encodeURIComponent(owner_total) +
      "&discount=" +
      encodeURIComponent(discount) +
      pickupParam +
      dropoffParam;
  return (
    <Fragment>
      <div className="row">
        <div className="col-12 col-lg-6 mb-2 mb-lg-0">
          <div
            className="card"
            style={{
              borderStyle: "solid",
              borderWidth: 1,
              height: "100%",
              marginBottom: 0,
            }}
          >
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="fechas">Rango de fechas del arriendo</label>
                <table
                  id="fechas"
                  style={{ width: "100%", fontWeight: "bolder" }}
                >
                  <tbody>
                    <tr>
                      <td style={{ color: "#203453" }}>INICIO</td>
                      <td>:</td>
                      <td align="right">
                        {dayjs(from)
                          .locale("es")
                          .format("LLL")}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ color: "#203453" }}>DEVOLUCIÓN</td>
                      <td>:</td>
                      <td align="right">
                        {dayjs(to)
                          .locale("es")
                          .format("LLL")}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="row mt-2">
                  <div className="col-lg-12">
                    <div className="form-group mb-1">
                      <label>Punto de recogida</label>
                      <input
                        type="text"
                        readOnly
                        value={pickup || ""}
                        onChange={() => false}
                        className="form-control disabled"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-0">
                      <label>Punto de devolución</label>
                      <input
                        type="text"
                        readOnly
                        value={dropoff || ""}
                        onChange={() => false}
                        className="form-control appcar-input"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div
            className="card"
            style={{
              borderStyle: "solid",
              borderWidth: 1,
              height: "100%",
              marginBottom: 0,
            }}
          >
            <div className="card-body">
              <h5>Garantía</h5>
              <p className="mb-3">
                Se autorizará una garantía de tu tarjeta de crédito de{" "}
                <b>$&nbsp;400.000</b>
              </p>
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <th className="py-1" align="left">
                      Política de cancelación
                    </th>
                    <td
                      align="right"
                      style={{
                        borderTop: "1px solid #484848",
                        borderBottom: "1px solid #484848",
                      }}
                    >
                      {cancellation_policy}
                      <br />
                      <a
                        href="/legal/rental_policy#cancellation_policies"
                        target="_blank"
                        style={{ fontSize: 12 }}
                      >
                        Más detalles
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th className="py-1" align="left">
                      Política de combustible
                    </th>
                    <td
                      align="right"
                      style={{
                        borderTop: "1px solid #484848",
                        borderBottom: "1px solid #484848",
                      }}
                    >
                      {fuel_policy}
                      <br />
                      <a
                        href="/legal/rental_policy#fuel_policies"
                        target="_blank"
                        style={{ fontSize: 12 }}
                      >
                        Más detalles
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th className="py-1" align="left">
                      Extras
                    </th>
                    <td
                      align="right"
                      style={{
                        borderTop: "1px solid #484848",
                        borderBottom: "1px solid #484848",
                      }}
                    >
                      {selected_extras.length > 0
                        ? selected_extras.reduce((prev, curr) => [
                            prev,
                            "; ",
                            curr,
                          ])
                        : "No hay extras seleccionados"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg">
          <p className="mt-2">Documentos asociados:</p>
          <ul>
            <li>
              <a
                href="/legal/terms_and_conditions"
                target="_blank"
                rel="noopener noreferrer"
                className="font-weight-bold"
              >
                Términos y condiciones.
              </a>
            </li>
            <li>
              <a
                href={url + "#privacy"}
                target="_blank"
                rel="noopener noreferrer"
                className="font-weight-bold"
              >
                Política de privacidad II.
              </a>
            </li>
          </ul>
        </div>
        <div className="d-none d-lg-block col-lg-auto">
          <div
            className="card"
            style={{
              borderStyle: "solid",
              borderWidth: 1,
              marginBottom: 0,
              marginTop: "1rem",
            }}
          >
            <div className="card-body p-2">
              <h4>TOTAL: {numeral(total_price).format("$ 0,0")}</h4>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export const leaseAgreementText = (
  booking_id,
  rental_id,
  user_id,
  from,
  to,
  total_price,
  pickup,
  dropoff,
  insurance_charge,
  clf_value,
  owner_total,
  discount
) => {
  const pickupParam = pickup ? "&pickup=" + encodeURIComponent(pickup) : "";
  const dropoffParam = dropoff ? "&dropoff=" + encodeURIComponent(dropoff) : "";
  const url = booking_id
    ? //Si tiene booking_id
      // "/legal/lease_agreement?booking_id=" + encodeURIComponent(booking_id)
      "/legal/booking_certificate?booking_id=" + encodeURIComponent(booking_id)
    : //Si no, es porque es una modificación de parte del owner
      // "/legal/lease_agreement?rental_id=" +
      "/legal/booking_certificate?rental_id=" +
      encodeURIComponent(rental_id) +
      "&user_id=" +
      encodeURIComponent(user_id) +
      "&from=" +
      encodeURIComponent(from) +
      "&to=" +
      encodeURIComponent(to) +
      "&total_price=" +
      encodeURIComponent(total_price) +
      "&insurance_charge=" +
      encodeURIComponent(insurance_charge) +
      "&clf_value=" +
      encodeURIComponent(clf_value) +
      "&owner_total=" +
      encodeURIComponent(owner_total) +
      "&discount=" +
      encodeURIComponent(discount) +
      pickupParam +
      dropoffParam;
  return (
    <Fragment>
      <p>Documentos asociados:</p>
      <ul>
        <li>
          <a
            href="/legal/terms_and_conditions"
            target="_blank"
            rel="noopener noreferrer"
            className="font-weight-bold"
          >
            Términos y condiciones.
          </a>
        </li>
        <li>
          <a
            href={url + "#privacy"}
            target="_blank"
            rel="noopener noreferrer"
            className="font-weight-bold"
          >
            Política de privacidad I.
          </a>
        </li>
      </ul>
    </Fragment>
  );
};

export const generateFilename = (originalFilename) => {
  //extension
  const extension = originalFilename.substring(
    originalFilename.lastIndexOf("."),
    originalFilename.length
  );

  //timestamp
  const timestamp = Date.now().toString();

  //Original filename
  const filename = originalFilename.substring(
    0,
    originalFilename.lastIndexOf(".")
  );

  //Return generated filename
  return filename + "-" + timestamp + extension;
};

export const validateRUT = (val) => {
  let value = val.replace(/[^0-9\K\k]/g, "");
  let without_dv = value.slice(0, -1);
  let dv = value.slice(-1).toUpperCase();

  // Si no cumple con el mínimo ej. (n.nnn.nnn)
  if (without_dv.length == 0) return false;
  else if (without_dv.length < 7) return false;

  let sum = 0;
  let mult = 2;

  // Para cada dígito del Cuerpo
  for (let i = 1; i <= without_dv.length; i++) {
    let index = mult * value.charAt(without_dv.length - i);
    sum = sum + index;
    if (mult < 7) {
      mult = mult + 1;
    } else {
      mult = 2;
    }
  }

  const dvEsperado = 11 - (sum % 11);

  // Casos Especiales (0 y K)
  dv = dv == "K" ? 10 : dv;
  dv = dv == 0 ? 11 : dv;

  if (dvEsperado != dv) return false;
  else return true;
};
