import React, { Component } from "react";
import iconNoImage from "images/Icons/NoImagePinkBG.svg";

class CardCarousel extends Component {
  state = {
    images: this.props.images,
    currentIndex: 0,
    translateValue: 0,
  };

  goToPrevSlide = () => {
    if (this.props.images.length == 0) return;
    if (this.state.currentIndex == 0) {
      return this.setState({
        currentIndex: this.props.images.length - 1,
        translateValue: -this.slideWidth() * (this.props.images.length - 1),
      });
    }

    this.setState((state) => ({
      currentIndex: state.currentIndex - 1,
      translateValue: state.translateValue + this.slideWidth(),
    }));
  };

  goToNextSlide = () => {
    if (this.props.images.length == 0) return;
    if (this.state.currentIndex == this.props.images.length - 1) {
      return this.setState({
        currentIndex: 0,
        translateValue: 0,
      });
    }

    this.setState((state) => ({
      currentIndex: state.currentIndex + 1,
      translateValue: state.translateValue + -this.slideWidth(),
    }));
  };

  slideWidth = () => {
    const doc = document.querySelector(".card-carousel-slide");
    return doc ? doc.clientWidth : 0;
  };

  render() {
    let images = Array.isArray(this.props.images) ? this.props.images : [];
    images = images.map((i) => `${i}/thumb`);
    // images = images.map(i => `${i}`);
    const hasImages = Array.isArray(images) && typeof images[0] == "string";
    if (!hasImages) {
      images.push(iconNoImage);
    }
    return (
      <div className="card-carousel">
        <div
          className="card-carousel-wrapper"
          style={{
            transform: `translate(${this.state.translateValue}px)`,
            transition: "transform ease-out 0.5s",
          }}
        >
          {images.map((image, i) => (
            <Slide key={i} image={image} noImage={!hasImages} />
          ))}
        </div>
        <LeftArrow goToPrevSlide={this.goToPrevSlide} />
        <RightArrow goToNextSlide={this.goToNextSlide} />
        <ImageCounter
          actual={
            this.props.images.length > 0 ? this.state.currentIndex + 1 : 0
          }
          total={this.props.images.length}
        />
      </div>
    );
  }
}

const Slide = ({ image, noImage = false }) => {
  const styles = {
    backgroundImage: `url("${image}")`,
    backgroundSize: noImage ? 80 : "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 50%",
    backgroundColor: noImage ? "#FFE5E1" : "#FFF",
  };
  return <div className="card-carousel-slide" style={styles} />;
};

const LeftArrow = (props) => {
  return (
    <div
      className="back-arrow card-carousel-arrow"
      onClick={props.goToPrevSlide}
    >
      <i className="arrow-left" />
    </div>
  );
};

const RightArrow = (props) => {
  return (
    <div
      className="next-arrow card-carousel-arrow"
      onClick={props.goToNextSlide}
    >
      <i className="arrow-right" />
    </div>
  );
};

const ImageCounter = ({ actual, total }) => {
  const styles = {
    backgroundColor: "#000",
  };
  return (
    <div className="card-carousel-counter">
      {actual} / {total}
    </div>
  );
};

export default CardCarousel;
