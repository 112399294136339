import React, { Component } from "react";
import DocumentLoader from "../../../common/components/image_loader/DocumentLoader";
import iconHorizontal from "images/Icons/horizontal_pic_advice.svg";
import iconPictures from "images/Icons/veh_pictures.svg";

export default class VehiclePictures extends Component {
  state = {
    loadingMore: false,
  };

  loadMore = () => {
    // const pictures = this.props.data.vehicle_pictures || [];
    // if (pictures.length > 0) {
    //   const lastItem = pictures[pictures.length - 1];
    //   if (lastItem.url || lastItem.filename) {
    //     this.setState({ loadingMore: true });
    //   }
    // }
    this.setState({ loadingMore: true });
  };

  componentDidUpdate(prevProps) {
    // const newPictures = this.props.data.vehicle_pictures || [];
    // const oldPictures = prevProps.data.vehicle_pictures || [];
    // if (newPictures.length > 0) {
    //   // if (oldPictures.length == newPictures.length) {
    //   //   const lastPic = newPictures[newPictures.length - 1];
    //   //   const oldLastPic = old;
    //   //   if (!!lastPic.filename && this.state.loadingMore)
    //   //     this.setState({ loadingMore: false });
    //   // }
    // }
    if (this.props.justLoadedAdditionalPicture) {
      this.setState({ loadingMore: false }, () =>
        this.props.reportUpdatedLoadingMore()
      );
    }
  }

  render() {
    const {
      data,
      openFileDialog,
      handleSubmit,
      isSubmitting,
      removePicture,
    } = this.props;

    let morePics = null;
    const loadingMore = this.state.loadingMore;
    let picsContainer = [];
    const pictures = data.vehicle_pictures || [];
    if (pictures.length > 0) {
      morePics = (
        <p
          className={`text-center fs-12 fw-700 text-underline text-appcar-primary ${
            !loadingMore ? "cursor-pointer" : "cursor-not-allowed"
          }`}
          onClick={() => {
            if (!loadingMore) this.loadMore();
          }}
        >
          + Agregar foto adicional
        </p>
      );
      picsContainer = data.vehicle_pictures.map((picture, i) => {
        if (i == 0) {
          return (
            <DocumentLoader
              addImage={openFileDialog}
              replaceImage={openFileDialog}
              imageBase64={picture.image_base_64}
              start={picture.start}
              url={picture.url}
              title="Foto principal:"
              backgroundPlaceHolder="/CarPlaceholder.png"
              objectName="firstVehiclePicture"
              canRemovePic={false}
              subtitle="Te sugerimos un ángulo así"
              key={i}
            />
          );
        } else {
          return (
            <DocumentLoader
              addImage={openFileDialog}
              removeImage={(obj) => removePicture(i, obj)}
              imageBase64={picture.image_base_64}
              start={picture.start}
              url={picture.url}
              title="Foto adicional:"
              backgroundPlaceHolder="/AdditionalPlaceholder.png"
              objectName="vehicle_pictures"
              subtitle=""
              key={i}
            />
          );
        }
      });
      if (loadingMore) {
        picsContainer.push(
          <DocumentLoader
            addImage={openFileDialog}
            removeImage={removePicture}
            imageBase64=""
            start=""
            url=""
            title="Foto adicional:"
            backgroundPlaceHolder="/AdditionalPlaceholder.png"
            objectName="vehicle_pictures"
            subtitle=""
            key={"new"}
          />
        );
      }
    } else {
      picsContainer.push(
        <DocumentLoader
          addImage={openFileDialog}
          replaceImage={openFileDialog}
          imageBase64=""
          start=""
          url=""
          title="Foto principal:"
          backgroundPlaceHolder="/CarPlaceholder.png"
          objectName="firstVehiclePicture"
          canRemovePic={false}
          subtitle="Te sugerimos un ángulo así"
          key={0}
        />
      );
    }
    return (
      <>
        <div className="my-3">
          {picsContainer}
          {morePics}
        </div>
        <div className="promotional-info mt-3">
          <div className="row align-items-center">
            <div className="col-auto pr-0">
              <img src={iconHorizontal} alt="horizontal" />
            </div>
            <d className="col">
              <p className="pricing-title fw-800">
                Asegúrate de sacar las fotos en formato horizontal.
              </p>
            </d>
          </div>
          <div className="row align-items-center mt-2">
            <d className="col pr-0">
              <p className="pricing-title fw-800">
                Al agregar varias fotos mejoras la publicación de tu auto.
              </p>
            </d>
            <div className="col-auto">
              <img src={iconPictures} alt="fotos" />
            </div>
          </div>
        </div>
        <div className="text-center my-4">
          <button
            type="button"
            className="btn btn-appcar-primary pt-2 pb-2"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Continuar
          </button>
        </div>
      </>
    );
  }
}
