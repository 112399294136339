import React, { Component, Fragment } from "react";
import moment from "moment";
import DateTimePicker from "../../../../common/search_components/DateTimePicker";
import PricingDetails from "../../../../common/PricingDetails";
import Select from "react-select";
import { constants } from "../../../../common/constants";
moment.locale("es");

class ConfirmEdit extends Component {
  state = {
    edited: false
  };

  componentDidMount() {

    const this_ = this;
    const options = {
      showDropdowns: true,
      maxYear: constants.MAX_YEAR,
      singleDatePicker: true,
      minDate: new Date(),
      locale: {
        format: "DD/MM/YYYY",
        cancelLabel: "Cancelar",
        applyLabel: "Aplicar",
        fromLabel: "Desde",
        toLabel: "Hasta",
        daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre"
        ],
        firstDay: 1
      }
    };

    $("#confirm_from").daterangepicker(options, (start) => {
      const from_ = moment(start);
      const until_ = moment(this_.props.newBookingData.untilDate, "DD/MM/YYYY");
      let fromDate = from_.format("DD/MM/YYYY");
      let untilDate = until_.format("DD/MM/YYYY");
      if (until_.valueOf() < from_.valueOf()) {
        untilDate = from_.clone().format("DD/MM/YYYY");
        $("#confirm_until").data("daterangepicker").setStartDate(untilDate);
        $("#confirm_until").data("daterangepicker").setEndDate(untilDate);
      }
      this.setState({ edited: true });
      this.props.handleChangeConfirmDateFrom(fromDate);
      this.props.handleChangeConfirmDateUntil(untilDate);
    });

    $("#confirm_until").daterangepicker(options, (start) => {
      const until_ = moment(start);
      const from_ = moment(this_.props.newBookingData.fromDate, "DD/MM/YYYY");
      let fromDate = from_.format("DD/MM/YYYY");
      let untilDate = until_.format("DD/MM/YYYY");
      if (from_.valueOf() > until_.valueOf()) {
        fromDate = until_.format("DD/MM/YYYY");
        $("#confirm_from").data("daterangepicker").setStartDate(fromDate);
        $("#confirm_from").data("daterangepicker").setEndDate(fromDate);
      }
      this.setState({ edited: true });
      this.props.handleChangeConfirmDateFrom(fromDate);
      this.props.handleChangeConfirmDateUntil(untilDate);
    });

    // $("#confirm_from").data("daterangepicker").setStartDate(moment(this_.props.newBookingData.fromDate, "DD/MM/YYYY"));
    // $("#confirm_from").data("daterangepicker").setEndDate(moment(this_.props.newBookingData.fromDate, "DD/MM/YYYY"));
    // $("#confirm_until").data("daterangepicker").setStartDate(moment(this_.props.newBookingData.untilDate, "DD/MM/YYYY"));
    // $("#confirm_until").data("daterangepicker").setEndDate(moment(this_.props.newBookingData.untilDate, "DD/MM/YYYY"));
  }

  render() {
    const {
      booking,
      selectedRental,
      newBookingData,
      handleChangeConfirmTimeFrom,
      handleChangeConfirmTimeUntil,
      handleChangeConfirmExtras,
      handleChangePickupLocation,
      handleChangeDropoffLocation,
      pricing,
      handleCalculatePrice,
      handleLegalPopUp
    } = this.props;
    //Original data
    const originalVehicleType = booking.vehicle.vehicle_type;
    const originalVehicleName = !!originalVehicleType ? originalVehicleType.brand + " " + originalVehicleType.model + " " + booking.vehicle.year + " | " + booking.vehicle.plate_number : booking.vehicle.plate_number;
    const originalDateFrom = moment(booking.date_from).format("DD/MM/YYYY HH:mm");
    const originalDateUntil = moment(booking.date_until).format("DD/MM/YYYY HH:mm");
    const originalPickUp = booking.pricing.selected_pickup_location.location.name;
    const originalDropOff = booking.pricing.selected_dropoff_location.location.name;
    const originalExtras = booking.pricing.extra_charges.map(extra => extra.vehicle_extra.a_name).join(", ");
    const originalPriceDetails = (
      <PricingDetails
        price={booking.price}
        dateFrom={booking.date_from}
        dateUntil={booking.date_until}
        id={booking.id}
      />
    );

    let newVehicleName = null;
    let newDateFrom = null;
    let newTimeFrom = null;
    let newDateUntil = null;
    let newTimeUntil = null;
    let selectedPickUp = null;
    let selectedDropOff = null;
    let newExtras = null;
    let alertNoVehicle = null;
    let locationOptions = [];
    let extrasOptions = [];
    let newPriceDetails = null;
    let btnUpdatePrice = null;
    let btnLegalPopUp = null;
    if (selectedRental) {
      // New data
      const newVehicleType = selectedRental.vehicle.vehicle_type;
      //Datos de vehículo
      newVehicleName = !!newVehicleType ? newVehicleType.brand + " " + newVehicleType.model + " " + selectedRental.vehicle.year + " | " + selectedRental.vehicle.plate_number : selectedRental.vehicle.plate_number;

      //Fechas del nuevo booking
      newDateFrom = newBookingData.fromDate;
      newTimeFrom = newBookingData.fromTime;
      newDateUntil = newBookingData.untilDate;
      newTimeUntil = newBookingData.untilTime;

      //Pick up y drop off
      const newPickUp = newBookingData.selected_pickup;
      const newDropOff = newBookingData.selected_dropoff;
      locationOptions = (selectedRental.pricing.extra_locations || []).map(x => ({ value: x.location.name, label: x.location.name }));
      locationOptions.unshift({ value: selectedRental.location.name, label: selectedRental.location.name });

      selectedPickUp = newPickUp ? locationOptions[locationOptions.findIndex(x => x.value === newPickUp.value)] : undefined;
      selectedDropOff = newDropOff ? locationOptions[locationOptions.findIndex(x => x.value === newDropOff.value)] : undefined;

      //Extras
      extrasOptions = (selectedRental.pricing.extra_charges || []).map(x => ({ value: x.vehicle_extra.id, label: x.vehicle_extra.a_name }));
      newExtras = selectedRental.pricing.extra_charges
        .filter(x => newBookingData.extras_ids.includes(x.vehicle_extra.id))
        .map(x => ({ value: x.vehicle_extra.id, label: x.vehicle_extra.a_name }));
      
      //Precio
      newPriceDetails = (
        <PricingDetails
          price={pricing}
          dateFrom={newBookingData.from_formatted}
          dateUntil={newBookingData.until_formatted}
        />
      )

      btnUpdatePrice = (
        <button
          type="button"
          disabled={!this.state.edited}
          className="btn btn-appcar-primary mr-2 mb-2"
          onClick={() => {
            this.setState({ edited: false }, handleCalculatePrice())
          }}
        >
          Actualizar precio
        </button>
      );

      btnLegalPopUp = (
        <button
          type="button"
          className="btn btn-appcar-light mb-2"
          onClick={() => handleLegalPopUp()}
          disabled={this.state.edited}
        >
          Confirmar nueva reserva
        </button>
      );

    } else {
      alertNoVehicle = (
        <div className="my-3">
          <div className="alert alert-warning">No has seleccionado un vehículo aún</div>
        </div>
      )
    }

    return (
      <Fragment>
        <div className="my-0 step-names">
          <div className="step-title">
            <span>Selección de auto</span>
          </div>
          <div className="step-title">
            <span>Confirmar datos</span>
          </div>
        </div>
        <div className="my-3">
          <h3 className="py-3">Confirmar datos</h3>
          <button type="button" className="btn btn-appcar-primary" onClick={() => this.props.jumpToStep(0)}>Volver a la lista</button>
          <hr />
          {alertNoVehicle}
          <div className="row">
            <div className="col-md-6 d-none d-md-block"><h4>Reserva original</h4></div>
            <div className="col-md-6 d-none d-md-block"><h4>Reserva propuesta</h4></div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Vehículo original</label>
                <p>{originalVehicleName}</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Vehículo propuesto</label>
                <p>{newVehicleName}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Fecha desde</label>
                <p>{originalDateFrom}</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Cambiar fecha de entrega</label>
                <DateTimePicker
                  changeTime={option => {
                    handleChangeConfirmTimeFrom(option);
                    this.setState({ edited: true });
                  }}
                  date={newDateFrom}
                  pickerId="confirm_from"
                  time={newTimeFrom}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Fecha hasta</label>
                <p>{originalDateUntil}</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Cambiar fecha de devolución</label>
                <DateTimePicker
                  changeTime={option => {
                    handleChangeConfirmTimeUntil(option);
                    this.setState({ edited: true });
                  }}
                  date={newDateUntil}
                  pickerId="confirm_until"
                  time={newTimeUntil}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Ubicación de entrega</label>
                <p>{originalPickUp}</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Cambiar punto de entrega</label>
                <Select
                  value={selectedPickUp}
                  options={locationOptions}
                  placeholder="Selecciona..."
                  noOptionsMessage={() => "Error..."}
                  onChange={option => {
                    handleChangePickupLocation(option);
                    this.setState({ edited: true });
                  }}
                  classNamePrefix="RS-FIX"
                  className="react-select-fix"
                  id="new_pickup"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Ubicación de devolución</label>
                <p>{originalDropOff}</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Cambiar punto de devolución</label>
                <Select
                  value={selectedDropOff}
                  options={locationOptions}
                  placeholder="Selecciona..."
                  noOptionsMessage={() => "Error..."}
                  onChange={option => {
                    handleChangeDropoffLocation(option);
                    this.setState({ edited: true });
                  }}
                  classNamePrefix="RS-FIX"
                  className="react-select-fix"
                  id="new_dropoff"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Extras</label>
                <p>{originalExtras || "La reserva original no tenía extras incluídos"}</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Cambiar extras</label>
                <Select
                  value={newExtras}
                  options={extrasOptions}
                  clearable={true}
                  isMulti={true}
                  placeholder="Selecciona..."
                  noOptionsMessage={() => "No existen extras"}
                  onChange={options => {
                    handleChangeConfirmExtras(options);
                    this.setState({ edited: true });
                  }}
                  closeMenuOnSelect={false}
                  classNamePrefix="RS-FIX"
                  className="react-select-fix"
                  id="new_extras"
                />
              </div>
            </div>
          </div>
          <div className="row vehicle-scheduler">
            <div className="col-md-6">
              <h4>Precio detallado</h4>
              {originalPriceDetails}
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-12">
                  {btnUpdatePrice}
                  {btnLegalPopUp}
                </div>
              </div>
              {newPriceDetails}
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default ConfirmEdit;