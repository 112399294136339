export const AxiosBackofficeInterceptor = function(error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (401 === error.response.status) {
    Turbolinks.visit("/backoffice/bo_user/sign_in?expired=1");
  }
  return Promise.reject(error);
};

export const AxiosApplicationInterceptor = function(error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (401 === error.response.status) {
    Turbolinks.visit("/owners/sign_in?expired=1");
  }
  return Promise.reject(error);
};
