import React, { Component } from "react";
import Axios from "axios";
import { AxiosApplicationInterceptor } from "../../../common/axios_interceptor";
import moment from "moment";
import BookingModal from "../../../common/legal_modals/BookingModal";
import PoliciesSummary from "./PoliciesSummary";
import { constants, numeral, validateEmail } from "../../../common/constants";
import RentalSchedule from "../schedule/RentalSchedule";
import Spinner from "../../../common/Spinner";
import Alert from "../../../common/components/alert/Alert";
import GuaranteeCard from "../../../common/components/GuaranteeCard";
import PasswordRecovery from "../../users/password_recovery/PasswordRecovery";
import ModalDates from "./modals/ModalDates";
import ModalMercadoPago from "./modals/ModalMercadoPago";
import LoginModal from "./modals/LoginModal";
import PasswordModal from "./modals/PasswordModal";
import UserFormModal from "./modals/UserFormModal";
import PaymentMethodModal from "./modals/PaymentMethodModal";
import AfterBookingModal from "./modals/AfterBookingModal";
import ModalPricing from "./modals/ModalPricing";
import ExtrasContainer from "./containers/ExtrasContainer";
import SchedulerContainer from "./containers/SchedulerContainer";
import LocationContainer from "./containers/LocationContainer";
import NeedMoreDataModal from "./modals/NeedMoreDataModal";
import ModalLocation from "./modals/ModalLocation";
import FailedPaymentModal from "../../bookings/FailedPaymentModal";
import SantanderContainer from "./containers/SantanderContainer";

Axios.interceptors.response.use(undefined, AxiosApplicationInterceptor);

export default class RentalScheduler extends Component {
  state = {
    fromDate: moment(constants.RENTAL_SEARCH_FROM).format("DD/MM/YYYY"),
    fromTime: moment(constants.RENTAL_SEARCH_FROM).format("HH:mm"),
    untilDate: moment(constants.RENTAL_SEARCH_UNTIL).format("DD/MM/YYYY"),
    untilTime: moment(constants.RENTAL_SEARCH_UNTIL).format("HH:mm"),
    error: false,
    success: false,
    hasQuoted: false,
    newFrom: moment(constants.RENTAL_SEARCH_FROM).format(""),
    newTo: moment(constants.RENTAL_SEARCH_UNTIL).format(""),
    selectedPickup: {
      label:
        (this.props.rental.location && this.props.rental.location.name) || "",
      value:
        (this.props.rental.location && this.props.rental.location.name) || "",
    },
    selectedDropoff: {
      label:
        (this.props.rental.location && this.props.rental.location.name) || "",
      value:
        (this.props.rental.location && this.props.rental.location.name) || "",
    },
    isSubmitting: false,
    extrasIds: [],
    user: this.props.user,
    firstLoad: true,
    notLoggedUser: {
      email: "",
      password: "",
      name: "",
      last_name: "",
      phone_number: "",
      document_type: null,
      id_number: "",
      billing_info: {
        rut: "",
        name: "",
        address: "",
        city: "",
        municipality: "",
        phone: "",
        business: "",
        email: "",
      },
    },
    needInvoice: false,
    termsAndConditionsAgreement: false,
    loadMP: false,
    pricing: {
      total: 0,
      details: [],
      extra_charges: [],
      commission: 0,
      insurance_charge: 0,
      payment_method_fee: 0,
      value_added_tax: 0,
      pick_up_charge: null,
      drop_off_charge: null,
      clf_value: 0.0,
      owner_total: 0.0,
      discount: 0.0,
    },
    alert: {
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined,
    },
    mapInfo: {
      lat: 0,
      lng: 0,
      locationAddress: "",
    },
  };

  modalAgreement = null;
  modalMP = null;
  dataLayer = dataLayer;
  modalLogin = null;
  modalPassword = null;
  modalUserForm = null;
  modalPasswordRecovery = null;
  modalChangePassword = null;
  modalPaymentMethod = null;
  modalAfterBooking = null;
  modalNeedMoreData = null;
  modalDates = null;
  modalPricingDetails = null;
  modalLocationMap = null;
  modalFailedPayment = null;

  componentDidMount() {
    moment.locale("es");

    let from;
    let until;
    let fromDate = "";
    let fromTime = "";
    let untilDate = "";
    let untilTime = "";
    let selectedDropoff = {};
    let selectedPickup = {};
    const propsDateFrom = !!this.props.dateFrom
      ? moment(this.props.dateFrom)
      : moment.invalid();
    const propsDateUntil = !!this.props.dateUntil
      ? moment(this.props.dateUntil)
      : moment.invalid();
    const storedDateFrom = moment(localStorage.getItem("user_date_from"));
    const storedDateUntil = moment(localStorage.getItem("user_date_until"));
    const defaultDateFrom = moment(constants.RENTAL_SEARCH_FROM);
    const defaultDateUntil = moment(constants.RENTAL_SEARCH_UNTIL);
    const now = moment().valueOf();

    if (propsDateFrom.isValid() && propsDateFrom.valueOf() > now) {
      from = propsDateFrom;
    } else if (
      storedDateFrom.isValid() &&
      storedDateFrom.valueOf() > defaultDateFrom.valueOf()
    ) {
      from = storedDateFrom;
    } else {
      from = defaultDateFrom;
    }

    if (propsDateUntil.isValid() && propsDateUntil.valueOf() > from.valueOf()) {
      until = propsDateUntil;
    } else if (
      storedDateUntil.isValid() &&
      storedDateUntil.valueOf() > defaultDateUntil.valueOf() &&
      storedDateUntil.valueOf() > from.valueOf()
    ) {
      until = storedDateUntil;
    } else {
      until = defaultDateUntil;
    }

    fromDate = from.format("DD/MM/YYYY");
    fromTime = from.format("HH:mm");
    untilDate = until.format("DD/MM/YYYY");
    untilTime = until.format("HH:mm");
    this.setCopyLinkData({ from: from.format(), to: until.format() });

    const rental = this.props.rental;
    const location = rental.location?.name;

    const address = this.props.address;
    const extraLocations = rental.pricing.extra_locations || [];
    const validExtraLocation = extraLocations.find(
      (l) => l.location?.name == address
    );
    if (validExtraLocation) {
      selectedDropoff = { label: address, value: address };
      selectedPickup = { label: address, value: address };
      this.setCopyLinkData({ address });
    } else if (location) {
      selectedDropoff = { label: location, value: location };
      selectedPickup = { label: location, value: location };
      this.setCopyLinkData({ address: location });
    }

    // Restore selected extras by rental id
    const storageExtras = localStorage.getItem("extras_ids");
    const storageRental = localStorage.getItem("current_rental");

    let extrasIds = [];
    // If current rental.id is the same as the saved id, then restore extras, else remove all extras, and set new id
    if (storageExtras && parseInt(storageRental) === this.props.rental.id) {
      extrasIds = storageExtras.split(",");
    } else {
      localStorage.removeItem("extras_ids");
      localStorage.setItem("current_rental", this.props.rental.id);
    }

    this.setState(
      {
        fromDate,
        fromTime,
        untilDate,
        untilTime,
        selectedDropoff,
        selectedPickup,
        extrasIds,
        newFrom: from.format(),
        newTo: until.format(),
      },
      () => {
        localStorage.setItem("user_date_from", from.format());
        localStorage.setItem("user_date_until", until.format());
        this.calculatePrice();
        this.initializeCalendars();
        this.modalAgreement = new bootstrap.Modal(
          document.getElementById("legalModal")
        );

        // Instantiate all modals
        this.modalMP = new bootstrap.Modal(document.getElementById("MPModal"));
        this.modalLogin = new bootstrap.Modal(
          document.getElementById("LoginModal")
        );
        this.modalPassword = new bootstrap.Modal(
          document.getElementById("PasswordModal")
        );
        this.modalUserForm = new bootstrap.Modal(
          document.getElementById("UserFormModal")
        );
        this.modalPasswordRecovery = new bootstrap.Modal(
          document.getElementById("passwordRecoveryModal")
        );
        this.modalDates = new bootstrap.Modal(
          document.getElementById("datesModal")
        );
        this.modalPaymentMethod = new bootstrap.Modal(
          document.getElementById("PaymentMethodModal")
        );
        this.modalAfterBooking = new bootstrap.Modal(
          document.getElementById("AfterBookingModal")
        );
        this.modalNeedMoreData = new bootstrap.Modal(
          document.getElementById("NeedMoreDataModal")
        );
        this.modalPricingDetails = new bootstrap.Modal(
          document.getElementById("pricingDetailsModal")
        );
        this.modalLocationMap = new bootstrap.Modal(
          document.getElementById("modalLocation")
        );
        this.modalFailedPayment = new bootstrap.Modal(
          document.getElementById("failedPaymentModal")
        );

        if (this.props.continueBooking) {
          // Check if logged or session expired
          if (this.props.user && this.props.user.id) {
            // Check important data
            if (this.checkImportantData()) {
              if (this.checkPaymentData()) {
                // Continue to show modal of confirmation
                this.addDataLayerInfo({ type: "addToCart" });
                this.modalAgreement.show();
              } else {
                // Show modal with mercadopago's form
                this.showMercadoPagoModal();
              }
            } else {
              // Show modal with form of personal data
              this.modalUserForm.show();
            }
          } else {
            this.modalLogin.show();
            document.getElementById("notlogged__email").focus();
          }
        }
      }
    );
  }

  setCopyLinkData = ({ from = "", to = "", address = "" }) => {
    if (!!from)
      sessionStorage.setItem("copy_from_" + this.props.rental.id, from);
    if (!!to) sessionStorage.setItem("copy_to_" + this.props.rental.id, to);
    if (!!address)
      sessionStorage.setItem("copy_address_" + this.props.rental.id, address);
  };

  checkImportantData = () => {
    // Check important data: name, last_name, phone_number, id_number (email already exists)
    const user = this.state.user;
    if (!user) return false;
    if (user.name && user.last_name && user.phone_number && user.id_number) {
      return true;
    } else return false;
  };

  checkContinuationData = () => {
    if (this.checkImportantData()) {
      const user = this.state.user;
      const checkDriverLicense =
        Array.isArray(user.driver_license) && user.driver_license.length > 0;
      const checkDocumentPictures =
        Array.isArray(user.document_pictures) &&
        user.document_pictures.length > 1 &&
        user.document_pictures.every((pic) => pic?.url);
      if (checkDriverLicense && checkDocumentPictures) {
        this.modalAfterBooking.show();
      } else {
        localStorage.setItem("need_to_complete_data", "1");
        this.modalNeedMoreData.show();
      }
    }
  };

  checkPaymentData = () => {
    // Check MercadoPago
    const user = this.props.user;
    if (!user) return false;
    // Check if cards is array, have at least 1 card and the first card has "last_four_digits"
    if (
      Array.isArray(user.mercadopago?.cards) &&
      user.mercadopago.cards.length > 0 &&
      !!user.mercadopago.cards[0].last_four_digits
    ) {
      return true;
    } else return false;
  };

  initializeCalendars = () => {
    const this_ = this;
    const options = {
      showDropdowns: true,
      maxYear: constants.MAX_YEAR,
      singleDatePicker: true,
      minDate: moment(),
      maxDate: moment(this_.props.rental.date_until),
      locale: {
        format: "DD/MM/YYYY",
        cancelLabel: "Cancelar",
        applyLabel: "Aplicar",
        fromLabel: "Desde",
        toLabel: "Hasta",
        daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        firstDay: 1,
      },
    };

    const dateFrom = $("#date_from").daterangepicker(options, (start) => {
      let { fromDate, fromTime, untilDate, untilTime } = this_.state;
      const fromHours = parseInt(fromTime.split(":")[0]);
      const fromMinutes = parseInt(fromTime.split(":")[1]);
      const untilHours = parseInt(untilTime.split(":")[0]);
      const untilMinutes = parseInt(untilTime.split(":")[1]);
      const from = moment(start)
        .hours(fromHours)
        .minutes(fromMinutes);
      let until = moment(untilDate, "DD/MM/YYYY")
        .hours(untilHours)
        .minutes(untilMinutes);

      if (until.valueOf() < from.valueOf()) {
        until = from.clone();
        $("#date_until")
          .data("daterangepicker")
          .setStartDate(until.format("DD/MM/YYYY"));
        $("#date_until")
          .data("daterangepicker")
          .setEndDate(until.format("DD/MM/YYYY"));
        untilDate = until.format("DD/MM/YYYY");
        untilTime = until.format("HH:mm");
      }
      fromDate = from.format("DD/MM/YYYY");
      const fromString = moment(
        fromDate + fromTime,
        "DD/MM/YYYYHH:mm"
      ).format();
      const untilString = moment(
        untilDate + untilTime,
        "DD/MM/YYYYHH:mm"
      ).format();
      localStorage.setItem("user_date_from", fromString);
      localStorage.setItem("user_date_until", untilString);
      let alert = this.state.alert;
      alert.showMessage = false;
      this_.setState(
        {
          fromDate,
          untilDate,
          untilTime,
          alert,
          hasQuoted: false,
          error: false,
          success: false,
        },
        () => {
          this.calculatePrice();
          this.setCopyLinkData({ from: fromString, to: untilString });
        }
      );
    });

    const dateUntil = $("#date_until").daterangepicker(options, (start) => {
      let { fromDate, fromTime, untilDate, untilTime } = this_.state;
      const fromHours = parseInt(fromTime.split(":")[0]);
      const fromMinutes = parseInt(fromTime.split(":")[1]);
      const untilHours = parseInt(untilTime.split(":")[0]);
      const untilMinutes = parseInt(untilTime.split(":")[1]);
      let from = moment(fromDate, "DD/MM/YYYY")
        .hours(fromHours)
        .minutes(fromMinutes);
      const until = moment(start)
        .hours(untilHours)
        .minutes(untilMinutes);

      if (until.valueOf() < from.valueOf()) {
        from = until.clone();
        $("#date_from")
          .data("daterangepicker")
          .setStartDate(from.format("DD/MM/YYYY"));
        $("#date_from")
          .data("daterangepicker")
          .setEndDate(from.format("DD/MM/YYYY"));
        fromDate = from.format("DD/MM/YYYY");
        fromTime = from.format("HH:mm");
      }
      untilDate = until.format("DD/MM/YYYY");
      const fromString = moment(
        fromDate + fromTime,
        "DD/MM/YYYYHH:mm"
      ).format();
      const untilString = moment(
        untilDate + untilTime,
        "DD/MM/YYYYHH:mm"
      ).format();
      localStorage.setItem("user_date_from", fromString);
      localStorage.setItem("user_date_until", untilString);
      let alert = this.state.alert;
      alert.showMessage = false;
      this_.setState(
        {
          fromDate,
          fromTime,
          untilDate,
          alert,
          hasQuoted: false,
          error: false,
          success: false,
        },
        () => {
          this.calculatePrice();
          this.setCopyLinkData({ from: fromString, to: untilString });
        }
      );
    });

    const blockedRanges = this.props.blockedRanges || [];
    dateFrom.isInvalidDate = (date) => {
      let disable = false;
      for (let event of blockedRanges) {
        disable = false;
        // Se considera que el día en el que inicia y finaliza el evento se libera por completo
        const eventMinValue = moment(event.from)
          .endOf("d")
          .valueOf();
        const eventMaxValue = moment(event.until)
          .startOf("d")
          .valueOf();

        const dateValue = moment(date).valueOf();
        if (dateValue > eventMinValue && dateValue < eventMaxValue) {
          disable = true;
          break;
        }
      }
      return disable;
    };
    dateUntil.isInvalidDate = (date) => {
      let disable = false;
      for (let event of blockedRanges) {
        disable = false;
        // Se considera que el día en el que inicia y finaliza el evento se libera por completo
        const eventMinValue = moment(event.from)
          .endOf("d")
          .valueOf();
        const eventMaxValue = moment(event.until)
          .startOf("d")
          .valueOf();

        const dateValue = moment(date).valueOf();
        if (dateValue > eventMinValue && dateValue < eventMaxValue) {
          disable = true;
          break;
        }
      }
      return disable;
    };
  };

  checkUser = () => {
    this.setState({ isSubmitting: true });
    Axios({
      method: "GET",
      url: `/owners/check_email.json?email=${this.state.notLoggedUser.email}`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.data.messages[0]) {
          // Ocultar modal email
          this.modalLogin.hide();
          // Si existe pedir contraseña, sino crearlo
          if (response.data.exists) {
            this.changeNotLoggedUser(response.data.name, "name");
            this.modalPassword.show();
            document.getElementById("notlogged__password").focus();
          } else {
            this.modalUserForm.show();
          }
        } else {
          this.showMessage("primary", "Error", [
            "Ha ocurrido un error inesperado",
          ]);
        }
      })
      .catch((error) => {
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ]);
      })
      .finally(() => this.setState({ isSubmitting: false }));
  };

  login = () => {
    this.setState({ isSubmitting: true });
    const user = this.state.notLoggedUser;
    const uninterceptedAxios = Axios.create();
    const { fromDate, fromTime, untilDate, untilTime } = this.state;
    let shouldHideSpinner = true;
    uninterceptedAxios({
      method: "POST",
      url: `/owners/sign_in.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        app_user: {
          email: user.email,
          password: user.password,
        },
        r_to: "rentals",
        obj_id: this.props.rental.id,
      },
    })
      .then((response) => {
        if (response.data.success) {
          // No se debe ocultar modal de contraseña y modal de formulario de usuario
          const newFrom = moment(
            fromDate + fromTime,
            "DD/MM/YYYYHH:mm"
          ).format();
          const newTo = moment(
            untilDate + untilTime,
            "DD/MM/YYYYHH:mm"
          ).format();
          shouldHideSpinner = false;
          localStorage.setItem("need_to_reload_MP", "1");
          Turbolinks.visit(
            `/rentals/${this.props.rental.id}?from=${newFrom}&to=${newTo}&continue_booking=1`
          );
        } else if (response.data.error) {
          this.showMessage("primary", "Error", [
            response.data.error || "Ha ocurrido un error al iniciar sesión",
          ]);
        }
      })
      .catch((error) => {
        const message =
          error?.request?.response?.error || "Ha ocurrido un error inesperado";
        this.showMessage("primary", "Error", [message]);
      })
      .finally(() => {
        if (shouldHideSpinner) this.setState({ isSubmitting: false });
      });
  };

  recoverPassword = () => {};

  changeFromTime = (fromTime) => {
    let { fromDate, untilDate, untilTime } = this.state;
    const from = moment(fromDate + fromTime, "DD/MM/YYYYHH:mm");
    const until = moment(untilDate + untilTime, "DD/MM/YYYYHH:mm");
    if (until.valueOf() < from.valueOf()) {
      until = from.clone();
      $("#date_until")
        .data("daterangepicker")
        .setStartDate(until.format("DD/MM/YYYY"));
      $("#date_until")
        .data("daterangepicker")
        .setEndDate(until.format("DD/MM/YYYY"));
      untilDate = until.format("DD/MM/YYYY");
      untilTime = until.format("HH:mm");
    }
    const fromString = moment(fromDate + fromTime, "DD/MM/YYYYHH:mm").format();
    const untilString = moment(
      untilDate + untilTime,
      "DD/MM/YYYYHH:mm"
    ).format();
    localStorage.setItem("user_date_from", fromString);
    localStorage.setItem("user_date_until", untilString);
    let alert = this.state.alert;
    alert.showMessage = false;
    this.setState(
      {
        fromTime,
        untilDate,
        untilTime,
        alert,
        hasQuoted: false,
        error: false,
        success: false,
      },
      () => {
        this.calculatePrice();
        this.setCopyLinkData({ from: fromString, to: untilString });
      }
    );
  };

  changeUntilTime = (untilTime) => {
    let { fromDate, fromTime, untilDate } = this.state;
    const from = moment(fromDate + fromTime, "DD/MM/YYYYHH:mm");
    const until = moment(untilDate + untilTime, "DD/MM/YYYYHH:mm");
    if (until.valueOf() < from.valueOf()) {
      from = until.clone();
      $("#date_from")
        .data("daterangepicker")
        .setStartDate(until.format("DD/MM/YYYY"));
      $("#date_from")
        .data("daterangepicker")
        .setEndDate(until.format("DD/MM/YYYY"));
      fromDate = from.format("DD/MM/YYYY");
      fromTime = from.format("HH:mm");
    }
    const fromString = moment(fromDate + fromTime, "DD/MM/YYYYHH:mm").format();
    const untilString = moment(
      untilDate + untilTime,
      "DD/MM/YYYYHH:mm"
    ).format();
    localStorage.setItem("user_date_from", fromString);
    localStorage.setItem("user_date_until", untilString);
    let alert = this.state.alert;
    alert.showMessage = false;
    this.setState(
      {
        fromDate,
        fromTime,
        untilTime,
        alert,
        hasQuoted: false,
        error: false,
        success: false,
      },
      () => {
        this.calculatePrice();
        this.setCopyLinkData({ from: fromString, to: untilString });
      }
    );
  };

  componentDidUpdate() {
    const dateFrom = $("#date_from").data("daterangepicker");
    const dateUntil = $("#date_until").data("daterangepicker");
    const blockedRanges = this.props.blockedRanges || [];
    if (dateFrom && dateUntil) {
      dateFrom.setStartDate(
        moment(this.state.fromDate, "DD/MM/YYYY").format("DD/MM/YYYY")
      );
      dateFrom.setEndDate(
        moment(
          this.state.fromDate + this.state.fromTime,
          "DD/MM/YYYYHH:mm"
        ).format("DD/MM/YYYY")
      );
      dateUntil.setStartDate(
        moment(
          this.state.untilDate + this.state.untilTime,
          "DD/MM/YYYYHH:mm"
        ).format("DD/MM/YYYY")
      );
      dateUntil.setEndDate(
        moment(
          this.state.untilDate + this.state.untilTime,
          "DD/MM/YYYYHH:mm"
        ).format("DD/MM/YYYY")
      );
      dateFrom.isInvalidDate = (date) => {
        let disable = false;
        for (let event of blockedRanges) {
          disable = false;
          // Se considera que el día en el que inicia y finaliza el evento se libera por completo
          const eventMinValue = moment(event.from)
            .endOf("d")
            .valueOf();
          const eventMaxValue = moment(event.until)
            .startOf("d")
            .valueOf();

          const dateValue = moment(date).valueOf();
          if (dateValue > eventMinValue && dateValue < eventMaxValue) {
            disable = true;
            break;
          }
        }
        return disable;
      };
      dateUntil.isInvalidDate = (date) => {
        let disable = false;
        for (let event of blockedRanges) {
          disable = false;
          // Se considera que el día en el que inicia y finaliza el evento se libera por completo
          const eventMinValue = moment(event.from)
            .endOf("d")
            .valueOf();
          const eventMaxValue = moment(event.until)
            .startOf("d")
            .valueOf();

          const dateValue = moment(date).valueOf();
          if (dateValue > eventMinValue && dateValue < eventMaxValue) {
            disable = true;
            break;
          }
        }
        return disable;
      };
    }
  }

  showMessage = (messageType, messageTitle, messages) =>
    this.setState(
      (state) => {
        state.alert.showMessage = true;
        state.alert.messageTitle = messageTitle;
        state.alert.messages = messages;
        state.alert.messageType = messageType;
        return { alert: state.alert };
      },
      () => setTimeout(this.handleAlertClose, 3000)
    );

  handleAlertClose = () =>
    this.setState((state) => {
      state.alert.showMessage = false;
      return { alert: state.alert };
    });

  updateUser = () => {
    this.setState({ isSubmitting: true });
    const { user, needInvoice, notLoggedUser } = this.state;
    const updatingUser = !!user?.id;
    let url = "";
    let method = "";
    let params = {};
    if (updatingUser) {
      params.user = user;
      // If need invoice then check if user has billing info, else create base billing info
      params.user.billing_info ??= {
        rut: "",
        name: "",
        address: "",
        city: "",
        municipality: "",
        phone: "",
        business: "",
        email: "",
      };
      if (needInvoice) {
        params.user.billing_info.rut =
          params.user.billing_info.rut || user.id_number;
      }
      url = `/owners/${user.id}.json?need_reload=true`;
      method = "PUT";
    } else {
      params.owner = notLoggedUser;
      if (needInvoice) {
        params.owner.billing_info.rut =
          params.owner.billing_info.rut || user.id_number;
      }
      url = `/owners.json`;
      method = "POST";
    }
    let shouldHideSpinner = true;
    Axios({
      method,
      url,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    })
      .then((response) => {
        if (response.data.success) {
          if (updatingUser) {
            this.setState({ user: response.data.user });
            this.modalUserForm.hide();
            if (this.checkPaymentData()) {
              // Continue to show modal of confirmation
              this.addDataLayerInfo({ type: "addToCart" });
              this.modalAgreement.show();
            } else {
              // Show modal with mercadopago's form
              this.showMercadoPagoModal();
            }
          } else {
            shouldHideSpinner = false;
            this.login();
          }
        } else {
          this.showMessage(
            "primary",
            "Error",
            response.data.messages || ["Ha ocurrido un error inesperado"]
          );
        }
      })
      .catch((error) => {
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ]);
      })
      .finally(() => {
        if (shouldHideSpinner) this.setState({ isSubmitting: false });
      });
  };

  reloadUser = () => {
    this.setState({ isSubmitting: true });
    Axios({
      method: "GET",
      url: `/owners/current.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.data) {
          this.setState({ user: response.data });
        } else {
          this.showMessage("primary", "Error", [
            "Ha ocurrido un error inesperado",
          ]);
        }
      })
      .catch((error) => {
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ]);
      })
      .finally(() => this.setState({ isSubmitting: false }));
  };

  createParams = () => {
    this.setState((state) => {
      state.alert.showMessage = false;
      state.alert.messageType = undefined;
      state.alert.messages = [];
      state.alert.messageTitle = undefined;
      return { isSubmitting: true, alert: state.alert };
    });
    const {
      selectedPickup,
      selectedDropoff,
      extrasIds,
      fromDate,
      fromTime,
      untilDate,
      untilTime,
    } = this.state;
    const rental = this.props.rental;
    const newFrom = moment(fromDate + fromTime, "DD/MM/YYYYHH:mm").format();
    const newTo = moment(untilDate + untilTime, "DD/MM/YYYYHH:mm").format();

    let rental_pricing = {
      rental_id: rental.id,
      start: newFrom,
      end: newTo,
      extras_ids: extrasIds,
    };
    if (!!selectedPickup && selectedPickup.value != rental.location.name) {
      rental_pricing.special_pickup = selectedPickup.value;
    }
    if (!!selectedDropoff && selectedDropoff.value != rental.location.name) {
      rental_pricing.special_dropoff = selectedDropoff.value;
    }
    return rental_pricing;
  };

  calculatePrice = (e) => {
    const rental_pricing = this.createParams();
    const firstLoad = this.state.firstLoad;
    const rental = this.props.rental;
    Axios({
      method: "POST",
      url: `/rentals/${rental.id}/calculate_pricing.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: { rental_pricing },
    })
      .then((response) => {
        if (response.data.success) {
          const pricing = response.data.pricing;
          this.addDataLayerInfo({ type: "productDetail", firstLoad });
          this.setState({
            hasQuoted: true,
            pricing,
            error: false,
            newFrom: rental_pricing.start,
            newTo: rental_pricing.end,
            firstLoad: false,
          });
        } else {
          this.showMessage(
            "primary",
            "Alerta",
            response.data.messages || ["Ha ocurrido un error inesperado"]
          );
        }
      })
      .catch((error) => {
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ]);
      })
      .finally(() => this.setState({ isSubmitting: false }));
  };

  addExtra = (extra) => {
    this.setState(
      (state) => {
        state.extrasIds.push(extra);
        localStorage.setItem("extras_ids", state.extrasIds);
        localStorage.setItem("current_rental", this.props.rental.id);
        return { extrasIds: state.extrasIds, hasQuoted: false };
      },
      () => this.calculatePrice()
    );
  };

  removeExtra = (extra) => {
    const index = this.state.extrasIds.findIndex((x) => x === extra);
    this.setState(
      (state) => {
        state.extrasIds.splice(index, 1);
        localStorage.setItem("extras_ids", state.extrasIds);
        localStorage.setItem("current_rental", this.props.rental.id);
        return { extrasIds: state.extrasIds, hasQuoted: false };
      },
      () => this.calculatePrice()
    );
  };

  handleChangeCheckedExtra = (checked, extra) => {
    if (checked) {
      this.addExtra(extra);
    } else {
      this.removeExtra(extra);
    }
  };

  changeLocation = (option, obj) =>
    this.setState(
      {
        hasQuoted: false,
        error: false,
        success: false,
        [obj]: option,
      },
      () => this.calculatePrice()
    );

  createBooking = () => {
    const booking = this.createParams();
    const pricing = this.state.pricing;
    const rental = this.props.rental;
    const vehicle = rental.vehicle;
    Axios({
      method: "POST",
      url: `/bookings.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: { booking },
    })
      .then((response) => {
        if (response.data.success) {
          this.addDataLayerInfo({
            type: "purchase",
            booking_id: response.data.booking_id,
          });
          localStorage.removeItem("extras_ids");
          this.setState({ success: true, error: false });
          this.modalAgreement.hide();
          this.showMessage("primary", "Éxito", ["Reserva exitosa"]);
          this.checkContinuationData();
        } else {
          this.showMessage(
            "primary",
            "Alerta",
            response.data.messages || ["Ha ocurrido un error inesperado"]
          );
          if (response.data.payment_failed) {
            this.modalAgreement.hide();
            this.modalFailedPayment.show();
          }
        }
      })
      .catch((error) => {
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ]);
      })
      .finally(() => this.setState({ isSubmitting: false }));
  };

  showModalLogin = () => {
    this.modalLogin.show();
    document.getElementById("notlogged__email").focus();
  };

  addPaymentMethod = () => {
    this.setState({ loadMP: true }, () => this.modalPaymentMethod.show());
  };

  completeProfile = () => {
    if (this.props.user && this.props.user.id) {
      this.modalUserForm.show();
    } else this.showModalLogin();
  };

  showMercadoPagoModal = () => {
    const user = this.props.user;
    if (user && user.oneclick && user.oneclick.tbk_user) {
      this.modalMP.show();
    } else {
      this.addPaymentMethod();
    }
  };

  showLocationMap = ({ lat, lng, locationAddress }) => {
    this.setState(
      (state) => {
        state.mapInfo.lat = lat;
        state.mapInfo.lng = lng;
        state.mapInfo.locationAddress = locationAddress;
        return { mapInfo: state.mapInfo };
      },
      () => this.modalLocationMap.show()
    );
  };

  changeNotLoggedUser = (value, name) =>
    this.setState((state) => {
      state.notLoggedUser[name] = value;
      return { notLoggedUser: state.notLoggedUser };
    });

  changeLoggedUser = (value, name) =>
    this.setState((state) => {
      state.user[name] = value;
      return { user: state.user };
    });

  changeNeedInvoice = (value) => this.setState({ needInvoice: value });

  handleBlurIdNumber = () => {
    const idNumber = this.state.data.id_number;
    const documentType = this.state.data.document_type;

    if (documentType && documentType.description == "RUT") {
      if (validateRUT(idNumber)) {
        const formatedRut = formateaRut(idNumber);
        this.setState((state) => {
          state.data.id_number = formatedRut;
          return { data: state.data, errorIdNumber: "" };
        });
      } else {
        this.setState({ errorIdNumber: "El RUT es inválido" });
      }
    }
  };

  addDataLayerInfo = ({ type, firstLoad = false, booking_id = null }) => {
    // Check dataLayer
    if (this.dataLayer != undefined) {
      if (Array.isArray(this.dataLayer)) {
        // Variables
        const pricing = this.state.pricing;
        const rental = this.props.rental;
        const vehicle = rental?.vehicle;
        const vehicleType = vehicle?.vehicle_type;
        const brand =
          (vehicleType?.model || "") + " | " + (vehicle?.year || "");

        // Add to datalayer
        if (type == "addtoCart") {
          this.dataLayer.push({
            event: type,
            ecommerce: {
              add: {
                products: [
                  {
                    name: vehicleType?.brand,
                    id: vehicle?.id,
                    price: pricing.total.toString(),
                    brand,
                  },
                ],
              },
            },
          });
        } else if (type == "productDetail" && firstLoad) {
          const vehicleExtras = rental.pricing.extra_charges || [];
          this.dataLayer.push({
            event: type,
            ecommerce: {
              detail: {
                products: [
                  {
                    name: vehicleType?.brand, // Name o id es requerido.
                    id: vehicle?.id,
                    brand,
                    price: pricing.total.toString(),
                    dimension2:
                      vehicle?.vehicle_transmission?.description || "", // Tipo de transmisión.
                    dimension3: vehicle?.fuel_type?.description || "", // Tipo de combustible.
                    dimension4: vehicle.capacity, // Capacidad de pasajeros.
                    dimension5: vehicle.doors, // Cantidad de puertas.
                    dimension6: vehicleExtras
                      .map((x) => x.vehicle_extra.a_name)
                      .join(", "), // Extras.
                  },
                ],
              },
            },
          });
        } else if (type == "purchase" && booking_id) {
          this.dataLayer.push({
            event: "purchase",
            ecommerce: {
              currencyCode: "CLP",
              purchase: {
                actionField: {
                  id: booking_id, // ID de transacción
                  revenue: pricing.total.toString(), // Revenue total
                  tax: pricing.value_added_tax.toString(), // Impuesto IVA
                  shipping: pricing.commission.toString(), // Comisión
                  coupon: pricing.discount.toString(), // Descuento
                },
                products: [
                  {
                    id: vehicle.id,
                    name: vehicleType.brand,
                    price: pricing.base_owner_total.toString(), // Costo sin agregados
                    metric1: (
                      pricing.owner_total - pricing.base_owner_total
                    ).toString(), // Extras
                    metric2: pricing.insurance_charge.toString(), // Seguro
                  },
                ],
              },
            },
          });
        }
      }
    }
  };

  showSpinner = () => this.setState({ isSubmitting: true });

  hideSpinner = () => this.setState({ isSubmitting: false });

  render() {
    moment.locale("es");
    const {
      fromDate,
      fromTime,
      untilDate,
      untilTime,
      extrasIds,
      selectedPickup,
      selectedDropoff,
      pricing,
      newFrom,
      newTo,
      hasQuoted,
      error,
      success,
      isSubmitting,
      alert,
      notLoggedUser,
      user,
      needInvoice,
      loadMP,
      mapInfo,
    } = this.state;

    const { messageTitle, messageType, messages, showMessage } = alert;

    const { blockedRanges, rental, documentTypes } = this.props;
    const vehicle = rental.vehicle;

    const from = moment(fromDate + fromTime, "DD/MM/YYYYHH:mm");
    const until = moment(untilDate + untilTime, "DD/MM/YYYYHH:mm");
    const dayOfWeek = from.day();

    const newFrom_ = moment(newFrom);
    const newTo_ = moment(newTo);
    const eventRange = moment.duration(newTo_.diff(newFrom_));
    const duration = Math.floor(eventRange.asDays());
    const hours = Math.floor(eventRange.add(-duration, "days").asHours());
    const minutes = Math.floor(eventRange.add(-hours, "hours").asMinutes());

    let bookingLengthText =
      "Total por " + duration + " día" + (duration > 1 ? "s" : "");
    if (hours) {
      const hoursText = hours + " hora" + (hours > 1 ? "s" : "");
      if (minutes) bookingLengthText += "," + hoursText;
      else bookingLengthText += " y " + hoursText;
    }
    if (minutes) {
      bookingLengthText +=
        " y " + minutes + " minutos" + (minutes > 1 ? "s" : "");
    }

    let btnText = "Solicitar reserva";
    let btnAction = () => this.showModalLogin();
    let btnDisabled = isSubmitting || (success && !error);

    if (user && user.id) {
      if (!hasQuoted) {
        btnText = "Actualizar precio";
        btnAction = () => this.calculatePrice();
      } else if (!this.checkImportantData()) {
        btnAction = () => this.completeProfile();
        // TBK2MP Retomar MP
      } else if (!user.mercadopago || user.mercadopago.cards.length == 0) {
        // } else if (!user.oneclick || !user.oneclick.tbk_user) {
        btnAction = () => this.showMercadoPagoModal();
      } else if (!success) {
        btnText = "Solicitar reserva";
        btnAction = () => {
          this.addDataLayerInfo({ type: "addToCart" });
          this.modalAgreement.show();
        };
      } else {
        btnText = "Solicitud enviada";
        btnAction = () => null;
        btnDisabled = true;
      }
    }

    let alertOverlap = null;
    let datesOverlaped = false;
    if (Array.isArray(blockedRanges) && blockedRanges.length > 0) {
      for (let event of blockedRanges) {
        const eventMinValue = moment(event.from);
        const eventMaxValue = moment(event.until);
        const fromValue = moment(from).valueOf();
        const untilValue = moment(until);
        if (fromValue <= eventMaxValue && eventMinValue <= untilValue) {
          datesOverlaped = true;
          alertOverlap = (
            <div className="alert alert-primary my-3">
              <i className="mdi mdi-calendar-alert mx-2" /> Las fechas
              seleccionadas no se encuentran disponibles
            </div>
          );
          break;
        }
      }
    }
    const btnBook = (
      <button
        type="button"
        className="btn btn-appcar-rental text-nowrap"
        onClick={btnAction}
        disabled={btnDisabled}
      >
        {btnText}
      </button>
    );

    const modalDates = (
      <ModalDates
        changeFromTime={this.changeFromTime}
        changeUntilTime={this.changeUntilTime}
        fromDate={fromDate}
        fromTime={fromTime}
        untilDate={untilDate}
        untilTime={untilTime}
        alertOverlap={alertOverlap}
      />
    );

    const modalMercadoPago = (
      <ModalMercadoPago action={this.addPaymentMethod} />
    );

    const loginModal = (
      <LoginModal
        user={notLoggedUser}
        onChange={this.changeNotLoggedUser}
        validateEmail={validateEmail}
        checkUser={this.checkUser}
      />
    );

    const passwordModal = (
      <PasswordModal
        user={notLoggedUser}
        onChange={this.changeNotLoggedUser}
        action={this.login}
        forgotPassword={() => {
          this.modalPassword.hide();
          this.modalPasswordRecovery.show();
        }}
        validateEmail={validateEmail}
      />
    );

    const updatingUser = !!user.id;

    const formUser = updatingUser ? user : notLoggedUser;
    const formOnChange = updatingUser
      ? this.changeLoggedUser
      : this.changeNotLoggedUser;

    const userFormModal = (
      <UserFormModal
        user={formUser}
        onChange={formOnChange}
        validateEmail={validateEmail}
        action={this.updateUser}
        isUpdating={updatingUser}
        documentTypes={documentTypes}
        checkNeedInvoice={needInvoice}
        changeNeedInvoice={this.changeNeedInvoice}
      />
    );

    const paymentMethodModal = (
      <PaymentMethodModal
        mpPublicKey={this.props.mpPublicKey}
        user={user}
        showSpinner={this.showSpinner}
        hideSpinner={this.hideSpinner}
        showMessage={this.showMessage}
        callback={() => {
          this.reloadUser();
          this.modalPaymentMethod.hide();
          this.addDataLayerInfo({ type: "addToCart" });
          this.modalAgreement.show();
        }}
        loadMP={loadMP}
        MPLoaded={() => this.setState({ loadMP: false })}
      />
    );

    const afterBookingModal = (
      <AfterBookingModal user={this.state.user || {}} />
    );
    const needMoreDataModal = (
      <NeedMoreDataModal url={`/owners/${this.props.user?.id}/edit_driver`} />
    );

    const modalPricingDetails = (
      <ModalPricing pricing={pricing} from={newFrom} to={newTo} />
    );

    const modalMap = (
      <ModalLocation
        lat={mapInfo.lat}
        lng={mapInfo.lng}
        address={mapInfo.locationAddress}
        markerUrl={this.props.markerIconURL}
      />
    );

    const paymentFailedModal = <FailedPaymentModal />;

    const cancellationPolicy = vehicle.cancellation_policy;
    const fuelPolicy = vehicle.fuel_policy;
    const {
      total,
      insurance_charge,
      clf_value,
      owner_total,
      discount,
    } = pricing;
    const pickupParam =
      selectedPickup && selectedPickup.value
        ? "&pickup=" + encodeURIComponent(selectedPickup.value)
        : "";
    const dropoffParam =
      selectedDropoff && selectedDropoff.value
        ? "&dropoff=" + encodeURIComponent(selectedDropoff.value)
        : "";
    const vehicleExtras = rental.pricing.extra_charges || [];
    const selectedExtras = vehicleExtras.filter((extra) =>
      extrasIds.includes(extra.vehicle_extra.id)
    );

    const limited = !vehicle.unlimited_distance;
    let minLength = 0;
    if (rental.availability && rental.availability.booking_length) {
      if (dayOfWeek == 5) {
        minLength =
          rental.availability.booking_length.minimum_length_saturday || 0;
      } else if (dayOfWeek == 6) {
        minLength =
          rental.availability.booking_length.minimum_length_sunday || 0;
      } else {
        minLength = rental.availability.booking_length.minimum_length || 0;
      }
    }

    let legalURL = "";
    if (user && user.id) {
      legalURL =
        // "/legal/sublease_agreement?rental_id=" +
        "/legal/booking_certificate?rental_id=" +
        rental.id +
        "&user_id=" +
        (user && user.id) +
        "&from=" +
        encodeURIComponent(from.format()) +
        "&to=" +
        encodeURIComponent(until.format()) +
        "&total_price=" +
        encodeURIComponent(total) +
        "&insurance_charge=" +
        encodeURIComponent(insurance_charge) +
        "&clf_value=" +
        encodeURIComponent(clf_value) +
        "&owner_total=" +
        encodeURIComponent(owner_total) +
        "&discount=" +
        encodeURIComponent(owner_total) +
        pickupParam +
        dropoffParam;
    }
    const bookingModal = (
      <BookingModal
        title={
          <div className="legal-modal-title">
            <h4 className="modal-title" id="legalModalLabel">
              Resumen de arriendo
            </h4>
            <h5 className="modal-subtitle">
              Lee atentamente antes de continuar
            </h5>
          </div>
        }
        pricing={pricing}
        fuelPolicy={fuelPolicy}
        cancellationPolicy={cancellationPolicy}
        dropoff={selectedDropoff.value}
        pickup={selectedPickup.value}
        from={from.format()}
        to={until.format()}
        textAgreement={
          <>
            Estoy de acuerdo con los
            <a
              href="/legal/terms_and_conditions"
              target="_blank"
              className="text-black"
              style={{ textDecoration: "none" }}
            >
              {" "}
              <b>términos y condiciones</b>
            </a>
            .
          </>
        }
        textPrivacy={
          <>
            Estoy de acuerdo con el tratamiento de mis datos personales como se
            describe en la
            <a
              href={legalURL + "#privacy"}
              target="_blank"
              className="text-black"
              style={{ textDecoration: "none" }}
            >
              {" "}
              <b> Política de Privacidad II </b>
            </a>
            .
          </>
        }
        selectedExtras={selectedExtras}
        max250km={limited}
        minLength={minLength}
        action={this.createBooking}
        btnText="Solicitar reserva"
        btnEditarReserva="Editar reserva"
      />
    );

    const extraLocations = rental.pricing.extra_locations || [];
    const extras = (rental.pricing && rental.pricing.extra_charges) || [];

    const extrasContainer = (
      <ExtrasContainer
        extras={extras}
        extrasIds={extrasIds}
        handleChangeCheckedExtra={this.handleChangeCheckedExtra}
      />
    );

    const locationContainer = (
      <LocationContainer
        extraLocations={extraLocations}
        selectedPickup={selectedPickup}
        selectedDropoff={selectedDropoff}
        location={rental.location}
        showLocationMap={this.showLocationMap}
        changePickUp={(option) =>
          this.setState(
            {
              selectedPickup: { value: option.value, label: option.value },
              hasQuoted: false,
            },
            () => {
              this.calculatePrice();
              this.setCopyLinkData({ address: option.value });
            }
          )
        }
        changeDropOff={(option) =>
          this.setState(
            {
              selectedDropoff: { value: option.value, label: option.value },
              hasQuoted: false,
            },
            () => this.calculatePrice()
          )
        }
      />
    );

    let featuresHTML = "No se han añadido características";

    if (
      Array.isArray(vehicle.vehicle_features) &&
      vehicle.vehicle_features.length > 0
    ) {
      featuresHTML = (
        <div className="row">
          {vehicle.vehicle_features.map((feature, i) => (
            <div className="col-6" key={i}>
              {feature.a_name}
            </div>
          ))}
        </div>
      );
    }

    const descriptionHTML =
      rental.information && rental.information.description ? (
        <div className="section border-section">
          <h5>Descripción</h5>
          <div className="section-content">
            {rental.information.description}
          </div>
        </div>
      ) : null;

    const schedulerContainer = (
      <SchedulerContainer
        pricing={pricing}
        from={from}
        until={until}
        bookingLengthText={bookingLengthText}
        datesOverlaped={datesOverlaped}
        showModalPricing={() => this.modalPricingDetails.show()}
        showModalDates={() => this.modalDates.show()}
        btnBook={btnBook}
      />
    );
    return (
      <>
        <Spinner loading={isSubmitting} />
        <Alert
          alertClass={messageType}
          isShowing={showMessage}
          messages={messages}
          title={messageTitle}
          onClose={this.handleAlertClose}
        />
        <div className="desk vehicle-scheduler">
          <div className="column">
            {schedulerContainer}
            <div className="rental-card hide-on-res">
              <PoliciesSummary rental={rental} dayOfWeek={dayOfWeek} />
            </div>
            <div className="rental-card hide-on-res bg-white">
              <SantanderContainer />
            </div>
          </div>
          <div className="column">
            <div className="rental-card">
              {locationContainer}
              <div className="py-3"></div>
              <RentalSchedule rental={rental} />
            </div>
            <div className="rental-card">
              <h5>Extras</h5>
              <div className="extras-container">{extrasContainer}</div>
            </div>
            <div className="rental-card show-on-res">
              <PoliciesSummary rental={rental} dayOfWeek={dayOfWeek} />
            </div>
            <div className="rental-card show-on-res bg-white">
              <SantanderContainer />
            </div>
          </div>
        </div>
        <div className="mobile mobile-scheduler">
          <div className="section">{schedulerContainer}</div>
          <div className="section border-section">{locationContainer}</div>
          <div className="section border-section">
            <RentalSchedule rental={rental} />
          </div>
          {descriptionHTML}
          <div className="section border-section">
            <h5>Características</h5>
            <div className="section-content">{featuresHTML}</div>
          </div>
          <div className="section border-section">
            <h5>Extras</h5>
            <div className="section-content">{extrasContainer}</div>
          </div>
          <div className="section">
            <PoliciesSummary rental={rental} dayOfWeek={dayOfWeek} />
          </div>
          <div className="section">
            <SantanderContainer />
          </div>
        </div>
        <PasswordRecovery
          propsEmail={notLoggedUser.email}
          updateEmail={(email) => this.changeNotLoggedUser(email, "email")}
          canBack={true}
          onBack={() => this.modalPassword.show()}
          callBack={() => this.modalPassword.show()}
        />
        {modalDates}
        {modalPricingDetails}
        {bookingModal}
        {modalMercadoPago}
        {loginModal}
        {passwordModal}
        {userFormModal}
        {paymentMethodModal}
        {afterBookingModal}
        {needMoreDataModal}
        {modalMap}
        {paymentFailedModal}
      </>
    );
  }
}
