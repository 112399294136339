import React, { Component } from "react";
import Spinner from "../../common/Spinner";
import { constants } from "../../common/constants";
import axios from "axios";
import { AxiosApplicationInterceptor } from "../../common/axios_interceptor";
import FlashMessage from "../../common/FlashMessage";
axios.interceptors.response.use(undefined, AxiosApplicationInterceptor);

class CalendarModal extends Component {
  state = {
    ui: {
      isSubmitting: false,
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined
    },
    rangeStart: moment().format(),
    rangeEnd: moment().format(),
    reason: ""
  };

  // reRender = () => {
  //   this.forceUpdate();
  // }

  componentDidMount() {
    // window.rerender = this.reRender;
    const dateRangeOptions = {
      showDropdowns: true,
      maxYear: constants.MAX_YEAR,
      timePicker24Hour: true,
      locale: {
        format: "LL",
        cancelLabel: "Cancelar",
        applyLabel: "Aplicar",
        fromLabel: "Desde",
        toLabel: "Hasta",
        daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre"
        ],
        firstDay: 1
      }
    }
    $("#dateRangeLockUnlock").daterangepicker(dateRangeOptions, (start, end) => {
      const start_ = moment(start).format();
      const end_ = moment(end).format();
      this.setState({ rangeStart: start_, rangeEnd: end_ });
    })

  }

  showMessage = msg => {
    this.setState(state => {
      state.ui.showMessage = msg.showMessage;
      state.ui.messageType = msg.messageType;
      state.ui.messages = msg.messages;
      state.ui.messageTitle = msg.messageTitle;
      return { ui: state.ui };
    });
  };

  blockDays = () => {
    this.setState(state => {
      state.ui.isSubmitting = true
      return { ui: state.ui };
    });
    const { rangeStart, rangeEnd, reason } = this.state;
    const vehicleIds = window.selectedVehicles || [];
    axios({
      method: "post",
      url: `/rentals/block_multiple_ranges.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        range_start: rangeStart,
        range_end: rangeEnd,
        reason: reason,
        vehicle_ids: vehicleIds
      }
    })
      .then(response => {
        if (response.data.success) {
          this.showMessage({
            showMessage: true,
            messageType: "alert-success",
            messages: ["Los días fueron bloqueados para los vehículos seleccionados"],
            messageTitle: "Éxito"
          });
        } else {
          this.showMessage({
            showMessage: true,
            messageType: "alert-warning",
            messages: response.data.messages || ["Ha ocurrido un error inesperado"],
            messageTitle: "Alerta"
          });
        }
      })
      .catch(error => {
        this.showMessage({
          showMessage: true,
          messageType: "alert-warning",
          messages: ["Ha ocurrido un error inesperado"],
          messageTitle: "Alerta"
        });
      })
      .finally(() => {
        this.setState(state => {
          state.ui.isSubmitting = false;
          return { ui: state.ui };
        })
      });
  }

  unblockDays = () => {
    this.setState(state => {
      state.ui.isSubmitting = true
      return { ui: state.ui };
    });
    const { rangeStart, rangeEnd, reason } = this.state;
    const vehicleIds = window.selectedVehicles || [];
    axios({
      method: "post",
      url: `/rentals/unblock_multiple_ranges.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        range_start: rangeStart,
        range_end: rangeEnd,
        reason: reason,
        vehicle_ids: vehicleIds
      }
    })
      .then(response => {
        if (response.data.success) {
          this.showMessage({
            showMessage: true,
            messageType: "alert-success",
            messages: ["Los días fueron liberados para los vehículos seleccionados"],
            messageTitle: "Éxito"
          });
        } else {
          this.showMessage({
            showMessage: true,
            messageType: "alert-warning",
            messages: response.data.messages || ["Ha ocurrido un error inesperado"],
            messageTitle: "Alerta"
          });
        }
      })
      .catch(error => {
        this.showMessage({
          showMessage: true,
          messageType: "alert-warning",
          messages: ["Ha ocurrido un error inesperado"],
          messageTitle: "Alerta"
        });
      })
      .finally(() => {
        this.setState(state => {
          state.ui.isSubmitting = false;
          return { ui: state.ui };
        })
      });
  }

  render() {
    const flash_message = this.state.ui.showMessage ? (
      <FlashMessage
        type={this.state.ui.messageType}
        msg={this.state.ui.messages}
        title={this.state.ui.messageTitle}
      />
    ) : null;
    return (
      <>
        <Spinner loading={this.state.ui.isSubmitting} />
        <div
          className="modal fade"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="block-ranges-title"
          aria-hidden="true"
          id="modal-block-ranges"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="block-ranges-title">
                  Modificar disponibilidad de vehículos seleccionados
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" id="body-cancellation">
                <div className="alert alert-info">
                  <i className="mdi mdi-information-outline" /> Cualquier cambio realizado afectará a todos los vehículos seleccionados.
                </div>
                {flash_message}
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="dateRangeLockUnlock">Rango de fechas a aplicar</label>
                      <table
                        id="dateRangeLockUnlock"
                        style={{ width: "100%", fontWeight: "bolder" }}
                      >
                        <tbody>
                          <tr>
                            <td style={{ color: "#203453" }}>INICIO</td>
                            <td>:</td>
                            <td>{moment(this.state.rangeStart).format("LLL")}</td>
                          </tr>
                          <tr>
                            <td style={{ color: "#203453" }}>FIN</td>
                            <td>:</td>
                            <td>{moment(this.state.rangeEnd).format("LLL")}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor="reason">Motivo</label>
                      <input
                        type="text"
                        id="reason"
                        name="reason"
                        className="form-control appcar-input"
                        onChange={e => this.setState({ reason: e.target.value })}
                        value={this.state.reason}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger mr-2"
                  onClick={() => this.blockDays()}
                >
                  <i className="mdi mdi-lock-outline" /> Bloquear fechas
                </button>
                <button
                  type="button"
                  className="btn btn-success mr-2"
                  onClick={() => this.unblockDays()}
                >
                  <i className="mdi mdi-lock-open-outline" /> Desbloquear fechas
                </button>
                <button
                  type="button"
                  className="btn btn-appcar-light"
                  data-dismiss="modal"
                  data-bs-dismiss="modal"
                >
                  Salir
              </button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default CalendarModal;
