import React from "react";
import { formatedPricing } from "../../constants";

const VehicleCard = ({ rental, link }) => {
  const vehicle = rental.vehicle;
  const user = vehicle.associated_users[0];
  const car_data = `${vehicle.vehicle_type.brand} ${vehicle.vehicle_type.model} | ${vehicle.year}`;
  let car_rating = [];
  for (let i = 0; i < 5; i++) {
    let r_icon = null;
    if (i < vehicle.vehicle_rating) {
      r_icon = <i className="mdi mdi-car-sports" key={i} />;
    } else {
      r_icon = <i className="mdi mdi-car-sports icon-gray" key={i} />;
    }
    car_rating.push(r_icon);
  }
  let user_rating = [];
  for (let i = 1; i <= 5; i++) {
    const r_icon =
      i <= user.admin_rating ? (
        <i className="mdi mdi-star" key={i} />
      ) : (
        <i className="mdi mdi-star-outline" key={i} />
      );
    user_rating.push(r_icon);
  }
  const evaluations = (
    <div className="badge-ratings" key="badge">
      {user.admin_evaluations}
    </div>
  );
  user_rating.push(evaluations);
  const photo = user.photo ? `${user.photo}/thumb` : "/user-icon.png";
  const vehicle_image =
    Array.isArray(vehicle.vehicle_pictures) &&
      typeof vehicle.vehicle_pictures[0] == "string"
      ? vehicle.vehicle_pictures[0] + "/thumb"
      : "/no-image.png";
  return (
    <div className="car-card link-to-rental">
      <a href={`/rentals/${rental.id}`} rel="noopener noreferrer" target="_blank">
        <div
          className="car-card-image"
          style={{
            backgroundImage: `url("${vehicle_image}")`
          }}
        />
      </a>
      <a href={link} rel="noopener noreferrer" target="_blank">
        <div className="car-card-content">
          <div className="car-card-price">
            <label className="discount-price">
              {formatedPricing(rental.pricing.base_price)}
            </label>
            <label className="text-each-day">/día</label>
          </div>
          <div className="user-data">
            <div
              className="user-image"
              style={{ backgroundImage: `url("${photo}")` }}
            />
            <div className="car-data">
              <div className="car-rating">
                <p className="car-name">{car_data.toUpperCase()}</p>
                <div className="car-rate">{car_rating}</div>
              </div>
              <div className="owner-info">
                <label className="owner-name">
                  {user.nickname}
                </label>
                <div className="owner-rating">{user_rating}</div>
              </div>
              <label className="owner-trips">{vehicle.trips} viajes</label>
            </div>
          </div>
          <div className="car-data-separator" />
        </div>
      </a>
    </div>
  );
}

export default VehicleCard;
