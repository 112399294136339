import React from "react";
import DocumentLoader from "../../../common/components/image_loader/DocumentLoader";

const VehicleDocuments = ({
  data,
  openFileDialog,
  handleSubmit,
  isSubmitting,
  handleChangeObj,
  handleChange,
  removePicture,
  buttonText = "Continuar",
  needMotorData = true,
}) => {
  const { registry, engine, chassis } = data;
  const name = registry && registry.name;
  const idNumber = registry && registry.id_number;
  const registration_document = data.registration_document;
  const padronPic =
    Array.isArray(registration_document.registration_document) &&
    registration_document.registration_document.length > 0
      ? registration_document.registration_document[0]
      : {
          filename: "",
          start: "",
          image_base_64: "",
          mime_type: "",
          url: "",
        };
  const techRevisionPic =
    Array.isArray(registration_document.vehicular_technical_revision) &&
    registration_document.vehicular_technical_revision.length > 0
      ? registration_document.vehicular_technical_revision[0]
      : {
          filename: "",
          start: "",
          image_base_64: "",
          mime_type: "",
          url: "",
        };
  const permitPic =
    Array.isArray(registration_document.circulation_permit) &&
    registration_document.circulation_permit.length > 0
      ? registration_document.circulation_permit[0]
      : {
          filename: "",
          start: "",
          image_base_64: "",
          mime_type: "",
          url: "",
        };
  const soapPic =
    Array.isArray(data.insurance_policy.soap) &&
    data.insurance_policy.soap.length > 0
      ? data.insurance_policy.soap[0]
      : {
          filename: "",
          start: "",
          image_base_64: "",
          mime_type: "",
          url: "",
        };
  const engineComp = needMotorData ? (
    <div className="form-group">
      <label htmlFor="engine" className="label-inside">
        N° de motor
      </label>
      <input
        type="text"
        name="engine"
        id="engine"
        className="form-control appcar-input"
        value={engine}
        required
        onChange={(e) => handleChange(e.target.value, "engine")}
      />
    </div>
  ) : null;
  const chassisComp = needMotorData ? (
    <div className="form-group">
      <label htmlFor="chassis" className="label-inside">
        N° de chasis
      </label>
      <input
        type="text"
        name="chassis"
        id="chassis"
        className="form-control appcar-input"
        value={chassis}
        required
        onChange={(e) => handleChange(e.target.value, "chassis")}
      />
    </div>
  ) : null;
  return (
    <>
      <DocumentLoader
        addImage={openFileDialog}
        removeImage={(obj) => removePicture(0, obj)}
        imageBase64={padronPic.image_base_64}
        start={padronPic.start}
        url={padronPic.url}
        title="Padrón o certificado de inscripción:"
        backgroundPlaceHolder="/PadronPlaceholder.png"
        objectName="registration_document.registration_document"
        filename={padronPic.filename}
      />
      {engineComp}
      {chassisComp}
      <div className="form-group">
        <label htmlFor="registry_id_number" className="label-inside">
          Rut en padrón o certificado de inscripción
        </label>
        <input
          type="text"
          name="registry[id_number]"
          id="registry_id_number"
          className="form-control appcar-input"
          value={idNumber}
          required
          onChange={(e) =>
            handleChangeObj(e.target.value, "id_number", "registry")
          }
        />
      </div>
      <div className="form-group mb-4">
        <label htmlFor="registry_name" className="label-inside">
          Nombre en padrón o certificado de inscripción
        </label>
        <input
          type="text"
          name="registry[name]"
          id="registry_name"
          className="form-control appcar-input"
          value={name}
          required
          onChange={(e) => handleChangeObj(e.target.value, "name", "registry")}
        />
      </div>
      <DocumentLoader
        addImage={openFileDialog}
        removeImage={(obj) => removePicture(0, obj)}
        imageBase64={techRevisionPic.image_base_64}
        start={techRevisionPic.start}
        url={techRevisionPic.url}
        title="Revisión técnica u homologación:"
        backgroundPlaceHolder="/VehicularTechRevPlaceholder.png"
        objectName="registration_document.vehicular_technical_revision"
        filename={techRevisionPic.filename}
      />
      <DocumentLoader
        addImage={openFileDialog}
        removeImage={(obj) => removePicture(0, obj)}
        imageBase64={permitPic.image_base_64}
        start={permitPic.start}
        url={permitPic.url}
        title="Permiso de circulación:"
        backgroundPlaceHolder="/CirculationPermitPlaceholder.png"
        objectName="registration_document.circulation_permit"
        filename={permitPic.filename}
      />
      <DocumentLoader
        addImage={openFileDialog}
        removeImage={(obj) => removePicture(0, obj)}
        imageBase64={soapPic.image_base_64}
        start={soapPic.start}
        url={soapPic.url}
        title="SOAP:"
        backgroundPlaceHolder="/SoapPlaceholder.png"
        objectName="insurance_policy.soap"
        filename={soapPic.filename}
      />
      <div className="text-center my-4">
        <button
          type="button"
          className="btn btn-appcar-primary pt-2 pb-2"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {buttonText}
        </button>
      </div>
    </>
  );
};

export default VehicleDocuments;
