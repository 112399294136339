import React, { useEffect } from "react";
import ToolTip, { LoadToolTips } from "../../../common/components/ToolTip";
import SuperOwnerBadge from "images/Icons/super_owner.svg";

const SuperOwnerButton = ({ fromDashboard }) => {
  useEffect(() => {
    LoadToolTips();
  }, []);
  const iconWidth = fromDashboard ? 18 : 25;
  const buttonTooltip = (
    <div
      className={`btn-tooltip-super-owner ${
        fromDashboard ? "vehicle" : "my-4"
      }`}
    >
      <img
        src={SuperOwnerBadge}
        width={iconWidth}
        className="ml-1"
        style={{ marginTop: "-5px" }}
      />{" "}
      Super-Owner
    </div>
  );
  const tooltipText = fromDashboard
    ? "Eres parte de nuestro selecto grupo de Super-Owners. Con esto, tus autos aparecerán publicados entre los primeros resultados de búsqueda, entre otros beneficios."
    : "Este auto pertenece a un Super-Owner. Estos dueños de auto tienen mucha experiencia, cuentan con calificaciones excelentes, se esfuerzan y comprometen al máximo para ofrecer un excelente servicio, ¡te lo recomendamos!";
  return (
    <>
      <ToolTip
        title="Super-Owner"
        tooltipHTML={tooltipText}
        containerHTML={buttonTooltip}
        id="ttip_rental_max_km_"
        placement="right"
      />
    </>
  );
};

export default SuperOwnerButton;
