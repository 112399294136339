import React, { useState } from "react";
import iconNoImage from "images/Icons/NoImageWhiteBG.svg";
import { generateFilename } from "../../common/constants";
import Alert from "../../common/components/alert/Alert";
import Spinner from "../../common/Spinner";
import Axios from "axios";
import { AxiosBackofficeInterceptor } from "../../common/axios_interceptor";
Axios.interceptors.response.use(undefined, AxiosBackofficeInterceptor);

const VehiclePictureUploader = ({ vehiclePictures, vehicleId }) => {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    showMessage: false,
    messageType: undefined,
    messages: [],
    messageTitle: undefined,
  });
  const [uploadedPicture, setUploadedPicture] = useState({
    filename: "",
    start: "",
    image_base_64: "",
    mime_type: "",
  });
  const hasPictures =
    Array.isArray(vehiclePictures) && vehiclePictures.length > 0;
  let picture = hasPictures ? vehiclePictures[0] : iconNoImage;
  if (!!uploadedPicture?.image_base_64) {
    picture = uploadedPicture.start + "," + uploadedPicture.image_base_64;
  }
  const imageTag = hasPictures ? (
    <img
      src={picture}
      data-bs-target="#modal-vehicle-image"
      data-bs-toggle="modal"
    />
  ) : (
    <img src={picture} style={{ height: "50%" }} className="image-filler" />
  );

  const uploadPicture = (e) => {
    let file = e.target.files;
    if (file.length > 0) {
      const filename = generateFilename(file[0].name);
      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onloadend = function(e) {
        var base64result = reader.result;
        addPicture(base64result, filename);
      }.bind(this);
    }
  };

  const addPicture = (picture, filename) => {
    const file = picture.split(",");
    const start = file[0];
    const image_base_64 = file[1];
    const mime = picture.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    const mime_type = mime && mime.length ? mime[1] : "";
    filename = encodeURI(filename);
    setUploadedPicture({ filename, start, image_base_64, mime_type });
  };

  const openFileDialog = () =>
    document.getElementById("image_uploader").click();

  const showMessage = (messageType, messageTitle, messages) => {
    setAlert((prev) => {
      prev.showMessage = true;
      prev.messageType = messageType;
      prev.messageTitle = messageTitle;
      prev.messages = messages;
      return { ...prev };
    });
    setTimeout(closeAlert, 3000);
  };

  const closeAlert = () =>
  setAlert((prevAlert) => ({ ...prevAlert, showMessage: false }));

  const handleSubmit = () => {
    if (!uploadedPicture.image_base_64) {
      showMessage("primary", "Atención", [
        "No se ha seleccionado ninguna imagen",
      ]);
      return;
    }
    setLoading(true);
    let shouldCloseSpinner = true;
    Axios({
      method: "PATCH",
      url: `/backoffice/vehicles/${vehicleId}/update_vehicle_picture.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: { uploadedPicture },
    })
      .then((response) => {
        if (response.data.success) {
          shouldCloseSpinner = false;
          showMessage("primary", "Éxito", ["Imagen actualizada"]);
          location.reload();
        } else {
          showMessage(
            "primary",
            "Alerta",
            response.data.messages || ["Ha ocurrido un error inesperado"]
          );
        }
      })
      .catch((error) => {
        showMessage("primary", "Error", [
          "Ocurrió un error al subir la imagen",
        ]);
      })
      .finally(() => {
        if (shouldCloseSpinner) setLoading(false);
      });
  };
  return (
    <>
      <Alert
        alertClass={alert.messageType}
        isShowing={alert.showMessage}
        messages={alert.messages}
        title={alert.messageTitle}
        onClose={closeAlert}
      />
      <Spinner loading={loading} />
      <div className="image-container image-profile my-3">
        {imageTag}

        <input
          type="file"
          id="image_uploader"
          className="d-none"
          onChange={(e) => uploadPicture(e)}
          accept="image/jpeg, image/png, image/jpg"
          onClick={(e) => {
            e.target.value = null;
          }}
        />
      </div>
      <div className="row">
        <div className="col">
          <button
            type="button"
            className="btn btn-appcar-primary pt-2 pb-2 fs-12 mb-2"
            onClick={openFileDialog}
          >
            Editar
          </button>
        </div>
        <div className="col">
          <button
            type="button"
            className="btn btn-appcar-light pt-2 pb-2 fs-12 mb-2"
            disabled={!uploadedPicture.image_base_64}
            onClick={handleSubmit}
          >
            Guardar
          </button>
        </div>
      </div>
    </>
  );
};

export default VehiclePictureUploader;
