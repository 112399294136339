import React, { Fragment } from "react";
import { numeral } from "./constants";
import sumBy from "lodash/sumBy";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

const PricingDetails = ({ price, dateFrom, dateUntil, fromEdit, id }) => {
  const {
    total,
    commission,
    payment_method_fee,
    insurance_charge,
    value_added_tax,
    pick_up_charge,
    drop_off_charge,
    discount,
    owner_discount
  } = { ...price };

  let details = {
    rental: 0,
    one_pay_extras: [],
    daily_pay_extras: []
  };
  let id_ = id || "";
  details.rental = sumBy(price.details, detail => {
    return detail.price;
  });
  id_ = id_ + Math.floor((Math.random() * 1000)).toString();
  const dayjs_from = dayjs(dateFrom);
  const dayjs_to = dayjs(dateUntil);
  const event_range = dayjs.duration(dayjs_to.diff(dayjs_from));
  const brute_duration = event_range.asDays();
  const duration = Math.floor(event_range.asDays());
  const hours = Math.floor(event_range.add(-duration, "days").asHours());
  const minutes = Math.floor(
    event_range
      .add(-duration, "days")
      .add(-hours, "hours")
      .asMinutes()
  );
  const tooltip_table = price.details.map((detail, i) => (
    <tr key={i}>
      <td align="left">{dayjs(detail.date).format("DD-MM-YYYY")}</td>
      <td align="right">{numeral(detail.price).format("$0,0")}</td>
    </tr>
  ));
  const vehicle_tooltip_content = (
    <Fragment>
      <h5>Desglose de precio base</h5>
      <table className="tooltip-table-pricing">
        <tbody>
          {tooltip_table}
          <tr>
            <td align="left" className="font-weight-bold">
              Total
            </td>
            <td align="right" className="font-weight-bold">
              {numeral(details.rental).format("$0,0")}
            </td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
  details.one_pay_extras = price.extra_charges.map(extra => {
    return {
      name: extra.extra.a_name,
      price: extra.price
    };
  });
  details.daily_pay_extras =
    price.details.length > 0
      ? price.details[0].extra_charges.map(extra => {
        return {
          name: extra.extra.a_name,
          price: 0
        };
      })
      : [];
  for (const detail of price.details) {
    for (const extra of detail.extra_charges) {
      for (const daily_extra of details.daily_pay_extras) {
        if (daily_extra.name === extra.extra.a_name) {
          daily_extra.price += extra.price;
        }
      }
    }
  }
  let total_extras = 0;
  const daily_extras = details.daily_pay_extras.map((extra, i) => {
    total_extras += extra.price;
    return (
      <div key={i} className="ml-2">
        <div className="start">{extra.name}</div>
        <div className="end">{numeral(extra.price).format("$ 0,0")}</div>
        <div style={{ clear: "both" }} />
      </div>
    );
  });
  const pay_per_day_extras_html =
    details.daily_pay_extras.length > 0 ? (
      <React.Fragment>
        <div className="charge-text-row charge-text-title ml-2">
          Extras de pago diario
        </div>
        {daily_extras}
      </React.Fragment>
    ) : null;
  const one_pay_extras = details.one_pay_extras.map((extra, i) => {
    total_extras += extra.price;
    return (
      <div key={i} className="ml-2">
        <div className="start">{extra.name}</div>
        <div className="end">{numeral(extra.price).format("$ 0,0")}</div>
        <div style={{ clear: "both" }} />
      </div>
    );
  });
  const one_pay_extras_html =
    details.one_pay_extras.length > 0 ? (
      <React.Fragment>
        <div className="charge-text-row charge-text-title ml-2">
          Extras de un sólo pago
        </div>
        {one_pay_extras}
      </React.Fragment>
    ) : null;

  const special_pickup =
    pick_up_charge && pick_up_charge.price ? (
      <div className="ml-2">
        <div className="start">
          Entrega{" "}
          <i
            className="mdi mdi-information-outline anc-tooltip"
            data-toggle-tooltip-for={"tooltip-text-pickup" + id_}
          >
            <span
              className="tooltiptext tooltip-right"
              id={"tooltip-text-pickup" + id_}
            >
              {pick_up_charge.location.name}
            </span>
          </i>
        </div>
        <div className="end">
          {numeral(pick_up_charge.price).format("$ 0,0")}
        </div>
        <div style={{ clear: "both" }} />
      </div>
    ) : null;

  if (pick_up_charge && pick_up_charge.price)
    total_extras += pick_up_charge.price;

  const special_dropoff =
    drop_off_charge && drop_off_charge.price ? (
      <div className="ml-2">
        <div className="start">
          Devolución{" "}
          <i
            className="mdi mdi-information-outline anc-tooltip"
            data-toggle-tooltip-for={"tooltip-text-dropoff" + id_}
          >
            <span
              className="tooltiptext tooltip-right"
              id={"tooltip-text-dropoff" + id_}
            >
              {drop_off_charge.location.name}
            </span>
          </i>
        </div>
        <div className="end">
          {numeral(drop_off_charge.price).format("$ 0,0")}
        </div>
        <div style={{ clear: "both" }} />
      </div>
    ) : null;

  if (drop_off_charge && drop_off_charge.price)
    total_extras += drop_off_charge.price;

  const extra_locations =
    !!special_dropoff || !!special_pickup ? (
      <React.Fragment>
        <div className="charge-text-row charge-text-title ml-2">
          Ubicaciones extra
        </div>
        {special_pickup}
        {special_dropoff}
      </React.Fragment>
    ) : null;
  const total_operation = commission + insurance_charge + payment_method_fee;
  const total_extras_html =
    details.daily_pay_extras.length > 0 ||
      details.one_pay_extras.length > 0 ||
      !!extra_locations ? (
        <div className="charge-text-row charge-text-main">
          <div className="start">Sub-total Extras</div>
          <div className="end">{numeral(total_extras).format("$ 0,0")}</div>
          <div style={{ clear: "both" }} />
        </div>
      ) : null;

  const discount_html =
    discount && discount > 0 ? (
      <div className="ml-2">
        <div className="start">Descuento</div>
        <div className="end" style={{ color: "#ec0000" }}>
          {numeral(discount).format("$ 0,0")}
        </div>
        <div style={{ clear: "both" }} />
      </div>
    ) : null;

  const discount_total =
    discount && discount > 0 ? (
      <div className="charge-text-row charge-text-main">
        <div className="start">Sub-total Descuento</div>
        <div className="end" style={{ color: "#ec0000" }}>
          {numeral(discount).format("$ 0,0")}
        </div>
        <div style={{ clear: "both" }} />
      </div>
    ) : null;

  const ownerDiscount = owner_discount ? owner_discount : 0; 
  const total_without_tax = total - value_added_tax;
  const insurance_text =
    isNaN(brute_duration) || 0
      ? `Corresponde al valor del seguro`
      : `Corresponde al valor del seguro para el período de ${duration} día${duration == 1 ? "" : "s"}${
      !!hours ? `, ${hours} hora${hours == 1 ? "" : "s"}` : ""
      }${!!minutes ? `, ${minutes} minutos` : ""}`;
  const ownerDiscountContainer = ownerDiscount > 0 ? (
    <div className="charge-text-row ml-5" >
      <div className="start">Descuento de Owner</div>
      <div className="end">{numeral(ownerDiscount).format("$ 0,0")}</div>
      <div style={{ clear: "both" }} />
    </div >
  )
  : null
  const total_price = total;
  return (
    <React.Fragment>
      <h4>Detalle del precio</h4>
      <div className="included-distance my-2">
        <div className="charge-text-row charge-text-main">
          <div className="start">
            Vehículo{" "}
            <i
              className="mdi mdi-information-outline anc-tooltip"
              data-toggle-tooltip-for={"tooltip-text-vehicle" + id_}
            >
              <span
                className={`tooltiptext tooltip-${
                  !!fromEdit ? "top" : "left"
                  } tooltip-lg`}
                id={"tooltip-text-vehicle" + id_}
              >
                {vehicle_tooltip_content}
              </span>
            </i>
          </div>
          <div className="end">{numeral(details.rental).format("$ 0,0")}</div>
          <div style={{ clear: "both" }} />
        </div>
        {one_pay_extras_html}
        {pay_per_day_extras_html}
        {extra_locations}
        {total_extras_html}
        {discount_html}
        {discount_total}
        <div className="charge-text-row charge-text-title ml-2">
          Costos de operación
        </div>
        <div className="ml-2">
          <div className="start">
            Seguro{" "}
            <i
              className="mdi mdi-information-outline anc-tooltip"
              data-toggle-tooltip-for={"tooltip-text-insurance" + id_}
            >
              <span
                className="tooltiptext tooltip-right"
                id={"tooltip-text-insurance" + id_}
              >
                {insurance_text}
              </span>
            </i>
          </div>
          <div className="end">{numeral(insurance_charge).format("$ 0,0")}</div>
          <div style={{ clear: "both" }} />
        </div>
        <div className="ml-2">
          <div className="start">
            Subarriendo Appcar{" "}
            <i
              className="mdi mdi-information-outline anc-tooltip"
              data-toggle-tooltip-for={"tooltip-text-commission" + id_}
            >
              <span
                className="tooltiptext tooltip-right"
                id={"tooltip-text-commission" + id_}
              >
                Corresponde al cargo por subarriendo por parte de Appcar al
                Driver.
              </span>
            </i>
          </div>
          <div className="end">{numeral(commission).format("$ 0,0")}</div>
          <div style={{ clear: "both" }} />
        </div>
        <div className="ml-2">
          <div className="start">
            Costo método de pago{" "}
            <i
              className="mdi mdi-information-outline anc-tooltip"
              data-toggle-tooltip-for={"tooltip-text-payment-method" + id_}
            >
              <span
                className="tooltiptext tooltip-right"
                id={"tooltip-text-payment-method" + id_}
              >
                Corresponde al cargo de pasarela de pago para transacción con
                tarjeta de crédito
              </span>
            </i>
          </div>
          <div className="end">
            {numeral(payment_method_fee).format("$ 0,0")}
          </div>
          <div style={{ clear: "both" }} />
        </div>
        <div className="charge-text-row charge-text-main charge-text-title">
          <div className="start">Sub-total operación</div>
          <div className="end">{numeral(total_operation).format("$ 0,0")}</div>
          <div style={{ clear: "both" }} />
        </div>
        {ownerDiscountContainer}
        <div className="charge-text-row ml-5">
          <div className="start">Total neto</div>
          <div className="end">
            {numeral(total_without_tax).format("$ 0,0")}
          </div>
          <div style={{ clear: "both" }} />
        </div>
        <div className="charge-text-row charge-text-title ml-5">
          <div className="start">IVA</div>
          <div className="end">{numeral(value_added_tax).format("$ 0,0")}</div>
          <div style={{ clear: "both" }} />
        </div>
        <div className="charge-text-row ml-5">
          <div className="start">TOTAL</div>
          <div className="end">{numeral(total_price).format("$ 0,0")}</div>
          <div style={{ clear: "both" }} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default PricingDetails;
