import React from "react";
import { numeral } from "../../../../common/constants";

const ExtrasContainer = ({ extras, extrasIds, handleChangeCheckedExtra }) =>
  extras.length > 0
    ? extras.map((extra, i) => (
        <div className="col-12 my-3" key={i}>
          <div className="form-group">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id={"extra_" + extra.vehicle_extra.id}
                name={"extra_" + extra.vehicle_extra.id}
                checked={extrasIds.indexOf(extra.vehicle_extra.id) > -1}
                onChange={(e) =>
                  handleChangeCheckedExtra(
                    e.target.checked,
                    extra.vehicle_extra.id
                  )
                }
              />
              <label
                className="form-check-label text-black extra-label"
                htmlFor={"extra_" + extra.vehicle_extra.id}
              >
                {extra.vehicle_extra.a_name}
                <span className="d-block extra-price">
                  <span className="d-inline text-appcar-primary fw-800">
                    {numeral(extra.price).format("$ 0,0")}
                  </span>
                  <span className="d-inline">
                    {extra.vehicle_extra.charge_per_day
                      ? " (Por día)."
                      : " (Pago único)."}
                  </span>
                </span>
              </label>
            </div>
          </div>
        </div>
      ))
    : "No se han agregado extras";

export default ExtrasContainer;
