import React from "react";
import TableCell from "./TableCell";

const TableRow = ({ columns, rowData }) => {
  const cells = columns.map((col, i) => (
    <TableCell text={rowData[col.name]} key={i}/>
  ))
  return <tr>{cells}</tr>
}

export default TableRow;