import React, { Component } from "react";
import { formatedPricing } from "../common/constants";

class LandingPageVehicleCard extends Component {
  render() {
    const vehicle = this.props.rental.vehicle;
    const user = this.props.rental.vehicle.associated_users[0];
    const rental = this.props.rental;
    const car_data = `${vehicle.vehicle_type.brand} ${vehicle.vehicle_type.model} | ${vehicle.year}`;
    let car_rating = [];
    for (let i = 0; i < 5; i++) {
      let r_icon = null;
      if (i < vehicle.vehicle_rating) {
        r_icon = <i className="mdi mdi-car-sports" key={i} />;
      } else {
        r_icon = <i className="mdi mdi-car-sports icon-gray" key={i} />;
      }
      car_rating.push(r_icon);
    }
    let user_rating = [];
    for (let i = 1; i <= 5; i++) {
      const r_icon =
        i <= user.admin_rating ? (
          <i className="mdi mdi-star" key={i} />
        ) : (
            <i className="mdi mdi-star-outline" key={i} />
          );
      user_rating.push(r_icon);
    }
    const evaluations = (
      <span className="badge-ratings" key="badge">
        {user.admin_evaluations}
      </span>
    );
    user_rating.push(evaluations);
    const photo = user.photo ? `${user.photo}/thumb` : "/user-icon.png";
    // const photo = user.photo ? `${user.photo}` : "/user-icon.png";
    const vehicle_image =
      Array.isArray(vehicle.vehicle_pictures) &&
        typeof vehicle.vehicle_pictures[0] == "string"
        ? vehicle.vehicle_pictures[0] + "/thumb"
        // ? vehicle.vehicle_pictures[0]
        : "/no-image.png";
    return (
      <div
        className="l-page-vehicle-card link-to-rental"
      >
        <a href={`/rentals/${rental.id}`} rel="noopener noreferrer" target="_blank">
          <div
            className="l-page-vehicle-card-image"
            style={{
              backgroundImage: `url("${vehicle_image}")`
            }}
          >
            <div className="l-page-vehicle-card-price">
              <p className="l-page-text-daily">Desde</p>
              <p className="l-page-text-price">
                <b>{formatedPricing(rental.pricing.base_price)}</b>
              </p>
              <p className="l-page-text-daily mt-0">/día</p>
            </div>
          </div>
        </a>
        <a href={`/rentals/${rental.id}`}>
          <div className="l-page-vehicle-card-content">
            {/* <div className="user-data">
              <div
                className="user-image"
                style={{ backgroundImage: `url("${photo}")` }}
              />
              <div className="car-data">
                <div className="car-rating">
                  <p className="car-name">{car_data.toUpperCase()}</p>
                  <div className="car-rate">{car_rating}</div>
                </div>
                <div className="owner-info">
                  <label className="owner-name">
                    {user.nickname}
                  </label>
                  <div className="owner-rating">{user_rating}</div>
                </div>
                <label className="owner-trips">{vehicle.trips} viajes</label>
              </div>
            </div> */}
            <div className="row mx-0">
              <div className="col-auto px-1 px-lg-2">
                <div
                  className="l-page-vehicle-card-user-image"
                  style={{ backgroundImage: `url("${photo}")` }}
                />
              </div>
              <div className="col px-1 px-md-2">
                <p className="my-1 my-md-0 my-lg-1 mb-0"><b>{car_data.toUpperCase()}</b></p>
                <p className="my-1 my-md-0 my-lg-1"><b>{user.nickname}</b> <span className="l-page-vehicle-card-user-rating">{user_rating}</span></p>
                <p className="my-1 my-md-0 my-lg-1 l-page-text-warning"><i className="mdi mdi-car-hatchback"></i> {vehicle.trips} viajes realizados</p>
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  }
}

export default LandingPageVehicleCard;
