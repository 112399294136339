import React, { Component } from "react";
import ImageContainer from "../../common/ImageContainer";
import { generateFilename } from "../../common/constants";

class VehicleInsurancePolicy extends Component {
  state = {
    full_path: ""
  };

  openFileDialog = full_path => {
    this.setState({ full_path }, () => $("#insurance_uploader").click());
  };

  addPicture = e => {
    let file = e.target.files;
    if (file.length > 0) {
      const filename = generateFilename(file[0].name);      
      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onloadend = function(e) {
        var base64result = reader.result;
        this.props.addDocumentation(
          base64result,
          filename,
          this.state.full_path
        );
      }.bind(this);
    }
  };

  removePicture = (index, full_path) => {
    this.props.removeDocumentation(index, full_path);
  };

  handlePrev = e => {
    e.preventDefault();
    $("html, main").animate({ scrollTop: 0 }, "slow");
    this.props.jumpToStep(2);
  };

  handleNext = e => {
    e.preventDefault();
    $("html, main").animate({ scrollTop: 0 }, "slow");
    this.props.jumpToStep(4);
  };

  render() {
    //SOAP

    //Third-party insurance
    // const third_party_insurance_pictures = this.props.insurancePolicy.third_party_insurance.map(
    //   (picture, i) => (
    //     <div className="col-md-6 my-3" key={i}>
    //       <ImageContainer
    //         start={picture.start}
    //         imageBase64={picture.image_base_64}
    //         removeImage={this.removePicture}
    //         fullPath="insurance_policy.third_party_insurance"
    //         index={i}
    //       />
    //     </div>
    //   )
    // );
    // third_party_insurance_pictures.push(
    //   <div className="col-md-6 my-3" key="add">
    //     <ImageContainer
    //       addImage={this.openFileDialog}
    //       fullPath="insurance_policy.third_party_insurance"
    //     />
    //   </div>
    // );

    // //Civil liability insurance
    // const civil_liability_insurance_pictures = this.props.insurancePolicy.civil_liability_insurance.map(
    //   (picture, i) => (
    //     <div className="col-md-6 my-3" key={i}>
    //       <ImageContainer
    //         start={picture.start}
    //         imageBase64={picture.image_base_64}
    //         removeImage={this.removePicture}
    //         fullPath="insurance_policy.civil_liability_insurance"
    //         index={i}
    //       />
    //     </div>
    //   )
    // );
    // civil_liability_insurance_pictures.push(
    //   <div className="col-md-6 my-3" key="add">
    //     <ImageContainer
    //       addImage={this.openFileDialog}
    //       fullPath="insurance_policy.civil_liability_insurance"
    //     />
    //   </div>
    // );

    // //Others
    // const others_pictures = this.props.insurancePolicy.others.map(
    //   (picture, i) => (
    //     <div className="col-md-6 my-3" key={i}>
    //       <ImageContainer
    //         start={picture.start}
    //         imageBase64={picture.image_base_64}
    //         removeImage={this.removePicture}
    //         fullPath="insurance_policy.others"
    //         index={i}
    //       />
    //     </div>
    //   )
    // );
    // others_pictures.push(
    //   <div className="col-md-6 my-3" key="add">
    //     <ImageContainer
    //       addImage={this.openFileDialog}
    //       fullPath="insurance_policy.others"
    //     />
    //   </div>
    // );

    return (
      <React.Fragment>
        <div className="my-0 step-names">
          <div className="step-title">
            <span>Datos del vehículo</span>
          </div>
          <div className="step-title">
            <span>Fotos del vehículo</span>
          </div>
          <div className="step-title">
            <span>Documentos</span>
          </div>
          <div className="step-title">
            <span>Accesorios</span>
          </div>
          {/* <div className="step-title">
            <span>Políticas</span>
          </div> */}
        </div>
        <div className="my-3 mx-5">
          <h1 className="my-4" style={{ textAlign: "center" }}>
            Por favor completa la siguiente información:
          </h1>
          <div className="container">
            <h4 className="mt-4">SOAP</h4>
            <div className="row">{soap_pictures}</div>
            {/* <h4 className="mt-4">Seguros para daños a terceros</h4>
            <div className="row">{third_party_insurance_pictures}</div>
            <h4 className="mt-4">Seguro de responsabilidad civil</h4>
            <div className="row">{civil_liability_insurance_pictures}</div>
            <h4 className="mt-4">Otros</h4>
            <div className="row">{others_pictures}</div> */}
          </div>
          <input
            type="file"
            id="insurance_uploader"
            style={{ display: "none" }}
            onChange={e => this.addPicture(e)}
            accept="image/jpeg, image/png, image/jpg"
            onClick={e => {
              e.target.value = null;
            }}
          />
        </div>
        <div className="footer-buttons">
          <button
            className="btn btn-prev btn-appcar-primary btn-lg pull-left"
            onClick={e => this.handlePrev(e)}
          >
            Anterior
          </button>
          <button
            className="btn btn-next btn-appcar-primary btn-lg pull-right"
            onClick={e => this.handleNext(e)}
          >
            Siguiente
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default VehicleInsurancePolicy;
