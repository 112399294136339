import React, { Component } from "react";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { SliderRail, Handle, Track, Tick } from "./SliderComponents";
import { numeral } from "../../constants";

const sliderStyle = {
  position: "relative",
  width: "100%",
  pointerEvents: "all"
};

class RangeSlider extends Component {
  state = {
    values: this.props.defaultValues.slice(),
    update: this.props.defaultValues.slice()
  };

  onUpdate = update => {
    this.props.onUpdate(update)
  };

  onChange = values => {
    this.props.onChange(values);
  };

  render() {
    const { domain, step, values } = { ...this.props };
    return (
      <div style={{ width: "90%", margin: "auto" }}>
        <Slider
          mode={2}
          step={step}
          domain={domain}
          rootStyle={sliderStyle}
          onUpdate={this.onUpdate}
          onChange={this.onChange}
          values={values}
        >
          <Rail>
            {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
          </Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className="slider-handles">
                {handles.map(handle => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    domain={domain}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
        </Slider>
      </div>
    );
  }
}

export default RangeSlider;
