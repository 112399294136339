import React, { Component } from "react";
import SelectWithRadio from "../../common/components/select/SelectWithRadio";
import ToolTip, { LoadToolTips } from "../../common/components/ToolTip";
import { constants, formateaRut, validateRUT } from "../../common/constants";
import iconInfo from "images/Icons/ico-info.png";

export default class UserCreateForm extends Component {
  state = {
    email: "",
    name: "",
    lastName: "",
    phoneNumber: "",
    idNumber: "",
    documentType: null,
    password: "",
    idNumberError: "",
    needInvoice: false,
    acceptTermsAndConditions: false,
    billingInfo: {
      rut: "",
      name: "",
      address: "",
      city: "",
      municipality: "",
      phone: "",
      business: "",
      email: "",
    },
  };

  handleBlurIdNumber = () => {
    const { idNumber, documentType } = this.state;
    if (documentType?.id == 1 && !!idNumber) {
      if (validateRUT(idNumber)) {
        const formatedRut = formateaRut(idNumber);
        this.setState((state) => {
          if (state.needInvoice) {
            state.billingInfo.rut = formatedRut;
          }
          return {
            idNumber: formatedRut,
            idNumberError: "",
            billingInfo: state.billingInfo,
          };
        });
      } else {
        this.setState({ idNumberError: "El RUT es inválido" });
      }
    } else {
      this.setState({ idNumberError: "" });
    }
  };

  handleChangeNeedInvoice = (checked) => {
    this.setState((state) => {
      state.needInvoice = checked;
      state.billingInfo.rut = state.idNumber;
      return { needInvoice: state.needInvoice, billingInfo: state.billingInfo };
    });
  };

  validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  validate = () => {
    const {
      name,
      lastName,
      email,
      phoneNumber,
      idNumber,
      password,
      acceptTermsAndConditions,
      documentType,
    } = this.state;
    return (
      name &&
      lastName &&
      this.validateEmail(email) &&
      phoneNumber &&
      idNumber &&
      password &&
      acceptTermsAndConditions &&
      documentType?.description
    );
  };

  componentDidMount() {
    LoadToolTips();
  }

  render() {
    const {
      email,
      name,
      lastName,
      phoneNumber,
      documentType,
      idNumber,
      password,
      idNumberError,
      billingInfo,
      needInvoice,
      acceptTermsAndConditions,
    } = this.state;
    const documentTypesOptions = this.props.documentTypes.map((d) => ({
      label: d.description,
      value: d.id,
    }));

    const selectedDocument = {
      value: documentType?.id,
      label: documentType?.description,
    };

    const needInvoiceContainer = (
      <div className="form-group mt-2 d-flex align-items-center justify-content-between">
        <div className="form-check form-check-user-form">
          <input
            type="checkbox"
            className="form-check-input mr-2"
            id="needInvoice"
            name="needInvoice"
            checked={needInvoice}
            onChange={(e) => this.handleChangeNeedInvoice(e.target.checked)}
          />
          <label
            className="form-check-label text-black fs-12 fw-400"
            htmlFor="needInvoice"
          >
            Necesito factura
          </label>
        </div>
        <ToolTip
          title="Necesito factura"
          tooltipHTML="Más adelante se le pedirán los datos, luego de solicitar tu reserva"
          containerHTML={
            <img src={iconInfo} width={20} className="float-end" />
          }
          id="ttip_need_invoice"
        />
      </div>
    );

    return (
      <>
        <div className="form-group">
          <label className="label-inside">Tu email</label>
          <input
            type="email"
            className="form-control appcar-input-light"
            value={email}
            onChange={(e) => this.setState({ email: e.target.value })}
            name="owner[email]"
          />
        </div>
        <div className="form-group">
          <label className="label-inside">Tu nombre</label>
          <input
            type="text"
            className="form-control appcar-input-light"
            value={name}
            onChange={(e) => this.setState({ name: e.target.value })}
            name="owner[name]"
          />
        </div>
        <div className="form-group">
          <label className="label-inside">Tus apellidos</label>
          <input
            type="text"
            className="form-control appcar-input-light"
            value={lastName}
            onChange={(e) => this.setState({ lastName: e.target.value })}
            name="owner[last_name]"
          />
        </div>
        <div className="form-group">
          <label className="label-inside">Tu teléfono</label>
          <input
            type="text"
            className="form-control appcar-input-light"
            value={phoneNumber}
            onChange={(e) => this.setState({ phoneNumber: e.target.value })}
            name="owner[phone_number]"
          />
        </div>
        <div className="form-group">
          <label className="label-inside">Tipo de documento</label>
          <SelectWithRadio
            value={selectedDocument}
            options={documentTypesOptions}
            placeholder="Selecciona"
            noOptionsMessage={() => "No hay tipos de documento"}
            onChange={(opt) => {
              this.setState(
                {
                  documentType: { id: opt.value, description: opt.label },
                },
                () => this.handleBlurIdNumber()
              );
            }}
            classNamePrefix="RS-FIX"
            className="react-select-fix appcar-input-light"
          />
          <input
            type="hidden"
            name="owner[document_type][id]"
            value={documentType?.id}
          />
          <input
            type="hidden"
            name="owner[document_type][description]"
            value={documentType?.description}
          />
        </div>
        <div className="form-group">
          <label className="label-inside">
            {" "}
            Número de{" "}
            {documentType?.description === constants.RUT ? "RUT" : "documento"}
          </label>
          <input
            type="test"
            className="form-control appcar-input-light"
            value={idNumber}
            onChange={(e) => this.setState({ idNumber: e.target.value })}
            onBlur={() => this.handleBlurIdNumber()}
            name="owner[id_number]"
          />
          {idNumberError ? (
            <span className="fs-8 text-appcar-primary">{idNumberError}</span>
          ) : null}
        </div>
        <input
          type="hidden"
          name="owner[billing_info][rut]"
          value={billingInfo.rut}
        />
        <input
          type="hidden"
          name="owner[billing_info][name]"
          value={billingInfo.name}
        />
        <input
          type="hidden"
          name="owner[billing_info][address]"
          value={billingInfo.address}
        />
        <input
          type="hidden"
          name="owner[billing_info][city]"
          value={billingInfo.city}
        />
        <input
          type="hidden"
          name="owner[billing_info][municipality]"
          value={billingInfo.municipality}
        />
        <input
          type="hidden"
          name="owner[billing_info][phone]"
          value={billingInfo.phone}
        />
        <input
          type="hidden"
          name="owner[billing_info][business]"
          value={billingInfo.business}
        />
        <input
          type="hidden"
          name="owner[billing_info][email]"
          value={billingInfo.email}
        />
        {/* {needInvoiceContainer} */}
        <div className="form-group">
          <label className="label-inside">Crea una contraseña</label>
          <input
            type="password"
            id="owner_password"
            className="form-control appcar-input-light"
            value={password}
            onChange={(e) => this.setState({ password: e.target.value })}
            name="owner[password]"
          />
        </div>
        <div className="form-group mt-2 d-flex align-items-center justify-content-between">
          <div className="form-check form-check-user-form">
            <input
              type="checkbox"
              className="form-check-input mr-2"
              id="termsAndConditionsCheck"
              name="termsAndConditionsCheck"
              checked={acceptTermsAndConditions}
              onChange={(e) =>
                this.setState({ acceptTermsAndConditions: e.target.checked })
              }
            />
            <label
              className="form-check-label text-black fs-12 fw-700"
              htmlFor="termsAndConditionsCheck"
            >
              Acepto los{" "}
              <a
                href="/legal/terms_and_conditions"
                target="_blank"
                rel="noreferrer noopener"
                className="fw-900 text-appcar-primary"
              >
                Términos y condiciones
              </a>
            </label>
          </div>
        </div>
        <div className="form-group my-3 text-center btn-container">
          <input
            type="submit"
            className="btn btn-appcar-light login-btn"
            name="commit"
            value="Regístrame"
            data-disable-with="Regístrame"
            disabled={!this.validate()}
          />
        </div>
      </>
    );
  }
}
