import React, { Component, Fragment } from "react";
import Spinner from "../../../common/Spinner";
import FlashMessage from "../../../common/FlashMessage";
import PricingDetails from "../../../common/PricingDetails";
import axios from "axios";
import { AxiosApplicationInterceptor } from "../../../common/axios_interceptor";
axios.interceptors.response.use(undefined, AxiosApplicationInterceptor);

class EstimatePrice extends Component {
  state = {
    isSubmitting: false,
    price: {
      total: 0,
      details: [],
      extra_charges: [],
      commission: 0,
      insurance_charge: 0,
      payment_method_fee: 0,
      value_added_tax: 0,
      pick_up_charge: null,
      drop_off_charge: null
    },
    showPricingDetails: false,
    dateFrom: "",
    dateUntil: "",
    ui: {
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined
    }
  };

  componentDidMount() {
    const this_ = this;
    $("#estimate_base_price").click(function() {
      const from = moment()
        .endOf("week")
        .add(1, "d")
        .format();
      const to = moment()
        .endOf("week")
        .add(2, "d")
        .format();
      this_.setState({ dateFrom: from, dateUntil: to }, () =>
        this_.estimate_price(from, to)
      );
    });

    $("#estimate_weekend_price").click(function() {
      const from = moment()
        .endOf("week")
        .add(-1, "day")
        .format();
      const to = moment()
        .endOf("week")
        .format();
      this_.setState({ dateFrom: from, dateUntil: to }, () =>
        this_.estimate_price(from, to)
      );
    });
  }

  estimate_price = (start, end) => {
    this.setState({ isSubmitting: true });
    const vehicle_appraisal = $("#vehicle_value").val();
    const daily = ((vehicle_appraisal * 0.085) / 30) * 0.75;
    const base_price = $("#base_price").val();
    const weekend_price = $("#weekend_price").val();
    const base_value = !!base_price ? base_price : daily;
    const weekend_value = !!weekend_price ? weekend_price : daily * 1.2;
    if (!!vehicle_appraisal) {
      axios({
        method: "POST",
        url: `/rentals/${this.props.rentalId}/estimate_price.json`,
        responseType: "json",
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          estimate_price: {
            weekend_value,
            base_value,
            vehicle_appraisal,
            start,
            end
          }
        }
      })
        .then(response => {
          if (response.data.success) {
            const price = response.data.price;
            this.setState({
              price,
              showPricingDetails: true,
              showMessage: false
            });
          } else {
            this.showMessage({
              showMessage: true,
              messageType: "alert-warning",
              messages: response.data.messages || [
                "Ha ocurrido un error inesperado"
              ],
              messageTitle: "Alerta"
            });
          }
        })
        .catch(error => {
          this.showMessage({
            showMessage: true,
            messageType: "alert-danger",
            messages: ["Ha ocurrido un error inesperado"],
            messageTitle: "Error"
          });
        })
        .finally(() => {
          this.setState({ isSubmitting: false });
        });
    } else {
      this.setState({ isSubmitting: false });
      this.showMessage({
        showMessage: true,
        messageType: "alert-warning",
        messages: ["Ingresa el valor aproximado del vehículo"],
        messageTitle: "Alerta"
      });
    }
  };

  showMessage = msg => {
    this.setState(state => {
      state.ui.showMessage = msg.showMessage;
      state.ui.messageType = msg.messageType;
      state.ui.messages = msg.messages;
      state.ui.messageTitle = msg.messageTitle;
      return { ui: state.ui };
    });
  };

  render() {
    const {
      showPricingDetails,
      dateFrom,
      dateUntil,
      price,
      isSubmitting
    } = this.state;
    const pricingDetails = showPricingDetails ? (
      <Fragment>
        <h5>Corresponde al valor diario aproximado por un día de arriendo.</h5>
        <PricingDetails
          price={price}
          dateFrom={dateFrom}
          dateUntil={dateUntil}
          fromEdit={true}
        />
      </Fragment>
    ) : null;
    const flash_message = this.state.ui.showMessage ? (
      <FlashMessage
        type={this.state.ui.messageType}
        msg={this.state.ui.messages}
        title={this.state.ui.messageTitle}
      />
    ) : null;
    return (
      <Fragment>
        <Spinner loading={isSubmitting} />
        <div className="my-3">
          {flash_message}
          {pricingDetails}
        </div>
      </Fragment>
    );
  }
}

export default EstimatePrice;
