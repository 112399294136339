import React, { Component, Fragment } from "react";
import Spinner from "../../../common/Spinner";
import axios from "axios";
import { DatePicker } from "../../../common/CustomInputs";
import moment from "moment";
import Alert from "../../../common/components/alert/Alert";
import { AxiosBackofficeInterceptor } from "../../../common/axios_interceptor";
import { constants, MercadoPagoPaymentStatus } from "../../../common/constants";
import Table from "../../../common/components/table/Table";
import debounce from "lodash/debounce";
import UserSearch from "../../user_search/UserSearch";
moment.locale("es");

export default class PenaltiesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form_data: {
        from: null,
        until: null,
        userId: undefined,
      },
      user: undefined,
      search_filter: "",
      penalties: this.props.penalties,
      isSubmitting: false,
      alert: {
        showMessage: false,
        messageType: undefined,
        messages: [],
        messageTitle: undefined,
      },
      search_info: {
        has_searched: false,
        from: "",
        until: "",
        user: "",
      },
      new_user: "",
      orderStatus: {
        name: "id",
        direction: "desc",
      },
      paginationInformation: this.props.paginationInformation || {
        limit: constants.DEFAULT_RESULTS_PER_PAGE,
        last: 0,
        total: this.props.penalties.length,
      },
      currentPage: 1,
      lastIds: [this.props.paginationInformation.last],
    };
    this.willUnmount = false;
    this.cancelToken = null;
    this.processRunning = 0;
    this.handleSubmit = debounce(this.handleSubmit, 400, { leading: true });
  }

  componentWillUnmount() {
    this.willUnmount = true;
  }

  componentDidMount() {
    const this_ = this;
    this_.willUnmount = false;
    this.cancelToken = axios.CancelToken.source();
    axios.interceptors.response.use(undefined, AxiosBackofficeInterceptor);
    const options = {
      showDropdowns: true,
      minYear: 2018,
      maxYear: constants.MAX_YEAR,
      autoUpdateInput: false,
      locale: {
        format: "DD/MM/YYYY",
        cancelLabel: "Limpiar",
        applyLabel: "Aplicar",
        fromLabel: "Desde",
        toLabel: "Hasta",
        daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        firstDay: 1,
      },
    };

    $("#search_dates").on("cancel.daterangepicker", function(ev, picker) {
      picker.setStartDate(moment());
      picker.setEndDate(moment());
      this_.setState((state) => {
        state.form_data.from = null;
        state.form_data.until = null;
        return { form_data: state.form_data };
      });
    });

    $("#search_dates").daterangepicker(options, (start, end) => {
      let from = moment(start)
        .startOf("day")
        .format();
      let to = moment(end)
        .endOf("day")
        .format();
      this.setState((state) => {
        state.alert.showMessage = false;
        state.form_data.from = from;
        state.form_data.until = to;
        return { form_data: state.form_data, alert: state.alert };
      });
    });
  }

  showMessage = (messageType, messageTitle, messages) =>
    this.setState((state) => {
      state.alert.showMessage = true;
      state.alert.messageTitle = messageTitle;
      state.alert.messages = messages;
      state.alert.messageType = messageType;
      return { alert: state.alert };
    });

  handleAlertClose = () =>
    this.setState((state) => {
      state.alert.showMessage = false;
      state.alert.messageTitle = undefined;
      state.alert.messages = [];
      state.alert.messageType = undefined;
      return { alert: state.alert };
    });

  handleSubmit = (e = null, direction = "after", resetPagination = false) => {
    if (e) e.preventDefault();
    this.processRunning += 1;
    this.setState({ isSubmitting: true });
    const {
      form_data,
      paginationInformation,
      search_filter,
      user,
    } = this.state;
    const fullName = !!user ? user.name + " " + user.last_name : "";
    let search_info = {
      has_searched: true,
      user: fullName,
    };
    const from = moment(form_data.from);
    let fromParam = "";
    let untilParam = "";
    let last = paginationInformation.last;
    let lastIds = this.state.lastIds;
    if (direction == "before") {
      lastIds.pop();
      lastIds.pop();
      last = lastIds.slice(this.state.lastIds.length - 1);
    }
    if (resetPagination) {
      lastIds = [];
      last = 0;
    }
    let paginationParams = `limit=${paginationInformation.limit}`;
    if (!resetPagination) paginationParams += `&last=${last}`;
    if (from.isValid()) {
      search_info.from = from.format("DD/MM/YYYY");
      fromParam = `&from=${encodeURIComponent(
        from.startOf("d").format("YYYY-MM-DDTHH:mm:ssZ")
      )}`;
    }
    const until = moment(form_data.until);
    if (until.isValid()) {
      search_info.until = until.format("DD/MM/YYYY");
      untilParam = `&until=${encodeURIComponent(
        until.endOf("d").format("YYYY-MM-DDTHH:mm:ssZ")
      )}`;
    }
    const userParam = form_data.userId ? `&user_id=${form_data.userId}` : "";
    const filterParam = search_filter ? `&filter=${search_filter}` : "";

    axios({
      method: "get",
      url: `/backoffice/penalties.json?${paginationParams}${fromParam}${untilParam}${userParam}${filterParam}`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        const penalties = response.data.penalties;
        const paginationInformation = response.data.paginationInformation;
        lastIds.push(paginationInformation.last);
        if (!this.willUnmount)
          this.setState({
            penalties,
            search_info,
            paginationInformation,
            lastIds,
          });
      })
      .catch((error) =>
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ])
      )
      .then(() => {
        this.processRunning -= 1;
        if (this.processRunning == 0) {
          this.setState({ isSubmitting: false });
        }
      });
  };

  selectUser = (user) => {
    this.setState(
      (state) => {
        state.form_data.userId = user ? user.id : undefined;
        return { form_data: state.form_data, user };
      },
      () => this.handleSubmit(null, "last", true)
    );
  };

  onSearchChange = (search_filter) => {
    this.setState({ search_filter, currentPage: 1 }, () => {
      this.handleSubmit(null, "last", true);
    });
  };

  onLimitChange = (limit) => {
    this.setState(
      (state) => {
        state.paginationInformation.limit = limit;
        return {
          paginationInformation: state.paginationInformation,
          currentPage: 1,
        };
      },
      () => {
        this.handleSubmit(null, "last", true);
      }
    );
  };

  onPageChange = (currentPage, direction) => {
    this.setState({ currentPage }, () => {
      this.handleSubmit(null, direction);
    });
  };

  onOrderChange = (orderStatus) => {
    this.setState({ orderStatus }, () => {
      // TODO: Acá se debe llamar la función para buscar resultados
    });
  };

  render() {
    const {
      penalties,
      form_data,
      search_info,
      orderStatus,
      paginationInformation,
      currentPage,
      alert,
      user,
    } = this.state;
    const { messageTitle, messageType, messages, showMessage } = alert;
    const penaltyMotives = this.props.penaltyMotives;

    const rows = penalties.sort((a, b) => {
      // Ordenamiento por id
      const aId = parseInt(a.id);
      const bId = parseInt(b.id);

      // Mayor a menor (Más futuro a más pasado)
      return bId - aId;
    });

    const { total } = paginationInformation;

    const showingText = `Mostrando ${rows.length} resultados de un total de ${total}`;
    let userText = "";
    let datesText = "";
    if (search_info.user) {
      userText = `, del usuario ${search_info.user}`;
    }
    if (search_info.from) {
      datesText = `, entre fechas ${search_info.from} y ${search_info.until}`;
    }

    const searchInfo = (
      <div className="alert light mw-100">
        {showingText}
        {userText}
        {datesText}
      </div>
    );

    let inputDatesText = "";
    const from = moment(form_data.from);
    const until = moment(form_data.until);
    if (from.isValid()) {
      inputDatesText = from.format("LL") + " - " + until.format("LL");
    }

    const columnDefinitions = [
      {
        name: "id",
        label: "ID",
        orderable: false,
      },
      {
        name: "user",
        label: "Usuario",
        orderable: false,
      },
      {
        name: "motive",
        label: "Motivo",
        orderable: false,
      },
      {
        name: "amount",
        label: "Monto de multa",
        orderable: false,
      },
      {
        name: "paid",
        label: "Pagada",
        orderable: false,
      },
      {
        name: "comment",
        label: "Notas",
        orderable: false,
      },
      {
        name: "date",
        label: "Fecha de multa",
        orderable: false,
      },
      {
        name: "actions",
        label: "",
        orderable: false,
      },
    ];

    const data = rows.map((penalties) => {
      const user = penalties.user;
      const oneclickTransaction = penalties.payment
        ? penalties.payment.oneclick_transaction
        : penalties.oneclick_transaction || {};
      const mercadoPagoTransaction = penalties.payment
        ? penalties.payment.mercado_pago
        : penalties.payment_mercado_pago || {};
      let penalties_is_paid = false;
      if (
        oneclickTransaction &&
        oneclickTransaction.capture &&
        oneclickTransaction.capture.amount > 0
      ) {
        penalties_is_paid = true;
      } else if (
        mercadoPagoTransaction &&
        mercadoPagoTransaction.status == MercadoPagoPaymentStatus.APPROVED
      ) {
        penalties_is_paid = true;
      }
      const image_icon = (
        <i
          className={
            penalties_is_paid
              ? "mdi mdi-checkbox-marked-circle-outline text-success"
              : "mdi mdi-checkbox-blank-circle-outline text-danger"
          }
        />
      );
      return {
        id: penalties.id,
        user: user.name + " " + user.last_name,
        motive: penalties.motive.name,
        amount: penalties.amount,
        paid: image_icon,
        comment: penalties.comment,
        date: moment(penalties.date).format("DD/MM/Y HH:mm"),
        actions: (
          <a
            href={`/backoffice/penalties/${penalties.id}`}
            className="btn btn-appcar-primary btn-sm"
            rel="noreferrer noopener"
            target="_blank"
          >
            Detalle
          </a>
        ),
      };
    });
    const table = (
      <Table
        id="table-guarantees"
        noResults={
          this.state.isSubmitting
            ? "Realizando Búsqueda"
            : "No hay resultados para mostrar..."
        }
        columns={columnDefinitions}
        data={data}
        hasFooter={true}
        tableClass="table dataTable table-striped"
        searchable={true}
        onSearchChange={this.onSearchChange}
        orderStatus={orderStatus}
        paginationInformation={paginationInformation}
        onLimitChange={this.onLimitChange}
        onPageChange={this.onPageChange}
        onOrderChange={this.onOrderChange}
        currentPage={currentPage}
        showPageSelector={false}
      />
    );
    const filters = (
      <div className="row my-3 align-items-end">
        <div className="col-sm-12 col-md">
          <DatePicker
            id="search_dates"
            label="Selecciona rango de fechas"
            onChange={(e) => this.changeFecha(e.target.value)}
            name="search_dates"
            disabled={true}
            classNameInput="form-control appcar-input"
            value={inputDatesText}
          />
        </div>
        <div className="col">
          <div className="form-group">
            <label className="label-inside">Buscar usuario</label>
            <UserSearch
              user={user}
              selectUser={this.selectUser}
              hasLabel={true}
              colClass="col-lg-12"
              clearable={true}
            />
          </div>
        </div>

        <div className="col-auto pl-md-0 pr-1">
          <button
            className="btn btn-appcar-primary btn-sm py-0 mb-1"
            onClick={() => this.handleSubmit(null, "last", true)}
          >
            <i className="mdi mdi-chevron-right fs-22" />
          </button>
        </div>
      </div>
    );
    return (
      <Fragment>
        <Spinner loading={this.state.isSubmitting} />
        <Alert
          alertClass={messageType}
          isShowing={showMessage}
          messages={messages}
          title={messageTitle}
          onClose={this.handleAlertClose}
        />
        {filters}
        {searchInfo}
        <div className="mb-3">{table}</div>
      </Fragment>
    );
  }
}
