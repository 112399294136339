import React, { Component, Fragment } from "react";
import Spinner from "../../../common/Spinner";
import axios from "axios";
import Select from "react-select";
import FlashMessage from "../../../common/FlashMessage";
import { AxiosBackofficeInterceptor } from "../../../common/axios_interceptor";
import { constants } from "../../../common/constants";
import Table from "../../../common/components/table/Table";
import debounce from "lodash/debounce";
import Alert from "../../../common/components/alert/Alert";

class VehicleList extends Component {
  state = {
    state: "",
    filter: "",
    vehicles: this.props.vehicles,
    isSubmitting: false,
    alert: {
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined,
    },
    search_info: {
      state: "",
    },
    orderStatus: {
      name: "id",
      direction: "desc",
    },
    paginationInformation: this.props.paginationInformation || {
      limit: constants.DEFAULT_RESULTS_PER_PAGE,
      last: 0,
      total: this.props.vehicles.length,
    },
    currentPage: 1,
    lastIds: [this.props.paginationInformation.last],
  };
  willUnmount = false;
  cancelToken = null;
  processRunning = 0;

  componentWillUnmount() {
    this.willUnmount = true;
  }

  componentDidMount() {
    this.handleSubmit = debounce(this.handleSubmit, 400, { leading: true });
    const this_ = this;
    this_.willUnmount = false;
    this.cancelToken = axios.CancelToken.source();
    axios.interceptors.response.use(undefined, AxiosBackofficeInterceptor);
  }

  showMessage = (messageType, messageTitle, messages) =>
    this.setState((state) => {
      state.alert.showMessage = true;
      state.alert.messageTitle = messageTitle;
      state.alert.messages = messages;
      state.alert.messageType = messageType;
      return { alert: state.alert };
    },
    () => setTimeout(this.handleAlertClose, 3000)
  );

  handleAlertClose = () =>
    this.setState(state => {
      state.alert.showMessage = false;
      return { alert: state.alert };
    });

  handleSubmit = (e = null, direction = "after", resetPagination = false) => {
    if (e) e.preventDefault();
    this.processRunning += 1;
    this.setState({ isSubmitting: true });
    const { state, paginationInformation, filter } = this.state;
    let search_info = {
      state: state || "",
    };
    let last = paginationInformation.last;
    let lastIds = this.state.lastIds;
    if (direction == "before") {
      lastIds.pop();
      lastIds.pop();
      last = lastIds.slice(this.state.lastIds.length - 1);
    }
    if (resetPagination) {
      lastIds = [];
      last = 0;
    }
    let paginationParams = `limit=${paginationInformation.limit}`;
    if (!resetPagination) paginationParams += `&last=${last}`;
    const stateParams = state || state === 0 ? `&state=${state}` : "";
    const filterParam = filter ? `&filter=${filter}` : "";

    axios({
      method: "get",
      url: `/backoffice/vehicles.json?${paginationParams}${stateParams}${filterParam}`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        const vehicles = response.data.vehicles;
        const paginationInformation = response.data.paginationInformation;
        lastIds.push(paginationInformation.last);
        if (!this.willUnmount)
          this.setState({ vehicles, search_info, paginationInformation, lastIds, });
      })
      .catch((error) => {
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ]);
      })
      .then(() => {
        this.processRunning -= 1;
        if (this.processRunning == 0) {
          this.setState({ isSubmitting: false });
        }
      });
  };

  handleChangeState = (option) => {
    const state = option ? option.value : "";
    this.setState({ state, currentPage: 1 }, () => {
      this.handleSubmit(null, "last", true);
    });
  };

  onSearchChange = (filter) => {
    this.setState({ filter, currentPage: 1 }, () => {
       this.handleSubmit(null, "last", true);
    });
  };

  onLimitChange = (limit) => {
    this.setState(
      (state) => {
        state.paginationInformation.limit = limit;
        return { paginationInformation: state.paginationInformation, currentPage: 1, };
      },
      () => {
        this.handleSubmit(null, "last", true);
      }
    );
  };

  onPageChange = (currentPage, direction) => {
    this.setState({ currentPage }, () => {
      this.handleSubmit(null, direction);
    });
  };

  onOrderChange = (orderStatus) => {
    this.setState({ orderStatus }, () => {
      // TODO: Acá se debe llamar la función para buscar resultados
    });
  };

  render() {
    moment.locale("es");

    const {
      vehicles,
      search_info,
      orderStatus,
      paginationInformation,
      currentPage,
      state,
      alert,
    } = this.state;
    const vehicleStates = this.props.vehicleStates;
    const { messageTitle, messageType, messages, showMessage } = alert;

    let rows = vehicles.sort((a, b) => {
      // Ordenamiento por id
      const aId = parseInt(a.id);
      const bId = parseInt(b.id);

      // Mayor a menor (Más futuro a más pasado)
      return bId - aId;
    });

    const { last, limit, total } = paginationInformation;

    const searchInfo = (
      <div className="alert light mw-100">
        {`Mostrando ${rows.length} resultados de un total de ${total}`}
        {search_info.state !== ""
          ? ` con estado ${
              vehicleStates.find((x) => x.id == search_info.state).description
            }`
          : ""}
      </div>
    );

    const selected_state = vehicleStates.find(
      (state) => state.id === this.state.state
    );
    const selected_option_state = selected_state
      ? {
          value: selected_state.id,
          label: selected_state.description,
        }
      : undefined;
    const state_options = vehicleStates.map((state) => {
      return {
        value: state.id,
        label: state.description,
      };
    });

    const columnDefinitions = [
      {
        name: "id",
        label: "ID",
        orderable: false,
      },
      {
        name: "type",
        label: "Marca/modelo/año",
        orderable: false,
      },
      {
        name: "plate_number",
        label: "Patente",
        orderable: false,
      },
      {
        name: "owner",
        label: "Dueño",
        orderable: false,
      },
      {
        name: "vehicle_state",
        label: "Estado del vehículo",
        orderable: false,
      },
      {
        name: "owner_state",
        label: "Estado del dueño",
        orderable: false,
      },
      {
        name: "actions",
        label: "",
        orderable: false,
      },
    ];

    const data = rows
      .map((vehicle) => {
        vehicle.vehicle_type ??= {
          brand: "-",
          model: "-",
        };
        let owner = vehicle.associated_users[0];
        owner.state ??= {
          id: "-",
          description: "-",
        };
        vehicle.vehicle_state ??= {
          id: "-",
          description: "-",
        };
        const rentalLink = (
          <a
            href={`/backoffice/vehicles/${vehicle.id}/rental`}
            className="btn btn-appcar-secondary btn-sm"
            target="_blank"
            rel="noopener noreferrer"
          >
            Ver publicación
          </a>
        );

        const vehiclelink =
          owner.state.id == 2 ? (
            <a
              href={`/backoffice/vehicles/${vehicle.id}`}
              className="btn btn-appcar-primary btn-sm mr-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              Detalles
            </a>
          ) : (
            <button
              type="button"
              className="btn btn-appcar-primary btn-sm disabled mr-2"
              disabled
            >
              Detalle
            </button>
          );

        return {
          id: vehicle.id,
          type:
            vehicle.vehicle_type.brand +
            " " +
            vehicle.vehicle_type.model +
            " | " +
            vehicle.year,
          plate_number: vehicle.plate_number,
          owner: owner.name + " " + owner.last_name,
          vehicle_state: vehicle.vehicle_state.description,
          owner_state: owner.state.description,
          actions: (
            <>
              {vehiclelink}
              {rentalLink}
            </>
          ),
        };
      })
      // AM1-T562: Ya no se filtran los datos visibles, el endpoint debería hacer el filtro ahora.
      // .filter((v) => {
      //   const searchFilter = this.state.filter.toLowerCase();
      //   const plateNumber = v.plate_number.toLowerCase();
      //   const id = v.id.toString().toLowerCase();
      //   const type = v.type.toLowerCase();
      //   const owner = v.owner.toLowerCase();
      //   if (!!searchFilter)
      //     return (
      //       plateNumber.includes(searchFilter) ||
      //       id.includes(searchFilter) ||
      //       type.includes(searchFilter) ||
      //       owner.includes(searchFilter)
      //     );
      //   else return true;
      // });
    const table = (
      <Table
        id="table-vehicles"
        noResults={
          this.state.isSubmitting
            ? "Realizando Búsqueda"
            : "No hay resultados para mostrar..."
        }
        columns={columnDefinitions}
        data={data}
        hasFooter={true}
        tableClass="table dataTable table-striped"
        searchable={true}
        onSearchChange={this.onSearchChange}
        orderStatus={orderStatus}
        paginationInformation={paginationInformation}
        onLimitChange={this.onLimitChange}
        onPageChange={this.onPageChange}
        onOrderChange={this.onOrderChange}
        currentPage={currentPage}
        showPageSelector={false}
      />
    );
    const stateParams = state ? `&state=${state}` : "";
    return (
      <Fragment>
        <Spinner loading={this.state.isSubmitting} />
        <Alert
          alertClass={messageType}
          isShowing={showMessage}
          messages={messages}
          title={messageTitle}
          onClose={this.handleAlertClose}
        />
        <div className="row my-3 align-items-end">
          <div className="col">
            <div className="form-group">
              <label>Selecciona el estado</label>
              <Select
                value={selected_option_state}
                options={state_options}
                placeholder="Selecciona..."
                noOptionsMessage={() => "No existen estados aún..."}
                onChange={this.handleChangeState}
                classNamePrefix="RS-FIX"
                className="react-select-fix"
                isClearable={true}
              />
            </div>
          </div>

          <div className="col-auto">
            <button
              className="btn btn-appcar-primary btn-sm py-0 mb-1"
              onClick={() => this.handleSubmit(null, "last", true)}
            >
              <i className="mdi mdi-chevron-right fs-22" />
            </button>
          </div>
        </div>
        {
          // MA1-T403 Comentar botón "Exportar a excel" en tablas de backoffice
          /* <div className="row justify-content-end my-2">
          <div className="col-auto">
            <a
              href={`/backoffice/vehicles/export_to_xlsx?${stateParams}`}
              className="btn btn-success"
            >
              <i className="mdi mdi-microsoft-excel"></i> Exportar a excel
            </a>
          </div>
        </div> */
        }
        {searchInfo}
        <div className="mb-3">{table}</div>
      </Fragment>
    );
  }
}

export default VehicleList;
