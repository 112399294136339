import React, { Component } from "react";
import { Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import Spinner from "../../common/Spinner";
import FlashMessage from "../../common/FlashMessage";
import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import Select from "react-select";
import { AxiosBackofficeInterceptor } from "../../common/axios_interceptor";

const BackofficeUsersSchema = isEditing =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("Campo requerido"),
    last_name: Yup.string()
      .trim()
      .required("Campo requerido"),
    second_last_name: Yup.string(),
    nickname: Yup.string()
      .trim()
      .required("Campo requerido"),
    email: Yup.string()
      .trim()
      .email("Debe ser formato email")
      .required("Campo requerido"),
    password: !isEditing
      ? Yup.string()
          .trim()
          .required("Campo requerido")
      : Yup.string(),
    type: Yup.string()
      .trim()
      .required("Campo requerido")
  });

class BackofficeUsersForm extends Component {
  state = {
    data: this.props.backofficeUser || {
      name: "",
      last_name: "",
      second_last_name: "",
      nickname: "",
      email: "",
      password: "",
      type: ""
    },
    ui: {
      isSubmitting: false,
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined
    },
    type_options: [
      { value: "admin", label: "Administrador" },
      { value: "sura", label: "Sura" }
    ]
  };
  tp = this.props.type;

  componentDidMount() {
    // axios.interceptors.response.use(undefined, AxiosBackofficeInterceptor);
    // if (this.props.backofficeUserId) {
    //   this.setState(state => {
    //     state.ui.isSubmitting = true;
    //     return { ui: state.ui };
    //   });
    //   axios({
    //     method: "get",
    //     url: `/backoffice/bo_user/${this.props.backofficeUserId}/edit.json`,
    //     responseType: "json",
    //     headers: {
    //       "Content-Type": "application/json"
    //     }
    //   })
    //     .then(response => {
    //       this.setState({ data: response.data });
    //     })
    //     .catch(error => {
    //       this.showMessage({
    //         showMessage: true,
    //         messageType: "alert-danger",
    //         messages: ["Ha ocurrido un error inesperado"],
    //         messageTitle: "Error"
    //       });
    //     })
    //     .then(() => {
    //       this.setState(state => {
    //         state.ui.isSubmitting = false;
    //         return { ui: state.ui };
    //       });
    //     });
    // }
  }

  showMessage = msg => {
    this.setState(state => {
      state.ui.showMessage = msg.showMessage;
      state.ui.messageType = msg.messageType;
      state.ui.messages = msg.messages;
      state.ui.messageTitle = msg.messageTitle;
      return { ui: state.ui };
    });
  };

  render() {
    const flash_message = this.state.ui.showMessage ? (
      <FlashMessage
        type={this.state.ui.messageType}
        msg={this.state.ui.messages}
        title={this.state.ui.messageTitle}
      />
    ) : null;
    return (
      <React.Fragment>
        {flash_message}
        <Formik
          showMessage={this.showMessage}
          enableReinitialize={true}
          initialValues={this.state.data}
          validationSchema={BackofficeUsersSchema(
            !!this.props.backofficeUser && !!this.props.backofficeUser.id
          )}
          onSubmit={(values, { setSubmitting }) => {
            // const token = document.getElementsByName("csrf-token")[0].content;
            console.log(this.props.backofficeUserId);
            let bo_user = cloneDeep(values);
            const token = null;
            let url = "";
            let method = "";
            if (this.props.backofficeUser && this.props.backofficeUser.id) {
              if (!bo_user.password) {
                delete bo_user.password;
              }
              url = `/backoffice/bo_user/${this.props.backofficeUser.id}.json`;
              method = "PUT";
              bo_user.id = parseFloat(bo_user.id);
            } else {
              url = `/backoffice/bo_user.json`;
              method = "POST";
            }
            axios(url, {
              method,
              url,
              responseType: "json",
              headers: {
                "Content-Type": "application/json"
              },
              data: { bo_user }
            })
              .then(response => {
                if (response.data.success) {
                  Turbolinks.visit(`/backoffice/portada/index?success=1`);
                } else {
                  this.showMessage({
                    showMessage: true,
                    messageType: "alert-warning",
                    messages: response.data.messages || [
                      "Ha ocurrido un error inesperado"
                    ],
                    messageTitle: "Alerta"
                  });
                }
              })
              .catch(error =>
                this.showMessage({
                  showMessage: true,
                  messageType: "alert-danger",
                  messages: ["Ha ocurrido un error inesperado"],
                  messageTitle: "Error"
                })
              )
              .then(() => setSubmitting(false));
          }}
        >
          {({
            setFieldValue,
            values,
            errors,
            touched,
            dirty,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => {
            const button_label = this.props.backofficeUser
              ? "Editar"
              : "Agregar nuevo";
            const user_type =
              this.tp == "admin" ? (
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label htmlFor="type">Tipo de usuario</label>
                    <Select
                      name="type"
                      value={
                        this.state.type_options
                          ? this.state.type_options.find(
                              option => option.value === values.type
                            )
                          : ""
                      }
                      options={this.state.type_options}
                      placeholder="Selecciona..."
                      onChange={option => setFieldValue("type", option.value)}
                      classNamePrefix="RS-FIX"
                      className="react-select-fix"
                    />
                    <ErrorMessage name="type">
                      {msg => (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
              ) : null;
            return (
              <React.Fragment>
                <Spinner loading={isSubmitting || this.state.ui.isSubmitting} />
                <form onSubmit={handleSubmit} className="">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label htmlFor="name">Nombre</label>
                        <Field
                          type="text"
                          name="name"
                          className={
                            "form-control appcar-input " +
                            classNames({
                              "is-invalid": touched.name && errors.name
                            })
                          }
                        />
                        <ErrorMessage name="name">
                          {msg => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label htmlFor="last_name">Apellido paterno</label>
                        <Field
                          type="text"
                          name="last_name"
                          className={
                            "form-control appcar-input " +
                            classNames({
                              "is-invalid":
                                touched.last_name && errors.last_name
                            })
                          }
                        />
                        <ErrorMessage name="last_name">
                          {msg => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label htmlFor="second_last_name">
                          Apellido materno
                        </label>
                        <Field
                          type="text"
                          name="second_last_name"
                          className={
                            "form-control appcar-input " +
                            classNames({
                              "is-invalid":
                                touched.second_last_name &&
                                errors.second_last_name
                            })
                          }
                        />
                        <ErrorMessage name="second_last_name">
                          {msg => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label htmlFor="nickname">Nickname</label>
                        <Field
                          type="text"
                          name="nickname"
                          className={
                            "form-control appcar-input " +
                            classNames({
                              "is-invalid": touched.nickname && errors.nickname
                            })
                          }
                        />
                        <ErrorMessage name="nickname">
                          {msg => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label htmlFor="email">Email</label>
                        <Field
                          type="email"
                          name="email"
                          className={
                            "form-control appcar-input " +
                            classNames({
                              "is-invalid": touched.email && errors.email
                            })
                          }
                        />
                        <ErrorMessage name="email">
                          {msg => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                    {user_type}
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label htmlFor="password">Contraseña</label>
                        <Field
                          type="password"
                          name="password"
                          className={
                            "form-control appcar-input " +
                            classNames({
                              "is-invalid": touched.password && errors.password
                            })
                          }
                        />
                        <ErrorMessage name="password">
                          {msg => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                  <button
                    
                    className="btn btn-appcar-primary"
                    disabled={isSubmitting}
                  >
                    {`${button_label} usuario de backoffice`}
                  </button>
                </form>
              </React.Fragment>
            );
          }}
        </Formik>
      </React.Fragment>
    );
  }
}

export default BackofficeUsersForm;
