import React, { Fragment } from 'react';

const RentalSchedule = ({ rental }) => {
  const hours = rental.availability.hours;

  const days = { "mon": "Lu", "tue": "Ma", "wed": "Mi", "thu": "Ju", "fri": "Vi", "sat": "Sa", "sun": "Do" };

  function scheduleText(availability, time) {
    if (availability.no_rental) {
      return 'Reservado';
    } else if (availability.closed) {
      return '----';
    } else if (!availability[time]) {
      return hours['base'][time];
    } else {
      return availability[time]
    }
  }

  const header = Object.keys(days).map((x, i) => <th key={i}>{days[x]}</th>);
  const opening = Object.keys(days).map((x, i) => <td key={i}>{scheduleText(hours[x], "opening_time")}</td>)
  const closing = Object.keys(days).map((x, i) => <td key={i}>{scheduleText(hours[x], "closing_time")}</td>)

  const hoursTable = hours ? (
    <Fragment>
      <h5>Disponibilidad horaria</h5>
      <table className="table-availability">
        <thead>
          <tr>
            <th></th>
            {header}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Desde</td>
            {opening}
          </tr>
          <tr>
            <td>Hasta</td>
            {closing}
          </tr>
        </tbody>
      </table>
    </Fragment>
  ) : null;
  return (hoursTable);
};

export default RentalSchedule;
