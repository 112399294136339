import React, { Component } from "react";
import Stepzilla from "react-stepzilla";
import VehicleData from "./VehicleData";
import VehicleRegistrationDocument from "./VehicleRegistrationDocument";
import VehicleInsurancePolicy from "./VehicleInsurancePolicy";
import VehicleAccessories from "./VehicleAccessories";
import Spinner from "../../common/Spinner";
import FlashMessage from "../../common/FlashMessage";
import VehiclePictures from "./VehiclePictures";
import axios from "axios";
import VehiclePolicies from "./VehiclePolicies";
import Alert from "../../common/components/alert/Alert";
import { AxiosApplicationInterceptor } from "../../common/axios_interceptor";
axios.interceptors.response.use(undefined, AxiosApplicationInterceptor);

export default class VehicleWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.vehicle || {
        plate_number: "",
        year: 2000,
        engine: "",
        doors: 0,
        vehicle_category: {
          id: "",
          description: ""
        },
        vehicle_transmission: {
          id: "",
          description: ""
        },
        chassis: "",
        vehicle_type: {
          id: "",
          brand: "",
          model: ""
        },
        color: "",
        fuel_type: {
          id: "",
          description: ""
        },
        cancellation_policy: null,
        fuel_policy: null,
        capacity: "",
        efficiency: "",
        vehicle_pictures: [],
        photos: {
          front: [],
          back: [],
          left: [],
          right: [],
          inside: []
        },
        vehicle_features: [],
        registration_document: {
          vehicular_technical_revision: [],
          homologation: [],
          registration_document: []
        },
        insurance_policy: {
          soap: [],
        },
        registry: {
          name: "",
          id_number: ""
        }
      },
      vehicleId: 0,
      isSubmitting: false,
      alert: {
        showMessage: false,
        messageType: undefined,
        messages: [],
        messageTitle: undefined
      }
    };
  }

  modalNewVehicle = null;

  toDataURL = (object, index, url) =>
    fetch(url, {
      method: "GET",
      cache: "default"
    })
      .then(response => response.blob())
      .then(
        blob =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () =>
              resolve({ base_64: reader.result, object, index });
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  componentDidMount() {
    $("body").click(function (e) {
      if (!$(e.target).hasClass("anc-tooltip")) {
        $(".tooltiptext").removeClass("tooltip-visible");
      } else {
        var tooltipFor = "#" + e.target.dataset.toggleTooltipFor;
        $(".tooltiptext")
          .not($(tooltipFor))
          .removeClass("tooltip-visible");
        $(tooltipFor).toggleClass("tooltip-visible");
      }
    });

    let data = this.state.data;

    if (this.props.vehicle) {

      const vehicle = this.props.vehicle;
      data.photos = vehicle.photos || {
        front: [],
        back: [],
        left: [],
        right: [],
        inside: []
      };
      data.vehicle_pictures = vehicle.vehicle_pictures || [];
      data.registration_document = vehicle.registration_document || {
        homologation: [],
        registration_document: [],
        vehicular_technical_revision: []
      };
      data.insurance_policy = vehicle.insurance_policy || {
        soap: []
      };
      data.engine = vehicle.engine || "";
      data.efficiency = vehicle.efficiency || "";
      data.chassis = vehicle.chassis || "";

      data.photos.front = data.photos.front.map(url => ({ url }));
      data.photos.back = data.photos.back.map(url => ({ url }));
      data.photos.left = data.photos.left.map(url => ({ url }));
      data.photos.right = data.photos.right.map(url => ({ url }));
      data.photos.inside = data.photos.inside.map(url => ({ url }));
      data.vehicle_pictures = data.vehicle_pictures.map(url => ({ url }));
      data.registration_document.homologation = data.registration_document.homologation.map(url => ({ url }));
      data.registration_document.registration_document = data.registration_document.registration_document.map(url => ({ url }));
      data.registration_document.vehicular_technical_revision = data.registration_document.vehicular_technical_revision.map(url => ({ url }));
      data.insurance_policy.soap = data.insurance_policy.soap.map(url => ({ url }));

      data.vehicle_category = vehicle.vehicle_category || {
        id: "",
        description: ""
      };
      data.vehicle_transmission = vehicle.vehicle_transmission || {
        id: "",
        description: ""
      };
      data.registry = vehicle.registry || {
        name: "",
        id_number: ""
      };
      this.setState({ data });

    } else {
      const defaultFeatures = this.props.vehicleFeatures.filter(x => [18, 22].includes(x.id))
      data.vehicle_features.push(...defaultFeatures);
      this.setState({ data });
    }

    this.modalNewVehicle = new bootstrap.Modal(document.getElementById("modal-new-vehicle"))
  }

  addPicture = (picture, filename) => {
    const file = picture.split(",");
    const start = file[0];
    const image_base_64 = file[1];
    const mime = picture.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    const mime_type = mime && mime.length ? mime[1] : "";
    filename = encodeURI(filename);
    this.setState(state => {
      state.data.vehicle_pictures.push({
        filename,
        start,
        image_base_64,
        mime_type
      });
      return { data: state.data };
    });
  };

  addDocumentation = (picture, filename, full_path) => {
    const path = full_path.split(".");
    const file = picture.split(",");
    const start = file[0];
    const image_base_64 = file[1];
    const mime = picture.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    const mime_type = mime && mime.length ? mime[1] : "";
    filename = encodeURI(filename);
    this.setState(state => {
      if (path.length == 1) {
        state.data[path[0]].push({
          filename,
          start,
          image_base_64,
          mime_type
        });
      } else if (path.length == 2) {
        state.data[path[0]][path[1]].push({
          filename,
          start,
          image_base_64,
          mime_type
        });
      }
      return { data: state.data };
    });
  };

  removePicture = index => {
    this.setState(state => {
      state.data.vehicle_pictures.splice(index, 1);
      return { data: state.data };
    });
  };

  removeDocumentation = (index, full_path) => {
    const path = full_path.split(".");
    this.setState(state => {
      if (path.length == 1) {
        state.data[path[0]].splice(index, 1);
      } else if (path.length == 2) {
        state.data[path[0]][path[1]].splice(index, 1);
      }
      return { data: state.data };
    });
  };

  changeVehicleType = vehicle_type => {
    this.setState(state => {
      state.data.vehicle_type = vehicle_type;
      return { data: state.data };
    });
  };

  changeFuelType = (fuel_type) => {
    this.setState(state => {
      state.data.fuel_type = fuel_type;
      return { data: state.data };
    });
  };

  changeVehicleCategory = (vehicle_category) => {
    this.setState(state => {
      state.data.vehicle_category = vehicle_category;
      return { data: state.data };
    });
  };

  handleChange = (name, value) => {
    this.setState(state => {
      state.data[name] = value;
      return { data: state.data };
    });
  };

  addFeature = feature => {
    this.setState(state => {
      state.data.vehicle_features.push(feature);
      return { data: state.data };
    });
  };

  removeFeature = feature => {
    const index = this.state.data.vehicle_features.findIndex(
      x => x.id === feature.id
    );
    this.setState(state => {
      state.data.vehicle_features.splice(index, 1);
      return { data: state.data };
    });
  };

  selectPolicy = (policy, name) => {
    this.setState(state => {
      state.data[name] = policy;
      return { data: state.data };
    });
  };

  changeRegistry = (name, value) => {
    this.setState(state => {
      state.data.registry[name] = value;
      return { data: state.data }
    })
  }

  showMessage = (messageType, messageTitle, messages) =>
    this.setState(state => {
      state.alert.showMessage = true;
      state.alert.messageTitle = messageTitle;
      state.alert.messages = messages;
      state.alert.messageType = messageType;
      return { alert: state.alert };
    },
    () => setTimeout(this.handleAlertClose, 3000)
  );

  handleAlertClose = () =>
    this.setState(state => {
      state.alert.showMessage = false;
      return { alert: state.alert };
    });

  handleSubmit = e => {
    e.preventDefault();
    let { data } = this.state;
    this.setState({ isSubmitting: true });
    const isEdition = !!this.props.vehicle && !!this.props.vehicle.id;
    // const token = document.getElementsByName("csrf-token")[0].content;
    const url = isEdition
      ? `/vehicles/${this.props.vehicle.id}.json`
      : `/vehicles.json`;
    const method = isEdition ? "PUT" : "POST";
    axios(url, {
      method,
      url,
      responseType: "json",
      headers: {
        "Content-Type": "application/json"
      },
      data: { vehicle: data }
    })
      .then(response => {
        if (response.data.success) {
          this.showMessage("primary", "Éxito", ["Datos guardados"]);
          if (isEdition) {
            window.location.assign(`/vehicles?success=1`);
          } else {
            this.setState({ vehicleId: response.data.vehicle_id }, () => {
              this.modalNewVehicle.show();

            })
          }
        } else {
          $("html, main").animate({ scrollTop: 0 }, "slow");
          this.showMessage("primary", "Alerta", response.data.messages || ["Ha ocurrido un error inesperado"]);
        }
      })
      .catch(error => {
        $("html, main").animate({ scrollTop: 0 }, "slow");
        this.showMessage("primary", "Error", ["Ha ocurrido un error inesperado"]);
      })
      .finally(() => {
        this.setState({ isSubmitting: false });
      });
  };

  render() {
    const {
      messageTitle,
      messageType,
      messages,
      showMessage
    } = this.state.alert;

    const vehicleTypes = this.props.vehicleTypes.sort((a, b) => {
      if (a.brand > b.brand) {
        return 1;
      } else if (a.brand < b.brand) {
        return -1;
      } else if (a.model > b.model) {
        return 1;
      } else if (a.model < b.model) {
        return -1;
      } else return 0;
    });
    const isEdition = !!this.props.vehicle && !!this.props.vehicle.id
    const steps = [
      {
        name: "",
        component: (
          <VehicleData
            vehicle={_.cloneDeep(this.state.data)}
            vehicleTypes={_.cloneDeep(vehicleTypes)}
            fuelTypes={_.cloneDeep(this.props.fuelTypes)}
            vehicleCategories={_.cloneDeep(this.props.vehicleCategories)}
            vehicleTransmissions={_.cloneDeep(this.props.vehicleTransmissions)}
            changeVehicleType={this.changeVehicleType}
            changeFuelType={this.changeFuelType}
            changeVehicleCategory={this.changeVehicleCategory}
            handleChange={this.handleChange}
          />
        )
      },
      {
        name: "",
        component: (
          <VehiclePictures
            pictures={this.state.data.vehicle_pictures}
            addPicture={this.addPicture}
            removePicture={this.removePicture}
            addDocumentation={this.addDocumentation}
            removeDocumentation={this.removeDocumentation}
            photos={this.state.data.photos}
          />
        )
      },
      {
        name: "",
        component: (
          <VehicleRegistrationDocument
            registrationDocument={this.state.data.registration_document}
            addDocumentation={this.addDocumentation}
            removeDocumentation={this.removeDocumentation}
            insurancePolicy={this.state.data.insurance_policy}
            registry={this.state.data.registry}
            changeRegistry={this.changeRegistry}
          />
        )
      },
      {
        name: "",
        component: (
          <VehicleAccessories
            vehicleFeatures={this.props.vehicleFeatures}
            vehicle={this.state.data}
            addFeature={this.addFeature}
            removeFeature={this.removeFeature}
            isEditing={isEdition}
            handleSubmit={this.handleSubmit}
          />
        )
      },
      // {
      //   name: "",
      //   component: (
      //     <VehiclePolicies
      //       vehicle={this.state.data}
      //       isEditing={isEdition}
      //       cancellationPolicies={this.props.cancellationPolicies}
      //       selectPolicy={this.selectPolicy}
      //       handleSubmit={this.handleSubmit}
      //       fuelPolicies={this.props.fuelPolicies}
      //     />
      //   )
      // }
    ];
    const stepzilla = (
      <Stepzilla
        steps={steps}
        nextButtonText="Siguiente"
        backButtonText="Atrás"
        showNavigation={false}
        preventEnterSubmission={true}
      />
    );
    const modalNewVehicle = (
      <div
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="new-vehicle-title"
        aria-hidden="true"
        id="modal-new-vehicle"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-appcar">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="new-vehicle-title">
                ¡Felicidades! ¡Tu auto ha sido ingresado con éxito!
              </h4>
            </div>
            <div className="modal-body">
              <p>En estos momentos estamos revisando la información que subiste, apenas hayamos terminado, tu vehículo quedará Activo.</p>
              <p>
                Mientras tanto, puedes avanzar en completar tu anuncio, este paso es fundamental
                para que tu vehículo aparezca en las búsquedas, te pediremos:
              </p>
              <ul className="list-padded">
                <li>Definir el precio para días de semana y fin de semana</li>
                <li>Ubicación (¿dónde podrán retirar tu vehículo?)</li>
                <li>Elegir las políticas de cancelación y combustible</li>
                <li>Tasación del vehículo (Permite calcular la cobertura del seguro)</li>
              </ul>
              <p>
                Sólo debes dar click en "Editar publicación".
              </p>
            </div>
            <div className="modal-footer">
              <a
                href={`/rentals/new?vehicle_id=${this.state.vehicleId}`}
                className="btn btn-appcar-light mr-2"
              >
                <i className="mdi mdi-publish"></i> Editar publicación
              </a>
              <a
                href="/vehicles?success=1"
                className="btn btn-appcar-primary"
              >
                Continuar
              </a>
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <React.Fragment>
        <Spinner loading={this.state.isSubmitting} />
        <Alert
          alertClass={messageType}
          isShowing={showMessage}
          messages={messages}
          title={messageTitle}
          onClose={this.handleAlertClose}
        />
        <div className="row">
          <div className="col">
            <div className="step-progress">{stepzilla}</div>
          </div>
        </div>
        {modalNewVehicle}
      </React.Fragment>
    );
  }
}
