import React, { Component } from "react";
import { numeral } from "../../../common/constants";
import iconStare from "images/IconStare.png";
import iconInfo from "images/Icons/ico-info.png";
import ToolTip, { LoadToolTips } from "../../../common/components/ToolTip";
import RentalPriceInput from "../../../common/search_components/RentalPriceInput";
import PriceAlert from "../../../common/components/alert/PriceAlert";

export default class RentalPricing extends Component {
  state = {
    blurredBasePrice: false,
    blurredWeekendPrice: false,
  };

  componentDidUpdate(prevProps) {
    LoadToolTips();
  }

  componentDidMount() {
    LoadToolTips();
  }
  render() {
    const {
      data,
      handleSubmit,
      vehicle,
      openModalWarning,
      handleCalcPrice,
      basePrice,
      weekendPrice,
      handleChangePricing,
      openModalWeekendPrice,
      openModalBasePrice,
      isSubmitting,
      clearPrice,
      suggestedPrice,
      appraisal,
      handleCheckPrice,
      priceAlert,
    } = this.props;
    // const suggestedPrice = ((appraisal * 0.0575) / 30) * 0.65;
    const pricing = data.pricing;
    let basePriceWarning = {};
    let weekendPriceWarning = {};
    if (pricing.base_price < 1) {
      basePriceWarning = {
        title: "Primero debes ingresar el valor",
        disabled: true,
        onClick: () => {},
      };
    } else {
      basePriceWarning = {
        onClick: openModalBasePrice,
      };
    }
    if (pricing.weekend_price < 1) {
      weekendPriceWarning = {
        title: "Primero debes ingresar el valor",
        disabled: true,
        onClick: () => {},
      };
    } else {
      weekendPriceWarning = {
        onClick: openModalWeekendPrice,
      };
    }

    const alertBasePrice = priceAlert.base_price ? (
      <PriceAlert message={priceAlert.base_price} />
    ) : null;
    const alertWeekendPrice = priceAlert.weekend_price ? (
      <PriceAlert message={priceAlert.weekend_price} />
    ) : null;
    const length_discount = pricing.length_discount;
    const weekly_discount = length_discount && length_discount.weekly_discount;
    const monthly_discount =
      length_discount && length_discount.monthly_discount;
    return (
      <>
        <div className="mt-3 mb-1 row align-items-center">
          <div className="col">
            <label className="fs-16 fw-800 text-appcar-primary">
              Precios base
            </label>
          </div>
          <div className="col-auto ml-2 pb-1">
            <ToolTip
              title="Precios base"
              tooltipHTML="Establece el precio base diario que quieres recibir. Este precio puede ser diferente para días de semana y fin de semana."
              containerHTML={<img src={iconInfo} width={20} className="ml-1" />}
              id="ttip_rental_pricing"
            />
          </div>
        </div>
        <div className="rental-tip mb-4">
          <p className="fs-12 fw-400">
            Precio base sugerido:{" "}
            <span className="fw-800">
              {numeral(suggestedPrice).format("$0,0")}
            </span>
          </p>
          <div className="d-flex">
            <div className="d-flex flex-column align-items-center mr-2">
              <img src={iconStare} width={50} height={30} />
              <span className="fs-16 fw-900 text-appcar-primary">¡Tip!</span>
            </div>
            <div className="flex-fill">
              <p className="fs-10">
                Utiliza nuestro <b>"Precio diario sugerido"</b> para maximizar
                tus ingresos y asegurar que te lleguen reservas. En cualquier
                momento podrás editar el precio.
              </p>
            </div>
          </div>
        </div>
        <h5 className="fs-13 fw-800 mb-0">
          Días Lunes, Martes, Miércoles y Jueves:
        </h5>
        <div className="row mb-2">
          <div className="col-6 pr-0">
            <div className="form-group">
              <RentalPriceInput
                handleOnChange={(e) => {
                  const cleanValue = e.target.value.replace(/\D/g, "") || "0";
                  handleChangePricing(parseInt(cleanValue), "base_price");
                }}
                handleOnBlur={() => {
                  this.setState({ blurredBasePrice: true }, () => {
                    if (pricing.base_price > 0) {
                      handleCalcPrice("base_price");
                      handleCheckPrice("base_price");
                    } else clearPrice("base_price");
                  });
                }}
                inputId="base_price"
                label="Ganancia diaria para ti"
                labelClass="label-inside"
                wrapperClass="form-control input-location-search appcar-input no-min-width"
                inputClass="input-price-edit no-min-width"
                value={numeral(pricing.base_price).format("$0,0")}
              />
              {this.state.blurredBasePrice ? (
                <span className="fs-10 text-danger">
                  {basePriceWarning.title}
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-6 pl-1">
            <div className="form-group">
              <label className="label-inside">Precio a pagar Driver</label>
              <input
                type="text"
                className="form-control appcar-input no-min-width"
                value={numeral(basePrice.total).format("$0,0")}
                onChange={() => {}}
                disabled
                readOnly
              />
            </div>
            <p
              className="text-appcar-primary fs-11 fw-900 text-right cursor-pointer mb-0"
              {...basePriceWarning}
            >
              Detalle precio
            </p>
          </div>
        </div>
        {alertBasePrice}
        <h5 className="fs-13 fw-800 mb-0">Días Viernes, Sábado y Domingo:</h5>
        <div className="row mb-2">
          <div className="col-6 pr-0">
            <div className="form-group">
              <RentalPriceInput
                handleOnChange={(e) => {
                  const cleanValue = e.target.value.replace(/\D/g, "") || "0";
                  handleChangePricing(parseInt(cleanValue), "weekend_price");
                }}
                handleOnBlur={() => {
                  this.setState({ blurredWeekendPrice: true }, () => {
                    if (pricing.weekend_price > 0) {
                      handleCalcPrice("weekend_price");
                      handleCheckPrice("weekend_price");
                    } else clearPrice("weekend_price");
                  });
                }}
                inputId="weekend_price"
                label="Ganancia diaria para ti"
                labelClass="label-inside"
                wrapperClass="form-control input-location-search appcar-input no-min-width"
                inputClass="input-price-edit no-min-width"
                value={numeral(pricing.weekend_price).format("$0,0")}
              />
              {this.state.blurredBasePrice ? (
                <span className="fs-10 text-danger">
                  {weekendPriceWarning.title}
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-6 pl-1">
            <div className="form-group">
              <label className="label-inside">Precio a pagar Driver</label>
              <input
                type="text"
                className="form-control appcar-input no-min-width"
                value={numeral(weekendPrice.total).format("$0,0")}
                onChange={() => {}}
                disabled
                readOnly
              />
            </div>
            <p
              className="text-appcar-primary fs-11 fw-900 text-right cursor-pointer mb-0"
              {...weekendPriceWarning}
            >
              Detalle precio
            </p>
          </div>
        </div>
        {alertWeekendPrice}
        <div className="mt-3 mb-1 row align-items-center">
          <div className="col">
            <label className="fs-13 fw-800 text-appcar-primary">
              Descuentos
            </label>
          </div>
          <div className="col-auto ml-2 pb-1">
            <ToolTip
              title="Descuentos"
              tooltipHTML="Aquí se busca premiar a los Drivers por arriendos por períodos más largos."
              containerHTML={<img src={iconInfo} width={20} className="ml-1" />}
              id="ttip_rental_discount"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="label-inside">Descuentos por semana</label>
          <input
            type="text"
            className="form-control appcar-input"
            value={numeral(weekly_discount / 100).format("0,0%")}
            onChange={(e) => {
              const cleanValue = parseInt(
                e.target.value.replace(/\D/g, "") || "0"
              );
              handleChangePricing(
                {
                  ...length_discount,
                  weekly_discount: cleanValue,
                },
                "length_discount"
              );
            }}
          />
        </div>
        <div className="form-group">
          <label className="label-inside">Descuentos por mes</label>
          <input
            type="text"
            className="form-control appcar-input"
            value={numeral(monthly_discount / 100).format("0,0%")}
            onChange={(e) => {
              const cleanValue = parseInt(
                e.target.value.replace(/\D/g, "") || "0"
              );
              handleChangePricing(
                {
                  ...length_discount,
                  monthly_discount: cleanValue,
                },
                "length_discount"
              );
            }}
          />
        </div>
        <div className="text-center my-4">
          <button
            type="button"
            className="btn btn-appcar-primary pt-2 pb-2"
            onClick={() => handleSubmit(2)}
            disabled={isSubmitting}
          >
            Continuar
          </button>
        </div>
      </>
    );
  }
}
