import React, { Component, Fragment } from "react";

class VehiclePolicies extends Component {
  handleChange = (e, policy, name) => {
    if (e.target.checked) {
      this.props.selectPolicy(policy, name);
    }
  };

  handlePrev = e => {
    e.preventDefault();
    $("html, main").animate({ scrollTop: 0 }, "slow");
    this.props.jumpToStep(3);
  };

  render() {
    const selected_policy = this.props.vehicle.cancellation_policy;
    const selected_f_policy = this.props.vehicle.fuel_policy;
    const btn_label = this.props.isEditing
      ? "Editar vehículo"
      : "Registrar vehículo";
    const policies = this.props.cancellationPolicies.map((policy, i) => {
      const checked = !!selected_policy && policy.id === selected_policy.id;
      const rules = policy.rules.map((rule, i) => {
        return <li key={i}>{rule.description}</li>;
      });
      return (
        <div className="row my-3" key={i}>
          <div className="col">
            <div className="custom-control custom-radio">
              <input
                type="radio"
                id={`policy_${i}`}
                className="custom-control-input"
                checked={checked}
                onChange={e =>
                  this.handleChange(e, policy, "cancellation_policy")
                }
              />
              <label htmlFor={`policy_${i}`} className="custom-control-label">
                {policy.name}
              </label>
            </div>
            <ul>{rules}</ul>
            <a href="/legal/rental_policy#cancellation_policies" target="_blank" className="text-appcar-primary">Más detalles</a>
          </div>
        </div>
      );
    });
    const f_policies = this.props.fuelPolicies.map((policy, i) => {
      const checked = !!selected_f_policy && policy.id === selected_f_policy.id;
      const rules = (
        <Fragment>
          <li>{policy.description}</li>
          <li style={{ whiteSpace: "pre-line" }}>{policy.owner_description}</li>
        </Fragment>
      );
      return (
        <div className="row my-3" key={i}>
          <div className="col">
            <div className="custom-control custom-radio">
              <input
                type="radio"
                id={`f_policy_${i}`}
                className="custom-control-input"
                checked={checked}
                onChange={e => this.handleChange(e, policy, "fuel_policy")}
              />
              <label htmlFor={`f_policy_${i}`} className="custom-control-label">
                {policy.name}
              </label>
            </div>
            <ul>{rules}</ul>
            <a href="/legal/rental_policy#fuel_policies" target="_blank" className="text-appcar-primary">Más detalles</a>
          </div>
        </div>
      );
    });
    return (
      <React.Fragment>
        <div className="my-0 step-names">
          <div className="step-title">
            <span>Datos del vehículo</span>
          </div>
          <div className="step-title">
            <span>Fotos del vehículo</span>
          </div>
          <div className="step-title">
            <span>Documentos</span>
          </div>
          <div className="step-title">
            <span>Accesorios</span>
          </div>
          {/* <div className="step-title">
            <span>Políticas</span>
          </div> */}
        </div>
        <div className="my-3 mx-5">
          <h1 style={{ textAlign: "center" }}>
            Por favor completa la siguiente información:
          </h1>
          <div className="container vehicle-extras-container mt-4">
            <div className="row my-1">
              <div className="col">
                <div className="alert alert-info bg-info text-white border-0 my-3">
                  <i className="mdi mdi-lightbulb-on-outline" /> A los usuarios
                  se les informará de las políiticas seleccionadas
                </div>
              </div>
            </div>
            <h4 className="ml-2">Políticas de cancelación</h4>
            <div className="ml-1">{policies}</div>
            <h4 className="pt-1 ml-2">Políticas de combustible</h4>
            <div className="ml-1 pb-3">{f_policies}</div>
          </div>
        </div>
        <div className="footer-buttons">
          <button
            className="btn btn-prev btn-appcar-primary btn-lg pull-left"
            onClick={e => this.handlePrev(e)}
          >
            Anterior
          </button>
          <button
            className="btn btn-next btn-appcar-primary btn-lg pull-right"
            onClick={e => this.props.handleSubmit(e)}
          >
            {btn_label}
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default VehiclePolicies;
