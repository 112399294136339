import React, { useEffect } from "react";
import ToolTip, { LoadToolTips } from "../../../common/components/ToolTip";
import iconInfo from "images/Icons/ico-info.png";

const BookingPriceDetail = ({ days }) => {
    useEffect(() => {
        LoadToolTips();
    }, []);
    let innerhtml = "<div class='col tooltip-table'>"
    days.forEach(day => {
        innerhtml += "<div class='row'><div class='col'>" + day.date.split("T")[0] + "</div><div class='col'>" + day.total + "</div></div>"
    })
    innerhtml += "<div class='row'><div class='col'><b>Total</b></div><div class='col'><b>" + days.reduce((prev, cur) => prev + cur.total, 0) + "</b></div></div>"
    innerhtml += "</div>"
    return (
        <>
            <ToolTip
                title={'Deglose de precio base'}
                tooltipHTML={innerhtml}
                containerHTML={<img src={iconInfo} width={12} className="ml-1 pb-1" />}
                id={`price_detail_ttip`}
                key={`price_detail_ttip`}
            />
        </>
    );
};

export default BookingPriceDetail;
