import React from "react";
import UserInfo from "../rentals_show/UserInfo";

const RentalsVehicleInfo = props => {
  const vehicle = props.vehicle;
  const vehicle_title =
    vehicle.vehicle_type.brand +
    " " +
    vehicle.vehicle_type.model +
    " " +
    vehicle.year;
  const vehicleExtras = vehicle.vehicle_features.map(v => {
      return (
        <div className="col-6 centered-stats mb-1" key={v.id}>
          <i className={v.icon_class + " mr-2"}/>
          <p>{v.a_name}</p>
        </div>
      )
    })
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-4">
          <UserInfo owner={vehicle.associated_users[0]}/>
        </div>
        <div className="col-8">
          <div className="rental-vehicle-info">
            <h4>Datos del vehículo</h4>
            <p className="vehicle-stats-title">{vehicle_title}</p>

            <div className="my-3">
              <div className="row">
                <div className="col-6">
                  <p>PATENTE</p>
                </div>
                <div className="col-6">
                  <p>{vehicle.plate_number}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p>COLOR</p>
                </div>
                <div className="col-6">
                  <p>{vehicle.color}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p>COMBUSTIBLE</p>
                </div>
                <div className="col-6">
                  <p>{vehicle.fuel_type.description}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p>CAPACIDAD</p>
                </div>
                <div className="col-6">
                  <p>{vehicle.capacity} pasajero(s)</p>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p>RENDIMIENTO</p>
                </div>
                <div className="col-6">
                  <p>{vehicle.efficiency} Km/L</p>
                </div>
              </div>
              <h4>EXTRAS</h4>
              <div className="row">
                {vehicleExtras}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RentalsVehicleInfo;
