import React from "react";
import PricingDetails from "../../common/PricingDetails";
import moment from "moment";

const BookingDetails = ({ booking }) => {
  const {
    selected_pickup_location,
    selected_dropoff_location
  } = booking.pricing;
  const pickup_location =
    selected_pickup_location && selected_pickup_location.location
      ? selected_pickup_location.location.name
      : "";
  const dropoff_location =
    selected_dropoff_location && selected_dropoff_location.location
      ? selected_dropoff_location.location.name
      : "";
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-auto">Estado</div>
        <div className="col align-self-end text-right">
          <label>{booking.status.description}</label>
        </div>
      </div>
      <hr style={{ marginTop: ".5rem", marginBottom: ".5rem" }} />
      <div className="row">
        <div className="col-auto">Lugar desde</div>
        <div className="col align-self-end text-right">
          <label>{pickup_location}</label>
        </div>
      </div>
      <hr style={{ marginTop: ".5rem", marginBottom: ".5rem" }} />
      <div className="row">
        <div className="col-auto">Fecha desde</div>
        <div className="col align-self-end text-right">
          <label>
            {moment(booking.date_from)
              .locale("es")
              .format("LLL")}
          </label>
        </div>
      </div>
      <hr style={{ marginTop: ".5rem", marginBottom: ".5rem" }} />
      <div className="row">
        <div className="col-auto">Lugar hasta</div>
        <div className="col align-self-end text-right">
          <label>{dropoff_location}</label>
        </div>
      </div>
      <hr style={{ marginTop: ".5rem", marginBottom: ".5rem" }} />
      <div className="row">
        <div className="col-auto">Fecha hasta</div>
        <div className="col align-self-end text-right">
          <label>
            {moment(booking.date_until)
              .locale("es")
              .format("LLL")}
          </label>
        </div>
      </div>
      <hr style={{ marginTop: ".5rem", marginBottom: ".5rem" }} />
      <div className="row">
        <div className="col vehicle-scheduler">
          <PricingDetails
            price={booking.price}
            dateFrom={booking.date_from}
            dateUntil={booking.date_until}
            id={booking.id}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default BookingDetails;