import React, { Component } from "react";
import Moment from "moment";
import { extendMoment } from "moment-range";
import FlashMessage from "../../common/FlashMessage";
import { numeral, BookingStatus } from "../../common/constants";
import WorkspaceForm from "./WorkspaceForm";
import DriverQualification from "../../common/qualification/DriverQualification";
import Policies from "../../common/Policies";
import BookingDetails from "./BookingDetails";
import cloneDeep from "lodash/cloneDeep";
const moment = extendMoment(Moment);

class Workspace extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSubmit = (event, is_deleting = false) => {
    this.props.handleSubmit(event, is_deleting);
  };

  handleConfirm = event => {
    this.props.handleConfirm(event);
  };

  rateDriver = () => {
    $("#modal_driver_eval").modal("show");
  };

  hideModal = vehicle => {
    this.props.reload(vehicle.id, vehicle);
    $("#modal_driver_eval").modal("hide");
  };

  bookingEditable = booking => {
    if (!booking) return false;
    if (booking.owner_base_booking_id && booking.status.id == BookingStatus.EN_REVISION) return false;
    if (
      booking.status.id === BookingStatus.PENDIENTE
      || booking.status.id === BookingStatus.EN_REVISION
      || booking.status.id === BookingStatus.ACTIVO
    ) {
      if (moment(booking.date_until).valueOf() > moment().valueOf()) {
        return true;
      }
    }
    return false;
  }

  shouldShowAcceptCancel = (accepting, booking_status, date_from, date_until, parent_status) => {
    // Flujo normal para bookings
    if (booking_status != BookingStatus.CANCELADO && booking_status != BookingStatus.REEMPLAZADO && moment(date_from).valueOf() >= moment().valueOf()) {
      // Aceptar bookings pendientes y en revisión
      if (accepting && (booking_status == BookingStatus.PENDIENTE || booking_status == BookingStatus.EN_REVISION)) return true;
      // Cancelar bookings
      else if (!accepting) return true;
      // Aceptar o rechazar booking iniciado
    } else if (booking_status == BookingStatus.EN_REVISION && moment(date_until).valueOf() >= moment().valueOf() && parent_status === BookingStatus.INICIADO) {
      return true;
    }
    return false;
  }

  render() {
    let flash_message = this.props.ui.showMessage ? (
      <FlashMessage
        type={this.props.ui.messageType}
        msg={this.props.ui.messages}
        title={this.props.ui.messageTitle}
      />
    ) : null;
    let price = null;
    const { fecha, rental, bookings, bookingId } = this.props;
    let price_component = null;
    let booking_component = null;
    let edit_btn = null;
    if (fecha) {
      const date = moment(fecha);
      const dow = date.day();
      let array_of_events = rental.availability.blackout_days.map(
        (b_day, i) => {
          return {
            base_price: rental.pricing.base_price,
            weekend_price: rental.pricing.weekend_price,
            only_one_day:
              moment(b_day.end).diff(moment(b_day.start)) <= 86400000,
            start: b_day.start,
            end: b_day.end,
            reason: b_day.reason,
            object: "availability.blackout_days",
            index: i,
            is_disabled: true
          };
        }
      );
      array_of_events.push(
        ...rental.pricing.custom_days.map((c_day, i) => {
          return {
            base_price: c_day.base_price || rental.pricing.base_price,
            weekend_price: c_day.weekend_price || rental.pricing.weekend_price,
            only_one_day:
              moment(c_day.end).diff(moment(c_day.start)) <= 86400000,
            start: c_day.start,
            end: c_day.end,
            reason: c_day.reason,
            object: "pricing.custom_days",
            is_disabled: false,
            index: i
          };
        })
      );
      let event = {
        base_price: rental.pricing.base_price,
        weekend_price: rental.pricing.weekend_price,
        start: date.startOf("day").format(),
        end: date.endOf("day").format(),
        only_one_day: false,
        object: "",
        index: null,
        reason: "",
        is_disabled: false
      };
      let is_in_range_of_event = false;
      let event_has_past_days = false;
      for (let j = 0; j < array_of_events.length; j++) {
        const range_c = moment().range(
          moment(array_of_events[j].start)
            .startOf("day")
            .format(),
          moment(array_of_events[j].end)
            .endOf("day")
            .format()
        );
        if (range_c.contains(fecha)) {
          event = array_of_events[j];
          is_in_range_of_event =
            moment(array_of_events[j].end)
              .endOf("day")
              .diff(moment()) > 0;
          event_has_past_days =
            moment(array_of_events[j].start)
              .startOf("day")
              .diff(moment()) <= 0;
          break;
        }
      }
      price = [0, 5, 6].includes(dow) ? event.weekend_price : event.base_price;
      const is_future_or_today =
        moment()
          .startOf("day")
          .diff(date) <= 0;
      let booked = false;
      let booking = null;
      let has_weekday = false;
      let has_weekend_day = false;
      let has_bookings = false;
      let event_checked = false;
      const event_range = moment().range(event.start, event.end);
      for (let k = 0; k < bookings.length; k++) {
        const range_b = moment().range(
          moment(bookings[k].date_from)
            .startOf("day")
            .format(),
          moment(bookings[k].date_until)
            .endOf("day")
            .format()
        );
        if (!event_checked) {
          has_bookings = event_range.contains(range_b);
          event_checked = has_bookings;
        }
        for (let day of range_b.by("day")) {
          if (has_weekday && has_weekend_day) break;
          const dow = day.day();
          if ([0, 5, 6].includes(dow)) {
            has_weekend_day = true;
            continue;
          } else {
            has_weekday = true;
          }
        }
        if (range_b.contains(fecha)) {
          booking = bookings[k];
          booked = true;
          if (has_bookings) break;
        }
      }
      if (bookingId) {
        const selected_booking = bookings.find(b => b.id == bookingId);
        booking = cloneDeep(selected_booking || booking);
      }
      if (!is_future_or_today && !is_in_range_of_event && !booked) {
        flash_message = (
          <FlashMessage
            type="primary"
            msg={["Información: No es posible editar el precio de una fecha anterior."]}
            title=""
          />
        );
        price_component = price ? (
          <React.Fragment>
            <h4>Detalles de publicación</h4>
            <hr style={{ marginTop: ".5rem", marginBottom: ".5rem" }} />
            <div className="row">
              <div className="col">Precio del día seleccionado</div>
              <div className="col align-self-end text-right">
                <label>{numeral(price).format("$ 0,0")}</label>
              </div>
            </div>
            <hr style={{ marginTop: ".5rem", marginBottom: "1.5rem" }} />
          </React.Fragment>
        ) : null;
      } else {
        if (booked) {
          flash_message = booked ? (
            <FlashMessage
              type="primary"
              msg={["Información: No es posible editar el precio de un día reservado."]}
              title=""
            />
          ) : null;
          price_component = price ? (
            <React.Fragment>
              <h4>Detalles de publicación</h4>
              <hr style={{ marginTop: ".5rem", marginBottom: ".5rem" }} />
              {has_weekday ? (
                <div className="row">
                  <div className="col-auto">Precio</div>
                  <div className="col align-self-end text-right">
                    <label>{numeral(event.base_price).format("$ 0,0")}</label>
                  </div>
                </div>
              ) : null}
              {has_weekend_day ? (
                <div className="row">
                  <div className="col-auto">Precio de fin de semana</div>
                  <div className="col align-self-end text-right">
                    <label>
                      {numeral(event.weekend_price).format("$ 0,0")}
                    </label>
                  </div>
                </div>
              ) : null}
              <hr style={{ marginTop: ".5rem", marginBottom: "1.5rem" }} />
            </React.Fragment>
          ) : null;
        } else {
          let msg = [];
          if (event_has_past_days)
            msg.push("Aviso: El rango de fechas del evento incluye días pasados.");
          if (has_bookings)
            msg.push(
              "Aviso: No es posible bloquear, desbloquear o editar los precios de un evento que tenga reservas."
            );
          if (event_has_past_days || has_bookings) {
            flash_message = (
              <FlashMessage type="light" msg={msg} title="" />
            );
          }

          price_component = (
            <WorkspaceForm
              event={event}
              events={array_of_events}
              bookings={bookings}
              date={date}
              handleSubmit={this.handleSubmit}
              handleConfirm={this.handleConfirm}
              hasBookings={has_bookings}
              rental={rental}
              originalStart={event.start}
              originalEnd={event.end}
            />
          );
        }
      }

      const base_booking_status = booking && booking.base_booking_id ? this.props.allBookings.find(x => x.id == booking.base_booking_id).status.id : null;
      const btn_accept_booking = booking && this.shouldShowAcceptCancel(true, booking.status.id, booking.date_from, booking.date_until, base_booking_status) && !booking.owner_base_booking_id ? (
        <button
          type="button"
          className="btn btn-appcar-primary float-left mr-2 mb-2 pt-2 pb-2"
          onClick={() => this.props.handleAcceptBooking(booking.id)}
        >
          {booking.status.id == BookingStatus.EN_REVISION
            ? "Aceptar modificación"
            : "Aceptar reserva"}
        </button>
      ) : null;
      let cancel_text = "";
      if (booking) {
        if (booking.status.id === BookingStatus.PENDIENTE) {
          cancel_text = "Rechazar reserva";
        } else if (booking.status.id === BookingStatus.EN_REVISION) {
          if (booking.base_booking_id) cancel_text = "Rechazar modificación";
          else if (booking.owner_base_booking_id) cancel_text = "Cancelar propuesta";
        } else {
          cancel_text = "Cancelar reserva"
        }
      }
      const btn_cancel_booking = booking && this.shouldShowAcceptCancel(false, booking.status.id, booking.date_from, booking.date_until, base_booking_status) ? (
        <button
          type="button"
          className="btn btn-appcar-secondary float-left mr-2 mb-2 pb-2 pt-2"
          onClick={() => this.props.handleCancelBooking(booking.id)}
        >
          {cancel_text}
        </button>
      ) : null;
      let edit_id = null;
      if (booking) {
        if (booking.base_booking_id) edit_id = booking.base_booking_id;
        else if (booking.owner_base_booking_id) edit_id = booking.owner_base_booking_id;
      }
      const btn_edit_booking =
        edit_id ? (
          <button
            className="btn btn-appcar-primary float-left mb-2 pt-2 pb-2"
            type="button"
            onClick={() => this.props.compareBookings(edit_id, booking.id)}
          >
            Comparar con reserva original
          </button>
        ) : null;
      const btns =
        !!btn_accept_booking || !!btn_cancel_booking || !!btn_edit_booking ? (
          <React.Fragment>
            <div className="row">
              <div className="col">
                {btn_accept_booking}
                {btn_cancel_booking}
                {btn_edit_booking}
              </div>
            </div>
            <hr style={{ marginTop: ".5rem", marginBottom: ".5rem" }} />
          </React.Fragment>
        ) : null;

      let modal = null;
      let driver_rating = [];
      if (booking) {
        const driver = booking.user[0];
        const photo = driver.photo ? driver.photo : "/user-icon.png";
        const image_style = {
          backgroundImage: `url("${photo}")`
        };
        const edit_booking_text =
          booking.status.id === BookingStatus.PENDIENTE || booking.status.id === BookingStatus.EN_REVISION
            ? "Ofrecer otro auto"
            : "Solicitar cambio de auto";
        edit_btn = this.props.canEditBooking && this.props.canOfferNewCar && this.bookingEditable(booking)
          ? (
            <div className="col-auto">
              <a
                href={`/bookings/${booking.id}/owner_edit`}
                className="btn btn-appcar-primary pt-2 pb-2 px-5"
              >
                {edit_booking_text}
              </a>
            </div>) : null;
        modal = booking.status.id === BookingStatus.COMPLETADO && (booking.review_status === null || !booking.review_status.driver) ? (
          <div
            id="modal_driver_eval"
            className="modal fade"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body p-4">
                  <DriverQualification
                    driverId={driver.id}
                    callback={() => this.hideModal(booking.vehicle)}
                    bookingId={booking.id}
                    key={booking.id}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null;
        for (let i = 1; i <= 5; i++) {
          const r_icon =
            i <= driver.driver_rating ? (
              <i className="mdi mdi-star" key={i} />
            ) : (
                <i className="mdi mdi-star-outline" key={i} />
              );
          driver_rating.push(r_icon);
        }
        // Para probar
        // const badgeRate = (booking.review_status === null || !booking.review_status.driver) ? 
        const badgeRate = booking.status.id === BookingStatus.COMPLETADO && (booking.review_status === null || !booking.review_status.driver) ?
          (<div
            className="calendar-driver-badge-ratings mr-1"
            key="rate"
            style={{
              width: "fit-content",
              backgroundColor: "#203453",
              color: "#fff",
              cursor: "pointer"
            }}
            onClick={this.rateDriver}
          >
            Evaluar
        </div>) : null;
        const evaluations = (
          <div className="d-flex flex-row-reverse">
            <div
              className="calendar-driver-badge-ratings"
              key="badge"
              style={{ width: "fit-content" }}
            >
              {driver.driver_evaluations || 0}{" "}
              {driver.driver_evaluations == 1 ? "evaluación" : "evaluaciones"}
            </div>
            {badgeRate}
          </div>
        );


        const alert_edited_booking =
          booking && booking.status.id == BookingStatus.EN_REVISION ? (
            <div className="alert alert-info">
              <label className="mb-0">Información</label>
              <p className="mb-0">
                {booking.owner_base_booking_id
                  ? "Esta es una solicitud de edición de otra reserva realizada por ti"
                  : "Esta es una edición de otra reserva"
                }
              </p>
            </div>
          ) : null;
        const ownerBaseBookingActive = booking.owner_base_booking_status && booking.owner_base_booking_status.id === BookingStatus.ACTIVO;
        const ownerBaseBooking = booking.owner_base_booking_id ? cloneDeep(this.props.allBookings.find(x => x.id == booking.owner_base_booking_id)) : null;

        if (ownerBaseBookingActive) {
          booking.price.owner_discount = 0;
          const bookingTotal = booking.price.total;
          const originalPrice = ownerBaseBooking ? ownerBaseBooking.price : null;
          if (originalPrice && bookingTotal > originalPrice.total) {
            booking.price.owner_discount = (booking.price.total - booking.price.value_added_tax) - (originalPrice.total - originalPrice.value_added_tax);
            booking.price.value_added_tax = originalPrice.value_added_tax;
            booking.price.total = originalPrice.total
          }
        }
        const bookingDetails = <BookingDetails booking={booking} />
        booking_component = (
          <React.Fragment>
            <h4>Reserva</h4>
            <hr style={{ marginTop: ".5rem", marginBottom: ".5rem" }} />
            {alert_edited_booking}
            <div className="row">
              <div className="col-auto">Usuario</div>
              <div className="col align-self-end text-right">
                <div className="calendar-driver-image" style={image_style} />
                <div className="calendar-driver-info">
                  <p className="driver-name">
                    {driver.name + " " + driver.last_name}
                  </p>
                  <div className="driver-rating">
                    {driver_rating}
                  </div>
                  {evaluations}
                </div>
              </div>
            </div>
            <hr style={{ marginTop: ".5rem", marginBottom: ".5rem" }} />
            {bookingDetails}
            <hr style={{ marginTop: ".5rem", marginBottom: ".5rem" }} />
            <div className="row pb-3">
              <div className="col-auto">N° contacto</div>
              <div className="col align-self-end text-right">
                <label>{driver.phone_number || "No indicado"}</label>
              </div>
            </div>
            {btns}
            <Policies
              fuelPolicy={booking.vehicle.fuel_policy}
              cancellationPolicy={booking.vehicle.cancellation_policy}
              isOwner={true}
              isDriver={false}
            />
            {modal}
          </React.Fragment>
        );
      }
    }
    return (
      <div className="workspace-scheduler">
        <div className="row mb-3">
          <div className="col-auto">
            <h3>Área de trabajo</h3>
          </div>
          {edit_btn}
        </div>
        {flash_message}
        {price_component}
        {booking_component}
      </div>
    );
  }
}

export default Workspace;
