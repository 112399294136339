import React from "react";
import carLocationIcon from "images/Icons/PickupIcon.png";
import SelectWithRadio from "../../../../common/components/select/SelectWithRadio";
import { constants, numeral } from "../../../../common/constants";

const LocationContainer = ({
  extraLocations,
  selectedPickup,
  selectedDropoff,
  location,
  changePickUp = () => null,
  changeDropOff = () => null,
  showLocationMap,
  canEdit = true,
  isBookingStarted = false,
}) => {
  const locationName = location?.name;
  const latLocation = location?.latitude;
  const lngLocation = location?.longitude;
  
  let locationsOptions = extraLocations
    .sort((a, b) => (a.price > b.price ? 1 : -1))
    .map((x) => {
      const comission = (x.price) * constants.COMISSION_PERCENTAGE / (1 - constants.COMISSION_PERCENTAGE);
      // const mpCharge = 0.0351 * (x.price + comission) / (1 - 0.0351);
      const tax = comission * 0.19;
      const totalPrice = (x.price + comission + tax) / (1 - (1 + 0.19) * 0.0295);
      return {
        label: `${numeral(totalPrice).format("$0,0")} - ` + x.location.name,
        value: x.location.name,
      };
    });

  let selectedDropoffLocation = null;
  let selectedPickupLocation = null;

  if (extraLocations.length > 0) {
    selectedPickupLocation = extraLocations.find(
      (x) => x.location.name == selectedPickup.value
    ) || {
      location: {
        name: location?.name,
        latitude: location?.latitude,
        longitude: location?.longitude,
      },
    };
    selectedDropoffLocation = extraLocations.find(
      (x) => x.location.name == selectedDropoff.value
    ) || {
      location: {
        name: location?.name,
        latitude: location?.latitude,
        longitude: location?.longitude,
      },
    };
  }

  locationsOptions.unshift({
    label: "$0 - " + locationName,
    value: locationName,
  });
  return extraLocations.length > 0 ? (
    <>
      <h5>Puntos de recogida y devolución</h5>
      <div className="row mt-3">
        <div className="col-auto pr-0 text-center">
          <img src={carLocationIcon} width={24} />
        </div>
        <div className="col">
          <div className="form-group">
            <SelectWithRadio
              value={selectedPickup}
              options={locationsOptions}
              placeholder="Selecciona punto de recogida"
              onChange={(option) => changePickUp(option)}
              classNamePrefix="RS-FIX"
              className="react-select-fix"
              clearable={false}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              backspaceRemovesValue={false}
              isSearchable={false}
              isDisabled={!canEdit || isBookingStarted}
            />
          </div>
        </div>
      </div>
      <div
        className="text-appcar-primary fs-13 fw-900 cursor-pointer text-right w-fit ml-auto"
        onClick={() =>
          showLocationMap({
            lat: selectedPickupLocation?.location?.latitude,
            lng: selectedPickupLocation?.location?.longitude,
            locationAddress: selectedPickupLocation?.location?.name,
          })
        }
      >
        Ver en mapa
      </div>
      <div className="row mt-3">
        <div className="col-auto pr-0 text-center">
          <img src={carLocationIcon} width={24} />
        </div>
        <div className="col">
          <div className="form-group">
            <SelectWithRadio
              value={selectedDropoff}
              options={locationsOptions}
              placeholder="Selecciona punto de devolución"
              onChange={(option) => changeDropOff(option)}
              classNamePrefix="RS-FIX"
              className="react-select-fix"
              clearable={false}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              backspaceRemovesValue={false}
              isSearchable={false}
              isDisabled={!canEdit}
            />
          </div>
        </div>
      </div>
      <div
        className="text-appcar-primary fs-13 fw-900 cursor-pointer text-right w-fit ml-auto"
        onClick={() =>
          showLocationMap({
            lat: selectedDropoffLocation?.location?.latitude,
            lng: selectedDropoffLocation?.location?.longitude,
            locationAddress: selectedDropoffLocation?.location?.name,
          })
        }
      >
        Ver en mapa
      </div>
    </>
  ) : (
    <>
      <h5>Puntos de recogida y devolución</h5>
      <div className="row mt-3">
        <div className="col-auto pr-0 text-center">
          <img src={carLocationIcon} width={24} />
        </div>
        <div className="col">
          <div className="form-group">
            <input
              type="text"
              readOnly
              value={locationName}
              onChange={() => false}
              className="form-control appcar-input disabled"
              disabled
            />
          </div>
        </div>
      </div>
      <div
        className="text-appcar-primary fs-13 fw-900 cursor-pointer text-right w-fit ml-auto"
        onClick={() =>
          showLocationMap({
            lat: latLocation,
            lng: lngLocation,
            locationAddress: locationName,
          })
        }
      >
        Ver en mapa
      </div>
      <div className="row mt-3">
        <div className="col-auto pr-0 text-center">
          <img src={carLocationIcon} width={24} />
        </div>
        <div className="col">
          <div className="form-group">
            <input
              type="text"
              readOnly
              value={locationName}
              onChange={() => false}
              className="form-control appcar-input disabled"
              disabled
            />
          </div>
        </div>
      </div>
      <div
        className="text-appcar-primary fs-13 fw-900 cursor-pointer text-right w-fit ml-auto"
        onClick={() =>
          showLocationMap({
            lat: latLocation,
            lng: lngLocation,
            locationAddress: locationName,
          })
        }
      >
        Ver en mapa
      </div>
    </>
  );
};

export default LocationContainer;
