import React, { Component, Fragment } from "react";
import { Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import Spinner from "../../common/Spinner";
import FlashMessage from "../../common/FlashMessage";
import axios from "axios";
import { AxiosBackofficeInterceptor } from "../../common/axios_interceptor";
axios.interceptors.response.use(undefined, AxiosBackofficeInterceptor);

const UserTypesSchema = Yup.object().shape({
  description: Yup.string().required("Campo requerido")
});

class UserTypesForm extends Component {
  state = {
    data: this.props.userType || {
      description: ""
    },
    ui: {
      isSubmitting: false,
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined
    }
  };

  showMessage = msg => {
    this.setState(state => {
      state.ui.showMessage = msg.showMessage;
      state.ui.messageType = msg.messageType;
      state.ui.messages = msg.messages;
      state.ui.messageTitle = msg.messageTitle;
      return { ui: state.ui };
    });
  };

  render() {
    const flash_message = this.state.ui.showMessage ? (
      <FlashMessage
        type={this.state.ui.messageType}
        msg={this.state.ui.messages}
        title={this.state.ui.messageTitle}
      />
    ) : null;
    return (
      <Fragment>
        {flash_message}
        <Formik
          showMessage={this.showMessage}
          enableReinitialize={true}
          initialValues={this.state.data}
          validationSchema={UserTypesSchema}
          onSubmit={(values, { setSubmitting }) => {
            // const token = document.getElementsByName("csrf-token")[0].content;
            const token = null;
            let url = "";
            let method = "";
            if (this.props.userType) {
              url = `/backoffice/user_types/${this.props.userType.id}.json`;
              method = "PUT";
              values.id = parseInt(values.id);
            } else {
              url = `/backoffice/user_types.json`;
              method = "POST";
            }
            axios(url, {
              method,
              url,
              responseType: "json",
              headers: {
                "Content-Type": "application/json"
              },
              data: { user_type: values }
            })
              .then(response => {
                if (response.data.success) {
                  window.location.assign(`/backoffice/user_types?success=1`);
                } else {
                  this.showMessage({
                    showMessage: true,
                    messageType: "alert-warning",
                    messages: response.data.messages || [
                      "Ha ocurrido un error inesperado"
                    ],
                    messageTitle: "Alerta"
                  });
                }
              })
              .catch(error =>
                this.showMessage({
                  showMessage: true,
                  messageType: "alert-danger",
                  messages: ["Ha ocurrido un error inesperado"],
                  messageTitle: "Error"
                })
              )
              .then(() => setSubmitting(false));
          }}
        >
          {({
            errors,
            touched,
            handleSubmit,
            isSubmitting
          }) => {
            const button_label = this.props.userType
              ? "Editar"
              : "Agregar nuevo";
            return (
              <Fragment>
                <Spinner loading={isSubmitting || this.state.ui.isSubmitting} />
                <form onSubmit={handleSubmit} className="">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label htmlFor="description">
                          Nombre del tipo de usuario
                        </label>
                        <Field
                          type="text"
                          name="description"
                          className={
                            "form-control appcar-input " +
                            classNames({
                              "is-invalid":
                                touched.description && errors.description
                            })
                          }
                        />
                        <ErrorMessage name="description">
                          {msg => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-appcar-primary"
                    disabled={isSubmitting}
                  >
                    {`${button_label} tipo de usuario`}
                  </button>
                </form>
              </Fragment>
            );
          }}
        </Formik>
      </Fragment>
    );
  }
}

export default UserTypesForm;
