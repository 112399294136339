import React, { Component } from "react";
import isEmpty from "lodash/isEmpty";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { numeral } from "../../common/constants";
import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);

class Scheduler extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  calendarRef = React.createRef();

  handleDateClick = e => {
    if (e) this.props.changeFecha(e);
  };

  render() {
    const { events, rental } = this.props;
    return (
      <div className="scheduler-anc">
        <h3 className="mb-3">Calendario</h3>
        <FullCalendar
          ref={this.calendarRef}
          defaultView="dayGridMonth"
          plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
          locale="es"
          dateClick={e => this.handleDateClick(e.date)}
          eventClick={e => {
            if (e.event.extendedProps.bookingId) {
              this.props.changeBooking(e.event.extendedProps.bookingId);
            } else {
              this.handleDateClick(e.event.start);
            }
          }}
          events={events}
          header={{
            left: "prev,next today",
            center: "title",
            right: "listWeek,dayGridMonth,listMonth"
          }}
          firstDay={1}
          contentHeight={650}
          fixedWeekCount={false}
          noEventsMessage="No existen eventos para mostrar en esta(s) fecha(s)"
          buttonText={{
            today: "Hoy",
            month: "Mes",
            week: "Semana",
            day: "Día",
            listMonth: "Lista mensual"
          }}
          displayEventTime={false}
          dayRender={dayRenderInfo => {
            if (!isEmpty(rental)) {
              const date = moment(dayRenderInfo.date).startOf("day");
              let array_of_events = rental.availability.blackout_days.map(
                (b_day, i) => {
                  return {
                    base_price: rental.pricing.base_price,
                    weekend_price: rental.pricing.weekend_price,
                    start: b_day.start,
                    end: b_day.end,
                    index: i,
                    is_disabled: true
                  };
                }
              );
              array_of_events.push(
                ...rental.pricing.custom_days.map((c_day, i) => {
                  return {
                    base_price: c_day.base_price || rental.pricing.base_price,
                    weekend_price:
                      c_day.weekend_price || rental.pricing.weekend_price,
                    start: c_day.start,
                    end: c_day.end,
                    is_disabled: false
                  };
                })
              );
              let rental_event = {
                base_price: rental.pricing.base_price,
                weekend_price: rental.pricing.weekend_price,
                start: moment(rental.date_from).format(),
                end: moment(rental.date_until).format(),
                is_disabled: false
              };
              array_of_events.push(rental_event);
              let event = null;
              for (let j = 0; j < array_of_events.length; j++) {
                const range_c = moment().range(
                  moment(array_of_events[j].start)
                    .startOf("day")
                    .format(),
                  moment(array_of_events[j].end)
                    .endOf("day")
                    .format()
                );
                if (range_c.contains(date)) {
                  event = array_of_events[j];
                  break;
                }
              }
            }
          }}
        />
      </div>
    );
  }
}

export default Scheduler;