import Axios from "axios";
import uniqBy from "lodash/uniqBy";
import React, { Component } from "react";
import Alert from "../../../common/components/alert/Alert";
import { constants, generateFilename } from "../../../common/constants";
import Spinner from "../../../common/Spinner";
import VehicleData from "./VehicleData";
import VehicleDocuments from "./VehicleDocuments";
import VehicleFeatures from "./VehicleFeatures";
import VehiclePictures from "./VehiclePictures";
import { AxiosApplicationInterceptor } from "../../../common/axios_interceptor";
import iconWink from "images/IconWink.png";
import iconStare from "images/IconStare.png";
Axios.interceptors.response.use(undefined, AxiosApplicationInterceptor);

export default class VehicleWizard extends Component {
  state = {
    data: {
      id: "",
      market_value: 0.0,
      plate_number: "",
      year: 2000,
      engine: "",
      chassis: "",
      color: "",
      capacity: "",
      efficiency: "",
      vehicle_category: null,
      vehicle_transmission: null,
      vehicle_type: null,
      fuel_type: null,
      cancellation_policy: null,
      fuel_policy: null,
      // unlimited_distance: false, // TODO - JUAN: Descomentar en caso de que quieran que lo de los 250km vaya acá también
      vehicle_features: [],
      vehicle_pictures: [],
      photos: {
        front: [],
        back: [],
        left: [],
        right: [],
        inside: [],
      },
      registration_document: {
        vehicular_technical_revision: [],
        circulation_permit: [],
        registration_document: [],
      },
      insurance_policy: {
        soap: [],
      },
      registry: {
        name: "",
        id_number: "",
      },
    },
    vehicleId: null,
    isSubmitting: false,
    activeStep: this.props.activeStep || 0,
    objectUploading: null,
    justLoadedAdditionalPicture: false,
    alert: {
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined,
    },
  };

  modalAlertFields = null;

  componentDidMount() {
    $("body").click(function(e) {
      if (!$(e.target).hasClass("anc-tooltip")) {
        $(".tooltiptext").removeClass("tooltip-visible");
      } else {
        var tooltipFor = "#" + e.target.dataset.toggleTooltipFor;
        $(".tooltiptext")
          .not($(tooltipFor))
          .removeClass("tooltip-visible");
        $(tooltipFor).toggleClass("tooltip-visible");
      }
    });

    let data = this.state.data;

    if (this.props.vehicle) {
      data = this.props.vehicle;

      // Pictures
      data.photos ??= {};
      data.photos.front ??= [];
      data.photos.back ??= [];
      data.photos.left ??= [];
      data.photos.right ??= [];
      data.photos.inside ??= [];
      data.vehicle_pictures ??= [];
      data.photos.front = data.photos.front.map((url) => ({ url }));
      data.photos.back = data.photos.back.map((url) => ({ url }));
      data.photos.left = data.photos.left.map((url) => ({ url }));
      data.photos.right = data.photos.right.map((url) => ({ url }));
      data.photos.inside = data.photos.inside.map((url) => ({ url }));

      // Documents
      data.registration_document ??= {};
      data.registration_document.circulation_permit ??= [];
      data.registration_document.registration_document ??= [];
      data.registration_document.vehicular_technical_revision ??= [];
      data.insurance_policy ??= {};
      data.insurance_policy.soap ??= [];
      data.vehicle_pictures = data.vehicle_pictures.map((url) => ({ url }));
      data.registration_document.circulation_permit = data.registration_document.circulation_permit.map(
        (url) => ({ url })
      );
      data.registration_document.registration_document = data.registration_document.registration_document.map(
        (url) => ({ url })
      );
      data.registration_document.vehicular_technical_revision = data.registration_document.vehicular_technical_revision.map(
        (url) => ({ url })
      );
      data.insurance_policy.soap = data.insurance_policy.soap.map((url) => ({
        url,
      }));
      // Data
      data.engine ??= "";
      data.efficiency ??= "";
      data.chassis ??= "";
      data.market_value ??= 0.0;
      data.registry ??= {
        name: "",
        id_number: "",
      };
      data.vehicle_features ??= [];
    }
    this.setState({ data });

    this.modalAlertFields = new bootstrap.Modal(
      document.getElementById("modal-save-changes")
    );
  }

  checkStep = (step) => {
    let ok = false;
    const data = this.state.data;
    const registrationDocument = data.registration_document;
    switch (step) {
      case 0:
        if (
          !!data.plate_number &&
          !!data.color &&
          !!data.capacity &&
          !!data.market_value &&
          !!data.vehicle_type &&
          !!data.vehicle_type.model &&
          !!data.vehicle_category &&
          !!data.vehicle_category.id &&
          !!data.fuel_type &&
          !!data.fuel_type.id &&
          !!data.vehicle_transmission &&
          !!data.vehicle_transmission.id
        ) {
          ok = true;
        }
        break;
      // Si se deben dejar fotos, por mientras no
      // case 1:
      //   if (
      //     Array.isArray(data.vehicle_pictures) &&
      //     data.vehicle_pictures.length > 0
      //   ) {
      //     ok = true;
      //   }

      // Si son obligatorios los documentos, por mientras no
      // case 2:
      //   if (
      //     Array.isArray(registrationDocument.vehicular_technical_revision) &&
      //     registrationDocument.vehicular_technical_revision.length > 0 &&
      //     Array.isArray(registrationDocument.homologation) &&
      //     registrationDocument.homologation.length > 0 &&
      //     Array.isArray(registrationDocument.registration_document) &&
      //     registrationDocument.registration_document.length > 0 &&
      //     Array.isArray(data.insurance_policy.soap) &&
      //     data.insurance_policy.soap.length > 0 &&
      //     !!data.registry.id_number &&
      //     !!data.registry.name &&
      //     !!data.chassis &&
      //     !!data.engine
      //   ) {
      //     ok = true;
      //   }
      default:
        ok = true;
    }
    return ok;
  };

  handleSubmit = (nextStep) => {
    let vehicle = this.state.data;
    const isLastStep = nextStep === 4;
    if (!this.checkStep(this.state.activeStep)) {
      this.modalAlertFields.show();
      return;
    }
    const newStep = isLastStep ? 3 : nextStep;
    this.setState({ isSubmitting: true });
    let method = "POST";
    let url = "/vehicles.json";
    const isEdition = !!vehicle.id;
    if (isEdition) {
      method = "PUT";
      url = "/vehicles/" + vehicle.id + ".json";
    }
    const lastStepParams = isLastStep ? "?finishing=true" : "";
    let shouldCloseSpinner = true;
    Axios({
      method: method,
      url: url + lastStepParams,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: { vehicle },
    })
      .then((response) => {
        if (response.data.success) {
          this.showMessage("primary", "Éxito", ["Datos guardados"]);
          if (!isEdition) {
            vehicle.id = response.data.vehicle_id;
            shouldCloseSpinner = false;
            window.location.href = `/vehicles/${vehicle.id}/edit?active_step=1`;
          }
          if (isLastStep) {
            shouldCloseSpinner = false;
            this.props.formAction(vehicle.id);
          }
        } else {
          $("html, main").animate({ scrollTop: 0 }, "slow");
          this.showMessage(
            "primary",
            "Alerta",
            response.data.messages || ["Ha ocurrido un error inesperado"]
          );
        }
      })
      .catch((error) => {
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ]);
      })
      .finally(() => {
        if (shouldCloseSpinner)
          this.setState({ isSubmitting: false, activeStep: newStep });
        else this.setState({ activeStep: newStep });
      });
  };

  handleChange = (value, name) => {
    this.setState((state) => {
      state.data[name] = value;
      return { data: state.data };
    });
  };

  handleChangeObj = (value, name, obj) => {
    this.setState((state) => {
      state.data[obj] = {
        ...state.data[obj],
        [name]: value,
      };
      return { data: state.data };
    });
  };

  handleChangeModel = (option) => {
    const vehicle_type = this.props.vehicleTypes.find(
      (x) => x.id === option.value
    );
    this.handleChange(vehicle_type, "vehicle_type");
  };

  showMessage = (messageType, messageTitle, messages) =>
    this.setState(
      (state) => {
        state.alert.showMessage = true;
        state.alert.messageTitle = messageTitle;
        state.alert.messages = messages;
        state.alert.messageType = messageType;
        return { alert: state.alert };
      },
      () => setTimeout(this.handleAlertClose, 3000)
    );

  handleAlertClose = () =>
    this.setState((state) => {
      state.alert.showMessage = false;
      return { alert: state.alert };
    });

  openFileDialog = (objectUploading) =>
    this.setState({ objectUploading }, () =>
      document.getElementById("image_uploader").click()
    );

  openFileDialogDocuments = (objectUploading) =>
    this.setState({ objectUploading }, () =>
      document.getElementById("image_uploader_documents").click()
    );

  uploadPicture = (e) => {
    let file = e.target.files;
    const fullPath = this.state.objectUploading;
    if (file.length > 0) {
      const filename = generateFilename(file[0].name);
      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onloadend = function(e) {
        var base64result = reader.result;
        this.addPicture(base64result, filename, fullPath);
      }.bind(this);
    }
  };

  addPicture = (picture, filename, full_path) => {
    const path = full_path.split(".");
    const file = picture.split(",");
    const start = file[0];
    const image_base_64 = file[1];
    const mime = picture.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    const mime_type = mime && mime.length ? mime[1] : "";
    filename = encodeURI(filename);
    this.setState((state) => {
      if (full_path === "firstVehiclePicture") {
        state.data.vehicle_pictures[0] = {
          filename,
          start,
          image_base_64,
          mime_type,
        };
      } else {
        if (path.length == 1) {
          state.data[path[0]].push({
            filename,
            start,
            image_base_64,
            mime_type,
          });
          if (path[0] == "vehicle_pictures") {
            state.justLoadedAdditionalPicture = true;
          }
        } else if (path.length == 2) {
          state.data[path[0]][path[1]].push({
            filename,
            start,
            image_base_64,
            mime_type,
          });
        }
      }
      return {
        data: state.data,
        justLoadedAdditionalPicture: state.justLoadedAdditionalPicture,
      };
    });
  };

  reportUpdatedLoadingMore = () =>
    this.setState({ justLoadedAdditionalPicture: false });

  removePicture = (index, full_path) => {
    const path = full_path.split(".");
    this.setState((state) => {
      if (path.length == 1) {
        state.data[path[0]].splice(index, 1);
      } else if (path.length == 2) {
        state.data[path[0]][path[1]].splice(index, 1);
      }
      return { data: state.data };
    });
  };

  addFeature = (feature) => {
    this.setState((state) => {
      state.data.vehicle_features.push(feature);
      return { data: state.data };
    });
  };

  removeFeature = (feature) => {
    const index = this.state.data.vehicle_features.findIndex(
      (x) => x.id === feature.id
    );
    this.setState((state) => {
      state.data.vehicle_features.splice(index, 1);
      return { data: state.data };
    });
  };

  render() {
    const {
      activeStep,
      alert,
      data,
      isSubmitting,
      justLoadedAdditionalPicture,
    } = this.state;
    const { messageTitle, messageType, messages, showMessage } = alert;
    const {
      vehicleCategories,
      fuelTypes,
      vehicleTransmissions,
      vehicleFeatures,
    } = this.props;

    const vehicleTypes = this.props.vehicleTypes.sort((a, b) => {
      if (a.brand > b.brand) {
        return 1;
      } else if (a.brand < b.brand) {
        return -1;
      } else if (a.model > b.model) {
        return 1;
      } else if (a.model < b.model) {
        return -1;
      } else return 0;
    });

    const selectedBrand = data.vehicle_type && data.vehicle_type.brand;

    const brandOptions = uniqBy(vehicleTypes, "brand").map((type) => ({
      label: type.brand,
      value: type.brand,
    }));

    const modelOptions = vehicleTypes
      .filter((type) => type.brand === selectedBrand)
      .map((type) => ({
        label: type.model,
        value: type.id,
      }));

    const categoriesOptions = vehicleCategories.map((category) => ({
      label: category.description,
      value: category.id,
    }));

    const fuelTypesOptions = fuelTypes.map((fuel) => ({
      label: fuel.description,
      value: fuel.id,
    }));

    const modalSave = (
      <div
        className="modal fade"
        id="modal-save-changes"
        aria-hidden="true"
        aria-labelledby="saveChangesLabel"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered modal-appcar">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-m-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                x
              </button>
              <h5 className="modal-title" id="saveChangesLabel">
                Atención
              </h5>
            </div>
            <div className="modal-body">
              <div className="row justify-content-center">
                <div className="col-auto text-center">
                  <img src={iconStare} width="66px" className="mt-3 mb-3" />
                  <h5 className="mb-4 fs-30 fw-900">¡Ojo!</h5>
                  <p className="mb-4 fs-15 fw-600" style={{ maxWidth: 342 }}>
                    Debes completar los campos antes de continua
                  </p>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-auto">
                  <button
                    type="button"
                    className="btn modal-btn"
                    data-dismiss="modal"
                    data-bs-dismiss="modal"
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    let menuList = [];
    const menuTexts = ["Datos", "Fotos", "Documentos", "Características"];
    for (let i = 0; i < 4; i++) {
      const item = (
        <li
          className={`list-group-item  ${i === activeStep ? "active" : ""}`}
          role="button"
          onClick={() => {
            if (i !== activeStep) {
              this.handleSubmit(i);
            }
          }}
          key={i}
        >
          <span className="list-number">{i + 1}</span>
          {menuTexts[i]}
        </li>
      );
      menuList.push(item);
    }

    let activeComponent = null;

    switch (activeStep) {
      case 0:
        activeComponent = (
          <VehicleData
            data={data}
            handleChange={this.handleChange}
            handleChangeObj={this.handleChangeObj}
            handleChangeModel={this.handleChangeModel}
            brands={brandOptions}
            models={modelOptions}
            categories={categoriesOptions}
            fuelTypes={fuelTypesOptions}
            transmissions={vehicleTransmissions}
            addFeature={this.addFeature}
            removeFeature={this.removeFeature}
            features={vehicleFeatures}
            isSubmitting={isSubmitting}
            handleSubmit={this.handleSubmit}
          />
        );
        break;
      case 1:
        activeComponent = (
          <VehiclePictures
            openFileDialog={this.openFileDialog}
            data={data}
            handleSubmit={this.handleSubmit}
            isSubmitting={isSubmitting}
            removePicture={this.removePicture}
            justLoadedAdditionalPicture={justLoadedAdditionalPicture}
            reportUpdatedLoadingMore={this.reportUpdatedLoadingMore}
          />
        );
        break;
      case 2:
        activeComponent = (
          <VehicleDocuments
            openFileDialog={this.openFileDialogDocuments}
            data={data}
            handleSubmit={() => this.handleSubmit(3)}
            isSubmitting={isSubmitting}
            handleChangeObj={this.handleChangeObj}
            handleChange={this.handleChange}
            removePicture={this.removePicture}
          />
        );
        break;
      case 3:
        activeComponent = (
          <VehicleFeatures
            data={data}
            addFeature={this.addFeature}
            removeFeature={this.removeFeature}
            isSubmitting={isSubmitting}
            handleSubmit={this.handleSubmit}
            vehicleFeatures={vehicleFeatures}
            handleChange={this.handleChange}
          />
        );
        break;
    }

    let advice = null;
    if (activeStep == 1) {
      advice = (
        <div className="photo-advice">
          <div className="icon">
            <img src={iconWink} width={60} />
          </div>
          <div className="advice">
            Al agregar varias fotos mejoras la publicación de tu auto
          </div>
        </div>
      );
    }
    return (
      <>
        <Spinner loading={isSubmitting} />
        <Alert
          alertClass={messageType}
          isShowing={showMessage}
          messages={messages}
          title={messageTitle}
          onClose={this.handleAlertClose}
        />
        <div className="edit-box">
          <div className="photo-container">
            <div
              className="image-loader pl-md-5 pb-0"
              style={{ marginBottom: 200 }}
            >
              <h2 className="fs-30">Agrega tu auto</h2>
              <h3 className="fs-12">Parte {activeStep + 1} de 4</h3>
              <ul className="list-group appcar-list pt-4">{menuList}</ul>
            </div>
            {advice}
          </div>
          <div className="form">
            {activeComponent}
            <input
              type="file"
              id="image_uploader"
              className="d-none"
              onChange={(e) => this.uploadPicture(e)}
              accept="image/jpeg, image/png, image/jpg"
              onClick={(e) => {
                e.target.value = null;
              }}
            />
            <input
              type="file"
              id="image_uploader_documents"
              className="d-none"
              onChange={(e) => this.uploadPicture(e)}
              accept="image/jpeg, image/png, image/jpg, application/pdf"
              onClick={(e) => {
                e.target.value = null;
              }}
            />
          </div>
        </div>
        {modalSave}
      </>
    );
  }
}
