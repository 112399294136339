import React, { Component } from "react";
import ImageContainer from "../../common/ImageContainer";
import classNames from "classnames";
import { InputFeedback } from "../../common/CustomInputs";
import { formateaRut, generateFilename, validateRUT } from "../../common/constants";

class VehicleRegistrationDocument extends Component {
  state = {
    full_path: "",
    touched_name: false,
    touched_id_number: false
  };

  openFileDialog = full_path => {
    this.setState({ full_path }, () => $("#documents_uploader").click());
  };

  addPicture = e => {
    let file = e.target.files;
    if (file.length > 0) {
      const filename = generateFilename(file[0].name);      
      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onloadend = function (e) {
        var base64result = reader.result;
        this.props.addDocumentation(
          base64result,
          filename,
          this.state.full_path
        );
      }.bind(this);
    }
  };

  removePicture = (index, full_path) => {
    this.props.removeDocumentation(index, full_path);
  };

  handlePrev = e => {
    e.preventDefault();
    // Comentar ésto y descomentar abajo para dejar obligatorio
    this.props.jumpToStep(1);
    $("html, main").animate({ scrollTop: 0 }, "slow");

    // Dejando padrón obligatorio
    // const { name, id_number } = this.props.registry;
    // const error_id_number = this.props.registrationDocument.vehicular_technical_revision.length > 0 && !id_number;
    // const error_name = this.props.registrationDocument.vehicular_technical_revision.length > 0 && !name;
    // if (!error_id_number && !error_name) {
    //   this.props.jumpToStep(1);
    //   $("html, main").animate({ scrollTop: 0 }, "slow");
    // } else {
    //   this.setState({ touched_id_number: true, touched_name: true }, () => {
    //     const anchor = $("#anchor");
    //     $("html, main").animate({ scrollTop: anchor.offset().top - 88 }, "slow");
    //   })
    // }
  };

  handleNext = e => {
    e.preventDefault();
    // Comentar ésto y descomentar abajo para dejar obligatorio
    this.props.jumpToStep(3);
    $("html, main").animate({ scrollTop: 0 }, "slow");

    // Dejando padrón obligatorio
    // const { name, id_number } = this.props.registry;
    // const error_id_number = this.props.registrationDocument.vehicular_technical_revision.length > 0 && !id_number;
    // const error_name = this.props.registrationDocument.vehicular_technical_revision.length > 0 && !name;
    // if (!error_id_number && !error_name) {
    //   this.props.jumpToStep(3);
    //   $("html, main").animate({ scrollTop: 0 }, "slow");
    // } else {
    //   this.setState({ touched_id_number: true, touched_name: true }, () => {
    //     const anchor = $("#anchor");
    //     $("html, main").animate({ scrollTop: anchor.offset().top - 88 }, "slow");
    //   })
    // }
  };

  handleBlurIdNumber = e => {
    this.setState({ touched_id_number: true });
    this.props.changeRegistry("id_number", formateaRut(e.target.value))
  }

  render() {
    const { registrationDocument, registry, insurancePolicy, changeRegistry } = this.props;
    const { touched_name, touched_id_number } = this.state;
    //Technical revision
    let tech_revision_pictures = registrationDocument.vehicular_technical_revision.map(
      (picture, i) => (
        <div className="col-md-6 my-3" key={i}>
          <ImageContainer
            start={picture.start}
            imageBase64={picture.image_base_64}
            removeImage={(index, fullPath) => {
              this.setState({ touched_name: false, touched_id_number: false }, () => {
                changeRegistry("name", "");
                changeRegistry("id_number", "")
                this.removePicture(index, fullPath)
              })
            }}
            fullPath="registration_document.vehicular_technical_revision"
            index={i}
            url={picture.url}
          />
        </div>
      )
    );
    if (tech_revision_pictures.length == 0)
      tech_revision_pictures.push(
        <div className="col-md-6 my-3" key="add">
          <ImageContainer
            addImage={this.openFileDialog}
            fullPath="registration_document.vehicular_technical_revision"
          />
        </div>
      );

    // Comentar ésto y descomentar abajo para dejar padrón obligatorio
    const error_name = false;
    // const error_name = registrationDocument.vehicular_technical_revision.length > 0 && !registry.name ? "Campo requerido" : false;
    let error_id_number = false;
    if (registrationDocument.vehicular_technical_revision.length > 0 && registry.id_number) {
      if (!validateRUT(registry.id_number)) error_id_number = "RUT inválido";
    }

    const padron_form = (
        <div className="col-md-6 my-3" key={"padron"}>
          <div className="col-lg-12">
            <div className="form-group mb-2">
              <label htmlFor="id_number">
                RUT del Padrón <i
                  className="mdi mdi-information-outline anc-tooltip"
                  data-toggle-tooltip-for="tooltip-padron"
                >
                  <span
                    className="tooltiptext tooltip-right"
                    id="tooltip-padron"
                  >
                    Estos datos del padrón son aquellos que se utilizan
                    siempre en el certificado de cobertura generado por la compañía
                    de seguros, independiente de los datos del usuario
                    o los de facturación.
                </span>
                </i>
              </label>
              <input
                type="text"
                name="id_number"
                id="registry_id_number"
                className={
                  "form-control appcar-input " +
                  classNames({
                    "is-invalid": touched_id_number && error_id_number
                  })
                }
                onBlur={this.handleBlurIdNumber}
                onChange={e => this.props.changeRegistry("id_number", e.target.value)}
                value={registry.id_number}
              />
              <InputFeedback error={touched_id_number && error_id_number} />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group mb-2">
              <label htmlFor="name">
                Nombre del Padrón
                </label>
              <input
                type="text"
                name="name"
                id="registry_name"
                className={
                  "form-control appcar-input " +
                  classNames({
                    "is-invalid": touched_name && error_name
                  })
                }
                onBlur={() => this.setState({ touched_name: true })}
                onChange={e => changeRegistry("name", e.target.value)}
                value={registry.name}
              />
              <InputFeedback error={touched_name && error_name} />
            </div>
          </div>
        </div>
      );

    tech_revision_pictures.push(padron_form);

    //Homologation
    let homologation_pictures = registrationDocument.homologation.map(
      (picture, i) => (
        <div className="col-md-6 my-3" key={i}>
          <ImageContainer
            start={picture.start}
            imageBase64={picture.image_base_64}
            removeImage={this.removePicture}
            fullPath="registration_document.homologation"
            index={i}
            url={picture.url}
          />
        </div>
      )
    );
    if (homologation_pictures.length == 0)
      homologation_pictures.push(
        <div className="col-md-6 my-3" key="add">
          <ImageContainer
            addImage={this.openFileDialog}
            fullPath="registration_document.homologation"
          />
        </div>
      );

    //Registration document
    let registration_document_pictures = registrationDocument.registration_document.map(
      (picture, i) => (
        <div className="col-md-6 my-3" key={i}>
          <ImageContainer
            start={picture.start}
            imageBase64={picture.image_base_64}
            removeImage={this.removePicture}
            fullPath="registration_document.registration_document"
            index={i}
            url={picture.url}
          />
        </div>
      )
    );
    if (registration_document_pictures.length == 0)
      registration_document_pictures.push(
        <div className="col-md-6 my-3" key="add">
          <ImageContainer
            addImage={this.openFileDialog}
            fullPath="registration_document.registration_document"
          />
        </div>
      );

    let soap_pictures = insurancePolicy.soap.map((picture, i) => (
      <div className="col-md-6 my-3" key={i}>
        <ImageContainer
          start={picture.start}
          imageBase64={picture.image_base_64}
          removeImage={this.removePicture}
          fullPath="insurance_policy.soap"
          index={i}
          url={picture.url}
        />
      </div>
    ));
    if (soap_pictures.length == 0)
      soap_pictures.push(
        <div className="col-md-6 my-3" key="add">
          <ImageContainer
            addImage={this.openFileDialog}
            fullPath="insurance_policy.soap"
          />
        </div>
      );

    return (
      <React.Fragment>
        <div className="my-0 step-names">
          <div className="step-title">
            <span>Datos del vehículo</span>
          </div>
          <div className="step-title">
            <span>Fotos del vehículo</span>
          </div>
          <div className="step-title">
            <span>Documentos</span>
          </div>
          <div className="step-title">
            <span>Accesorios</span>
          </div>
          {/* <div className="step-title">
            <span>Políticas</span>
          </div> */}
        </div>
        <div className="my-3 mx-5">
          <h1 className="my-4" style={{ textAlign: "center" }}>
            Por favor completa la siguiente información:
          </h1>
          <div className="container">
            <h4 className="mt-4">Padrón</h4>
            <div id="anchor" />
            <div className="row">{tech_revision_pictures}</div>
            <h4 className="mt-4">Revisión Técnica u homologación</h4>
            <div className="row">{homologation_pictures}</div>
            <h4 className="mt-4">Permiso de circulación</h4>
            <div className="row">{registration_document_pictures}</div>
            <h4 className="mt-4">SOAP</h4>
            <div className="row">{soap_pictures}</div>
          </div>
          <input
            type="file"
            id="documents_uploader"
            style={{ display: "none" }}
            onChange={e => this.addPicture(e)}
            accept="image/jpeg, image/png, image/jpg"
            onClick={e => {
              e.target.value = null;
            }}
          />
        </div>
        <div className="footer-buttons">
          <button
            className="btn btn-prev btn-appcar-primary btn-lg pull-left"
            onClick={e => this.handlePrev(e)}
          >
            Anterior
          </button>
          <button
            className="btn btn-next btn-appcar-primary btn-lg pull-right"
            onClick={e => this.handleNext(e)}
          >
            Siguiente
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default VehicleRegistrationDocument;
