import React, { Component, Fragment } from "react";
import { constants } from "../../../common/constants";
import LocationSelector from "../../../common/search_components/AppcarLocationSelector";
import DateTimePicker from "../../../common/search_components/AppcarDateTimePicker";
import Filters from "./Filters";

class Search extends Component {
  state = {
    isSubmitting: false,
    minPriceUpdate: constants.MIN_PRICE,
    maxPriceUpdate: constants.MAX_PRICE,
    showRange: false,
  };

  componentDidMount() {
    this.initializeCalendars();
    document.onclick = this.hideRange;
  }

  initializeCalendars = () => {
    const this_ = this;
    const options = {
      showDropdowns: true,
      maxYear: constants.MAX_YEAR,
      singleDatePicker: true,
      minDate: new Date(),
      locale: {
        format: "DD/MM/YYYY",
        cancelLabel: "Cancelar",
        applyLabel: "Aplicar",
        fromLabel: "Desde",
        toLabel: "Hasta",
        daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        firstDay: 1,
      },
    };

    $("#search_from").daterangepicker(options, (start) => {
      let { fromDate, fromTime, untilTime, untilDate } = this_.props.data;
      const fromHours = parseInt(fromTime.split(":")[0]);
      const fromMinutes = parseInt(fromTime.split(":")[1]);
      const untilHours = parseInt(untilTime.split(":")[0]);
      const untilMinutes = parseInt(untilTime.split(":")[1]);
      const from = moment(start)
        .hours(fromHours)
        .minutes(fromMinutes);
      let until = moment(untilDate, "DD/MM/YYYY")
        .hours(untilHours)
        .minutes(untilMinutes);

      if (from.valueOf() >= until.valueOf()) {
        until = from.add(1, "hours").clone();
        $("#search_until")
          .data("daterangepicker")
          .setStartDate(until.format("DD/MM/YYYY"));
        $("#search_until")
          .data("daterangepicker")
          .setEndDate(until.format("DD/MM/YYYY"));
        untilDate = until.format("DD/MM/YYYY");
        untilTime = until.format("HH:mm");
      }
      fromDate = from.format("DD/MM/YYYY");
      if (this_.props.shouldUpdateLocalDates) {
        const fromString = moment(
          fromDate + fromTime,
          "DD/MM/YYYYHH:mm"
        ).format();
        const untilString = moment(
          untilDate + untilTime,
          "DD/MM/YYYYHH:mm"
        ).format();
        localStorage.setItem("user_date_from", fromString);
        localStorage.setItem("user_date_until", untilString);
      }
      this.props.handleChangeDate({ fromDate, fromTime, untilDate, untilTime });
    });

    $("#search_until").daterangepicker(options, (start) => {
      let { fromDate, fromTime, untilTime, untilDate } = this_.props.data;
      const fromHours = parseInt(fromTime.split(":")[0]);
      const fromMinutes = parseInt(fromTime.split(":")[1]);
      const untilHours = parseInt(untilTime.split(":")[0]);
      const untilMinutes = parseInt(untilTime.split(":")[1]);
      let from = moment(fromDate, "DD/MM/YYYY")
        .hours(fromHours)
        .minutes(fromMinutes);
      let until = moment(start)
        .hours(untilHours)
        .minutes(untilMinutes);

      if (from.valueOf() >= until.valueOf()) {
        from = until.clone();
        $("#search_from")
          .data("daterangepicker")
          .setStartDate(from.format("DD/MM/YYYY"));
        $("#search_from")
          .data("daterangepicker")
          .setEndDate(from.format("DD/MM/YYYY"));
        fromDate = from.format("DD/MM/YYYY");
        fromTime = from.format("HH:mm");
        until = from.add(1, "hours").clone();
        $("#search_until")
          .data("daterangepicker")
          .setStartDate(until.format("DD/MM/YYYY"));
        $("#search_until")
          .data("daterangepicker")
          .setEndDate(until.format("DD/MM/YYYY"));
        untilDate = until.format("DD/MM/YYYY");
        untilTime = until.format("HH:mm");
      }
      untilDate = until.format("DD/MM/YYYY");
      if (this_.props.shouldUpdateLocalDates) {
        const fromString = moment(
          fromDate + fromTime,
          "DD/MM/YYYYHH:mm"
        ).format();
        const untilString = moment(
          untilDate + untilTime,
          "DD/MM/YYYYHH:mm"
        ).format();
        localStorage.setItem("user_date_from", fromString);
        localStorage.setItem("user_date_until", untilString);
      }
      this.props.handleChangeDate({ fromDate, fromTime, untilDate, untilTime });
    });
  };

  changeFromTime = (fromTime) => {
    let { fromDate, untilDate, untilTime } = this.props.data;
    const from = moment(fromDate + fromTime, "DD/MM/YYYYHH:mm");
    let until = moment(untilDate + untilTime, "DD/MM/YYYYHH:mm");
    if (until.valueOf() <= from.valueOf()) {
      until = from.add(1, "hours").clone();
      $("#search_until")
        .data("daterangepicker")
        .setStartDate(until.format("DD/MM/YYYY"));
      $("#search_until")
        .data("daterangepicker")
        .setEndDate(until.format("DD/MM/YYYY"));
      untilDate = until.format("DD/MM/YYYY");
      untilTime = until.format("HH:mm");
    }
    if (this.props.shouldUpdateLocalDates) {
      const fromString = moment(
        fromDate + fromTime,
        "DD/MM/YYYYHH:mm"
      ).format();
      const untilString = moment(
        untilDate + untilTime,
        "DD/MM/YYYYHH:mm"
      ).format();
      localStorage.setItem("user_date_from", fromString);
      localStorage.setItem("user_date_until", untilString);
    }
    this.props.handleChangeDate({ fromDate, fromTime, untilDate, untilTime });
  };

  changeUntilTime = (untilTime) => {
    let { fromDate, fromTime, untilDate } = this.props.data;
    let from = moment(fromDate + fromTime, "DD/MM/YYYYHH:mm");
    let until = moment(untilDate + untilTime, "DD/MM/YYYYHH:mm");
    if (until.valueOf() <= from.valueOf()) {
      from = until.clone();
      $("#search_from")
        .data("daterangepicker")
        .setStartDate(until.format("DD/MM/YYYY"));
      $("#search_from")
        .data("daterangepicker")
        .setEndDate(until.format("DD/MM/YYYY"));
      fromDate = from.format("DD/MM/YYYY");
      fromTime = from.format("HH:mm");
      until = from.add(1, "hours").clone();
      $("#search_until")
        .data("daterangepicker")
        .setStartDate(until.format("DD/MM/YYYY"));
      $("#search_until")
        .data("daterangepicker")
        .setEndDate(until.format("DD/MM/YYYY"));
      untilDate = until.format("DD/MM/YYYY");
      untilTime = until.format("HH:mm");
    }
    if (this.props.shouldUpdateLocalDates) {
      const fromString = moment(
        fromDate + fromTime,
        "DD/MM/YYYYHH:mm"
      ).format();
      const untilString = moment(
        untilDate + untilTime,
        "DD/MM/YYYYHH:mm"
      ).format();
      localStorage.setItem("user_date_from", fromString);
      localStorage.setItem("user_date_until", untilString);
    }
    this.props.handleChangeDate({ fromDate, fromTime, untilDate, untilTime });
  };

  componentDidUpdate() {
    $("#search_from")
      .data("daterangepicker")
      .setStartDate(moment(this.props.data.fromDate, "DD/MM/YYYY"));
    $("#search_from")
      .data("daterangepicker")
      .setEndDate(moment(this.props.data.fromDate, "DD/MM/YYYY"));
    $("#search_until")
      .data("daterangepicker")
      .setStartDate(moment(this.props.data.untilDate, "DD/MM/YYYY"));
    $("#search_until")
      .data("daterangepicker")
      .setEndDate(moment(this.props.data.untilDate, "DD/MM/YYYY"));
    // const { minPriceUpdate, maxPriceUpdate } = this.state;
    // const { minPrice, maxPrice } = this.props.data;
    // if (minPriceUpdate != minPrice) this.setState({ minPriceUpdate: minPrice });
    // if (maxPriceUpdate != maxPrice) this.setState({ maxPriceUpdate: maxPrice });
  }

  handleSelectLocation = ({ success, lat, lon, address }) => {
    this.props.handleSelectLocation(success, address, lat, lon);
  };

  showRange = () => this.setState({ showRange: true });

  hideRange = (event) => {
    const rangeContainer = document.getElementById("range-container");
    const rangeInput = document.getElementById("range-input");
    if (rangeContainer && !rangeInput.contains(event.target)) {
      const rangeContainerBounds = rangeContainer.getBoundingClientRect();
      const eventX = event.pageX;
      const eventY = event.pageY;
      if (
        eventX < rangeContainerBounds.left ||
        eventX > rangeContainerBounds.right ||
        eventY < rangeContainerBounds.top ||
        eventY > rangeContainerBounds.bottom
      )
        this.setState({ showRange: false });
    }
  };

  updateRange = (values) => {
    const minPriceUpdate = values[0];
    const maxPriceUpdate = values[1];
    this.setState({ minPriceUpdate, maxPriceUpdate });
  };

  render() {
    const {
      vehicleExtras,
      vehicleFeatures,
      vehicleCategories,
      vehicleTransmissions,
      shouldShowFilters,
      handleChange,
      loadSpinner,
      changeRange,
      callbackSubmit,
      textColorClass,
      modalFilters,
      resetFilters,
      openFullMap,
      closeFullMap,
      showMobileMap,
    } = this.props;

    const {
      fromDate,
      fromTime,
      untilDate,
      untilTime,
      selectedExtras,
      selectedFeatures,
      selectedCategories,
      selectedTransmissions,
      selectedSeats,
      selectedKm,
      minPrice,
      maxPrice,
      checkAirport,
      address,
      selectedOrder,
    } = this.props.data;

    const {
      isSubmitting,
      maxPriceUpdate,
      minPriceUpdate,
      showRange,
    } = this.state;

    const mainFilterLabelClassName = !shouldShowFilters
      ? "label-big " + textColorClass
      : "location-special-label";
    const locationSelectorLabelText = !shouldShowFilters
      ? "¿Dónde te gustaría recoger tu auto?"
      : "Dónde";

    const airportCheck = (
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id="checkAirport"
          name="checkAirport"
          checked={checkAirport}
          onChange={(e) => handleChange("checkAirport", e.target.checked)}
        />
        <label
          className={`form-check-label ${textColorClass}`}
          htmlFor="checkAirport"
        >
          Entrega / Devolución en Aeropuerto
        </label>
      </div>
    );

    const locationInput = (
      <div className="col-12 col-xl-4 mb-2">
        <div className="form-group">
          <LocationSelector
            countries={["cl"]}
            handleSelectLocation={this.handleSelectLocation}
            placeholder="Ciudad, aeropuerto, dirección..."
            label={locationSelectorLabelText}
            airportsFirst={checkAirport}
            loadSpinner={loadSpinner}
            labelClass={mainFilterLabelClassName}
            address={address}
          />
        </div>
      </div>
    );

    let filtersContainer = null;
    let btnShowFilters = null;

    if (shouldShowFilters) {
      const optionsExtras = vehicleExtras.map((v) => ({
        label: v.a_name,
        value: v.id,
      }));

      const optionsFeatures = vehicleFeatures.map((v) => ({
        label: v.a_name,
        value: v.id,
      }));
      // .filter((x) => x.value != constants.MAX_250); // TODO - JUAN: Descomentar si no sacan el valor de los features

      const optionsCategories = vehicleCategories.map((v) => ({
        label: v.description,
        value: v.id,
      }));

      const optionsTransmissions = vehicleTransmissions.map((v) => ({
        label: v.description,
        value: v.id,
      }));

      filtersContainer = (
        <Filters
          selectedExtras={selectedExtras}
          selectedFeatures={selectedFeatures}
          selectedCategories={selectedCategories}
          selectedTransmissions={selectedTransmissions}
          selectedSeats={selectedSeats}
          selectedKm={selectedKm}
          minPrice={minPrice}
          minPriceUpdate={minPriceUpdate}
          maxPrice={maxPrice}
          maxPriceUpdate={maxPriceUpdate}
          optionsExtras={optionsExtras}
          optionsFeatures={optionsFeatures}
          optionsCategories={optionsCategories}
          optionsTransmissions={optionsTransmissions}
          isShowingRange={showRange}
          showRange={this.showRange}
          changeRange={changeRange}
          updateRange={this.updateRange}
          handleChange={handleChange}
          resetFilters={resetFilters}
          selectedOrder={selectedOrder}
          showMobileMap={showMobileMap}
        />
      );

      const btnMapText = showMobileMap ? "Vista lista" : "Vista mapa";
      const btnMapIcon = showMobileMap
        ? "mdi mdi-view-list mr-1"
        : "mdi mdi-map mr-1";
      btnShowFilters = (
        <div className="mobile-show-filters">
          <div className="btn-group" role="group">
            <button
              type="button"
              onClick={() => {
                if (showMobileMap) {
                  closeFullMap();
                } else {
                  openFullMap();
                }
              }}
              className="btn btn-appcar-primary btn-mobile-filter"
            >
              <i className={btnMapIcon}></i>
              {btnMapText}
            </button>
            <span className="separator"></span>
            <button
              type="button"
              onClick={() => {
                if (!!modalFilters) {
                  modalFilters.show();
                }
              }}
              className="btn btn-appcar-primary btn-mobile-filter"
            >
              <i className="mdi mdi-filter-variant mr-1"></i>
              Filtros
            </button>
          </div>
        </div>
      );
    }

    return (
      <Fragment>
        <div className={`rentals-search ${textColorClass}`}>
          <div className="row justify-content-center main-filter">
            {locationInput}
            <div className="col-12 col-lg mb-2">
              <div className="form-group">
                <label className={mainFilterLabelClassName}>Desde</label>
                <DateTimePicker
                  changeTime={(option) => this.changeFromTime(option.value)}
                  date={fromDate}
                  pickerId="search_from"
                  time={fromTime}
                />
              </div>
            </div>
            <div className="col-12 col-lg mb-2">
              <div className="form-group">
                <label className={mainFilterLabelClassName}>Hasta</label>
                <DateTimePicker
                  changeTime={(option) => this.changeUntilTime(option.value)}
                  date={untilDate}
                  pickerId="search_until"
                  time={untilTime}
                />
              </div>
            </div>
            <div className="col-auto mb-2 align-self-end">
              <button
                type="button"
                className="btn btn-appcar-primary btn-search"
                onClick={callbackSubmit}
              >
                <i className="mdi mdi-magnify"></i>
                Buscar
              </button>
            </div>
          </div>
          {filtersContainer}
          {btnShowFilters}
        </div>
      </Fragment>
    );
  }
}

export default Search;
