import React, { Component } from "react";
import { formatedPricing } from "../../constants";
import CardCarousel from "../../CardCarousel";

const SearchResultsCard = ({ rental, link, goToLocation, children, mouseEnter, plateChild = "" }) => {
  const vehicle = rental.vehicle;

  const car_data = `${vehicle.vehicle_type.brand} ${vehicle.vehicle_type.model} | ${vehicle.year}${plateChild}`;
  let vehicle_pictures = vehicle.vehicle_pictures;
  const extras = (rental.pricing.extra_charges || []).map(x => {
    return x.vehicle_extra.a_name;
  });
  const extrasContainer = (
    <div className="item-extras">
      <div className="item-extras-text">{extras.join(" · ")}</div>
    </div>
  );
  const features = (vehicle.vehicle_features || []).map(f => {
    return f.a_name;
  });
  const featuresContainer = (
    <div className="item-extras">
      <div className="item-extras-text">{features.join(" · ")}</div>
    </div>
  );
  const pricing = (
    <div className="item-pricing">
      <div className="item-price">
        <span>{formatedPricing(rental.pricing.base_price)}/día &gt; IVA Inc.</span>
        <br />
        <span style={{ fontSize: "10px" }}>Precio final a pagar</span>
      </div>
    </div>
  );
  const buttons = (
    <div className="item-buttons">
      <a
        className="btn btn-appcar-primary btn-sm"
        href={link}
        rel="noopener noreferrer"
        target="_blank"
      >
        Ir a la publicación
      </a>
      <button
        type="button"
        className="ml-2 btn-marker"
        title="Ver en el mapa"
        onClick={() =>
          goToLocation({
            lat: rental.location.latitude,
            lng: rental.location.longitude
          }, rental.id)}
      >
        <i className="mdi mdi-map-marker" />
      </button>
    </div>
  );
  return (
    <div className="map-search-result">
      <div className="result-container">
        <div className="search-result-item d-block d-sm-table" onMouseEnter={mouseEnter}>
          <div className="carousel-container">
            <CardCarousel images={vehicle_pictures} />
          </div>
          <div className="item-info">
            <div className="item-info-container">
              <div className="item-info-m">
                <div>
                  <div className="item-title">
                    <div className="item-title-text">{car_data}</div>
                  </div>
                  {extrasContainer}
                  {featuresContainer}
                </div>
                {children}
              </div>
              {pricing}
              {buttons}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchResultsCard;
