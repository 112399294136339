import React from "react";
import { constants } from "../../constants";
import Select from "react-select";

const TableLimitSelector = ({ paginationInformation, onLimitChange }) => {
  const selectedValue = { value: paginationInformation.limit, label: paginationInformation.limit }
  const options = constants.RESULTS_PER_PAGE.map(val => ({ value: val, label: val }));
  return (
    <div className="dataTables_length">
      <label className="d-inline-block">Mostrar
        <div className="d-inline-block mx-2" style={{ width: 100 }}>
          <Select
            value={selectedValue}
            options={options}
            onChange={option => onLimitChange(option.value)}
            isSearchable={false}
            isClearable={false}
            classNamePrefix="RS-FIX"
            className="react-select-fix mr-2 mr-4 w-100"
            id="limit_select"
            style={{
              width: 100
            }}
          />
        </div>
        registros
      </label>
    </div>
  )
}

export default TableLimitSelector;