import React from "react";
import appcarMPBrand from "images/brand/AppCarMP.png";
import MPBrand from "images/brand/MP.png";

const ModalMercadoPago = ({ action }) => (
  <div
    className="modal fade"
    id="MPModal"
    aria-hidden="true"
    aria-labelledby="MPModalLabel"
    tabIndex={-1}
  >
    <div className="modal-dialog modal-dialog-centered modal-appcar">
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="btn-m-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            x
          </button>
          <h5 className="modal-title" id="MPModalLabel">
            ¡Novedades!
          </h5>
        </div>
        <div className="modal-body">
          <div className="row my-4 align-items-center justify-content-center">
            <div className="col-auto pr-0">
              <img src={appcarMPBrand} alt="AppCar" width={123} />
            </div>
            <div className="col-auto pl-0">
              <img src={MPBrand} alt="Mercado Pago" width={170} />
            </div>
          </div>
          <div
            className="text-center mb-4"
            style={{ maxWidth: 290, margin: "auto" }}
          >
            <p className="fs-12">
              Te contamos que ahora Appcar tiene una nueva alianza con{" "}
              <b>MercadoPago</b> y todas las transacciones se realizarán a
              través de ese medio de pago.
            </p>
            <p className="text-appcar-primary fw-700">
              <i>Arrendar un auto jamás fue tan fácil</i>
            </p>
            <button
              type="button"
              onClick={() => action()}
              className="btn btn-appcar-primary btn-slim w-50"
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ModalMercadoPago;
