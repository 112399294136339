import React, { Component } from "react";
import RentalsWizard from "./form/RentalsWizard";
import ThanksModalRental from "../../common/legal_modals/ThanksModalRental";

export default class RentalsEdit extends Component {
  modalThanks = null;
  componentDidMount() {
    const modalEl = document.getElementById("thanksModal");
    this.modalThanks = new bootstrap.Modal(modalEl);
    modalEl.addEventListener("hidden.bs.modal", (ev) => {
      this.redirectToDashboard();
    });
  }
  redirectToDashboard = () => {
    window.location.assign("/vehicles?success=1");
  };
  openModal = () => {
    this.modalThanks.show();
  };
  render() {
    const modal = <ThanksModalRental action={this.redirectToDashboard} />;
    const rental = this.props.rental;
    return (
      <>
        <RentalsWizard
          vehicle={this.props.vehicle}
          rental={rental}
          extras={this.props.extras}
          formAction={this.openModal}
          title="Tu publicación"
          activeStep={this.props.activeStep}
          saveLink={`/`}
          editVehicleLink={`/vehicles/${this.props.vehicle.id}/edit`}
          recommendedPrice={this.props.recommendedPrice}
          appraisal={this.props.appraisal}
          vehicleTypes={this.props.vehicleTypes}
          vehicleCategories={this.props.vehicleCategories}
          fuelTypes={this.props.fuelTypes}
          vehicleTransmissions={this.props.vehicleTransmissions}
          vehicleFeatures={this.props.vehicleFeatures}
        />
        {modal}
      </>
    );
  }
}
