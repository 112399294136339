import React, { Component } from "react";
import moment from "moment";
import chunk from "lodash/chunk";
import { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import BookingCard from "./BookingCard";
import { BookingStatus } from "../../common/constants";
moment.locale("es");

export default class BookingList extends Component {
  state = {
    currentView: "now"
  }
  render() {

    const { bookings } = this.props;
    const currentView = this.state.currentView;
    const width = window.innerWidth;
    let showingBookings = 6;

    if (width >= 800 && width < 1200) {
      showingBookings = 4;
    } else if (width >= 1200) {
      showingBookings = 6;
    }

    let bookingsBy = [];
    const now = moment();

    // Date filter
    // if (currentView == "now") {
    //   bookingsBy = bookings.filter(b => now.valueOf() >= moment(b.date_from) && now.valueOf() <= moment(b.date_until));
    // } else if (currentView == "past") {
    //   bookingsBy = bookings.filter(b => now.valueOf() > moment(b.date_until));
    // } else {
    //   bookingsBy = bookings;
    // }

    // Status filter
    if (currentView == "now") {
      bookingsBy = bookings.filter(b => [BookingStatus.PENDIENTE, BookingStatus.ACTIVO, BookingStatus.INICIADO, BookingStatus.EN_REVISION].includes(b.status.id));
    } else if (currentView == "past") {
      bookingsBy = bookings.filter(b => [BookingStatus.COMPLETADO].includes(b.status.id))
    } else {
      bookingsBy = bookings;
    }
    const groupedBookings = chunk(bookingsBy, showingBookings);

    const bookingSlides = groupedBookings.map((bookingGroup, i) => (
      <SwiperSlide key={i}>
        <div className="booking-cards-page">
          {bookingGroup.map((booking, j) => (
            <BookingCard
              booking={booking}
              key={j}
            />
          ))}
        </div>
      </SwiperSlide>
    ))

    const slide = bookingsBy.length > 0 ? (
      <Swiper
        modules={[Navigation, Pagination, A11y]}
        slidesPerView={1}
        navigation
        pagination={width >= 800}
        autoHeight={true}
      >
        {bookingSlides}
      </Swiper >
    ) : (
      <div className="alert light my-5">No hay arriendos por acá...</div>
    )
    return (
      <>
        <ul className="nav nav-tabs tabs-appcar" id="booking-tabs" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              type="button"
              className={`nav-link ${currentView == "now" ? "active" : ""}`}
              role="tab"
              aria-selected={currentView == "now"}
              onClick={() => this.setState({ currentView: "now" })}
            >
              Vigentes
            </button>
          </li>
          <li className="nav-item mid-item" role="presentation">
            <button
              type="button"
              className={`nav-link ${currentView == "past" ? "active" : ""}`}
              role="tab"
              aria-selected={currentView == "past"}
              onClick={() => this.setState({ currentView: "past" })}
            >
              Pasados
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              type="button"
              className={`nav-link ${currentView == "all" ? "active" : ""}`}
              role="tab"
              aria-selected={currentView == "all"}
              onClick={() => this.setState({ currentView: "all" })}
            >
              Todos
            </button>
          </li>
        </ul>
        <div className="tab-content">
          {slide}
        </div>
      </>
    )
  }
}