import React from "react";
import btnPdf from "images/Icons/btn_PDF.svg";
import placeHolderPdf from "images/Icons/placeholder_PDF.svg";

const DocumentLoader = ({
  addImage,
  imageBase64,
  start,
  backgroundPlaceHolder,
  objectName,
  title,
  url = null,
  subtitle = "",
  canRemovePic = true,
  removeImage = () => {},
  replaceImage = null,
  filename = "",
  ...props
}) => {
  const isPdf =
    (start && start.includes("pdf")) || (url && url.endsWith(".pdf"));
  let textButton = "Subir foto";
  if (imageBase64 || url) {
    if (canRemovePic) textButton = "Quitar foto";
    else textButton = "Cambiar foto";
  }
  const actionButton = (
    <div className="btn-image-loader">
      <button
        onClick={() => {
          if (imageBase64 || url) {
            if (canRemovePic) removeImage(objectName);
            else replaceImage(objectName);
          } else {
            addImage(objectName);
          }
        }}
        type="button"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <i className={`mdi mdi-image-${imageBase64 || url ? "off" : "plus"}`} />
      </button>
      <span>{textButton}</span>
    </div>
  );
  let secondAction =
    canRemovePic &&
    typeof replaceImage === "function" &&
    (imageBase64 || url) ? (
      <div className="btn-image-loader">
        <button onClick={() => replaceImage(objectName)} type="button">
          <i className="mdi mdi-image-edit" />
        </button>
        <span>Cambiar foto</span>
      </div>
    ) : null;

  const downloadLinkSource =
    isPdf && url && url.endsWith(".pdf") ? url : start + "," + imageBase64;
  const fileName =
    isPdf && url && url.endsWith(".pdf") ? url.split("/").pop() : filename;
  const downloadPdf = isPdf ? (
    <div className="btn-image-loader">
      <a
        href={downloadLinkSource}
        download={fileName}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        target="_blank"
      >
        <img
          src={btnPdf}
          style={{ width: 20, height: "auto", margin: "auto", borderRadius: 0 }}
        />
      </a>
      <span>Descargar</span>
    </div>
  ) : null;
  let backgroundImage = backgroundPlaceHolder;
  if (imageBase64) {
    backgroundImage = start + "," + imageBase64;
  } else if (!!url) backgroundImage = url;
  if (isPdf) backgroundImage = placeHolderPdf;
  const isPdfStyleBackground = isPdf ? "is-pdf" : "";
  const subText = !!subtitle ? (
    <div className="d-flex">
      <p
        style={{ marginLeft: 12, width: 215 }}
        className="text-center fs-11 fw-800 text-appcar-primary mb-0"
      >
        {subtitle}
      </p>
    </div>
  ) : null;
  return (
    <div className="document-loader" {...props}>
      <span className="loader-title">{title}</span>
      <div className="document-image-loader">
        <img src={backgroundImage} className={isPdfStyleBackground} />
        <div className="d-flex flex-column" style={{ marginLeft: 20 }}>
          {actionButton}
          {secondAction}
          {downloadPdf}
        </div>
      </div>
      {subText}
    </div>
  );
};

export default DocumentLoader;
