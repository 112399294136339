import React, { Component, Fragment } from "react";
import Spinner from "../../../common/Spinner";
import * as _ from "lodash";
import Search from "./Search";
import { constants } from "../../../common/constants";

export default class LandingSearch extends Component {
  state = {
    data: {
      address: "",
      lat: null,
      lon: null,
      fromDate: moment(constants.RENTAL_SEARCH_FROM).format("DD/MM/YYYY"),
      fromTime: moment(constants.RENTAL_SEARCH_FROM).format("HH:mm"),
      untilDate: moment(constants.RENTAL_SEARCH_UNTIL).format("DD/MM/YYYY"),
      untilTime: moment(constants.RENTAL_SEARCH_UNTIL).format("HH:mm"),
      checkAirport: this.props.subfilter ? this.props.subfilter.toString().indexOf("aeropuerto") > -1 : false
    },
    isSubmitting: false
  }

  componentDidMount() {
    moment.locale("es");

    let from;
    let until;
    let fromDate = "";
    let fromTime = "";
    let untilDate = "";
    let untilTime = "";
    const propsDateFrom = !!this.props.dateFrom ? moment(this.props.dateFrom) : moment.invalid();
    const propsDateUntil = !!this.props.dateUntil ? moment(this.props.dateUntil) : moment.invalid();
    const storedDateFrom = moment(localStorage.getItem("user_date_from"));
    const storedDateUntil = moment(localStorage.getItem("user_date_until"));
    const defaultDateFrom = moment(constants.RENTAL_SEARCH_FROM);
    const defaultDateUntil = moment(constants.RENTAL_SEARCH_UNTIL);

    if (propsDateFrom.isValid()) {
      from = propsDateFrom;
    } else if (storedDateFrom.isValid() && storedDateFrom.valueOf() > defaultDateFrom.valueOf()) {
      from = storedDateFrom;
    } else {
      from = defaultDateFrom;
    }

    if (propsDateUntil.isValid()) {
      until = propsDateUntil;
    } else if (storedDateUntil.isValid() && storedDateUntil.valueOf() > defaultDateUntil.valueOf() && storedDateUntil.valueOf() > from.valueOf()) {
      until = storedDateUntil;
    } else {
      until = defaultDateUntil;
    }

    fromDate = from.format("DD/MM/YYYY");
    fromTime = from.format("HH:mm");
    untilDate = until.format("DD/MM/YYYY");
    untilTime = until.format("HH:mm");
    this.setState(state => {
      state.data.fromDate = fromDate;
      state.data.fromTime = fromTime;
      state.data.untilDate = untilDate;
      state.data.untilTime = untilTime;
      return { data: state.data };
    }, () => {
      localStorage.setItem("user_date_from", from.format());
      localStorage.setItem("user_date_until", until.format());
    })
  }

  handleSubmit = () => {
    this.setState({ isSubmitting: true });

    const {
      checkAirport,
      fromDate,
      fromTime,
      untilDate,
      untilTime,
      lat,
      lon,
      address
    } = this.state.data;

    const rad = constants.RENTAL_SEARCH_RADIUS;

    const URIfrom = "from=" + encodeURIComponent(moment(fromDate + fromTime, "DD/MM/YYYYHH:mm").format("YYYY-MM-DDTHH:mm:ssZ"));
    const URIuntil = "&until=" + encodeURIComponent(moment(untilDate + untilTime, "DD/MM/YYYYHH:mm").format("YYYY-MM-DDTHH:mm:ssZ"));
    const URIsubfilter = checkAirport ? "&subfilter=aeropuerto" : "";
    const URIradius = "&radius=" + encodeURIComponent(rad);
    const URIlat = "&lat=" + encodeURIComponent(lat);
    const URIlon = "&lon=" + encodeURIComponent(lon);
    const URIaddress = "&address=" + encodeURIComponent(address);

    const encodedParams =
      URIfrom + URIuntil + URIsubfilter + URIradius +
      URIlat + URIlon + URIaddress
    window.location.href = "/appcar/rentals?" + encodedParams;
  }

  handleChange = (key, value) => this.setState(state => {
    state.data[key] = value;
    return { data: state.data };
  })

  handleChangeLocation = (success, address, lat, lon) => {
    if (success) {
      this.setState(state => {
        state.data.address = address;
        state.data.lat = lat;
        state.data.lon = lon;
        return { data: state.data, isSubmitting: false };
      });
    } else this.setState({ isSubmitting: false });
  }

  handleChangeDate = ({fromDate, fromTime, untilDate, untilTime}) => this.setState(state => {
    state.data.fromDate = fromDate;
    state.data.fromTime = fromTime;
    state.data.untilDate = untilDate;
    state.data.untilTime = untilTime;
    return { data: state.data };
  });

  loadSpinner = () => this.setState({ isSubmitting: true });

  changeRange = values => {
    const minPrice = values[0];
    const maxPrice = values[1];
    this.setState(state => {
      state.data.minPrice = minPrice;
      state.data.maxPrice = maxPrice;
      return { data: state.data };
    });
  };

  render() {
    const {
      data,
      isSubmitting
    } = this.state;
    return (
      <Fragment>
        <Spinner loading={isSubmitting} />
        <div>
          <Search
            data={data}
            vehicleExtras={[]}
            vehicleFeatures={[]}
            vehicleCategories={[]}
            vehicleTransmissions={[]}
            shouldShowFilters={false}
            handleChange={this.handleChange}
            handleSelectLocation={this.handleChangeLocation}
            handleChangeDate={this.handleChangeDate}
            loadSpinner={this.loadSpinner}
            callbackSubmit={this.handleSubmit}
            changeRange={this.changeRange}
            textColorClass={this.props.textColorClass || "text-white"}
          />
        </div>
      </Fragment>
    )
  }
}
