import React, { Component } from "react";

class VehicleInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      style_hide_text: {
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundImage: "linear-gradient(to bottom, transparent, white)"
      },
      style_text: {
        maxHeight: "7rem",
        overflow: "hidden"
      },
      is_showing: false,
      shouldCropDescription: false,
      shouldCropFeatures: false,
      shouldCropExtras: false,
      shouldCropRestrictions: false
      
    };
    this.handleResize = this.handleResize.bind(this);
  }
  
  toggleText = (e, id) => {
    e.preventDefault();
    let el = document.getElementById(id);
    if (!this.state.is_showing) {
      el.style.maxHeight = "none";
      el.previousSibling.style.backgroundImage = "none";
      e.target.textContent = "Menos";
      this.setState(state => {
        state.is_showing = true;
        return { button_text: state.button_text, is_showing: state.is_showing };
      });
    } else {
      el.style.maxHeight = "7rem";
      el.previousSibling.style.backgroundImage =
        "linear-gradient(to bottom, transparent, white)";
      e.target.textContent = "Más";
      this.setState(state => {
        state.is_showing = false;
        return { button_text: state.button_text, is_showing: state.is_showing };
      });
    }
  };

  handleResize() {
    const description = document.getElementById("description");
    const features = document.getElementById("features");
    const extras = document.getElementById("extras");
    const restrictions = document.getElementById("restrictions");
    const descriptionHeight = description ? description.clientHeight : 0;
    const featuresHeight = features ? features.clientHeight : 0;
    const extrasHeight = extras ? extras.clientHeight : 0;
    const restrictionsHeight = restrictions ? restrictions.clientHeight : 0;
    const remDescription = parseInt(getComputedStyle(description || document.documentElement).fontSize.replace("px", ""));
    const remFeatures = parseInt(getComputedStyle(features || document.documentElement).fontSize.replace("px", ""));
    const remExtras = parseInt(getComputedStyle(extras || document.documentElement).fontSize.replace("px", ""));
    const remRestrictions = parseInt(getComputedStyle(restrictions || document.documentElement).fontSize.replace("px", ""));
    
    if (descriptionHeight > 0 && descriptionHeight / (remDescription * 1.5) > 3) {
      if (!this.state.shouldCropDescription)
        this.setState(state => {
          state.shouldCropDescription = true;
          return { shouldCropDescription: state.shouldCropDescription };
        });
    } else {
      if (this.state.shouldCropDescription)
        this.setState(state => {
          state.shouldCropDescription = false;
          return { shouldCropDescription: state.shouldCropDescription };
        });
    }

    if (featuresHeight > 0 && featuresHeight / (remFeatures * 1.5) > 3) {
      if (!this.state.shouldCropFeatures)
        this.setState(state => {
          state.shouldCropFeatures = true;
          return { shouldCropFeatures: state.shouldCropFeatures };
        });
    } else {
      if (this.state.shouldCropFeatures)
        this.setState(state => {
          state.shouldCropFeatures = false;
          return { shouldCropFeatures: state.shouldCropFeatures };
        });
    }

    if (extrasHeight > 0 && extrasHeight / (remExtras * 1.5) > 3) {
      if (!this.state.shouldCropExtras)
        this.setState(state => {
          state.shouldCropExtras = true;
          return { shouldCropExtras: state.shouldCropExtras };
        });
    } else {
      if (this.state.shouldCropExtras)
        this.setState(state => {
          state.shouldCropExtras = false;
          return { shouldCropExtras: state.shouldCropExtras };
        });
    }

    if (restrictionsHeight > 0 && restrictionsHeight / (remRestrictions * 1.5) > 3) {
      if (!this.state.shouldCropRestrictions)
        this.setState(state => {
          state.shouldCropRestrictions = true;
          return { shouldCropRestrictions: state.shouldCropRestrictions };
        });
    } else {
      if (this.state.shouldCropRestrictions)
        this.setState(state => {
          state.shouldCropRestrictions = false;
          return { shouldCropRestrictions: state.shouldCropRestrictions };
        });
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize)
  }

  componentWillUnmount() {
    window.addEventListener("resize", this.handleResize)
  }

  componentDidUpdate() {
    const description = document.getElementById("description");
    const features = document.getElementById("features");
    const extras = document.getElementById("extras");
    const restrictions = document.getElementById("restrictions");
    const descriptionHeight = description ? description.clientHeight : 0;
    const featuresHeight = features ? features.clientHeight : 0;
    const extrasHeight = extras ? extras.clientHeight : 0;
    const restrictionsHeight = restrictions ? restrictions.clientHeight : 0;
    const remDescription = parseInt(getComputedStyle(description || document.documentElement).fontSize.replace("px", ""));
    const remFeatures = parseInt(getComputedStyle(features || document.documentElement).fontSize.replace("px", ""));
    const remExtras = parseInt(getComputedStyle(extras || document.documentElement).fontSize.replace("px", ""));
    const remRestrictions = parseInt(getComputedStyle(restrictions || document.documentElement).fontSize.replace("px", ""));
    if (descriptionHeight > 0 && descriptionHeight / (remDescription * 1.5) > 3) {
      if (!this.state.shouldCropDescription)
        this.setState(state => {
          state.shouldCropDescription = true;
          return { shouldCropDescription: state.shouldCropDescription };
        });
    } else {
      if (this.state.shouldCropDescription)
        this.setState(state => {
          state.shouldCropDescription = false;
          return { shouldCropDescription: state.shouldCropDescription };
        });
    }
  }

  render() {
    //Description
    const degradeDescription = this.state.shouldCropDescription ? (
      <div style={this.state.style_hide_text} />
    ) : null;
    const textDescription = this.state.shouldCropDescription ? (
      <p
        className="vehicle-info-text"
        id="description"
        style={this.state.style_text}
      >
        {this.props.vehicle.description}
      </p>
    ) : (
      <p className="vehicle-info-text" id="description">
        {this.props.vehicle.description}
      </p>
    );
    const buttonMoreDescription = this.state.shouldCropDescription ? (
      <button
        className="vehicle-info-show-more"
        onClick={e => this.toggleText(e, "description")}
      >
        Más
      </button>
    ) : null;
    const description = this.props.vehicle.description ? (
      <div className="row mb-5">
        <div className="col-sm-4">
          <p className="vehicle-info-title">DESCRIPCIÓN</p>
        </div>
        <div className="col-sm-8">
          {degradeDescription}
          {textDescription}
          {buttonMoreDescription}
        </div>
      </div>
    ) : null;

    //Features
    const degradeFeatures = this.state.shouldCropFeatures ? (
      <div style={this.state.style_hide_text} />
    ) : null;
    const textFeatures = this.state.shouldCropFeatures ? (
      <p
        className="vehicle-info-text"
        id="features"
        style={this.state.style_text}
      >
        {this.props.vehicle.features}
      </p>
    ) : (
      <p className="vehicle-info-text" id="features">
        {this.props.vehicle.features}
      </p>
    );
    const buttonMoreFeatures = this.state.shouldCropFeatures ? (
      <button
        className="vehicle-info-show-more"
        onClick={e => this.toggleText(e, "features")}
      >
        Más
      </button>
    ) : null;
    const features = this.props.vehicle.features ? (
      <div className="row mb-5">
        <div className="col-sm-4">
          <p className="vehicle-info-title">FEATURES</p>
        </div>
        <div className="col-sm-8">
          {degradeFeatures}
          {textFeatures}
          {buttonMoreFeatures}
        </div>
      </div>
    ) : null;

    //Extras
    const degradeExtras = this.state.shouldCropExtras ? (
      <div style={this.state.style_hide_text} />
    ) : null;
    const textExtras = this.state.shouldCropExtras ? (
      <p
        className="vehicle-info-text"
        id="extras"
        style={this.state.style_text}
      >
        {this.props.vehicle.extras}
      </p>
    ) : (
      <p className="vehicle-info-text" id="extras">
        {this.props.vehicle.extras}
      </p>
    );
    const buttonMoreExtras = this.props.shouldCropExtras ? (
      <button
        className="vehicle-info-show-more"
        onClick={e => this.toggleText(e, "extras")}
      >
        Más
      </button>
    ) : null;
    const extras = this.props.vehicle.extras ? (
      <div className="row mb-5">
        <div className="col-sm-4">
          <p className="vehicle-info-title">EXTRAS</p>
        </div>
        <div className="col-sm-8">
          {degradeExtras}
          {textExtras}
          {buttonMoreExtras}
        </div>
      </div>
    ) : null;

    //Restrictions
    const degradeRestrictions = this.state.shouldCropRestrictions ? (
      <div style={this.state.style_hide_text} />
    ) : null;
    const textRestrictions = this.state.shouldCropRestrictions ? (
      <p
        className="vehicle-info-text"
        id="restrictions"
        style={this.state.style_text}
      >
        {this.props.vehicle.restrictions}
      </p>
    ) : (
      <p className="vehicle-info-text" id="restrictions">
        {this.props.vehicle.restrictions}
      </p>
    );
    const buttonMoreRestrictions = this.state.shouldCropRestrictions ? (
      <button
        className="vehicle-info-show-more"
        onClick={e => this.toggleText(e, "restrictions")}
      >
        Más
      </button>
    ) : null;
    const restrictions = this.props.vehicle.restrictions ? (
      <div className="row mb-5">
        <div className="col-sm-4">
          <p className="vehicle-info-title">RESTRICCIONES</p>
        </div>
        <div className="col-sm-8">
          {degradeRestrictions}
          {textRestrictions}
          {buttonMoreRestrictions}
        </div>
      </div>
    ) : null;
    return (
      <div className="vehicle-view-info my-4 mx-3">
        {description}
        {features}
        {extras}
        {restrictions}
      </div>
    );
  }
}

export default VehicleInfo;
