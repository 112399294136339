import React, { Component } from "react";
import LandingPageVehicleCard from "../../common/LandingPageVehicleCard";

class FeaturedOwners extends Component {
  state = {
    rentals: this.props.rentals,
  };

  render() {
    let rentals = this.props.rentals.map((rental, i) => { });

    const vehicleCards = rentals.map((rental, i) => {
      return (
        <div
          className="col-12 col-md-6 mb-3"
          key={i}
        >
          <LandingPageVehicleCard rental={rental} />
        </div>
      );
    });

    return (
      <>
        <div className="text-center">
          <p className="slogan" style={{ lineHeight: 1.1 }}>
            Los usuarios de Appcar
            <br />
            <b>han preferido estos autos</b>
          </p>
          <p className="font-1-2">Mira una lista de nuestras publicaciones destacadas</p>
        </div>
        <div className="row justify-content-center my-3">
          {vehicleCards}
        </div>
      </>
    );
  }
}

export default FeaturedOwners;
