import React, { Component, Fragment } from "react";
import ImagesContainer from "./ImagesContainer";

export default class EnsuranceImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actual_session_carousel: 0,
    };
  }

  changeSession = (actual_session_carousel) => {
    this.setState({ actual_session_carousel });
  };

  render() {
    const { insurancePics, insurancePicsPost, startLocation, endLocation } = this.props;

    // Arrays
    // INSURANCE PÏCS
    let front_pictures = insurancePics.front_pictures;
    let left_pictures = insurancePics.left_pictures;
    let right_pictures = insurancePics.right_pictures;
    let back_pictures = insurancePics.back_pictures;
    let chassis_pictures = insurancePics.chassis_pictures;
    let additional_pictures = insurancePics.additional_pictures;
    let spare_wheel_pictures = insurancePics.spare_wheel_pictures;
    let vehicle_odometer_pictures = insurancePics.vehicle_odometer_pictures;

    // INSURANCE PICS POST
    let front_pictures_post = insurancePicsPost.front_pictures;
    let left_pictures_post = insurancePicsPost.left_pictures;
    let right_pictures_post = insurancePicsPost.right_pictures;
    let back_pictures_post = insurancePicsPost.back_pictures;
    let chassis_pictures_post = insurancePicsPost.chassis_pictures;
    let additional_pictures_post = insurancePicsPost.additional_pictures;
    let spare_wheel_pictures_post = insurancePicsPost.spare_wheel_pictures;
    let vehicle_odometer_pictures_post = insurancePicsPost.vehicle_odometer_pictures;

    // START LOCATION
    let start_vehicle_pictures,
      start_front_pictures,
      start_right_pictures,
      start_left_pictures,
      start_back_pictures,
      start_chassis_pictures,
      start_user_vehicle_pictures,
      start_vehicle_odometer_pictures,
      start_spare_wheel_pictures,
      start_fuel_level_pictures,
      start_radio_pictures;
    if (startLocation) {
      start_vehicle_pictures = startLocation.vehicle_pictures;
      start_front_pictures = startLocation.front_pictures;
      start_right_pictures = startLocation.right_pictures;
      start_left_pictures = startLocation.left_pictures;
      start_back_pictures = startLocation.back_pictures;
      start_chassis_pictures = startLocation.chassis_pictures;
      start_user_vehicle_pictures = startLocation.user_vehicle_pictures;
      start_vehicle_odometer_pictures = startLocation.vehicle_odometer_pictures;
      start_spare_wheel_pictures = startLocation.spare_wheel_pictures;
      start_fuel_level_pictures = startLocation.fuel_level_pictures;
      start_radio_pictures = startLocation.radio_pictures;
    }

    // END LOCATION
    let end_vehicle_pictures,
      end_front_pictures,
      end_right_pictures,
      end_left_pictures,
      end_back_pictures,
      end_chassis_pictures,
      end_owner_vehicle_pictures,
      end_spare_wheel_pictures,
      end_vehicle_odometer_pictures;
    if (endLocation) {
      end_vehicle_pictures = endLocation.vehicle_pictures;
      end_front_pictures = endLocation.front_pictures;
      end_right_pictures = endLocation.right_pictures;
      end_left_pictures = endLocation.left_pictures;
      end_back_pictures = endLocation.back_pictures;
      end_chassis_pictures = endLocation.chassis_pictures;
      end_owner_vehicle_pictures = endLocation.owner_vehicle_pictures;
      end_spare_wheel_pictures = endLocation.spare_wheel_pictures;
      end_vehicle_odometer_pictures = endLocation.vehicle_odometer_pictures;
    }

    // Containers
    // INSURANCE PICS
    const front_container = <ImagesContainer pics={front_pictures} modalName="modal-front-pictures" picsName="Fotos frontales" />
    const left_container = <ImagesContainer pics={left_pictures} modalName="modal-left-pictures" picsName="Fotos del costado izquierdo" />
    const right_container = <ImagesContainer pics={right_pictures} modalName="modal-right-pictures" picsName="Fotos del costado derecho" />
    const back_container = <ImagesContainer pics={back_pictures} modalName="modal-back-pictures" picsName="Fotos traseras" />
    const chassis_container = <ImagesContainer pics={chassis_pictures} modalName="modal-chassis-pictures" picsName="Fotos del chassis" />
    const additional_container = <ImagesContainer pics={additional_pictures} modalName="modal-additional-pictures" picsName="Fotos adicionales" />
    const spare_wheel_container = <ImagesContainer pics={spare_wheel_pictures} modalName="modal-spare-wheel-pictures" picsName="Fotos de rueda de repuesto" />
    const odometer_container = <ImagesContainer pics={vehicle_odometer_pictures} modalName="modal-odometer-pictures" picsName="Fotos del odómetro" />

    // INSURANCE PICS POST
    const front_post_container = <ImagesContainer pics={front_pictures_post} modalName="modal-front-pictures-post" picsName="Fotos frontales" />
    const left_post_container = <ImagesContainer pics={left_pictures_post} modalName="modal-left-pictures-post" picsName="Fotos del costado izquierdo" />
    const right_post_container = <ImagesContainer pics={right_pictures_post} modalName="modal-right-pictures-post" picsName="Fotos del costado derecho" />
    const back_post_container = <ImagesContainer pics={back_pictures_post} modalName="modal-back-pictures-post" picsName="Fotos traseras" />
    const chassis_post_container = <ImagesContainer pics={chassis_pictures_post} modalName="modal-chassis-pictures-post" picsName="Fotos del chassis" />
    const additional_post_container = <ImagesContainer pics={additional_pictures_post} modalName="modal-additional-post-pictures" picsName="Fotos adicionales" />
    const spare_wheel_post_container = <ImagesContainer pics={spare_wheel_pictures_post} modalName="modal-spare-wheel-post-pictures" picsName="Fotos de rueda de repuesto" />
    const odometer_post_container = <ImagesContainer pics={vehicle_odometer_pictures_post} modalName="modal-odometer-post-pictures" picsName="Fotos del odómetro" />

    // START LOCATION
    let start_vehicle_pictures_container,
      start_front_pictures_container,
      start_right_pictures_container,
      start_left_pictures_container,
      start_back_pictures_container,
      start_chassis_pictures_container,
      start_user_vehicle_pictures_container,
      start_vehicle_odometer_pictures_container,
      start_spare_wheel_pictures_container,
      start_fuel_level_pictures_container,
      start_radio_pictures_container;
    if (startLocation) {
      start_vehicle_pictures_container = <ImagesContainer pics={start_vehicle_pictures} modalName="modal-start-vehicle" picsName="Fotos del vehículo" />
      start_front_pictures_container = <ImagesContainer pics={start_front_pictures} modalName="modal-start-front" picsName="Fotos frontales" />
      start_left_pictures_container = <ImagesContainer pics={start_left_pictures} modalName="modal-start-left" picsName="Fotos del costado izquierdo" />
      start_right_pictures_container = <ImagesContainer pics={start_right_pictures} modalName="modal-start-right" picsName="Fotos del costado derecho" />
      start_back_pictures_container = <ImagesContainer pics={start_back_pictures} modalName="modal-start-back" picsName="Fotos traseras" />
      start_chassis_pictures_container = <ImagesContainer pics={start_chassis_pictures} modalName="modal-start-chassis" picsName="Fotos del chassis" />
      start_user_vehicle_pictures_container = <ImagesContainer pics={start_user_vehicle_pictures} modalName="modal-start-user" picsName="Fotos del driver" />
      start_vehicle_odometer_pictures_container = <ImagesContainer pics={start_vehicle_odometer_pictures} modalName="modal-start-odometer" picsName="Fotos del odómetro" />
      start_spare_wheel_pictures_container = <ImagesContainer pics={start_spare_wheel_pictures} modalName="modal-start-spare-wheel" picsName="Fotos de la rueda de repuesto" />
      start_fuel_level_pictures_container = <ImagesContainer pics={start_fuel_level_pictures} modalName="modal-start-fuel" picsName="Fotos del nivel de combustible" />
      start_radio_pictures_container = <ImagesContainer pics={start_radio_pictures} modalName="modal-start-radio" picsName="Fotos de la radio" />
    }

    // END LOCATION 
    let end_vehicle_pictures_container,
      end_front_pictures_container,
      end_right_pictures_container,
      end_left_pictures_container,
      end_back_pictures_container,
      end_chassis_pictures_container,
      end_owner_vehicle_pictures_container,
      end_spare_wheel_pictures_container,
      end_vehicle_odometer_pictures_container
    if (endLocation) {
      end_vehicle_pictures_container = <ImagesContainer pics={end_vehicle_pictures} modalName="modal-end-vehicle" picsName="Fotos del vehículo" />
      end_front_pictures_container = <ImagesContainer pics={end_front_pictures} modalName="modal-end-front" picsName="Fotos frontales" />
      end_left_pictures_container = <ImagesContainer pics={end_left_pictures} modalName="modal-end-left" picsName="Fotos del costado izquierdo" />
      end_right_pictures_container = <ImagesContainer pics={end_right_pictures} modalName="modal-end-right" picsName="Fotos del costado derecho" />
      end_back_pictures_container = <ImagesContainer pics={end_back_pictures} modalName="modal-end-back" picsName="Fotos traseras" />
      end_chassis_pictures_container = <ImagesContainer pics={end_chassis_pictures} modalName="modal-end-chassis" picsName="Fotos del chassis" />
      end_owner_vehicle_pictures_container = <ImagesContainer pics={end_owner_vehicle_pictures} modalName="modal-end-owner" picsName="Fotos del owner" />
      end_vehicle_odometer_pictures_container = <ImagesContainer pics={end_vehicle_odometer_pictures} modalName="modal-end-odometer" picsName="Fotos del odómetro" />
      end_spare_wheel_pictures_container = <ImagesContainer pics={end_spare_wheel_pictures} modalName="modal-end-spare-wheel" picsName="Fotos de la rueda de repuesto" />
    }

    const start_owner_pictures_container_class =
      this.state.actual_session_carousel == 0 ? "" : "d-none";
    const start_driver_pictures_container_class =
      this.state.actual_session_carousel == 1 ? "" : "d-none";
    const end_driver_pictures_container_class =
      this.state.actual_session_carousel == 2 ? "" : "d-none";
    const end_ownver_pictures_container_class =
      this.state.actual_session_carousel == 3 ? "" : "d-none";
    const buttonInicialDriver = startLocation ? <button
      className="btn btn-appcar-light btn-rounded mr-2"
      type="button"
      onClick={() => this.changeSession(1)}
    >
      Sesión Inicio Arriendo | Driver
    </button> : null;
    const buttonFinalDriver = endLocation ? <button
      className="btn btn-appcar-primary btn-rounded mr-2"
      type="button"
      onClick={() => this.changeSession(2)}
    >
      Sesión Fin Arriendo | Driver
    </button> : null;
    const session_button_group = (
      <div className="row my-4">
        <div className="col">
          <button
            className="btn btn-appcar-primary btn-rounded mr-2"
            type="button"
            onClick={() => this.changeSession(0)}
          >
            Sesión Inicial | Owner
          </button>
          {buttonInicialDriver}
          {buttonFinalDriver}
          <button
            className="btn btn-appcar-light btn-rounded mr-2"
            type="button"
            onClick={() => this.changeSession(3)}
          >
            Sesión Final | Owner
          </button>
        </div>
      </div>
    );
    const start_owner_pictures_container = (
      <div className={start_owner_pictures_container_class}>
        <h3>Sesión inicial Owner</h3>
        {front_container}
        {left_container}
        {right_container}
        {back_container}
        {chassis_container}
        {additional_container}
        {odometer_container}
        {spare_wheel_container}
        <hr />
      </div>
    );
    const start_driver_pictures_container = startLocation ? (
      <div className={start_driver_pictures_container_class}>
        <h3>Sesión inicial Driver</h3>
        {start_vehicle_pictures_container}
        {start_front_pictures_container}
        {start_left_pictures_container}
        {start_right_pictures_container}
        {start_back_pictures_container}
        {start_chassis_pictures_container}
        {start_user_vehicle_pictures_container}
        {start_vehicle_odometer_pictures_container}
        {start_spare_wheel_pictures_container}
        {start_fuel_level_pictures_container}
        {start_radio_pictures_container}
        <hr />
      </div>
    ) : null;
    const end_driver_pictures_container = endLocation ? (
      <div className={end_driver_pictures_container_class}>
        <h3>Sesión final Driver</h3>
        {end_vehicle_pictures_container}
        {end_front_pictures_container}
        {end_left_pictures_container}
        {end_right_pictures_container}
        {end_back_pictures_container}
        {end_chassis_pictures_container}
        {end_owner_vehicle_pictures_container}
        {end_vehicle_odometer_pictures_container}
        {end_spare_wheel_pictures_container}
        <hr />
      </div>
    ) : null;
    const end_owner_pictures_container = (
      <div className={end_ownver_pictures_container_class}>
        <h3>Sesión final Owner</h3>
        {front_post_container}
        {left_post_container}
        {right_post_container}
        {back_post_container}
        {chassis_post_container}
        {additional_post_container}
        {odometer_post_container}
        {spare_wheel_post_container}
        <hr />
      </div>
    );
    return (
      <React.Fragment>
        {session_button_group}
        {start_owner_pictures_container}
        {start_driver_pictures_container}
        {end_driver_pictures_container}
        {end_owner_pictures_container}
      </React.Fragment>
    );
  }
}
