import React from "react";
import TableRow from "./TableRow";

const TableBody = ({ columns, data, noResults }) => {
  let rows = data.map((row, i) => (
    <TableRow rowData={row} columns={columns} key={i} />
  ));
  if (rows.length === 0) {
    rows.push(
      <tr key={"noresults"}>
        <td colSpan={columns.length} className="text-center">{noResults}</td>
      </tr>
    )
  }
  return (
    <tbody>
      {rows}
    </tbody>
  )
}

export default TableBody