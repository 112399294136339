import React, { Component } from "react";
import { BookingStatus } from "../../../../common/constants";
import VehicleQualification from "../../../../common/qualification/VehicleQualification";

class VehicleStats extends Component {
  handleChange = (e, extra) => {
    if (e.target.checked) {
      this.props.addExtra(extra);
    } else {
      this.props.removeExtra(extra);
    }
  };

  rate = () => {
    $("#modal_qualification").modal("show");
  };
  hide_modal = () => {
    $("#modal_qualification").modal("hide");
    if (this.props.callbackRate) {
      this.props.callbackRate();
    }
  };
  render() {
    const { extras, features, vehicle, booking, bookingId } = this.props;
    const vehicle_title =
      vehicle.vehicle_type.brand +
      " " +
      vehicle.vehicle_type.model +
      " " +
      vehicle.year;
    let car_rating = [];
    for (let i = 0; i < 5; i++) {
      let r_icon = null;
      if (i < vehicle.vehicle_rating) {
        r_icon = <i className="mdi mdi-car-sports" key={i} />;
      } else {
        r_icon = <i className="mdi mdi-car-sports icon-gray" key={i} />;
      }
      car_rating.push(r_icon);
    }
    const evaluations = (
      <div className="vehicle-stats-badge-ratings mr-1" key="badge">
        {vehicle.trips || 0} viaje
        {vehicle.trips == 1 ? "" : "s"}
      </div>
    );
    car_rating.push(evaluations);
    const badgeRate =
      booking.status.id == BookingStatus.COMPLETADO &&
      (booking.review_status === null || !booking.review_status.vehicle) ? (
        <div
          className="vehicle-stats-badge-ratings"
          key="rate"
          onClick={this.rate}
          style={{
            width: "fit-content",
            backgroundColor: "#203453",
            color: "#fff",
            cursor: "pointer",
          }}
        >
          Evaluar
        </div>
      ) : null;
    car_rating.push(badgeRate);
    const modal =
      booking.status.id == BookingStatus.COMPLETADO &&
      (booking.review_status === null || !booking.review_status.vehicle) ? (
        <div
          id="modal_qualification"
          className="modal fade"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body p-4">
                <VehicleQualification
                  vehicleId={vehicle.id}
                  callback={this.hide_modal}
                  bookingId={bookingId}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null;
    const vehicle_features = features.map((v, i) => {
      return (
        <div className="col-6 centered-stats mb-1 px-0" key={i}>
          <i className={v.icon_class + " mr-1"} />
          <p>{v.a_name}</p>
        </div>
      );
    });
    const vehicle_extras = extras.map((extra, i) => {
      return (
        <div className="col-md-6 centered-stats mb-1 px-0" key={i}>
          <i className={extra.icon_class + " mr-1"} />
          <p>{extra.a_name}</p>
        </div>
      );
    });
    const features_container =
      features.length > 0 ? (
        <div className="mb-3 vehicle-props">
          <h4>ADEMÁS INCLUYE</h4>
          <div className="row ml-1 ml-md-0">{vehicle_features}</div>
        </div>
      ) : null;
    const extras_container =
      extras.length > 0 ? (
        <div className="mb-3 vehicle-props">
          <h4>CARGOS ADICIONALES</h4>
          <div className="row ml-1 ml-md-0">{vehicle_extras}</div>
        </div>
      ) : null;
    const transmission =
      vehicle.vehicle_transmission &&
      vehicle.vehicle_transmission.description ? (
        <div className="col-6 centered-stats mb-1 px-0">
          <i className="mdi mdi-car-shift-pattern mr-1" />
          <p>Transmisión {vehicle.vehicle_transmission.description}</p>
        </div>
      ) : null;
    const category =
      vehicle.vehicle_category && vehicle.vehicle_category.description ? (
        <div className="col-6 centered-stats mb-1 px-0">
          <i className="mdi mdi-car-pickup mr-1" />
          <p>{vehicle.vehicle_category.description}</p>
        </div>
      ) : null;
    return (
      <div className="vehicle-stats ml-1 ml-md-0">
        <p className="vehicle-stats-title">{vehicle_title}</p>
        <div className="vehicle-stats-rating">{car_rating}</div>
        <div className="vehicle-props my-1 my-sm-2 ml-1 ml-md-0">
          <div className="row ml-1 ml-md-0">
            <div className="col-6 centered-stats mb-1 px-0">
              <i className="mdi mdi-gas-station mr-1" />
              <p>{vehicle.efficiency} Km/L</p>
            </div>
            <div className="col-6 centered-stats mb-1 px-0">
              <i className="mdi mdi-fuel mr-1" />
              <p>{vehicle.fuel_type.description}</p>
            </div>
            <div className="col-6 centered-stats mb-1 px-0">
              <i className="mdi mdi-seat-recline-normal mr-1" />
              <p>{vehicle.capacity} pasajero(s)</p>
            </div>
            {transmission}
            {category}
          </div>
        </div>
        {features_container}
        {extras_container}
        {modal}
      </div>
    );
  }
}

export default VehicleStats;
