import React, { Component } from "react";
import Spinner from "../../common/Spinner";
import Alert from "../../common/components/alert/Alert";
import axios from "axios";
import { AxiosApplicationInterceptor } from "../../common/axios_interceptor";
import SelectWithRadio from "../../common/components/select/SelectWithRadio";
import lockIcon from "images/lock.png";
import stareIcon from "images/IconStare.png";

axios.interceptors.response.use(undefined, AxiosApplicationInterceptor);

export default class BankData extends Component {
  state = {
    data: {
      bank: "",
      account: "",
      bank_details: undefined
    },
    isSubmitting: false,
    alert: {
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined
    }
  }

  componentDidMount() {
    const bankInfo = this.props.user.bank_info;
    let data = this.state.data;
    if (bankInfo) {
      data.bank = bankInfo.bank || "";
      data.account = bankInfo.account || "";
      data.bank_details = bankInfo.bank_details || undefined;
      this.setState({ data });
    }
  }

  showMessage = (messageType, messageTitle, messages) =>
    this.setState(state => {
      state.alert.showMessage = true;
      state.alert.messageTitle = messageTitle;
      state.alert.messages = messages;
      state.alert.messageType = messageType;
      return { alert: state.alert };
    },
    () => setTimeout(this.handleAlertClose, 3000)
  );

  handleAlertClose = () =>
    this.setState(state => {
      state.alert.showMessage = false;
      return { alert: state.alert };
    });

  handleSubmit = () => {
    this.setState({ isSubmitting: true });
    let user = this.props.user;
    const data = this.state.data;
    user.bank_info = data;
    axios({
      method: "PUT",
      url: `/owners/${this.props.user.id}.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json"
      },
      data: { user }
    })
      .then(response => {
        if (response.data.success) {
          const rTo = this.props.r_to;
          if (rTo == "vehicles") window.location.assign('/vehicles');
          else window.location.assign('/');
        } else {
          this.showMessage("primary", "Alerta", response.data.messages || ["Ha ocurrido un error inesperado"])
        }
      })
      .catch(error =>
        this.showMessage("primary", "Error", ["Ha ocurrido un error inesperado"])
      )
      .then(() => this.setState({ isSubmitting: false }));
  }

  changeBank = option => {
    const bank = this.props.banks.find(bank => bank.id === option.value);
    this.setState(state => {
      state.data.bank = bank.description;
      state.data.bank_details = bank;
      return { data: state.data };
    });
  };

  changeAccount = val =>
    this.setState(state => {
      state.data.account = val;
      return { data: state.data };
    });


  render() {
    const {
      data,
      alert,
      isSubmitting
    } = this.state;

    const {
      messageTitle,
      messageType,
      messages,
      showMessage
    } = alert;

    const bankOptions = this.props.banks.map(bank => ({
      value: bank.id,
      label: bank.description
    }));
    const selectedBank = data.bank_details ? {
      value: data.bank_details.id,
      label: data.bank_details.description
    } : undefined;
    const sub = this.props.redirectedFrom == "edit_owner"
      ? (
        <h3>Paso 2 de 2</h3>
      ) : null;
    const secondaryClass = this.props.redirectedFrom == "edit_owner" ? "-light" : "";
    return (
      <div className="edit-box">
        <Alert
          alertClass={messageType}
          isShowing={showMessage}
          messages={messages} title={messageTitle}
          onClose={this.handleAlertClose}
        />
        <Spinner loading={isSubmitting} />
        <div className="photo-container">
          <div className="image-loader">
            <h2>Mis datos bancarios</h2>
            {sub}
          </div>
          <div className="photo-advice">
            <div className="icon">
              <img src={this.props.redirectedFrom == "edit_owner" ? stareIcon : lockIcon} width={36} />
            </div>
            <div className="advice">
              Necesitamos tus datos bancarios para
              depositarte cuando tengas arriendos
            </div>
          </div>
        </div>
        <div className="form">
          <div className="form-group">
            <label htmlFor="bank" className="label-inside">Banco</label>
            <SelectWithRadio
              value={selectedBank}
              options={bankOptions}
              placeholder="Selecciona..."
              noOptionsMessage={() => "No fue posible cargar los bancos"}
              onChange={this.changeBank}
              classNamePrefix="RS-FIX"
              className={`react-select-fix appcar-input${secondaryClass}`}
              hideSelectedOptions={false}
              backspaceRemovesValue={false}
              isSearchable={false}
              closeMenuOnSelect={true}
            />
          </div>
          <div className="form-group">
            <label htmlFor="account" className="label-inside">N° de cuenta</label>
            <input
              type="text"
              value={data.account}
              className={`form-control appcar-input${secondaryClass}`}
              onChange={e => this.changeAccount(e.target.value)}
              required
            />
          </div>
          <div className="text-center my-4">
            <button
              type="button"
              className={`btn btn-appcar${secondaryClass || "-primary"}`}
              disabled={isSubmitting}
              onClick={this.handleSubmit}
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    )
  }

}