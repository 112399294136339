import React, { Component } from "react";
import { ErrorMessage } from "formik";
import Spinner from "../../common/Spinner";
import axios from "axios";
import * as _ from "lodash";
import { DatePicker } from "../../common/CustomInputs";
import VehicleCard from "../../common/components/cards/VehicleCard";
import { constants } from "../../common/constants";
import { AxiosApplicationInterceptor } from "../../common/axios_interceptor";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/es";
dayjs.extend(localizedFormat);
axios.interceptors.response.use(undefined, AxiosApplicationInterceptor);

class SearchResults extends Component {
  state = {
    form_data: {
      lat: this.props.lat || constants.RENTAL_SEARCH_LATITUDE,
      lon: this.props.lon || constants.RENTAL_SEARCH_LONGITUDE,
      radius: this.props.radius || constants.RENTAL_SEARCH_RADIUS,
      from: this.props.from
        ? dayjs(this.props.from).format()
        : constants.RENTAL_SEARCH_FROM,
      until: this.props.until
        ? dayjs(this.props.until).format()
        : constants.RENTAL_SEARCH_UNTIL
    },
    location: "",
    rentals: [],
    locations: [],
    ui: {
      isSubmitting: false,
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined
    }
  };

  componentDidMount() {
    this.setState(state => {
      state.ui.isSubmitting = true;
      return { ui: state.ui };
    });
    const options = {
      showDropdowns: true,
      maxYear: constants.MAX_YEAR,
      singleDatePicker: true,
      locale: {
        format: "LL",
        cancelLabel: "Cancelar",
        applyLabel: "Aplicar",
        fromLabel: "Desde",
        toLabel: "Hasta",
        daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre"
        ],
        firstDay: 1
      }
    };

    $("#search_locations").select2();
    $("#search_locations").on("change", e => {
      const location = _.find(this.state.locations, {
        id: parseInt(e.target.value)
      });
      this.setState(state => {
        state.form_data.lat = location.latitude;
        state.form_data.lon = location.longitude;
        state.location = e.target.value;
        return { form_data: state.form_data, location: state.location };
      });
    });

    $("#search_from").daterangepicker(options, (start, end) => {
      let from = dayjs(start).format();
      this.setState(state => {
        state.form_data.from = from;
        return { form_data: state.form_data };
      });
    });

    $("#search_until").daterangepicker(options, (start, end) => {
      let until = dayjs(start).format();
      this.setState(state => {
        state.form_data.until = until;
        return { form_data: state.form_data };
      });
    });

    const locationsPromise = axios({
      method: "get",
      url: `/backoffice/locations.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json"
      }
    });

    const rentalsPromise = axios({
      method: "get",
      url: `/portada/search_rentals.json?lat=${encodeURIComponent(
        this.props.lat
      )}&lon=${encodeURIComponent(this.props.lon)}&radius=${encodeURIComponent(
        this.props.radius
      )}&from=${encodeURIComponent(this.props.from)}&until=${encodeURIComponent(
        this.props.until
      )}`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json"
      }
    });

    Promise.all([locationsPromise, rentalsPromise])
      .then(values => {
        const [locationsData, rentalsData] = values;
        const locations = locationsData.data.map(l => {
          l.id = parseInt(l.id);
          return l;
        });
        const rentals = rentalsData.data;
        this.setState({ locations, rentals }, () => {
          const selected_location =
            _.find(locations, { id: this.props.locationId }) || locations[0];
          $("#search_locations")
            .val(selected_location.id)
            .trigger("change");
        });
      })
      .catch(error => {
        this.showMessage({
          showMessage: true,
          messageType: "alert-danger",
          messages: ["Ha ocurrido un error inesperado"],
          messageTitle: "Error"
        });
      })
      .then(() => {
        this.setState(state => {
          state.ui.isSubmitting = false;
          return { ui: state.ui };
        });
      });
  }

  changeFecha = fecha => {
    this.setState({ fecha });
  };

  changeLugar = location => {
    this.setState({ location });
  };

  showMessage = msg => {
    this.setState(state => {
      state.ui.showMessage = msg.showMessage;
      state.ui.messageType = msg.messageType;
      state.ui.messages = msg.messages;
      state.ui.messageTitle = msg.messageTitle;
      return { ui: state.ui };
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState(state => {
      state.ui.isSubmitting = true;
      return { ui: state.ui };
    });
    dayjs.locale("es");
    const form_data = this.state.form_data;
    const from = dayjs(form_data.from)
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ssZ");
    const until = dayjs(form_data.until)
      .endOf("day")
      .format("YYYY-MM-DDTHH:mm:ssZ");
    axios({
      method: "get",
      url: `/portada/search_rentals.json?lat=${encodeURIComponent(
        form_data.lat
      )}&lon=${encodeURIComponent(form_data.lon)}&radius=${encodeURIComponent(
        form_data.radius
      )}&from=${encodeURIComponent(from)}&until=${encodeURIComponent(until)}`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        const rentals = response.data;
        this.setState({ rentals });
      })
      .catch(error => {
        this.showMessage({
          showMessage: true,
          messageType: "alert-danger",
          messages: ["Ha ocurrido un error inesperado"],
          messageTitle: "Error"
        });
      })
      .then(() => {
        this.setState(state => {
          state.ui.isSubmitting = false;
          return { ui: state.ui };
        });
      });
  };

  render() {
    dayjs.locale("es");
    const date_from = dayjs(this.state.form_data.from).format("LL");
    const date_until = dayjs(this.state.form_data.until).format("LL");
    const locations = this.state.locations;
    const options = locations.map(lugar => {
      return (
        <option value={lugar.id} key={lugar.id}>
          {lugar.name}
        </option>
      );
    });

    const min = 0;
    const max = 6;
    const maxEval = 100;
    let rentals = this.state.rentals.map((rental, i) => {
      if (
        !rental.vehicle.associated_users ||
        rental.vehicle.associated_users.length == 0
      ) {
        rental.vehicle.associated_users = [];
        const associated_user = {
          user_type: "",
          id: "",
          nickname: "",
          name: "",
          last_name: "",
          photo: "",
          user_rating: 0,
          super_user: ""
        };
        rental.vehicle.associated_users.push(associated_user);
      }
      rental.reviews = Math.floor(Math.random() * (+maxEval - +min)) + +min;
      rental.vehicle.vehicle_rating =
        Math.floor(Math.random() * (+max - +min)) + +min;
      rental.discount = 10;
      rental.isFavorite = Math.random() >= 0.5;
      return rental;
    });

    const vehicleCards = rentals.map((rental, i) => {
      return (
        <div
          className="col-xl-4 col-lg-6 mb-3"
          key={i}
          style={{ maxWidth: "40rem", minWidth: "34rem" }}
        >
          <VehicleCard rental={rental} link={`/rentals/${rental.id}`} />
        </div>
      );
    });

    const empty_message = is_submitting => (
      <div className="col">
        <div
          className="fill alert alert-info fade show pr-5"
          style={{ display: "inline-block" }}
        >
          {is_submitting
            ? "Realizando búsqueda..."
            : "No se han encontrado resultados"}
        </div>
      </div>
    );

    return (
      <React.Fragment>
        <Spinner loading={this.state.ui.isSubmitting} />

        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-3">
                <label htmlFor="lugar">Lugar de recogida</label>
                <select
                  id="search_locations"
                  name="lugar"
                  value={this.state.location}
                  onChange={e => this.changeLugar(e.target.value)}
                  style={{ display: "block" }}
                  className="search-cars-select"
                >
                  {options}
                </select>
                <ErrorMessage name="lugar">
                  {msg => (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              </div>
              <div className="col-4">
                <DatePicker
                  id="search_from"
                  label="Fecha desde"
                  value={date_from}
                  onChange={e => this.changeFecha(e.target.value)}
                  name="date_from"
                  disabled={true}
                  classNameInput="input-search-cars"
                />
                <ErrorMessage name="date_from">
                  {msg => (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              </div>
              <div className="col-4">
                <DatePicker
                  id="search_until"
                  label="Fecha hasta"
                  value={date_until}
                  onChange={e => this.changeFecha(e.target.value)}
                  name="date_until"
                  disabled={true}
                  classNameInput="input-search-cars"
                />
                <ErrorMessage name="date_until">
                  {msg => (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              </div>
              <div className="col-1">
                <button
                  className="btn-airncar-yellow mt-2"
                  onClick={this.handleSubmit}
                >
                  <i className="mdi mdi-chevron-right" />
                </button>
              </div>
            </div>
            <h4>Resultados de búsqueda</h4>
            <div className="row justify-content-center">
              {this.state.rentals.length
                ? vehicleCards
                : this.state.ui.isSubmitting
                ? empty_message(true)
                : empty_message(false)}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SearchResults;
