import React, { Component } from "react";
import LocationSelector from "../../../common/search_components/LocationSelector";
import Spinner from "../../../common/Spinner";

class ExtraLocationSearch extends Component {
  state = {
    name: "",
    latitude: 0.0,
    longitude: 0.0,
    isSubmitting: false
  };

  handleSelectLocation = ({ success, lat, lon, address }) => {
    if (success) {
      this.setState({ latitude: lat, longitude: lon, name: address, isSubmitting: false });
    } else {
      this.setState({ latitude: "", longitude: "", isSubmitting: false });
    }
  };

  loadSpinner = () => this.setState({ isSubmitting: true });

  render() {
    const { name, latitude, longitude } = this.state;
    let countries = ["cl"];
    if (this.props.userCountry && this.props.userCountry != "CL") {
      countries.push(this.props.userCountry.toLowerCase());
    }
    return (
      <React.Fragment>
        <Spinner loading={this.state.isSubmitting} />
        <h4>Lugar de entrega / devolución</h4>
        <LocationSelector
          countries={countries}
          handleSelectLocation={this.handleSelectLocation}
          placeholder="Introduce una ubicación a buscar..."
          label=""
          loadSpinner={this.loadSpinner}
        />
        <input
          type="hidden"
          name="extra_location[location][latitude]"
          value={latitude}
          id="extra_location_latitude"
          required
        />
        <input
          type="hidden"
          name="extra_location[location][longitude]"
          value={longitude}
          id="extra_location_longitude"
          required
        />
        <input
          type="hidden"
          name="extra_location[location][name]"
          value={name}
          id="extra_location_name"
          required
        />
      </React.Fragment>
    );
  }
}

export default ExtraLocationSearch;
