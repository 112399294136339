import React, { Component, Fragment } from "react";
import Spinner from "../../common/Spinner";
import FlashMessage from "../../common/FlashMessage";
import Qualification from "../../common/qualification/Qualification";
import Axios from "axios";

class RateBooking extends Component {
  constructor(props) {
    super(props);
    this.secondary_rating_vehicle = [
      { name: "steering", label: "Dirección" },
      { name: "shock_absorber", label: "Suspensión" },
      { name: "brakes", label: "Frenos" },
      { name: "lights", label: "Luces" },
      { name: "odors", label: "Olor" },
      { name: "noises", label: "Ruidos" }
    ];
    this.principal_rating_vehicle = {
      name: "general_state",
      label: "Estado general"
    };
    this.ratings = [
      { name: "communication", label: "Comunicación" },
      { name: "punctuality", label: "Puntualidad" },
      { name: "cleanliness", label: "Limpieza" },
      { name: "truthfulness", label: "Veracidad" },
      { name: "vehicle", label: "Vehículo" }
    ];
    this.state = {
      owner_eval: {
        communication: 0,
        punctuality: 0,
        cleanliness: 0,
        truthfulness: 0,
        vehicle: 0,
        comments: "",
        would_recommend: false
      },
      vehicle_eval: {
        general_state: 0,
        steering: 0,
        shock_absorber: 0,
        brakes: 0,
        lights: 0,
        odors: 0,
        noises: 0,
        comments: "",
        would_recommend: false
      },
      ui: {
        isSubmitting: false,
        showMessage: false,
        messageType: undefined,
        messages: [],
        messageTitle: undefined
      }
    };
  }

  reRender = () => {
    this.forceUpdate();
  }

  componentDidMount() {
    window.rerender = this.reRender;
  }

  updateQualification = (obj, name, value) => {
    const secondary_rating = this.secondary_rating_vehicle.map(x => x.name);
    if (obj === "vehicle_eval" && name === "general_state" && value >= 4) {
      secondary_rating.map(x => this.setState(state => {
        state.vehicle_eval[x] = value;
        return { vehicle_eval: state.vehicle_eval }
      }));
    } else if (obj === "vehicle_eval" && name === "general_state" && value < 4) {
      secondary_rating.map(x => this.setState(state => {
        state.vehicle_eval[x] = 0;
        return { vehicle_eval: state.vehicle_eval }
      }));
    }
    this.setState(state => {
      state[obj][name] = value;
      return { [obj]: state[obj] };
    });
  }

  handleChangeComments = comments => {
    this.setState(state => {
      state.eval.comments = comments;
      return { eval: state.eval };
    });
  };

  sendQualification = () => {
    this.setState(state => {
      state.ui.isSubmitting = true;
      return { ui: state.ui };
    });
    const bookingId = window.bookingId;
    const vehicle_id = window.vehicleId;
    const owner_id = window.ownerId;

    const url = `/bookings/${bookingId}/rating.json`;
    const method = "POST";
    let admin_qualification = { ...this.state.owner_eval };
    let vehicle_qualification = { ...this.state.vehicle_eval };
    admin_qualification.booking_id = bookingId;
    vehicle_qualification.booking_id = bookingId;
    Axios(url, {
      method,
      url,
      responseType: "json",
      headers: {
        "Content-Type": "application/json"
      },
      data: { admin_qualification, vehicle_qualification, owner_id, vehicle_id }
    })
      .then(response => {
        if (response.data.success) {
          this.showMessage({
            showMessage: true,
            messageType: "alert-success",
            messages: ["Evaluación guardada..."],
            messageTitle: "Éxito"
          });
          setTimeout(() => {
            Turbolinks.visit(window.location);
          }, 750);
        } else {
          this.showMessage({
            showMessage: true,
            messageType: "alert-warning",
            messages: response.data.messages || [
              "Ha ocurrido un error inesperado"
            ],
            messageTitle: "Alerta"
          });
        }
      })
      .catch(error => {
        console.log("ERROR", error);
        this.showMessage({
          showMessage: true,
          messageType: "alert-danger",
          messages: ["Ha ocurrido un error inesperado"],
          messageTitle: "Error"
        });
      })
      .finally(() =>
        this.setState(state => {
          state.ui.isSubmitting = false;
          return { ui: state.ui };
        })
      );
  }

  showMessage = msg => {
    this.setState(state => {
      state.ui.showMessage = msg.showMessage;
      state.ui.messageType = msg.messageType;
      state.ui.messages = msg.messages;
      state.ui.messageTitle = msg.messageTitle;
      return { ui: state.ui };
    });
  };

  render() {
    const { ui, vehicle_eval, owner_eval } = this.state;
    const { ratings, secondary_rating_vehicle } = this;
    const flash_message = this.state.ui.showMessage ? (
      <FlashMessage
        type={ui.messageType}
        msg={ui.messages}
        title={ui.messageTitle}
      />
    ) : null;

    const buttons_owner = ratings.map((x, i) => (
      <Qualification
        label={x.label}
        key={i}
        name={x.name}
        visible={true}
        value={owner_eval[x.name]}
        onRatingChange={(name, val) => this.updateQualification("owner_eval", name, val)}
      />
    ));
    const comment_owner = (
      <div className="form-group">
        <h4>¿Quieres dejar un comentario?</h4>
        <textarea
          rows={3}
          onChange={e => this.updateQualification("owner_eval", "comments", e.target.value)}
          className="form-control appcar-input"
          value={owner_eval.comments}
        />
      </div>
    );
    const check_would_recommend_owner = (
      <div className="form-group custom-control custom-checkbox mt-2">
        <input
          type="checkbox"
          className="custom-control-input"
          id="wouldrecommendowner"
          onChange={e => this.updateQualification("owner_eval", "would_recommend", e.target.checked)}
          checked={owner_eval.would_recommend}
        />
        <label className="custom-control-label" htmlFor="wouldrecommendowner">
          ¿Lo recomendaría?
        </label>
      </div>
    );

    const general_state_rate = (
      <Qualification
        label={"Estado general"}
        name={"general_state"}
        visible={true}
        value={vehicle_eval.general_state}
        onRatingChange={(name, val) => this.updateQualification("vehicle_eval", name, val)}
      />
    )
    const buttons_vehicle = secondary_rating_vehicle.map((x, i) => (
      <Qualification
        label={x.label}
        key={i}
        name={x.name}
        visible={vehicle_eval.general_state < 4 && vehicle_eval.general_state != 0}
        value={vehicle_eval[x.name]}
        onRatingChange={(name, val) => this.updateQualification("vehicle_eval", name, val)}
      />
    ));
    const comment_vehicle = (
      <div className="form-group">
        <h4>¿Quieres dejar un comentario?</h4>
        <textarea
          rows={3}
          onChange={e => this.updateQualification("vehicle_eval", "comments", e.target.value)}
          className="form-control appcar-input"
          value={vehicle_eval.comments}
        />
      </div>
    );
    const check_would_recommend_vehicle = (
      <div className="form-group custom-control custom-checkbox mt-2">
        <input
          type="checkbox"
          className="custom-control-input"
          id="wouldrecommend"
          onChange={e => this.updateQualification("vehicle_eval", "would_recommend", e.target.checked)}
          checked={vehicle_eval.would_recommend}
        />
        <label className="custom-control-label" htmlFor="wouldrecommend">
          ¿Lo recomendaría?
        </label>
      </div>
    );
    let modal = (
      <div
        id="modal_evaluation"
        className="modal fade"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body p-4">
              <h2>¡Califica tu viaje!</h2>
              {flash_message}
              <div className="row">
                <div className="col-6" style={{ display: "flex", flexDirection: "column" }}>
                  <div className="mb-2">
                    <h3>Calificar Dueño</h3>
                    {buttons_owner}
                  </div>
                  <div style={{ marginTop: "auto" }}>
                    {comment_owner}
                    {check_would_recommend_owner}
                  </div>
                </div>
                <div className="col-6" style={{ display: "flex", flexDirection: "column" }}>
                  <div className="mb-2">
                    <h3>Calificar Vehículo</h3>
                    {general_state_rate}
                    {buttons_vehicle}
                  </div>
                  <div style={{ marginTop: "auto" }}>
                    {comment_vehicle}
                    {check_would_recommend_vehicle}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" onClick={this.sendQualification} className="btn btn-appcar-primary">
                Calificar
              </button>
            </div>
          </div>
        </div>
      </div>
    )
    return (
      <Fragment>
        <Spinner loading={this.state.ui.isSubmitting} />
        {modal}
      </Fragment>
    )
  }
}

export default RateBooking;