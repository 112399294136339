import React from "react";

const TableSearch = ({ onTextChange }) => (
  <div className="dataTables_filter text-right">
    <label className="text-nowrap text-left">
      Buscar dentro de la tabla
      <input
        type="text"
        id="search_input"
        onChange={e => onTextChange(e.target.value)}
        className="ml-2 form-control form-control-sm"
      />
    </label>
  </div>
)

export default TableSearch