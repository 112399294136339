import React, { useState, useRef } from "react";
import { useEffect } from "react";
import LoginModal from "../../rentals/show/modals/LoginModal";
import { validateEmail } from "../../../common/constants";
import Axios from "axios";
import PasswordModal from "../../rentals/show/modals/PasswordModal";
import UserFormModal from "../../rentals/show/modals/UserFormModal";
import PasswordRecovery from "../password_recovery/PasswordRecovery";
import Spinner from "../../../common/Spinner";
import Alert from "../../../common/components/alert/Alert";

const OwnerLogin = ({ documentTypes }) => {
  const [user, setUser] = useState({
    email: "",
    password: "",
    name: "",
    last_name: "",
    phone_number: "",
    document_type: null,
    id_number: "",
  });
  const [alert, setAlert] = useState({
    showMessage: false,
    messageType: undefined,
    messages: [],
    messageTitle: undefined,
  });
  const [isLoading, setLoading] = useState(false);
  const loginModalRef = useRef();
  const passwordModalRef = useRef();
  const userFormModalRef = useRef();
  const passwRecoveryModalRef = useRef();

  useEffect(() => {
    const modalLogin = new bootstrap.Modal(loginModalRef.current);
    const modalPassword = new bootstrap.Modal(passwordModalRef.current);
    const modalUserForm = new bootstrap.Modal(userFormModalRef.current);
    const modalPwRecovery = new bootstrap.Modal(passwRecoveryModalRef.current);
  }, []);

  // const showLoginModal = () =>
  //   bootstrap.Modal.getInstance(loginModalRef.current).show();
  const showPasswordModal = () =>
    bootstrap.Modal.getInstance(passwordModalRef.current).show();
  const showUserFormModal = () =>
    bootstrap.Modal.getInstance(userFormModalRef.current).show();
  const showPwRecoveryModal = () =>
    bootstrap.Modal.getInstance(passwRecoveryModalRef.current).show();
  const closeLoginModal = () =>
    bootstrap.Modal.getInstance(loginModalRef.current).hide();
  const closePasswordModal = () =>
    bootstrap.Modal.getInstance(passwordModalRef.current).hide();
  // const closeUserFormModal = () =>
  //   bootstrap.Modal.getInstance(userFormModalRef.current).hide();
  // const closePwRecoveryModal = () =>
  //   bootstrap.Modal.getInstance(passwRecoveryModalRef.current).hide();

  const handleChangeUser = (value, field) =>
    setUser((prevUser) => ({
      ...prevUser,
      [field]: value,
    }));

  const showMessage = (messageType, messageTitle, messages) => {
    setAlert((prevAlert) => ({
      ...prevAlert,
      messageTitle,
      messageType,
      messages,
      showMessage: true,
    }));
    setTimeout(closeMessage, 3000);
  };

  const closeMessage = () =>
    setAlert((prevAlert) => ({ ...prevAlert, showMessage: false }));

  const checkUser = () => {
    setLoading(true);
    Axios({
      method: "GET",
      url: `/owners/check_email.json?email=${user.email}`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.data.messages[0]) {
          // Ocultar modal email
          closeLoginModal();
          // Si existe pedir contraseña, sino crearlo
          if (response.data.exists) {
            handleChangeUser(response.data.name, "name");
            showPasswordModal();
            document.getElementById("notlogged__password").focus();
          } else {
            showUserFormModal();
          }
        } else {
          showMessage("primary", "Error", ["Ha ocurrido un error inesperado"]);
        }
      })
      .catch((error) => {
        showMessage("primary", "Error", ["Ha ocurrido un error inesperado"]);
      })
      .finally(() => setLoading(false));
  };

  const login = () => {
    setLoading(true);
    const uninterceptedAxios = Axios.create();
    let shouldHideSpinner = true;
    uninterceptedAxios({
      method: "POST",
      url: `/owners/sign_in.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        app_user: {
          email: user.email,
          password: user.password,
        },
        r_to: "vehicles",
        obj_id: "new",
      },
    })
      .then((response) => {
        if (response.data.success) {
          // No se debe ocultar modal de contraseña y modal de formulario de usuario
          shouldHideSpinner = false;
          document.cookie = "user_mode=owner";
          Turbolinks.visit(`/vehicles/new`);
        } else if (response.data.error) {
          showMessage("primary", "Error", [
            response.data.error || "Ha ocurrido un error al iniciar sesión",
          ]);
        }
      })
      .catch((error) => {
        const message =
          error?.request?.response?.error || "Ha ocurrido un error inesperado";
        showMessage("primary", "Error", [message]);
      })
      .finally(() => {
        if (shouldHideSpinner) setLoading(false);
      });
  };

  const updateUser = () => {
    setLoading(true);
    let shouldHideSpinner = true;
    Axios({
      method: "POST",
      url: "/owners.json",
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: { owner: user },
    })
      .then((response) => {
        if (response.data.success) {
          shouldHideSpinner = false;
          login();
        } else {
          showMessage(
            "primary",
            "Error",
            response.data.messages || ["Ha ocurrido un error inesperado"]
          );
        }
      })
      .catch((error) => {
        showMessage("primary", "Error", ["Ha ocurrido un error inesperado"]);
      })
      .finally(() => {
        if (shouldHideSpinner) setLoading(false);
      });
  };

  return (
    <>
      <Spinner loading={isLoading} />
      <Alert
        alertClass={alert.messageType}
        isShowing={alert.showMessage}
        messages={alert.messages}
        title={alert.messageTitle}
        onClose={closeMessage}
      />
      <LoginModal
        user={user}
        onChange={handleChangeUser}
        validateEmail={validateEmail}
        checkUser={checkUser}
        title="Publica tu auto"
        modalRef={loginModalRef}
      />
      <PasswordModal
        user={user}
        onChange={handleChangeUser}
        action={login}
        forgotPassword={() => {
          closePasswordModal();
          showPwRecoveryModal();
        }}
        validateEmail={validateEmail}
        modalRef={passwordModalRef}
        title="Publica tu auto"
        hideDriverAlert={true}
      />
      <UserFormModal
        user={user}
        onChange={handleChangeUser}
        validateEmail={validateEmail}
        action={updateUser}
        isUpdating={false}
        documentTypes={documentTypes}
        checkNeedInvoice={false}
        changeNeedInvoice={() => {}}
        title="Publica tu auto"
        modalRef={userFormModalRef}
        hideDriverAlert={true}
        subtitle="Por favor completa tus datos para publicar tu auto:"
      />
      <PasswordRecovery
        propsEmail={user.email}
        updateEmail={(email) => handleChangeUser(email, "email")}
        canBack={true}
        onBack={showPasswordModal}
        callBack={showPasswordModal}
        modalRef={passwRecoveryModalRef}
      />
    </>
  );
};

export default OwnerLogin;
