import React, { Component, Fragment } from "react";
import { Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import Spinner from "../../common/Spinner";
import FlashMessage from "../../common/FlashMessage";
import axios from "axios";
import { AxiosBackofficeInterceptor } from "../../common/axios_interceptor";
import { validateRUT } from "../../common/constants";
axios.interceptors.response.use(undefined, AxiosBackofficeInterceptor);

const BlackListCheckerSchema = Yup.object().shape({
  rut: Yup.string()
    .matches(/^0*(\d{1,3}(\.?\d{3})*)\-?([\dkK])$/, "RUT inválido")
    .required("RUT inválido")
});

class BlackListChecker extends Component {
  state = {
    data: {
      rut: ""
    },
    blacklisted: undefined,
    error: false,
    ui: {
      isSubmitting: false,
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined
    }
  }

  showMessage = msg => {
    this.setState(state => {
      state.ui.showMessage = msg.showMessage;
      state.ui.messageType = msg.messageType;
      state.ui.messages = msg.messages;
      state.ui.messageTitle = msg.messageTitle;
      return { ui: state.ui };
    });
  };

  render() {
    const flash_message = this.state.ui.showMessage ? (
      <FlashMessage
        type={this.state.ui.messageType}
        msg={this.state.ui.messages}
        title={this.state.ui.messageTitle}
      />
    ) : null;
    let resultText = null;
    if (this.state.blacklisted) resultText = (
      <div className="text-danger">
        <h1>Se encuentra en la lista negra <i className="mdi mdi-close-circle" /></h1>
      </div>
    )
    else if (this.state.blacklisted === false) resultText = (
      <div className="text-success">
        <h1>Aprobado lista negra <i className="mdi mdi-check-circle" /></h1>
      </div>
    );
    const stateError = this.state.error;
    return (
      <Fragment>
        {flash_message}
        <Formik
          showMessage={this.showMessage}
          enableReinitialize={true}
          initialValues={this.state.data}
          validationSchema={BlackListCheckerSchema}
          onSubmit={(values, { setSubmitting }) => {
            // const token = document.getElementsByName("csrf-token")[0].content;
            if (!validateRUT(values.rut)) {
              this.setState({ error: "RUT inválido", blacklisted: undefined }, () => {
                setSubmitting(false);
              });
              return;
            } else {
              this.setState({ error: false, blacklisted: undefined });
            }
            axios(`/backoffice/blacklist.json?rut=${values.rut}`, {
              method: "GET",
              url: `/backoffice/blacklist.json?rut=${values.rut}`,
              responseType: "json",
              headers: {
                "Content-Type": "application/json"
              },
            })
              .then(response => {
                const blacklisted = response.data.blacklisted;
                this.setState({ blacklisted });
              })
              .catch(error =>
                this.showMessage({
                  showMessage: true,
                  messageType: "alert-danger",
                  messages: ["Ha ocurrido un error inesperado"],
                  messageTitle: "Error"
                })
              )
              .then(() => setSubmitting(false));
          }}
        >
          {({
            errors,
            touched,
            handleSubmit,
            isSubmitting
          }) => {
            return (
              <Fragment>
                <Spinner loading={isSubmitting || this.state.ui.isSubmitting} />
                <form onSubmit={handleSubmit} style={{ marginTop: "30vh" }}>
                  <div className="text-center mt-5">
                    {resultText}
                  </div>
                  <div className="row mt-2">
                    <div className="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
                      <div className="form-group mb-2">
                        <Field
                          type="text"
                          name="rut"
                          className={
                            "mr-2 form-control appcar-input " +
                            classNames({
                              "is-invalid":
                                stateError || touched.rut && errors.rut
                            })
                          }
                          placeholder="Ingrese rut..."
                        />
                        {(<ErrorMessage name="rut">
                          {msg => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>) || stateError ? (
                          <div className="invalid-feedback" style={{ display: "block" }}>{stateError}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
                      <div className="row">
                        <div className="col mr-auto">
                          <button
                            type="submit"
                            className="btn btn-appcar-primary mr-4"
                            disabled={isSubmitting}
                          >
                            Verificar rut
                          </button>
                        </div>
                        <div className="col-auto pt-3">
                          <a href="/backoffice/blacklist/new" className="text-black">
                            Añadir a la lista negra...
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </Fragment>
            );
          }}
        </Formik>
      </Fragment>
    )
  }
}

export default BlackListChecker;
