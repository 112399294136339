import React, { Component } from "react";
import Spinner from "../../../common/Spinner";
import FlashMessage from "../../../common/FlashMessage";
import LocationSelector from "../../../common/search_components/LocationSelector";

class LocationSearchInput extends Component {
  state = {
    name: this.props.name || "",
    latitude: parseFloat(this.props.latitude || 0.0),
    longitude: parseFloat(this.props.longitude || 0.0),
    isSubmitting: false,
    ui: {
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined
    }
  };


  handleSelectLocation = ({ success, lat, lon, address }) => {
    if (success) {
      this.setState({ latitude: lat, longitude: lon, name: address, isSubmitting: false });
    } else {
      this.setState({ latitude: "", longitude: "", isSubmitting: false });
    }
  };

  loadSpinner = () => this.setState({ isSubmitting: true })

  handleSubmit = e => {
    if (!!!this.state.latitude || !!!this.state.longitude) {
      e.preventDefault();
      this.setState(state => {
        state.ui.showMessage = true;
        state.ui.messageType = "alert-warning";
        state.ui.messages = ["Debes seleccionar un campo de la lista"];
        state.ui.messageTitle = "Alerta";
        return { ui: state.ui };
      });
    } else {
      this.setState({ isSubmitting: true });
    }
  };

  render() {
    const { name, latitude, longitude } = this.state;
    let countries = ["cl"];
    if (this.props.userCountry && this.props.userCountry != "CL") {
      countries.push(this.props.userCountry.toLowerCase());
    }
    const flash_message = this.state.ui.showMessage ? (
      <FlashMessage
        type={this.state.ui.messageType}
        msg={this.state.ui.messages}
        title={this.state.ui.messageTitle}
      />
    ) : null;
    return (
      <React.Fragment>
        <Spinner loading={this.state.isSubmitting} />
        {flash_message}
        <h4>Lugar de retirada/entrega del vehículo</h4>
        <LocationSelector
          countries={countries}
          handleSelectLocation={this.handleSelectLocation}
          placeholder="Introduce una ubicación a buscar..."
          label=""
          loadSpinner={this.loadSpinner}
        />
        <input
          type="hidden"
          name="rental_location[latitude]"
          value={latitude}
          id="rental_location_latitude"
        />
        <input
          type="hidden"
          name="rental_location[longitude]"
          value={longitude}
          id="rental_location_longitude"
        />
        <input
          type="hidden"
          name="rental_location[name]"
          value={name}
          id="rental_location_name"
        />
        <div className="row mt-2 mb-5 pb-4">
          <div className="col">
            <button
              type="submit"
              className="btn btn-appcar-primary"
              onClick={e => this.handleSubmit(e)}
            >
              Guardar Cambios
            </button>
            <a
              href={`/rentals/${this.props.rentalId}/edit?step=2`}
              className="ml-2 btn btn-appcar-light"
            >
              Cancelar
            </a>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default LocationSearchInput;
