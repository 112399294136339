import React, { Component } from "react";
import Spinner from "../../../common/Spinner";
import FlashMessage from "../../../common/FlashMessage";
import axios from "axios";
import ImageCarousel from "../../../common/ImageCarousel";
import UserInfo from "../../rentals/rentals_show/UserInfo";
import VehicleStats from "./components/VehicleStats";
import VehicleInfo from "../../rentals/rentals_show/VehicleInfo";
import Bookinginfo from "./components/BookingInfo";
import { AxiosApplicationInterceptor } from "../../../common/axios_interceptor";
axios.interceptors.response.use(undefined, AxiosApplicationInterceptor);
import uniqBy from "lodash/uniqBy";

class BookingShow extends Component {
  state = {
    booking: this.props.booking,
    cancelError: false,
    reviews: [
      {
        user: {
          user_image: {
            url:
              "https://www.platypusdigital.com/wp-content/uploads/2014/07/random-lady.jpg"
          },
          name: "Andrea",
          last_name: "Pérez"
        },
        review:
          "Aenean placerat nisl quis rutrum convallis. Duis volutpat elementum nisl sed mollis. Nam quis vehicula lorem. Vestibulum ut hendrerit sem. Suspendisse a ipsum ac purus blandit condimentum.",
        rating: 5,
        date: new Date(2018, 8, 14)
      },
      {
        user: {
          user_image: {
            url:
              "https://www.rockstart.com/wp-content/uploads/2012/06/bas-300x300.jpg"
          },
          name: "Erik",
          last_name: "Meneses"
        },
        review:
          "Sed ornare arcu ex, sit amet volutpat leo rhoncus eget. Etiam id maximus lacus. Maecenas pulvinar pulvinar sem. Ut sed tincidunt sapien. Aliquam sed sem dolor. Maecenas eu dictum purus, eu euismod ipsum. Maecenas a risus ultrices, ullamcorper ligula in, aliquet felis.",
        rating: 4,
        date: new Date(2018, 10, 7)
      }
    ],
    bookings: [],
    ui: {
      isSubmitting: false,
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined
    }
  };

  componentDidMount() {
    $("body").click(function (e) {
      if (!$(e.target).hasClass("anc-tooltip")) {
        $(".tooltiptext").removeClass("tooltip-visible");
      } else {
        var tooltipFor = "#" + e.target.dataset.toggleTooltipFor;
        $(".tooltiptext")
          .not($(tooltipFor))
          .removeClass("tooltip-visible");
        $(tooltipFor).toggleClass("tooltip-visible");
      }
    });
  }

  showMessage = msg => {
    this.setState(state => {
      state.ui.showMessage = msg.showMessage;
      state.ui.messageType = msg.messageType;
      state.ui.messages = msg.messages;
      state.ui.messageTitle = msg.messageTitle;
      return { ui: state.ui };
    });
  };

  callbackRateVehicle = () => [
    this.setState(state => {
      if (state.booking.review_status == null) {
        state.booking.review_status = {
          owner: false,
          driver: false,
          vehicle: true
        }
      } else {
        state.booking.review_status.vehicle = true;
      }
      return { booking: state.booking }
    })
  ]

  callbackRateOwner = () => [
    this.setState(state => {
      if (state.booking.review_status == null) {
        state.booking.review_status = {
          owner: true,
          driver: false,
          vehicle: false
        }
      } else {
        state.booking.review_status.owner = true;
      }
      return { booking: state.booking }
    })
  ]

  confirmCancellation = () => {
    this.setState(state => {
      state.ui.isSubmitting = true;
      return { ui: state.ui };
    });
    axios({
      method: "post",
      url: `/bookings/${this.props.bookingId}/driver_cancel.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        const result = response.data.result;
        console.log(result);
        axios({
          method: "get",
          url: `/bookings/${this.props.bookingId}.json`,
          responseType: "json",
          headers: {
            "Content-Type": "application/json"
          }
        })
          .then(response_ => {
            const booking = response_.data;
            this.setState({ booking }, () => {
              this.showMessage({
                showMessage: true,
                messageTitle: result.success
                  ? "Cancelación de reserva"
                  : "Error",
                messages: [result.message],
                messageType: result.success ? "alert-info" : "alert-danger"
              });
            });
          })
          .catch(error => {
            console.log(error);
            this.showMessage({
              showMessage: true,
              messageType: "alert-danger",
              messages: ["Ha ocurrido un error inesperado"],
              messageTitle: "Error"
            });
          })
          .finally(() => {
            this.setState(state => {
              state.ui.isSubmitting = false;
              return { ui: state.ui };
            });
          });
      })
      .catch(error => {
        console.log(error);
        this.showMessage({
          showMessage: true,
          messageType: "alert-danger",
          messages: ["Ha ocurrido un error inesperado"],
          messageTitle: "Error"
        });
      })
      .finally(() => {
        this.setState(state => {
          state.ui.isSubmitting = false;
          return { ui: state.ui };
        });
      });
  };

  cancelBooking = () => {
    const booking = this.state.booking;
    if (moment(booking.date_until).valueOf() > moment().valueOf()) {
      $("#modal-confirm-cancellation").modal("show");
    } else {
      this.setState({ cancelError: true }, () =>
        this.showMessage({
          showMessage: true,
          messageType: "alert-warning",
          messages: ["No puedes cancelar un booking pasado"],
          messageTitle: "Error"
        })
      );
    }
  };

  render() {
    const images = this.state.booking.vehicle.vehicle_pictures.map(image => {
      const url = image;
      return { url };
    });
    const flash_message = this.state.ui.showMessage ? (
      <FlashMessage
        type={this.state.ui.messageType}
        msg={this.state.ui.messages}
        title={this.state.ui.messageTitle}
      />
    ) : null;
    const features = this.state.booking.vehicle.vehicle_features || [];
    let temp_extras = this.state.booking.price.extra_charges.map(
      extra => extra
    );
    this.state.booking.price.details.forEach(detail => {
      detail.extra_charges.forEach(charge => temp_extras.push(charge));
    });
    let extras = uniqBy(temp_extras.map(x => x.extra), "id");

    const rules = this.state.booking.vehicle.cancellation_policy
      ? this.state.booking.vehicle.cancellation_policy.rules.map((rule, i) => {
        return <li key={i}>{rule.description}</li>;
      })
      : <li>El vehículo no tiene políticas de cancelación</li>;
    const modal_confirm = (
      <div
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="confirm-title"
        aria-hidden="true"
        id="modal-confirm-cancellation"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="confirm-title">
                {`Política de cancelación: ${this.state.booking.vehicle
                  .cancellation_policy &&
                  this.state.booking.vehicle.cancellation_policy.name || "-"}`}
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                data-bs-dismiss="modal"
                aria-label="Cerrar"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h5>
                La reserva tiene las siguientes políticas al momento de cancelar
              </h5>
              <ul>{rules}</ul>
            </div>
            <div className="modal-footer">
              <h5>
                Al presionar sí, estás aceptando la política de cancelación
              </h5>
              <button
                type="button"
                className="btn btn-appcar-primary"
                id="confirm-btn"
                onClick={e => {
                  e.preventDefault();
                  this.confirmCancellation();
                  $("#modal-confirm-cancellation").modal("hide");
                }}
              >
                Si
              </button>
              <button
                type="button"
                className="btn btn-appcar-light"
                data-dismiss="modal"
                data-bs-dismiss="modal"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    const carousel = images.length > 0 ?
    (<div className="vehicle-pictures">
      <ImageCarousel
        images={images}
        canViewFullSize={true}
        modalName="modal-booking-carousel"
      />
    </div>) : null;
    return (
      <React.Fragment>
        <div className="vehicle-view">
          <Spinner loading={this.state.ui.isSubmitting} />
          {carousel}
          <div className="container-search mb-5" style={{ bottom: images.length > 0 ? "4rem" : "" }}>
            <div className="container">
              <div className="card">
                <div className="card-body">
                  {flash_message}
                  <div className="row mb-3">
                    <div className="col-lg-8 col-md-12">
                      <div className="row">
                      <div className="col-4 pr-1">
                          <UserInfo
                            owner={
                              this.state.booking.vehicle.associated_users[0]
                            }
                            isBooking={true}
                            booking={this.state.booking}
                            callbackRate={this.callbackRateOwner}
                          />
                        </div>
                        <div className="col-8 pl-1">
                          <VehicleStats
                            vehicle={this.state.booking.vehicle}
                            booking={this.state.booking}
                            extras={extras}
                            features={features}
                            isBooking={true}
                            bookingId={this.state.booking.id}
                            callbackRate={this.callbackRateVehicle}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <VehicleInfo
                            vehicle={this.state.booking.vehicle}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <Bookinginfo
                        booking={this.state.booking}
                        cancelBooking={this.cancelBooking}
                        cancelError={this.state.cancelError}
                        originalBooking={this.props.originalBooking}
                      />
                    </div>
                  </div>
                  {/* <div className="row justify-content-center">
                    <div className="col-8">
                      <VehicleReviews reviews={this.state.reviews} />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {modal_confirm}
      </React.Fragment>
    );
  }
}

export default BookingShow;