import { head } from "lodash";
import React from "react";

const TableHeader = ({ text, icon = null, onOrderClick=null, newOrderStatus }) => (
  <th
    onClick={() => onOrderClick ? onOrderClick(newOrderStatus) : null}
  >
    {text} {icon}
  </th>
)

export default TableHeader;