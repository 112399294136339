import React, { Component } from "react";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import axios from "axios";
import { AxiosBackofficeInterceptor } from "../../common/axios_interceptor";
import debounce from "lodash/debounce";

export default class UserSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      inputValue: "",
    };
    this.filterUsers = debounce(this.filterUsers, 500, { leading: true });
  }

  componentDidMount() {
    axios.interceptors.response.use(undefined, AxiosBackofficeInterceptor);
  }

  filterUsers = (value, callback) => {
    const val = value.toLowerCase();
    if (val.length > 7) {
      return axios({
        method: "get",
        url: `/backoffice/rentals/search_users.json?name=${encodeURIComponent(
          val
        )}`,
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          const users = response.data;
          this.setState({ users });
          const options = users.map((u) => ({
            value: u.id,
            label:
              u.name +
              " " +
              u.last_name +
              " " +
              (u.second_last_name || "") +
              " | " +
              u.id_number,
          }));
          callback(options);
        })
        .catch((error) => {
          this.props.showMessage({
            showMessage: true,
            messageType: "alert-danger",
            messages: ["Ha ocurrido un error al buscar los usuarios"],
            messageTitle: "Error",
          });
        });
    } else {
      callback([]);
    }
  };

  handleUserChange = (option) => {
    const users = this.state.users;
    const user =
      !!option && option.value
        ? users.find((user) => user.id == option.value)
        : undefined;
    this.props.selectUser(user);
  };

  onInputChange = (inputValue) => {
    const users = inputValue.length > 0 ? this.state.users : [];
    this.setState({ inputValue, users });
  };

  render() {
    const user = this.props.user;
    const value = {
      value: !!user ? user.id : null,
      label: !!user
        ? user.name +
          " " +
          user.last_name +
          " " +
          (user.second_last_name || "") +
          " | " +
          user.id_number
        : "Buscar...",
    };
    const clearable = !!this.props.clearable;
    return (
      <AsyncSelect
        cacheOptions={true}
        loadOptions={this.filterUsers}
        onChange={this.handleUserChange}
        // inputValue={this.state.inputValue}
        // onInputChange={this.onInputChange}
        loadingMessage={(obj) => {
          if (obj.inputValue.length >= 7) {
            return "Buscando...";
          } else {
            return "Se deben escribir al menos 7 caracteres";
          }
        }}
        noOptionsMessage={(obj) => {
          if (obj.inputValue.length >= 7) {
            return "No se han encontrado resultados";
          } else {
            return "Se deben buscar usuarios";
          }
        }}
        placeholder="Buscar..."
        value={value}
        classNamePrefix="RS-FIX"
        className="react-select-fix"
        isClearable={clearable}
        components={{
          Option,
        }}
        on
      />
    );
  }
}

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div className="form-check">
          <input
            type="radio"
            className="form-check-input"
            id={`select_option_${props.value}`}
            name={`select_option_${props.value}`}
            checked={props.isSelected}
            onChange={() => null}
          />
          <label
            className="form-check-label"
            htmlFor={`select_option_${props.value}`}
          >
            {props.label}
          </label>
        </div>
      </components.Option>
    </div>
  );
};
