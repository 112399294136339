import React, { Component, Fragment } from "react";
import * as Yup from "yup";
import Spinner from "../../common/Spinner";
import axios from "axios";
import * as _ from "lodash";
import find from "lodash/find";
import UserSearch from "../user_search/UserSearch";
import Select from "react-select";
import { numeral } from "../../common/constants";
import { AxiosBackofficeInterceptor } from "../../common/axios_interceptor";
import Alert from "../../common/components/alert/Alert";
axios.interceptors.response.use(undefined, AxiosBackofficeInterceptor);

const PenaltiesSchema = Yup.object().shape({
  user: Yup.object().shape({
    id: Yup.number().required("Campo requerido"),
  }),
  motive: Yup.object().shape({
    id: Yup.number().required("Campo requerido"),
  }),
  amount: Yup.number().required("Campo requerido"),
  notes: Yup.string(),
});

class PenaltiesForm extends Component {
  state = {
    penalty: {
      user: undefined,
      motive: undefined,
      amount: 0.0,
      notes: "",
    },
    isSubmitting: false,
    penalty_motives: this.props.penaltyMotives,
    alert: {
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined,
    },
  };

  modalConfirm = null;

  componentDidMount() {
    this.modalConfirm = new bootstrap.Modal(
      document.getElementById("modal-confirm-penalty")
    );
  }

  checkForm = () => {
    const penalty = this.state.penalty;
    return !!penalty.user && !!penalty.motive && !!penalty.amount;
  };

  showMessage = (messageType, messageTitle, messages) =>
    this.setState(
      (state) => {
        state.alert.showMessage = true;
        state.alert.messageTitle = messageTitle;
        state.alert.messages = messages;
        state.alert.messageType = messageType;
        return { alert: state.alert };
      },
      () => setTimeout(this.handleAlertClose, 3000)
    );

  handleAlertClose = () =>
    this.setState((state) => {
      state.alert.showMessage = false;
      return { alert: state.alert };
    });

  showModal = () => {
    this.modalConfirm.show();
  };

  handleSubmit = () => {
    this.setState({ isSubmitting: true });
    const penalty = this.state.penalty;
    const token = null;
    const url = `/backoffice/penalties.json`;
    const method = "POST";
    axios(url, {
      method,
      url,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: { penalty },
    })
      .then((response) => {
        if (response.data.success) {
          Turbolinks.visit(`/backoffice/penalties?success=1`);
        } else {
          this.showMessage(
            "primary",
            "Alerta",
            response.data.messages || ["Ha ocurrido un error inesperado"]
          );
        }
      })
      .catch((error) =>
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ])
      )
      .finally(() => {
        this.setState({ isSubmitting: false });
      });
  };

  selectUser = (user) => {
    this.setState((state) => {
      state.penalty.user = user;
      return { penalty: state.penalty };
    });
  };

  handleChange = (name, value) => {
    const val = name == "amount" ? parseFloat(value) : value;
    this.setState((state) => {
      state.penalty[name] = val;
      return { penalty: state.penalty };
    });
  };

  handleChangeReason = (option) => {
    const reason = find(this.state.penalty_motives, { id: option.value });
    this.setState((state) => {
      state.penalty.motive = reason;
      return { penalty: state.penalty };
    });
  };

  render() {
    const enableBtn = this.checkForm();

    const { penalty, isSubmitting, alert, penalty_motives } = this.state;
    const penalty_motive_options = penalty_motives.map((fine) => {
      return {
        value: fine.id,
        label: fine.name,
      };
    });

    const { messageTitle, messageType, messages, showMessage } = alert;

    const user = penalty.user;
    const username = user
      ? user.name + " " + user.last_name + " " + (user.last_name || "")
      : "";
    const modal_confirm_fine = (
      <div
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="fine-title"
        aria-hidden="true"
        id="modal-confirm-penalty"
      >
        <div className="modal-dialog modal-dialog-centered modal-appcar">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-m-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                x
              </button>
              <h5 className="modal-title" id="fine-title">
                Confirmar multa
              </h5>
            </div>
            <div className="modal-body">
              <div className="px-4 pb-5">
                <h5>Resumen de la multa a aplicar</h5>
                <div className="row">
                  <div className="col-auto">Usuario</div>
                  <div className="col align-self-end text-right">
                    {username}
                  </div>
                </div>
                <div className="row">
                  <div className="col-auto">Motivo de multa</div>
                  <div className="col align-self-end text-right">
                    {this.state.penalty.motive &&
                      this.state.penalty.motive.name}
                  </div>
                </div>
                <div className="row">
                  <div className="col-auto">Valor de la multa</div>
                  <div className="col align-self-end text-right">
                    {numeral(this.state.penalty.amount).format("$ 0,0")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-auto">Notas</div>
                  <div className="col align-self-end text-right">
                    {this.state.penalty.notes}
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-auto">
                  <button
                    type="button"
                    className="btn modal-btn-cancel"
                    data-dismiss="modal"
                    data-bs-dismiss="modal"
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="btn modal-btn-confirm"
                    id="confirm-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      this.handleSubmit();
                      $("#modal-confirm-penalty").modal("hide");
                    }}
                  >
                    Si
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    const selected_penalty_motive = penalty.motive
      ? {
          value: penalty.motive.id,
          label: penalty.motive.name,
        }
      : undefined;
    return (
      <Fragment>
        <Spinner loading={isSubmitting} />
        <Alert
          alertClass={messageType}
          isShowing={showMessage}
          messages={messages}
          title={messageTitle}
          onClose={this.handleAlertClose}
        />
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label className="label-inside">Buscar usuario</label>
              <UserSearch
                user={this.state.penalty.user}
                selectUser={this.selectUser}
                hasLabel={true}
                colClass="col-lg-12"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="label-inside">Selecciona el motivo</label>
              <Select
                value={selected_penalty_motive}
                options={penalty_motive_options}
                placeholder="Selecciona..."
                noOptionsMessage={() => "No existen motivos aún..."}
                onChange={this.handleChangeReason}
                classNamePrefix="RS-FIX"
                className="react-select-fix"
              />
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-lg-6">
            <div className="form-group mb-3">
              <label htmlFor="code">Monto de la multa</label>
              <div className="input-group appcar-input-light">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="fee-prepend">
                    $
                  </span>
                </div>
                <input
                  type="number"
                  name="amount"
                  className="form-control appcar-input-light"
                  value={this.state.penalty.amount}
                  onChange={(e) => this.handleChange("amount", e.target.value)}
                  aria-describedby="fee-prepend"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group mb-3">
              <label htmlFor="description">Notas</label>
              <textarea
                type="text"
                name="notes"
                className="form-control appcar-input-light"
                rows="4"
                value={this.state.penalty.notes}
                onChange={(e) => this.handleChange("notes", e.target.value)}
              />
            </div>
          </div>
        </div>
        <button
          type="button"
          className="btn btn-appcar-primary"
          disabled={this.state.isSubmitting || !enableBtn}
          onClick={this.showModal}
        >
          Multar usuario
        </button>
        {modal_confirm_fine}
      </Fragment>
    );
  }
}

export default PenaltiesForm;
