import React from "react";
import { numeral } from "../../../common/constants";

const PromotionalMiniCard = ({
  image,
  qty,
  daysNumber,
  ownerName,
  vehicleType,
}) => (
  <div className="promotional-minicard">
    <div className="car-img">
      <img src={image} alt="Vehículo" />
    </div>
    <div className="earned">
      <p>
        <span className="fs-17">{numeral(qty).format("$0,0")}</span>
        <br />
        arrendándolo
        <br />
        {daysNumber} días en el mes
      </p>
    </div>
    <div className="owner-vehicle">
      <p>
        <span className="fw-900">{ownerName}</span> con su
        <br />
        <span className="fw-900">{vehicleType}</span>
      </p>
    </div>
  </div>
);

export default PromotionalMiniCard;
