import React, { Component } from "react";

const ratingClass = (number, val) =>
  number <= val ? "mdi mdi-star" : "mdi mdi-star-outline";

class Qualification extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.resetRating = this.resetRating.bind(this);
    // this.qualificationChange = this.qualificationChange.bind(this);
  }

  componentDidMount() {
    let star_container = $(".vehicle-qualification");
    star_container.find(".mdi").mouseenter(this.hoverRating);
    star_container.mouseleave(e => this.resetRating(e));
  }

  componentDidUpdate() {
    let star_container = $(".vehicle-qualification");
    star_container.find(".mdi").mouseenter(this.hoverRating);
    star_container.mouseleave(e => this.resetRating(e));
  }

  hoverRating() {
    let star = $(this);
    star.removeClass("mdi-star-outline").addClass("mdi-star");
    star
      .prevAll()
      .removeClass("mdi-star-outline")
      .addClass("mdi-star");
    star
      .nextAll()
      .removeClass("mdi-star")
      .addClass("mdi-star-outline");
  }

  resetRating = e => {
    const value = this.props.value || 0;
    let star_container = $(`#${this.props.name}`);
    star_container.children().each((i, elem) => {
      $(elem)
        .removeClass("mdi-star-outline")
        .removeClass("mdi-star")
        .addClass(i < value ? "mdi-star" : "mdi-star-outline");
    });
  };

  render() {
    if (!this.props.visible) return null;
    const ratings = [1, 2, 3, 4, 5].map(x => {
      return (
        <li
          className={`${ratingClass(x, this.props.value)}`}
          onClick={() => this.props.onRatingChange(this.props.name, x)}
          key={x}
        />
      );
    });
    return (
      <div className="anc-qualification">
        <h4>{this.props.label}</h4>
        <ul id={this.props.name}>
          {ratings}
        </ul>
      </div>
    );
  }
}

export default Qualification;
