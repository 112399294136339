import React from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';

const Spinner = props => {
  const message = props.message ? <h3 className="spinner-caption">{props.message}</h3> : null;
  return (
    <div className={props.loading ? "sweet-loading" : "ocultar"}>
      <div className="spinner text-center">
        <img src="/loading.gif" width={100} height={100}/>
        {message}
      </div>
    </div>
  );
};

export default Spinner;