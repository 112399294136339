import React, { Component, Fragment } from "react";
import ImageCarousel from "../../common/components/carousel/ImageCarousel";
import Spinner from "../../common/Spinner";

export default class ImagesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      ui: {
        isSubmitting: false,
        showMessage: false,
        messageType: undefined,
        messages: [],
        messageTitle: undefined
      },
      actual_session_carousel: 0
    };
  }

  changePictureIndex = (index) => {
    this.setState({ index });
  };

  handleDownload = url => {
    this.setState(state => {
      state.ui.isSubmitting = true;
      return { ui: state.ui };
    });
    const filename = url.substring(url.lastIndexOf("/") + 1);
    let a = document.createElement("a");
    fetch(url)
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        a.style.display = "none";
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert("oh no!"))
      .finally(() => {
        a.parentNode.removeChild(a);
        this.setState(state => {
          state.ui.isSubmitting = false;
          return { ui: state.ui };
        });
      });
  };

  render() {
    // REFACTOR: este componente necesita deshacerse en un montón más, por ejemplo por cada carrusel, por cada botón.
    const pics = this.props.pics;
    const pics_carousel =
      pics.length > 0 ? (
        <ImageCarousel
          images={pics}
          canDownload={true}
          name="index"
          reportIndex={this.changePictureIndex}
        />
      ) : (
          <div className="text-center alert light">No hay imágenes</div>
        );
    const download_button =
      pics.length > 0 ? (
        <button
          onClick={() =>
            this.handleDownload(
              pics[this.state.index]
            )
          }
          className="btn btn-appcar-light float-right"
          type="button"
        >
          Descargar imagen
        </button>
      ) : null;
    const pics_container = (
      <div>
        <div className="row my-2">
          <div className="col-auto">
            <h4>{this.props.picsName}</h4>
          </div>
          <div className="col align-self-end">
            {download_button}
          </div>
        </div>
        <div className="row my-3">
          <div className="col">{pics_carousel}</div>
        </div>
        <hr />
      </div>
    );
    return (
      <React.Fragment>
        <Spinner loading={this.state.ui.isSubmitting} />
        {pics_container}
      </React.Fragment>
    );
  }
}
