import React from "react";

const VehicleFeatures = ({
  data,
  addFeature,
  removeFeature,
  isSubmitting,
  handleSubmit,
  vehicleFeatures,
  handleChange,
}) => {
  const selectedFeatures = data.vehicle_features;
  const unlimitedDistance = data.unlimited_distance;
  let featuresContainer = vehicleFeatures
    .filter((x) => x.a_name != "4 x 4" && x.id != 2)
    .map((feature, i) => {
      const checked = selectedFeatures.some((f) => f.id === feature.id);
      return (
        <div className="col-6 mb-3" key={i}>
          <button
            type="button"
            onClick={() => {
              if (checked) removeFeature(feature);
              else addFeature(feature);
            }}
            className={`btn btn-appcar-${
              checked ? "secondary" : "white text-black"
            } w-100 fs-12`}
          >
            {feature.a_name}
          </button>
        </div>
      );
    });
  const max250kmContainer = (
    <div className="col-6 mb-3">
      <button
        type="button"
        onClick={() => {
          if (unlimitedDistance) handleChange(false, "unlimited_distance");
          else handleChange(true, "unlimited_distance");
        }}
        className={`btn btn-appcar-${
          unlimitedDistance ? "secondary" : "white text-black"
        } w-100 fs-12`}
      >
        Distancia incluída: 250km/día
      </button>
    </div>
  );
  // featuresContainer.unshift(max250kmContainer); // TODO - JUAN: Descomentar esto si quieren que vaya acá lo de los 250km
  return (
    <>
      <div className="row">{featuresContainer}</div>
      <div className="text-center my-4">
        <button
          type="button"
          className="btn btn-appcar-primary pt-2 pb-2"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          Continuar
        </button>
      </div>
    </>
  );
};

export default VehicleFeatures;
