import React, { Component } from "react";
import Qualification from "./Qualification";
import axios from "axios";
import FlashMessage from "../../common/FlashMessage";
import Spinner from "../../common/Spinner";

class DriverQualification extends Component {
  constructor(props) {
    super(props);
    this.ratings = [
      { name: "communication", label: "Comunicación" },
      { name: "punctuality", label: "Puntualidad" },
      { name: "cleanliness", label: "Limpieza" },
      { name: "driving", label: "Conducción" }
    ];
    this.state = {
      eval: {
        communication: 0,
        punctuality: 0,
        cleanliness: 0,
        driving: 0,
        comments: ""
      },
      // would_recommend: false,
      ui: {
        isSubmitting: false,
        showMessage: false,
        messageType: undefined,
        messages: [],
        messageTitle: undefined
      }
    };
    this.updateQualification = this.updateQualification.bind(this);
    this.handleSendDriverQualification = this.handleSendDriverQualification.bind(
      this
    );
    this.handleWouldRecomendCheck = this.handleWouldRecomendCheck.bind(this);
  }
  updateQualification(name, value) {
    this.setState(state => {
      state.eval[name] = value;
      return { eval: state.eval };
    });
  }
  handleWouldRecomendCheck(value) {
    this.setState({ would_recommend: value });
  }
  handleChangeComments = comments => {
    this.setState(state => {
      state.eval.comments = comments;
      return { eval: state.eval };
    });
  };
  handleSendDriverQualification() {
    this.setState(state => {
      state.ui.isSubmitting = true;
      return { ui: state.ui };
    });
    const url = `/owners/${this.props.driverId}/rating_driver`;
    const method = "POST";
    let data = { ...this.state.eval };
    data.booking_id = this.props.bookingId;
    axios(url, {
      method,
      url,
      responseType: "json",
      headers: {
        "Content-Type": "application/json"
      },
      data: { driver_qualification: data }
    })
      .then(response => {
        if (response.data.success) {
          this.showMessage({
            showMessage: true,
            messageType: "alert-success",
            messages: ["Evaluación guardada..."],
            messageTitle: "Éxito"
          });
          setTimeout(() => {
            this.props.callback();
          }, 750);
        } else {
          this.showMessage({
            showMessage: true,
            messageType: "alert-warning",
            messages: response.data.messages || [
              "Ha ocurrido un error inesperado"
            ],
            messageTitle: "Alerta"
          });
        }
      })
      .catch(error => {
        console.log("ERROR", error);
        this.showMessage({
          showMessage: true,
          messageType: "alert-danger",
          messages: ["Ha ocurrido un error inesperado"],
          messageTitle: "Error"
        });
      })
      .finally(() =>
        this.setState(state => {
          state.ui.isSubmitting = false;
          return { ui: state.ui };
        })
      );
  }

  showMessage = msg => {
    this.setState(state => {
      state.ui.showMessage = msg.showMessage;
      state.ui.messageType = msg.messageType;
      state.ui.messages = msg.messages;
      state.ui.messageTitle = msg.messageTitle;
      return { ui: state.ui };
    });
  };

  render() {
    const flash_message = this.state.ui.showMessage ? (
      <FlashMessage
        type={this.state.ui.messageType}
        msg={this.state.ui.messages}
        title={this.state.ui.messageTitle}
      />
    ) : null;

    const buttons = this.ratings.map((x, i) => (
      <Qualification
        label={x.label}
        key={i}
        name={x.name}
        visible={true}
        value={this.state.eval[x.name]}
        onRatingChange={this.updateQualification}
      />
    ));
    const would_recommend = (
      <div className="form-group custom-control custom-checkbox mt-2">
        <input
          type="checkbox"
          className="custom-control-input"
          id="wouldrecommend"
          onChange={e => this.handleWouldRecomendCheck(e.target.checked)}
        />
        <label className="custom-control-label" htmlFor="wouldrecommend">
          ¿Lo recomendaría?
        </label>
      </div>
    );
    return (
      <div>
        <h2>¡Califica al usuario!</h2>
        {flash_message}
        {buttons}
        <br className="my-2" />
        <div className="form-group">
          <h4>¿Quieres dejar un comentario?</h4>
          <textarea
            rows={3}
            onChange={e => this.handleChangeComments(e.target.value)}
            className="form-control appcar-input"
          />
        </div>
        <button
          className={"btn btn-appcar-primary"}
          onClick={() => this.handleSendDriverQualification()}
        >
          Calificar
        </button>
        <Spinner loading={this.state.ui.isSubmitting} />
      </div>
    );
  }
}

export default DriverQualification;
