import React, { Component } from "react";
import { InputFeedback } from "../../../common/CustomInputs";
import Moment from "moment";
import { extendMoment } from "moment-range";
import isEmpty from "lodash/isEmpty";
import axios from "axios";
import queryString from "query-string";
import { constants, subleaseAgreementText } from "../../../common/constants";
import PricingDetails from "../../../common/PricingDetails";
import LegalModal from "../../../common/legal_modals/LegalModal";
import Spinner from "../../../common/Spinner";
import Select from "react-select";
import { AxiosApplicationInterceptor } from "../../../common/axios_interceptor";
import Policies from "../../../common/Policies";
import RentalSchedule from "../../../common/RentalSchedule";
import MercadoPagoModal from "../../../common/components/mercadopago/MercadoPagoModal";
axios.interceptors.response.use(undefined, AxiosApplicationInterceptor);
const moment = extendMoment(Moment);

class VehicleScheduler extends Component {
  user_date_from = this.props.from || localStorage.getItem("user_date_from");
  user_date_until = this.props.to || localStorage.getItem("user_date_until");
  t_date_from = moment(constants.RENTAL_SEARCH_FROM);
  t_date_until = moment(constants.RENTAL_SEARCH_UNTIL);
  user_date_from = this.user_date_from ? moment(this.user_date_from) : moment();
  user_date_until = this.user_date_until
    ? moment(this.user_date_until)
    : moment();
  state = {
    from:
      this.t_date_from.valueOf() > this.user_date_from.valueOf()
        ? this.t_date_from.format()
        : this.user_date_from.format(),
    to:
      this.t_date_until.valueOf() > this.user_date_until.valueOf()
        ? this.t_date_until.format()
        : this.user_date_until.format(),
    error: false,
    success: false,
    has_quoted: false,
    new_from: "",
    new_to: "",
    button_state: "not_quoted", // "quoted_and_not_registered", "quoted_and_registered",
    pricing: {
      total: 0,
      details: [],
      extra_charges: [],
      commission: 0,
      insurance_charge: 0,
      payment_method_fee: 0,
      value_added_tax: 0,
      pick_up_charge: null,
      drop_off_charge: null,
      clf_value: 0.0,
      owner_total: 0.0,
      discount: 0.0
    },
    payment: {
      oneclick: {
        register_init: false,
        register_finish: false,
        tbk_user: false,
        token: "",
        url: ""
      }
    },
    selected_dropoff: undefined,
    selected_pickup: undefined,
    ui: {
      isSubmitting: false,
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined,
      submittingMessage: ""
    },
    // modal_body: ""
  };

  transbankWindow = null;

  componentDidMount() {
    if (
      this.props.rental.id &&
      this.state.from &&
      this.state.to &&
      !this.state.has_quoted
    ) {
      const location = this.props.rental.location;
      if (location && location.name) {
        this.setState({
          selected_dropoff: { label: location.name, value: location.name },
          selected_pickup: { label: location.name, value: location.name }
        }, () => this.calculate_price());
      }
    }
    const options = {
      showDropdowns: true,
      maxYear: constants.MAX_YEAR,
      timePicker: true,
      minDate: new Date(),
      timePicker24Hour: true,
      timePickerIncrement: 15,
      separator: " \n ",
      locale: {
        separator: " hasta ",
        format: "LLL",
        cancelLabel: "Cancelar",
        applyLabel: "Aplicar",
        fromLabel: "Desde",
        toLabel: "Hasta",
        daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre"
        ],
        firstDay: 1
      }
    };

    $("#fechas").daterangepicker(options, (start, end) => {
      let from = moment(start).format();
      let to = moment(end).format();
      localStorage.setItem("user_date_from", from);
      localStorage.setItem("user_date_until", to);
      let has_quoted = false;
      let error = false;
      let success = false;
      this.setState(state => {
        state.ui.showMessage = false;
        return { from, to, has_quoted, error, success, ui: state.ui };
      }, () => this.calculate_price());
    });

    this.disableDates();
  }

  checkOneClickWindowClosed = () => {
    const oneClickInterval = setInterval(() => {
      if (this.transbankWindow && this.transbankWindow.closed) {
        window.focus();
        this.updateUser();
        clearInterval(oneClickInterval);
      }
    }, 500)
  }

  updateUser = () => {
    axios({
      method: "GET",
      url: `/owners/current.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json"
      },
    })
      .then(response => {
        if (response.data) {
          this.setState({
            payment: {
              oneclick: {
                register_init: false,
                register_finish: false,
                tbk_user: false,
                token: "",
                url: ""
              }
            },
          }, () => this.props.reloadUser(response.data));
        } else {
          this.showMessage({
            showMessage: true,
            messageType: "alert-danger",
            messages: ["Ha ocurrido un error inesperado"],
            messageTitle: "Error"
          });
        }
      })
      .catch(error => {
        this.showMessage({
          showMessage: true,
          messageType: "alert-danger",
          messages: ["Ha ocurrido un error inesperado"],
          messageTitle: "Error"
        });
      })
      .finally(() => {
        this.setState(state => {
          state.ui.isSubmitting = false;
          state.ui.submittingMessage = "";
          return { ui: state.ui };
        });
      });
  }

  formOneClickSubmit = e => {
    this.setState(state => {
      state.ui.isSubmitting = true;
      state.ui.submittingMessage = "Esperando a Transbank...";
      return { ui: state.ui }
    });
    this.transbankWindow = window.open("", "transbankWindow");
    this.checkOneClickWindowClosed();
  }

  reloadUser = () => {
    this.setState(state => {
      state.ui.isSubmitting = true;
      state.ui.submittingMessage = "Esperando a MercadoPago";
      return { ui: state.ui }
    });
    this.updateUser();
  }

  disableDates = () => {
    const user_date_from = this.props.from || localStorage.getItem("user_date_from");
    const user_date_until = this.props.to || localStorage.getItem("user_date_until");

    let t_date_from = moment(user_date_from || constants.RENTAL_SEARCH_FROM);
    let t_date_until = moment(user_date_until || constants.RENTAL_SEARCH_UNTIL);

    let initialDate = t_date_from.format();
    let endDate = t_date_until.format();
    let oneclick = { ...this.state.payment.oneclick };
    oneclick.tbk_user =
      !!this.props.user.oneclick && this.props.user.oneclick.tbk_user;
    const blockedRanges = this.props.blockedRanges || [];
    this.setState({ payment: { oneclick: oneclick } });
    this.setState(
      state => {
        state.from = initialDate;
        state.to =
          moment(endDate).valueOf() > moment(initialDate).valueOf()
            ? endDate
            : moment(initialDate).format();
        return { from: state.from, to: state.to };
      },
      () => {
        const el = $("#fechas").data("daterangepicker");
        el.setStartDate(moment(initialDate));
        el.setEndDate(moment(endDate));
        el.minDate = moment();
        el.maxDate = moment(this.props.rental.date_until);
        el.isInvalidDate = date => {
          let disable = false;
          if (Array.isArray(blockedRanges) && blockedRanges.length > 0) {
            for (let event of blockedRanges) {
              disable = false;
              // Se considera que el día en el que inicia y finaliza el evento se libera por completo
              const event_min_value = moment(event.from).endOf("d").valueOf();
              const event_max_value = moment(event.until).startOf("d").valueOf();

              const date_value = moment(date).valueOf();
              if (date_value > event_min_value && date_value < event_max_value) {
                disable = true;
                break;
              }
            }
          }
          return disable;
        };
      }
    );
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.rental != prevProps.rental ||
      this.props.user != prevProps.user
    ) {
      this.disableDates();
    }

    if (this.props.redoQuote) {
      this.setState(
        state => {
          state.ui.showMessage = false;
          return {
            has_quoted: false,
            ui: state.ui,
            error: false,
            success: false
          };
        },
        () => {
          this.props.reportRedo();
          this.calculate_price();
        }
      );
    }
  }

  changeFecha = fecha => {
    this.setState({ fecha });
  };

  showMessage = msg => {
    this.setState(state => {
      state.ui.showMessage = msg.showMessage;
      state.ui.messageType = msg.messageType;
      state.ui.messages = msg.messages;
      state.ui.messageTitle = msg.messageTitle;
      return { ui: state.ui };
    });
  };
  oneclick_register_init = e => {
    this.setState(state => {
      state.ui.isSubmitting = true;
      return { ui: state.ui };
    });
    e.preventDefault();
    axios({
      method: "POST",
      url: `/bookings/oneclick_register_init.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        rental_id: this.props.rental.id,
        from: this.state.from,
        to: this.state.to
      }
    })
      .then(response => {
        if (response.data.success) {
          let oneclick = { ...this.state.payment.oneclick };
          oneclick.register_init = true;
          oneclick.token = response.data.tbk_response.token;
          oneclick.url = response.data.tbk_response.url;
          this.setState({ error: false, payment: { oneclick: oneclick } });
        } else {
          this.showMessage({
            showMessage: true,
            messageType: "alert-warning",
            messages: response.data.messages || [
              "Ha ocurrido un error inesperado"
            ],
            messageTitle: "Alerta"
          });
        }
      })
      .catch(error => {
        this.showMessage({
          showMessage: true,
          messageType: "alert-danger",
          messages: ["Ha ocurrido un error inesperado"],
          messageTitle: "Error"
        });
      })
      .finally(() => {
        this.setState(state => {
          state.ui.isSubmitting = false;
          return { ui: state.ui };
        });
      });
  };

  calculate_price = e => {
    this.setState(state => {
      state.ui.isSubmitting = true;
      state.ui.showMessage = false;
      state.ui.messageType = undefined;
      state.ui.messages = [];
      state.ui.messageTitle = undefined;
      return { ui: state.ui };
    });
    if (e) e.preventDefault();
    const { selected_dropoff, selected_pickup } = this.state;
    const rental = this.props.rental;
    const moment_from = moment(this.state.from);
    const moment_to = moment(this.state.to);
    const extras_ids = this.props.extrasIds || [];
    const duration = moment.duration(moment_to.diff(moment_from)).asDays();
    const new_from = moment_from.format();
    const new_to = moment_to.format();
    let rental_pricing = {
      rental_id: rental.id,
      start: moment_from.format(),
      end: moment_to.format(),
      extras_ids
    };
    if (!!selected_pickup && selected_pickup.value != rental.location.name) {
      rental_pricing.special_pickup = selected_pickup.value;
    }
    if (!!selected_dropoff && selected_dropoff.value != rental.location.name) {
      rental_pricing.special_dropoff = selected_dropoff.value;
    }
    axios({
      method: "POST",
      url: `/rentals/${this.props.rental.id}/calculate_pricing.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json"
      },
      data: { rental_pricing }
    })
      .then(response => {
        if (response.data.success) {
          const pricing = response.data.pricing;
          this.setState({
            has_quoted: true,
            pricing,
            error: false,
            new_from,
            new_to
          });
        } else {
          this.showMessage({
            showMessage: true,
            messageType: "alert-warning",
            messages: response.data.messages || [
              "Ha ocurrido un error inesperado"
            ],
            messageTitle: "Alerta"
          });
        }
      })
      .catch(error => {
        this.showMessage({
          showMessage: true,
          messageType: "alert-danger",
          messages: ["Ha ocurrido un error inesperado"],
          messageTitle: "Error"
        });
      })
      .finally(() => {
        this.setState(state => {
          state.ui.isSubmitting = false;
          return { ui: state.ui };
        });
      });
  };
  book_rental = e => {
    e.preventDefault();
    // this.setState(state => {
    //   state.ui.isSubmitting = true;
    //   return { ui: state.ui };
    // });
    // const { rental, user } = this.props;
    // const total_price = this.state.pricing.total;
    // const { from, to } = this.state;
    // axios({
    //   method: "get",
    //   url: `/legal/sublease_agreement?rental_id=${encodeURIComponent(
    //     rental.id
    //   )}&user_id=${encodeURIComponent(
    //     user.id
    //   )}&total_price=${encodeURIComponent(
    //     total_price
    //   )}&from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}&nolayout`
    // })
    //   .then(response => {
    //     const modal_body = response.data;
    //     this.setState({ modal_body });
    //   })
    //   .catch(error => {
    //     this.showMessage({
    //       showMessage: true,
    //       messageType: "alert-danger",
    //       messages: ["Ha ocurrido un error inesperado"],
    //       messageTitle: "Error"
    //     });
    //   })
    //   .finally(() => {
    //     this.setState(
    //       state => {
    //         state.ui.isSubmitting = false;
    //         return { ui: state.ui };
    //       },
    //       () => {
    //         $("#modal-sublease-agreement").modal("show");
    //       }
    //     );
    //   });
    $("#modal-sublease-agreement").modal("show");
  };

  createBooking = () => {
    this.setState(state => {
      state.ui.isSubmitting = true;
      state.ui.showMessage = false;
      state.ui.messageType = undefined;
      state.ui.messages = [];
      state.ui.messageTitle = undefined;
      return { ui: state.ui };
    });
    const { selected_dropoff, selected_pickup } = this.state;
    const rental = this.props.rental;
    const moment_from = moment(this.state.from);
    const moment_to = moment(this.state.to);
    const extras_ids = this.props.extrasIds || [];
    const duration = moment.duration(moment_to.diff(moment_from)).asDays();
    let booking = {
      date_from: moment_from.format(),
      date_until: moment_to.format(),
      vehicle_id: this.props.rental.vehicle.id,
      rental_id: this.props.rental.id,
      extras_ids
    };
    if (!!selected_pickup && selected_pickup.value != rental.location.name) {
      booking.special_pickup = selected_pickup.value;
    }
    if (!!selected_dropoff && selected_dropoff.value != rental.location.name) {
      booking.special_dropoff = selected_dropoff.value;
    }
    const booking_params = queryString.stringify(booking);
    axios({
      method: "POST",
      url: `/bookings.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json"
      },
      data: { booking }
    })
      .then(response => {
        if (response.data.success) {
          this.setState({ success: true, error: false });
        } else {
          this.showMessage({
            showMessage: true,
            messageType: "alert-warning",
            messages: response.data.messages || [
              "Ha ocurrido un error inesperado"
            ],
            messageTitle: "Alerta"
          });
        }
      })
      .catch(error => {
        this.showMessage({
          showMessage: true,
          messageType: "alert-danger",
          messages: ["Ha ocurrido un error inesperado"],
          messageTitle: "Error"
        });
      })
      .finally(() => {
        this.setState(state => {
          state.ui.isSubmitting = false;
          return { ui: state.ui };
        });
      });
  };

  changeDropOff = selected_dropoff => {
    const has_quoted = false;
    const error = false;
    const success = false;
    this.setState({ selected_dropoff, has_quoted, error, success }, () => this.calculate_price());
  };

  changePickUp = selected_pickup => {
    const has_quoted = false;
    const error = false;
    const success = false;
    this.setState({ selected_pickup, has_quoted, error, success }, () => this.calculate_price());
  };

  render() {
    moment.locale("es");
    let fecha_input =
      "Inicio: " +
      moment(this.state.from).format("LLL") +
      " \nDevolución: " +
      moment(this.state.to).format("LLL");
    const class_btn = !this.state.has_quoted
      ? "btn-appcar-light"
      : this.state.ui.isSubmitting
        ? "btn-light"
        : this.state.success
          ? "btn-success"
          : "btn-appcar-primary";
    const text_btn = !this.state.has_quoted
      ? "ACTUALIZAR PRECIO"
      : this.state.ui.isSubmitting
        ? "RESERVANDO..."
        : this.state.success
          ? "RESERVA EXITOSA"
          : "RESERVAR";
    let btn_disabled =
      this.state.ui.isSubmitting || (this.state.success && !this.state.error)
        ? true
        : false;
    let alert_overlap = null;
    // [MA1-79] Ticket 4644: Arriendo Activo, no permite editar
    // if (
    //   moment(this.props.dateFrom).valueOf() >
    //   moment(this.state.from).valueOf() ||
    //   moment(this.props.dateUntil).valueOf() < moment(this.state.to).valueOf()
    // ) {
    //   btn_disabled = true;
    //   alert_overlap = "Las fechas deben estar en el rango";
    // }
    const errors_list = this.state.ui.messages.map((msg, i) => (
      <li key={i} className="invalid-feedback" style={{ display: "list-item" }}>
        {msg}
      </li>
    ));
    let error_booking = this.state.ui.showMessage ? (
      <ul className="list-unstyled">{errors_list}</ul>
    ) : null;
    const moment_from = moment(this.state.from);
    const moment_to = moment(this.state.to);
    const duration = moment.duration(moment_to.diff(moment_from)).asDays();
    let oneclick_is_user_registered = false;
    let message_text = "Cargo aún no realizado";

    let rental_action = null;
    const pricingDetails = (
      <PricingDetails
        price={this.state.pricing}
        dateFrom={this.state.new_from}
        dateUntil={this.state.new_to}
      />
    );

    const mpModal = this.props.user && this.props.user.id ? (
      <MercadoPagoModal
        user={this.props.user}
        mpPublicKey={this.props.mpPublicKey}
        actionUrl={`/owners/${this.props.user.id}/create_mercadopago`}
        type="func"
        callback={() => this.reloadUser()}
      />
    ) : null;

    // si no ha simulado
    if (!this.state.has_quoted) {
      rental_action = (
        <button
          className={`btn ${class_btn} btn-lg fill`}
          disabled={btn_disabled}
          onClick={e => this.calculate_price(e)}
        >
          ACTUALIZAR PRECIO
        </button>
      );
    }
    // ha simulado pero no esta registrado
    if (this.state.has_quoted && isEmpty(this.props.user)) {
      message_text = "Debes registrarte para poder reservar";
      rental_action = (
        <button
          className={`btn ${class_btn} btn-lg fill`}
          disabled={true}
          onClick={e => this.calculate_price(e)}
        >
          RESERVAR
        </button>
      );
    }
    // ha simulado, esta registrado pero no esta en transbank
    if (false) {
      // No hay transbank por ahora
      if (
        this.state.has_quoted &&
        !isEmpty(this.props.user) &&
        isEmpty(this.props.user.oneclick)
      ) {
        let message_text = "";
        rental_action = (
          <button
            className={`btn ${class_btn} btn-lg fill`}
            // disabled={true}
            onClick={e => this.oneclick_register_init(e)}
          >
            Debes registrarte en Transbank
            <br />
            OneClick para poder reservar
          </button>
        );
      }
    }
    // ha simulado, está registrado, pero no está en mercadopago, o está pero no tiene tarjeta
    if (this.state.has_quoted && !isEmpty(this.props.user) && (isEmpty(this.props.user.mercadopago) || this.props.user.mercadopago.cards.length == 0)) {
      let message_text = "";
      rental_action = (
        <button
          className={`btn ${class_btn} btn-lg fill`}
          onClick={() => $("#modal-add-mercadopago").modal("show")}
        >
          Debes registrar una tarjeta
          <br />
          para poder reservar
        </button>
      );
    }
    // ha simulado, esta registrado, tiene token pero no tiene usuario tbk
    if (false) {
      // Tbk desactivado
      if (
        this.state.has_quoted &&
        !isEmpty(this.props.user) &&
        this.state.payment.oneclick.register_init &&
        !this.state.payment.oneclick.tbk_user
      ) {
        rental_action = (
          <form action={this.state.payment.oneclick.url} method="post" target="transbankWindow" id="formOneClick" onSubmit={this.formOneClickSubmit}>
            <input
              type="hidden"
              name="TBK_TOKEN"
              value={this.state.payment.oneclick.token}
            />
            <input
              type="submit"
              value="Continuar a Transbank >>"
              className={`btn ${class_btn} btn-lg fill`}
            />
          </form>
        );
      }
    }
    // ha simulado y esta registrado
    if (
      this.state.has_quoted &&
      !isEmpty(this.props.user) &&
      // !isEmpty(this.props.user.oneclick) && // tbk desactivado
      // !isEmpty(this.props.user.oneclick.tbk_user) // tbk desactivado
      !isEmpty(this.props.user.mercadopago) &&
      this.props.user.mercadopago.cards.length > 0
    ) {
      rental_action = (
        <button
          className={`btn ${class_btn} btn-lg fill`}
          disabled={false}
          onClick={e => this.book_rental(e)}
        >
          RESERVAR
        </button>
      );
    }
    // ha simulado y reservó
    if (this.state.success) {
      message_text = "Reserva realizada exitosamente";
      rental_action = (
        <button
          className={`btn ${class_btn} btn-lg fill`}
          disabled={true}
          onClick={e => this.book_rental(e)}
        >
          RESERVADO
        </button>
      );
    }
    const rental = this.props.rental;
    const cancellation_policy = rental.vehicle.cancellation_policy;
    const fuel_policy = rental.vehicle.fuel_policy;
    const user_ = this.props.user;
    const extras = this.props.extras
      .filter(extra => this.props.extrasIds.includes(extra.vehicle_extra.id))
      .map(x => x.vehicle_extra.a_name);
    const { selected_dropoff, selected_pickup, pricing } = this.state;
    const { total, insurance_charge, clf_value, owner_total, discount } = pricing;
    const modalBody = subleaseAgreementText(
      null,
      rental.id,
      user_ && user_.id,
      moment_from.format(),
      moment_to.format(),
      total,
      selected_pickup && selected_pickup.value,
      selected_dropoff && selected_dropoff.value,
      insurance_charge,
      clf_value,
      owner_total,
      discount,
      cancellation_policy && cancellation_policy.name || "EL VEHÍCULO NO TIENE POLÍTICAS DE CANCELACIÓN",
      fuel_policy && fuel_policy.name || "EL VEHÍCULO NO TIENE POLÍTICAS DE USO DE COMBUSTIBLES",
      extras
    );

    const modalSubleaseAgreement = (
      <LegalModal
        action={this.createBooking}
        title="Lee atentamente antes de continuar"
        checkboxLabel="Estoy de acuerdo con los términos y condiciones."
        checkboxError="Debes aceptar los términos y condiciones"
        btnText="Continuar"
        body={modalBody}
        elementId="modal-sublease-agreement"
        secondCheckboxLabel="Estoy de acuerdo con el tratamiento de mis datos personales como se describe en la “Política de Privacidad II”."
        secondCheckboxError="Debes aceptar la Política de Privacidad II para continuar"
        refId=""
        totalPrice={this.state.pricing && this.state.pricing.total}
        isSubleaseAgreement={true}
      />
    );

    const extra_locations = rental.pricing.extra_locations || [];
    let location_options = extra_locations.map(x => ({
      label: x.location.name,
      value: x.location.name
    }));
    let location_name = rental.location && rental.location.name;
    location_options.unshift({ label: location_name, value: location_name });
    const location_container =
      extra_locations.length > 0 ? (
        <div className="row">
          <div className="col-lg-12">
            <div className="form-group">
              <label>Seleccionar punto de recogida</label>
              <Select
                value={this.state.selected_pickup}
                options={location_options}
                placeholder="Selecciona..."
                noOptionsMessage={() => "Error..."}
                onChange={this.changePickUp}
                classNamePrefix="RS-FIX"
                className="react-select-fix"
              />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <label>Seleccionar punto de devolución</label>
              <Select
                value={this.state.selected_dropoff}
                options={location_options}
                placeholder="Selecciona..."
                noOptionsMessage={() => "Error..."}
                onChange={this.changeDropOff}
                classNamePrefix="RS-FIX"
                className="react-select-fix"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-lg-12">
            <div className="form-group">
              <label>Punto de recogida</label>
              <input
                type="text"
                readOnly
                value={rental.location && rental.location.name}
                onChange={() => false}
                className="form-control disabled"
                disabled
              />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <label>Punto de devolución</label>
              <input
                type="text"
                readOnly
                value={rental.location && rental.location.name}
                onChange={() => false}
                className="form-control appcar-input"
                disabled
              />
            </div>
          </div>
        </div>
      );
    const rentalHours = <RentalSchedule rental={rental} />;
    return (
      <div className="vehicle-scheduler">
        <Spinner loading={this.state.ui.isSubmitting} message={this.state.ui.submittingMessage} />
        <h4 className="mt-3">Solicitar arriendo</h4>
        {location_container}
        {rentalHours}
        <div className="row">
          <div className="col-lg-12">
            <div className="form-group">
              <label htmlFor="fechas">Rango de fechas del arriendo</label>
              <table
                id="fechas"
                style={{ width: "100%", fontWeight: "bolder" }}
              >
                <tbody>
                  <tr>
                    <td style={{ color: "#203453" }}>INICIO</td>
                    <td>:</td>
                    <td className="text-warning fecha_mano">{moment(this.state.from).format("LLL")}</td>
                    <td rowSpan="2"><i className="font-18 mdi mdi-calendar-edit mr-2 text-warning fecha_mano"></i></td>
                  </tr>
                  <tr>
                    <td style={{ color: "#203453" }}>DEVOLUCIÓN</td>
                    <td>:</td>
                    <td className="text-warning fecha_mano">{moment(this.state.to).format("LLL")}</td>
                  </tr>
                </tbody>
              </table>
              <InputFeedback error={alert_overlap} />
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            {rental_action}
            {error_booking}
            <div className="charge-text">{message_text}</div>
          </div>
        </div>
        {pricingDetails}
        <Policies
          fuelPolicy={fuel_policy}
          cancellationPolicy={cancellation_policy}
          isOwner={false}
          isDriver={true}
        />
        {modalSubleaseAgreement}
        {mpModal}
      </div>
    );
  }
}

export default VehicleScheduler;
