import React, { Component, Fragment } from "react";
import Spinner from "../../common/Spinner";
import axios from "axios";
import isObject from "lodash/isObject";
import { DatePicker } from "../../common/CustomInputs";
import moment from "moment";
import { constants, numeral } from "../../common/constants";
import { AxiosBackofficeInterceptor } from "../../common/axios_interceptor";
import Alert from "../../common/components/alert/Alert";
import Table from "../../common/components/table/Table";
moment.locale("es");

class EnsuranceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      booking: {},
      form_data: {
        from: this.props.from
          ? moment(this.props.from).format()
          : constants.RENTAL_SEARCH_FROM,
        until: this.props.until
          ? moment(this.props.until).format()
          : constants.RENTAL_SEARCH_UNTIL,
        plate_number: "",
      },
      bookings: [],
      isSubmitting: false,
      alert: {
        showMessage: false,
        messageType: undefined,
        messages: [],
        messageTitle: undefined,
      },
      search_info: {
        has_searched: false,
        from: "",
        until: "",
        plate: "",
      },
      orderStatus: {
        name: "id",
        direction: "desc",
      },
      paginationInformation: {
        limit: constants.DEFAULT_RESULTS_PER_PAGE,
        last: null,
        total: 0,
      },
      currentPage: 1,
      lastIds: [],
    };
    this.willUnmount = false;
    this.cancelToken = null;
  }

  componentWillUnmount() {
    this.willUnmount = true;
  }

  componentDidMount() {
    axios.interceptors.response.use(undefined, AxiosBackofficeInterceptor);
    const this_ = this;
    this_.willUnmount = false;
    this.cancelToken = axios.CancelToken.source();
    const options = {
      showDropdowns: true,
      maxYear: constants.MAX_YEAR,
      minDate: new Date(2018, 0, 1),
      autoUpdateInput: false,
      locale: {
        format: "DD/MM/YYYY",
        cancelLabel: "Limpiar",
        applyLabel: "Aplicar",
        fromLabel: "Desde",
        toLabel: "Hasta",
        daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        firstDay: 1,
      },
      startDate: moment(constants.RENTAL_SEARCH_FROM).toDate(),
      endDate: moment(constants.RENTAL_SEARCH_UNTIL).toDate(),
    };

    $("#search_dates").on("cancel.daterangepicker", function(ev, picker) {
      picker.setStartDate(moment());
      picker.setEndDate(moment());
      this_.setState((state) => {
        state.form_data.from = null;
        state.form_data.until = null;
        return { form_data: state.form_data };
      });
    });

    $("#search_dates").daterangepicker(options, (start, end) => {
      let from = moment(start)
        .startOf("day")
        .format();
      let to = moment(end)
        .endOf("day")
        .format();
      this.setState((state) => {
        state.alert.showMessage = false;
        state.form_data.from = from;
        state.form_data.until = to;
        return { form_data: state.form_data, alert: state.alert };
      });
    });
  }

  showMessage = (messageType, messageTitle, messages) =>
    this.setState(
      (state) => {
        state.alert.showMessage = true;
        state.alert.messageTitle = messageTitle;
        state.alert.messages = messages;
        state.alert.messageType = messageType;
        return { alert: state.alert };
      },
      () => setTimeout(this.handleAlertClose, 3000)
    );

  handleAlertClose = () =>
    this.setState((state) => {
      state.alert.showMessage = false;
      return { alert: state.alert };
    });

  handleSubmit = (e = null, direction = "after", resetPagination = false) => {
    if (e) e.preventDefault();
    this.setState({ isSubmitting: true });
    const { form_data, paginationInformation } = this.state;
    const search_info = {
      has_searched: true,
      from: moment(form_data.from).format("DD/MM/YYYY"),
      until: moment(form_data.until).format("DD/MM/YYYY"),
      plate: form_data.plate_number,
    };
    const from = moment(form_data.from);
    const until = moment(form_data.until);
    let fromParam = "";
    let untilParam = "";
    let last = paginationInformation.last;
    let lastIds = this.state.lastIds;
    if (direction == "before") {
      lastIds.pop();
      lastIds.pop();
      last = lastIds.slice(this.state.lastIds.length - 1);
    }
    if (resetPagination) {
      lastIds = [];
      last = 0;
    }
    let paginationParams = `limit=${paginationInformation.limit}`;
    if (!resetPagination) paginationParams += `&last=${last}`;
    if (from.isValid()) {
      search_info.from = from.format("DD/MM/YYYY");
      fromParam = `&from=${encodeURIComponent(
        from.startOf("d").format("YYYY-MM-DDTHH:mm:ssZ")
      )}`;
    } else {
      search_info.from = "";
    }
    if (until.isValid()) {
      search_info.until = until.format("DD/MM/YYYY");
      untilParam = `&until=${encodeURIComponent(
        until.endOf("d").format("YYYY-MM-DDTHH:mm:ssZ")
      )}`;
    } else {
      search_info.until = "";
    }
    const plateNumberParams = form_data.plate_number
      ? `&plate_number=${form_data.plate_number}`
      : "";
    axios({
      method: "get",
      url: `/backoffice/ensurance/search_bookings.json?${paginationParams}${fromParam}${untilParam}${plateNumberParams}`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        const bookings = response.data.bookings;
        const paginationInformation = response.data.paginationInformation;
        lastIds.push(paginationInformation.last);

        if (!this.willUnmount)
          this.setState({
            bookings,
            search_info,
            paginationInformation,
            lastIds,
          });
      })
      .catch((error) => {
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ]);
      })
      .then(() => {
        this.setState({ isSubmitting: false });
      });
  };

  handleSelectBooking = (booking) => {
    this.setState({ booking }, () => {
      $("#modal-ensurance-images").modal("show");
    });
  };

  handleChangePlateNumber = (plate_number) => {
    this.setState((state) => {
      state.form_data.plate_number = plate_number;
      return { form_data: state.form_data };
    });
  };

  onLimitChange = (limit) => {
    this.setState(
      (state) => {
        state.paginationInformation.limit = limit;
        return {
          paginationInformation: state.paginationInformation,
          currentPage: 1,
        };
      },
      () => {
        this.handleSubmit(null, "last", true);
      }
    );
  };

  handleDownload = (url) => {
    this.setState({ isSubmitting: true });
    const filename = url.substring(url.lastIndexOf("/") + 1);
    let a = document.createElement("a");
    fetch(url)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        a.style.display = "none";
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert("oh no!"))
      .finally(() => {
        a.parentNode.removeChild(a);
        this.setState({ isSubmitting: false });
      });
  };

  onPageChange = (currentPage, direction) => {
    this.setState({ currentPage }, () => {
      this.handleSubmit(null, direction);
    });
  };

  onOrderChange = (orderStatus) => {
    this.setState({ orderStatus }, () => {
      // TODO: Acá se debe llamar la función para buscar resultados
    });
  };

  render() {
    const {
      bookings,
      form_data,
      alert,
      search_info,
      orderStatus,
      paginationInformation,
      currentPage,
    } = this.state;
    const { messageTitle, messageType, messages, showMessage } = alert;

    const empty_message = (is_submitting) => (
      <tr>
        <td colSpan="15" className="text-center">
          {is_submitting
            ? "Realizando búsqueda..."
            : "No hay nada para mostrar..."}
        </td>
      </tr>
    );

    const rows = bookings.sort((a, b) => {
      // Ordenamiento por id
      const aId = parseInt(a.id);
      const bId = parseInt(b.id);

      // Mayor a menor (Más futuro a más pasado)
      return bId - aId;
    });

    const { last, limit, total } = paginationInformation;

    const showingText = `Mostrando ${rows.length} resultados de un total de ${total}`;
    let plateNumberText = "";
    let datesText = "";
    if (search_info.plate) {
      plateNumberText = `, de patente ${search_info.plate}`;
    }
    if (search_info.from) {
      datesText = `, entre fechas ${search_info.from} y ${search_info.until}`;
    }

    const searchInfo = search_info.has_searched ? (
      <div className="alert light mw-100">
        {showingText}
        {plateNumberText}
        {datesText}
      </div>
    ) : null;

    let inputDatesText = "";
    const from = moment(form_data.from);
    const until = moment(form_data.until);
    if (from.isValid()) {
      inputDatesText = from.format("LL") + " - " + until.format("LL");
    }

    const columnDefinitions = [
      {
        name: "id",
        label: "ID",
        orderable: false,
      },
      {
        name: "driver",
        label: "Driver",
        orderable: false,
      },
      {
        name: "owner",
        label: "Owner",
        orderable: false,
      },
      {
        name: "vehicle",
        label: "Vehículo",
        orderable: false,
      },
      {
        name: "plate_number",
        label: "Patente",
        orderable: false,
      },
      {
        name: "appraisal",
        label: "Avalúo",
        orderable: false,
      },
      {
        name: "market_value",
        label: "Valor comercial",
        orderable: false,
      },
      {
        name: "dates",
        label: "Duración de arriendo",
        orderable: false,
      },
      {
        name: "has_images",
        label: "Tiene imágenes",
        orderable: false,
      },
      {
        name: "actions",
        label: "Acciones",
        orderable: false,
      },
    ];
    const data = rows.map((bkng) => {
      const driver = bkng.user[0];
      const vehicle = bkng.vehicle;
      const owner = vehicle.associated_users[0];
      let has_images = false;
      if (isObject(bkng.insurance_pictures)) {
        for (let pics in bkng.insurance_pictures) {
          if (
            Array.isArray(bkng.insurance_pictures[pics]) &&
            bkng.insurance_pictures[pics].length > 0
          ) {
            has_images = true;
            break;
          }
        }
      }
      const image_icon = (
        <i
          className={
            has_images
              ? "text-center text-success mdi mdi-checkbox-marked-circle-outline"
              : "text-center text-danger mdi mdi-checkbox-blank-circle-outline"
          }
          style={{ fontSize: "1.375rem", fontWeight: "900" }}
        />
      );
      return {
        id: bkng.id,
        driver: driver.name + " " + driver.last_name,
        owner: owner.name + " " + owner.last_name,
        vehicle:
          vehicle.vehicle_type.brand +
          " " +
          vehicle.vehicle_type.model +
          " " +
          vehicle.year,
        plate_number: vehicle.plate_number,
        appraisal: numeral(vehicle.appraisal || 0).format("$0,0"),
        market_value: numeral(vehicle.market_value || 0).format("$0,0"),
        dates:
          moment(bkng.date_from).format("DD/MM/Y HH:mm") +
          " - " +
          moment(bkng.date_until).format("DD/MM/Y HH:mm"),
        has_images: image_icon,
        actions: (
          <a
            href={`/backoffice/ensurance/${bkng.id}`}
            className="btn btn-appcar-primary btn-sm"
            rel="noopener noreferrer"
            target="_blank"
          >
            Detalle
          </a>
        ),
      };
    });

    const table = (
      <Table
        id="table-ensurance"
        noResults={
          this.state.isSubmitting
            ? "Realizando Búsqueda"
            : "No hay resultados para mostrar..."
        }
        columns={columnDefinitions}
        data={data}
        hasFooter={true}
        tableClass="table dataTable table-striped"
        searchable={true}
        onSearchChange={this.handleChangePlateNumber}
        orderStatus={orderStatus}
        paginationInformation={paginationInformation}
        onLimitChange={this.onLimitChange}
        onPageChange={this.onPageChange}
        onOrderChange={this.onOrderChange}
        currentPage={currentPage}
        showPageSelector={false}
      />
    );

    return (
      <Fragment>
        <Spinner loading={this.state.isSubmitting} />
        <Alert
          alertClass={messageType}
          isShowing={showMessage}
          messages={messages}
          title={messageTitle}
          onClose={this.handleAlertClose}
        />
        <h4>Buscar reservas</h4>
        <div className="row my-3 align-items-end">
          <div className="col">
            <DatePicker
              id="search_dates"
              label="Selecciona rango de fechas"
              onChange={(e) => this.changeFecha(e.target.value)}
              name="date_from"
              disabled={true}
              classNameInput="form-control appcar-input"
              value={inputDatesText}
            />
          </div>
          <div className="col">
            <div className="form-group">
              <label htmlFor="plate_number">Patente</label>
              <input
                id="plate_number"
                name="form_data[plate_number]"
                onChange={(e) => this.handleChangePlateNumber(e.target.value)}
                className="form-control appcar-input"
              />
            </div>
          </div>

          <div className="col-auto pl-md-0 pr-1">
            <button
              className="btn btn-appcar-primary btn-sm py-0 mb-1"
              onClick={this.handleSubmit}
            >
              <i className="mdi mdi-chevron-right fs-22" />
            </button>
          </div>
        </div>
        {searchInfo}
        <div className="mb-3">{table}</div>
      </Fragment>
    );
  }
}

export default EnsuranceForm;
