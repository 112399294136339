import React, { Component, Fragment } from "react";
import Spinner from "../../common/Spinner";
import FlashMessage from "../../common/FlashMessage";
import axios from "axios";
import * as _ from "lodash";
import UserSearch from "../user_search/UserSearch";
import Select from "react-select";
import { AxiosBackofficeInterceptor } from "../../common/axios_interceptor";
axios.interceptors.response.use(undefined, AxiosBackofficeInterceptor);

class TransferData extends Component {
  state = {
    selectedUser: undefined,
    ui: {
      isSubmitting: false,
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined
    }
  };

  showMessage = msg => {
    this.setState(state => {
      state.ui.showMessage = msg.showMessage;
      state.ui.messageType = msg.messageType;
      state.ui.messages = msg.messages;
      state.ui.messageTitle = msg.messageTitle;
      return { ui: state.ui };
    });
  };

  selectUser = selectedUser => {
    this.setState({ selectedUser });
  };

  render() {
    const flash_message = this.state.ui.showMessage ? (
      <FlashMessage
        type={this.state.ui.messageType}
        msg={this.state.ui.messages}
        title={this.state.ui.messageTitle}
      />
    ) : null;

    const user = this.state.selectedUser;
    const userId = user && user.id || "";
    return (
      <Fragment>
        {flash_message}
        <Spinner loading={this.state.ui.isSubmitting} />
        <UserSearch
          user={user}
          selectUser={this.selectUser}
          hasLabel={false}
          colClass="col-lg-12"
        />
        <input
          type="hidden"
          name="new_owner_id"
          value={userId}
        />
      </Fragment>
    );
  }
}

export default TransferData;
