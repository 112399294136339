import React from "react";
import iconAlert from "images/Icons/Alert.png";

const PriceAlert = ({ message }) => (
  <div className="alert primary price-alert">
    <img src={iconAlert} />
    <div className="desc">{message}</div>
  </div>
);

export default PriceAlert;
