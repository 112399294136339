import React from "react";
import UserForm from "../UserForm";
import moment from "moment";

const PersonalData = ({ user, genders, documentTypes, countries, r_to }) => {
  const birthDate = moment(user.birth_date, "YYYY-MM-DD");
  const fixedBirthDate = birthDate.isValid()
    ? birthDate.format("YYYY-MM-DD")
    : moment()
        .add(-18, "y")
        .format("DD/MM/YYYY");
  let fixedUser = user;
  fixedUser.birth_date = fixedBirthDate;
  return (
    <UserForm
      user={fixedUser}
      genders={genders}
      documentTypes={documentTypes}
      countries={countries}
      r_to={r_to}
      isOwner={false}
    />
  );
};
export default PersonalData;
