import React, { Fragment, useState, useEffect } from "react";
import { constants, formatedPricing } from "../../constants";
import ShareButtons from "../share/ShareButtons";
import Spec from "./Spec";
import ToolTip, { LoadToolTips } from "../ToolTip";
import Alert from "../alert/Alert";
import SuperOwnerBadge from "images/Icons/super_owner_badge.png";
import iconNoImage from "images/Icons/NoImageWhiteBG.svg";

const VehicleCard = ({
  rental,
  visitLink,
  mouseEnter,
  sharingUrl,
  linkSuffix,
  openModalPricing,
  dayOfWeek = 0,
}) => {
  const [insidePricing, changeInsidePricing] = useState(false);
  const [showShareLinks, changeShowShareLinks] = useState(false);
  const [alert, setAlert] = useState({
    showMessage: false,
    messageType: undefined,
    messages: [],
    messageTitle: undefined,
  });

  const showMessage = (messageType, messageTitle, messages) => {
    setAlert((prev) => {
      prev.showMessage = true;
      prev.messageType = messageType;
      prev.messageTitle = messageTitle;
      prev.messages = messages;
      return { ...prev };
    });
  };

  const closeAlert = () =>
    setAlert((prev) => {
      prev.showMessage = false;
      return { ...prev };
    });

  useEffect(() => {
    LoadToolTips();
    if (alert.showMessage) var timeoutAlert = setTimeout(closeAlert, 3000);
    return () => clearTimeout(timeoutAlert);
  }, [alert]);

  const vehicle = rental.vehicle;
  const rentalId = rental.parent_id || rental.id;
  const hasImages =
    Array.isArray(vehicle.vehicle_pictures) &&
    typeof vehicle.vehicle_pictures[0] == "string";
  const vehicleImage = hasImages
    ? vehicle.vehicle_pictures[0] + "/thumb"
    : iconNoImage;
  const vehicleType = vehicle.vehicle_type;
  const brand = vehicleType.brand;
  const model = vehicleType.model;
  const year = vehicle.year;
  const fuel = (vehicle.fuel_type && vehicle.fuel_type.description) || "";
  const capacity = vehicle.capacity || 0;
  const transmission = vehicle.vehicle_transmission?.description;
  const primarySpecs = (
    <Fragment>
      <Spec icon="mdi mdi-car-shift-pattern" text={transmission} />
      <Spec icon="mdi mdi-gas-station" text={fuel.split(" ")[0]} />
      <Spec
        icon="mdi mdi-account-multiple"
        text={capacity ? capacity + " Asientos" : "-"}
      />
    </Fragment>
  );
  const extras = (rental.pricing.extra_charges || []).map((x) => {
    return x.vehicle_extra.a_name;
  });
  const features = (vehicle.vehicle_features || [])
    // .filter((x) => x.id != constants.MAX_250) // TODO - JUAN: Descomentar si no eliminan el dato
    .map((f) => {
      return f.a_name;
    });
  const limited = !vehicle.unlimited_distance ? " Máx 250 Kms." : " Ilimitado";
  const all = [...extras, ...features].join(", ");
  let minLength = 0;
  if (rental.availability && rental.availability.booking_length) {
    if (dayOfWeek == 5) {
      minLength =
        rental.availability.booking_length.minimum_length_saturday || 0;
    } else if (dayOfWeek == 6) {
      minLength = rental.availability.booking_length.minimum_length_sunday || 0;
    } else {
      minLength = rental.availability.booking_length.minimum_length || 0;
    }
  }
  const minBooking =
    minLength > 0 ? (
      <span className="fs-14 fw-800 text-appcar-primary">
        <i className="mdi mdi-alert fs-18 mr-1"></i>
        Mín. {minLength} día
        {minLength > 1 ? "s" : ""}
      </span>
    ) : null;

  const superOwnerBadge = rental.super ? (
    <div className="badge-super-owner">
      <img src={SuperOwnerBadge} height={47} />
    </div>
  ) : null;

  return (
    <div
      className="vehicle-card mb-4 cursor-pointer"
      onClick={() => visitLink()}
      onMouseEnter={mouseEnter}
    >
      {superOwnerBadge}
      <Alert
        alertClass={alert.messageType}
        isShowing={alert.showMessage}
        messages={alert.messages}
        title={alert.messageTitle}
        onClose={closeAlert}
        key={"rental_" + rental.id}
      />
      <div className="vehicle-card-plate">
        <i className="mdi mdi-car"></i> ** ** {vehicle.plate_number}
      </div>
      <div className="vehicle-card-body">
        <div className={`vehicle-image ${!hasImages ? "no-image" : ""}`}>
          <img src={vehicleImage} />
        </div>
        <div
          className="share-link"
          title="Copiar enlace"
          onClick={(e) => {
            e.stopPropagation();
            navigator.clipboard.writeText(
              `${sharingUrl}rentals/${rentalId}${linkSuffix}&address=${encodeURIComponent(
                rental.current_location[0].name
              )}`
            );
            showMessage("primary", "", ["¡Vínculo copiado!"]);
          }}
        >
          <ToolTip
            title="Copiar enlace"
            tooltipHTML=""
            containerHTML={<i className="mdi mdi-share-variant"></i>}
            widthClass="slim"
            id={`rental_${rental.id}`}
            placement="left"
            colorClass="primary"
          />
        </div>
        <div className="vehicle-info">
          <div className="vehicle-info-title">
            <span className="car-brand">{brand}</span>
            <span className="car-model">
              {model} | {year}
            </span>
          </div>
          <div className="vehicle-info-specs">{primarySpecs}</div>
          <div className="vehicle-info-pricing">
            <div className="d-flex justify-content-between">
              <div>{minBooking}</div>
              <div className="fs-18 fw-900">
                {formatedPricing(rental.current_daily_price)}
                <span className="fs-14">/día</span>
              </div>
            </div>
            <div
              className="estimated-total cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                openModalPricing();
              }}
            >
              Total estimado: {formatedPricing(rental.current_price)}{" "}
              <i className="mdi mdi-information fs-16 text-appcar-primary"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleCard;
