import React, { Component, Fragment } from "react";
import Spinner from "../../common/Spinner";
import axios from "axios";
import * as _ from "lodash";
import find from "lodash/find";
import UserSearch from "../user_search/UserSearch";
import Select from "react-select";
import { MasterKeyRequestMotives, numeral } from "../../common/constants";
import { AxiosBackofficeInterceptor } from "../../common/axios_interceptor";
import Alert from "../../common/components/alert/Alert";
import SelectWithRadio from "../../common/components/select/SelectWithRadio";
axios.interceptors.response.use(undefined, AxiosBackofficeInterceptor);

class ChangeMasterKeyForm extends Component {
  state = {
    master_key_request: {
      user: undefined,
      master_key_request_motive: undefined,
      notes: "",
      master_key: "",
      repeat_master_key: "",
    },
    isSubmitting: false,
    alert: {
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined,
    },
  };

  componentDidMount() {}

  checkForm = () => {
    const masterKeyRequest = this.state.master_key_request;
    const checkUser = !!masterKeyRequest.user;
    let checkMotive = !!masterKeyRequest.master_key_request_motive;
    if (
      masterKeyRequest.master_key_request_motive?.value ===
      MasterKeyRequestMotives.OTHER
    ) {
      checkMotive = !!masterKeyRequest.notes.trim();
    }
    const checkMasterKey = !!masterKeyRequest.master_key.trim();
    const checkSameKeys =
      masterKeyRequest.master_key === masterKeyRequest.repeat_master_key;

    return checkUser && checkMotive && checkMasterKey && checkSameKeys;
  };

  showMessage = (messageType, messageTitle, messages) =>
    this.setState(
      (state) => {
        state.alert.showMessage = true;
        state.alert.messageTitle = messageTitle;
        state.alert.messages = messages;
        state.alert.messageType = messageType;
        return { alert: state.alert };
      },
      () => setTimeout(this.handleAlertClose, 3000)
    );

  handleAlertClose = () =>
    this.setState((state) => {
      state.alert.showMessage = false;
      return { alert: state.alert };
    });

  handleSubmit = () => {
    this.setState({ isSubmitting: true });
    const master_key_request = this.state.master_key_request;
    if (this.checkForm()) {
      const token = null;
      const url = `/backoffice/save_master_password.json`;
      const method = "POST";
      axios(url, {
        method,
        url,
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
        },
        data: { master_key_request },
      })
        .then((response) => {
          if (response.data.success) {
            Turbolinks.visit(`/backoffice?success=1`);
          } else {
            this.showMessage(
              "primary",
              "Alerta",
              response.data.messages || ["Ha ocurrido un error inesperado"]
            );
          }
        })
        .catch((error) =>
          this.showMessage("primary", "Error", [
            "Ha ocurrido un error inesperado",
          ])
        )
        .finally(() => {
          this.setState({ isSubmitting: false });
        });
    } else {
      this.showMessage("primary", "Alerta", [
        "Todos los campos son obligatorios",
      ]);
    }
  };

  selectUser = (user) => {
    this.setState((state) => {
      state.master_key_request.user = user;
      return { master_key_request: state.master_key_request };
    });
  };

  handleChange = (name, value) => {
    this.setState((state) => {
      state.master_key_request[name] = value;
      return { master_key_request: state.master_key_request };
    });
  };

  handleChangeReason = (option) => {
    const motive = find(this.props.masterKeyRequestMotives, { code: option.value });
    this.setState((state) => {
      state.master_key_request.master_key_request_motive = motive;
      return { master_key_request: state.master_key_request };
    });
  };

  render() {
    const enableBtn = this.checkForm();

    const { master_key_request, isSubmitting, alert } = this.state;
    const master_key_request_motives_options = this.props.masterKeyRequestMotives.map(
      (motive) => {
        return {
          value: motive.code,
          label: motive.description,
        };
      }
    );

    const { messageTitle, messageType, messages, showMessage } = alert;

    const selected_master_key_request_motive = master_key_request.master_key_request_motive
      ? {
          value: master_key_request.master_key_request_motive.code,
          label: master_key_request.master_key_request_motive.description,
        }
      : undefined;
    const notesContainer =
      master_key_request.master_key_request_motive?.code ===
      MasterKeyRequestMotives.OTHER ? (
        <div className="col-lg-12">
          <div className="form-group mb-3">
            <label htmlFor="notes" className="label-inside">
              Explique el motivo
            </label>
            <textarea
              type="text"
              name="notes"
              id="notes"
              className="form-control appcar-input-light"
              rows="4"
              value={master_key_request.notes}
              onChange={(e) => this.handleChange("notes", e.target.value)}
            />
          </div>
        </div>
      ) : null;
    return (
      <Fragment>
        <Spinner loading={isSubmitting} />
        <Alert
          alertClass={messageType}
          isShowing={showMessage}
          messages={messages}
          title={messageTitle}
          onClose={this.handleAlertClose}
        />
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label className="label-inside">Buscar usuario</label>
              <UserSearch
                user={this.state.master_key_request.user}
                selectUser={this.selectUser}
                hasLabel={true}
                colClass="col-lg-12"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="label-inside" htmlFor="motive">
                Selecciona el motivo
              </label>
              <SelectWithRadio
                value={selected_master_key_request_motive}
                options={master_key_request_motives_options}
                placeholder="Selecciona..."
                noOptionsMessage={() => "No existen motivos aún..."}
                onChange={this.handleChangeReason}
                classNamePrefix="RS-FIX"
                className="react-select-fix"
                id="motive"
                isSearchable={false}
              />
            </div>
          </div>
          {notesContainer}
        </div>

        <div className="row my-3">
          <div className="col-lg-6">
            <div className="form-group mb-3">
              <label htmlFor="master_key" className="label-inside">
                Contraseña maestra
              </label>
              <input
                type="password"
                name="master_key"
                id="master_key"
                className="form-control appcar-input-light"
                value={master_key_request.master_key}
                onChange={(e) =>
                  this.handleChange("master_key", e.target.value)
                }
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group mb-3">
              <label htmlFor="repeat_master_key" className="label-inside">
                Repetir contraseña
              </label>
              <input
                type="password"
                name="notes"
                id="repeat_master_key"
                className="form-control appcar-input-light"
                value={master_key_request.repeat_master_key}
                onChange={(e) =>
                  this.handleChange("repeat_master_key", e.target.value)
                }
              />
            </div>
          </div>
        </div>
        <button
          type="button"
          className="btn btn-appcar-primary"
          disabled={this.state.isSubmitting || !enableBtn}
          onClick={this.handleSubmit}
        >
          Cambiar contraseña maestra
        </button>
      </Fragment>
    );
  }
}

export default ChangeMasterKeyForm;
