import React, { Component } from "react";
import Spinner from "../../../common/Spinner";
import FlashMessage from "../../../common/FlashMessage";
import axios from "axios";
import ImageCarousel from "../../../common/ImageCarousel";
import { AxiosApplicationInterceptor } from "../../../common/axios_interceptor";
import VehicleStats from "../../../common/VehicleStats";
import UserInfo from "../../rentals/rentals_show/UserInfo";
import VehicleInfo from "../../rentals/rentals_show/VehicleInfo";
import VehicleScheduler from "./components/VehicleScheduler";
axios.interceptors.response.use(undefined, AxiosApplicationInterceptor);

class BookingEdit extends Component {
  state = {
    user: this.props.currentUser,
    booking: this.props.booking,
    rental: this.props.rental,
    extras_ids: [],
    need_to_requote: false,
    blocked_ranges: this.props.blockedRanges || [],
    from: undefined,
    until: undefined,
    ui: {
      isSubmitting: false,
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined
    }
  };

  componentDidMount() {
    $("body").click(function (e) {
      if (!$(e.target).hasClass("anc-tooltip")) {
        $(".tooltiptext").removeClass("tooltip-visible");
      } else {
        var tooltipFor = "#" + e.target.dataset.toggleTooltipFor;
        $(".tooltiptext")
          .not($(tooltipFor))
          .removeClass("tooltip-visible");
        $(tooltipFor).toggleClass("tooltip-visible");
      }
    });
    const extras_ids = this.props.booking.pricing.extra_charges.map(
      x => x.vehicle_extra.id
    );
    this.setState({ extras_ids });
  }

  reloadUser = user => {
    this.setState({ user },
      () => $("#modal-add-mercadopago").modal("hide"));
  }

  showMessage = msg => {
    this.setState(state => {
      state.ui.showMessage = msg.showMessage;
      state.ui.messageType = msg.messageType;
      state.ui.messages = msg.messages;
      state.ui.messageTitle = msg.messageTitle;
      return { ui: state.ui };
    });
  };

  addExtra = extra => {
    this.setState(state => {
      state.extras_ids.push(extra);
      return { extras_ids: state.extras_ids, need_to_requote: true };
    });
  };

  removeExtra = extra => {
    const index = this.state.extras_ids.findIndex(x => x === extra);
    this.setState(state => {
      state.extras_ids.splice(index, 1);
      return { extras_ids: state.extras_ids, need_to_requote: true };
    });
  };

  report_redo = () => {
    this.setState({ need_to_requote: false });
  };

  render() {
    const images = this.state.rental.vehicle.vehicle_pictures.map(image => {
      const url = image;
      return { url };
    });
    let rental = this.state.rental;
    rental.vehicle.trips = rental.vehicle.trips || 0;
    rental.vehicle.vehicle_rating = rental.vehicle.vehicle_rating || 0;
    rental.vehicle.associated_users = rental.vehicle.associated_users.map(
      user => {
        user.admin_rating = user.admin_rating || 0;
        user.evaluations = user.evaluations || 0;
        return user;
      }
    );
    const flash_message = this.state.ui.showMessage ? (
      <FlashMessage
        type={this.state.ui.messageType}
        msg={this.state.ui.messages}
        title={this.state.ui.messageTitle}
      />
    ) : null;
    const features = this.state.rental.vehicle.vehicle_features || [];
    const extras = this.state.rental.pricing.extra_charges || [];
    const carousel = images.length > 0 ?
      (<div className="vehicle-pictures">
        <ImageCarousel
          images={images}
          canViewFullSize={true}
          modalName="modal-booking-edit"
        />
      </div>) : null;
    return (
      <React.Fragment>
        <div className="vehicle-view">
          <Spinner loading={this.state.ui.isSubmitting} />
          {carousel}
          <div className="container-search mb-5" style={{ bottom: images.length > 0 ? "4rem" : "" }}>
            <div className="container">
              <div className="card">
                <div className="card-body p-2 p-sm-3">
                  {flash_message}
                  <div className="row mb-3">
                    <div className="col-lg-8 col-md-12">
                      <div className="row">
                        <div className="col-4 pr-1">
                          <UserInfo
                            owner={
                              this.state.rental.vehicle.associated_users[0]
                            }
                          />
                        </div>
                        <div className="col-8 pl-1">
                          <VehicleStats
                            vehicle={this.state.rental.vehicle}
                            rental={this.state.rental}
                            extras={extras}
                            features={features}
                            extrasIds={this.state.extras_ids}
                            addExtra={this.addExtra}
                            removeExtra={this.removeExtra}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <VehicleInfo
                            vehicle={this.state.rental.information}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      {this.state.rental && this.state.rental.id ? (
                        <VehicleScheduler
                          rental={this.state.rental}
                          user={this.state.user}
                          dateFrom={this.state.rental.date_from}
                          dateUntil={this.state.rental.date_until}
                          blockedRanges={this.state.blocked_ranges}
                          redoQuote={this.state.need_to_requote}
                          reportRedo={this.report_redo}
                          extrasIds={this.state.extras_ids}
                          booking={this.state.booking}
                          extras={extras}
                          reloadUser={this.reloadUser}
                          mpPublicKey={this.props.mpPublicKey}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BookingEdit;
