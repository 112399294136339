import React, { Component } from "react";
import axios from "axios";
import * as _ from "lodash";
import RentalsVehicleInfo from "./RentalsVehicleInfo";
import ImageCarousel from "../../../common/ImageCarousel";
import Spinner from "../../../common/Spinner";
import FlashMessage from "../../../common/FlashMessage";
import { AxiosApplicationInterceptor } from "../../../common/axios_interceptor";
axios.interceptors.response.use(undefined, AxiosApplicationInterceptor);

class RentalsForm extends Component {
  state = {};

  render() {
    const rental = this.props.rental;
    const images = rental.vehicle.vehicle_pictures.map(image => {
      // const url = image.start + "," + image.image_base_64;
      const url = image;
      return { url };
    });
    return (
      <React.Fragment>
        <div className="row my-3">
          <div className="col">
            <h3>Información del vehículo</h3>
            <div className="row">
              <div className="col">
                <h4>Imágenes del vehículo</h4>
                <div className="mb-2 vehicle-pictures">
                  <ImageCarousel
                    images={images}
                    canViewFullSize={true}
                    modalName="modal-rental-form"
                  />
                </div>
              </div>
            </div>
            <div className="row my-3">
              <div className="col">
                <RentalsVehicleInfo vehicle={rental.vehicle} />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RentalsForm;
