import React from "react";
import PageSelector from "./PageSelector";

const TablePageNavigation = ({ paginationInformation, onPageChange, showPageSelector = true, currentPage }) => {
  const { total, limit, last } = paginationInformation;
  let pageSelectors = [];
  const rawTotal = total / limit;
  let totalPages = Math.floor(rawTotal)
  if (rawTotal - totalPages !== 0) {
    totalPages++;
  }
  if (showPageSelector) {
    for (let i = 1; i <= totalPages; i++) {
      pageSelectors.push(<PageSelector number={i} onPageChange={onPageChange} isActualPage={currentPage == i} limit={limit} key={i} />)
    }
  }
  const previousSelector =
    (
      <li
        className={`table-page-selector${currentPage === 1 ? " disabled" : ""}`}
        onClick={() => currentPage !== 1 ? onPageChange(currentPage - 1, "before") : null}
      >
        Anterior
      </li>
    );
  const nextSelector =
    (
      <li
        className={`table-page-selector${currentPage === totalPages ? " disabled" : ""}`}
        onClick={() => currentPage !== totalPages ? onPageChange(currentPage + 1, "after") : null}
      >
        Siguiente
      </li>
    );
  const pageSelectorList = (
    <div className="table-paging">
      <ul className="table-page-selectors">
        {previousSelector}
        {/* {pageSelectors.map(el => el)} */}
        {nextSelector}
      </ul>
    </div>
  );
  return (
    <div className="row">
      <div className="col-12 col-md-6 p-2 fs-12">
        Página {currentPage}{totalPages > 0 ? ` de un total de ${totalPages}` : ""}
      </div>
      <div className="col d-inline">
        {pageSelectorList}
      </div>
    </div>
  )
}

export default TablePageNavigation;