import React, { Component } from "react";
import Axios from "axios";
import { AxiosBackofficeInterceptor } from "../../common/axios_interceptor";
import moment from "moment";
import DateTimePicker from "../../common/search_components/AppcarDateTimePicker";
import PricingDetails from "../../common/components/pricing_details/PricingDetails";
import SelectWithRadio from "../../common/components/select/SelectWithRadio";
import carLocationIcon from "images/Icons/PickupIcon.png";
import PoliciesSummary from "../../appcar/rentals/show/PoliciesSummary";
import { constants, numeral } from "../../common/constants";
import RentalSchedule from "../../appcar/rentals/schedule/RentalSchedule";
import Spinner from "../../common/Spinner";
import Alert from "../../common/components/alert/Alert";
import UserSearch from "../user_search/UserSearch";
import ModalDates from "../../appcar/rentals/show/modals/ModalDates";
import UserFormModal from "../../appcar/rentals/show/modals/UserFormModal";
import DatesContainer from "../../appcar/rentals/show/containers/DatesContainer";
import ExtrasContainer from "../../appcar/rentals/show/containers/ExtrasContainer";
import LocationContainer from "../../appcar/rentals/show/containers/LocationContainer";
import SchedulerContainer from "../../appcar/rentals/show/containers/SchedulerContainer";
import ModalPricing from "../../appcar/rentals/show/modals/ModalPricing";
import ModalLocation from "../../appcar/rentals/show/modals/ModalLocation";

Axios.interceptors.response.use(undefined, AxiosBackofficeInterceptor);

export default class RentalScheduler extends Component {
  state = {
    fromDate: moment(constants.RENTAL_SEARCH_FROM).format("DD/MM/YYYY"),
    fromTime: moment(constants.RENTAL_SEARCH_FROM).format("HH:mm"),
    untilDate: moment(constants.RENTAL_SEARCH_UNTIL).format("DD/MM/YYYY"),
    untilTime: moment(constants.RENTAL_SEARCH_UNTIL).format("HH:mm"),
    error: false,
    success: false,
    hasQuoted: false,
    newFrom: moment(constants.RENTAL_SEARCH_FROM).format(""),
    newTo: moment(constants.RENTAL_SEARCH_UNTIL).format(""),
    selectedPickup: {
      label:
        (this.props.rental.location && this.props.rental.location.name) || "",
      value:
        (this.props.rental.location && this.props.rental.location.name) || "",
    },
    selectedDropoff: {
      label:
        (this.props.rental.location && this.props.rental.location.name) || "",
      value:
        (this.props.rental.location && this.props.rental.location.name) || "",
    },
    isSubmitting: false,
    extrasIds: [],
    checkedGuarantee: false,
    checkedPaid: false,
    transactionID: "",
    guaranteeTransactionID: "",
    user: null,
    pricing: {
      total: 0,
      details: [],
      extra_charges: [],
      commission: 0,
      insurance_charge: 0,
      payment_method_fee: 0,
      value_added_tax: 0,
      pick_up_charge: null,
      drop_off_charge: null,
      clf_value: 0.0,
      owner_total: 0.0,
      discount: 0.0,
    },
    alert: {
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined,
    },
    mapInfo: {
      lat: 0,
      lng: 0,
      locationAddress: "",
    },
  };

  modalUserForm = null;
  modalDates = null;
  modalPricingDetails = null;
  modalLocationMap = null;

  componentDidMount() {
    moment.locale("es");

    let from;
    let until;
    let fromDate = "";
    let fromTime = "";
    let untilDate = "";
    let untilTime = "";
    let selectedDropoff = {};
    let selectedPickup = {};
    const propsDateFrom = !!this.props.dateFrom
      ? moment(this.props.dateFrom)
      : moment.invalid();
    const propsDateUntil = !!this.props.dateUntil
      ? moment(this.props.dateUntil)
      : moment.invalid();
    const defaultDateFrom = moment(constants.RENTAL_SEARCH_FROM);
    const defaultDateUntil = moment(constants.RENTAL_SEARCH_UNTIL);
    const now = moment().valueOf();

    if (propsDateFrom.isValid() && propsDateFrom.valueOf() > now) {
      from = propsDateFrom;
    } else {
      from = defaultDateFrom;
    }

    if (propsDateUntil.isValid() && propsDateUntil.valueOf() > now) {
      until = propsDateUntil;
    } else {
      until = defaultDateUntil;
    }

    fromDate = from.format("DD/MM/YYYY");
    fromTime = from.format("HH:mm");
    untilDate = until.format("DD/MM/YYYY");
    untilTime = until.format("HH:mm");
    this.setCopyLinkData({ from: from.format(), to: until.format() });

    const rental = this.props.rental;
    const location = rental.location?.name;

    const address = this.props.address;
    const extraLocations = rental.pricing.extra_locations || [];
    const validExtraLocation = extraLocations.find(
      (l) => l.location?.name == address
    );
    if (validExtraLocation) {
      selectedDropoff = { label: address, value: address };
      selectedPickup = { label: address, value: address };
      this.setCopyLinkData({ address });
    } else if (location) {
      selectedDropoff = { label: location, value: location };
      selectedPickup = { label: location, value: location };
      this.setCopyLinkData({ address: location });
    }

    this.setState(
      {
        fromDate,
        fromTime,
        untilDate,
        untilTime,
        selectedDropoff,
        selectedPickup,
        newFrom: from.format(),
        newTo: until.format(),
      },
      () => {
        this.calculatePrice();
        this.initializeCalendars();
        this.modalUserForm = new bootstrap.Modal(
          document.getElementById("UserFormModal")
        );
        this.modalDates = new bootstrap.Modal(
          document.getElementById("datesModal")
        );
        this.modalPricingDetails = new bootstrap.Modal(
          document.getElementById("pricingDetailsModal")
        );
        this.modalLocationMap = new bootstrap.Modal(
          document.getElementById("modalLocation")
        );
      }
    );
  }

  setCopyLinkData = ({ from = "", to = "", address = "" }) => {
    if (!!from)
      sessionStorage.setItem("copy_from_" + this.props.rental.id, from);
    if (!!to) sessionStorage.setItem("copy_to_" + this.props.rental.id, to);
    if (!!address)
      sessionStorage.setItem("copy_address_" + this.props.rental.id, address);
  };

  checkImportantData = () => {
    // Check important data: name, last_name, phone_number, id_number (email already exists)
    const user = this.state.user;
    if (!user) return false;
    if (user.name && user.last_name && user.phone_number && user.id_number) {
      return true;
    } else return false;
  };

  initializeCalendars = () => {
    const this_ = this;
    const options = {
      showDropdowns: true,
      maxYear: constants.MAX_YEAR,
      singleDatePicker: true,
      minDate: moment(),
      maxDate: moment(this_.props.rental.date_until),
      locale: {
        format: "DD/MM/YYYY",
        cancelLabel: "Cancelar",
        applyLabel: "Aplicar",
        fromLabel: "Desde",
        toLabel: "Hasta",
        daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        firstDay: 1,
      },
    };

    const dateFrom = $("#date_from").daterangepicker(options, (start) => {
      let { fromDate, fromTime, untilDate, untilTime } = this_.state;
      const fromHours = parseInt(fromTime.split(":")[0]);
      const fromMinutes = parseInt(fromTime.split(":")[1]);
      const untilHours = parseInt(untilTime.split(":")[0]);
      const untilMinutes = parseInt(untilTime.split(":")[1]);
      const from = moment(start)
        .hours(fromHours)
        .minutes(fromMinutes);
      let until = moment(untilDate, "DD/MM/YYYY")
        .hours(untilHours)
        .minutes(untilMinutes);

      if (until.valueOf() < from.valueOf()) {
        until = from.clone();
        $("#date_until")
          .data("daterangepicker")
          .setStartDate(until.format("DD/MM/YYYY"));
        $("#date_until")
          .data("daterangepicker")
          .setEndDate(until.format("DD/MM/YYYY"));
        untilDate = until.format("DD/MM/YYYY");
        untilTime = until.format("HH:mm");
      }
      fromDate = from.format("DD/MM/YYYY");
      const fromString = moment(
        fromDate + fromTime,
        "DD/MM/YYYYHH:mm"
      ).format();
      const untilString = moment(
        untilDate + untilTime,
        "DD/MM/YYYYHH:mm"
      ).format();
      let alert = this.state.alert;
      alert.showMessage = false;
      this_.setState(
        {
          fromDate,
          untilDate,
          untilTime,
          alert,
          hasQuoted: false,
          error: false,
          success: false,
        },
        () => {
          this.calculatePrice();
          this.setCopyLinkData({ from: fromString, to: untilString });
        }
      );
    });

    const dateUntil = $("#date_until").daterangepicker(options, (start) => {
      let { fromDate, fromTime, untilDate, untilTime } = this_.state;
      const fromHours = parseInt(fromTime.split(":")[0]);
      const fromMinutes = parseInt(fromTime.split(":")[1]);
      const untilHours = parseInt(untilTime.split(":")[0]);
      const untilMinutes = parseInt(untilTime.split(":")[1]);
      let from = moment(fromDate, "DD/MM/YYYY")
        .hours(fromHours)
        .minutes(fromMinutes);
      const until = moment(start)
        .hours(untilHours)
        .minutes(untilMinutes);

      if (until.valueOf() < from.valueOf()) {
        from = until.clone();
        $("#date_from")
          .data("daterangepicker")
          .setStartDate(from.format("DD/MM/YYYY"));
        $("#date_from")
          .data("daterangepicker")
          .setEndDate(from.format("DD/MM/YYYY"));
        fromDate = from.format("DD/MM/YYYY");
        fromTime = from.format("HH:mm");
      }
      untilDate = until.format("DD/MM/YYYY");
      const fromString = moment(
        fromDate + fromTime,
        "DD/MM/YYYYHH:mm"
      ).format();
      const untilString = moment(
        untilDate + untilTime,
        "DD/MM/YYYYHH:mm"
      ).format();
      let alert = this.state.alert;
      alert.showMessage = false;
      this_.setState(
        {
          fromDate,
          fromTime,
          untilDate,
          alert,
          hasQuoted: false,
          error: false,
          success: false,
        },
        () => {
          this.calculatePrice();
          this.setCopyLinkData({ from: fromString, to: untilString });
        }
      );
    });

    const blockedRanges = this.props.blockedRanges || [];
    dateFrom.isInvalidDate = (date) => {
      let disable = false;
      for (let event of blockedRanges) {
        disable = false;
        // Se considera que el día en el que inicia y finaliza el evento se libera por completo
        const eventMinValue = moment(event.from)
          .endOf("d")
          .valueOf();
        const eventMaxValue = moment(event.until)
          .startOf("d")
          .valueOf();

        const dateValue = moment(date).valueOf();
        if (dateValue > eventMinValue && dateValue < eventMaxValue) {
          disable = true;
          break;
        }
      }
      return disable;
    };
    dateUntil.isInvalidDate = (date) => {
      let disable = false;
      for (let event of blockedRanges) {
        disable = false;
        // Se considera que el día en el que inicia y finaliza el evento se libera por completo
        const eventMinValue = moment(event.from)
          .endOf("d")
          .valueOf();
        const eventMaxValue = moment(event.until)
          .startOf("d")
          .valueOf();

        const dateValue = moment(date).valueOf();
        if (dateValue > eventMinValue && dateValue < eventMaxValue) {
          disable = true;
          break;
        }
      }
      return disable;
    };
  };

  changeFromTime = (fromTime) => {
    let { fromDate, untilDate, untilTime } = this.state;
    const from = moment(fromDate + fromTime, "DD/MM/YYYYHH:mm");
    const until = moment(untilDate + untilTime, "DD/MM/YYYYHH:mm");
    if (until.valueOf() < from.valueOf()) {
      until = from.clone();
      $("#date_until")
        .data("daterangepicker")
        .setStartDate(until.format("DD/MM/YYYY"));
      $("#date_until")
        .data("daterangepicker")
        .setEndDate(until.format("DD/MM/YYYY"));
      untilDate = until.format("DD/MM/YYYY");
      untilTime = until.format("HH:mm");
    }
    const fromString = moment(fromDate + fromTime, "DD/MM/YYYYHH:mm").format();
    const untilString = moment(
      untilDate + untilTime,
      "DD/MM/YYYYHH:mm"
    ).format();
    let alert = this.state.alert;
    alert.showMessage = false;
    this.setState(
      {
        fromTime,
        untilDate,
        untilTime,
        alert,
        hasQuoted: false,
        error: false,
        success: false,
      },
      () => {
        this.calculatePrice();
        this.setCopyLinkData({ from: fromString, to: untilString });
      }
    );
  };

  changeUntilTime = (untilTime) => {
    let { fromDate, fromTime, untilDate } = this.state;
    const from = moment(fromDate + fromTime, "DD/MM/YYYYHH:mm");
    const until = moment(untilDate + untilTime, "DD/MM/YYYYHH:mm");
    if (until.valueOf() < from.valueOf()) {
      from = until.clone();
      $("#date_from")
        .data("daterangepicker")
        .setStartDate(until.format("DD/MM/YYYY"));
      $("#date_from")
        .data("daterangepicker")
        .setEndDate(until.format("DD/MM/YYYY"));
      fromDate = from.format("DD/MM/YYYY");
      fromTime = from.format("HH:mm");
    }
    const fromString = moment(fromDate + fromTime, "DD/MM/YYYYHH:mm").format();
    const untilString = moment(
      untilDate + untilTime,
      "DD/MM/YYYYHH:mm"
    ).format();
    let alert = this.state.alert;
    alert.showMessage = false;
    this.setState(
      {
        fromDate,
        fromTime,
        untilTime,
        alert,
        hasQuoted: false,
        error: false,
        success: false,
      },
      () => {
        this.calculatePrice();
        this.setCopyLinkData({ from: fromString, to: untilString });
      }
    );
  };

  componentDidUpdate() {
    const dateFrom = $("#date_from").data("daterangepicker");
    const dateUntil = $("#date_until").data("daterangepicker");
    const blockedRanges = this.props.blockedRanges || [];
    if (dateFrom && dateUntil) {
      dateFrom.setStartDate(
        moment(this.state.fromDate, "DD/MM/YYYY").format("DD/MM/YYYY")
      );
      dateFrom.setEndDate(
        moment(
          this.state.fromDate + this.state.fromTime,
          "DD/MM/YYYYHH:mm"
        ).format("DD/MM/YYYY")
      );
      dateUntil.setStartDate(
        moment(
          this.state.untilDate + this.state.untilTime,
          "DD/MM/YYYYHH:mm"
        ).format("DD/MM/YYYY")
      );
      dateUntil.setEndDate(
        moment(
          this.state.untilDate + this.state.untilTime,
          "DD/MM/YYYYHH:mm"
        ).format("DD/MM/YYYY")
      );
      dateFrom.isInvalidDate = (date) => {
        let disable = false;
        for (let event of blockedRanges) {
          disable = false;
          // Se considera que el día en el que inicia y finaliza el evento se libera por completo
          const eventMinValue = moment(event.from)
            .endOf("d")
            .valueOf();
          const eventMaxValue = moment(event.until)
            .startOf("d")
            .valueOf();

          const dateValue = moment(date).valueOf();
          if (dateValue > eventMinValue && dateValue < eventMaxValue) {
            disable = true;
            break;
          }
        }
        return disable;
      };
      dateUntil.isInvalidDate = (date) => {
        let disable = false;
        for (let event of blockedRanges) {
          disable = false;
          // Se considera que el día en el que inicia y finaliza el evento se libera por completo
          const eventMinValue = moment(event.from)
            .endOf("d")
            .valueOf();
          const eventMaxValue = moment(event.until)
            .startOf("d")
            .valueOf();

          const dateValue = moment(date).valueOf();
          if (dateValue > eventMinValue && dateValue < eventMaxValue) {
            disable = true;
            break;
          }
        }
        return disable;
      };
    }
  }

  showMessage = (messageType, messageTitle, messages) =>
    this.setState(
      (state) => {
        state.alert.showMessage = true;
        state.alert.messageTitle = messageTitle;
        state.alert.messages = messages;
        state.alert.messageType = messageType;
        return { alert: state.alert };
      },
      () => setTimeout(this.handleAlertClose, 3000)
    );

  handleAlertClose = () =>
    this.setState((state) => {
      state.alert.showMessage = false;
      return { alert: state.alert };
    });

  updateUser = () => {
    this.setState({ isSubmitting: true });
    const { user, needInvoice } = this.state;
    const updatingUser = !!user?.id;
    let url = "";
    let method = "";
    let params = {};
    if (updatingUser) {
      params.user = user;
      // If need invoice then check if user has billing info, else create base billing info
      params.user.billing_info ??= {
        rut: "",
        name: "",
        address: "",
        city: "",
        municipality: "",
        phone: "",
        business: "",
        email: "",
      };
      if (needInvoice) {
        params.user.billing_info.rut =
          params.user.billing_info.rut || user.id_number;
      }
      url = `/backoffice/owners/${user.id}.json?need_reload=true`;
      method = "PUT";
    } else {
      this.showMessage(
        "primary",
        "Error",
        response.data.messages || ["Primero debes seleccionar un usuario"]
      );
      this.setState({ isSubmitting: false });
      return;
    }
    Axios({
      method,
      url,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    })
      .then((response) => {
        if (response.data.success) {
          this.setState({ user: response.data.user });
          this.modalUserForm.hide();
          this.showMessage("primary", "Éxito", ["Datos actualizados"]);
        } else {
          this.showMessage(
            "primary",
            "Error",
            response.data.messages || ["Ha ocurrido un error inesperado"]
          );
        }
      })
      .catch((error) => {
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ]);
      })
      .finally(() => {
        this.setState({ isSubmitting: false });
      });
  };

  selectUser = (user) => {
    this.setState({ isSubmitting: true });
    Axios({
      method: "GET",
      url: `/backoffice/owners/${user.id}/user_data.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.data.user) {
          this.setState({ user: response.data.user });
        } else {
          this.showMessage("primary", "Error", [
            "Ha ocurrido un error inesperado",
          ]);
        }
      })
      .catch((error) => {
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ]);
      })
      .finally(() => this.setState({ isSubmitting: false }));
  };

  createParams = () => {
    this.setState((state) => {
      state.alert.showMessage = false;
      state.alert.messageType = undefined;
      state.alert.messages = [];
      state.alert.messageTitle = undefined;
      return { isSubmitting: true, alert: state.alert };
    });
    const {
      selectedPickup,
      selectedDropoff,
      extrasIds,
      fromDate,
      fromTime,
      untilDate,
      untilTime,
    } = this.state;
    const rental = this.props.rental;
    const newFrom = moment(fromDate + fromTime, "DD/MM/YYYYHH:mm").format();
    const newTo = moment(untilDate + untilTime, "DD/MM/YYYYHH:mm").format();

    let rental_pricing = {
      rental_id: rental.id,
      start: newFrom,
      end: newTo,
      extras_ids: extrasIds,
    };
    if (!!selectedPickup && selectedPickup.value != rental.location.name) {
      rental_pricing.special_pickup = selectedPickup.value;
    }
    if (!!selectedDropoff && selectedDropoff.value != rental.location.name) {
      rental_pricing.special_dropoff = selectedDropoff.value;
    }
    return rental_pricing;
  };

  calculatePrice = (e) => {
    const rental_pricing = this.createParams();
    Axios({
      method: "POST",
      url: `/rentals/${this.props.rental.id}/calculate_pricing.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: { rental_pricing },
    })
      .then((response) => {
        if (response.data.success) {
          const pricing = response.data.pricing;
          this.setState({
            hasQuoted: true,
            pricing,
            error: false,
            newFrom: rental_pricing.start,
            newTo: rental_pricing.end,
          });
        } else {
          this.showMessage(
            "primary",
            "Alerta",
            response.data.messages || ["Ha ocurrido un error inesperado"]
          );
        }
      })
      .catch((error) => {
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ]);
      })
      .finally(() => this.setState({ isSubmitting: false }));
  };

  addExtra = (extra) => {
    this.setState(
      (state) => {
        state.extrasIds.push(extra);
        return { extrasIds: state.extrasIds, hasQuoted: false };
      },
      () => this.calculatePrice()
    );
  };

  removeExtra = (extra) => {
    const index = this.state.extrasIds.findIndex((x) => x === extra);
    this.setState(
      (state) => {
        state.extrasIds.splice(index, 1);
        return { extrasIds: state.extrasIds, hasQuoted: false };
      },
      () => this.calculatePrice()
    );
  };

  handleChangeCheckedExtra = (checked, extra) => {
    if (checked) {
      this.addExtra(extra);
    } else {
      this.removeExtra(extra);
    }
  };

  changeLocation = (option, obj) =>
    this.setState(
      {
        hasQuoted: false,
        error: false,
        success: false,
        [obj]: option,
      },
      () => this.calculatePrice()
    );

  createBooking = () => {
    let booking = this.createParams();
    booking.user_id = this.state.user && this.state.user.id;
    booking.transaction_id = this.state.transactionID;
    booking.guarantee_transaction_id = this.state.guaranteeTransactionID;
    booking.vehicle_id = this.props.rental.vehicle.id;
    booking.is_paid = this.state.checkedPaid;
    booking.guarantee_is_paid = this.state.checkedGuarantee;
    Axios({
      method: "POST",
      url: "/backoffice/bookings.json",
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: { booking },
    })
      .then((response) => {
        if (response.data.success) {
          this.setState({ success: true, error: false });
          this.showMessage("primary", "Éxito", ["Reserva exitosa"]);
        } else {
          this.showMessage(
            "primary",
            "Alerta",
            response.data.messages || ["Ha ocurrido un error inesperado"]
          );
        }
      })
      .catch((error) => {
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ]);
      })
      .finally(() => this.setState({ isSubmitting: false }));
  };

  handleCheckPaid = (checkedPaid) => {
    this.setState({ checkedPaid });
  };

  handleCheckGuarantee = (checkedGuarantee) => {
    this.setState({ checkedGuarantee });
  };

  completeProfile = () => {
    if (this.state.user && this.state.user.id) {
      this.modalUserForm.show();
    }
  };

  handleChangeTransactionID = (transactionID) => {
    this.setState({ transactionID });
  };

  handleChangeGuaranteeTransactionID = (guaranteeTransactionID) => {
    this.setState({ guaranteeTransactionID });
  };

  showLocationMap = ({ lat, lng, locationAddress }) => {
    this.setState(
      (state) => {
        state.mapInfo.lat = lat;
        state.mapInfo.lng = lng;
        state.mapInfo.locationAddress = locationAddress;
        return { mapInfo: state.mapInfo };
      },
      () => this.modalLocationMap.show()
    );
  };

  changeLoggedUser = (value, name) =>
    this.setState((state) => {
      state.user[name] = value;
      return { user: state.user };
    });

  validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  changeNeedInvoice = (value) => this.setState({ needInvoice: value });

  handleBlurIdNumber = () => {
    const idNumber = this.state.data.id_number;
    const documentType = this.state.data.document_type;

    if (documentType && documentType.description == "RUT") {
      if (validateRUT(idNumber)) {
        const formatedRut = formateaRut(idNumber);
        this.setState((state) => {
          state.data.id_number = formatedRut;
          return { data: state.data, errorIdNumber: "" };
        });
      } else {
        this.setState({ errorIdNumber: "El RUT es inválido" });
      }
    }
  };

  showSpinner = () => this.setState({ isSubmitting: true });

  hideSpinner = () => this.setState({ isSubmitting: false });

  render() {
    moment.locale("es");
    const {
      fromDate,
      fromTime,
      untilDate,
      untilTime,
      extrasIds,
      selectedPickup,
      selectedDropoff,
      pricing,
      newFrom,
      newTo,
      hasQuoted,
      error,
      success,
      isSubmitting,
      alert,
      user,
      needInvoice,
      checkedPaid,
      transactionID,
      checkedGuarantee,
      guaranteeTransactionID,
      mapInfo,
    } = this.state;

    const { messageTitle, messageType, messages, showMessage } = alert;

    const { blockedRanges, rental, documentTypes } = this.props;
    const vehicle = rental.vehicle;

    const from = moment(fromDate + fromTime, "DD/MM/YYYYHH:mm");
    const until = moment(untilDate + untilTime, "DD/MM/YYYYHH:mm");
    const dayOfWeek = from.day();

    const newFrom_ = moment(newFrom);
    const newTo_ = moment(newTo);
    const eventRange = moment.duration(newTo_.diff(newFrom_));
    const duration = Math.floor(eventRange.asDays());
    const hours = Math.floor(eventRange.add(-duration, "days").asHours());
    const minutes = Math.floor(eventRange.add(-hours, "hours").asMinutes());

    let bookingLengthText =
      "Total por " + duration + " día" + (duration > 1 ? "s" : "");
    if (hours) {
      const hoursText = hours + " hora" + (hours > 1 ? "s" : "");
      if (minutes) bookingLengthText += "," + hoursText;
      else bookingLengthText += " y " + hoursText;
    }
    if (minutes) {
      bookingLengthText +=
        " y " + minutes + " minutos" + (minutes > 1 ? "s" : "");
    }

    let btnText = "Debes seleccionar un usuario";
    let btnAction = () => null;
    let btnDisabled = isSubmitting || (success && !error) || !user;

    if (user && user.id) {
      btnText = "Solicitar reserva";
      if (!hasQuoted) {
        btnText = "Actualizar precio";
        btnAction = () => this.calculatePrice();
      } else if (!this.checkImportantData()) {
        btnAction = () => this.completeProfile();
      } else if (!success) {
        btnText = "Solicitar reserva";
        btnAction = () => {
          this.createBooking();
        };
      } else {
        btnText = "Solicitud enviada";
        btnAction = () => null;
        btnDisabled = true;
      }
    }

    let alertOverlap = null;
    let datesOverlaped = false;
    if (Array.isArray(blockedRanges) && blockedRanges.length > 0) {
      for (let event of blockedRanges) {
        const eventMinValue = moment(event.from);
        const eventMaxValue = moment(event.until);

        const fromValue = moment(from).valueOf();
        const untilValue = moment(until);
        if (fromValue <= eventMaxValue && eventMinValue <= untilValue) {
          datesOverlaped = true;
          alertOverlap = (
            <div className="alert alert-primary my-3">
              <i className="mdi mdi-calendar-alert mx-2" /> Las fechas
              seleccionadas no se encuentran disponibles
            </div>
          );
          break;
        }
      }
    }
    const btnBook = (
      <button
        type="button"
        className="btn btn-appcar-rental text-nowrap"
        onClick={btnAction}
        disabled={btnDisabled}
      >
        {btnText}
      </button>
    );

    const modalDates = (
      <ModalDates
        changeFromTime={this.changeFromTime}
        changeUntilTime={this.changeUntilTime}
        fromDate={fromDate}
        fromTime={fromTime}
        untilDate={untilDate}
        untilTime={untilTime}
        alertOverlap={alertOverlap}
      />
    );

    const userFormModal = (
      <UserFormModal
        user={user}
        onChange={this.changeLoggedUser}
        validateEmail={this.validateEmail}
        action={this.updateUser}
        isUpdating={true}
        documentTypes={documentTypes}
        checkNeedInvoice={needInvoice}
        changeNeedInvoice={this.changeNeedInvoice}
        fromBackoffice={true}
      />
    );

    const modalPricingDetails = (
      <ModalPricing pricing={pricing} from={newFrom} to={newTo} />
    );

    const modalMap = (
      <ModalLocation
        lat={mapInfo.lat}
        lng={mapInfo.lng}
        address={mapInfo.locationAddress}
        markerUrl={this.props.markerIconURL}
      />
    );

    const extraLocations = rental.pricing.extra_locations || [];
    const extras = (rental.pricing && rental.pricing.extra_charges) || [];

    const extrasContainer = (
      <ExtrasContainer
        extras={extras}
        extrasIds={extrasIds}
        handleChangeCheckedExtra={this.handleChangeCheckedExtra}
      />
    );

    const locationContainer = (
      <LocationContainer
        extraLocations={extraLocations}
        selectedPickup={selectedPickup}
        selectedDropoff={selectedDropoff}
        location={rental.location}
        showLocationMap={this.showLocationMap}
        changePickUp={(option) =>
          this.setState(
            {
              selectedPickup: { value: option.value, label: option.value },
              hasQuoted: false,
            },
            () => {
              this.calculatePrice();
              this.setCopyLinkData({ address: option.value });
            }
          )
        }
        changeDropOff={(option) =>
          this.setState(
            {
              selectedDropoff: { value: option.value, label: option.value },
              hasQuoted: false,
            },
            () => this.calculatePrice()
          )
        }
      />
    );

    let featuresHTML = "No se han añadido características";

    if (
      Array.isArray(vehicle.vehicle_features) &&
      vehicle.vehicle_features.length > 0
    ) {
      featuresHTML = (
        <div className="row">
          {vehicle.vehicle_features.map((feature, i) => (
            <div className="col-6" key={i}>
              {feature.a_name}
            </div>
          ))}
        </div>
      );
    }

    const descriptionHTML =
      rental.information && rental.information.description ? (
        <div className="section border-section">
          <h5>Descripción</h5>
          <div className="section-content">
            {rental.information.description}
          </div>
        </div>
      ) : null;

    const schedulerContainer = (
      <SchedulerContainer
        pricing={pricing}
        from={from}
        until={until}
        bookingLengthText={bookingLengthText}
        datesOverlaped={datesOverlaped}
        showModalPricing={() => this.modalPricingDetails.show()}
        showModalDates={() => this.modalDates.show()}
        btnBook={btnBook}
      />
    );

    const bankTransferData = (
      <>
        <h5>Datos de pago</h5>
        <div className="form-group">
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="check-paid"
              name="check-paid"
              checked={checkedPaid}
              onChange={(e) => this.handleCheckPaid(e.target.checked)}
            />
            <label
              className="form-check-label text-black extra-label"
              htmlFor="check-paid"
            >
              ¿Ya fue pagado?
            </label>
          </div>
        </div>
        <div className="form-group">
          <label className="label-inside" htmlFor="transactionID">
            Código de transacción del pago
          </label>
          <input
            type="text"
            className="form-control appcar-input"
            id="transactionID"
            name="transactionID"
            value={transactionID}
            onChange={(e) => this.handleChangeTransactionID(e.target.value)}
          />
        </div>
        <div className="form-group">
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="check-guarantee"
              name="check-guarantee"
              checked={checkedGuarantee}
              onChange={(e) => this.handleCheckGuarantee(e.target.checked)}
            />
            <label
              className="form-check-label text-black extra-label"
              htmlFor="check-guarantee"
            >
              ¿Fue pagada la garantía?
            </label>
          </div>
        </div>
        <div className="form-group">
          <label className="label-inside" htmlFor="guaranteeTransactionID">
            Código de transacción de garantía
          </label>
          <input
            type="text"
            className="form-control appcar-input"
            id="guaranteeTransactionID"
            name="guaranteeTransactionID"
            value={guaranteeTransactionID}
            onChange={(e) =>
              this.handleChangeGuaranteeTransactionID(e.target.value)
            }
          />
        </div>
      </>
    );
    return (
      <>
        <Spinner loading={isSubmitting} />
        <Alert
          alertClass={messageType}
          isShowing={showMessage}
          messages={messages}
          title={messageTitle}
          onClose={this.handleAlertClose}
        />
        <div className="desk vehicle-scheduler">
          <div className="column">
            {schedulerContainer}
            <div className="rental-card">
              <h5>Seleccionar usuario</h5>
              <UserSearch user={this.state.user} selectUser={this.selectUser} />
              <div className="py-3"></div>
              {bankTransferData}
            </div>
            <div className="rental-card hide-on-res">
              <PoliciesSummary rental={rental} dayOfWeek={dayOfWeek} />
            </div>
          </div>
          <div className="column">
            <div className="rental-card">
              {locationContainer}
              <div className="py-3"></div>
              <RentalSchedule rental={rental} />
            </div>
            <div className="rental-card">
              <h5>Extras</h5>
              <div className="extras-container">{extrasContainer}</div>
            </div>
            <div className="rental-card show-on-res">
              <PoliciesSummary rental={rental} dayOfWeek={dayOfWeek} />
            </div>
          </div>
        </div>
        <div className="mobile mobile-scheduler">
          <div className="section">{schedulerContainer}</div>
          <div className="section border-section">
            <h5>Seleccionar usuario</h5>
            <UserSearch user={this.state.user} selectUser={this.selectUser} />
            <div className="py-3"></div>
            {bankTransferData}
          </div>
          <div className="section border-section">{locationContainer}</div>
          <div className="section border-section">
            <RentalSchedule rental={rental} />
          </div>
          {descriptionHTML}
          <div className="section border-section">
            <h5>Características</h5>
            <div className="section-content">{featuresHTML}</div>
          </div>
          <div className="section border-section">
            <h5>Extras</h5>
            <div className="section-content">{extrasContainer}</div>
          </div>
          <div className="section">
            <PoliciesSummary rental={rental} dayOfWeek={dayOfWeek} />
          </div>
        </div>
        {modalDates}
        {modalPricingDetails}
        {userFormModal}
        {modalMap}
      </>
    );
  }
}
