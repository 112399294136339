import React from "react";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import ClearIndicator from "./ClearIndicator";
import IndicatorsContainer from "./IndicatorsContainer";

const { ValueContainer, Placeholder } = components;

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id={`select_option_${props.value}`}
            name={`select_option_${props.value}`}
            checked={props.isSelected}
            onChange={() => null}
          />
          <label className="form-check-label">{props.label}</label>
        </div>
      </components.Option>
    </div>
  );
};

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

const SelectWithCheckbox = (props) => {
  const multiValueContainer = ({ children, selectProps, data }) => {
    const label = data.label;
    const allSelected = selectProps.value;
    // const index = allSelected.findIndex(selected => selected.label === label);
    // const isLastSelected = index === allSelected.length - 1;
    // const labelSuffix = isLastSelected ? "" : ", ";
    // const val = `${label}${labelSuffix}`;
    const val = allSelected.length > 1 ? "" : "Selecciona";
    return "";
  };
  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      textOverflow: "ellipsis",
      maxWidth: "90%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      display: "initial",
    }),
  };
  return (
    <ReactSelect
      components={{
        Option,
        MultiValueContainer: multiValueContainer,
        ValueContainer: CustomValueContainer,
        ClearIndicator,
        IndicatorsContainer,
      }}
      styles={customStyles}
      {...props}
    />
  );
};

export default SelectWithCheckbox;
