import React, { useRef } from "react";

const PasswordChangeForm = ({
  email,
  setEmail,
  code,
  setCode,
  newPassword,
  setNewPassword,
  confirmPassword,
  setConfirmPassword,
  changePassword,
  showModalCode,
}) => {
  const alert = useRef();
  const checkPasswordAreEqual = () => {
    if (newPassword != confirmPassword) {
      alert.current.style.display = "block";
    }
  };
  let disableBtn = newPassword != confirmPassword;
  return (
    <div className="modal-dialog modal-dialog-centered modal-appcar">
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="btn-m-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            x
          </button>
          <h5 className="modal-title" id="passwordChangeModalLabel">
            Cambia tu contraseña
          </h5>
        </div>
        <div
          className="modal-body mx-auto pb-3"
          style={{ maxWidth: 350, paddingTop: 36 }}
        >
          <div className="text-center mb-2">
            <h4 className="fs-25 fw-900 mb-4">Ya casi estamos</h4>
            <h5 className="fs-13 fw-700 mb-3">Ingresa estos datos:</h5>
            <div className="text-left mb-4">
              <div className="form-group">
                <label className="label-inside">Tu email</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control appcar-input"
                  style={{ width: 310 }}
                  required
                />
              </div>
              <div className="form-group">
                <label className="label-inside">Código</label>
                <input
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  className="form-control appcar-input"
                  style={{ width: 310 }}
                  required
                />
              </div>
              <div className="form-group">
                <label className="label-inside">Nueva contraseña</label>
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="form-control appcar-input"
                  style={{ width: 310 }}
                  required
                />
              </div>
              <div className="form-group">
                <label className="label-inside">Repite tu contraseña</label>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    alert.current.style.display = "none";
                  }}
                  className="form-control appcar-input"
                  style={{ width: 310 }}
                  required
                  onBlur={() => checkPasswordAreEqual()}
                />
                <div
                  className="invalid-feedback"
                  style={{ display: "none" }}
                  id="confirm_password_text_validation"
                  ref={alert}
                >
                  Las contraseñas no coinciden
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-appcar-primary btn-slim w-50"
              disabled={!newPassword || newPassword != confirmPassword}
              onClick={() => {
                if (newPassword === confirmPassword) changePassword();
                else alert.current.style.display = "block";
              }}
            >
              Cambiar
            </button>
          </div>
          <div className="text-center foot-link my-3">
            <span
              className="fs-11 fw-900 text-appcar-primary cursor-pointer"
              onClick={showModalCode}
            >
              Volver a solicitar código
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordChangeForm;
