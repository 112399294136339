import React, { Component, Fragment } from "react";
import Spinner from "../../../common/Spinner";
import Alert from "../../../common/components/alert/Alert";
import axios from "axios";
import { generateFilename } from "../../../common/constants";
import DocumentLoader from "../../../common/components/image_loader/DocumentLoader";
import { AxiosApplicationInterceptor } from "../../../common/axios_interceptor";
import SelectWithRadio from "../../../common/components/select/SelectWithRadio";
import AfterRegisterModal from "./AfterRegisterModal";
axios.interceptors.response.use(undefined, AxiosApplicationInterceptor);

export default class PersonalData extends Component {
  state = {
    country: this.props.user.country || undefined,
    countries: this.props.countries,
    frontalDocumentPicture: this.props.frontalDocumentPicture || {
      filename: "",
      start: "",
      image_base_64: "",
      mime_type: "",
      url: "",
    },
    backDocumentPicture: this.props.backDocumentPicture || {
      filename: "",
      start: "",
      image_base_64: "",
      mime_type: "",
      url: "",
    },
    driverLicensePicture: this.props.driverLicensePicture || {
      filename: "",
      start: "",
      image_base_64: "",
      mime_type: "",
      url: "",
    },
    isSubmitting: false,
    objectUploading: "",
    acceptTermsAndConditions: false,
    alert: {
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined,
    },
  };

  componentDidMount() {
    this.afterRegisterModal = new bootstrap.Modal(
      document.getElementById("AfterRegisterModal")
    );
  }

  openFileDialog = (objectUploading) =>
    this.setState({ objectUploading }, () =>
      document.getElementById("image_uploader").click()
    );

  uploadPicture = (e) => {
    let file = e.target.files;
    if (file.length > 0) {
      const filename = generateFilename(file[0].name);
      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onloadend = function(e) {
        var base64result = reader.result;
        this.addPicture(base64result, filename);
      }.bind(this);
    }
  };

  addPicture = (picture, filename) => {
    const file = picture.split(",");
    const start = file[0];
    const base64 = file[1];
    const mime = picture.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    const mimeType = mime && mime.length ? mime[1] : "";
    const object = this.state.objectUploading;
    filename = encodeURI(filename);
    this.setState((state) => {
      state[object].filename = filename;
      state[object].start = start;
      state[object].image_base_64 = base64;
      state[object].mime_type = mimeType;
      return { [object]: state[object] };
    });
  };

  removePicture = (object) =>
    this.setState((state) => {
      state[object] = {
        filename: "",
        start: "",
        image_base_64: "",
        mime_type: "",
        url: "",
      };
      return { [object]: state[object] };
    });

  showMessage = (messageType, messageTitle, messages) =>
    this.setState(
      (state) => {
        state.alert.showMessage = true;
        state.alert.messageTitle = messageTitle;
        state.alert.messages = messages;
        state.alert.messageType = messageType;
        return { alert: state.alert };
      },
      () => setTimeout(this.handleAlertClose, 3000)
    );

  handleAlertClose = () =>
    this.setState((state) => {
      state.alert.showMessage = false;
      return { alert: state.alert };
    });

  handleSubmit = () => {
    this.setState({ isSubmitting: true });
    let user = this.props.user;
    const {
      frontalDocumentPicture,
      backDocumentPicture,
      driverLicensePicture,
      country,
    } = this.state;
    
    user.document_pictures = [frontalDocumentPicture, backDocumentPicture];
    user.driver_license = [driverLicensePicture];
    user.country = country;
    axios({
      method: "PUT",
      url: `/owners/${user.id}.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: { user },
    })
      .then((response) => {
        if (response.data.success) {
          const action = sessionStorage.getItem("afterAction");
          const needToCompleteData = localStorage.getItem(
            "need_to_complete_data"
          );
          if (needToCompleteData === "1") {
            localStorage.removeItem("need_to_complete_data");
          } else {
            if (action == "payment_method") {
              sessionStorage.removeItem("afterAction");
              window.location.assign(`/owners/${user.id}/payment_method`);
            } else {
              this.afterRegisterModal.show();
            };
          }
        } else {
          this.showMessage(
            "primary",
            "Alerta",
            response.data.messages || ["Ha ocurrido un error inesperado"]
          );
        }
      })
      .catch((error) =>
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ])
      )
      .then(() => this.setState({ isSubmitting: false }));
  };

  render() {
    const {
      alert,
      isSubmitting,
      acceptTermsAndConditions,
      frontalDocumentPicture,
      backDocumentPicture,
      driverLicensePicture,
    } = this.state;

    const countriesOptions = this.state.countries.map((c) => ({
      label: c.description,
      value: c.id,
    }));

    const selectedCountry = {
      value: this.state.country && this.state.country.id,
      label: this.state.country && this.state.country.description,
    };

    const { messageTitle, messageType, messages, showMessage } = alert;

    const afterRegisterModal = (
      <AfterRegisterModal
        user={this.props.user || {}}
      />
    );

    return (
      <Fragment>
        <div className="edit-box">
          <Alert
            alertClass={messageType}
            isShowing={showMessage}
            messages={messages}
            title={messageTitle}
            onClose={this.handleAlertClose}
          />
          <Spinner loading={isSubmitting} />
          <div className="photo-container">
            <div className="image-loader">
              <h2>Completa tu registro</h2>
              <h3>Parte 2 de 2</h3>
            </div>
            <div className="photo-advice">
              <div className="icon">
                <img src="/IconAppcar.png" />
              </div>
              <div className="advice">
                Recuerda que únicamente el Driver que solicita la reserva puede
                retirar el auto
              </div>
            </div>
          </div>
          <div className="form-documents">
            <DocumentLoader
              addImage={this.openFileDialog}
              removeImage={this.removePicture}
              imageBase64={frontalDocumentPicture.image_base_64}
              start={frontalDocumentPicture.start}
              url={frontalDocumentPicture.url}
              title="Documento de identificación (Frontal)"
              backgroundPlaceHolder="/IDFront.png"
              objectName="frontalDocumentPicture"
            />
            <div className="form-group">
              <label htmlFor="country" className="label-inside">
                País emisor del documento
              </label>
              <SelectWithRadio
                value={selectedCountry}
                options={countriesOptions}
                placeholder="Selecciona"
                noOptionsMessage={() => "No hay países"}
                onChange={(opt) =>
                  this.setState((state) => {
                    state.country = {
                      id: opt.value,
                      description: opt.label,
                    };
                    return { data: state.country };
                  })
                }
                classNamePrefix="RS-FIX"
                className="react-select-fix appcar-input"
              />
            </div>
            <br/>
            <DocumentLoader
              addImage={this.openFileDialog}
              removeImage={this.removePicture}
              imageBase64={backDocumentPicture.image_base_64}
              start={backDocumentPicture.start}
              url={backDocumentPicture.url}
              title="Documento de identificación (Trasera)"
              backgroundPlaceHolder="/IDBack.png"
              objectName="backDocumentPicture"
            />
            <DocumentLoader
              addImage={this.openFileDialog}
              removeImage={this.removePicture}
              imageBase64={driverLicensePicture.image_base_64}
              start={driverLicensePicture.start}
              url={driverLicensePicture.url}
              title="Licencia de conducir (Frontal)"
              backgroundPlaceHolder="/DriverLicensePlaceholder.png"
              objectName="driverLicensePicture"
            />
            <div className="form-group">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="acceptTermsAndConditions"
                  name="acceptTermsAndConditions"
                  checked={acceptTermsAndConditions}
                  onChange={(e) =>
                    this.setState({
                      acceptTermsAndConditions: e.target.checked,
                    })
                  }
                />
                <label
                  className="form-check-label text-black"
                  htmlFor="acceptTermsAndConditions"
                >
                  Acepto los{" "}
                  <a
                    href="/legal/terms_and_conditions"
                    className="text-black"
                    target="_blank"
                  >
                    términos y condiciones
                  </a>
                </label>
              </div>
            </div>
            <div className="text-center my-4">
              <button
                type="button"
                className="btn btn-appcar-light"
                disabled={isSubmitting || !acceptTermsAndConditions}
                onClick={this.handleSubmit}
              >
                Guardar y continuar
              </button>
            </div>
            <input
              type="file"
              id="image_uploader"
              className="d-none"
              onChange={(e) => this.uploadPicture(e)}
              accept="image/jpeg, image/png, image/jpg"
              onClick={(e) => {
                e.target.value = null;
              }}
            />
          </div>
        </div>
        {afterRegisterModal}
      </Fragment>
    );
  }
}
