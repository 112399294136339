import React, { Component } from "react";

export default class BookingInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  currencyFormatter = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: "CLP"
  });

  render() {
    const booking = this.props.booking;
    const driver = booking.user[0];
    const vehicle = booking.vehicle;
    const owner = vehicle.associated_users[0];
    let date_from = new Date();
    date_from.setTime(Date.parse(booking.date_from));
    let date_until = new Date();
    date_until.setTime(Date.parse(booking.date_until));
    const select_status = this.props.selectStatus;
    const oneclickTransaction = booking.payment ? booking.payment.oneclick_transaction : booking.oneclick_transaction;
    const walletTransaction = booking.payment ? booking.payment.wallet_transaction : booking.wallet_transaction;
    let booking_is_paid = false
    if (booking.ledger) {
      const lastRecord = booking.ledger[booking.ledger.length - 1];
      booking_is_paid = lastRecord.booking_total == lastRecord.user_balance;
    } else if (!!oneclickTransaction) {
      booking_is_paid = oneclickTransaction.capture && oneclickTransaction.capture.authorization_code;
    } else if (!!walletTransaction) {
      booking_is_paid = !!walletTransaction.id;
    }
    const image_icon = (
      <i
        className={
          booking_is_paid
            ? "mdi mdi-checkbox-marked-circle-outline"
            : "mdi mdi-checkbox-blank-circle-outline"
        }
      />
    );
    return (
      <div
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="booking-info-title"
        aria-hidden="true"
        id="modal-booking-info"
      >
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="booking-info-title">
                Ver datos de reserva: {booking.id}
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body mx-3">
              <h3>Detalles</h3>
              <div className="row">
                <div className="col-md-6">
                  <table className="table dt-bootstrap4 table-striped table-bordered my-3">
                    <thead>
                      <tr>
                        <th colSpan="4">Reserva</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Desde</td>
                        <td colSpan="3">{date_from.toLocaleDateString()}</td>
                      </tr>
                      <tr>
                        <td>Hasta</td>
                        <td colSpan="3">{date_until.toLocaleDateString()}</td>
                      </tr>
                      <tr>
                        <td>Precio de la reserva</td>
                        <td colSpan="3">
                          {this.currencyFormatter.format(
                            booking.price.total
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>¿Ya se pagó?</td>
                        <td
                          className={`text-center ${
                            booking_is_paid ? "text-success" : "text-danger"
                          }`}
                          style={{ fontSize: "1.375rem", fontWeight: "900" }}
                        >
                          {image_icon}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-md-6">
                  <table className="table dt-bootstrap4 table-striped table-bordered my-3">
                    <thead>
                      <tr>
                        <th colSpan="4">Vehículo</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Marca / Modelo</td>
                        <td colSpan="3">
                          {vehicle.vehicle_type.brand +
                            " " +
                            vehicle.vehicle_type.model +
                            " " +
                            vehicle.year}
                        </td>
                      </tr>
                      <tr>
                        <td>Patente</td>
                        <td colSpan="3">{vehicle.plate_number}</td>
                      </tr>
                      <tr>
                        <td>Color</td>
                        <td colSpan="3">{vehicle.color}</td>
                      </tr>
                      <tr>
                        <td>Combustible</td>
                        <td colSpan="3">{vehicle.fuel_type.description}</td>
                      </tr>
                      {/* <tr>
                        <td>Capacidad</td>
                        <td colSpan="3">{vehicle.capacity}</td>
                      </tr>
                      <tr>
                        <td>Eficiencia</td>
                        <td colSpan="3">{vehicle.efficiency}</td>
                      </tr>
                      <tr>
                        <td>N° Motor</td>
                        <td colSpan="3">{vehicle.engine}</td>
                      </tr>
                      <tr>
                        <td>N° Chasis</td>
                        <td colSpan="3">{vehicle.chassis}</td>
                      </tr>
                      <tr>
                        <td>Avalúo</td>
                        <td colSpan="3">
                          {this.currencyFormatter.format(vehicle.appraisal)}
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <table className="table dt-bootstrap4 table-striped table-bordered my-3">
                    <thead>
                      <tr>
                        <th colSpan="4">Driver</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Nombre</td>
                        <td colSpan="3">
                          {driver.name +
                            " " +
                            driver.last_name +
                            " " +
                            (driver.second_last_name || "")}
                        </td>
                      </tr>
                      <tr>
                        <td>N° identificación</td>
                        <td colSpan="3">{driver.id_number}</td>
                      </tr>
                      <tr>
                        <td>Correo</td>
                        <td colSpan="3">{driver.email}</td>
                      </tr>
                      <tr>
                        <td>N° contacto</td>
                        <td colSpan="3">{driver.phone_number}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-md-6">
                  <table className="table dt-bootstrap4 table-striped table-bordered my-3">
                    <thead>
                      <tr>
                        <th colSpan="4">Owner</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Nombre</td>
                        <td colSpan="3">
                          {owner.name +
                            " " +
                            owner.last_name +
                            " " +
                            (owner.second_last_name || "")}
                        </td>
                      </tr>
                      <tr>
                        <td>N° identificación</td>
                        <td colSpan="3">{owner.id_number}</td>
                      </tr>
                      <tr>
                        <td>Correo</td>
                        <td colSpan="3">{owner.email}</td>
                      </tr>
                      <tr>
                        <td>N° contacto</td>
                        <td colSpan="3">{owner.phone_number}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="row">
                {/* <div className="col-6">
                  <div className="form-group">
                    <label>Nuevo estado</label>
                    {select_status}
                  </div>
                </div> */}
                <div className="col-6">
                  {/* <button
                    type="button"
                    className="btn btn-appcar-primary mr-2"
                    onClick={this.props.handleUpdateBooking}
                    disabled={!this.props.newStatus}
                  >
                    Aplicar nuevo estado
                  </button> */}
                  <button
                    type="button"
                    className="btn btn-appcar-light"
                    id="confirm-btn"
                    onClick={e => {
                      e.preventDefault();
                      this.props.cancel;
                    }}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
