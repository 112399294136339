import React from "react";

const LoginModal = ({ user, onChange, validateEmail, checkUser, title = "Solicitar reserva", modalRef = null }) => (
  <div
    className="modal fade"
    id="LoginModal"
    aria-hidden="true"
    aria-labelledby="LoginModalLabel"
    tabIndex={-1}
    ref={modalRef}
  >
    <div className="modal-dialog modal-dialog-centered modal-appcar">
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="btn-m-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            x
          </button>
          <h5 className="modal-title" id="LoginModalLabel">
            {title}
          </h5>
        </div>
        <div className="modal-body mx-auto" style={{ maxWidth: 350 }}>
          <div className="text-center mb-4">
            <h4 className="fs-25 fw-900">Aquí comienza tu viaje</h4>
            <div className="form-group text-left mb-4">
              <label className="label-inside">Tu email</label>
              <input
                type="email"
                id="notlogged__email"
                className="form-control appcar-input"
                value={user.email}
                style={{ width: 310 }}
                onChange={(e) => onChange(e.target.value, "email")}
                onKeyDown={(e) => {
                  // Submit on enter
                  if (e.key === "Enter") {
                    // Verify if is valid email
                    if (validateEmail(user.email)) {
                      checkUser();
                    }
                  }
                }}
              />
            </div>
            <button
              type="button"
              onClick={() => checkUser()}
              className="btn btn-appcar-primary btn-slim w-50"
              disabled={!validateEmail(user.email)}
            >
              Siguiente
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default LoginModal;
