import React from 'react';
import SyncLoader from 'react-spinners/SyncLoader';

const Spinner = ({ loading }) => {
  return (
    <div className={loading ? "loading-more" : "d-none"}>
      <SyncLoader loading={loading} color={"#FF543E"} />
    </div>
  );
};

export default Spinner;