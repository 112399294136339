import moment from "moment";
import React from "react";
import { numeral } from "../../../common/constants";
import SelectWithRadio from "../../../common/components/select/SelectWithRadio";
import iconInfo from "images/Icons/ico-info.png";
import ToolTip, { LoadToolTips } from "../../../common/components/ToolTip";

const VehicleData = ({
  data,
  handleChange,
  handleChangeObj,
  handleChangeModel,
  brands,
  models,
  categories,
  fuelTypes,
  transmissions,
  features,
  isSubmitting,
  handleSubmit,
  addFeature,
  removeFeature,
  fromModal = false,
}) => {
  const {
    plate_number,
    year,
    vehicle_type,
    vehicle_category,
    color,
    fuel_type,
    capacity,
    vehicle_transmission,
    vehicle_features,
    market_value,
  } = data;

  const selectedBrand =
    vehicle_type && vehicle_type.brand
      ? {
          label: vehicle_type.brand,
          value: vehicle_type.brand,
        }
      : null;
  const selectedModel =
    vehicle_type && vehicle_type.model
      ? {
          label: vehicle_type.model,
          value: vehicle_type.id,
        }
      : null;
  const selectedTransmission =
    vehicle_transmission && vehicle_transmission.id
      ? {
          label: vehicle_transmission.description,
          value: vehicle_transmission.id,
        }
      : null;
  const selectedFuelType =
    fuel_type && fuel_type.id
      ? {
          label: fuel_type.description,
          value: fuel_type.id,
        }
      : null;
  const selectedCategory =
    vehicle_category && vehicle_category.id
      ? {
          label: vehicle_category.description,
          value: vehicle_category.id,
        }
      : null;

  LoadToolTips();

  let disableButton = true;

  if (
    !!plate_number &&
    !!year &&
    !!selectedModel &&
    !!selectedCategory &&
    !!color &&
    !!selectedFuelType &&
    !!capacity &&
    !!selectedTransmission &&
    !!market_value
  ) {
    disableButton = false;
  }
  const maxYear = moment().year();
  const manualTransmission = transmissions.find(
    (x) => x.description == "Manual" || x.id === 1
  );
  const automaticTransmission = transmissions.find(
    (x) => x.description == "Automático" || x.id === 2
  );
  const transmissionIsManual =
    vehicle_transmission?.id === manualTransmission.id;
  const feature4x4 = features.find((x) => x.a_name == "4 x 4" || x.id === 2);
  const is4x4 = vehicle_features.some((x) => x.id === feature4x4.id);
  const saveButton = fromModal ? null : (
    <div className="text-center my-4">
      <button
        type="button"
        className="btn btn-appcar-primary pt-2 pb-2"
        onClick={() => handleSubmit(1)}
        disabled={isSubmitting || disableButton}
      >
        Continuar
      </button>
    </div>
  );
  return (
    <>
      <div className="row">
        <div className="col-6 pr-1">
          <div className="form-group">
            <label htmlFor="plate_number" className="label-inside">
              Patente
            </label>
            <input
              type="text"
              name="plate_number"
              id="plate_number"
              className="form-control appcar-input no-min-width"
              value={plate_number}
              required
              onChange={(e) => handleChange(e.target.value, "plate_number")}
            />
          </div>
        </div>
        <div className="col pl-1 pr-0">
          <div className="form-group">
            <label htmlFor="year" className="label-inside">
              Año
            </label>
            <input
              type="number"
              name="year"
              id="year"
              className="form-control appcar-input no-min-width"
              value={year}
              max={maxYear}
              min={maxYear - 15}
              required
              onChange={(e) => handleChange(e.target.value, "year")}
              onBlur={(e) => {
                if (e.target.value < maxYear - 15)
                  handleChange(maxYear - 15, "year");
              }}
            />
          </div>
        </div>
        <div className="col-auto pl-1" style={{ marginTop: 30 }}>
          <ToolTip
            title="Antigüedad"
            tooltipHTML="Tu auto debe tener menos de 15 años de antigüedad para poder ser parte de nuestra plataforma."
            containerHTML={<img src={iconInfo} width={20} className="ml-1" />}
            id="ttip_vehicle_max_age"
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="vehicle_type.brand" className="label-inside">
          Marca
        </label>
        <SelectWithRadio
          value={selectedBrand}
          options={brands}
          placeholder="Selecciona"
          noOptionsMessage={() => "No hay marcas"}
          onChange={(opt) => {
            handleChange(
              { brand: opt.value, model: "", id: "" },
              "vehicle_type"
            );
          }}
          classNamePrefix="RS-FIX"
          className="react-select-fix appcar-input"
        />
      </div>
      <div className="row">
        <div className="col pr-0">
          <div className="form-group">
            <label htmlFor="vehicle_type" className="label-inside">
              Modelo
            </label>
            <SelectWithRadio
              value={selectedModel}
              options={models}
              placeholder="Selecciona"
              noOptionsMessage={() => "Primero selecciona la marca"}
              onChange={(opt) => handleChangeModel(opt)}
              classNamePrefix="RS-FIX"
              className="react-select-fix appcar-input"
            />
          </div>
        </div>
        <div className="col-auto pl-1" style={{ marginTop: 32 }}>
          <ToolTip
            title="¿Por qué no está mi auto?"
            tooltipHTML="Si el modelo de tu auto no está en el listado, lamentablemente no podremos publicarlo dado que no tiene cobertura por Seguros Sura."
            containerHTML={<img src={iconInfo} width={20} className="ml-1" />}
            id="ttip_vehicle_model"
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="vehicle_category" className="label-inside">
          Categoría
        </label>
        <SelectWithRadio
          value={selectedCategory}
          options={categories}
          placeholder="Selecciona"
          noOptionsMessage={() => "No hay categorías disponibles..."}
          onChange={(opt) =>
            handleChange(
              { id: opt.value, description: opt.label },
              "vehicle_category"
            )
          }
          classNamePrefix="RS-FIX"
          className="react-select-fix appcar-input"
          hideSelectedOptions={false}
          backspaceRemovesValue={false}
          isSearchable={false}
        />
      </div>
      <div className="form-group">
        <label htmlFor="color" className="label-inside">
          Color
        </label>
        <input
          type="text"
          name="color"
          id="color"
          className="form-control appcar-input no-min-width"
          value={color}
          required
          onChange={(e) => handleChange(e.target.value, "color")}
        />
      </div>
      <div className="row">
        <div className="col-6 pr-1">
          <div className="form-group">
            <label htmlFor="fuel_type" className="label-inside">
              Tipo de combustible
            </label>
            <SelectWithRadio
              value={selectedFuelType}
              options={fuelTypes}
              placeholder="Selecciona"
              noOptionsMessage={() => "No hay tipos de combustible..."}
              onChange={(opt) =>
                handleChange(
                  { id: opt.value, description: opt.label },
                  "fuel_type"
                )
              }
              classNamePrefix="RS-FIX"
              className="react-select-fix appcar-input no-min-width"
              hideSelectedOptions={false}
              backspaceRemovesValue={false}
              isSearchable={false}
            />
          </div>
        </div>
        <div className="col-6 pl-1">
          <div className="form-group">
            <label htmlFor="capacity" className="label-inside">
              Capacidad de pasajeros
            </label>
            <input
              min={0}
              type="number"
              name="capacity"
              id="capacity"
              className="form-control appcar-input no-min-width"
              value={capacity}
              required
              onChange={(e) => handleChange(e.target.value, "capacity")}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 form-group mt-2">
          <label
            htmlFor="vehicle_transmission"
            className="label-inside no-transform"
          >
            Transmisión
          </label>
        </div>
        <div className="col-6">
          <button
            type="button"
            onClick={() => {
              if (!transmissionIsManual)
                handleChange(manualTransmission, "vehicle_transmission");
            }}
            className={`btn btn-appcar-${
              transmissionIsManual ? "secondary" : "white text-black"
            } w-100 fs-12`}
          >
            Manual
          </button>
        </div>
        <div className="col-6">
          <button
            type="button"
            onClick={() => {
              if (transmissionIsManual)
                handleChange(automaticTransmission, "vehicle_transmission");
            }}
            className={`btn btn-appcar-${
              !transmissionIsManual ? "secondary" : "white text-black"
            } w-100 fs-12`}
          >
            Automática
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 form-group mt-2">
          <label
            htmlFor="vehicle_transmission"
            className="label-inside no-transform"
          >
            Tracción
          </label>
        </div>
        <div className="col-6">
          <button
            type="button"
            onClick={() => {
              if (is4x4) removeFeature(feature4x4);
            }}
            className={`btn btn-appcar-${
              !is4x4 ? "secondary" : "white text-black"
            } w-100 fs-12`}
          >
            Normal
          </button>
        </div>
        <div className="col-6">
          <button
            type="button"
            onClick={() => {
              if (!is4x4) addFeature(feature4x4);
            }}
            className={`btn btn-appcar-${
              is4x4 ? "secondary" : "white text-black"
            } w-100 fs-12`}
          >
            4 x 4
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col pr-0">
          <div className="form-group">
            <label htmlFor="market_value" className="label-inside">
              Valor comercial aprox. del auto
            </label>
            <input
              type="text"
              name="market_value"
              id="market_value"
              className="form-control appcar-input no-min-width"
              value={numeral(market_value).format("$0,0")}
              required
              onChange={(e) => {
                const cleanValue = e.target.value.replace(/\D/g, "") || "0";
                handleChange(parseInt(cleanValue), "market_value");
              }}
            />
          </div>
        </div>
        <div className="col-auto pl-1" style={{ marginTop: 30 }}>
          <ToolTip
            title="Valor comercial del auto"
            tooltipHTML="Ingresa un estimado del valor comercial actual de tu vehículo, el cual será informado a nuestra compañía de seguros."
            containerHTML={<img src={iconInfo} width={20} className="ml-1" />}
            id="ttip_vehicle_market_value"
          />
        </div>
      </div>
      {saveButton}
    </>
  );
};

export default VehicleData;
