import React, { Component } from "react";
import { CustomCheckBox } from "../../common/CustomInputs";

class VehicleAccessories extends Component {
  handleChange = (e, feature) => {
    if (e.target.checked) {
      this.props.addFeature(feature);
    } else {
      this.props.removeFeature(feature);
    }
  };

  handlePrev = e => {
    e.preventDefault();
    $("html, main").animate({ scrollTop: 0 }, "slow");
    this.props.jumpToStep(2);
  };

  // handleNext = e => {
  //   e.preventDefault();
  //   $("html, main").animate({ scrollTop: 0 }, "slow");
  //   this.props.jumpToStep(4);
  // }


  render() {
    const features = this.props.vehicle.vehicle_features;
    const accesories = this.props.vehicleFeatures.map((feature, i) => {
      const obj = _.find(features, { id: feature.id });
      const checked = obj ? true : false;
      return (
        <div className="col-md-6 vehicle-extras" key={i}>
          <CustomCheckBox
            id={feature.id}
            name={feature.a_name}
            checked={checked}
            handleChange={e => this.handleChange(e, feature)}
            label={feature.a_name}
          />
        </div>
      );
    });
    const btn_label = this.props.isEditing
      ? "Editar vehículo"
      : "Registrar vehículo";
    return (
      <React.Fragment>
        <div className="my-0 step-names">
          <div className="step-title">
            <span>Datos del vehículo</span>
          </div>
          <div className="step-title">
            <span>Fotos del vehículo</span>
          </div>
          <div className="step-title">
            <span>Documentos</span>
          </div>
          <div className="step-title">
            <span>Accesorios</span>
          </div>
          {/* <div className="step-title">
            <span>Políticas</span>
          </div> */}
        </div>
        <div className="my-3 mx-5">
          <h1 style={{ textAlign: "center" }}>
            Por favor completa la siguiente información:
          </h1>
          <div className="container vehicle-extras-container mt-4">
            <h4 className="pt-3 ml-2">Accesorios del vehículo</h4>
            <div className="ml-1 row pt-2 pb-3">{accesories}</div>
          </div>
        </div>
        <div className="footer-buttons">
          <button
            className="btn btn-prev btn-appcar-primary btn-lg pull-left"
            onClick={e => this.handlePrev(e)}
          >
            Anterior
          </button>
          {/* <button
            className="btn btn-next btn-appcar-primary btn-lg pull-right"
            onClick={e => this.handleNext(e)}
          >
            Siguiente
          </button> */}
          <button
            className="btn btn-next btn-appcar-primary btn-lg pull-right"
            onClick={e => this.props.handleSubmit(e)}
          >
            {btn_label}
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default VehicleAccessories;
