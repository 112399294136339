import React from "react";

const Spec = ({icon, text}) => (
  <div className="spec">
    <i className={icon}></i>
    <span className="main">
      {text}
    </span>
  </div>
)

export default Spec;