import React from "react";
export default LeaseAgreement = ({
  bookingId,
  rentalId,
  userId,
  from,
  to,
  totalPrice,
  pickup,
  dropoff,
  insuranceCharge,
  clfValue,
  ownerTotal,
  discount
}) => {
  const pickupParam = pickup ? "&pickup=" + encodeURIComponent(pickup) : "";
  const dropoffParam = dropoff ? "&dropoff=" + encodeURIComponent(dropoff) : "";
  const url = bookingId ?
    //Si tiene bookingId
    // "/legal/lease_agreement?booking_id=" + encodeURIComponent(bookingId)
    "/legal/booking_certificate?booking_id=" + encodeURIComponent(bookingId)
    //Si no, es porque es una modificación de parte del owner
    // : "/legal/lease_agreement?rental_id=" + encodeURIComponent(rentalId)
    : "/legal/booking_certificate?rental_id=" + encodeURIComponent(rentalId)
    + "&user_id=" + encodeURIComponent(userId)
    + "&from=" + encodeURIComponent(from)
    + "&to=" + encodeURIComponent(to)
    + "&total_price=" + encodeURIComponent(totalPrice)
    + "&insurance_charge=" + encodeURIComponent(insuranceCharge)
    + "&clf_value=" + encodeURIComponent(clfValue)
    + "&owner_total=" + encodeURIComponent(ownerTotal)
    + "&discount=" + encodeURIComponent(discount)
    + pickupParam + dropoffParam;
  return (
    <>
      <p>Documentos asociados:</p>
      <ul>
        <li>
          <a
            href={"/legal/terms_and_conditions"}
            target="_blank"
            rel="noopener noreferrer"
            className="font-weight-bold"
          >
            Términos y condiciones
        </a>
        </li>
        <li>
          <a
            href={url + "#privacy"}
            target="_blank"
            rel="noopener noreferrer"
            className="font-weight-bold"
          >
            Política de privacidad I.
        </a>
        </li>
      </ul>
    </>
  );
}