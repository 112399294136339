import React, { Component } from "react";
import styles from "../map/AppcarStyles.json";
import markerIcon from "images/MapMarkerActive.png";
import { GoogleMap, withGoogleMap, Marker } from "react-google-maps";

const MapComponent = withGoogleMap((props) => (
  <GoogleMap
    isMarkerShown={props.isMarkerShown}
    loadingElement={props.loadingElement}
    // googleMapURL={props.googleMapURL}
    containerElement={props.containerElement}
    mapElement={props.mapElement}
    ref={props.onMapMounted}
    {...props}
    // onClick={() => null}
    options={{
      minZoom: 16,
      maxZoom: 16,
      mapTypeControl: false,
      disablePanMomentum: false,
      styles: styles,
      fullscreenControl: false,
      streetViewControl: false,
      zoomControl: false,
    }}
    disablePanMomentum={false}
    center={props.center}
    // onBoundsChanged={() => props.onBoundsChanged()}
  >
    {props.places}
  </GoogleMap>
));

export default class FixedMap extends Component {
  mapRef = React.createRef();

  handleMapMounted = (map) => {
    this._map = map;
    window._map = map;
  };

  componentDidUpdate(prevProps) {
    if (prevProps.lat != this.props.lat || prevProps.lng != this.props.lng) {
      let bounds = new google.maps.LatLngBounds();
      bounds.extend(new google.maps.LatLng(this.props.lat, this.props.lng));
      this._map.fitBounds(bounds);
      google.maps.event.trigger(this._map, "drag", {});
      // this._map.panTo(new google.maps.LatLng(this.props.lat, this.props.lng));
    }
  }

  render() {
    const { lat, lng } = this.props;
    const places = [
      <Marker
        position={{ lat, lng }}
        key="searchmarker"
        icon={{
          url: markerIcon,
          scaledSize: new google.maps.Size(37, 37),
          anchor: new google.maps.Point(0, 37),
        }}
      />,
    ];
    return (
      <MapComponent
        isMarkerShown
        loadingElement={<div style={{ height: "100%" }} />}
        containerElement={<div className="fixed-map-container" />}
        mapElement={<div style={{ height: "100%", width: "100%" }} />}
        places={places}
        center={{ lat, lng }}
        onMapMounted={this.handleMapMounted}
        // onMapClick={this.onMapClick}
        // onDragEnd={this.onDragEnd}
        // onDragStart={this.onDragStart}
        // onZoomChanged={this.onZoomChanged}
        // onBoundsChanged={this.onBoundsChanged}
      />
    );
  }
}
