import React, { Component } from "react";
import { CustomCheckBox, DatePicker } from "../../common/CustomInputs";
import Moment from "moment";
import { extendMoment } from "moment-range";
import cloneDeep from "lodash/cloneDeep";
import { constants } from "../../common/constants";

const moment = extendMoment(Moment);

class WorkspaceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: {
        is_disabled: false,
        start: constants.RENTAL_SEARCH_FROM,
        end: constants.RENTAL_SEARCH_UNTIL,
        reason: "",
        index: null,
        object: ""
      }
    };
  }

  componentDidMount() {
    const options = {
      showDropdowns: true,
      singleDatePicker: true,
      maxYear: constants.MAX_YEAR,
      locale: {
        format: "LL",
        cancelLabel: "Cancelar",
        applyLabel: "Aplicar",
        fromLabel: "Desde",
        toLabel: "Hasta",
        daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre"
        ],
        firstDay: 1
      }
    };

    $("#date_start").daterangepicker(options, (start, end) => {
      const start_ = moment(start)
        .startOf("day")
        .format();
      this.setState(state => {
        state.event.start = start_;
        return { event: state.event };
      });
    });
    $("#date_end").daterangepicker(options, (start, end) => {
      const end_ = moment(start)
        .endOf("day")
        .format();
      this.setState(state => {
        state.event.end = end_;
        return { event: state.event };
      });
    });
  }

  componentDidUpdate(prevProps) {
    // console.log(event);
    if (prevProps.event != this.props.event) {
      this.setState({ event: this.props.event }, () => {
        $("#date_start")
          .data("daterangepicker")
          .setStartDate(
            moment(this.state.event.start)
              .locale("es")
              .format("LL")
          );
        $("#date_start")
          .data("daterangepicker")
          .setEndDate(
            moment(this.state.event.start)
              .locale("es")
              .format("LL")
          );
        $("#date_end")
          .data("daterangepicker")
          .setStartDate(
            moment(this.state.event.end)
              .locale("es")
              .format("LL")
          );
        $("#date_end")
          .data("daterangepicker")
          .setEndDate(
            moment(this.state.event.end)
              .locale("es")
              .format("LL")
          );
      });
    }
  }

  handleChange = (key, value) =>
    this.setState(state => {
      state.event[key] = value;
      return { event: state.event };
    });

  handleSubmit = (e, event, is_deleting = false) => {
    e.preventDefault();
    const event_ = cloneDeep(event);
    this.setState(
      state => {
        state.event = {
          is_disabled: false,
          start: moment().format(),
          end: moment().format(),
          reason: ""
        };
      },
      () => {
        this.props.handleSubmit(event_, is_deleting);
      }
    );
  };

  handleConfirm = (e, event) => {
    e.preventDefault();
    this.props.handleConfirm(event);
  };

  render() {
    const {
      is_disabled,
      start,
      end,
      reason,
      index,
      object
    } = this.state.event;
    const { bookings, events, rental, hasBookings } = this.props;
    const start_date = moment(start)
      .locale("es")
      .format("LL");
    const end_date = moment(end)
      .locale("es")
      .format("LL");
    let alert_overlap =
      moment(end).diff(moment(start)) < 0
        ? "La fecha final debe ser inferior a la fecha inicial"
        : "";
    let affected_bookings = [];
    const event_range = moment().range(
      moment(this.props.originalStart),
      moment(this.props.originalEnd)
    );
    const actual_range = moment().range(moment(start), moment(end));
    for (let k = 0; k < bookings.length; k++) {
      const range_b = moment().range(
        moment(bookings[k].date_from)
          .startOf("day")
          .format(),
        moment(bookings[k].date_until)
          .endOf("day")
          .format()
      );
      if (event_range.contains(range_b)) affected_bookings.push(bookings[k]);
      if (
        !hasBookings &&
        (range_b.contains(moment(start)) || range_b.contains(moment(end)))
      ) {
        alert_overlap = "El rango no puede incluir una fecha reservada";
        break;
      }
    }
    for (let k = 0; k < events.length; k++) {
      const range_b = moment().range(
        moment(events[k].start)
          .startOf("day")
          .format(),
        moment(events[k].end)
          .endOf("day")
          .format()
      );
      if (
        (range_b.contains(moment(start)) || range_b.contains(moment(end))) &&
        events[k].index != index &&
        events[k].object != object
      ) {
        alert_overlap = "El rango entra en conflicto con otro evento";
        break;
      }
    }

    if (!is_disabled) {
      let min_date = moment(rental.date_until);
      let max_date = moment(rental.date_from);
      if (hasBookings) {
        for (let booking of affected_bookings) {
          if (
            moment(booking.date_from)
              .startOf("day")
              .diff(moment(min_date)) < 0
          ) {
            min_date = moment(booking.date_from).startOf("day");
          }
          if (
            moment(booking.date_until)
              .endOf("day")
              .diff(moment(max_date)) > 0
          ) {
            max_date = moment(booking.date_until).endOf("day");
          }
        }
        const moment_start = moment(start);
        const moment_end = moment(end);
        const diff_start = min_date.diff(moment_start, "s");
        const diff_end = max_date.diff(moment_end, "s");
        if (diff_start < 0 || diff_end > 0) {
          alert_overlap = "Al existir reservas, el rango debe incluirlas";
        }
      }
    }

    const btn_delete =
      typeof index === "number" && object ? (
        <button
          className="btn btn-appcar-secondary pt-2 pb-2 px-4 float-right"
          type="button"
          onClick={e => this.handleConfirm(e, this.state.event)}
        >
          Eliminar
        </button>
      ) : null;
    return (
      <React.Fragment>
        <h4 className="mb-4">Detalles de la publicación</h4>
        <div className="row">
          <div className="col-12">
            <CustomCheckBox
              id="is_disabled"
              name="is_disabled"
              disabled={hasBookings}
              checked={is_disabled}
              handleChange={e =>
                this.handleChange("is_disabled", e.target.checked)
              }
              label="Bloquear reserva para este rango de fechas"
            />
          </div>
          <div
            className="col-12"
          >
            <DatePicker
              id="date_start"
              label="Inicio del rango"
              value={start_date}
              onChange={e => this.handleChange("start", e.target.value)}
              name="date_start"
              disabled={true}
              error={alert_overlap}
            />
          </div>
          <div
            className="col-12"
          >
            <DatePicker
              id="date_end"
              label="Final del rango"
              value={end_date}
              onChange={e => this.handleChange("end", e.target.value)}
              name="date_end"
              disabled={true}
              error={alert_overlap}
            />
          </div>
          <div className="col-12">
            <div className="form-group my-2">
              <label>Motivo</label>
              <textarea
                className="form-control appcar-input"
                rows="4"
                value={reason}
                onChange={e => this.handleChange("reason", e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <button
              className="btn btn-appcar-primary pt-2 pb-2 px-4 float-left mr-2"
              type="button"
              onClick={e => this.handleSubmit(e, this.state.event)}
              disabled={!!alert_overlap}
            >
              Guardar
            </button>
            {btn_delete}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default WorkspaceForm;
