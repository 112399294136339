import React, { Component } from "react";
import RentalsWizard from "../../appcar/rentals/form/RentalsWizard";

export default class RentalsEdit extends Component {
  redirectToView = () => {
    window.location.assign(`/backoffice/vehicles/${this.props.vehicle.id}`);
  };
  render() {
    const owner = this.props.owner;
    const ownerName = owner.name + " " + owner.last_name;
    const rental = this.props.rental;
    return (
      <>
        <RentalsWizard
          vehicle={this.props.vehicle}
          rental={rental}
          extras={this.props.extras}
          formAction={this.redirectToView}
          title={`Editar la publicación de ${ownerName}`}
          activeStep={this.props.activeStep}
          saveLink={`/backoffice/`}
          editVehicleLink={`/backoffice/vehicles/${this.props.vehicle.id}`}
          recommendedPrice={this.props.recommendedPrice}
          appraisal={this.props.appraisal}
          vehicleTypes={this.props.vehicleTypes}
          vehicleCategories={this.props.vehicleCategories}
          fuelTypes={this.props.fuelTypes}
          vehicleTransmissions={this.props.vehicleTransmissions}
          vehicleFeatures={this.props.vehicleFeatures}
        />
      </>
    );
  }
}