import React from "react";

const ModalHeader = ({ id, title, closeable, className }) => {
  const closeButton = closeable ?
    (
      <button
        type="button"
        className="close"
        data-bs-dismiss="modal"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    ) : null;
  return (
    <div className={"modal-header " + className}>
      <h4 className="modal-title" id={id}>
        {title}
      </h4>
      {closeButton}
    </div>
  )
};

export default ModalHeader;