import React, { Component } from "react";
import { GoogleMap, withGoogleMap, Marker } from "react-google-maps";
import RentalMarker from "./RentalMarker";
import styles from "./AppcarStyles.json";
import iconNoImage from "images/Icons/NoImageHorizontal.svg";

const MapComponent = withGoogleMap((props) => (
  <GoogleMap
    defaultZoom={11}
    defaultCenter={{ lat: -33.4488897, lng: -70.6692655 }}
    isMarkerShown={props.isMarkerShown}
    loadingElement={props.loadingElement}
    googleMapURL={props.googleMapURL}
    containerElement={props.containerElement}
    mapElement={props.mapElement}
    ref={props.onMapMounted}
    {...props}
    onClick={props.onMapClick}
    options={{
      maxZoom: 18,
      mapTypeControl: false,
      zoomControlOptions: {
        position: google.maps.ControlPosition.TOP_LEFT,
      },
      disablePanMomentum: true,
      styles: styles,
      fullscreenControl: false,
      streetViewControl: false,
    }}
    onDragEnd={props.onDragEnd}
    onDragStart={props.onDragStart}
    onZoomChanged={props.onZoomChanged}
    disablePanMomentum={true}
    onBoundsChanged={() => props.onBoundsChanged()}
  >
    {props.places}
  </GoogleMap>
));

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: 12,
      rental_id: null,
      latLng: {
        lat: -33.4488897,
        lng: -70.6692655,
      },
      error: false,
      eventHasClosedInfoWindow: false,
      location: "",
    };
    this._map = null;
  }

  mapRef = React.createRef();

  handleMapMounted = (map) => {
    this._map = map;
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.rentals != this.props.rentals &&
      // && this.props.rentals.length > 0
      this.props.shouldUpdate
    ) {
      let bounds = new google.maps.LatLngBounds(null);
      this.props.rentals.forEach((rental) => {
        bounds.extend(
          new google.maps.LatLng(
            rental.location.latitude,
            rental.location.longitude
          )
        );
      });
      bounds.extend(
        new google.maps.LatLng(
          this.props.searchedLatLong.lat,
          this.props.searchedLatLong.lng
        )
      );
      if (
        !!bounds.getNorthEast() &&
        !!bounds.getSouthWest() &&
        !this.state.error
      ) {
        if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
          const extendPoint1 = new google.maps.LatLng(
            bounds.getNorthEast().lat() + 0.02,
            bounds.getNorthEast().lng() + 0.02
          );
          const extendPoint2 = new google.maps.LatLng(
            bounds.getNorthEast().lat() - 0.02,
            bounds.getNorthEast().lng() - 0.02
          );
          bounds.extend(extendPoint1);
          bounds.extend(extendPoint2);
        }
        this._map.fitBounds(bounds, 0);
        this._map.panToBounds(bounds);
        const currentZoom = this._map.getZoom();
        this.setState({ zoom: currentZoom + 0.4 }, () =>
          this.props.reportChange()
        );
      }
    }

    if (
      prevProps.customLatLng != this.props.customLatLng &&
      this.props.customLatLng
    ) {
      const zoom = this.props.customZoom
        ? this.props.customZoom
        : this.state.zoom;
      this.setState(
        {
          latLng: this.props.customLatLng,
          zoom,
          rental_id: this.props.selectedRental,
        },
        () => {
          this.props.reportChange();
        }
      );
    }
  }

  markerClick = (location, rental_id) => {
    this.setState(
      (state) => {
        if (rental_id) return { location, rental_id };
        else return { rental_id: null, location: null };
      },
      () => this.props.pinClick()
    );
  };

  onMapClick = () => {
    this.setState((state) => {
      state.rental_id = null;
      return { rental_id: state.rental_id };
    });
  };

  onDragEnd = () => {
    if (!this.state.eventHasClosedInfoWindow) {
      const latLngBounds = this._map.getBounds();
      // const lastNWBorderBounds = `${latLngBounds.getSouthWest().lat()},${latLngBounds.getNorthEast().lng()}`;
      // const lastSEBorderBounds = `${latLngBounds.getNorthEast().lat()},${latLngBounds.getSouthWest().lng()}`;
      // this.props.updateBounds({ lastNWBorderBounds, lastSEBorderBounds});
      const newRadius = this.getRadius(latLngBounds);
      const center = this._map.getCenter();
      // const mapLatLng = { lat: center.lat(), lng: center.lng() };
      if (newRadius) {
        this.props.updateRadius({
          radius: newRadius,
          lat: center.lat(),
          lng: center.lng(),
        });
      }
    }
  };

  onDragStart = () => {
    if (this.state.rental_id) {
      this.setState({ rental_id: null, eventHasClosedInfoWindow: true });
    } else {
      this.setState({ eventHasClosedInfoWindow: false });
    }
  };

  onZoomChanged = (e) => {
    if (this.props.shouldUpdate) return;
    const latLngBounds = this._map.getBounds();
    // const lastNWBorderBounds = `${latLngBounds.getSouthWest().lat()},${latLngBounds.getNorthEast().lng()}`;
    // const lastSEBorderBounds = `${latLngBounds.getNorthEast().lat()},${latLngBounds.getSouthWest().lng()}`;
    // this.props.updateBounds({ lastNWBorderBounds, lastSEBorderBounds});
    const newRadius = this.getRadius(latLngBounds);
    const center = this._map.getCenter();
    // const mapLatLng = { lat: center.lat(), lng: center.lng() };
    if (newRadius) {
      this.props.updateRadius({
        radius: newRadius,
        lat: center.lat(),
        lng: center.lng(),
      });
    }
  };

  onBoundsChanged = (e) => {
    if (
      this._map.getDiv().firstChild.clientHeight === window.innerHeight &&
      this._map.getDiv().firstChild.clientWidth === window.innerWidth
    ) {
      // this.onZoomChanged();
    }
  };

  getRadius = (latLngBounds) => {
    try {
      const earthRadius = 6371e3;

      // Get points
      const northEast = latLngBounds.getNorthEast();
      const southWest = latLngBounds.getSouthWest();
      const southEast = new google.maps.LatLng({
        lat: southWest.lat(),
        lng: northEast.lng(),
      });

      // Get lat and lng
      const lat1 = southEast.lat();
      const lat2 = southWest.lat();
      const lng1 = southEast.lng();
      const lng2 = southWest.lng();

      // lat in radians
      const latInRadians1 = (lat1 * Math.PI) / 180;
      const latInRadians2 = (lat2 * Math.PI) / 180;

      // deltas
      const deltaLatitude = ((lat2 - lat1) * Math.PI) / 180;
      const deltaLongitude = ((lng2 - lng1) * Math.PI) / 180;

      const chordLength =
        Math.sin(deltaLatitude / 2) * Math.sin(deltaLatitude / 2) +
        Math.cos(latInRadians1) *
          Math.cos(latInRadians2) *
          Math.sin(deltaLongitude / 2) *
          Math.sin(deltaLongitude / 2);
      const angularDistance =
        2 * Math.atan2(Math.sqrt(chordLength), Math.sqrt(1 - chordLength));

      // radius in kilometers
      return (earthRadius * angularDistance) / 2000;
    } catch (e) {
      return false;
    }
  };

  render() {
    const { zoom, latLng, rental_id, location } = this.state;
    const {
      dayOfWeek,
      searchedLatLong,
      originalRentals,
      isMobile,
      showMobileMap,
    } = this.props;
    let mapContainerClass = "map-container ";
    if (isMobile && !showMobileMap) mapContainerClass += "invisible";
    let places = this.props.rentals.map((rental, i) => (
      <RentalMarker
        key={i}
        lat={rental.location.latitude}
        lng={rental.location.longitude}
        rental={rental}
        photoUrl={
          Array.isArray(rental.vehicle.vehicle_pictures) &&
          typeof rental.vehicle.vehicle_pictures[0] == "string"
            ? rental.vehicle.vehicle_pictures[0].toString() + "/thumb"
            : iconNoImage
        }
        id={rental.id}
        markerClick={this.markerClick}
        showInfo={
          // rental.id == rental_id &&
          // latLng.lat == rental.location.latitude &&
          // latLng.lng == rental.location.longitude
          rental.id == rental_id && location == rental.location.name
        }
        closeMarker={this.onMapClick}
        mapNotLoaded={this.state.error}
        visitLink={() =>
          this.props.visitLink(
            rental,
            originalRentals
              .map((x, i) => ({ rental: x, position: i + 1 }))
              .find((x) => x.rental.id == rental.id).position,
            rental.current_location[0].name
          )
        }
        hasImage={
          Array.isArray(rental.vehicle.vehicle_pictures) &&
          typeof rental.vehicle.vehicle_pictures[0] == "string"
        }
        highlighted={this.props.highlightedRental == rental.id}
        dayOfWeek={dayOfWeek}
        openModalPricing={() =>
          this.props.openModalPricing(
            rental.pick_up_price ? rental.current_location[0].name : "",
            rental.id
          )
        }
      />
    ));
    places.push(
      <Marker
        position={{ lat: searchedLatLong.lat, lng: searchedLatLong.lng }}
        key="searchmarker"
        icon={{
          url: require("images/Pin.png"),
          scaledSize: new google.maps.Size(37, 37),
          anchor: new google.maps.Point(0, 37),
        }}
      />
    );
    return (
      <MapComponent
        isMarkerShown
        loadingElement={<div style={{ height: "100%" }} />}
        containerElement={<div className={mapContainerClass} />}
        mapElement={<div style={{ height: "100%" }} />}
        places={places}
        zoom={zoom}
        center={latLng}
        onMapMounted={this.handleMapMounted}
        onMapClick={this.onMapClick}
        onDragEnd={this.onDragEnd}
        onDragStart={this.onDragStart}
        onZoomChanged={this.onZoomChanged}
        onBoundsChanged={this.onBoundsChanged}
      />
    );
  }
}

export default Map;
