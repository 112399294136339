import React, { Component, Fragment } from "react";
import { Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import Spinner from "../../common/Spinner";
import Alert from "../../common/components/alert/Alert";
import axios from "axios";
import { AxiosApplicationInterceptor } from "../../common/axios_interceptor";
import {
  formateaRut,
  generateFilename,
  validateRUT,
  constants,
} from "../../common/constants";
import SelfieLoader from "../../common/components/image_loader/SelfieLoader";
import SelectWithRadio from "../../common/components/select/SelectWithRadio";
import { cloneDeep, deburr } from "lodash";
import { InputFeedback } from "../../common/CustomInputs";
import moment from "moment";
axios.interceptors.response.use(undefined, AxiosApplicationInterceptor);

Yup.addMethod(Yup.array, "unique", function(message, mapper = (a) => a) {
  return this.test("unique", message, function(list) {
    return list.length === new Set(list.map(mapper)).size;
  });
});

const Schema = ({ isEditing, needInvoice }) =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("Campo requerido"),
    last_name: Yup.string()
      .trim()
      .required("Campo requerido"),
    email: Yup.string()
      .trim()
      .email("Debe ser formato e-mail")
      .required("Campo requerido"),
    document_type: Yup.object().shape({
      description: Yup.string().required("Campo requerido"),
    }),
    id_number: Yup.string().when("document_type.description", {
      is: "RUT",
      then: Yup.string()
        .matches(/^0*(\d{1,3}(\.?\d{3})*)\-?([\dkK])$/, "RUT inválido")
        .required("RUT inválido"),
      otherwise: Yup.string().when("document_type.description", {
        is: "Pasaporte",
        then: Yup.string().required("Campo requerido"),
        otherwise: Yup.string(),
      }),
    }),
    birth_date: Yup.string(),
    phone_number: Yup.string(),
    country: Yup.object().shape({
      description: Yup.string(),
    }),
    gender: Yup.object().shape({
      description: Yup.string(),
    }),
    billing_info: Yup.object().shape({
      rut: needInvoice
        ? Yup.string()
            .matches(/^0*(\d{1,3}(\.?\d{3})*)\-?([\dkK])$/, "RUT inválido")
            .required("RUT inválido")
        : Yup.string(),
      name: needInvoice
        ? Yup.string().required("Campo requerido")
        : Yup.string(),
      address: needInvoice
        ? Yup.string().required("Campo requerido")
        : Yup.string(),
      city: needInvoice
        ? Yup.string().required("Campo requerido")
        : Yup.string(),
      municipality: needInvoice
        ? Yup.string().required("Campo requerido")
        : Yup.string(),
      phone: needInvoice
        ? Yup.string().required("Campo requerido")
        : Yup.string(),
      email: needInvoice
        ? Yup.string().required("Campo requerido")
        : Yup.string(),
      business: needInvoice
        ? Yup.string().required("Campo requerido")
        : Yup.string(),
    }),
  });

export default class UserForm extends Component {
  state = {
    data: {
      name: this.props.user.name,
      last_name: this.props.user.last_name,
      email: this.props.user.email,
      country: this.props.user.country || undefined,
      nickname: this.props.user.nickname || "",
      document_type: this.props.user.document_type || undefined,
      id_number: this.props.user.id_number || "",
      birth_date: this.props.user.birth_date
        ? moment(this.props.user.birth_date, "YYYY-MM-DD").format("DD/MM/YYYY")
        : moment()
            .add(-18, "y")
            .format("DD/MM/YYYY"),
      phone_number: this.props.user.phone_number || "",
      gender: this.props.user.gender || undefined,
      photo: {
        filename: this.props.user.photo.filename || "",
        start: this.props.user.photo.start || "",
        image_base_64: this.props.user.photo.image_base_64 || "",
        mime_type: this.props.user.photo.mime_type || "",
        url: this.props.user.photo.url || "",
      },
      bank_info: this.props.user.bank_info,
      billing_info: this.props.user.billing_info || {
        rut: "",
        name: "",
        address: "",
        city: "",
        municipality: "",
        phone: "",
        business: "",
        email: "",
      },
      document_pictures: this.props.user.document_pictures,
      driver_license: this.props.user.driver_license,
    },
    needInvoice: false,
    isSubmitting: false,
    errorIdNumber: "",
    alert: {
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined,
    },
  };

  componentDidMount() {
    const this_ = this;
    let options = {
      showDropdowns: true,
      maxYear: moment()
        .add(-18, "y")
        .year(),
      startDate: moment().add(-18, "y"),
      endDate: moment().add(-18, "y"),
      singleDatePicker: true,
      minDate: moment("01/01/1900", "DD/MM/YYYY"),
      locale: {
        format: "DD/MM/YYYY",
        cancelLabel: "Cancelar",
        applyLabel: "Aplicar",
        fromLabel: "Desde",
        toLabel: "Hasta",
        daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        firstDay: 1,
      },
    };

    if (this_.props.user.birth_date) {
      options.startDate = moment(
        this_.props.user.birth_date,
        "YYYY-MM-DD"
      ).format("DD/MM/YYYY");
      options.endDate = moment(
        this_.props.user.birth_date,
        "YYYY-MM-DD"
      ).format("DD/MM/YYYY");
    }

    $("#birth_date").daterangepicker(options, (start) => {
      this_.setState((state) => {
        state.data.birth_date = moment(start).format("DD/MM/YYYY");
        return { data: state.data };
      });
    });

    const needInvoice = !!this.props.user?.billing_info?.rut;
    this.setState({ needInvoice });
  }

  openFileDialog = () => {
    document.getElementById("image_uploader").click();
  };

  addPicture = (e) => {
    let file = e.target.files;
    if (file.length > 0) {
      const filename = generateFilename(file[0].name);
      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onloadend = function(e) {
        var base64result = reader.result;
        this.addPhoto(base64result, filename);
      }.bind(this);
    }
  };

  addPhoto = (picture, filename) => {
    const file = picture.split(",");
    const start = file[0];
    const base64 = file[1];
    const mime = picture.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    const mimeType = mime && mime.length ? mime[1] : "";
    filename = encodeURI(filename);
    this.setState((state) => {
      state.data.photo.filename = filename;
      state.data.photo.start = start;
      state.data.photo.image_base_64 = base64;
      state.data.photo.mime_type = mimeType;
      return { data: state.data };
    });
  };

  removePhoto = () =>
    this.setState((state) => {
      state.data.photo = {
        filename: "",
        start: "",
        image_base_64: "",
        mime_type: "",
        url: "",
      };
      return { data: state.data };
    });

  onChange = (key, value) =>
    this.setState((state) => {
      state.data[key] = value;
      return { data: state.data };
    });

  onChangeBilling = (key, value) =>
    this.setState((state) => {
      state.data.billing_info[key] = value;
      return { data: state.data };
    });

  handleChangeIdNumber = (value) => {
    this.setState((state) => {
      state.data.id_number = value;
      return { data: state.data };
    });
  };

  handleBlurIdNumber = () => {
    const idNumber = this.state.data.id_number;
    const documentType = this.state.data.document_type;

    if (documentType && documentType.id == 1) {
      if (validateRUT(idNumber)) {
        const formatedRut = formateaRut(idNumber);
        this.setState((state) => {
          state.data.id_number = formatedRut;
          return { data: state.data, errorIdNumber: "" };
        });
      } else {
        this.setState({ errorIdNumber: "El RUT es inválido" });
      }
    }
  };

  showMessage = (messageType, messageTitle, messages) =>
    this.setState(
      (state) => {
        state.alert.showMessage = true;
        state.alert.messageTitle = messageTitle;
        state.alert.messages = messages;
        state.alert.messageType = messageType;
        return { alert: state.alert };
      },
      () => setTimeout(this.handleAlertClose, 3000)
    );

  handleAlertClose = () =>
    this.setState((state) => {
      state.alert.showMessage = false;
      return { alert: state.alert };
    });

  handleCopy = () => {
    this.setState((state) => {
      const data = this.state.data;
      state.data.billing_info.rut = data.id_number;
      state.data.billing_info.name = `${data.name} ${data.last_name}`;
      state.data.billing_info.phone = data.phone_number;
      state.data.billing_info.email = data.email;
      return { data: state.data };
    });
  };

  generateNickname = (name, last_name) => {
    let temp_name = name.normalize("NFD").replace(/[\u0300-\u036f´`^¨¨']/g, "");
    let temp_last_name = last_name
      .normalize("NFD")
      .replace(/[\u0300-\u036f´`^¨¨']/g, "");
    return deburr(
      temp_name.split(" ")[0].toUpperCase() +
        temp_last_name.charAt(0).toUpperCase()
    ).latinise();
  };

  render() {
    const { alert, needInvoice, errorIdNumber } = this.state;

    let data = this.state.data;

    const { image_base_64, start, url } = data.photo;

    const { messageTitle, messageType, messages, showMessage } = alert;

    const { documentTypes, banks, countries, genders } = this.props;

    let shouldStopSpinner = false;
    const advice = this.props.isOwner ? null : (
      <div className="photo-advice">
        <div className="icon">
          <img src="/IconAppcar.png" />
        </div>
        <div className="advice">
          Recuerda que únicamente el Driver que solicita la reserva puede
          retirar el auto
        </div>
      </div>
    );
    const needInvoiceMessage = `Soy ${
      this.props.isOwner ? "Owner" : "Driver"
    } empresa y necesito factura`;
    return (
      <Fragment>
        <div className="edit-box">
          <Alert
            alertClass={messageType}
            isShowing={showMessage}
            messages={messages}
            title={messageTitle}
            onClose={this.handleAlertClose}
          />
          <div className="photo-container">
            <div className="image-loader">
              <h2>Completa tu registro</h2>
              <h3>Parte 1 de 2</h3>
              <SelfieLoader
                addImage={this.openFileDialog}
                removeImage={this.removePhoto}
                imageBase64={image_base_64}
                start={start}
                url={url}
              />
              <input
                type="file"
                id="image_uploader"
                className="d-none"
                onChange={(e) => this.addPicture(e)}
                accept="image/jpeg, image/png, image/jpg"
                onClick={(e) => {
                  e.target.value = null;
                }}
              />
            </div>
            {advice}
          </div>
          <div className="form">
            <Formik
              showMessage={this.showMessage}
              enableReinitialize
              initialValues={this.state.data}
              validationSchema={Schema}
              onSubmit={(values, { setSubmitting }) => {
                const data_ = cloneDeep(data);
                data_.nickname = this.generateNickname(
                  data_.name,
                  data_.last_name
                );
                data_.birth_date = moment(
                  data_.birth_date,
                  "DD/MM/YYYY"
                ).format("YYYY-MM-DD");
                axios({
                  method: "PUT",
                  url: `/owners/${this.props.user.id}.json`,
                  responseType: "json",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  data: { user: data_ },
                })
                  .then((response) => {
                    if (response.data.success) {
                      const rTo = this.props.r_to
                        ? `&r_to=${this.props.r_to}`
                        : "";
                      const redirectURL = this.props.isOwner
                        ? `/owners/${this.props.user.id}/bank_data?redirectedfrom=edit_owner${rTo}`
                        : `/owners/${this.props.user.id}/edit_driver_documents${rTo}`;
                      window.location.assign(redirectURL);
                    } else {
                      this.showMessage(
                        "primary",
                        "Alerta",
                        response.data.messages || [
                          "Ha ocurrido un error inesperado",
                        ]
                      );
                      shouldStopSpinner = true;
                    }
                  })
                  .catch((error) => {
                    this.showMessage("primary", "Error", [
                      "Ha ocurrido un error inesperado",
                    ]);
                    shouldStopSpinner = true;
                  })
                  .then(() => shouldStopSpinner && setSubmitting(false));
              }}
            >
              {({
                values,
                handleBlur,
                handleSubmit,
                isSubmitting,
                handleChange,
              }) => {
                const gendersOptions = genders.map((g) => ({
                  label: g.description,
                  value: g.id,
                }));
                const documentTypesOptions = documentTypes.map((d) => ({
                  label: d.description,
                  value: d.id,
                }));

                const selectedGender = {
                  value: values.gender && values.gender.id,
                  label: values.gender && values.gender.description,
                };
                const selectedDocument = {
                  value: values.document_type && values.document_type.id,
                  label:
                    values.document_type && values.document_type.description,
                };

                const copyValues = () => {
                  values.billing_info.rut = values.id_number;
                  values.billing_info.name = `${values.name} ${values.last_name}`;
                  values.billing_info.phone = values.phone_number;
                  values.billing_info.email = values.email;
                  this.handleCopy();
                };
                const billingData = needInvoice ? (
                  <div className="billing-data">
                    <h3 className="billing-data-title">Datos de facturación</h3>
                    <div
                      className="copy-billing-data"
                      onClick={() => copyValues()}
                    >
                      <i className="mdi mdi-content-copy" />{" "}
                      <span className="text-decoration-underline">
                        Copiar desde datos personales
                      </span>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="billing_info.rut"
                        className="label-inside"
                      >
                        RUT
                      </label>
                      <Field
                        type="text"
                        name="billing_info.rut"
                        className="form-control appcar-input"
                        value={data.billing_info.rut}
                        onChange={(e) => {
                          handleChange(e);
                          this.onChangeBilling("rut", e.currentTarget.value);
                        }}
                      />
                      <ErrorMessage name="billing_info.rut">
                        {(msg) => (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="billing_info.name"
                        className="label-inside"
                      >
                        Razón social
                      </label>
                      <Field
                        type="text"
                        name="billing_info.name"
                        className="form-control appcar-input"
                        value={data.billing_info.name}
                        onChange={(e) => {
                          handleChange(e);
                          this.onChangeBilling("name", e.currentTarget.value);
                        }}
                      />
                      <ErrorMessage name="billing_info.name">
                        {(msg) => (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="billing_info.business"
                        className="label-inside"
                      >
                        Giro SII
                      </label>
                      <Field
                        type="text"
                        name="billing_info.business"
                        className="form-control appcar-input"
                        value={data.billing_info.business}
                        onChange={(e) => {
                          handleChange(e);
                          this.onChangeBilling(
                            "business",
                            e.currentTarget.value
                          );
                        }}
                      />
                      <ErrorMessage name="billing_info.business">
                        {(msg) => (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="billing_info.address"
                        className="label-inside"
                      >
                        Dirección
                      </label>
                      <Field
                        type="text"
                        name="billing_info.address"
                        className="form-control appcar-input"
                        value={data.billing_info.address}
                        onChange={(e) => {
                          handleChange(e);
                          this.onChangeBilling(
                            "address",
                            e.currentTarget.value
                          );
                        }}
                      />
                      <ErrorMessage name="billing_info.address">
                        {(msg) => (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="billing_info.phone"
                        className="label-inside"
                      >
                        Teléfono
                      </label>
                      <Field
                        type="text"
                        name="billing_info.phone"
                        className="form-control appcar-input"
                        value={data.billing_info.phone}
                        onChange={(e) => {
                          handleChange(e);
                          this.onChangeBilling("phone", e.currentTarget.value);
                        }}
                      />
                      <ErrorMessage name="billing_info.phone">
                        {(msg) => (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="billing_info.email"
                        className="label-inside"
                      >
                        Email
                      </label>
                      <Field
                        type="text"
                        name="billing_info.email"
                        className="form-control appcar-input"
                        value={data.billing_info.email}
                        onChange={(e) => {
                          handleChange(e);
                          this.onChangeBilling("email", e.currentTarget.value);
                        }}
                      />
                      <ErrorMessage name="billing_info.email">
                        {(msg) => (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="billing_info.city"
                        className="label-inside"
                      >
                        Ciudad
                      </label>
                      <Field
                        type="text"
                        name="billing_info.city"
                        className="form-control appcar-input"
                        value={data.billing_info.city}
                        onChange={(e) => {
                          handleChange(e);
                          this.onChangeBilling("city", e.currentTarget.value);
                        }}
                      />
                      <ErrorMessage name="billing_info.city">
                        {(msg) => (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="billing_info.municipality"
                        className="label-inside"
                      >
                        Comuna
                      </label>
                      <Field
                        type="text"
                        name="billing_info.municipality"
                        className="form-control appcar-input"
                        value={data.billing_info.municipality}
                        onChange={(e) => {
                          handleChange(e);
                          this.onChangeBilling(
                            "municipality",
                            e.currentTarget.value
                          );
                        }}
                      />
                      <ErrorMessage name="billing_info.municipality">
                        {(msg) => (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>
                ) : null;
                const needInvoiceContainer = (
                  <div className="form-group">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="needInvoice"
                        name="needInvoice"
                        checked={needInvoice}
                        onChange={(e) =>
                          this.setState({ needInvoice: e.target.checked })
                        }
                      />
                      <label
                        className="form-check-label text-black"
                        htmlFor="needInvoice"
                      >
                        {needInvoiceMessage}
                      </label>
                    </div>
                  </div>
                );
                return (
                  <Fragment>
                    <Spinner
                      loading={isSubmitting || this.state.isSubmitting}
                    />
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="name" className="label-inside">
                          Tu nombre
                        </label>
                        <Field
                          type="text"
                          name="name"
                          className="form-control appcar-input"
                          value={data.name}
                          required
                          onChange={(e) => {
                            handleChange(e);
                            this.onChange("name", e.currentTarget.value);
                          }}
                        />
                        <ErrorMessage name="name">
                          {(msg) => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>

                      <div className="form-group">
                        <label htmlFor="last_name" className="label-inside">
                          Tus apellidos
                        </label>
                        <Field
                          type="text"
                          name="last_name"
                          className="form-control appcar-input"
                          value={data.last_name}
                          required
                          onChange={(e) => {
                            handleChange(e);
                            this.onChange("last_name", e.currentTarget.value);
                          }}
                        />
                        <ErrorMessage name="last_name">
                          {(msg) => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>

                      <div className="form-group">
                        <label htmlFor="email" className="label-inside">
                          Tu email
                        </label>
                        <Field
                          type="email"
                          name="email"
                          className="form-control appcar-input"
                          value={data.email}
                          required
                          onChange={(e) => {
                            handleChange(e);
                            this.onChange("email", e.currentTarget.value);
                          }}
                        />
                        <ErrorMessage name="email">
                          {(msg) => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>

                      <div className="form-group">
                        <label htmlFor="phone_number" className="label-inside">
                          Teléfono
                        </label>
                        <Field
                          type="text"
                          name="phone_number"
                          className="form-control appcar-input"
                          value={data.phone_number}
                          required
                          onChange={(e) => {
                            handleChange(e);
                            this.onChange(
                              "phone_number",
                              e.currentTarget.value
                            );
                          }}
                        />
                        <ErrorMessage name="phone_number">
                          {(msg) => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>

                      <div className="form-group">
                        <label htmlFor="document_type" className="label-inside">
                          Tipo de documento
                        </label>
                        <SelectWithRadio
                          value={selectedDocument}
                          options={documentTypesOptions}
                          placeholder="Selecciona"
                          noOptionsMessage={() => "No hay tipos de documento"}
                          onChange={(opt) => {
                            this.onChange("document_type", {
                              id: opt.value,
                              description: opt.label,
                            });
                          }}
                          classNamePrefix="RS-FIX"
                          className="react-select-fix appcar-input"
                        />
                        <ErrorMessage name="document_type.description">
                          {(msg) => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>

                      <div className="form-group">
                        <label htmlFor="id_number" className="label-inside">
                          Número de{" "}
                          {values.document_type?.description === constants.RUT
                            ? "RUT"
                            : "documento"}
                        </label>
                        <input
                          type="text"
                          name="id_number"
                          className="form-control appcar-input"
                          onBlur={(e) => {
                            handleBlur(e);
                            this.handleBlurIdNumber();
                          }}
                          required
                          onChange={(e) =>
                            this.handleChangeIdNumber(e.target.value)
                          }
                          value={data.id_number}
                        />
                        <InputFeedback name="id_number" error={errorIdNumber} />
                      </div>

                      <div className="form-group">
                        <label htmlFor="birth_date" className="label-inside">
                          Fecha de nacimiento
                        </label>
                        <input
                          type="text"
                          readOnly
                          name="birth_date"
                          id="birth_date"
                          className="form-control appcar-input"
                          value={data.birth_date}
                          required
                          onChange={(e) => {
                            handleChange(e);
                            this.onChange("birth_date", e.currentTarget.value);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                        <ErrorMessage name="birth_date">
                          {(msg) => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>

                      <div className="form-group">
                        <label htmlFor="gender" className="label-inside">
                          Género
                        </label>
                        <SelectWithRadio
                          value={selectedGender}
                          options={gendersOptions}
                          placeholder="Selecciona"
                          noOptionsMessage={() => "No hay géneros"}
                          onChange={(opt) =>
                            this.onChange("gender", {
                              id: opt.value,
                              description: opt.label,
                            })
                          }
                          classNamePrefix="RS-FIX"
                          className="react-select-fix appcar-input"
                        />
                        <ErrorMessage name="gender.description">
                          {(msg) => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                      {/* {needInvoiceContainer} */}
                      {/* {billingData} */}
                      <div className="text-center my-2">
                        <button
                          type="submit"
                          className="btn btn-appcar-primary"
                          disabled={isSubmitting || errorIdNumber}
                        >
                          Guardar y continuar
                        </button>
                      </div>
                    </form>
                  </Fragment>
                );
              }}
            </Formik>
          </div>
        </div>
      </Fragment>
    );
  }
}
