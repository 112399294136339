import React from "react";
import iconInfo from "images/Icons/ico-info.png";
import ToolTip, { LoadToolTips } from "../../../common/components/ToolTip";
import { constants } from "../../../common/constants";

const PoliciesSummary = ({ rental, dayOfWeek }) => {
  LoadToolTips();

  const vehicle = rental.vehicle;

  const cancellationPolicy = vehicle.cancellation_policy;
  const fuelPolicy = vehicle.fuel_policy;

  let tooltipCancellationTitle = "";
  let tooltipCancellationContent = "";
  let tooltipFuelTitle = "";
  let tooltipFuelContent = "";

  if (cancellationPolicy && cancellationPolicy.name) {
    tooltipCancellationTitle = cancellationPolicy.name;
    tooltipCancellationContent = `<ul>${cancellationPolicy.rules
      .map((rule) => `<li>${rule.description}</li>`)
      .join("")}</ul>`;
  } else {
    tooltipCancellationTitle = "Política de cancelación";
    tooltipCancellationContent = "El vehículo no tiene política de cancelación";
  }

  if (fuelPolicy && fuelPolicy.name) {
    tooltipFuelTitle = fuelPolicy.name;
    tooltipFuelContent = fuelPolicy.driver_description;
  } else {
    tooltipFuelTitle = "Política de combustible";
    tooltipFuelContent = "El vehículo no tiene política de combustible";
  }

  const limited = !vehicle.unlimited_distance
  let minLength = 0;
  if (rental.availability && rental.availability.booking_length) {
    if (dayOfWeek == 5) {
      minLength =
        rental.availability.booking_length.minimum_length_saturday || 0;
    } else if (dayOfWeek == 6) {
      minLength = rental.availability.booking_length.minimum_length_sunday || 0;
    } else {
      minLength = rental.availability.booking_length.minimum_length || 0;
    }
  }

  let textMinLength = "No tiene mínimo";

  if (minLength > 0) {
    textMinLength = `${minLength} día${minLength > 1 ? "s" : ""}`;
  }

  return (
    <div className="policies-summary">
      <h5>Políticas del auto</h5>
      <div className="policies-container">
        <div className="row">
          <div className="col text-left fs-15 fw-400">Kilometraje diario</div>
          <div className="col-auto text-right fs-15 fw-700">
            {limited ? "Máx 250 Kms." : "Ilimitado"}
            <ToolTip
              title={limited ? "250 km por día" : "Ilimitado"}
              tooltipHTML={
                limited ?
                "Este auto tiene un máximo de 250 km por día, que son acumulables por los días que dure el arriendo. (Ej. si arriendas por 3 días, podrás andar 3 x 250 = 750 kms). <br> Si sobrepasas el límite de kilómetros permitido del auto, se cobrará un día de arriendo por cada 250 km adicionales o el proporcional." :
                "Este auto no tiene un máximo de kilómetros diarios."
              }
              containerHTML={<img src={iconInfo} width={14} className="ml-1" />}
              id="ttip_rental_max_km_"
            />
          </div>
        </div>
        <div className="row">
          <div className="col text-left fs-15 fw-400">Duración del arriendo</div>
          <div className="col-auto text-right fs-15 fw-700">
            {textMinLength}
            <ToolTip
              title="Duración del arriendo"
              tooltipHTML="Tiempo mínimo permitido para el arriendo."
              containerHTML={<img src={iconInfo} width={14} className="ml-1" />}
              id="ttip_rental_length"
            />
          </div>
        </div>
        <div className="row">
          <div className="col text-left fs-15 fw-400">Política de cancelación</div>
          <div className="col-auto text-right fs-15 fw-700">
            {(cancellationPolicy && cancellationPolicy.name) || "Sin política"}
            <ToolTip
              title={tooltipCancellationTitle}
              tooltipHTML={tooltipCancellationContent}
              containerHTML={<img src={iconInfo} width={14} className="ml-1" />}
              id="ttip_rental_cancel_policy"
            />
          </div>
        </div>
        <div className="row">
          <div className="col text-left fs-15 fw-400">Política de combustible</div>
          <div className="col-auto text-right fs-15 fw-700">
            {(fuelPolicy && fuelPolicy.name) || "Sin política"}
            <ToolTip
              title={tooltipFuelTitle}
              tooltipHTML={tooltipFuelContent}
              containerHTML={<img src={iconInfo} width={14} className="ml-1" />}
              id="ttip_rental_fuel_policy"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoliciesSummary;
