import React from "react";
import Calendar from "../../application/calendar/Calendar";

const CalendarContext = ({ vehicleId, vehicles, categories, ownerId }) => (
  <>
    <Calendar
      vehicleId={vehicleId}
      vehicles={vehicles}
      categories={categories}
      updateRentalLink={(rental_id, vehicle_id) =>
        `/backoffice/owners/${ownerId}/calendar/${rental_id}/update_rental.json?vehicle_id=${vehicle_id}`
      }
      getRentalDataLink={(vehicle_id) =>
        `/backoffice/owners/${ownerId}/calendar/${vehicle_id}/get_rental_data.json`
      }
      confirmBookingLink={(booking_id) => `/backoffice/bookings/${booking_id}/confirm.json?owner_id=${ownerId}`}
      cancelBookingLink={(booking_id) => `/backoffice/bookings/${booking_id}/owner_cancel.json?owner_id=${ownerId}`}
      canOfferNewCar={false}
    />
  </>
);

export default CalendarContext;
