import React from "react";
import PricingDetails from "../../../../common/components/pricing_details/PricingDetails";

const ModalPricing = ({ pricing, from, to }) => (
  <div
    className="modal fade"
    id="pricingDetailsModal"
    aria-hidden="true"
    aria-labelledby="pricingDetailsModalLabel"
    tabIndex={-1}
  >
    <div className="modal-dialog modal-dialog-centered modal-appcar modal-pricing">
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="btn-m-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            x
          </button>
          <h5 className="modal-title" id="pricingDetailsModalLabel">
            Detalle de precio
          </h5>
        </div>
        <div className="modal-body modal-body-less-padding">
          <div className="modal-dates-body">
            <PricingDetails
              price={pricing}
              dateFrom={from}
              dateUntil={to}
              showTitle={false}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ModalPricing;
