import Axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import Alert from "../../../common/components/alert/Alert";
import Spinner from "../../../common/Spinner";
import GetCodeForm from "./GetCodeForm";
import PasswordChangeForm from "./PasswordChangeForm";

const PasswordRecovery = ({
  propsEmail = null,
  updateEmail = null,
  canBack = false,
  onBack = null,
  callBack = null,
  modalRef = null
}) => {
  // Datos
  const [email, setEmail] = useState(propsEmail || "");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // Spinner, modals y alerta
  const [loading, setLoading] = useState(false);
  const [modalCode, setModalCode] = useState(false);
  const [modalRecovery, setModalRecovery] = useState(false);
  const modalCodeRef = modalRef || useRef();
  const modalRecoveryRef = useRef();

  const [alert, setAlert] = useState({
    showMessage: false,
    messageType: undefined,
    messages: [],
    messageTitle: undefined,
  });

  // Mostrar mensaje
  const showMessage = (messageType, messageTitle, messages) => {
    setAlert((prev) => {
      prev.showMessage = true;
      prev.messageType = messageType;
      prev.messageTitle = messageTitle;
      prev.messages = messages;
      return { ...prev };
    });
  };

  // Hide Alert & load modals

  const reloadInstanceModalCode = () => {
    setModalCode(bootstrap.Modal.getInstance(modalCodeRef.current));
  };
  const reloadInstanceModalRecovery = () =>
    setModalRecovery(bootstrap.Modal.getInstance(modalRecoveryRef.current));

  useEffect(() => {
    if (alert.showMessage) {
      setTimeout(closeAlert, 3000);
    }

    if (!modalCode && !modalRecovery) {
      const modalCode_ = new bootstrap.Modal(modalCodeRef.current);
      const modalRecovery_ = new bootstrap.Modal(modalRecoveryRef.current);
      setModalCode(modalCode_);
      setModalRecovery(modalRecovery_);
    }

    if (propsEmail) {
      setEmail(propsEmail);
    }
  }, [propsEmail]);

  // Close alert
  const closeAlert = () =>
    setAlert((prev) => {
      prev.showMessage = false;
      return { ...prev };
    });

  // Send code via email
  const getCode = () => {
    setLoading(true);
    Axios({
      method: "POST",
      url: `/owners/get_code.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: { email: email },
    })
      .then((response) => {
        if (response.data.success) {
          showMessage("primary", "Éxito", response.data.messages);
          modalRecovery.show();
        } else {
          showMessage(
            "primary",
            "Error",
            response.data.messages || ["Ha ocurrido un error inesperado"]
          );
        }
      })
      .catch((error) => {
        console.log(error);
        showMessage("primary", "Error", ["Ha ocurrido un error inesperado"]);
      })
      .finally(() => setLoading(false));
  };

  // Change password
  const changePassword = () => {
    setLoading(true);
    Axios({
      method: "POST",
      url: `/owners/recover.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email,
        code,
        new_password: newPassword,
        confirm_password: confirmPassword,
      },
    })
      .then((response) => {
        if (response.data.success) {
          showMessage("primary", "Éxito", response.data.messages);
          modalRecovery.hide();
          if (typeof callBack === "function") callBack();
        } else {
          showMessage(
            "primary",
            "Error",
            response.data.messages || ["Ha ocurrido un error inesperado"]
          );
        }
      })
      .catch((error) => {
        console.log(error);
        showMessage("primary", "Error", ["Ha ocurrido un error inesperado"]);
      })
      .finally(() => setLoading(false));
  };

  const showModalCode = () => {
    modalCode.show();
    modalRecovery.hide();
  };

  const showModalRecovery = () => {
    modalRecovery.show();
    modalCode.hide();
  };

  return (
    <>
      <Alert
        alertClass={alert.messageType}
        isShowing={alert.showMessage}
        messages={alert.messages}
        title={alert.messageTitle}
        onClose={closeAlert}
      />
      <Spinner loading={loading} />
      <div
        className="modal fade"
        id="passwordRecoveryModal"
        aria-hidden="true"
        aria-labelledby="passwordRecoveryModalLabel"
        tabIndex={-1}
        ref={modalCodeRef}
      >
        <GetCodeForm
          email={email}
          setEmail={(email) => {
            setEmail(email);
            if (typeof updateEmail === "function") updateEmail(email);
          }}
          getCode={getCode}
          showModalRecovery={() => {
            showModalRecovery();
          }}
          canBack={canBack}
          onBack={() => {
            onBack();
          }}
        />
      </div>
      <div
        className="modal fade"
        id="passwordChangeModal"
        aria-hidden="true"
        aria-labelledby="passwordChangeModalLabel"
        tabIndex={-1}
        ref={modalRecoveryRef}
      >
        <PasswordChangeForm
          email={email}
          setEmail={setEmail}
          code={code}
          setCode={setCode}
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          changePassword={changePassword}
          showModalCode={showModalCode}
        />
      </div>
    </>
  );
};

export default PasswordRecovery;
