import React, { Component } from "react";
import NewVehicleWizard from "./form/NewVehicleWizard";
import ThanksModalVehicle from "../../common/legal_modals/ThanksModalVehicle";

export default class VehiclesNew extends Component {
  modalThanks = null;
  componentDidMount() {
    const modalEl = document.getElementById("thanksModal");
    this.modalThanks = new bootstrap.Modal(modalEl, {
      backdrop: "static",
      keyboard: false,
    });
    modalEl.addEventListener("hidden.bs.modal", (ev) => {
      this.redirectToDashboard();
    });
  }
  openModal = () => {
    this.modalThanks.show();
  };

  redirectToDashboard = () => {
    window.location.assign(`/vehicles?success=1`);
  };
  render() {
    const modal = <ThanksModalVehicle />;
    return (
      <>
        <NewVehicleWizard
          fuelTypes={this.props.fuelTypes}
          vehicleTypes={this.props.vehicleTypes}
          vehicleFeatures={this.props.vehicleFeatures}
          vehicleCategories={this.props.vehicleCategories}
          vehicleTransmissions={this.props.vehicleTransmissions}
          formAction={this.openModal}
        />
        {modal}
      </>
    );
  }
}
