import React from "react";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import ClearIndicator from "./ClearIndicator";
import IndicatorsContainer from "./IndicatorsContainer";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div className="form-check">
          <input
            type="radio"
            className="form-check-input"
            id={`select_option_${props.value}`}
            name={`select_option_${props.value}`}
            checked={props.isSelected}
            onChange={() => null}
          />
          <label
            className={`form-check-label ${props.isSelected ? "fw-700" : ""}`}
            htmlFor={`select_option_${props.value}`}
          >
            {props.label}
          </label>
        </div>
      </components.Option>
    </div>
  );
};

const SelectWithRadio = (props) => {
  return (
    <ReactSelect
      components={{
        Option,
        ClearIndicator,
        IndicatorsContainer,
      }}
      {...props}
    />
  );
};

export default SelectWithRadio;
