import React, { Component, Fragment } from "react";
import VehicleCard from "../../../common/components/vehicle_card/VehicleCard";
import LoadingMore from "../../../common/components/spinner/LoadingMore";
import SelectWithRadio from "../../../common/components/select/SelectWithRadio";
import { OptionsOrder } from "../../../common/constants";
import EmptyListIcon from "images/Icons/EmptyListIcon.png";

class List extends Component {
  componentWillUnmount() {
    window.removeEventListener("scroll", this.loadMore);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.loadMore);
  }

  loadMore = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.scrollingElement.scrollHeight - 10
    ) {
      if (this.props.oldCount != this.props.newCount)
        this.props.loadMoreResults({
          submittingObj: "isLoadingMoreRentals",
          loadingMore: true,
        });
    }
  };

  render() {
    const rentalsCards = this.props.rentals.map((rental, key) => (
      <VehicleCard
        rental={rental}
        visitLink={() => this.props.visitLink(rental, key + 1, rental.current_location[0].name)}
        key={key}
        mouseEnter={() => this.props.enterCard(rental.id)}
        linkSuffix={this.props.linkSuffix}
        dayOfWeek={this.props.dayOfWeek}
        sharingUrl={this.props.sharingUrl}
        openModalPricing={() => this.props.openModalPricing(rental.pick_up_price ? rental.current_location[0].name : "", rental.id)}
      />
    ));
    const noMoreResults =
      this.props.oldCount == this.props.newCount &&
      !this.props.isLoadingMoreRentals ? (
        <div
          className={`no-more-results${
            this.props.rentals.length == 0 ? " list-empty" : ""
          }`}
        >
          <img src={EmptyListIcon} className="no-more-results-icon" />
          <span className="no-more-results-header">
            ¡No hay más resultados!
          </span>
          <span className="no-more-results-advice">
            Para mejorar tu búsqueda, limpia filtros y/o amplía tu área de
            búsqueda
          </span>
        </div>
      ) : null;
    return (
      <Fragment>
        <div className="search-result">
          <div className="rentals-list">{rentalsCards}</div>
          {noMoreResults}
        </div>
        <LoadingMore loading={this.props.isLoadingMoreRentals} />
      </Fragment>
    );
  }
}

export default List;
