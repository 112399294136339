import React, { Component } from "react";
import ImageContainer from "../../common/ImageContainer";
import { generateFilename } from "../../common/constants";

class VehiclePictures extends Component {
  state = {
    full_path: ""
  };

  openFileDialog = full_path => {
    this.setState({ full_path }, () => $("#image_uploader").click());
  };

  addPicture = e => {
    let file = e.target.files;
    if (file.length > 0) {
      const filename = generateFilename(file[0].name);      
      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onloadend = function(e) {
        var base64result = reader.result;
        this.props.addDocumentation(
          base64result,
          filename,
          this.state.full_path
        );
      }.bind(this);
    }
  };

  removePicture = (index, full_path) => {
    this.props.removeDocumentation(index, full_path);
  };

  handlePrev = e => {
    e.preventDefault();
    $("html, main").animate({ scrollTop: 0 }, "slow");
    this.props.jumpToStep(0);
  };

  handleNext = e => {
    e.preventDefault();
    $("html, main").animate({ scrollTop: 0 }, "slow");
    this.props.jumpToStep(2);
  };

  render() {
    let images = this.props.pictures.map((picture, i) => (
      <div className="col-md-6 my-3" key={i}>
        <ImageContainer
          start={picture.start}
          imageBase64={picture.image_base_64}
          removeImage={this.removePicture}
          fullPath="vehicle_pictures"
          index={i}
          url={picture.url}
        />
      </div>
    ));
    images.push(
      <div className="col-md-6 my-3" key="add">
        <ImageContainer
          addImage={this.openFileDialog}
          fullPath="vehicle_pictures"
        />
      </div>
    );

    let front_images = this.props.photos.front.map((picture, i) => (
      <div className="col-md-6 my-3" key={i}>
        <ImageContainer
          start={picture.start}
          imageBase64={picture.image_base_64}
          removeImage={this.removePicture}
          fullPath="photos.front"
          index={i}
          url={picture.url}
        />
      </div>
    ));

    front_images.push(
      <div className="col-md-6 my-3" key="add">
        <ImageContainer
          addImage={this.openFileDialog}
          fullPath="photos.front"
        />
      </div>
    );

    let back_images = this.props.photos.back.map((picture, i) => (
      <div className="col-md-6 my-3" key={i}>
        <ImageContainer
          start={picture.start}
          imageBase64={picture.image_base_64}
          removeImage={this.removePicture}
          fullPath="photos.back"
          index={i}
          url={picture.url}
        />
      </div>
    ));
    back_images.push(
      <div className="col-md-6 my-3" key="add">
        <ImageContainer addImage={this.openFileDialog} fullPath="photos.back" />
      </div>
    );

    let left_images = this.props.photos.left.map((picture, i) => (
      <div className="col-md-6 my-3" key={i}>
        <ImageContainer
          start={picture.start}
          imageBase64={picture.image_base_64}
          removeImage={this.removePicture}
          fullPath="photos.left"
          index={i}
          url={picture.url}
        />
      </div>
    ));
    left_images.push(
      <div className="col-md-6 my-3" key="add">
        <ImageContainer addImage={this.openFileDialog} fullPath="photos.left" />
      </div>
    );

    let right_images = this.props.photos.right.map((picture, i) => (
      <div className="col-md-6 my-3" key={i}>
        <ImageContainer
          start={picture.start}
          imageBase64={picture.image_base_64}
          removeImage={this.removePicture}
          fullPath="photos.right"
          index={i}
          url={picture.url}
        />
      </div>
    ));
    right_images.push(
      <div className="col-md-6 my-3" key="add">
        <ImageContainer
          addImage={this.openFileDialog}
          fullPath="photos.right"
        />
      </div>
    );

    let inside_images = this.props.photos.inside.map((picture, i) => (
      <div className="col-md-6 my-3" key={i}>
        <ImageContainer
          start={picture.start}
          imageBase64={picture.image_base_64}
          removeImage={this.removePicture}
          fullPath="photos.inside"
          index={i}
          url={picture.url}
        />
      </div>
    ));
    inside_images.push(
      <div className="col-md-6 my-3" key="add">
        <ImageContainer
          addImage={this.openFileDialog}
          fullPath="photos.inside"
        />
      </div>
    );

    return (
      <React.Fragment>
        <div className="my-0 step-names">
          <div className="step-title">
            <span>Datos del vehículo</span>
          </div>
          <div className="step-title">
            <span>Fotos del vehículo</span>
          </div>
          <div className="step-title">
            <span>Documentos</span>
          </div>
          <div className="step-title">
            <span>Accesorios</span>
          </div>
          {/* <div className="step-title">
            <span>Políticas</span>
          </div> */}
        </div>
        <div className="my-3 mx-5">
          <h1 className="my-4" style={{ textAlign: "center" }}>
            Por favor completa la siguiente información:
          </h1>
          <div className="container">
            <h3 style={{ textAlign: "center" }}>
              Añade imágenes de tu vehículo, ¡se verán en la publicación!
            </h3>
            <div className="row">{images}</div>
            <hr />
            <h3 className="mt-4">Imágenes detalladas</h3>
            <h4>Frente</h4>
            <div className="row">{front_images}</div>
            <h4>Posterior</h4>
            <div className="row">{back_images}</div>
            <h4>Lado izquierdo</h4>
            <div className="row">{left_images}</div>
            <h4>Lado derecho</h4>
            <div className="row">{right_images}</div>
            <h4>Interior</h4>
            <div className="row">{inside_images}</div>
          </div>
          <input
            type="file"
            id="image_uploader"
            style={{ display: "none" }}
            onChange={e => this.addPicture(e)}
            accept="image/jpeg, image/png, image/jpg"
            onClick={e => {
              e.target.value = null;
            }}
          />
        </div>
        <div className="footer-buttons">
          <button
            className="btn btn-prev btn-appcar-primary btn-lg pull-left"
            onClick={e => this.handlePrev(e)}
          >
            Anterior
          </button>
          <button
            className="btn btn-next btn-appcar-primary btn-lg pull-right"
            onClick={e => this.handleNext(e)}
          >
            Siguiente
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default VehiclePictures;
