import React from "react";
import huinchaSantander from "images/brand/Perfil-auto-huincha-santander.png";
import tarjetas from "images/brand/Perfil-auto-tarjetas.png";

const SantanderContainer = () => (
  <div className="promotional-banner rental">
    <img
      src={huinchaSantander}
      alt="Huincha Santander"
      className="banner-santander"
    />
    <div className="banner-title">
      Todos nuestros arriendos
      <br />
      <span className="fw-800">acumulan el doble de Millas LATAM Pass.</span>
      <br />
    </div>
    <div className="banner-subtitle">
      Pagando con tus tarjetas Banco Santander.
    </div>
    <img src={tarjetas} alt="tarjetas" className="banner-santander-cards" />
  </div>
);

export default SantanderContainer;
