import React, { Component } from "react";
import Axios from "axios";
import {
  DefaultImageObj,
  DefaultInsurancePics,
  generateFilename,
  PhotoAngleDictionary,
} from "../../../common/constants";
import DocumentLoader from "../../../common/components/image_loader/DocumentLoader";
import photoPlaceholder from "images/Fillers/camera.svg";
import Alert from "../../../common/components/alert/Alert";
import Spinner from "../../../common/Spinner";
import { clone, cloneDeep } from "lodash";

export default class InsurancePicsEditor extends Component {
  state = {
    insurance_pics: cloneDeep(DefaultInsurancePics),
    isSubmitting: false,
    photoSessionUploading: "",
    photoAngleUploading: "",
    currentAdditionalInitialIndex: 0,
    currentAdditionalEndIndex: 0,
    loaded: false,
    alert: {
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined,
    },
  };

  componentDidMount() {
    this.setPictures();
  }

  setPictures = () => {
    const booking = this.props.booking;
    let insurance_pics = this.state.insurance_pics;
    const current_pics = {
      start_location: cloneDeep(booking.start_location),
      end_location: cloneDeep(booking.end_location),
      insurance_pictures: cloneDeep(booking.insurance_pictures),
      insurance_pictures_post: cloneDeep(booking.insurance_pictures_post),
    };
    for (let photo_session in insurance_pics) {
      // Si la sesión de fotos no es nula se continúa
      if (!!current_pics[photo_session]) {
        for (let photo_angle in current_pics[photo_session]) {
          // Verificar si existe el ángulo de foto
          if (!PhotoAngleDictionary[photo_angle]) continue;
          // Si el ángulo de la foto es un arreglo y tiene una foto
          if (
            Array.isArray(current_pics[photo_session][photo_angle]) &&
            current_pics[photo_session][photo_angle].length > 0
          ) {
            for (let i = 0; i < current_pics[photo_session][photo_angle].length; i++) {
              insurance_pics[photo_session][photo_angle][i] = clone(DefaultImageObj);
              insurance_pics[photo_session][photo_angle][i].url =
              current_pics[photo_session][photo_angle][i];
              insurance_pics[photo_session][photo_angle][i].mime_type = "";
              insurance_pics[photo_session][photo_angle][i].start = "";
              insurance_pics[photo_session][photo_angle][i].filename = "";
              insurance_pics[photo_session][photo_angle][i].image_base_64 = "";
            }
          } else {
            insurance_pics[photo_session][photo_angle] = [
              clone(DefaultImageObj),
            ];
          }
        }
      } else {
        for (let photo_angle in insurance_pics[photo_session]) {
          insurance_pics[photo_session][photo_angle] = [clone(DefaultImageObj)];
        }
      }
    }
    this.setState({ insurance_pics, loaded: true });
  };

  showMessage = (messageType, messageTitle, messages) =>
    this.setState(
      (state) => {
        state.alert.showMessage = true;
        state.alert.messageTitle = messageTitle;
        state.alert.messages = messages;
        state.alert.messageType = messageType;
        return { alert: state.alert };
      },
      () => setTimeout(this.handleAlertClose, 3000)
    );

  handleAlertClose = () =>
    this.setState((state) => {
      state.alert.showMessage = false;
      return { alert: state.alert };
    });

  openFileDialog = (photoSession, photoAngle, index = 0) =>
    this.setState(
      (state) => {
        console.log(photoSession)
        console.log(photoAngle)
        state.photoSessionUploading = photoSession;
        state.photoAngleUploading = photoAngle;
        if (photoAngle === "additional_pictures") {
          if (photoSession === "insurance_pictures") {
            state.currentAdditionalInitialIndex = index;
          } else if (photoSession === "insurance_pictures_post") {
            state.currentAdditionalEndIndex = index;
          }
        }
        return {
          photoSessionUploading: state.photoSessionUploading,
          photoAngleUploading: state.photoAngleUploading,
          currentAdditionalEndIndex: state.currentAdditionalEndIndex,
          currentAdditionalInitialIndex: state.currentAdditionalInitialIndex
        };
      },
      () => document.getElementById(this.props.fileInputId).click()
    );

  uploadPicture = (e) => {
    let file = e.target.files;
    if (file.length > 0) {
      const filename = generateFilename(file[0].name);
      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onloadend = function(e) {
        var base64result = reader.result;
        this.addPicture(base64result, filename);
      }.bind(this);
    }
  };

  addPicture = (picture, filename) => {
    const file = picture.split(",");
    const start = file[0];
    const base64 = file[1];
    const mime = picture.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    const mimeType = mime && mime.length ? mime[1] : "";
    const { photoSessionUploading, photoAngleUploading } = this.state;
    filename = encodeURI(filename);
    this.setState((state) => {
      if (photoAngleUploading === "additional_pictures") {
        if (photoSessionUploading === "insurance_pictures_post") {
          if (state.insurance_pics[photoSessionUploading][photoAngleUploading][state.currentAdditionalEndIndex] == null) {
            state.insurance_pics[photoSessionUploading][photoAngleUploading][state.currentAdditionalEndIndex] = cloneDeep(DefaultImageObj);
          }
          state.insurance_pics[photoSessionUploading][
            photoAngleUploading
          ][state.currentAdditionalEndIndex].filename = filename;
          state.insurance_pics[photoSessionUploading][
            photoAngleUploading
          ][state.currentAdditionalEndIndex].start = start;
          state.insurance_pics[photoSessionUploading][
            photoAngleUploading
          ][state.currentAdditionalEndIndex].image_base_64 = base64;
          state.insurance_pics[photoSessionUploading][
            photoAngleUploading
          ][state.currentAdditionalEndIndex].mime_type = mimeType;
          state.insurance_pics[photoSessionUploading][photoAngleUploading][state.currentAdditionalEndIndex].url =
            "";
        } else if (photoSessionUploading === "insurance_pictures") {
          if (state.insurance_pics[photoSessionUploading][photoAngleUploading][state.currentAdditionalInitialIndex] == null) {
            state.insurance_pics[photoSessionUploading][photoAngleUploading][state.currentAdditionalInitialIndex] = cloneDeep(DefaultImageObj);
          }
          state.insurance_pics[photoSessionUploading][
            photoAngleUploading
          ][state.currentAdditionalInitialIndex].filename = filename;
          state.insurance_pics[photoSessionUploading][
            photoAngleUploading
          ][state.currentAdditionalInitialIndex].start = start;
          state.insurance_pics[photoSessionUploading][
            photoAngleUploading
          ][state.currentAdditionalInitialIndex].image_base_64 = base64;
          state.insurance_pics[photoSessionUploading][
            photoAngleUploading
          ][state.currentAdditionalInitialIndex].mime_type = mimeType;
          state.insurance_pics[photoSessionUploading][photoAngleUploading][state.currentAdditionalInitialIndex].url =
            "";
        }
      } else {
        state.insurance_pics[photoSessionUploading][
          photoAngleUploading
        ][0].filename = filename;
        state.insurance_pics[photoSessionUploading][
          photoAngleUploading
        ][0].start = start;
        state.insurance_pics[photoSessionUploading][
          photoAngleUploading
        ][0].image_base_64 = base64;
        state.insurance_pics[photoSessionUploading][
          photoAngleUploading
        ][0].mime_type = mimeType;
        state.insurance_pics[photoSessionUploading][photoAngleUploading][0].url =
          "";
      }
      return { insurance_pics: state.insurance_pics, isSubmitting: false };
    });
  };

  removePicture = (photoSession, photoAngle, index = 0) =>
    this.setState((state) => {
      if (photoAngle === "aditional_pictures") {
        state.insurance_pics[photoSession][photoAngle][index] = [clone(DefaultImageObj)]
      }
      else {
        state.insurance_pics[photoSession][photoAngle] = [clone(DefaultImageObj)];
      }
      return { insurance_pics: state.insurance_pics };
    });

  decodeObject = (objName, callback, index = 0) => {
    const objFields = objName.split(".");
    const photoSession = objFields[0];
    const photoAngle = objFields[1];
    callback(photoSession, photoAngle, index);
  };

  generateUploaderComponent = (obj, objName) => {
    let childrenCollection = [];
    let weakKey = 0;
    for (const angle in cloneDeep(DefaultInsurancePics)[objName]) {
      let pictureUploader = null;
      if (angle === "additional_pictures") {
        pictureUploader = [];
        let index = 0;
        obj[angle].forEach((element, i) => {
          if (element.url ||element.image_base_64) {
            pictureUploader.push(
              <div className="col col-md-6" key={objName + angle + weakKey}>
                <DocumentLoader
                  addImage={(objName) =>
                    this.decodeObject(objName, this.openFileDialog, i)
                  }
                  removeImage={(objName) =>
                    this.decodeObject(objName, this.removePicture, i)
                  }
                  imageBase64={element.image_base_64}
                  start={element.start}
                  url={element.url}
                  title={PhotoAngleDictionary[angle]}
                  backgroundPlaceHolder={photoPlaceholder}
                  objectName={`${objName}.${angle}`}
                  replaceImage={(objName) =>
                    this.decodeObject(objName, this.openFileDialog, i)
                  }
                  className="document-loader mx-auto"
                  style={{ width: 333 }}
                  canRemovePic={false}
                />
              </div>
            );
            index++;
          }
        });     
        pictureUploader.push(
          <div className="col col-md-6" key={objName + angle + weakKey + "extra" + index}>
            <DocumentLoader
              addImage={(objName) =>
                this.decodeObject(objName, this.openFileDialog, index)
              }
              imageBase64=""
              start=""
              url=""
              title={PhotoAngleDictionary[angle]}
              backgroundPlaceHolder={photoPlaceholder}
              objectName={`${objName}.${angle}`}
              className="document-loader mx-auto"
              style={{ width: 333 }}
              canRemovePic={false}
            />
          </div>
        )
        
      } else {
        pictureUploader = (
          <div className="col col-md-6" key={weakKey}>
            <DocumentLoader
              addImage={(objName) =>
                this.decodeObject(objName, this.openFileDialog)
              }
              removeImage={(objName) =>
                this.decodeObject(objName, this.removePicture)
              }
              imageBase64={obj[angle][0].image_base_64}
              start={obj[angle][0].start}
              url={obj[angle][0].url}
              title={PhotoAngleDictionary[angle]}
              backgroundPlaceHolder={photoPlaceholder}
              objectName={`${objName}.${angle}`}
              replaceImage={(objName) =>
                this.decodeObject(objName, this.openFileDialog)
              }
              className="document-loader mx-auto"
              style={{ width: 333 }}
              canRemovePic={false}
            />
          </div>
        );
      }
      weakKey++;
      // console.log(obj)
      if (Array.isArray(pictureUploader)) childrenCollection.push(...pictureUploader);
      else childrenCollection.push(pictureUploader);
    }
    return childrenCollection;
  };

  handleSubmit = () => {
    this.setState({ isSubmitting: true });
    let booking = this.props.booking;
    const { insurance_pics } = this.state;
    let insurancePics = {};
    if (this.props.userType == "driver") {
      insurancePics.start_location = insurance_pics.start_location;
      insurancePics.end_location = insurance_pics.end_location;
    } else {
      insurancePics.insurance_pictures = insurance_pics.insurance_pictures;
      insurancePics.insurance_pictures_post =
        insurance_pics.insurance_pictures_post;
    }
    Axios({
      method: "PATCH",
      url: `/backoffice/bookings/${booking.id}/update_insurance_pictures.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: { insurancePics },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.assign(
            `/backoffice/bookings/${booking.id}?success=1`
          );
        } else {
          this.showMessage("primary", "Alerta", [
            "Ha ocurrido un error al actualizar las imágenes",
          ]);
        }
      })
      .catch((error) =>
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ])
      )
      .then(() => this.setState({ isSubmitting: false }));
  };

  render() {
    const { insurance_pics, isSubmitting, alert, loaded } = this.state;
    const { messageTitle, messageType, messages, showMessage } = alert;
    const userType = this.props.userType;
    let startSession = [];
    let endSession = [];
    let startObject = null;
    let endObject = null;
    let startName = "";
    let endName = "";
    if (userType === "driver") {
      startObject = insurance_pics.start_location;
      endObject = insurance_pics.end_location;
      startName = "start_location";
      endName = "end_location";
    } else {
      startObject = insurance_pics.insurance_pictures;
      endObject = insurance_pics.insurance_pictures_post;
      startName = "insurance_pictures";
      endName = "insurance_pictures_post";
    }
    if (loaded) {
      startSession = this.generateUploaderComponent(startObject, startName);
      endSession = this.generateUploaderComponent(endObject, endName);
    }
    return (
      <>
        <Alert
          alertClass={messageType}
          isShowing={showMessage}
          messages={messages}
          title={messageTitle}
          onClose={this.handleAlertClose}
        />
        <Spinner loading={isSubmitting} />
        <h4>Sesión inicial</h4>
        <div className="row">{startSession}</div>
        <h4>Sesión final</h4>
        <div className="row">{endSession}</div>
        <input
          type="file"
          id={this.props.fileInputId}
          className="d-none"
          onChange={(e) => {
            this.setState({ isSubmitting: true });
            this.uploadPicture(e);
          }}
          accept="image/jpeg, image/png, image/jpg"
          onClick={(e) => {
            e.target.value = null;
          }}
        />
        <div className="row justify-content-around mt-3">
          <div className="col-auto">
            <button
              type="button"
              className="btn btn-checklist-pictures"
              onClick={() => this.handleSubmit()}
            >
              Guardar cambios
            </button>
          </div>
          <div className="col-auto">
            <button
              type="button"
              className="btn btn-checklist-pictures"
              onClick={() => this.setPictures()}
            >
              Revertir cambios
            </button>
          </div>
          <div className="col-auto">
            <button
              type="button"
              className="btn btn-checklist-pictures"
              data-dismiss="modal"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
          </div>
        </div>
      </>
    );
  }
}
