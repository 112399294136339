import React from "react";
import { numeral } from "../../constants";
import sumBy from "lodash/sumBy";

const PricingDetails = ({
  price,
  dateFrom,
  dateUntil,
  id,
  // fromEdit = false,
  showTitle = true,
}) => {
  const {
    total,
    commission,
    payment_method_fee,
    insurance_charge,
    value_added_tax,
    pick_up_charge,
    drop_off_charge,
    discount,
    base_owner_total,
  } = { ...price };

  let details = {
    owner_total_with_insurance: 0,
    onePayExtras: [],
    dailyPayExtras: [],
  };

  // details.rental = sumBy(price.details, (detail) => {
  //   return detail.price;
  // });

  details.rental = base_owner_total + insurance_charge + payment_method_fee;

  const from = moment(dateFrom);
  const to = moment(dateUntil);
  const eventRange = moment.duration(to.diff(from));
  const duration = Math.floor(eventRange.asDays());
  const hours = Math.floor(eventRange.add(-duration, "days").asHours());
  const minutes = Math.floor(eventRange.add(-hours, "hours").asMinutes());

  // let bookingLengthText =
  //   "Arriendo por " + duration + " día" + (duration > 1 ? "s" : "");
  // if (hours) {
  //   const hoursText = hours + " hora" + (hours > 1 ? "s" : "");
  //   if (minutes) bookingLengthText += "," + hoursText;
  //   else bookingLengthText += " y " + hoursText;
  // }
  // if (minutes) {
  //   bookingLengthText +=
  //     " y " + minutes + " minutos" + (minutes > 1 ? "s" : "");
  // }

  let bookingLengthText = "Auto (Seguro incluído)";

  // if (fromEdit) bookingLengthText = "Ganancia diaria Owner";

  details.onePayExtras = price.extra_charges.map((extra) => {
    return {
      name: extra.extra.a_name,
      price: extra.price,
    };
  });
  details.dailyPayExtras =
    price.details.length > 0
      ? price.details[0].extra_charges.map((extra) => {
          return {
            name: extra.extra.a_name,
            price: 0,
          };
        })
      : [];

  for (const detail of price.details) {
    for (const extra of detail.extra_charges) {
      for (const daily_extra of details.dailyPayExtras) {
        if (daily_extra.name === extra.extra.a_name) {
          daily_extra.price += extra.price;
        }
      }
    }
  }
  let totalExtras = 0;

  details.dailyPayExtras.forEach((extra) => (totalExtras += extra.price));
  details.onePayExtras.forEach((extra) => (totalExtras += extra.price));

  // let extraLocationPrice = 0;
  if (pick_up_charge?.price) totalExtras += pick_up_charge?.price;
  if (drop_off_charge?.price) totalExtras += drop_off_charge?.price;

  // const extraLocationContainer = !!extraLocationPrice ? (
  //   <div className="charge-row">
  //     <div className="start">Ubicación adicional</div>
  //     <div className="middle">$</div>
  //     <div className="end">{numeral(extraLocationPrice).format("0,0")}</div>
  //   </div>
  // ) : null;

  let totalAddedValues = commission;

  const lengthHTML = (
    <div className="charge-row">
      <div className="start">{bookingLengthText}</div>
      <div className="middle">$</div>
      <div className="end">{numeral(details.rental).format("0,0")}</div>
    </div>
  );

  const totalExtrasHTML = (
    <div className="charge-row">
      <div className="start">Extras</div>
      <div className="middle">$</div>
      <div className="end">{numeral(totalExtras).format("0,0")}</div>
    </div>
  );

  const discountHTML = (
    <div className="charge-row">
      <div className="start">Descuento</div>
      <div className="middle">$</div>
      <div className="end"> {numeral(discount).format("0,0")}</div>
    </div>
  );

  // const insuranceHTML = (
  //   <div className="charge-row">
  //     <div className="start">Cargo por seguro</div>
  //     <div className="middle">$</div>
  //     <div className="end">{numeral(insurance_charge).format("0,0")}</div>
  //   </div>
  // );

  const comissionHTML = (
    <div className="charge-row">
      <div className="start">Comisión por servicio (IVA inc.)</div>
      <div className="middle">$</div>
      <div className="end">{numeral(totalAddedValues).format("0,0")}</div>
    </div>
  );

  // const feeHTML = (
  //   <div className="charge-row ">
  //     <div className="start">Tarifa medio de pago</div>
  //     <div className="middle">$</div>
  //     <div className="end">{numeral(payment_method_fee).format("0,0")}</div>
  //   </div>
  // );

  // const taxHTML = (
  //   <div className="charge-row">
  //     <div className="start">IVA</div>
  //     <div className="middle">$</div>
  //     <div className="end">{numeral(value_added_tax).format("0,0")}</div>
  //   </div>
  // );

  const totalHTML = (
    <div className="charge-row over-line">
      <div className="start fw-800">Total a pagar:</div>
      <div className="middle">$</div>
      <div className="end fw-800">{numeral(total).format("0,0")}</div>
    </div>
  );

  const title = showTitle ? <h5>Detalle del precio</h5> : null;
  return (
    <>
      {title}
      <div className="pricing-details">
        {lengthHTML}
        {totalExtrasHTML}
        {/* {extraLocationContainer} */}
        {discountHTML}
        {/* {insuranceHTML} */}
        {comissionHTML}
        {/* {feeHTML} */}
        {/* {taxHTML} */}
        {totalHTML}
      </div>
    </>
  );
};

export default PricingDetails;
