import React, { useState, useEffect } from "react";
import iconStare from "images/IconStare.png";
import ToolTip, { LoadToolTips } from "../../../../common/components/ToolTip";
import iconInfo from "images/Icons/ico-info.png";
import SelectWithRadio from "../../../../common/components/select/SelectWithRadio";
import {
  constants,
  formateaRut,
  validateRUT,
} from "../../../../common/constants";

const UserFormModal = ({
  user,
  onChange,
  validateEmail,
  action,
  isUpdating,
  documentTypes,
  checkNeedInvoice,
  changeNeedInvoice,
  fromBackoffice = false,
  title = "Solicitar reserva",
  modalRef = null,
  subtitle = "Por favor completa estos datos para solicitar tu reserva:",
  hideDriverAlert = false,
}) => {
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState(
    false
  );
  const [idNumberError, setIdNumberError] = useState("");
  useEffect(() => {
    LoadToolTips();
  }, []);

  let modalBody = null;
  if (!fromBackoffice || user?.id) {
    const userFormTitle = isUpdating
      ? `¡Hola ${user.name}!`
      : "¡Bienvenido a Appcar!";

    const selectedDocument = {
      value: user.document_type?.id,
      label: user.document_type?.description,
    };

    const documentTypesOptions = documentTypes.map((d) => ({
      label: d.description,
      value: d.id,
    }));

    const validate = () => {
      const validatePassword = isUpdating ? true : user.password;
      return (
        user.name &&
        user.last_name &&
        validateEmail(user.email) &&
        user.phone_number &&
        user.id_number &&
        user.document_type?.id &&
        validatePassword &&
        acceptTermsAndConditions
      );
    };

    const userFormPasswordContainer = !isUpdating ? (
      <div className="form-group">
        <label className="label-inside">Crea una contraseña</label>
        <input
          type="password"
          id="notlogged__password"
          className="form-control appcar-input"
          value={user.password}
          onChange={(e) => onChange(e.target.value, "password")}
        />
      </div>
    ) : null;

    const handleBlurIdNumber = () => {
      const idNumber = user.id_number;
      const documentType = user.document_type;
      if (documentType?.id == 1 && !!idNumber) {
        if (validateRUT(idNumber)) {
          const formatedRut = formateaRut(idNumber);
          onChange(formatedRut, "id_number");
          setIdNumberError("");
        } else {
          setIdNumberError("El RUT es inválido");
        }
      } else {
        setIdNumberError("");
      }
    };

    const needInvoiceContainer = (
      <div className="form-group mt-2 d-flex align-items-center justify-content-between">
        <div className="form-check form-check-user-form">
          <input
            type="checkbox"
            className="form-check-input mr-2"
            id="needInvoice"
            name="needInvoice"
            checked={checkNeedInvoice}
            onChange={(e) => changeNeedInvoice(e.target.checked)}
          />
          <label
            className="form-check-label text-black fs-12 fw-400"
            htmlFor="needInvoice"
          >
            Necesito factura
          </label>
        </div>
        <ToolTip
          title="Necesito factura"
          tooltipHTML="Más adelante se le pedirán los datos, luego de solicitar tu reserva"
          containerHTML={
            <img src={iconInfo} width={20} className="float-end" />
          }
          id="ttip_need_invoice"
        />
      </div>
    );

    const driverAlert = hideDriverAlert ? null : (
      <div className="alert-advice">
        <div className="alert-icon">
          <img src={iconStare} height={22} />
        </div>
        <div className="alert-text">
          Recuerda que únicamente el Driver que solicita la reserva puede
          retirar el auto
        </div>
      </div>
    );

    modalBody = (
      <div className="text-center mb-4">
        <h4 className="fs-25 fw-900 mb-4">{userFormTitle}</h4>
        <h5 className="fs-13 fw-700 mb-3">{subtitle}</h5>
        <div
          className="text-left mb-3"
          tabIndex={0}
          onKeyDown={(e) => {
            // Submit on enter
            if (e.key === "Enter") {
              // Verify if is valid email
              if (validate()) {
                action();
              }
            }
          }}
        >
          <div className="form-group">
            <label className="label-inside">Tu email</label>
            <input
              type="email"
              className="form-control appcar-input"
              value={user.email || ""}
              style={{ width: 310 }}
              onChange={(e) => onChange(e.target.value, "email")}
            />
          </div>
          <div className="form-group">
            <label className="label-inside">Tu nombre</label>
            <input
              type="text"
              className="form-control appcar-input"
              value={user.name || ""}
              style={{ width: 310 }}
              onChange={(e) => onChange(e.target.value, "name")}
            />
          </div>
          <div className="form-group">
            <label className="label-inside">Tus apellidos</label>
            <input
              type="test"
              className="form-control appcar-input"
              value={user.last_name || ""}
              style={{ width: 310 }}
              onChange={(e) => onChange(e.target.value, "last_name")}
            />
          </div>
          <div className="form-group">
            <label className="label-inside">Tu teléfono</label>
            <input
              type="test"
              className="form-control appcar-input"
              value={user.phone_number || ""}
              style={{ width: 310 }}
              onChange={(e) => onChange(e.target.value, "phone_number")}
            />
          </div>
          <div className="form-group">
            <label className="label-inside">Tipo de documento</label>
            <SelectWithRadio
              value={selectedDocument}
              options={documentTypesOptions}
              placeholder="Selecciona"
              noOptionsMessage={() => "No hay tipos de documento"}
              onChange={(opt) => {
                onChange(
                  {
                    id: opt.value,
                    description: opt.label,
                  },
                  "document_type"
                );
              }}
              classNamePrefix="RS-FIX"
              className="react-select-fix appcar-input"
            />
          </div>
          <div className="form-group">
            <label className="label-inside">
              Número de{" "}
              {selectedDocument?.label == constants.RUT ? "RUT" : "documento"}{" "}
            </label>
            <input
              type="test"
              className="form-control appcar-input"
              value={user.id_number || ""}
              style={{ width: 310 }}
              onChange={(e) => onChange(e.target.value, "id_number")}
              onBlur={() => handleBlurIdNumber()}
            />
            {idNumberError ? (
              <span className="fs-8 text-appcar-primary">{idNumberError}</span>
            ) : null}
          </div>
          {/* {needInvoiceContainer} */}
          {userFormPasswordContainer}
          <div className="form-group mt-2 d-flex align-items-center justify-content-between">
            <div className="form-check form-check-user-form">
              <input
                type="checkbox"
                className="form-check-input mr-2"
                id="termsAndConditionsCheck"
                name="termsAndConditionsCheck"
                checked={acceptTermsAndConditions}
                onChange={(e) => setAcceptTermsAndConditions(e.target.checked)}
              />
              <label
                className="form-check-label text-black fs-12 fw-700"
                htmlFor="termsAndConditionsCheck"
              >
                Acepto los{" "}
                <a
                  href="/legal/terms_and_conditions"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="fw-900 text-appcar-primary"
                >
                  Términos y condiciones
                </a>
              </label>
            </div>
          </div>
        </div>
        <button
          type="button"
          onClick={() => {
            if (validate()) action();
          }}
          className="btn btn-appcar-primary btn-slim px-5 mb-4"
          disabled={!validate()}
        >
          Siguiente
        </button>
        {driverAlert}
      </div>
    );
  }

  return (
    <div
      className="modal fade"
      id="UserFormModal"
      aria-hidden="true"
      aria-labelledby="UserFormModalLabel"
      tabIndex={-1}
      ref={modalRef}
    >
      <div className="modal-dialog modal-dialog-centered modal-appcar">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-m-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              x
            </button>
            <h5 className="modal-title" id="UserFormModalLabel">
              {title}
            </h5>
          </div>
          <div
            className={`modal-body mx-auto ${
              hideDriverAlert ? "pb-4" : "pb-0"
            }`}
            style={{ maxWidth: 350, paddingTop: 39 }}
          >
            {modalBody}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserFormModal;
