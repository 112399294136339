import React from "react";
import { BookingStatus } from "../../common/constants";
import BookingDetails from "./BookingDetails";

const CompareBookings = ({ originalBooking, booking }) => {
  const ownerBaseBookingActive = booking && booking.owner_base_booking_status && booking.owner_base_booking_status.id === BookingStatus.ACTIVO;
  const ownerBaseBooking = booking && booking.owner_base_booking_id ? originalBooking : null;

  if (ownerBaseBookingActive) {
    booking.price.owner_discount = 0;
    const bookingTotal = booking.price.total;
    const originalPrice = ownerBaseBooking ? ownerBaseBooking.price : null;
    if (originalPrice && bookingTotal > originalPrice.total) {
      booking.price.owner_discount = (booking.price.total - booking.price.value_added_tax) - (originalPrice.total - originalPrice.value_added_tax);
      booking.price.value_added_tax = originalPrice.value_added_tax;
      booking.price.total = originalPrice.total
    }
  }
  const originalBookingComponent = originalBooking ? <BookingDetails booking={originalBooking} /> : null;
  const bookingComponent = booking ? <BookingDetails booking={booking} /> : null;
  return (
    <div
      className="modal fade"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="booking-info-title"
      aria-hidden="true"
      id="modal-compare-bookings"
    >
      <div className="modal-dialog modal-lg modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title" id="booking-info-title">
              Comparar reservas
            </h3>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body mx-3">
            <div className="row">
              <div className="col">
                <h4>Reserva original</h4>
                {originalBookingComponent}
              </div>
              <div className="col">
                <h4>Cambios solicitados</h4>
                {bookingComponent}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-appcar-light"
              id="confirm-btn"
              data-dismiss="modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              Cerrar ventana
            </button>
          </div>
        </div>
      </div>
    </div>
  );

}
export default CompareBookings;