import React, { Component } from "react";
import { subleaseAgreementText } from "../../../../common/constants";
import LegalModal from "../../../../common/legal_modals/LegalModal";
import Spinner from "../../../../common/Spinner";
import axios from "axios";
import { AxiosApplicationInterceptor } from "../../../../common/axios_interceptor";
axios.interceptors.response.use(undefined, AxiosApplicationInterceptor);

class DriverLegalModal extends Component {
  state = {
    isSubmitting: false
  };

  confirmBooking = () => {
    this.setState({ isSubmitting: true });
    axios({
      method: "post",
      url: `/bookings/${this.props.booking.id}/accept_owner_edition.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        if (response.data.result.success) {
          $("#modal-sublease-agreement").modal("hide");
          window.location.assign("/bookings?success=1");
        } else {
          let alert = $("#alert-error");
          let alert_messages = $("#alert-messages");
          alert_messages.empty();
          response.data.result.messages.forEach(message => {
            alert_messages.append(`<p class="mb-0 d-block">${message}</p>`)
          })
          alert.removeClass("d-none");
          $("html, main").animate({ scrollTop: 0 }, "slow");
        }
      })
      .catch(error => {
        let alert = $("#alert-error");
        let alert_messages = $("#alert-messages");
        alert_messages.empty();
        alert_messages.append('<p class="mb-0 d-block">Ha ocurrido un error inesperado</p>')
        alert.removeClass("d-none");
        $("html, main").animate({ scrollTop: 0 }, "slow");
      })
      .finally(() => this.setState({ isSubmitting: false}));
  };

  render() {
    const booking = this.props.booking;
    const modalBody = subleaseAgreementText(booking.id);
    const modalSubleaseAgreement = (
      <LegalModal
        action={this.confirmBooking}
        title="Lee atentamente antes de continuar"
        checkboxLabel="Estoy de acuerdo con los términos y condiciones."
        checkboxError="Debes aceptar los términos y condiciones"
        btnText="Continuar"
        body={modalBody}
        elementId="modal-sublease-agreement"
        secondCheckboxLabel="Estoy de acuerdo con el tratamiento de mis datos personales como se describe en la “Política de Privacidad II”."
        secondCheckboxError="Debes aceptar la Política de Privacidad II para continuar"
        refId={booking.id}
      />
    );
    return (
      <>
        <Spinner loading={this.state.isSubmitting} />
        {modalSubleaseAgreement}
      </>
    )
  }
}
export default DriverLegalModal;
