import React, { Component } from "react";
import PricingDetails from "../components/pricing_details/PricingDetails";
import { numeral } from "../constants";
import ToolTip, { LoadToolTips } from "../components/ToolTip";
import iconInfo from "images/Icons/ico-info.png";
import LogoSura from "images//Sura.png";

export default class BookingModal extends Component {
  state = {
    checked: false,
    error: false,
    secondChecked: false,
    secondError: false,
  };

  changeAccept = (checked) => this.setState({ checked });
  changeSecondAccept = (secondChecked) => this.setState({ secondChecked });

  render() {
    const {
      title,
      pricing,
      fuelPolicy,
      cancellationPolicy,
      dropoff,
      pickup,
      from,
      to,
      textAgreement,
      textPrivacy,
      action,
      max250km,
      minLength,
      btnText,
      btnEditarReserva,
      selectedExtras = [],
    } = this.props;

    LoadToolTips();

    const { checked, error, secondChecked, secondError } = this.state;
    const from_ = moment(from);
    const to_ = moment(to);
    const rentalDates = (
      <div className="rental-dates">
        <div className="item">
          <span>Inicio:</span>
          <span className="fw-800">{from_.format("DD [de] MMMM, YYYY")}</span>
          <span className="fw-800">{from_.format("HH:mm [horas]")}</span>
        </div>
        <div className="item">
          <span>Devolución:</span>
          <span className="fw-800">{to_.format("DD [de] MMMM, YYYY")}</span>
          <span className="fw-800">{to_.format("HH:mm [horas]")}</span>
        </div>
      </div>
    );
    const extrasHTML = selectedExtras.map((x, i) => (
      <li key={i}>
        <span className="fw-700">
          {x.vehicle_extra.a_name}. {numeral(x.price).format("$ 0,0")}
        </span>
        <span className="fw-400">
          {x.vehicle_extra.charge_per_day ? "(Por día)." : "(Pago único)."}
        </span>
      </li>
    ));

    const extrasContainer =
      extrasHTML.length > 0 ? (
        <div className="section">
          <h5>Extras</h5>
          <ul>{extrasHTML}</ul>
        </div>
      ) : null;

    return (
      <div
        className="modal fade"
        id="legalModal"
        aria-hidden="true"
        aria-labelledby="legalModalLabel"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered modal-appcar">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-m-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                x
              </button>
              {title}
            </div>
            <div className="modal-body rental-modal-body pb-0 px-3">
              <div className="rental-card legal">
                <div className="section">
                  <h5>Rango de fechas del arriendo:</h5>
                  {rentalDates}
                </div>
                <div className="section">
                  <h5>Punto de recogida</h5>
                  <p className="fw-700">{pickup}</p>
                </div>
                <div className="section">
                  <h5>Punto de entrega</h5>
                  <p className="fw-700">{dropoff}</p>
                </div>
                {extrasContainer}
                <div className="section">
                  <h5>Políticas del auto</h5>
                  <div className="row">
                    <div className="col fw-400">Kilometraje diario:</div>
                    <div className="col-auto fw-800">
                      {max250km ? "Máx 250 Kms." : "Ilimitado"}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col fw-400">
                      Duración mínima del arriendo:
                    </div>
                    <div className="col-auto fw-800">
                      {minLength
                        ? `${minLength} día${minLength > 1 ? "s" : ""}`
                        : "No tiene mínimo"}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col fw-400">Política de cancelación:</div>
                    <div className="col-auto fw-800">
                      {(cancellationPolicy && cancellationPolicy.name) ||
                        "Sin política"}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col fw-400">Política de combustible:</div>
                    <div className="col-auto fw-800">
                      {(fuelPolicy && fuelPolicy.name) || "Sin política"}
                    </div>
                  </div>
                </div>
                <div className="section">
                  <PricingDetails
                    dateFrom={from}
                    dateUntil={to}
                    price={pricing}
                  />
                </div>
              </div>

              <div className="rental-card legal py-1">
                <div className="section">
                  <div className="row">
                    <div className="col">
                      <h5>Garantía</h5>
                    </div>
                    <div className="col-auto">
                      <ToolTip
                        title="Garantía"
                        tooltipHTML="Dos días antes del inicio del arriendo se tomará una garantía parcial de $100.000. Una vez finalizado el arriendo, siempre que no haya inconvenientes con el auto, se gestionará su devolución a través de Mercado Pago. El tiempo en que esto se vea reflejado en tu tarjeta dependerá de tu banco. En caso de alguna incidencia durante el arriendo, se tomará el complemento de la garantía equivalente a $300.000"
                        containerHTML={
                          <img src={iconInfo} width={20} className="ml-1" />
                        }
                        id="ttip_booking_modal"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-9">
                    Dos días antes del inicio del arriendo, se generará un cargo temporal en tu tarjeta de crédito de <b className="fw-880">$100.000</b> 
                    </div>
                    <div className="col-md-3 align-self-center">
                    <img src={LogoSura} width={123} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="rental-card legal py-1">
                <div className="section">
                  <div className="row">                  
                      <div className="col">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="agreementCheck"
                            name="agreementCheck"
                            checked={checked}
                            onChange={(e) => this.changeAccept(e.target.checked)}
                          />
                            <label
                              
                              className="form-check-label text-black fs-12 fw-400"
                              htmlFor="agreementCheck"
                            >
                              {textAgreement}
                            </label>
                        </div>
                      </div>
                        
                  </div>
              <div className="row">
                  <div className="col">
                    <div className="form-check">
                      <input
                        type="checkbox"              
                        className="form-check-input"
                        id="privacyCheck"
                        name="privacyCheck"
                        checked={secondChecked}
                        onChange={(e) => this.changeSecondAccept(e.target.checked)}
                      />
                      <label
                        
                        className="form-check-label text-black fs-12 fw-400"
                        htmlFor="privacyCheck"
                      >
                        {textPrivacy}
                      </label>
                    </div>
                  </div>
               
              </div>
                </div>
                  </div>

              <div className="row" >
                <div className="col my-3 md-4 align-self-center">               
                    <button
                      type="button"
                      className="btn btn-appcar-return align-text-center"
                      data-bs-dismiss="modal"                          
                    >
                      <i className="mdi mdi-chevron-left button-icon"  />
                        &nbsp;
                        {btnEditarReserva}
                      </button>
                </div>
                <div className="col my-3 md-3 offset-md-3 align-self-center" style={{paddingLeft: 35}}>               
                  <button
                      type="button"
                      className={`btn ${checked && secondChecked ? "btn-appcar-primary" : "btn-appcar-light"}`}
                      disabled={!checked || !secondChecked}
                      onClick={() => action()}
                    >
                      {btnText}
                  </button>
                </div>
             </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
