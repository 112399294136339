import React, { Component } from "react";
import iconInfo from "images/Icons/ico-info.png";
import ToolTip, { LoadToolTips } from "../../../common/components/ToolTip";
import { cancelPolicyDictionary, fuelPolicyDictionary } from "../../../common/constants";

export default class RentalPolicies extends Component {
  componentDidUpdate() {
    LoadToolTips();
  }

  componentDidMount() {
    LoadToolTips();
  }
  render() {
    const {
      isSubmitting,
      handleSubmit,
      key,
      handleChangeFuelPolicy,
      handleChangeCancellationPolicy,
      unlimitedDistance,
      handleChangeUnlimitedDistance,
      selectedFuelPolicy,
      selectedCancellationPolicy,
    } = this.props;
    
    const fuelPoliciesContainer = fuelPolicyDictionary().map((fPolicy, i) => (
      <div key={i}>
        <div className="row">
          <div className="col">
            <div className="form-check mt-0">
              <input
                className="form-check-input"
                type="radio"
                name="radioFuelPolicy"
                id={`check_radio_fuel_${fPolicy.id}`}
                checked={selectedFuelPolicy == fPolicy.id}
                onChange={() => handleChangeFuelPolicy(fPolicy.id)}
              />
              <label
                className="form-check-label"
                htmlFor={`check_radio_fuel_${fPolicy.id}`}
              >
                {fPolicy.title}
              </label>
            </div>
          </div>
          <div className="col-auto">
            <ToolTip
              title={fPolicy.title}
              tooltipHTML={fPolicy.content}
              containerHTML={<img src={iconInfo} width={20} className="ml-1" />}
              id={`ttip_fuel_policy_${fPolicy.id}`}
            />
          </div>
        </div>
      </div>
    ));
    const cancelPoliciesContainer = cancelPolicyDictionary().map((cPolicy, i) => (
      <div key={i}>
        <div className="row">
          <div className="col">
            <div className="form-check mt-0">
              <input
                className="form-check-input"
                type="radio"
                name="radioCancelPolicy"
                id={`check_radio_cancel_${cPolicy.id}`}
                checked={selectedCancellationPolicy == cPolicy.id}
                onChange={() => handleChangeCancellationPolicy(cPolicy.id)}
              />
              <label
                className="form-check-label"
                htmlFor={`check_radio_cancel_${cPolicy.id}`}
              >
                {cPolicy.title}
              </label>
            </div>
          </div>
          <div className="col-auto">
            <ToolTip
              title={cPolicy.title}
              tooltipHTML={cPolicy.content}
              containerHTML={<img src={iconInfo} width={20} className="ml-1" />}
              id={`ttip_cancel_policy_${cPolicy.id}`}
            />
          </div>
        </div>
      </div>
    ));
    return (
      <>
        <div className="mt-3 mb-1 row align-items-center">
          <div className="col">
            <label className="fs-13 fw-800 text-appcar-primary">
              Kilómetros incluídos por día
            </label>
          </div>
          <div className="col-auto ml-2">
            <ToolTip
              title="Kilómetros máximos por día"
              tooltipHTML="Puedes limitar el kilometraje diario al activar esta opción. Si el driver supera el kilometraje máximo diario, se le puede establecer una multa."
              containerHTML={<img src={iconInfo} width={20} className="ml-1" />}
              id="ttip_rental_max_km"
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-6">
            <button
              type="button"
              className={`fs-12 w-100 btn btn-appcar-${
                !unlimitedDistance ? "secondary" : "white text-black"
              }`}
              onClick={() => handleChangeUnlimitedDistance(false)}
            >
              250 al día inc.
            </button>
          </div>
          <div className="col-6">
            <button
              type="button"
              className={`fs-12 w-100 btn btn-appcar-${
                !unlimitedDistance ? "white text-black" : "secondary"
              }`}
              onClick={() => handleChangeUnlimitedDistance(true)}
            >
              Ilimitado
            </button>
          </div>
        </div>
        <h5 className="fs-13 fw-800 mt-4 mb-3">Combustible</h5>
        {fuelPoliciesContainer}
        <h5 className="fs-13 fw-800 mt-4 mb-3">Cancelación</h5>
        {cancelPoliciesContainer}
        <div className="text-center my-4">
          <button
            type="button"
            className="btn btn-appcar-primary pt-2 pb-2"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Continuar
          </button>
        </div>
      </>
    );
  }
}
