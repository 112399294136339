import React, { Component } from "react";
import * as Yup from "yup";
import classNames from "classnames";
import * as _ from "lodash";
import { InputFeedback } from "../../common/CustomInputs";
import Select from "react-select";
import SelectWithRadio from "../../common/components/select/SelectWithRadio";

const VehicleDataSchema = Yup.object().shape({
  plate_number: Yup.string().notRequired(),
  year: Yup.number().integer().notRequired(),
  vehicle_type: Yup.object()
    .shape({
      brand: Yup.string().notRequired(),
      model: Yup.string().notRequired()
    }).notRequired(),
  color: Yup.string().notRequired(),
  fuel_type: Yup.object().shape({
    description: Yup.string().notRequired()
  }).notRequired(),
  vehicle_category: Yup.object().shape({
    description: Yup.string().notRequired()
  }).notRequired(),
  vehicle_transmission: Yup.object().shape({
    description: Yup.string().notRequired()
  }).notRequired(),
  capacity: Yup.number().notRequired(),
  efficiency: Yup.number().notRequired(),
  engine: Yup.string().notRequired(),
  chassis: Yup.string().notRequired(),
  doors: Yup.string().notRequired()
}).notRequired();

_.mixin({
  deepMapObject: function (map) {
    return function (obj, fn) {
      return map(
        _.mapValues(obj, function (val) {
          return _.isPlainObject(val) ? _.deepMapObject(map)(val, fn) : val;
        }),
        fn
      );
    };
  }
});

class VehicleData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.vehicle,
      touched: {
        plate_number: false,
        year: false,
        vehicle_type: {
          brand: false,
          model: false
        },
        color: false,
        fuel_type: {
          description: false
        },
        vehicle_category: {
          description: false
        },
        vehicle_transmission: {
          description: false
        },
        capacity: false,
        efficiency: false,
        engine: false,
        chassis: false,
        doors: false
      },
      errors: {
        plate_number: false,
        year: false,
        vehicle_type: {
          brand: false,
          model: false
        },
        color: false,
        fuel_type: {
          description: false
        },
        vehicle_category: {
          description: false
        },
        vehicle_transmission: {
          description: false
        },
        capacity: false,
        efficiency: false,
        engine: false,
        chassis: false,
        doors: false
      },
      selected_brand: undefined
    };
    this.isValidated = this.isValidated.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  isValidated() {
    let { touched } = { ...this.state };
    touched = _.deepMapObject(_.mapValues)(touched, (val, key) => {
      if (typeof val === "object") {
        return val;
      } else return true;
    });
    this.setState(state => {
      state.touched = touched;
      return { touched: state.touched };
    });
    return VehicleDataSchema.isValid(this.state.data);
  }

  handleBlur = e => {
    const target = e.target;
    let error = false;
    VehicleDataSchema.validateAt(target.name, this.state.data)
      .catch(er => {
        error = er.message.includes("NaN")
          // ? "Debe ser un número válido"
          ? false
          : er.message;
      })
      .finally(
        setTimeout(() => {
          if (!target.contains(document.activeElement)) {
            this.setState(state => {
              state.touched[target.name] = true;
              state.errors[target.name] = error;
              return { touched: state.touched, errors: state.errors };
            });
          }
        }, 0)
      );
  };

  blurVehicleType = input_name => {
    this.setState(state => {
      state.touched.vehicle_type[input_name] = true;
      return { touched: state.touched };
    });
  };

  blurFuelType = () => {
    this.setState(state => {
      state.touched.fuel_type.description = true;
      return { touched: state.touched };
    });
  };

  blurCategory = () => {
    this.setState(state => {
      state.touched.category.description = true;
      return { touched: state.touched };
    });
  };

  blurSelect = name => {
    this.setState(state => {
      state.touched[name].description = true;
      return { touched: state.touched };
    });
  };

  componentDidUpdate(prevProps) {

    if (this.props.vehicle != prevProps.vehicle) {
      this.setState(
        state => {
          state.data = this.props.vehicle;
          return { data: state.data };
        },
        () => {
          let errors = {
            plate_number: false,
            year: false,
            vehicle_type: {
              brand: false,
              model: false
            },
            color: false,
            fuel_type: {
              description: false
            },
            vehicle_category: {
              description: false
            },
            vehicle_transmission: {
              description: false
            },
            capacity: false,
            efficiency: false,
            engine: false,
            chassis: false,
            doors: false
          };
          VehicleDataSchema.validate(this.state.data, {
            abortEarly: false
          })
            .catch(e => {
              e.inner.forEach(v_error => {
                const path = v_error.path.split(".");
                if (path.length > 1) {
                  errors[path[0]][path[1]] = v_error.message.includes("NaN")
                    // ? "Debe ser un número válido"
                    ? false
                    : v_error.message;
                } else {
                  errors[path[0]] = v_error.message.includes("NaN")
                    // ? "Debe ser un número válido"
                    ? false
                    : v_error.message;
                }
              });
            })
            .then(() => {
              this.setState(state => {
                state.errors = errors;
                return { errors: state.errors };
              });
            });
        }
      );
    }
  }

  changeBrand = option => {
    this.setState({ selected_brand: option }, () => {
      const selected_model = option
        ? this.props.vehicleTypes.filter(
          v_type => v_type.brand === option.value
        )[0]
        : null;
      this.props.changeVehicleType(selected_model);
    });
  };

  changeModel = option => {
    const model = option
      ? this.props.vehicleTypes.find(type => type.id == option.value)
      : null;
    this.props.changeVehicleType(model);
  };

  changeFuelType = option => {
    const fuel_type = this.props.fuelTypes.find(
      type => type.id == option.value
    );
    this.props.handleChange("fuel_type", fuel_type);
  };

  changeCategory = option => {
    const vehicle_category = this.props.vehicleCategories.find(
      cat => cat.id == option.value
    );
    this.props.handleChange("vehicle_category", vehicle_category);
  };

  changeTransmission = option => {
    const vehicle_transmission = this.props.vehicleTransmissions.find(
      t => t.id == option.value
    );
    this.props.handleChange("vehicle_transmission", vehicle_transmission);
  };

  handleChange = e => {
    const target = e.target;
    let value = target.value;
    let error = false;
    let data = { ...this.state.data };
    if (target.name == "capacity" || target.name == "doors")
      value = parseInt(value);
    else if (target.name == "efficiency") value = parseFloat(value);
    else if (target.name == "plate_number") value = value.toUpperCase();
    data[target.name] = value;
    VehicleDataSchema.validateAt(target.name, data)
      .catch(er => {
        error = er.message.includes("NaN")
          // ? "Debe ser un número válido"
          ? false
          : er.message;
      })
      .finally(() => {
        this.setState(state => {
          state.errors[target.name] = error;
          return { errors: state.errors };
        });
        this.props.handleChange(target.name, value);
      });
  };

  handleNext = e => {
    e.preventDefault();
    // this.isValidated().then(valid => {
    //   if (valid) {
    $("html, main").animate({ scrollTop: 0 }, "slow");
    this.props.jumpToStep(1);
    //   }
    // });
  };

  render() {
    const { touched, errors, data, selected_brand } = this.state;
    const vehicle = this.props.vehicle;
    let selected_brand_ = null;
    if (selected_brand) {
      selected_brand_ = selected_brand;
    } else if (vehicle.vehicle_type && vehicle.vehicle_type.brand) {
      selected_brand_ = {
        label: vehicle.vehicle_type.brand,
        value: vehicle.vehicle_type.brand
      };
    }
    const brand_options = _.uniqBy(this.props.vehicleTypes, "brand").map(
      type => {
        return { label: type.brand, value: type.brand };
      }
    );
    const model_options = this.props.vehicleTypes
      .filter(
        v_type =>
          v_type.brand ===
          ((selected_brand_ && selected_brand_.value) ||
            (vehicle.vehicle_type && vehicle.vehicle_type.brand))
      )
      .map(type => {
        return { label: type.model, value: type.id };
      });
    const categories_options = this.props.vehicleCategories.map(cat => {
      return { label: cat.description, value: cat.id };
    });
    const transmissions_options = this.props.vehicleTransmissions.map(t => {
      return { label: t.description, value: t.id };
    });
    const fuel_options = this.props.fuelTypes.map(fuel => {
      return { label: fuel.description, value: fuel.id };
    });
    const selected_model =
      vehicle.vehicle_type && vehicle.vehicle_type.model
        ? {
          label: vehicle.vehicle_type.model,
          value: vehicle.vehicle_type.id
        }
        : null;
    const selected_category = vehicle.vehicle_category ? {
      label: vehicle.vehicle_category.description,
      value: vehicle.vehicle_category.id
    } : null;
    const selected_transmission = vehicle.vehicle_transmission ? {
      label: vehicle.vehicle_transmission.description,
      value: vehicle.vehicle_transmission.id
    } : null;
    const selected_fuel = vehicle.fuel_type ? {
      label: vehicle.fuel_type.description,
      value: vehicle.fuel_type.id
    } : null;
    const brand_error =
      typeof errors.vehicle_type == "string"
        ? "Campo requerido"
        : errors.vehicle_type.brand;
    const model_error =
      typeof errors.vehicle_type == "string"
        ? "Campo requerido"
        : errors.vehicle_type.model;
    return (
      <React.Fragment>
        <div className="my-0 step-names">
          <div className="step-title">
            <span>Datos del vehículo</span>
          </div>
          <div className="step-title">
            <span>Fotos del vehículo</span>
          </div>
          <div className="step-title">
            <span>Documentos</span>
          </div>
          <div className="step-title">
            <span>Accesorios</span>
          </div>
          {/* <div className="step-title">
            <span>Políticas</span>
          </div> */}
        </div>
        <h1 className="my-4" style={{ textAlign: "center" }}>
          Por favor completa la siguiente información:
        </h1>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-6 mb-2">
                <div className="form-group">
                  <label className="label-inside">Marca</label>
                  <SelectWithRadio
                    value={selected_brand_}
                    options={brand_options}
                    placeholder="Selecciona..."
                    noOptionsMessage={() => "No existen marcas aún..."}
                    onChange={this.changeBrand}
                    classNamePrefix="RS-FIX"
                    isSearchable={false}
                    className={
                      "react-select-fix appcar-input " +
                      classNames({
                        "is-invalid": touched.vehicle_type.brand && brand_error
                      })
                    }
                    isClearable
                    onBlur={() =>
                      this.setState(state => {
                        state.touched.vehicle_type.brand = true;
                        return { touched: state.touched };
                      })
                    }
                  />
                  <InputFeedback
                    error={touched.vehicle_type.brand && brand_error}
                  />
                </div>
              </div>
              <div className="col-lg-6 mb-2">
                <div className="form-group">
                  <label className="label-inside">Modelo</label>
                  <SelectWithRadio
                    value={selected_model}
                    options={model_options}
                    placeholder="Selecciona..."
                    noOptionsMessage={() => "No existen modelos aún..."}
                    onChange={this.changeModel}
                    classNamePrefix="RS-FIX"
                    className={
                      "react-select-fix appcar-input " +
                      classNames({
                        "is-invalid": touched.vehicle_type.model && model_error
                      })
                    }
                    isClearable
                    onBlur={() =>
                      this.setState(state => {
                        state.touched.vehicle_type.model = true;
                        return { touched: state.touched };
                      })
                    }
                  />
                  <InputFeedback
                    error={touched.vehicle_type.model && model_error}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-2">
                  <label className="label-inside" htmlFor="plate_number">Patente</label>
                  <input
                    type="text"
                    name="plate_number"
                    className={
                      "form-control appcar-input " +
                      classNames({
                        "is-invalid":
                          touched.plate_number && errors.plate_number
                      })
                    }
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    value={data.plate_number}
                  />
                  <InputFeedback
                    error={touched.plate_number && errors.plate_number}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-2">
                  <label className="label-inside" htmlFor="year">Año</label>
                  <input
                    type="number"
                    name="year"
                    min={1886}
                    max={new Date().getFullYear()}
                    className={
                      "form-control appcar-input " +
                      classNames({
                        "is-invalid": touched.year && errors.year
                      })
                    }
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    value={data.year}
                  />
                  <InputFeedback error={touched.year && errors.year} />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-2">
                  <label className="label-inside" htmlFor="color">Color</label>
                  <input
                    type="text"
                    name="color"
                    className={
                      "form-control appcar-input " +
                      classNames({
                        "is-invalid": touched.color && errors.color
                      })
                    }
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    value={data.color}
                  />
                  <InputFeedback error={touched.color && errors.color} />
                </div>
              </div>
              <div className="col-lg-6 mb-2">
                <div className="form-group">
                  <label className="label-inside">Tipo de combustible</label>
                  <SelectWithRadio
                    value={selected_fuel}
                    options={fuel_options}
                    placeholder="Selecciona..."
                    noOptionsMessage={() => "No existen combustibles aún..."}
                    onChange={this.changeFuelType}
                    classNamePrefix="RS-FIX"
                    className={
                      "react-select-fix appcar-input " +
                      classNames({
                        "is-invalid":
                          touched.fuel_type.description &&
                          errors.fuel_type.description
                      })
                    }
                    onBlur={() =>
                      this.setState(state => {
                        state.touched.fuel_type.description = true;
                        return { touched: state.touched };
                      })
                    }
                  />
                  <InputFeedback
                    error={
                      touched.fuel_type.description &&
                      errors.fuel_type.description
                    }
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-2">
                  <label className="label-inside" htmlFor="doors">Cantidad de puertas</label>
                  <input
                    type="number"
                    name="doors"
                    min={1}
                    className={
                      "form-control appcar-input " +
                      classNames({
                        "is-invalid": touched.doors && errors.doors
                      })
                    }
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    value={data.doors}
                  />
                  <InputFeedback error={touched.doors && errors.doors} />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-2">
                  <label className="label-inside">Categoría del vehículo</label>
                  <SelectWithRadio
                    value={selected_category}
                    options={categories_options}
                    placeholder="Selecciona..."
                    noOptionsMessage={() => "No existen categorías aún..."}
                    onChange={this.changeCategory}
                    classNamePrefix="RS-FIX"
                    className={
                      "react-select-fix appcar-input " +
                      classNames({
                        "is-invalid":
                          touched.vehicle_category.description &&
                          errors.vehicle_category.description
                      })
                    }
                    onBlur={() =>
                      this.setState(state => {
                        state.touched.vehicle_category.description = true;
                        return { touched: state.touched };
                      })
                    }
                  />
                  <InputFeedback
                    error={
                      touched.vehicle_category.description &&
                      errors.vehicle_category.description
                    }
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-2">
                  <label className="label-inside">Transmisión del vehículo</label>
                  <SelectWithRadio
                    value={selected_transmission}
                    options={transmissions_options}
                    placeholder="Selecciona..."
                    noOptionsMessage={() =>
                      "No existen tipos de transmisión aún..."
                    }
                    onChange={this.changeTransmission}
                    classNamePrefix="RS-FIX"
                    className={
                      "react-select-fix appcar-input " +
                      classNames({
                        "is-invalid":
                          touched.vehicle_transmission.description &&
                          errors.vehicle_transmission.description
                      })
                    }
                    onBlur={() =>
                      this.setState(state => {
                        state.touched.vehicle_transmission.description = true;
                        return { touched: state.touched };
                      })
                    }
                  />
                  <InputFeedback
                    error={
                      touched.vehicle_transmission.description &&
                      errors.vehicle_transmission.description
                    }
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-2">
                  <label className="label-inside" htmlFor="capacity">
                    Capacidad (Cantidad de asientos)
                  </label>
                  <input
                    type="number"
                    name="capacity"
                    min={1}
                    className={
                      "form-control appcar-input " +
                      classNames({
                        "is-invalid": touched.capacity && errors.capacity
                      })
                    }
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    value={data.capacity}
                  />
                  <InputFeedback error={touched.capacity && errors.capacity} />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-2">
                  <label className="label-inside" htmlFor="efficiency">Rendimiento (Km/L)</label>
                  <input
                    type="number"
                    name="efficiency"
                    min={0}
                    step={0.5}
                    className={
                      "form-control appcar-input " +
                      classNames({
                        "is-invalid": touched.efficiency && errors.efficiency
                      })
                    }
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    value={data.efficiency}
                  />
                  <InputFeedback
                    error={touched.efficiency && errors.efficiency}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-2">
                  <label className="label-inside" htmlFor="engine">N° Motor</label>
                  <input
                    type="text"
                    name="engine"
                    className={
                      "form-control appcar-input " +
                      classNames({
                        "is-invalid": touched.engine && errors.engine
                      })
                    }
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    value={data.engine}
                  />
                  <InputFeedback error={touched.engine && errors.engine} />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-2">
                  <label className="label-inside" htmlFor="chassis">N° Chasis</label>
                  <input
                    type="text"
                    name="chassis"
                    className={
                      "form-control appcar-input " +
                      classNames({
                        "is-invalid": touched.chassis && errors.chassis
                      })
                    }
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    value={data.chassis}
                  />
                  <InputFeedback error={touched.chassis && errors.chassis} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-buttons">
          <button
            className="btn btn-next btn-appcar-primary btn-lg pull-right"
            onClick={e => this.handleNext(e)}
          >
            Siguiente
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default VehicleData;
