import React, { Component, Fragment } from "react";
import { Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import Spinner from "../../common/Spinner";
import FlashMessage from "../../common/FlashMessage";
import axios from "axios";
import Select from "react-select";
import { AxiosBackofficeInterceptor } from "../../common/axios_interceptor";
axios.interceptors.response.use(undefined, AxiosBackofficeInterceptor);

const CitiesSchema = Yup.object().shape({
  description: Yup.string().required("Campo requerido"),
  country: Yup.object().shape({
    description: Yup.string().required("Campo requerido")
  })
});

class CitiesForm extends Component {
  state = {
    city: this.props.city || {
      description: "",
      country: {
        id: "",
        description: ""
      }
    },
    selected_country: this.props.city ? {
      label: this.props.city.country.description,
      value: this.props.city.country.id
    } : undefined,
    countries: this.props.countries,
    ui: {
      isSubmitting: false,
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined
    }
  };

  changeCountry = option => {
    const country = this.state.countries.find(
      country => country.id == option.value
    );
    this.setState(state => {
      state.city.country = country;
      state.selected_country = option;
      return { city: state.city, selected_country: state.selected_country };
    });
  };

  showMessage = msg => {
    this.setState(state => {
      state.ui.showMessage = msg.showMessage;
      state.ui.messageType = msg.messageType;
      state.ui.messages = msg.messages;
      state.ui.messageTitle = msg.messageTitle;
      return { ui: state.ui };
    });
  };

  render() {
    const flash_message = this.state.ui.showMessage ? (
      <FlashMessage
        type={this.state.ui.messageType}
        msg={this.state.ui.messages}
        title={this.state.ui.messageTitle}
      />
    ) : null;
    const button_label = this.props.city ? "Editar" : "Agregar nueva";
    const selected_country = this.state.selected_country;
    return (
      <Fragment>
        {flash_message}
        <Formik
          showMessage={this.showMessage}
          enableReinitialize={true}
          initialValues={this.state.city}
          validationSchema={CitiesSchema}
          onSubmit={(values, { setSubmitting }) => {
            // const token = document.getElementsByName("csrf-token")[0].content;
            const token = null;
            let url = "";
            let method = "";
            if (this.props.city) {
              url = `/backoffice/cities/${this.props.city.id}.json`;
              method = "PUT";
              values.id = parseInt(values.id);
            } else {
              url = `/backoffice/cities.json`;
              method = "POST";
            }
            axios(url, {
              method,
              url,
              responseType: "json",
              headers: {
                "Content-Type": "application/json"
              },
              data: { city: values }
            })
              .then(response => {
                const resp = response;
                if (response.data.success) {
                  window.location.assign(`/backoffice/cities?success=1`);
                } else {
                  this.showMessage({
                    showMessage: true,
                    messageType: "alert-warning",
                    messages: response.data.messages || [
                      "Ha ocurrido un error inesperado"
                    ],
                    messageTitle: "Alerta"
                  });
                }
              })
              .catch(error =>
                this.showMessage({
                  showMessage: true,
                  messageType: "alert-danger",
                  messages: ["Ha ocurrido un error inesperado"],
                  messageTitle: "Error"
                })
              )
              .then(() => setSubmitting(false));
          }}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => {
            const countries_options = this.state.countries.map(country => {
              return { label: country.description, value: country.id };
            });
            return (
              <Fragment>
                <Spinner loading={isSubmitting || this.state.ui.isSubmitting} />
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label htmlFor="description">Nombre de la ciudad</label>
                        <Field
                          type="text"
                          name="description"
                          className={
                            "form-control appcar-input " +
                            classNames({
                              "is-invalid":
                                touched.description && errors.description,
                            })
                          }
                        />
                        <ErrorMessage name="description">
                          {msg => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label htmlFor="country">Nombre del país</label>
                        <Select
                          value={selected_country}
                          options={countries_options}
                          placeholder="Selecciona..."
                          noOptionsMessage={() => "No existen países aún..."}
                          onChange={this.changeCountry}
                          classNamePrefix="RS-FIX"
                          className="react-select-fix"
                        />
                        <ErrorMessage name="country.description">
                          {msg => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-appcar-primary"
                    disabled={isSubmitting}
                  >
                    {`${button_label} ciudad`}
                  </button>
                </form>
              </Fragment>
            );
          }}
        </Formik>
      </Fragment>
    );
  }
}

export default CitiesForm;
