import React from "react";
import iconNoImage from "images/Icons/NoImageHorizontal.svg";

const RentalPreview = ({ vehicle, action, title }) => {
  const vType = vehicle.vehicle_type;
  const brand = vType?.brand || "-";
  const model = vType?.model || "-";
  const year = vehicle.year || "-";
  const plateNumber = vehicle.plate_number;
  const hasImages =
    Array.isArray(vehicle.vehicle_pictures) &&
    typeof vehicle.vehicle_pictures[0]?.url == "string";
  const vehicleImage = hasImages
    ? vehicle.vehicle_pictures[0].url + "/thumb"
    : iconNoImage;
  return (
    <div className="m-auto mt-4 mt-lg-0">
      <h5 className="fs-12 fw-900 pl-md-5 mb-3">{title}</h5>
      <div className="rental-preview pl-md-5">
        <div className="rental-vehicle-plate">
          <i className="mdi mdi-car text-appcar-secondary fs-16 mr-1" />
          <span className="rental-plate">{plateNumber}</span>
        </div>
        <div className="rental-preview-main">
          <div>
            <img
              src={vehicleImage}
              className={`vehicle-image ${!hasImages ? "no-image" : ""}`}
            />
          </div>
          <div className="rental-vehicle-info">
            <div className="rental-brand">{brand}</div>
            <div className="rental-model-year">{model}</div>
            <div className="rental-model-year">{year}</div>
            <div className="rental-edit-link" onClick={action}>
              <i className="mdi mdi-square-edit-outline fs-13" /> Editar datos
              del auto
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentalPreview;
