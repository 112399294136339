import React, { Component, Fragment } from "react";
import { Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import Spinner from "../../common/Spinner";
import FlashMessage from "../../common/FlashMessage";
import axios from "axios";
import { AxiosBackofficeInterceptor } from "../../common/axios_interceptor";
axios.interceptors.response.use(undefined, AxiosBackofficeInterceptor);

const VehicleTypesSchema = Yup.object().shape({
  brand: Yup.string().required("Campo requerido"),
  model: Yup.string().required("Campo requerido"),
  sura_info: Yup.object().shape({
    Cod_Marca: Yup.string().required("Campo requerido"),
    Cod_Modelo: Yup.string().required("Campo requerido"),
    Descripcion: Yup.string().required("Campo requerido")
  })
});

class VehicleTypesForm extends Component {
  state = {
    data:  this.props.vehicleType ||{
      brand: "",
      model: "",
      sura_info: {
        Cod_Marca: "",
        Cod_Modelo: "",
        Descripcion: ""
      }
    },
    ui: {
      isSubmitting: false,
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined
    }
  };

  showMessage = msg => {
    this.setState(state => {
      state.ui.showMessage = msg.showMessage;
      state.ui.messageType = msg.messageType;
      state.ui.messages = msg.messages;
      state.ui.messageTitle = msg.messageTitle;
      return { ui: state.ui };
    });
  };

  render() {
    const flash_message = this.state.ui.showMessage ? (
      <FlashMessage
        type={this.state.ui.messageType}
        msg={this.state.ui.messages}
        title={this.state.ui.messageTitle}
      />
    ) : null;
    return (
      <Fragment>
        {flash_message}
        <Formik
          showMessage={this.showMessage}
          enableReinitialize={true}
          initialValues={this.state.data}
          validationSchema={VehicleTypesSchema}
          onSubmit={(values, { setSubmitting }) => {
            // const token = document.getElementsByName("csrf-token")[0].content;
            const token = null;
            let url = "";
            let method = "";
            if (this.props.vehicleType) {
              url = `/backoffice/vehicle_types/${this.props.vehicleType.id}.json`;
              method = "PUT";
              values.id = parseInt(values.id);
            } else {
              url = `/backoffice/vehicle_types.json`;
              method = "POST";
            }
            axios(url, {
              method,
              url,
              responseType: "json",
              headers: {
                "Content-Type": "application/json"
              },
              data: { vehicle_type: values }
            })
              .then(response => {
                if (response.data.success) {
                  window.location.assign(`/backoffice/vehicle_types?success=1`);
                } else {
                  this.showMessage({
                    showMessage: true,
                    messageType: "alert-warning",
                    messages: response.data.messages || [
                      "Ha ocurrido un error inesperado"
                    ],
                    messageTitle: "Alerta"
                  });
                }
              })
              .catch(error =>
                this.showMessage({
                  showMessage: true,
                  messageType: "alert-danger",
                  messages: ["Ha ocurrido un error inesperado"],
                  messageTitle: "Error"
                })
              )
              .then(() => setSubmitting(false));
          }}
        >
          {({
            errors,
            touched,
            handleSubmit,
            isSubmitting
            /* and other goodies verde: #28a745 rojo: #dc3545 */
          }) => {
            const button_label = this.props.vehicleType
              ? "Editar"
              : "Agregar nuevo";
            const t_sura_info = touched.sura_info;
            const e_sura_info = errors.sura_info;
            return (
              <Fragment>
                <Spinner loading={isSubmitting || this.state.ui.isSubmitting} />
                <form onSubmit={handleSubmit} className="">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label htmlFor="brand">Nombre de la marca</label>
                        <Field
                          type="text"
                          name="brand"
                          className={
                            "form-control appcar-input " +
                            classNames({
                              "is-invalid": touched.brand && errors.brand
                            })
                          }
                        />
                        <ErrorMessage name="brand">
                          {msg => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label htmlFor="model">Nombre del modelo</label>
                        <Field
                          type="text"
                          name="model"
                          className={
                            "form-control appcar-input " +
                            classNames({
                              "is-invalid": touched.model && errors.model
                            })
                          }
                        />
                        <ErrorMessage name="model">
                          {msg => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                  <h4>Información de SURA</h4>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label htmlFor="sura_info.Cod_Marca">
                          Código de Marca
                        </label>
                        <Field
                          type="text"
                          name="sura_info.Cod_Marca"
                          className={
                            "form-control appcar-input " +
                            classNames({
                              "is-invalid":
                                t_sura_info &&
                                t_sura_info.Cod_Marca &&
                                e_sura_info &&
                                e_sura_info.Cod_Marca
                            })
                          }
                        />
                        <ErrorMessage name="sura_info.Cod_Marca">
                          {msg => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label htmlFor="sura_info.Cod_Modelo">
                          Código de Modelo
                        </label>
                        <Field
                          type="text"
                          name="sura_info.Cod_Modelo"
                          className={
                            "form-control appcar-input " +
                            classNames({
                              "is-invalid":
                                t_sura_info &&
                                t_sura_info.Cod_Modelo &&
                                e_sura_info &&
                                e_sura_info.Cod_Modelo
                            })
                          }
                        />
                        <ErrorMessage name="sura_info.Cod_Modelo">
                          {msg => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label htmlFor="sura_info.Descripcion">
                          Descripción
                        </label>
                        <Field
                          type="text"
                          name="sura_info.Descripcion"
                          className={
                            "form-control appcar-input " +
                            classNames({
                              "is-invalid":
                                t_sura_info &&
                                t_sura_info.Descripcion &&
                                e_sura_info &&
                                e_sura_info.Descripcion
                            })
                          }
                        />
                        <ErrorMessage name="sura_info.Descripcion">
                          {msg => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-appcar-primary"
                    disabled={isSubmitting}
                  >
                    {`${button_label} tipo de vehículo`}
                  </button>
                </form>
              </Fragment>
            );
          }}
        </Formik>
      </Fragment>
    );
  }
}

export default VehicleTypesForm;