import React, { Component } from "react";
import Qualification from "./Qualification";
import axios from "axios";
import FlashMessage from "../../common/FlashMessage";
import Spinner from "../../common/Spinner";
import { AxiosApplicationInterceptor } from "../../common/axios_interceptor";
axios.interceptors.response.use(undefined, AxiosApplicationInterceptor);

class VehicleQualification extends Component {
  constructor(props) {
    super(props);
    this.dependant_ratings = [
      { name: "steering", label: "Dirección" },
      { name: "shock_absorber", label: "Suspensión" },
      { name: "brakes", label: "Frenos" },
      { name: "lights", label: "Luces" },
      { name: "odors", label: "Olor" },
      { name: "noises", label: "Ruidos" }
    ];
    this.principal_rating = {
      name: "general_state",
      label: "Estado general"
    };
    this.state = {
      // would_recommend: false,
      ui: {
        isSubmitting: false,
        showMessage: false,
        messageType: undefined,
        messages: [],
        messageTitle: undefined
      },
      comments: ""
    };
    this.updateQualification = this.updateQualification.bind(this);
    this.handleSendVehicleQualification = this.handleSendVehicleQualification.bind(
      this
    );
    this.handleWouldRecomendCheck = this.handleWouldRecomendCheck.bind(this);
  }
  updateQualification(name, value) {
    const dependant_ratings = this.dependant_ratings.map(x => x.name);
    if (name === "general_state" && value >= 4) {
      this.setState({ [name]: value });
      dependant_ratings.map(x => this.setState({ [x]: value }));
    } else if (name === "general_state" && value < 4) {
      this.setState({ [name]: value });
      dependant_ratings.map(x => this.setState({ [x]: undefined }));
    } else {
      this.setState({ [name]: value });
    }
  }
  handleChangeComments = comments => {
    this.setState(state => {
      state.comments = comments;
      return {};
    });
  };
  handleWouldRecomendCheck(value) {
    this.setState({ would_recommend: value });
  }
  handleSendVehicleQualification() {
    this.setState(state => {
      state.ui.isSubmitting = true;
      return { ui: state.ui };
    });
    const url = `/vehicles/${this.props.vehicleId}/rating`;
    const method = "POST";
    let data = { ...this.state };
    delete data.ui;
    data.booking_id = this.props.bookingId;
    axios(url, {
      method,
      url,
      responseType: "json",
      headers: {
        "Content-Type": "application/json"
      },
      data: { vehicle_qualification: data }
    })
      .then(response => {
        if (response.data.success) {
          this.showMessage({
            showMessage: true,
            messageType: "alert-success",
            messages: ["Evaluación guardada..."],
            messageTitle: "Éxito"
          });
          setTimeout(() => {
            this.props.callback();
          }, 750);
        } else {
          this.showMessage({
            showMessage: true,
            messageType: "alert-warning",
            messages: response.data.messages || [
              "Ha ocurrido un error inesperado"
            ],
            messageTitle: "Alerta"
          });
        }
      })
      .catch(error => {
        console.log("ERROR", error);
        this.showMessage({
          showMessage: true,
          messageType: "alert-danger",
          messages: ["Ha ocurrido un error inesperado"],
          messageTitle: "Error"
        });
      })
      .finally(() =>
        this.setState(state => {
          state.ui.isSubmitting = false;
          return { ui: state.ui };
        })
      );
  }

  showMessage = msg => {
    this.setState(state => {
      state.ui.showMessage = msg.showMessage;
      state.ui.messageType = msg.messageType;
      state.ui.messages = msg.messages;
      state.ui.messageTitle = msg.messageTitle;
      return { ui: state.ui };
    });
  };

  render() {
    const flash_message = this.state.ui.showMessage ? (
      <FlashMessage
        type={this.state.ui.messageType}
        msg={this.state.ui.messages}
        title={this.state.ui.messageTitle}
      />
    ) : null;

    const buttons = this.dependant_ratings.map((x, i) => (
      <Qualification
        label={x.label}
        key={i}
        name={x.name}
        visible={this.state["general_state"] < 4}
        value={this.state[x.name]}
        onRatingChange={this.updateQualification}
      />
    ));

    const checkWouldRecommend = (
      <div className="form-group custom-control custom-checkbox mt-2">
        <input
          type="checkbox"
          className="custom-control-input"
          id="wouldrecommend"
          onChange={e => this.handleWouldRecomendCheck(e.target.checked)}
        />
        <label className="custom-control-label" htmlFor="wouldrecommend">
          ¿Lo recomendaría?
        </label>
      </div>
    );
    return (
      <div>
        <h2>¡Califica el vehículo!</h2>
        {flash_message}
        <Qualification
          label={"Estado general"}
          name={"general_state"}
          visible={true}
          value={this.state["general_state"]}
          onRatingChange={this.updateQualification}
        />
        {buttons}
        <br className="my-2" />
        <div className="form-group">
          <h4>¿Quieres dejar un comentario?</h4>
          <textarea
            rows={3}
            onChange={e => this.setState({ comments: e.target.value })}
            className="form-control appcar-input"
          />
        </div>
        <button
          className={"btn btn-appcar-primary"}
          onClick={() => this.handleSendVehicleQualification()}
        >
          Calificar
        </button>
        <Spinner loading={this.state.ui.isSubmitting} />
      </div>
    );
  }
}

export default VehicleQualification;
