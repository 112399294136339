import React from "react";
import SelectWithRadio from "../../common/components/select/SelectWithRadio";

const CategorySelector = ({
  title,
  postCategories,
  url_action,
  selectedCategoryId,
}) => {
  const options = postCategories.map((category) => ({
    value: category.id,
    label: category.description,
  }));
  const selectedCategory = selectedCategoryId
    ? postCategories.find((x) => x.id == selectedCategoryId)
    : null;
  const selectedOption = selectedCategory
    ? {
        value: selectedCategory.id,
        label: selectedCategory.description,
      }
    : null;
  return (
    <div className="row">
      <div className="col-auto">
        <h2>{title}</h2>
      </div>
      <div className="col col-md-6 col-lg-4 col-xl-3 form-group">
        <SelectWithRadio
          value={selectedOption}
          options={options}
          placeholder="Todos"
          noOptionsMessage={() => "No hay tipos de documento"}
          onChange={(e) => {
            const params = e?.value ? "?post_category_id=" + e.value : "";
            Turbolinks.visit(url_action + params + "#blog-title");
          }}
          classNamePrefix="RS-FIX"
          className="react-select-fix appcar-input turbolinks-disable-scroll"
          name="post_category_id"
          isClearable={true}
        />
      </div>
    </div>
  );
};

export default CategorySelector;
