import React, { Component, Fragment } from "react";
import { Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import Spinner from "../../common/Spinner";
import FlashMessage from "../../common/FlashMessage";
import axios from "axios";
import { AxiosBackofficeInterceptor } from "../../common/axios_interceptor";
import { validateRUT } from "../../common/constants";
axios.interceptors.response.use(undefined, AxiosBackofficeInterceptor);

const AddToBlacklistSchema = Yup.object().shape({
  rut: Yup.string()
    .matches(/^0*(\d{1,3}(\.?\d{3})*)\-?([\dkK])$/, "RUT inválido")
    .required("RUT inválido"),
  source: Yup.string(),
  name: Yup.string()
});

class BlackListForm extends Component {
  state = {
    data: {
      rut: "",
      source: "",
      name: "",
    },
    error: "",
    ui: {
      isSubmitting: false,
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined
    }
  }

  render() {
    const flash_message = this.state.ui.showMessage ? (
      <FlashMessage
        type={this.state.ui.messageType}
        msg={this.state.ui.messages}
        title={this.state.ui.messageTitle}
      />
    ) : null;
    const stateError = this.state.error;
    return (
      <Fragment>
        {flash_message}
        <Formik
          showMessage={this.showMessage}
          enableReinitialize={true}
          initialValues={this.state.data}
          validationSchema={AddToBlacklistSchema}
          onSubmit={(values, { setSubmitting }) => {
            // const token = document.getElementsByName("csrf-token")[0].content;
            if (!validateRUT(values.rut)) {
              this.setState({ error: "RUT inválido" }, () => {
                setSubmitting(false);
              });
              return;
            } else {
              this.setState({ error: false });
            }
            axios("/backoffice/blacklist.json", {
              method: "POST",
              url: "/backoffice/blacklist.json",
              responseType: "json",
              headers: {
                "Content-Type": "application/json"
              },
              data: { blacklist: values }
            })
              .then(response => {
                if (response.data.success) {
                  window.location.assign(
                    `/backoffice/blacklist?success=1`
                  );
                } else {
                  this.showMessage({
                    showMessage: true,
                    messageType: "alert-warning",
                    messages: response.data.messages || [
                      "Ha ocurrido un error inesperado"
                    ],
                    messageTitle: "Alerta"
                  });
                }
              })
              .catch(error =>
                this.showMessage({
                  showMessage: true,
                  messageType: "alert-danger",
                  messages: ["Ha ocurrido un error inesperado"],
                  messageTitle: "Error"
                })
              )
              .then(() => setSubmitting(false));
          }}
        >
          {({
            errors,
            touched,
            handleSubmit,
            isSubmitting
          }) => {
            return (
              <Fragment>
                <Spinner loading={isSubmitting || this.state.ui.isSubmitting} />
                <form onSubmit={handleSubmit}>
                  <div className="row mt-3">
                    <div className="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-0">
                      <div className="form-group mb-3">
                        <label htmlFor="rut">RUT</label>
                        <Field
                          type="text"
                          name="rut"
                          className={
                            "form-control appcar-input " +
                            classNames({
                              "is-invalid":
                                stateError || touched.rut && errors.rut
                            })
                          }
                          placeholder="Ingrese rut..."
                        />
                        {(<ErrorMessage name="rut">
                          {msg => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>) || stateError ? (
                          <div className="invalid-feedback" style={{ display: "block" }}>{stateError}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-0">
                      <div className="form-group mb-3">
                        <label htmlFor="source">Origen</label>
                        <Field
                          type="text"
                          name="source"
                          className={
                            "form-control appcar-input " +
                            classNames({
                              "is-invalid":
                                touched.source && errors.source
                            })
                          }
                          placeholder="Origen"
                        />
                        <ErrorMessage name="source">
                          {msg => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-0">
                      <div className="form-group mb-3">
                        <label htmlFor="name">Descripción</label>
                        <Field
                          type="text"
                          name="name"
                          className={
                            "form-control appcar-input " +
                            classNames({
                              "is-invalid":
                                touched.name && errors.name
                            })
                          }
                          placeholder="Descripción"
                        />
                        <ErrorMessage name="name">
                          {msg => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-0">
                      <div className="row">
                        <div className="col-auto mr-auto">
                          <button
                            type="submit"
                            className="btn btn-appcar-primary mr-4"
                            disabled={isSubmitting}
                          >
                            Añadir a la lista negra
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </Fragment>
            );
          }}
        </Formik>
      </Fragment>
    )
  }
}

export default BlackListForm;
