import React from "react";
import iconStare from "images/IconStare.png";

const PasswordModal = ({
  user,
  onChange,
  action,
  validateEmail,
  forgotPassword,
  title = "Solicitar reserva",
  modalRef = null,
  hideDriverAlert = false,
}) => (
  <div
    className="modal fade"
    id="PasswordModal"
    aria-hidden="true"
    aria-labelledby="PasswordModalLabel"
    tabIndex={-1}
    ref={modalRef}
  >
    <div className="modal-dialog modal-dialog-centered modal-appcar">
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="btn-m-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            x
          </button>
          <h5 className="modal-title" id="PasswordModalLabel">
            {title}
          </h5>
        </div>
        <div
          className={`modal-body mx-auto ${hideDriverAlert ? "pb-4" : "pb-0"}`}
          style={{ maxWidth: 360, paddingTop: 39 }}
        >
          <div
            tabIndex={0}
            className="text-center mb-4"
            onKeyDown={(e) => {
              // Submit on enter
              if (e.key === "Enter") {
                // Verify if is valid email
                if (validateEmail(user.email) && user.password) {
                  action();
                }
              }
            }}
          >
            <h4 className="fs-25 fw-900 mb-4">¡Hola {user.name}!</h4>
            <h5 className="fs-13 fw-700 mb-3">
              Por favor ingresa tu contraseña:
            </h5>
            <div className="form-group text-left">
              <label className="label-inside">Tu email</label>
              <input
                type="email"
                className="form-control appcar-input"
                value={user.email}
                onChange={(e) => onchange(e.target.value, "email")}
              />
            </div>
            <div className="form-group text-left mb-4">
              <label className="label-inside">Tu contraseña</label>
              <input
                type="password"
                id="notlogged__password"
                className="form-control appcar-input"
                value={user.password}
                onChange={(e) => onChange(e.target.value, "password")}
              />
            </div>
            <button
              type="button"
              onClick={() => action()}
              className="btn btn-appcar-primary btn-slim w-50"
              disabled={!validateEmail(user.email) || !user.password}
            >
              Siguiente
            </button>
            <h5
              className="my-4 fs-11 fw-900 cursor-pointer"
              onClick={() => forgotPassword()}
            >
              Olvidé mi contraseña
            </h5>
            {hideDriverAlert ? null : (
              <div className="alert-advice">
                <div className="alert-icon">
                  <img src={iconStare} height={22} />
                </div>
                <div className="alert-text">
                  Recuerda que únicamente el Driver que solicita la reserva
                  puede retirar el auto
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PasswordModal;
