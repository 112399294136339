import React, { Component } from "react";
import Axios from "axios";
import { AxiosApplicationInterceptor } from "../../common/axios_interceptor";
import moment from "moment";
import BookingModal from "../../common/legal_modals/BookingModal";
import PoliciesSummary from "../rentals/show/PoliciesSummary";
import { constants, numeral, BookingStatus } from "../../common/constants";
import RentalSchedule from "../rentals/schedule/RentalSchedule";
import Spinner from "../../common/Spinner";
import Alert from "../../common/components/alert/Alert";
import StartedIcon from "images/Icons/StartedIconInverted.png";
import PendingIcon from "images/Icons/PendingIconInverted.png";
import ConfirmedIcon from "images/Icons/ConfirmedIconInverted.png";
import ModalMercadoPago from "../rentals/show/modals/ModalMercadoPago";
import ModalDates from "../rentals/show/modals/ModalDates";
import UserFormModal from "../rentals/show/modals/UserFormModal";
import PaymentMethodModal from "../rentals/show/modals/PaymentMethodModal";
import AfterBookingModal from "../rentals/show/modals/AfterBookingModal";
import NeedMoreDataModal from "../rentals/show/modals/NeedMoreDataModal";
import ExtrasContainer from "../rentals/show/containers/ExtrasContainer";
import LocationContainer from "../rentals/show/containers/LocationContainer";
import ModalPricing from "../rentals/show/modals/ModalPricing";
import ModalLocation from "../rentals/show/modals/ModalLocation";
import DatesContainer from "../rentals/show/containers/DatesContainer";
import FailedPaymentModal from "./FailedPaymentModal";
import SantanderContainer from "../rentals/show/containers/SantanderContainer";
Axios.interceptors.response.use(undefined, AxiosApplicationInterceptor);

export default class BookingEdit extends Component {
  state = {
    fromDate: moment(constants.RENTAL_SEARCH_FROM).format("DD/MM/YYYY"),
    fromTime: moment(constants.RENTAL_SEARCH_FROM).format("HH:mm"),
    untilDate: moment(constants.RENTAL_SEARCH_UNTIL).format("DD/MM/YYYY"),
    untilTime: moment(constants.RENTAL_SEARCH_UNTIL).format("HH:mm"),
    error: false,
    success: false,
    hasQuoted: false,
    newFrom: moment(constants.RENTAL_SEARCH_FROM).format(""),
    newTo: moment(constants.RENTAL_SEARCH_UNTIL).format(""),
    selectedPickup: {
      label:
        (this.props.rental.location && this.props.rental.location.name) || "",
      value:
        (this.props.rental.location && this.props.rental.location.name) || "",
    },
    selectedDropoff: {
      label:
        (this.props.rental.location && this.props.rental.location.name) || "",
      value:
        (this.props.rental.location && this.props.rental.location.name) || "",
    },
    isSubmitting: false,
    extrasIds: [],
    user: this.props.user,
    needInvoice: false,
    termsAndConditionsAgreement: false,
    loadMP: false,
    pricing: {
      total: 0,
      details: [],
      extra_charges: [],
      commission: 0,
      insurance_charge: 0,
      payment_method_fee: 0,
      value_added_tax: 0,
      pick_up_charge: null,
      drop_off_charge: null,
      clf_value: 0.0,
      owner_total: 0.0,
      discount: 0.0,
    },
    alert: {
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined,
    },
    mapInfo: {
      lat: 0,
      lng: 0,
      locationAddress: "",
    },
  };

  modalAgreement = null;
  modalMP = null;
  dataLayer = dataLayer;

  modalUserForm = null;
  modalPaymentMethod = null;
  modalAfterBooking = null;
  modalNeedMoreData = null;
  modalDates = null;
  modalPricingDetails = null;
  modalLocationMap = null;
  modalFailedPayment = null;

  componentDidMount() {
    moment.locale("es");

    const { booking } = this.props;
    const {
      selected_pickup_location,
      selected_dropoff_location,
    } = booking.pricing;
    const selectedPickup =
      selected_pickup_location && selected_pickup_location.location
        ? {
            label:
              selected_pickup_location.location.name +
              ` (${numeral(selected_pickup_location.price).format("$0,0")})`,
            value: selected_pickup_location.location.name,
          }
        : undefined;
    const selectedDropoff =
      selected_dropoff_location && selected_dropoff_location.location
        ? {
            label:
              selected_dropoff_location.location.name +
              ` (${numeral(selected_dropoff_location.price).format("$0,0")})`,
            value: selected_dropoff_location.location.name,
          }
        : undefined;
    const from = moment(booking.date_from);
    const until = moment(booking.date_until);
    const fromDate = from.format("DD/MM/YYYY");
    const fromTime = from.format("HH:mm");
    const untilDate = until.format("DD/MM/YYYY");
    const untilTime = until.format("HH:mm");
    const extrasIds = this.props.booking.pricing.extra_charges.map(
      (x) => x.vehicle_extra.id
    );

    this.setState(
      {
        fromDate,
        fromTime,
        untilDate,
        untilTime,
        selectedDropoff,
        selectedPickup,
        extrasIds,
        newFrom: from.format(),
        newTo: until.format(),
      },
      () => {
        this.calculatePrice();
        this.initializeCalendars();
        this.modalAgreement = new bootstrap.Modal(
          document.getElementById("legalModal")
        );

        // Instantiate all modals
        this.modalMP = new bootstrap.Modal(document.getElementById("MPModal"));
        this.modalUserForm = new bootstrap.Modal(
          document.getElementById("UserFormModal")
        );
        this.modalDates = new bootstrap.Modal(
          document.getElementById("datesModal")
        );
        this.modalPaymentMethod = new bootstrap.Modal(
          document.getElementById("PaymentMethodModal")
        );
        this.modalAfterBooking = new bootstrap.Modal(
          document.getElementById("AfterBookingModal")
        );
        this.modalNeedMoreData = new bootstrap.Modal(
          document.getElementById("NeedMoreDataModal")
        );
        this.modalPricingDetails = new bootstrap.Modal(
          document.getElementById("pricingDetailsModal")
        );
        this.modalLocationMap = new bootstrap.Modal(
          document.getElementById("modalLocation")
        );
        this.modalFailedPayment = new bootstrap.Modal(
          document.getElementById("failedPaymentModal")
        );
      }
    );
  }

  checkImportantData = () => {
    // Check important data: name, last_name, phone_number, id_number (email already exists)
    const user = this.state.user;
    if (!user) return false;
    if (user.name && user.last_name && user.phone_number && user.id_number) {
      return true;
    } else return false;
  };

  checkContinuationData = () => {
    if (this.checkImportantData()) {
      const user = this.state.user;
      const checkDriverLicense =
        Array.isArray(user.driver_license) && user.driver_license.length > 0;
      const checkDocumentPictures =
        Array.isArray(user.document_pictures) &&
        user.document_pictures.length > 1;
      if (checkDriverLicense && checkDocumentPictures) {
        this.modalAfterBooking.show();
      } else {
        localStorage.setItem("need_to_complete_data", "1");
        this.modalNeedMoreData.show();
      }
    }
  };

  checkPaymentData = () => {
    // Check MercadoPago
    const user = this.props.user;
    if (!user) return false;
    // Check if cards is array, have at least 1 card and the first card has "last_four_digits"
    if (
      Array.isArray(user.mercadopago?.cards) &&
      user.mercadopago.cards.length > 0 &&
      !!user.mercadopago.cards[0].last_four_digits
    ) {
      return true;
    } else return false;
  };

  initializeCalendars = () => {
    const this_ = this;
    const booking = this_.props.booking;
    const options = {
      showDropdowns: true,
      maxYear: constants.MAX_YEAR,
      singleDatePicker: true,
      minDate: moment(),
      maxDate: moment(this_.props.rental.date_until),
      locale: {
        format: "DD/MM/YYYY",
        cancelLabel: "Cancelar",
        applyLabel: "Aplicar",
        fromLabel: "Desde",
        toLabel: "Hasta",
        daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        firstDay: 1,
      },
    };

    let dateFrom = null;

    if (booking.status.id != BookingStatus.INICIADO) {
      dateFrom = $("#date_from").daterangepicker(options, (start) => {
        let { fromDate, fromTime, untilDate, untilTime } = this_.state;
        const fromHours = parseInt(fromTime.split(":")[0]);
        const fromMinutes = parseInt(fromTime.split(":")[1]);
        const untilHours = parseInt(untilTime.split(":")[0]);
        const untilMinutes = parseInt(untilTime.split(":")[1]);
        const from = moment(start)
          .hours(fromHours)
          .minutes(fromMinutes);
        let until = moment(untilDate, "DD/MM/YYYY")
          .hours(untilHours)
          .minutes(untilMinutes);

        if (until.valueOf() < from.valueOf()) {
          until = from.clone();
          $("#date_until")
            .data("daterangepicker")
            .setStartDate(until.format("DD/MM/YYYY"));
          $("#date_until")
            .data("daterangepicker")
            .setEndDate(until.format("DD/MM/YYYY"));
          untilDate = until.format("DD/MM/YYYY");
          untilTime = until.format("HH:mm");
        }
        fromDate = from.format("DD/MM/YYYY");
        const fromString = moment(
          fromDate + fromTime,
          "DD/MM/YYYYHH:mm"
        ).format();
        const untilString = moment(
          untilDate + untilTime,
          "DD/MM/YYYYHH:mm"
        ).format();
        localStorage.setItem("user_date_from", fromString);
        localStorage.setItem("user_date_until", untilString);
        let alert = this.state.alert;
        alert.showMessage = false;
        this_.setState(
          {
            fromDate,
            untilDate,
            untilTime,
            alert,
            hasQuoted: false,
            error: false,
            success: false,
          },
          () => this.calculatePrice()
        );
      });
    }

    const dateUntil = $("#date_until").daterangepicker(options, (start) => {
      let { fromDate, fromTime, untilDate, untilTime } = this_.state;
      const fromHours = parseInt(fromTime.split(":")[0]);
      const fromMinutes = parseInt(fromTime.split(":")[1]);
      const untilHours = parseInt(untilTime.split(":")[0]);
      const untilMinutes = parseInt(untilTime.split(":")[1]);
      let from = moment(fromDate, "DD/MM/YYYY")
        .hours(fromHours)
        .minutes(fromMinutes);
      const until = moment(start)
        .hours(untilHours)
        .minutes(untilMinutes);

      if (until.valueOf() < from.valueOf()) {
        from = until.clone();
        $("#date_from")
          .data("daterangepicker")
          .setStartDate(from.format("DD/MM/YYYY"));
        $("#date_from")
          .data("daterangepicker")
          .setEndDate(from.format("DD/MM/YYYY"));
        fromDate = from.format("DD/MM/YYYY");
        fromTime = from.format("HH:mm");
      }
      untilDate = until.format("DD/MM/YYYY");
      const fromString = moment(
        fromDate + fromTime,
        "DD/MM/YYYYHH:mm"
      ).format();
      const untilString = moment(
        untilDate + untilTime,
        "DD/MM/YYYYHH:mm"
      ).format();
      localStorage.setItem("user_date_from", fromString);
      localStorage.setItem("user_date_until", untilString);
      let alert = this.state.alert;
      alert.showMessage = false;
      this_.setState(
        {
          fromDate,
          fromTime,
          untilDate,
          alert,
          hasQuoted: false,
          error: false,
          success: false,
        },
        () => this.calculatePrice()
      );
    });

    const blockedRanges = this.props.blockedRanges || [];
    if (booking.status.id != BookingStatus.INICIADO) {
      dateFrom.isInvalidDate = (date) => {
        let disable = false;
        for (let event of blockedRanges) {
          disable = false;
          // Se considera que el día en el que inicia y finaliza el evento se libera por completo
          const eventMinValue = moment(event.from)
            .endOf("d")
            .valueOf();
          const eventMaxValue = moment(event.until)
            .startOf("d")
            .valueOf();

          const dateValue = moment(date).valueOf();
          if (dateValue > eventMinValue && dateValue < eventMaxValue) {
            if (event.booking_id == booking.id) break;
            disable = true;
            break;
          }
        }
        return disable;
      };
    }
    dateUntil.isInvalidDate = (date) => {
      let disable = false;
      for (let event of blockedRanges) {
        disable = false;
        // Se considera que el día en el que inicia y finaliza el evento se libera por completo
        const eventMinValue = moment(event.from)
          .endOf("d")
          .valueOf();
        const eventMaxValue = moment(event.until)
          .startOf("d")
          .valueOf();

        const dateValue = moment(date).valueOf();
        if (dateValue > eventMinValue && dateValue < eventMaxValue) {
          if (event.booking_id == booking.id) break;
          disable = true;
          break;
        }
      }
      return disable;
    };
  };

  changeFromTime = (fromTime) => {
    if (this.props.booking.status.id != BookingStatus.INICIADO) {
      let { fromDate, untilDate, untilTime } = this.state;
      const from = moment(fromDate + fromTime, "DD/MM/YYYYHH:mm");
      const until = moment(untilDate + untilTime, "DD/MM/YYYYHH:mm");
      if (until.valueOf() < from.valueOf()) {
        until = from.clone();
        $("#date_until")
          .data("daterangepicker")
          .setStartDate(until.format("DD/MM/YYYY"));
        $("#date_until")
          .data("daterangepicker")
          .setEndDate(until.format("DD/MM/YYYY"));
        untilDate = until.format("DD/MM/YYYY");
        untilTime = until.format("HH:mm");
      }
      const fromString = moment(
        fromDate + fromTime,
        "DD/MM/YYYYHH:mm"
      ).format();
      const untilString = moment(
        untilDate + untilTime,
        "DD/MM/YYYYHH:mm"
      ).format();
      localStorage.setItem("user_date_from", fromString);
      localStorage.setItem("user_date_until", untilString);
      let alert = this.state.alert;
      alert.showMessage = false;
      this.setState(
        {
          fromTime,
          untilDate,
          untilTime,
          alert,
          hasQuoted: false,
          error: false,
          success: false,
        },
        () => this.calculatePrice()
      );
    }
  };

  changeUntilTime = (untilTime) => {
    let { fromDate, fromTime, untilDate } = this.state;
    const from = moment(fromDate + fromTime, "DD/MM/YYYYHH:mm");
    const until = moment(untilDate + untilTime, "DD/MM/YYYYHH:mm");
    if (until.valueOf() < from.valueOf()) {
      from = until.clone();
      $("#date_from")
        .data("daterangepicker")
        .setStartDate(until.format("DD/MM/YYYY"));
      $("#date_from")
        .data("daterangepicker")
        .setEndDate(until.format("DD/MM/YYYY"));
      fromDate = from.format("DD/MM/YYYY");
      fromTime = from.format("HH:mm");
    }
    const fromString = moment(fromDate + fromTime, "DD/MM/YYYYHH:mm").format();
    const untilString = moment(
      untilDate + untilTime,
      "DD/MM/YYYYHH:mm"
    ).format();
    localStorage.setItem("user_date_from", fromString);
    localStorage.setItem("user_date_until", untilString);
    let alert = this.state.alert;
    alert.showMessage = false;
    this.setState(
      {
        fromDate,
        fromTime,
        untilTime,
        alert,
        hasQuoted: false,
        error: false,
        success: false,
      },
      () => this.calculatePrice()
    );
  };

  componentDidUpdate() {
    const dateFrom = $("#date_from").data("daterangepicker");
    const dateUntil = $("#date_until").data("daterangepicker");
    const booking = this.props.booking;
    const blockedRanges = this.props.blockedRanges || [];
    if (dateFrom && dateUntil) {
      dateFrom.setStartDate(
        moment(this.state.fromDate, "DD/MM/YYYY").format("DD/MM/YYYY")
      );
      dateFrom.setEndDate(
        moment(
          this.state.fromDate + this.state.fromTime,
          "DD/MM/YYYYHH:mm"
        ).format("DD/MM/YYYY")
      );
      dateUntil.setStartDate(
        moment(
          this.state.untilDate + this.state.untilTime,
          "DD/MM/YYYYHH:mm"
        ).format("DD/MM/YYYY")
      );
      dateUntil.setEndDate(
        moment(
          this.state.untilDate + this.state.untilTime,
          "DD/MM/YYYYHH:mm"
        ).format("DD/MM/YYYY")
      );
      if (this.props.booking.status.id != BookingStatus.INICIADO) {
        dateFrom.isInvalidDate = (date) => {
          let disable = false;
          for (let event of blockedRanges) {
            disable = false;
            // Se considera que el día en el que inicia y finaliza el evento se libera por completo
            const eventMinValue = moment(event.from)
              .endOf("d")
              .valueOf();
            const eventMaxValue = moment(event.until)
              .startOf("d")
              .valueOf();

            const dateValue = moment(date).valueOf();
            if (dateValue > eventMinValue && dateValue < eventMaxValue) {
              if (event.booking_id == booking.id) break;
              disable = true;
              break;
            }
          }
          return disable;
        };
      }
      dateUntil.isInvalidDate = (date) => {
        let disable = false;
        for (let event of blockedRanges) {
          disable = false;
          // Se considera que el día en el que inicia y finaliza el evento se libera por completo
          const eventMinValue = moment(event.from)
            .endOf("d")
            .valueOf();
          const eventMaxValue = moment(event.until)
            .startOf("d")
            .valueOf();

          const dateValue = moment(date).valueOf();
          if (dateValue > eventMinValue && dateValue < eventMaxValue) {
            if (event.booking_id == booking.id) break;
            disable = true;
            break;
          }
        }
        return disable;
      };
    }
  }

  showMessage = (messageType, messageTitle, messages) =>
    this.setState(
      (state) => {
        state.alert.showMessage = true;
        state.alert.messageTitle = messageTitle;
        state.alert.messages = messages;
        state.alert.messageType = messageType;
        return { alert: state.alert };
      },
      () => setTimeout(this.handleAlertClose, 3000)
    );

  handleAlertClose = () =>
    this.setState((state) => {
      state.alert.showMessage = false;
      return { alert: state.alert };
    });

  updateUser = () => {
    this.setState({ isSubmitting: true });
    const { user, needInvoice } = this.state;
    let url = "";
    let method = "";
    let params = {};
    params.user = user;
    // If need invoice then check if user has billing info, else create base billing info
    params.user.billing_info ??= {
      rut: "",
      name: "",
      address: "",
      city: "",
      municipality: "",
      phone: "",
      business: "",
      email: "",
    };
    if (needInvoice) {
      params.user.billing_info.rut =
        params.user.billing_info.rut || user.id_number;
    }
    url = `/owners/${user.id}.json?need_reload=true`;
    method = "PUT";
    Axios({
      method,
      url,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    })
      .then((response) => {
        if (response.data.success) {
          this.setState({ user: response.data.user });
          this.modalUserForm.hide();
          if (this.checkPaymentData()) {
            // Continue to show modal of confirmation
            this.modalAgreement.show();
          } else {
            // Show modal with mercadopago's form
            this.showMercadoPagoModal();
          }
        } else {
          this.showMessage(
            "primary",
            "Error",
            response.data.messages || ["Ha ocurrido un error inesperado"]
          );
        }
      })
      .catch((error) => {
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ]);
      })
      .finally(() => {
        this.setState({ isSubmitting: false });
      });
  };

  reloadUser = () => {
    this.setState({ isSubmitting: true });
    Axios({
      method: "GET",
      url: `/owners/current.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.data) {
          this.setState({ user: response.data });
        } else {
          this.showMessage("primary", "Error", [
            "Ha ocurrido un error inesperado",
          ]);
        }
      })
      .catch((error) => {
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ]);
      })
      .finally(() => this.setState({ isSubmitting: false }));
  };

  createParams = () => {
    this.setState((state) => {
      state.alert.showMessage = false;
      state.alert.messageType = undefined;
      state.alert.messages = [];
      state.alert.messageTitle = undefined;
      return { isSubmitting: true, alert: state.alert };
    });
    const {
      selectedPickup,
      selectedDropoff,
      extrasIds,
      fromDate,
      fromTime,
      untilDate,
      untilTime,
    } = this.state;
    const rental = this.props.rental;
    const newFrom = moment(fromDate + fromTime, "DD/MM/YYYYHH:mm").format();
    const newTo = moment(untilDate + untilTime, "DD/MM/YYYYHH:mm").format();

    let rental_pricing = {
      rental_id: rental.id,
      start: newFrom,
      end: newTo,
      extras_ids: extrasIds,
    };
    if (!!selectedPickup && selectedPickup.value != rental.location.name) {
      rental_pricing.special_pickup = selectedPickup.value;
    }
    if (!!selectedDropoff && selectedDropoff.value != rental.location.name) {
      rental_pricing.special_dropoff = selectedDropoff.value;
    }
    return rental_pricing;
  };

  calculatePrice = (e) => {
    const rental_pricing = this.createParams();
    Axios({
      method: "POST",
      url: `/rentals/${this.props.rental.id}/calculate_pricing.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: { rental_pricing },
    })
      .then((response) => {
        if (response.data.success) {
          const pricing = response.data.pricing;
          this.setState({
            hasQuoted: true,
            pricing,
            error: false,
            newFrom: rental_pricing.start,
            newTo: rental_pricing.end,
          });
        } else {
          this.showMessage(
            "primary",
            "Alerta",
            response.data.messages || ["Ha ocurrido un error inesperado"]
          );
        }
      })
      .catch((error) => {
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ]);
      })
      .finally(() => this.setState({ isSubmitting: false }));
  };

  addExtra = (extra) => {
    this.setState(
      (state) => {
        state.extrasIds.push(extra);
        return { extrasIds: state.extrasIds, hasQuoted: false, success: false };
      },
      () => this.calculatePrice()
    );
  };

  removeExtra = (extra) => {
    const index = this.state.extrasIds.findIndex((x) => x === extra);
    this.setState(
      (state) => {
        state.extrasIds.splice(index, 1);
        return { extrasIds: state.extrasIds, hasQuoted: false, success: false };
      },
      () => this.calculatePrice()
    );
  };

  handleChangeCheckedExtra = (checked, extra) => {
    if (checked) {
      this.addExtra(extra);
    } else {
      this.removeExtra(extra);
    }
  };

  changeLocation = (option, obj) =>
    this.setState(
      {
        hasQuoted: false,
        error: false,
        success: false,
        [obj]: option,
      },
      () => this.calculatePrice()
    );

  createBooking = () => {
    let booking = this.createParams();
    Axios({
      method: "PUT",
      url: `/bookings/${this.props.booking.id}.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: { booking },
    })
      .then((response) => {
        if (response.data.success) {
          this.setState({ success: true, error: false });
          this.modalAgreement.hide();
          this.showMessage("primary", "Éxito", ["Reserva modificada"]);
          this.checkContinuationData();
        } else {
          this.showMessage(
            "primary",
            "Alerta",
            response.data.messages || ["Ha ocurrido un error inesperado"]
          );
          if (response.data.payment_failed) {
            this.modalAgreement.hide();
            this.modalFailedPayment.show();
          }
        }
      })
      .catch((error) => {
        // console.log(error);
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ]);
      })
      .finally(() => this.setState({ isSubmitting: false }));
  };

  showModalLogin = () => {
    this.modalLogin.show();
    document.getElementById("notlogged__email").focus();
  };

  addPaymentMethod = () => {
    this.setState({ loadMP: true }, () => this.modalPaymentMethod.show());
  };

  completeProfile = () => {
    if (this.props.user && this.props.user.id) {
      this.modalUserForm.show();
    } else this.showModalLogin();
  };

  showMercadoPagoModal = () => {
    const user = this.props.user;
    if (user.oneclick && user.oneclick.tbk_user) {
      this.modalMP.show();
    } else {
      this.addPaymentMethod();
    }
  };

  showLocationMap = ({ lat, lng, locationAddress }) => {
    this.setState(
      (state) => {
        state.mapInfo.lat = lat;
        state.mapInfo.lng = lng;
        state.mapInfo.locationAddress = locationAddress;
        return { mapInfo: state.mapInfo };
      },
      () => this.modalLocationMap.show()
    );
  };

  changeLoggedUser = (value, name) =>
    this.setState((state) => {
      state.user[name] = value;
      return { user: state.user };
    });

  validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  changeNeedInvoice = (value) => this.setState({ needInvoice: value });

  handleBlurIdNumber = () => {
    const idNumber = this.state.data.id_number;
    const documentType = this.state.data.document_type;

    if (documentType && documentType.description == "RUT") {
      if (validateRUT(idNumber)) {
        const formatedRut = formateaRut(idNumber);
        this.setState((state) => {
          state.data.id_number = formatedRut;
          return { data: state.data, errorIdNumber: "" };
        });
      } else {
        this.setState({ errorIdNumber: "El RUT es inválido" });
      }
    }
  };
  showSpinner = () => this.setState({ isSubmitting: true });

  hideSpinner = () => this.setState({ isSubmitting: false });

  render() {
    moment.locale("es");
    const {
      fromDate,
      fromTime,
      untilDate,
      untilTime,
      extrasIds,
      selectedPickup,
      selectedDropoff,
      pricing,
      newFrom,
      newTo,
      hasQuoted,
      error,
      success,
      isSubmitting,
      alert,
      user,
      needInvoice,
      loadMP,
      mapInfo,
    } = this.state;

    const { messageTitle, messageType, messages, showMessage } = alert;

    const { blockedRanges, rental, documentTypes, booking } = this.props;
    const vehicle = rental.vehicle;
    const isBookingStarted = booking.status.id === BookingStatus.INICIADO;

    const from = moment(fromDate + fromTime, "DD/MM/YYYYHH:mm");
    const until = moment(untilDate + untilTime, "DD/MM/YYYYHH:mm");
    const dayOfWeek = from.day();

    const newFrom_ = moment(newFrom);
    const newTo_ = moment(newTo);
    const eventRange = moment.duration(newTo_.diff(newFrom_));
    const duration = Math.floor(eventRange.asDays());
    const hours = Math.floor(eventRange.add(-duration, "days").asHours());
    const minutes = Math.floor(eventRange.add(-hours, "hours").asMinutes());

    let bookingLengthText =
      "Total por " + duration + " día" + (duration > 1 ? "s" : "");
    if (hours) {
      const hoursText = hours + " hora" + (hours > 1 ? "s" : "");
      if (minutes) bookingLengthText += "," + hoursText;
      else bookingLengthText += " y " + hoursText;
    }
    if (minutes) {
      bookingLengthText +=
        " y " + minutes + " minutos" + (minutes > 1 ? "s" : "");
    }

    let btnText = "Modificar reserva";
    let btnAction = () => this.calculatePrice();
    let btnDisabled = isSubmitting || (success && !error);

    if (!hasQuoted) {
      btnText = "Actualizar precio";
      btnAction = () => this.calculatePrice();
    } else if (!this.checkImportantData()) {
      btnAction = () => this.completeProfile();
      // TBK2MP Retomar MP
    } else if (!user.mercadopago || user.mercadopago.cards.length == 0) {
      // } else if (!user.oneclick || !user.oneclick.tbk_user) {
      btnAction = () => this.showMercadoPagoModal();
    } else if (!success) {
      btnText = "Modificar reserva";
      btnAction = () => {
        this.modalAgreement.show();
      };
    } else {
      btnText = "Solicitud enviada";
      btnAction = () => null;
      btnDisabled = true;
    }

    let alertOverlap = null;
    let datesOverlaped = false;
    let alertStartedBooking = null;

    if (Array.isArray(blockedRanges) && blockedRanges.length > 0) {
      for (let event of blockedRanges) {
        const eventMinValue = moment(event.from);
        const eventMaxValue = moment(event.until);

        const fromValue = moment(from).valueOf();
        const untilValue = moment(until);
        if (
          fromValue <= eventMaxValue &&
          eventMinValue <= untilValue &&
          event.booking_id != booking.id
        ) {
          datesOverlaped = true;
          alertOverlap = (
            <div className="alert alert-primary my-3">
              <i className="mdi mdi-calendar-alert mx-2" /> Las fechas
              seleccionadas no se encuentran disponibles
            </div>
          );
          break;
        }
      }
    }

    if (booking.status.id === BookingStatus.ACTIVO) {
      if (until.valueOf() < moment().valueOf()) {
        btnDisabled = true;
        alertOverlap = (
          <div className="alert alert-primary my-3">
            <i className="mdi mdi-calendar-alert mx-2" /> Las fechas no pueden
            ser pasadas
          </div>
        );
      }
      // Iniciado
    } else if (isBookingStarted) {
      alertOverlap = (
        <div className="alert light">
          <i className="mdi mdi-alert-outline" /> No es posible modificar el
          punto de recogida ni la fecha de inicio de una reserva <b>Iniciada</b>
          .
        </div>
      );
      if (from.valueOf() != moment(booking.date_from).valueOf()) {
        btnDisabled = true;
      }
    }

    const btnBook = (
      <button
        type="button"
        className="btn btn-appcar-rental text-nowrap"
        onClick={btnAction}
        disabled={btnDisabled}
      >
        {btnText}
      </button>
    );

    const modalDates = (
      <ModalDates
        changeFromTime={this.changeFromTime}
        changeUntilTime={this.changeUntilTime}
        fromDate={fromDate}
        fromTime={fromTime}
        untilDate={untilDate}
        untilTime={untilTime}
        alertOverlap={alertOverlap}
        isBookingStarted={isBookingStarted}
      />
    );

    const modalMercadoPago = (
      <ModalMercadoPago action={this.addPaymentMethod} />
    );

    const userFormModal = (
      <UserFormModal
        user={user}
        onChange={this.changeLoggedUser}
        validateEmail={this.validateEmail}
        action={this.updateUser}
        isUpdating={true}
        documentTypes={documentTypes}
        checkNeedInvoice={needInvoice}
        changeNeedInvoice={this.changeNeedInvoice}
      />
    );

    const paymentMethodModal = (
      <PaymentMethodModal
        mpPublicKey={this.props.mpPublicKey}
        user={user}
        showSpinner={this.showSpinner}
        hideSpinner={this.hideSpinner}
        showMessage={this.showMessage}
        callback={() => {
          this.reloadUser();
          this.modalPaymentMethod.hide();
          this.modalAgreement.show();
        }}
        loadMP={loadMP}
        MPLoaded={() => this.setState({ loadMP: false })}
      />
    );

    const afterBookingModal = (
      <AfterBookingModal user={this.state.user || {}} />
    );
    const needMoreDataModal = (
      <NeedMoreDataModal url={`/owners/${this.props.user?.id}/edit_driver`} />
    );

    const modalPricingDetails = (
      <ModalPricing pricing={pricing} from={newFrom} to={newTo} />
    );

    const modalMap = (
      <ModalLocation
        lat={mapInfo.lat}
        lng={mapInfo.lng}
        address={mapInfo.locationAddress}
        markerUrl={this.props.markerIconURL}
      />
    );

    const paymentFailedModal = <FailedPaymentModal />;

    const totalPriceContainer = (
      <div className="price-container">
        <span className="d-block fs-12 fw-400 mx-2">
          Nº reserva <span className="fs-21 fw-900">{booking.id}</span>
        </span>
        <span className="d-block fs-12 fw-400 mx-2">
          Valor{" "}
          <span className="fs-21 fw-900">
            {numeral(pricing.total).format("$0,0")}
          </span>
        </span>
        <div className="duration">{bookingLengthText}</div>
        <div className="duration">
          <span
            className="edit-dates cursor-pointer text-underline"
            onClick={() => this.modalPricingDetails.show()}
          >
            Detalle precio
          </span>
        </div>
      </div>
    );

    const cancellationPolicy = vehicle.cancellation_policy;
    const fuelPolicy = vehicle.fuel_policy;
    const {
      total,
      insurance_charge,
      clf_value,
      owner_total,
      discount,
    } = pricing;
    const pickupParam =
      selectedPickup && selectedPickup.value
        ? "&pickup=" + encodeURIComponent(selectedPickup.value)
        : "";
    const dropoffParam =
      selectedDropoff && selectedDropoff.value
        ? "&dropoff=" + encodeURIComponent(selectedDropoff.value)
        : "";
    const vehicleExtras = rental.pricing.extra_charges || [];
    const selectedExtras = vehicleExtras.filter((extra) =>
      extrasIds.includes(extra.vehicle_extra.id)
    );

    const limited = !vehicle.unlimited_distance;
    let minLength = 0;
    if (rental.availability && rental.availability.booking_length) {
      if (dayOfWeek == 5) {
        minLength =
          rental.availability.booking_length.minimum_length_saturday || 0;
      } else if (dayOfWeek == 6) {
        minLength =
          rental.availability.booking_length.minimum_length_sunday || 0;
      } else {
        minLength = rental.availability.booking_length.minimum_length || 0;
      }
    }

    let legalURL = "";
    if (user && user.id) {
      legalURL =
        // "/legal/sublease_agreement?rental_id=" +
        "/legal/booking_certificate?rental_id=" +
        rental.id +
        "&user_id=" +
        (user && user.id) +
        "&from=" +
        encodeURIComponent(from.format()) +
        "&to=" +
        encodeURIComponent(until.format()) +
        "&total_price=" +
        encodeURIComponent(total) +
        "&insurance_charge=" +
        encodeURIComponent(insurance_charge) +
        "&clf_value=" +
        encodeURIComponent(clf_value) +
        "&owner_total=" +
        encodeURIComponent(owner_total) +
        "&discount=" +
        encodeURIComponent(owner_total) +
        pickupParam +
        dropoffParam;
    }
    const bookingModal = (
      <BookingModal
        title={
          <div className="legal-modal-title">
            <h4 className="modal-title" id="legalModalLabel">
              Resumen de arriendo
            </h4>
            <h5 className="modal-subtitle">
              Lee atentamente antes de continuar
            </h5>
          </div>
        }
        pricing={pricing}
        fuelPolicy={fuelPolicy}
        cancellationPolicy={cancellationPolicy}
        dropoff={selectedDropoff.value}
        pickup={selectedPickup.value}
        from={from.format()}
        to={until.format()}
        textAgreement={
          <>
            Estoy de acuerdo con los
            <a
              href="/legal/terms_and_conditions"
              target="_blank"
              className="text-black"
            >
              {" "}
              términos y condiciones
            </a>
            .
          </>
        }
        textPrivacy={
          <>
            Estoy de acuerdo con el tratamiento de mis datos personales como se
            describe en la
            <a
              href={legalURL + "#privacy"}
              target="_blank"
              className="text-black"
            >
              {" "}
              Política de Privacidad II
            </a>
            .
          </>
        }
        selectedExtras={selectedExtras}
        max250km={limited}
        minLength={minLength}
        action={this.createBooking}
        btnText="Modificar reserva"
      />
    );

    const extraLocations = rental.pricing.extra_locations || [];
    const extras = (rental.pricing && rental.pricing.extra_charges) || [];

    const extrasContainer = (
      <ExtrasContainer
        extras={extras}
        extrasIds={extrasIds}
        handleChangeCheckedExtra={this.handleChangeCheckedExtra}
      />
    );

    const locationContainer = (
      <LocationContainer
        extraLocations={extraLocations}
        selectedPickup={selectedPickup}
        selectedDropoff={selectedDropoff}
        location={rental.location}
        showLocationMap={this.showLocationMap}
        changePickUp={(option) =>
          this.setState({ selectedPickup: option, hasQuoted: false }, () =>
            this.calculatePrice()
          )
        }
        changeDropOff={(option) =>
          this.setState({ selectedDropoff: option, hasQuoted: false }, () =>
            this.calculatePrice()
          )
        }
      />
    );

    let featuresHTML = "No se han añadido características";

    if (
      Array.isArray(vehicle.vehicle_features) &&
      vehicle.vehicle_features.length > 0
    ) {
      featuresHTML = (
        <div className="row">
          {vehicle.vehicle_features.map((feature, i) => (
            <div className="col-6" key={i}>
              {feature.a_name}
            </div>
          ))}
        </div>
      );
    }

    let bookingIcon = null;
    switch (booking.status.id) {
      case BookingStatus.PENDIENTE || BookingStatus.EN_REVISION:
        bookingIcon = PendingIcon;
        break;
      case BookingStatus.INICIADO:
        bookingIcon = StartedIcon;
        break;
      default:
        bookingIcon = ConfirmedIcon;
    }

    const descriptionHTML =
      rental.information && rental.information.description ? (
        <div className="section border-section">
          <h5>Descripción</h5>
          <div className="section-content">
            {rental.information.description}
          </div>
        </div>
      ) : null;

    const datesContainer = (
      <DatesContainer
        from={from}
        until={until}
        datesOverlaped={datesOverlaped}
        showModalDates={() => this.modalDates.show()}
      />
    );
    return (
      <>
        <Spinner loading={isSubmitting} />
        <Alert
          alertClass={messageType}
          isShowing={showMessage}
          messages={messages}
          title={messageTitle}
          onClose={this.handleAlertClose}
        />
        <div className="desk vehicle-scheduler">
          <div className="column">
            <div className="scheduler-component">
              <div className="row align-items-center justify-content-around">
                <div className="col text-center">
                  <img src={bookingIcon} width={60} />
                </div>
                <div className="col booking-status text-center">
                  {booking.status.description}
                </div>
              </div>
              <hr className="text-white" />
              <div className="row align-items-center">
                <div className="col-6 scheduler-separator text-center">
                  {totalPriceContainer}
                </div>
                <div className="col-6 text-center align-self-end">
                  {datesContainer}
                </div>
              </div>
              <hr className="text-white" />
              <div className="row justify-content-center">{btnBook}</div>
            </div>
            <div className="rental-card hide-on-res">
              <PoliciesSummary rental={rental} dayOfWeek={dayOfWeek} />
            </div>
            <div className="rental-card hide-on-res bg-white">
              <SantanderContainer />
            </div>
            {/* <GuaranteeCard principalClassName="rental-card" /> */}
          </div>
          <div className="column">
            <div className="rental-card">
              {locationContainer}
              <div className="py-3"></div>
              <RentalSchedule rental={rental} />
            </div>
            <div className="rental-card">
              <h5>Extras</h5>
              <div className="extras-container">{extrasContainer}</div>
            </div>
            <div className="rental-card show-on-res">
              <PoliciesSummary rental={rental} dayOfWeek={dayOfWeek} />
            </div>
            <div className="rental-card show-on-res bg-white">
              <SantanderContainer />
            </div>
          </div>
        </div>
        <div className="mobile mobile-scheduler">
          <div className="section">
            <div className="scheduler-component">
              <div className="row align-items-center justify-content-around">
                <div className="col text-center">
                  <img src={bookingIcon} width={60} />
                </div>
                <div className="col booking-status text-center">
                  {booking.status.description}
                </div>
              </div>
              <hr className="text-white" />
              <div className="row align-items-center">
                <div className="col-6 scheduler-separator text-center">
                  {totalPriceContainer}
                </div>
                <div className="col-6 text-center align-self-end">
                  {datesContainer}
                </div>
              </div>
              <hr className="text-white" />
              <div className="row justify-content-center">{btnBook}</div>
            </div>
          </div>
          <div className="section border-section">{locationContainer}</div>
          <div className="section border-section">
            <RentalSchedule rental={rental} />
          </div>
          {descriptionHTML}
          <div className="section border-section">
            <h5>Características</h5>
            <div className="section-content">{featuresHTML}</div>
          </div>
          <div className="section border-section">
            <h5>Extras</h5>
            <div className="section-content">{extrasContainer}</div>
          </div>
          <div className="section">
            <PoliciesSummary rental={rental} dayOfWeek={dayOfWeek} />
          </div>
          <div className="section">
            <SantanderContainer />
          </div>
          {/* <GuaranteeCard principalClassName="section" /> */}
        </div>
        {modalDates}
        {modalPricingDetails}
        {bookingModal}
        {modalMercadoPago}
        {userFormModal}
        {paymentMethodModal}
        {afterBookingModal}
        {needMoreDataModal}
        {modalMap}
        {paymentFailedModal}
      </>
    );
  }
}
