import React, { Component, Fragment } from "react";
import moment from "moment";
import SearchComponent from "./SearchComponent";
import ImageCarousel from "../../common/ImageCarousel";
moment.locale("es");

class LandingPageSearch extends Component {
  render() {
    const carouselChildren = [
      <div className="position-absolute w-100">
        <p className="text-white mt-5 jumbo-text">
          Arrienda un auto,
          <br />
          <b className="text-warning">sin trámites</b>
          <br />
          <b className="text-warning">ni papeleos.</b>
        </p>
      </div>,
      <div className="position-absolute w-100 text-center">
        <p className="text-promo-banner text-white mt-2 mt-sm-3">
          Únete a la{" "}
          <a
            href="https://twitter.com/hashtag/B%C3%BAsquedaAirnCar"
            className="text-white font-weight-bold"
            target="_blank"
            rel="noopener noreferrer"
          >
            #BúsquedaAirnCar
          </a>
          <br />
          estamos reclutando autos
          <br />
          <span className="text-silly">¡TE REGALAMOS 20 LUCAS!</span>
          <br />
          <span className="text-warning">al cerrar el primer arriendo</span>
          <br />
          <span className="text-warning">de tu auto</span>
        </p>
      </div>,
    ];
    return (
      <Fragment>
        <div
          className="position-relative text-white"
          style={{ height: "50vh" }}
          id="carousel-landing"
        >
          <ImageCarousel
            imageCounterHide={true}
            images={this.props.images.map((url) => ({ url }))}
            canViewFullSize={false}
            modalName="hide"
            autoChange={true}
            height={"50vh"}
            slideChildren={carouselChildren}
          />
        </div>
        <div className="card mb-0">
          <div className="card-body">
            <div className="container-fluid ">
              <SearchComponent />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default LandingPageSearch;
