import React, { useState, useEffect } from "react";
import Alert from "../../../common/components/alert/Alert";
import ToolTip, { LoadToolTips } from "../../../common/components/ToolTip";

const CopyLink = ({ shareLink, rentalId }) => {
  const [alert, setAlert] = useState({
    showMessage: false,
    messageType: undefined,
    messages: [],
    messageTitle: undefined,
  });

  const showMessage = (messageType, messageTitle, messages) => {
    setAlert((prev) => {
      prev.showMessage = true;
      prev.messageType = messageType;
      prev.messageTitle = messageTitle;
      prev.messages = messages;
      return { ...prev };
    });
  };

  const closeAlert = () =>
    setAlert((prev) => {
      prev.showMessage = false;
      return { ...prev };
    });

  useEffect(() => {
    LoadToolTips();
    if (alert.showMessage) var timeoutAlert = setTimeout(closeAlert, 3000);
    return () => clearTimeout(timeoutAlert);
  }, [alert]);

  const copy = () => {
    const from = sessionStorage.getItem("copy_from_" + rentalId);
    const to = sessionStorage.getItem("copy_to_" + rentalId);
    const address = sessionStorage.getItem("copy_address_" + rentalId);
    const dateParams = !!from && !!to ? `from=${from}&to=${to}` : "";
    const addressParams = !!address
      ? `address=${encodeURIComponent(address)}`
      : "";
    const startParams = !!dateParams || !!addressParams ? "?" : "";
    const andAddressParams = !!dateParams && !!addressParams ? "&" : "";
    navigator.clipboard.writeText(
      shareLink + startParams + dateParams + andAddressParams + addressParams
    );
  };
  return (
    <>
      <Alert
        alertClass={alert.messageType}
        isShowing={alert.showMessage}
        messages={alert.messages}
        title={alert.messageTitle}
        onClose={closeAlert}
      />
      <ToolTip
        title="Copiar enlace"
        tooltipHTML=""
        containerHTML={
          <i
            className="mdi mdi-share-variant"
            onClick={() => {
              copy();
              showMessage("primary", "", ["¡Vínculo copiado!"]);
            }}
          ></i>
        }
        widthClass="slim"
        id="copy_link_ttip"
        placement="right"
        colorClass="primary"
      />
    </>
  );
};
export default CopyLink;
