import React, { Fragment } from 'react';

const RentalSchedule = ({ rental }) => {
  const hours = rental.availability.hours;
  const base = hours ? hours.base : {
    opening_time: rental.availability.checkin_checkout.checkin_start_time,
    closing_time: rental.availability.checkin_checkout.checkin_end_time
  }

  let monday = null;
  let tuesday = null;
  let wednesday = null;
  let thursday = null;
  let friday = null;
  let saturday = null;
  let sunday = null;
  if (hours) {
    //Monday
    if (hours.mon.no_rental) {
      monday = <td align="center" colSpan="2" className="text-danger font-weight-bold">Reservado</td>;
    } else if (hours.mon.closed) {
      monday = (
        <Fragment>
          <td align="center">----</td>
          <td align="center">----</td>
        </Fragment>
      );
    } else {
      monday = (
        <Fragment>
          <td align="center">{hours.mon.opening_time ? hours.mon.opening_time : base.opening_time}</td>
          <td align="center">{hours.mon.closing_time ? hours.mon.closing_time : base.closing_time}</td>
        </Fragment>
      );
    }

    //Tuesday
    if (hours.tue.no_rental) {
      tuesday = <td align="center" colSpan="2" className="text-danger font-weight-bold">Reservado</td>;
    } else if (hours.tue.closed) {
      tuesday = (
        <Fragment>
          <td align="center">----</td>
          <td align="center">----</td>
        </Fragment>
      );
    } else {
      tuesday = (
        <Fragment>
          <td align="center">{hours.tue.opening_time ? hours.tue.opening_time : base.opening_time}</td>
          <td align="center">{hours.tue.closing_time ? hours.tue.closing_time : base.closing_time}</td>
        </Fragment>
      );
    }

    //Wednesday
    if (hours.wed.no_rental) {
      wednesday = <td align="center" colSpan="2" className="text-danger font-weight-bold">Reservado</td>;
    } else if (hours.wed.closed) {
      wednesday = (
        <Fragment>
          <td align="center">----</td>
          <td align="center">----</td>
        </Fragment>
      );
    } else {
      wednesday = (
        <Fragment>
          <td align="center">{hours.wed.opening_time ? hours.wed.opening_time : base.opening_time}</td>
          <td align="center">{hours.wed.closing_time ? hours.wed.closing_time : base.closing_time}</td>
        </Fragment>
      );
    }

    //Thursday
    if (hours.thu.no_rental) {
      thursday = <td align="center" colSpan="2" className="text-danger font-weight-bold">Reservado</td>;
    } else if (hours.thu.closed) {
      thursday = (
        <Fragment>
          <td align="center">----</td>
          <td align="center">----</td>
        </Fragment>
      );
    } else {
      thursday = (
        <Fragment>
          <td align="center">{hours.thu.opening_time ? hours.thu.opening_time : base.opening_time}</td>
          <td align="center">{hours.thu.closing_time ? hours.thu.closing_time : base.closing_time}</td>
        </Fragment>
      );
    }

    //Friday
    if (hours.fri.no_rental) {
      friday = <td align="center" colSpan="2" className="text-danger font-weight-bold">Reservado</td>;
    } else if (hours.fri.closed) {
      friday = (
        <Fragment>
          <td align="center">----</td>
          <td align="center">----</td>
        </Fragment>
      );
    } else {
      friday = (
        <Fragment>
          <td align="center">{hours.fri.opening_time ? hours.fri.opening_time : base.opening_time}</td>
          <td align="center">{hours.fri.closing_time ? hours.fri.closing_time : base.closing_time}</td>
        </Fragment>
      );
    }

    //Saturday
    if (hours.sat.no_rental) {
      saturday = <td align="center" colSpan="2" className="text-danger font-weight-bold">Reservado</td>;
    } else if (hours.sat.closed) {
      saturday = (
        <Fragment>
          <td align="center">----</td>
          <td align="center">----</td>
        </Fragment>
      );
    } else {
      saturday = (
        <Fragment>
          <td align="center">{hours.sat.opening_time ? hours.sat.opening_time : base.opening_time}</td>
          <td align="center">{hours.sat.closing_time ? hours.sat.closing_time : base.closing_time}</td>
        </Fragment>
      );
    }

    //Sunday
    if (hours.sun.no_rental) {
      sunday = <td align="center" colSpan="2" className="text-danger font-weight-bold">Reservado</td>;
    } else if (hours.sun.closed) {
      sunday = (
        <Fragment>
          <td align="center">----</td>
          <td align="center">----</td>
        </Fragment>
      );
    } else {
      sunday = (
        <Fragment>
          <td align="center">{hours.sun.opening_time ? hours.sun.opening_time : base.opening_time}</td>
          <td align="center">{hours.sun.closing_time ? hours.sun.closing_time : base.closing_time}</td>
        </Fragment>
      );
    }

  }


  const hoursTable = hours ? (
    <Fragment>
      <h5 className="text-center">Horarios</h5>
      <table className="tooltip-table-pricing mb-3 nowrap">
        <thead>
          <tr>
            <th></th>
            <th>Apertura</th>
            <th>Cierre</th>
            <th style={{ width: "1rem" }}></th>
            <th></th>
            <th>Apertura</th>
            <th>Cierre</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td align="center">Lun</td>
            {monday}
            <td style={{ border: "none" }}></td>
            <td align="center">Vie</td>
            {friday}
          </tr>
          <tr>
            <td align="center">Mar</td>
            {tuesday}
            <td style={{ border: "none" }}></td>
            <td align="center">Sáb</td>
            {saturday}
          </tr>
          <tr>
            <td align="center">Mié</td>
            {wednesday}
            <td style={{ border: "none" }}></td>
            <td align="center">Dom</td>
            {sunday}
          </tr>
          <tr>
            <td align="center">Jue</td>
            {thursday}
            <td style={{ border: "none" }}></td>
            <td style={{ border: "none" }}></td>
            <td style={{ border: "none" }}></td>
            <td style={{ border: "none" }}></td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  ) : null;
  return (hoursTable);
};

export default RentalSchedule;
