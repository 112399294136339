import React, { Component } from 'react';

class MultipleVehicleButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  reRender = () => {
    this.forceUpdate();
  }

  componentDidMount() {
    window.rerender = this.reRender;
  }

  render() {
    const vehiclesIds = window.selectedVehicles || [];
    const multipleRentalEditionLink = `/rentals/edit_multiple&vehicle_ids=${vehiclesIds.join(",")}`;
    return (
      <div className="row multiple-vehicle-buttons" style={{ display: "none" }}>
        <div className="col">
          <div className="btn-group" role="group" aria-label="multiple vehicle buttons">
            <a href={multipleRentalEditionLink} className="btn btn-appcar-light mr-1" rel="noreferrer noopener" target="_blank">
              <i className="mdi mdi-publish"></i> Editar publicación de seleccionado(s)
          </a>
            <button
              className="btn btn-warning"
              type="button"
              onClick={e => {
                e.preventDefault();
                $("#modal-multiple-calendar").modal("show");
              }}
            >
              <i className="mdi mdi-calendar-multiselect"></i> Ver calendario
          </button>
          </div>
        </div>
      </div>
    );
  }
}

export default MultipleVehicleButtons;