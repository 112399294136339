import React, { Component, Fragment } from "react";
import { constants } from "./constants";
import DateTimePicker from "./search_components/AppcarDateTimePicker";
import LocationSelector from "./search_components/AppcarLocationSelector";
class AppcarTest extends Component {
  state = {
    inputName: "",
    time: "",
    date: moment(constants.RENTAL_SEARCH_FROM).format("DD/MM/YYYY"),
    searchLocation: "",
    lat: constants.RENTAL_SEARCH_LATITUDE,
    lon: constants.RENTAL_SEARCH_LONGITUDE,
    address: constants.RENTAL_SEARCH_DEFAULT_ADDRESS,
    isSubmitting: false
  }

  componentDidMount() {
    const this_ = this;
    const options = {
      showDropdowns: true,
      maxYear: constants.MAX_YEAR,
      singleDatePicker: true,
      minDate: new Date(),
      locale: {
        format: "DD/MM/YYYY",
        cancelLabel: "Cancelar",
        applyLabel: "Aplicar",
        fromLabel: "Desde",
        toLabel: "Hasta",
        daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre"
        ],
        firstDay: 1
      }
    };

    $("#test_fechas").daterangepicker(options, (start) => {
      const date = moment(start).format("DD/MM/YYYY");
      this_.setState({ date });
    });
  }

  handleSelectLocation = ({ success, lat, lon, address }) => {
    if (success) {
      this.setState({ lat, lon, address, isSubmitting: false });
    } else {
      this.setState({ isSubmitting: false });
    }
  };

  loadSpinner = () => this.setState({ isSubmitting: true });

  changeTime = option => this.setState({ time: option.value });

  render() {
    const { time, date } = this.state;
    return (
      <div className="container pt-3 pb-5">
        <div className="mb-4">
          <h1>Fuentes</h1>
          <h1>H1: Prueba de texto</h1>
          <h2>H2: Prueba de texto</h2>
          <h3>H3: Prueba de texto</h3>
          <h4>H4: Prueba de texto</h4>
          <h5>H5: Prueba de texto</h5>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Pellentesque pretium sollicitudin sapien, sit amet commodo nulla.
            Praesent sit amet varius massa. Sed porta libero quis suscipit dignissim.
            Mauris sed mi commodo, mattis sapien quis, porta arcu.
            Maecenas quis metus facilisis, luctus metus ut, volutpat eros.
          </p>
          <p className="text-appcar-primary">
            Quisque justo ligula, convallis ut accumsan ut, porttitor et neque.
            Nam odio ipsum, placerat vitae volutpat sit amet,
            condimentum vitae sem. Cras nec erat volutpat, interdum elit et, congue mauris.
            Nunc ac est sollicitudin, imperdiet dolor a, sodales mauris.
          </p>
          <p className="text-appcar-secondary">
            Pellentesque habitant morbi tristique senectus et netus et malesuada
            fames ac turpis egestas. Vestibulum posuere ut libero a laoreet. Nullam tortor nulla,
            blandit ac venenatis eget, pretium sit amet nisl.
          </p>
          <p className="text-appcar-light">
            Donec eget gravida odio. Nunc tincidunt lorem a mollis dapibus. Aliquam
            gravida dui vel ex pretium porta. Donec sit amet sodales lorem. Etiam lacus
            felis, rutrum at malesuada sit amet, porttitor quis ante. In rhoncus vitae nisi vitae dapibus.
          </p>
        </div>
        <hr />
        <div className="my-4">
          <h1>Alertas</h1>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="alert primary alert-dismissible my-2 ">
                <button type="button" className="close" data-dismiss="alert" data-bs-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                Esta es una alerta de prueba
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="alert secondary alert-dismissible my-2 ">
                <button type="button" className="close" data-dismiss="alert" data-bs-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                Esta es una alerta de prueba
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="alert light alert-dismissible my-2 ">
                <button type="button" className="close" data-dismiss="alert" data-bs-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                Esta es una alerta de prueba
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="alert alert-success alert-dismissible my-2 ">
                <button type="button" className="close" data-dismiss="alert" data-bs-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                Esta es una alerta de prueba
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="alert alert-warning alert-dismissible my-2 ">
                <button type="button" className="close" data-dismiss="alert" data-bs-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                Esta es una alerta de prueba
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="alert alert-info alert-dismissible my-2 ">
                <button type="button" className="close" data-dismiss="alert" data-bs-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                Esta es una alerta de prueba
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="my-4">
          <h1>Inputs</h1>
          <div className="row">
            <div className="col-12 col-lg-6 my-2">
              <div className="form-group">
                <LocationSelector
                  countries={["CL"]}
                  handleSelectLocation={this.handleSelectLocation}
                  placeholder="Selecciona un lugar"
                  label="Input de prueba de "
                  airportsFirst={false}
                  loadSpinner={this.loadSpinner}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 my-2">
              <div className="form-group">
                <label>Selección de fechas</label>
                <DateTimePicker
                  changeTime={this.changeTime}
                  date={date}
                  pickerId="test_fechas"
                  time={time}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 my-2">
              <div className="form-group">
                <label>Prueba de label</label>
                <input
                  type="text"
                  className="form-control appcar-input"
                  placeholder="Prueba de input de texto"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 my-2">
              <div className="form-group">
                <label className="label-inside">Prueba de label</label>
                <input
                  type="text"
                  className="form-control appcar-input"
                  placeholder="Prueba de input de texto"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 my-2">
              <div className="form-group">
                <label>Prueba de label</label>
                <input
                  type="text"
                  className="form-control appcar-input-light"
                  placeholder="Prueba de input de texto"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 my-2">
              <div className="form-group">
                <label className="label-inside light">Prueba de label</label>
                <input
                  type="text"
                  className="form-control appcar-input-light"
                  placeholder="Prueba de input de texto"
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="my-4">
          <h1>Botones</h1>
          <div className="row my-2">
            <div className="col-6 col-md-4 my-2">
              <button type="button" className="btn btn-appcar-light mr-2">Botón de prueba</button>
            </div>
            <div className="col-6 col-md-4 my-2">
              <button type="button" className="btn btn-appcar-primary mr-2">Botón de prueba</button>
            </div>
            <div className="col-6 col-md-4 my-2">
              <button type="button" className="btn btn-appcar-light mr-2">Botón de prueba</button>
            </div>
          </div>
        </div>
        <hr />
      </div>
    )
  }
}

export default AppcarTest;