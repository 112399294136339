import React, { Component } from "react";
import { InputFeedback } from "../../../../common/CustomInputs";
import Moment from "moment";
import { extendMoment } from "moment-range";
import isEmpty from "lodash/isEmpty";
import axios from "axios";
import { constants, subleaseAgreementText, BookingStatus } from "../../../../common/constants";
import PricingDetails from "../../../../common/PricingDetails";
import RentalSchedule from "../../../../common/RentalSchedule";
import LegalModal from "../../../../common/legal_modals/LegalModal";
import Spinner from "../../../../common/Spinner";
import Select from "react-select";
import { AxiosApplicationInterceptor } from "../../../../common/axios_interceptor";
import Policies from "../../../../common/Policies";
import MercadoPagoModal from "../../../../common/components/mercadopago/MercadoPagoModal";
axios.interceptors.response.use(undefined, AxiosApplicationInterceptor);
const moment = extendMoment(Moment);

class VehicleScheduler extends Component {
  state = {
    from: moment(),
    to: moment(),
    error: false,
    success: false,
    has_quoted: false,
    button_state: "not_quoted", // "quoted_and_not_registered", "quoted_and_registered",
    pricing: {
      total: 0,
      details: [],
      extra_charges: [],
      commission: 0,
      insurance_charge: 0,
      payment_method_fee: 0,
      value_added_tax: 0,
      pick_up_charge: null,
      drop_off_charge: null,
      clf_value: 0.0,
      owner_total: 0.0,
      discount: 0.0
    },
    payment: {
      oneclick: {
        register_init: false,
        register_finish: false,
        tbk_user: false,
        token: "",
        url: ""
      }
    },
    new_from: "",
    new_to: "",
    selected_dropoff: undefined,
    selected_pickup: undefined,
    ui: {
      isSubmitting: false,
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined,
      submittingMessage: ""
    },
    // modal_body: ""
  };

  transbankWindow = null;

  componentDidMount() {
    if (this.props.booking && this.props.booking.pricing) {
      this.setupBooking();
    }

    const options = {
      showDropdowns: true,
      maxYear: constants.MAX_YEAR,
      timePicker: true,
      timePicker24Hour: true,
      timePickerIncrement: 15,
      separator: " \n ",
      locale: {
        separator: " hasta ",
        format: "LLL",
        cancelLabel: "Cancelar",
        applyLabel: "Aplicar",
        fromLabel: "Desde",
        toLabel: "Hasta",
        daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre"
        ],
        firstDay: 1
      }
    };

    const booking = this.props.booking;

    $("#fechas").daterangepicker(options, (start, end) => {
      if (booking.status.id == BookingStatus.INICIADO) {
        start = moment(booking.date_from);
      }
      let from = moment(start).format();
      let to = moment(end).format();
      let has_quoted = false;
      let error = false;
      let success = false;
      this.setState(state => {
        state.ui.showMessage = false;
        return { from, to, has_quoted, error, success, ui: state.ui };
      }, () => this.calculate_price());
    });

    $("#fechas").on("apply.daterangepicker", (ev, picker) => {
      if (booking.status.id == BookingStatus.INICIADO) {
        if (picker.startDate.valueOf() != moment(booking.date_from).valueOf()) alert('No se puede modificar la fecha de inicio de un arriendo en curso');
        picker.setStartDate(moment(booking.date_from));
      }
    });
  }

  checkOneClickWindowClosed = () => {
    const oneClickInterval = setInterval(() => {
      if (this.transbankWindow && this.transbankWindow.closed) {
        window.focus();
        this.updateUser();
        clearInterval(oneClickInterval);
      }
    }, 500)
  }

  updateUser = () => {
    axios({
      method: "GET",
      url: `/owners/current.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json"
      },
    })
      .then(response => {
        if (response.data) {
          this.setState({
            payment: {
              oneclick: {
                register_init: false,
                register_finish: false,
                tbk_user: false,
                token: "",
                url: ""
              }
            },
          }, () => this.props.reloadUser(response.data));
        } else {
          this.showMessage({
            showMessage: true,
            messageType: "alert-danger",
            messages: ["Ha ocurrido un error inesperado"],
            messageTitle: "Error"
          });
        }
      })
      .catch(error => {
        this.showMessage({
          showMessage: true,
          messageType: "alert-danger",
          messages: ["Ha ocurrido un error inesperado"],
          messageTitle: "Error"
        });
      })
      .finally(() => {
        this.setState(state => {
          state.ui.isSubmitting = false;
          state.ui.submittingMessage = "";
          return { ui: state.ui };
        });
      });
  }

  formOneClickSubmit = e => {
    this.setState(state => {
      state.ui.isSubmitting = true;
      state.ui.submittingMessage = "Esperando a Transbank...";
      return { ui: state.ui }
    });
    this.transbankWindow = window.open("", "transbankWindow");
    this.checkOneClickWindowClosed();
  }

  setupBooking = () => {
    const { booking, user, rental, blockedRanges } = this.props;
    const {
      selected_pickup_location,
      selected_dropoff_location
    } = booking.pricing;
    const pickup_location =
      selected_pickup_location && selected_pickup_location.location
        ? selected_pickup_location.location.name
        : undefined;
    const dropoff_location =
      selected_dropoff_location && selected_dropoff_location.location
        ? selected_dropoff_location.location.name
        : undefined;

    const selected_dropoff = dropoff_location ? {
      label: dropoff_location,
      value: dropoff_location
    } : undefined;
    const selected_pickup = pickup_location ? { label: pickup_location, value: pickup_location } : undefined;

    let oneclick = { ...this.state.payment.oneclick };
    oneclick.tbk_user = !!user.oneclick && user.oneclick.tbk_user;

    this.setState(
      state => {
        state.payment = {
          oneclick
        };
        state.from = booking.date_from;
        state.to = booking.date_until;

        return {
          from: state.from,
          to: state.to,
          payment: state.payment,
          selected_dropoff,
          selected_pickup
        };
      },
      () => {
        const booking = this.props.booking;
        this.calculate_price();
        const el = $("#fechas").data("daterangepicker");
        const date_from = moment(booking.date_from);
        const date_until = moment(booking.date_until);
        el.setStartDate(date_from);
        el.setEndDate(date_until);
        // la fecha mínima no debe chocar con la fecha mínima del booking a editar.
        // Cuando una persona edita el rango de fechas de la publicación el nuevo date_from del rental pasa a ser ese mismo momento.
        // Por lo tanto, podría chocar con la fecha desde de una reserva que tenga como inicio tiempo pasado.
        el.minDate = moment(this.props.dateFrom).valueOf() < date_from.valueOf() ? moment(this.props.dateFrom) : date_from;
        el.maxDate = moment(this.props.dateUntil).valueOf() > date_until.valueOf() ? moment(this.props.dateUntil) : date_until;
        el.isInvalidDate = date => {
          let disable = false;
          if (Array.isArray(blockedRanges) && blockedRanges.length > 0) {
            for (let event of blockedRanges) {
              disable = false;
              const event_min_value = moment(event.from);
              const event_max_value = moment(event.until);;
              let date_value = moment(date).valueOf();
              if (
                date_value > event_min_value.endOf("d").valueOf() &&
                date_value < event_max_value.startOf("d").valueOf()
              ) {
                if (event.booking_id && event.booking_id == booking.id) break;
                disable = true;
                break;
              }
            }
          } 
          return disable;
        };
      }
    );
  };

  reloadUser = () => {
    this.setState(state => {
      state.ui.isSubmitting = true;
      state.ui.submittingMessage = "Esperando a MercadoPago";
      return { ui: state.ui }
    });
    this.updateUser();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.rental != prevProps.rental ||
      this.props.user != prevProps.user
    ) {
      this.setupBooking();
    }

    if (this.props.redoQuote) {
      this.setState(
        state => {
          state.ui.showMessage = false;
          return {
            has_quoted: false,
            ui: state.ui,
            error: false,
            success: false
          };
        },
        () => {
          this.props.reportRedo();
          this.calculate_price();
        }
      );
    }
  }

  changeFecha = fecha => {
    this.setState({ fecha });
  };

  showMessage = msg => {
    this.setState(state => {
      state.ui.showMessage = msg.showMessage;
      state.ui.messageType = msg.messageType;
      state.ui.messages = msg.messages;
      state.ui.messageTitle = msg.messageTitle;
      return { ui: state.ui };
    });
  };
  oneclick_register_init = e => {
    this.setState(state => {
      state.ui.isSubmitting = true;
      return { ui: state.ui };
    });
    e.preventDefault();
    axios({
      method: "POST",
      url: `/bookings/oneclick_register_init.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        rental_id: this.props.rental.id,
        from: this.state.from,
        to: this.state.to
      }
    })
      .then(response => {
        if (response.data.success) {
          let oneclick = { ...this.state.payment.oneclick };
          oneclick.register_init = true;
          oneclick.token = response.data.tbk_response.token;
          oneclick.url = response.data.tbk_response.url;
          this.setState({ error: false, payment: { oneclick: oneclick } });
        } else {
          this.showMessage({
            showMessage: true,
            messageType: "alert-warning",
            messages: response.data.messages || [
              "Ha ocurrido un error inesperado"
            ],
            messageTitle: "Alerta"
          });
        }
      })
      .catch(error => {
        this.showMessage({
          showMessage: true,
          messageType: "alert-danger",
          messages: ["Ha ocurrido un error inesperado"],
          messageTitle: "Error"
        });
      })
      .finally(() => {
        this.setState(state => {
          state.ui.isSubmitting = false;
          return { ui: state.ui };
        });
      });
  };

  calculate_price = e => {
    this.setState(state => {
      state.ui.isSubmitting = true;
      state.ui.showMessage = false;
      state.ui.messageType = undefined;
      state.ui.messages = [];
      state.ui.messageTitle = undefined;
      return { ui: state.ui };
    });
    if (e) e.preventDefault();
    const { selected_dropoff, selected_pickup } = this.state;
    const rental = this.props.rental;
    const moment_from = moment(this.state.from);
    const moment_to = moment(this.state.to);
    const extras_ids = this.props.extrasIds || [];
    const duration = moment.duration(moment_to.diff(moment_from)).asDays();
    const new_from = moment_from.format();
    const new_to = moment_to.format();
    let rental_pricing = {
      rental_id: rental.id,
      start: moment_from.format(),
      end: moment_to.format(),
      extras_ids
    };
    if (!!selected_pickup && selected_pickup.value != rental.location.name) {
      rental_pricing.special_pickup = selected_pickup.value;
    }
    if (!!selected_dropoff && selected_dropoff.value != rental.location.name) {
      rental_pricing.special_dropoff = selected_dropoff.value;
    }
    axios({
      method: "POST",
      url: `/rentals/${this.props.rental.id}/calculate_pricing.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json"
      },
      data: { rental_pricing }
    })
      .then(response => {
        if (response.data.success) {
          const pricing = response.data.pricing;
          this.setState({
            has_quoted: true,
            pricing,
            error: false,
            new_from,
            new_to
          });
        } else {
          this.showMessage({
            showMessage: true,
            messageType: "alert-warning",
            messages: response.data.messages || [
              "Ha ocurrido un error inesperado"
            ],
            messageTitle: "Alerta"
          });
        }
      })
      .catch(error => {
        this.showMessage({
          showMessage: true,
          messageType: "alert-danger",
          messages: ["Ha ocurrido un error inesperado"],
          messageTitle: "Error"
        });
      })
      .finally(() => {
        this.setState(state => {
          state.ui.isSubmitting = false;
          return { ui: state.ui };
        });
      });
  };
  book_rental = e => {
    e.preventDefault();
    $("#modal-sublease-agreement").modal("show");
  };

  createBooking = () => {
    this.setState(state => {
      state.ui.isSubmitting = true;
      state.ui.showMessage = false;
      state.ui.messageType = undefined;
      state.ui.messages = [];
      state.ui.messageTitle = undefined;
      return { ui: state.ui };
    });
    const { selected_dropoff, selected_pickup } = this.state;
    const rental = this.props.rental;
    const moment_from = moment(this.state.from);
    const moment_to = moment(this.state.to);
    const extras_ids = this.props.extrasIds || [];
    const duration = moment.duration(moment_to.diff(moment_from)).asDays();
    let booking = {
      date_from: moment_from.format(),
      date_until: moment_to.format(),
      location_id: rental.location.id,
      vehicle_id: rental.vehicle.id,
      rental_id: rental.id,
      extras_ids
    };
    if (!!selected_pickup && selected_pickup.value != rental.location.name) {
      booking.special_pickup = selected_pickup.value;
    }
    if (!!selected_dropoff && selected_dropoff.value != rental.location.name) {
      booking.special_dropoff = selected_dropoff.value;
    }
    axios({
      method: "PUT",
      url: `/bookings/${this.props.booking.id}.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json"
      },
      data: { booking }
    })
      .then(response => {
        if (response.data.success) {
          this.setState({ success: true, error: false });
        } else {
          this.showMessage({
            showMessage: true,
            messageType: "alert-warning",
            messages: response.data.messages || [
              "Ha ocurrido un error inesperado"
            ],
            messageTitle: "Alerta"
          });
        }
      })
      .catch(error => {
        this.showMessage({
          showMessage: true,
          messageType: "alert-danger",
          messages: ["Ha ocurrido un error inesperado"],
          messageTitle: "Error"
        });
      })
      .finally(() => {
        this.setState(state => {
          state.ui.isSubmitting = false;
          return { ui: state.ui };
        });
      });
  };

  changeDropOff = selected_dropoff => {
    const has_quoted = false;
    const error = false;
    const success = false;
    this.setState({ selected_dropoff, has_quoted, error, success }, () => this.calculate_price());
  };

  changePickUp = selected_pickup => {
    const has_quoted = false;
    const error = false;
    const success = false;
    this.setState({ selected_pickup, has_quoted, error, success }, () => this.calculate_price());
  };

  render() {
    moment.locale("es");
    let fecha_input =
      "Inicio: " +
      moment(this.state.from).format("LLL") +
      " \nDevolución: " +
      moment(this.state.to).format("LLL");
    const class_btn = !this.state.has_quoted
      ? "btn-appcar-light"
      : this.state.ui.isSubmitting
        ? "btn-light"
        : this.state.success
          ? "btn-success"
          : "btn-appcar-primary";
    const text_btn = !this.state.has_quoted
      ? "ACTUALIZAR PRECIO"
      : this.state.ui.isSubmitting
        ? "GUARDANDO..."
        : this.state.success
          ? "SOLICITUD ENVIADA"
          : "SOLICITAR MODIFICACIÓN";
    let btn_disabled =
      this.state.ui.isSubmitting || (this.state.success && !this.state.error)
        ? true
        : false;
    let alert_overlap = null;
    // [MA1-79] Ticket 4644: Arriendo Activo, no permite editar
    // if (
    //   moment(this.props.dateFrom).valueOf() >
    //   moment(this.state.from).valueOf() ||
    //   moment(this.props.dateUntil).valueOf() < moment(this.state.to).valueOf()
    // ) {
    //   // btn_disabled = true;
    //   // alert_overlap = "Las fechas deben estar en el rango";
    // }

    let alertStartedBooking = null;
    // Validación si la fecha desde es inferior a ahora, se permite que un rental activo o iniciado tenga la fecha pasada
    //Rental activo
    if (this.props.booking.status.id === BookingStatus.ACTIVO) {
      if (moment(this.state.to).valueOf() < moment().valueOf()) {
        btn_disabled = true;
        alert_overlap = "Las fechas no pueden ser pasadas";
      }
      // Iniciado
    } else if (this.props.booking.status.id === BookingStatus.INICIADO) {
      alertStartedBooking = <div className="alert alert-warning"><i className="mdi mdi-alert-outline" /> No es posible modificar el punto de recogida ni la fecha de inicio de una reserva <b>Iniciada</b>.</div>
      if (
        moment(this.state.from).valueOf() !=
        moment(this.props.booking.date_from).valueOf()
      ) {
        btn_disabled = true;
        alert_overlap =
          "No se puede editar la fecha de inicio en una reserva iniciada";
      }
    }

    const errors_list = this.state.ui.messages.map((msg, i) => (
      <li key={i} className="invalid-feedback" style={{ display: "list-item" }}>
        {msg}
      </li>
    ));
    let error_booking = this.state.ui.showMessage ? (
      <ul className="list-unstyled">{errors_list}</ul>
    ) : null;
    const moment_from = moment(this.state.from);
    const moment_to = moment(this.state.to);
    const duration = moment.duration(moment_to.diff(moment_from)).asDays();
    let oneclick_is_user_registered = false;
    let message_text = "";

    let rental_action = null;
    const pricingDetails = (
      <PricingDetails
        price={this.state.pricing}
        dateFrom={this.state.new_from}
        dateUntil={this.state.new_to}
      />
    );


    const mpModal = this.props.user && this.props.user.id ? (
      <MercadoPagoModal
        user={this.props.user}
        mpPublicKey={this.props.mpPublicKey}
        actionUrl={`/owners/${this.props.user.id}/create_mercadopago`}
        type="func"
        callback={() => this.reloadUser()}
      />
    ) : null;

    // si no ha simulado
    if (!this.state.has_quoted) {
      rental_action = (
        <button
          className={`btn ${class_btn} btn-lg fill`}
          disabled={btn_disabled}
          onClick={e => this.calculate_price(e)}
        >
          ACTUALIZAR PRECIO
        </button>
      );
    }
    // ha simulado pero no esta registrado
    if (this.state.has_quoted && isEmpty(this.props.user)) {
      message_text = "Debes registrarte para poder reservar";
      rental_action = (
        <button
          className={`btn ${class_btn} btn-lg fill`}
          disabled={true}
          onClick={e => this.calculate_price(e)}
        >
          RESERVAR
        </button>
      );
    }
    // ha simulado, esta registrado pero no esta en transbank
    if (false) {
      // No hay transbank por ahora
      if (
        this.state.has_quoted &&
        !isEmpty(this.props.user) &&
        isEmpty(this.props.user.oneclick)
      ) {
        let message_text = "";
        rental_action = (
          <button
            className={`btn ${class_btn} btn-lg fill`}
            // disabled={true}
            onClick={e => this.oneclick_register_init(e)}
          >
            Debes registrarte en Transbank
            <br />
            OneClick para poder reservar
          </button>
        );
      }
    }
    // ha simulado, está registrado, pero no está en mercadopago, o está pero no tiene tarjeta
    if (this.state.has_quoted && !isEmpty(this.props.user) && (isEmpty(this.props.user.mercadopago) || this.props.user.mercadopago.cards.length == 0)) {
      let message_text = "";
      rental_action = (
        <button
          className={`btn ${class_btn} btn-lg fill`}
          onClick={() => $("#modal-add-mercadopago").modal("show")}
        >
          Debes registrar una tarjeta
          <br />
          para poder reservar
        </button>
      );
    }
    // ha simulado, esta registrado, tiene token pero no tiene usuario tbk
    if (false) {
      // Tbk desactivado
      if (
        this.state.has_quoted &&
        !isEmpty(this.props.user) &&
        this.state.payment.oneclick.register_init &&
        !this.state.payment.oneclick.tbk_user
      ) {
        rental_action = (
          <form action={this.state.payment.oneclick.url} method="post" target="transbankWindow" id="formOneClick" onSubmit={this.formOneClickSubmit}>
            <input
              type="hidden"
              name="TBK_TOKEN"
              value={this.state.payment.oneclick.token}
            />
            <input
              type="submit"
              value="Continuar a Transbank >>"
              className={`btn ${class_btn} btn-lg fill`}
            />
          </form>
        );
      }
    }
    // ha simulado y esta registrado
    if (
      this.state.has_quoted &&
      !isEmpty(this.props.user) &&
      // !isEmpty(this.props.user.oneclick) && // tbk desactivado
      // !isEmpty(this.props.user.oneclick.tbk_user) // tbk desactivado
      !isEmpty(this.props.user.mercadopago) &&
      this.props.user.mercadopago.cards.length > 0
    ) {
      rental_action = (
        <button
          className={`btn ${class_btn} btn-lg fill`}
          disabled={false}
          onClick={e => this.book_rental(e)}
        >
          SOLICITAR MODIFICACIÓN
        </button>
      );
    }
    // ha simulado y reservó
    if (this.state.success) {
      message_text = "Reserva actualizada exitosamente";
      rental_action = (
        <button
          className={`btn ${class_btn} btn-lg fill`}
          disabled={true}
          onClick={e => this.book_rental(e)}
        >
          SOLICITUD ENVIADA
        </button>
      );
    }
    const rental = this.props.rental;
    const cancellation_policy = rental.vehicle.cancellation_policy;
    const fuel_policy = rental.vehicle.fuel_policy;
    const user_ = this.props.user;
    const extras = this.props.extras
      .filter(extra => this.props.extrasIds.includes(extra.vehicle_extra.id))
      .map(x => x.vehicle_extra.a_name);
    const { selected_dropoff, selected_pickup, pricing } = this.state;
    const { total, insurance_charge, clf_value, owner_total, discount } = pricing;
    const modalBody = subleaseAgreementText(
      null,
      rental.id,
      user_ && user_.id,
      moment_from.format(),
      moment_to.format(),
      total,
      selected_pickup && selected_pickup.value,
      selected_dropoff && selected_dropoff.value,
      insurance_charge,
      clf_value,
      owner_total,
      discount,
      cancellation_policy && cancellation_policy.name || "EL VEHÍCULO NO TIENE POLÍTICAS DE CANCELACIÓN",
      fuel_policy && fuel_policy.name || "EL VEHÍCULO NO TIENE POLÍTICAS DE USO DE COMBUSTIBLES",
      extras
    );

    const modalSubleaseAgreement = (
      <LegalModal
        action={this.createBooking}
        title="Lee atentamente antes de continuar"
        checkboxLabel="Estoy de acuerdo con los términos y condiciones."
        checkboxError="Debes aceptar los términos y condiciones"
        btnText="Continuar"
        body={modalBody}
        elementId="modal-sublease-agreement"
        secondCheckboxLabel="Estoy de acuerdo con el tratamiento de mis datos personales como se describe en la “Política de Privacidad II”."
        secondCheckboxError="Debes aceptar la Política de Privacidad II para continuar"
        refId=""
        totalPrice={this.state.pricing && this.state.pricing.total}
        isSubleaseAgreement={true}
      />
    );

    const extra_locations = rental.pricing.extra_locations || [];
    let location_options = extra_locations.map(x => ({
      label: x.location.name,
      value: x.location.name
    }));
    let location_name = rental.location && rental.location.name;
    location_options.unshift({ label: location_name, value: location_name });
    const location_container =
      extra_locations.length > 0 ? (
        <div className="row">
          <div className="col-lg-12">
            <div className="form-group">
              <label>Seleccionar punto de recogida</label>
              <Select
                value={this.state.selected_pickup}
                options={location_options}
                placeholder="Selecciona..."
                noOptionsMessage={() => "Error..."}
                onChange={this.changePickUp}
                isDisabled={this.props.booking.status.id === BookingStatus.INICIADO}
              />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <label>Seleccionar punto de devolución</label>
              <Select
                value={this.state.selected_dropoff}
                options={location_options}
                placeholder="Selecciona..."
                noOptionsMessage={() => "Error..."}
                onChange={this.changeDropOff}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-lg-12">
            <div className="form-group">
              <label>Punto de recogida</label>
              <input
                type="text"
                readOnly
                value={rental.location && rental.location.name}
                onChange={() => false}
                className="form-control disabled"
                disabled
              />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <label>Punto de devolución</label>
              <input
                type="text"
                readOnly
                value={rental.location && rental.location.name}
                onChange={() => false}
                className="form-control appcar-input"
                disabled
              />
            </div>
          </div>
        </div>
      );
    const rentalHours = <RentalSchedule rental={rental} />;
    return (
      <div className="vehicle-scheduler">
        <Spinner loading={this.state.ui.isSubmitting} message={this.state.ui.submittingMessage} />
        <h4 className="mt-3">Solicitar arriendo</h4>
        {alertStartedBooking}
        {location_container}
        {rentalHours}
        <div className="row">
          <div className="col-lg-12">
            <div className="form-group">
              <label htmlFor="fechas">Rango de fechas del arriendo</label>
              <table
                id="fechas"
                style={{ width: "100%", fontWeight: "bolder" }}
              >
                <tbody>
                  <tr>
                    <td style={{ color: "#203453" }}>INICIO</td>
                    <td>:</td>
                    <td className="text-warning fecha_mano">{moment(this.state.from).format("LLL")}</td>
                    <td rowspan="2"><i className="font-18 mdi mdi-calendar-edit mr-2 text-warning fecha_mano"></i></td>
                  </tr>
                  <tr>
                    <td style={{ color: "#203453" }}>DEVOLUCIÓN</td>
                    <td>:</td>
                    <td className="text-warning fecha_mano">{moment(this.state.to).format("LLL")}</td>
                  </tr>
                </tbody>
              </table>
              <InputFeedback error={alert_overlap} />
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            {rental_action}
            {error_booking}
            <div className="charge-text">{message_text}</div>
          </div>
        </div>
        {pricingDetails}
        <Policies
          fuelPolicy={fuel_policy}
          cancellationPolicy={cancellation_policy}
        />
        {modalSubleaseAgreement}
        {mpModal}
      </div>
    );
  }
}

export default VehicleScheduler;
