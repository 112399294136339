import React, { Component } from "react";
import { numeral } from "../../common/constants";

class IncomeCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 10000000,
      days: 20
    };
    this.sliderValueChange = this.sliderValueChange.bind(this);
    this.sliderDaysChange = this.sliderDaysChange.bind(this);
  }
  sliderValueChange(e) {
    this.setState({ value: e.target.value });
  }
  sliderDaysChange(e) {
    this.setState({ days: e.target.value });
  }
  render() {
    const { value, days } = this.state;
    const car_value = numeral(value);
    const total = ((value * .085) / 30) * days;
    return (
      <div>
        <div className="form-row">
          <div className="form-group col-md-12 text-center">
            <p className="display-3">¿Cuánto puedes ganar?</p>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6 text-center">
            <label htmlFor="range-value">Valor del auto</label>
            <input
              className="custom-range"
              id="range-value"
              type="range"
              name="range"
              min="0"
              max="50000000"
              onChange={this.sliderValueChange}
              value={car_value.value()}
            />
            <p className="display-4">{car_value.format("$0,0")}</p>
          </div>
          <div className="form-group col-md-6 text-center">
            <label htmlFor="range-days">Días de arriendo al mes</label>
            <input
              className="custom-range"
              id="range-days"
              type="range"
              name="range"
              min="0"
              max="30"
              onChange={this.sliderDaysChange}
              value={this.state.days}
            />
            <p className="display-4">{this.state.days}</p>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12 text-center">
            <p className="display-3 total-income">{numeral(total * 12).format("$0,0")}</p>
            <p className="total-income-subtext">Ingreso estimado anual</p>
          </div>
        </div>
      </div>
    );
  }
}

export default IncomeCalculator;
