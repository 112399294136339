import React, { Component, Fragment } from "react";
import { constants } from "../../common/constants";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import LocationSelector from "../../common/search_components/LocationSelector";
import DateTimePicker from "../../common/search_components/DateTimePicker";
import moment from "moment";
moment.locale("es");

class Search extends Component {
  willUnmount = false;
  state = {
    form_data: {
      lat: constants.RENTAL_SEARCH_LATITUDE,
      lon: constants.RENTAL_SEARCH_LONGITUDE,
      radius: constants.RENTAL_SEARCH_RADIUS,
      fromDate: moment(constants.RENTAL_SEARCH_FROM).format("DD/MM/YYYY"),
      untilDate: moment(constants.RENTAL_SEARCH_UNTIL).format("DD/MM/YYYY"),
      fromTime: moment(constants.RENTAL_SEARCH_FROM).format("HH:mm"),
      untilTime: moment(constants.RENTAL_SEARCH_UNTIL).format("HH:mm"),
    },
    address: "",
    chkAirport: false,
    ui: {
      isSubmitting: false,
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined,
    },
    selectedAddress: "",
    suggestions: [],
    clearSuggestions: false,
    selectedByClick: false,
  };

  componentWillUnmount() {
    this.willUnmount = true;
  }

  componentDidMount() {
    this.initializeCalendars();
  }

  initializeCalendars = () => {
    const this_ = this;
    const options = {
      showDropdowns: true,
      maxYear: constants.MAX_YEAR,
      singleDatePicker: true,
      minDate: new Date(),
      locale: {
        format: "DD/MM/YYYY",
        cancelLabel: "Cancelar",
        applyLabel: "Aplicar",
        fromLabel: "Desde",
        toLabel: "Hasta",
        daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        firstDay: 1,
      },
    };

    $("#search_from_").daterangepicker(options, (start) => {
      const from_ = moment(start);
      const until_ = moment(this_.state.form_data.untilDate, "DD/MM/YYYY");
      let fromDate = from_.format("DD/MM/YYYY");
      let untilDate = until_.format("DD/MM/YYYY");
      if (until_.valueOf() < from_.valueOf()) {
        untilDate = from_.clone().format("DD/MM/YYYY");
        $("#search_until_")
          .data("daterangepicker")
          .setStartDate(untilDate);
        $("#search_until_")
          .data("daterangepicker")
          .setEndDate(untilDate);
      }
      if (!this_.willUnmount) {
        this_.setState((state) => {
          state.form_data.fromDate = fromDate;
          state.form_data.untilDate = untilDate;
          return { form_data: state.form_data };
        });
      }
    });

    $("#search_until_").daterangepicker(options, (start) => {
      const until_ = moment(start);
      const from_ = moment(this_.state.form_data.fromDate, "DD/MM/YYYY");
      let fromDate = from_.format("DD/MM/YYYY");
      let untilDate = until_.format("DD/MM/YYYY");
      if (from_.valueOf() > until_.valueOf()) {
        fromDate = until_.format("DD/MM/YYYY");
        $("#search_from_")
          .data("daterangepicker")
          .setStartDate(fromDate);
        $("#search_from_")
          .data("daterangepicker")
          .setEndDate(fromDate);
      }
      if (!this_.willUnmount) {
        this_.setState((state) => {
          state.form_data.untilDate = untilDate;
          state.form_data.fromDate = fromDate;
          return { form_data: state.form_data };
        });
      }
    });

    const m_from = moment(constants.RENTAL_SEARCH_FROM);
    const m_until = moment(constants.RENTAL_SEARCH_UNTIL);
    $("#search_from_")
      .data("daterangepicker")
      .setStartDate(m_from);
    $("#search_from_")
      .data("daterangepicker")
      .setEndDate(m_from);
    $("#search_until_")
      .data("daterangepicker")
      .setStartDate(m_until);
    $("#search_until_")
      .data("daterangepicker")
      .setEndDate(m_until);
  };

  loadSpinner = () => {
    this.setState((state) => {
      state.ui.isSubmitting = true;
      return { ui: state.ui };
    });
  };

  handleSelectLocation = ({ success, lat, lon, address }) => {
    if (success) {
      this.setState((state) => {
        state.form_data.lat = lat;
        state.form_data.lon = lon;
        return {
          form_data: state.form_data,
          address,
          selectedAddress: address,
        };
      });
    } else {
      this.setState((state) => {
        state.ui.isSubmitting = false;
        return { ui: state.ui };
      });
    }
  };

  changeTimeFrom = (option) => {
    this.setState((state) => {
      state.form_data.fromTime = option.value;
      return { form_data: state.form_data };
    });
  };

  changeTimeUntil = (option) => {
    this.setState((state) => {
      state.form_data.untilTime = option.value;
      return { form_data: state.form_data };
    });
  };

  search = () => {
    const { form_data, selectedAddress, chkAirport } = this.state;
    const paramAddress =
      selectedAddress || constants.RENTAL_SEARCH_DEFAULT_ADDRESS;
    const from = moment(
      form_data.fromDate + form_data.fromTime,
      "DD/MM/YYYYHH:mm"
    ).format();
    const until = moment(
      form_data.untilDate + form_data.untilTime,
      "DD/MM/YYYYHH:mm"
    ).format();
    const lat = form_data.lat;
    const lon = form_data.lon;
    const radius = form_data.radius;
    const subfilter = chkAirport ? "&subfilter=aeropuerto" : "";
    let qry = `?from=${from}&until=${until}&lat=${lat}&lon=${lon}&radius=${radius}&address=${paramAddress}${subfilter}`;
    // Turbolinks.visit("/search_rentals" + qry);
    window.location.href = "/search_rentals" + qry;
  };

  handleCheckAirport = (e) => {
    const chkAirport = e.target.checked;
    this.setState({ chkAirport });
  };

  render() {
    const countries = ["cl"];
    const { fromDate, untilDate, fromTime, untilTime } = this.state.form_data;
    const { chkAirport, suggestions, clearSuggestions } = this.state;

    const airportCheck = (
      <div className="custom-control custom-checkbox mt-2">
        <input
          type="checkbox"
          className="custom-control-input"
          id="chkAirport"
          name="chkAirport"
          checked={chkAirport}
          onChange={this.handleCheckAirport}
        />
        <label className="custom-control-label" htmlFor="chkAirport">
          Entrega / Devolución en Aeropuerto
        </label>
      </div>
    );

    return (
      <Fragment>
        <div className="row">
          <div className="col-12 col-lg-5 px-1">
            <div className="form-group">
              <LocationSelector
                countries={countries}
                handleSelectLocation={this.handleSelectLocation}
                placeholder="Dónde te gustaría recoger tu auto"
                label="Tu dirección, aeropuerto, hotel, comuna..."
                airportsFirst={chkAirport}
                loadSpinner={this.loadSpinner}
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3 px-1">
            <div className="form-group">
              <label>Desde</label>
              <DateTimePicker
                changeTime={this.changeTimeFrom}
                date={fromDate}
                pickerId="search_from_"
                time={fromTime}
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3 px-1">
            <div className="form-group">
              <label>Hasta</label>
              <DateTimePicker
                changeTime={this.changeTimeUntil}
                date={untilDate}
                pickerId="search_until_"
                time={untilTime}
              />
            </div>
          </div>
          <div className="col-2 col-md-1 px-1">
            <div className="form-group">
              <label className="d-block">&nbsp;&nbsp;</label>
              <button
                type="button"
                className="btn search-button"
                onClick={() => this.search()}
              >
                <i className="mdi mdi-magnify"></i>{" "}
                <span className="d-lg-none">Buscar</span>
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Search;
