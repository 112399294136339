import React from "react";
import iconWink from "images/IconWink.png";

const NeedMoreDataModal = ({ url }) => (
  <div
    className="modal fade"
    id="NeedMoreDataModal"
    aria-hidden="true"
    aria-labelledby="NeedMoreDataModalLabel"
    tabIndex={-1}
  >
    <div className="modal-dialog modal-dialog-centered modal-appcar">
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="btn-m-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            x
          </button>
          <h5 className="modal-title" id="NeedMoreDataModalLabel">
            Reserva recibida
          </h5>
        </div>
        <div className="modal-body">
          <div className="row justify-content-center">
            <div className="col-auto text-center">
              <img src={iconWink} height="42px" className="mt-3 mb-3" />
              <h5 className="mb-4 fs-25 fw-900">
                ¡Hemos recibido <br /> tu solicitud de reserva!
              </h5>
              <p
                className="mb-4 fs-16 fw-600"
                style={{ paddingLeft: 35, paddingRight: 35 }}
              >
                Ya le avisamos al owner y estamos a la espera de su confirmación
                para darte una respuesta lo antes posible.
              </p>
              <p
                className="mb-4 fs-16 fw-600"
                style={{ paddingLeft: 35, paddingRight: 35 }}
              >
                Para poder hacer efectiva tu reserva, es necesario que completes
                tu registro. Ten a mano tu licencia de conducir y cédula de
                identidad.
              </p>
            </div>
          </div>
          <div className="row justify-content-around">
            <div className="col-auto">
              <a href={url} className="btn btn-appcar-primary btn-slim">
                Completa tu registro
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default NeedMoreDataModal;
