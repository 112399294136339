import React, { Component } from "react";
import {
  advanceNoticeOptions,
  DefaultRentalHours,
  preparationTimeOptions,
  reservationPreferencesOptions,
} from "../../../common/constants";
import SelectWithRadio from "../../../common/components/select/SelectWithRadio";
import TimePicker from "../../../common/search_components/TimePicker";
import iconInfo from "images/Icons/ico-info.png";
import ToolTip, { LoadToolTips } from "../../../common/components/ToolTip";

export default class RentalAvailability extends Component {
  state = {
    rentalInHours:
      this.props.data.availability?.booking_length?.minimum_length == 0,
  };

  componentDidUpdate(prevProps) {
    const prevBookingLength = prevProps.data.availability?.booking_length || {};
    const bookingLength = this.props.data.availability?.booking_length || {};
    if (prevBookingLength?.minimum_length != bookingLength.minimum_length)
      this.setState({
        rentalInHours: bookingLength.minimum_length == 0,
      });
    LoadToolTips();
  }

  componentDidMount() {
    LoadToolTips();
  }

  loadAdvancedAvailability = (val) => {
    this.props.handleChangeAdvancedAvailabilityCheck(val);
    const hours = this.props.data.availability.hours;
    const checkinCheckout = this.props.data.availability.checkin_checkout;
    const base = {
      no_rental: false,
      closed: false,
      opening_time: checkinCheckout.checkin_start_time,
      closing_time: checkinCheckout.checkin_end_time,
    };
    for (const day in hours) {
      if (day == "base") continue;
      if (!hours[day].opening_time) hours[day].opening_time = base.opening_time;
      if (!hours[day].closing_time) hours[day].closing_time = base.closing_time;
      this.props.handleChangeAvailability(hours[day], day, "hours");
    }
  };

  permitRentalInHours = (val) => {
    this.setState({ rentalInHours: val }, () => {
      if (val) {
        this.props.handleChangeAvailability(
          0,
          "minimum_length",
          "booking_length"
        );
        this.props.handleChangeAvailability(
          0,
          "minimum_length_saturday",
          "booking_length"
        );
        this.props.handleChangeAvailability(
          0,
          "minimum_length_sunday",
          "booking_length"
        );
      }
    });
  };

  render() {
    const {
      data,
      handleSubmit,
      isSubmitting,
      handleChangeAvailability,
      handleChangeDates,
    } = this.props;
    const availability = data.availability;
    const reservationPreferences = (availability &&
      availability.reservation_preferences) || {
      advance_notice: 0,
      preparation_time: 1,
      booking_window: 120,
    };
    const advanceNotice =
      reservationPreferences && reservationPreferences.advance_notice;
    const preparationTime =
      reservationPreferences && reservationPreferences.preparation_time;
    const bookingWindow =
      reservationPreferences && reservationPreferences.booking_window;
    const selectedAdvanceNotice = advanceNoticeOptions.find(
      (x) => x.value == advanceNotice
    );
    const selectedPreparationTime = preparationTimeOptions.find(
      (x) => x.value == preparationTime
    );
    const selectedBookingWindow = reservationPreferencesOptions.find(
      (x) => x.value == bookingWindow
    );
    const bookingLength = availability.booking_length;
    const minimumLength = bookingLength.minimum_length;
    const maximumLength = bookingLength.maximum_length;
    const minLengthSaturday = bookingLength.minimum_length_saturday;
    const checkinCheckout = availability.checkin_checkout;
    const checkinStartTime = checkinCheckout.checkin_start_time;
    const checkinEndTime = checkinCheckout.checkin_end_time;
    const { rentalInHours } = this.state;
    const advancedAvailabilityOn = this.props.advancedAvailabilityOn;
    let advancedAvailabilityContainer = null;
    const isAvailable = availability.reservation_preferences.booking_window === 120;
    if (advancedAvailabilityOn) {
      const hours = availability.hours || DefaultRentalHours;
      const hoursContainer = [];
      const daysDictionary = {
        mon: "Lunes",
        tue: "Martes",
        wed: "Miércoles",
        thu: "Jueves",
        fri: "Viernes",
        sat: "Sábado",
        sun: "Domingo",
      };
      for (const day in hours) {
        if (day == "base") continue;
        const defaultOpenHour =
          hours[day].opening_time || hours.base.opening_time;
        const defaultCloseHour =
          hours[day].closing_time || hours.base.closing_time;
        const container = (
          <div key={day} className="mb-4">
            <h5 className="fs-13 fw-800 mb-0">{daysDictionary[day]}</h5>
            <div className="row">
              <div className="col-6 pr-1">
                <div className="form-group">
                  <label className="label-inside">Desde</label>
                  <TimePicker
                    changeTime={(opt) => {
                      handleChangeAvailability(
                        { ...hours[day], opening_time: opt.value },
                        day,
                        "hours"
                      );
                    }}
                    key={"time_picker_" + day}
                    time={defaultOpenHour}
                    isDisabled={hours[day].no_rental || hours[day].closed}
                    inputStyle="date-time-picker form-control appcar-input"
                  />
                </div>
              </div>
              <div className="col-6 pl-1">
                <div className="form-group">
                  <label className="label-inside">Hasta</label>
                  <TimePicker
                    changeTime={(opt) => {
                      handleChangeAvailability(
                        { ...hours[day], closing_time: opt.value },
                        day,
                        "hours"
                      );
                    }}
                    time={defaultCloseHour}
                    isDisabled={hours[day].no_rental || hours[day].closed}
                    inputStyle="date-time-picker form-control appcar-input"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 pr-1">
                <div className="form-group">
                  <div className="form-check pl-3 mt-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={"checkNoRental" + day}
                      name={"checkNoRental" + day}
                      checked={hours[day].no_rental}
                      onChange={(e) =>
                        handleChangeAvailability(
                          {
                            ...hours[day],
                            no_rental: e.target.checked,
                          },
                          day,
                          "hours"
                        )
                      }
                    />
                    <label
                      className="form-check-label fw-800 fs-11"
                      htmlFor={"checkNoRental" + day}
                    >
                      No arriendo
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-6 pl-1">
                <div className="form-group">
                  <div className="form-check pl-3 mt-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={"checkNotOpen" + day}
                      name={"checkNotOpen" + day}
                      checked={hours[day].closed}
                      onChange={(e) =>
                        handleChangeAvailability(
                          {
                            ...hours[day],
                            closed: e.target.checked,
                          },
                          day,
                          "hours"
                        )
                      }
                    />
                    <label
                      className="form-check-label fw-800 fs-11"
                      htmlFor={"checkNotOpen" + day}
                    >
                      No abierto
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        hoursContainer.push(container);
      }
      advancedAvailabilityContainer = (
        <>
          <h5 className="fs-13 fw-800 mt-4 mb-3">Disponibilidad avanzada</h5>
          <p className="fs-12">
            Determina horas específicas de disponibilidad para la entrega y
            devolución según el día de la semana. Además tienes las opciones de{" "}
            <b>No arriendo</b>
            <span
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              data-bs-html="true"
              data-bs-title='<div class="title">No arriendo</div><div class="description">Los días que selecciones en esta columna, configurarán que ninguna solicitud de arriendo podrá contener estos días.<br/><br/>Por ejemplo: si seleccionas el día Sábado, ningún arriendo podrá incluir el día Sábado.</div>'
              data-bs-template='<div class="tooltip" role="tooltip"><div class="tooltip-inner wider"></div></div>'
              className="d-inline ttip"
            >
              <img src={iconInfo} width={14} className="ml-0" />
            </span>{" "}
            y <b>No abierto</b>
            <span
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              data-bs-html="true"
              data-bs-title='<div class="title">No abierto</div><div class="description">Los días que selecciones en esta columna, configurarán que ninguna solicitud de arriendo podrá partir o terminar en el día seleccionado.<br/><br/>Por ejemplo: si seleccionas el día Sábado, ningún arriendo podrá partir o terminar el día Sábado.</div>'
              data-bs-template='<div class="tooltip" role="tooltip"><div class="tooltip-inner wider"></div></div>'
              className="d-inline ttip"
            >
              <img src={iconInfo} width={14} className="ml-0" />
            </span>
          </p>
          {hoursContainer}
        </>
      );
    }
    return (
      <>
        <h5 className="fs-13 fw-800">Preferencias de la reserva</h5>
        <div className="row">
          <div className="col pr-0">
            <div className="form-group">
              <label className="label-inside">Días de anticipación</label>
              <SelectWithRadio
                value={selectedAdvanceNotice}
                options={advanceNoticeOptions}
                placeholder="Selecciona..."
                noOptionsMessage={() => "Error..."}
                onChange={(opt) =>
                  handleChangeAvailability(
                    opt.value,
                    "advance_notice",
                    "reservation_preferences"
                  )
                }
                classNamePrefix="RS-FIX"
                className="react-select-fix appcar-input"
                hideSelectedOptions={false}
                backspaceRemovesValue={false}
                isSearchable={false}
              />
            </div>
          </div>
          <div className="col-auto pt-1 pl-0" style={{ marginTop: 26 }}>
            <ToolTip
              title="Días de anticipación"
              tooltipHTML="Elige si quiere recibir reservas que son para el mismo día o prefieres que sólo te lleguen reservas para días posteriores."
              containerHTML={<img src={iconInfo} width={20} className="ml-1" />}
              id="ttip_rental_advance"
            />
          </div>
        </div>
        <div className="row">
          <div className="col pr-0">
            <div className="form-group">
              <label className="label-inside">
                Tiempo de preparación (horas)
              </label>
              <SelectWithRadio
                value={selectedPreparationTime}
                options={preparationTimeOptions}
                placeholder="Selecciona..."
                noOptionsMessage={() => "Error..."}
                onChange={(opt) => {
                  handleChangeAvailability(
                    opt.value,
                    "preparation_time",
                    "reservation_preferences"
                  );
                }}
                classNamePrefix="RS-FIX"
                className="react-select-fix appcar-input"
                hideSelectedOptions={false}
                backspaceRemovesValue={false}
                isSearchable={false}
              />
            </div>
          </div>
          <div className="col-auto pt-1 pl-0" style={{ marginTop: 26 }}>
            <ToolTip
              title="Tiempo de preparación"
              tooltipHTML="Determina el tiempo que necesitas para preparar tu auto antes de un nuevo arriendo."
              containerHTML={<img src={iconInfo} width={20} className="ml-1" />}
              id="ttip_rental_preparation_time"
            />
          </div>
        </div>
        <h5 className="fs-13 fw-800 mt-4">Duración del arriendo</h5>
        <div className="row align-items-center">
          <div className="col">
            <div className="form-group">
              <div className="form-check pl-3 mt-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="check-duration"
                  name="check-duration"
                  checked={rentalInHours}
                  onChange={(e) => this.permitRentalInHours(e.target.checked)}
                />
                <label
                  className="form-check-label fw-800 fs-11"
                  htmlFor="check-duration"
                >
                  Permito arriendo por horas
                </label>
              </div>
            </div>
          </div>
          <div className="col-auto ml-2">
            <ToolTip
              title="Permito arriendo por horas"
              tooltipHTML="Puedes configurar el tiempo mínimo y máximo que desees para los arriendos, desde un arriendo por horas o algún mínimo de días en que tu auto se pueda arrendar."
              containerHTML={<img src={iconInfo} width={20} className="ml-1" />}
              id="ttip_rental_by_hours"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 pr-1">
            <div className="form-group">
              <label className="label-inside">Mínimo de días</label>
              <input
                min={0}
                max={30}
                type="number"
                id="minlength-rental"
                className="form-control appcar-input no-min-width no-arrows"
                value={minimumLength}
                disabled={rentalInHours}
                required
                onChange={(e) => {
                  const newVal = parseInt(e.target.value) || 0;
                  handleChangeAvailability(
                    newVal,
                    "minimum_length",
                    "booking_length"
                  );
                  handleChangeAvailability(
                    newVal,
                    "minimum_length_saturday",
                    "booking_length"
                  );
                  handleChangeAvailability(
                    newVal,
                    "minimum_length_sunday",
                    "booking_length"
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="mt-4 mb-1 row align-items-center">
          <div className="col">
            <label className="fs-13 fw-800 text-appcar-primary">
              Horarios para entrega y devolución
            </label>
          </div>
          <div className="col-auto ml-2">
            <ToolTip
              title="Horarios para entrega y devolución"
              tooltipHTML="Horarios mínimos y máximos en que se puede entregar o recibir un auto."
              containerHTML={<img src={iconInfo} width={20} className="ml-1" />}
              id="ttip_rental_start_end_hours"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6 pr-1">
            <div className="form-group">
              <label className="label-inside">Desde</label>
              <TimePicker
                changeTime={(opt) => {
                  handleChangeAvailability(
                    opt.value,
                    "checkin_start_time",
                    "checkin_checkout"
                  );
                }}
                time={checkinStartTime}
                isDisabled={false}
                inputStyle="date-time-picker form-control appcar-input"
              />
            </div>
          </div>
          <div className="col-6 pl-1">
            <div className="form-group">
              <label className="label-inside">Hasta</label>
              <TimePicker
                changeTime={(opt) => {
                  handleChangeAvailability(
                    opt.value,
                    "checkin_end_time",
                    "checkin_checkout"
                  );
                }}
                time={checkinEndTime}
                inputStyle="date-time-picker form-control appcar-input"
                isDisabled={false}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="form-check pl-3 mt-2">
            <input
              type="checkbox"
              className="form-check-input"
              id="checkAdvancedAvailability"
              name="checkAdvancedAvailability"
              checked={advancedAvailabilityOn}
              onChange={(e) => this.loadAdvancedAvailability(e.target.checked)}
            />
            <label
              className="form-check-label fw-800 fs-11"
              htmlFor="checkAdvancedAvailability"
            >
              Activar opciones de disponibilidad avanzada
            </label>
          </div>
        </div>
        {advancedAvailabilityContainer}
        <div className="row mt-3 mb-4">
          <div className="col">
            <label className="fs-13 fw-800 text-appcar-primary" htmlFor="reservation_preferences">
              Horarios para entrega y devolución
            </label>
          </div>
          <div className="col-auto ml-2">
            <ToolTip
              title="¿Está tu auto disponible para ser publicado?"
              tooltipHTML="Si tu auto no está disponible para ser arrendado, puedes pausar la publicación cuando necesites y reactivarla cuando quieras."
              containerHTML={<img src={iconInfo} width={20} className="ml-1" />}
              id="ttip_rental_reservation_preferences_"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <button
              type="button"
              onClick={() => {
                handleChangeAvailability(
                  120,
                  "booking_window",
                  "reservation_preferences"
                );
                handleChangeDates(120);
              }}
              className={`btn btn-appcar-${
                isAvailable ? "secondary" : "white text-black"
              } w-100 fs-12`}
            >
              Sí, publicar
            </button>
          </div>
          <div className="col-6">
            <button
              type="button"
              onClick={() => {
                handleChangeAvailability(
                  0,
                  "booking_window",
                  "reservation_preferences"
                );
                handleChangeDates(0);
              }}
              className={`btn btn-appcar-${
                !isAvailable ? "secondary" : "white text-black"
              } w-100 fs-12 px-1`}
            >
              Pausar publicación
            </button>
          </div>
        </div>
        <div className="text-center mt-3 mb-4">
          <button
            type="button"
            className="btn btn-appcar-primary pt-2 pb-2"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Continuar
          </button>
        </div>
      </>
    );
  }
}
