import React from "react";
import iconWink from "images/Icons/GuinoOk.svg";

const ThanksModalVehicle = () => (
  <div
    className="modal fade"
    id="thanksModal"
    aria-hidden="true"
    aria-labelledby="thanksModalLabel"
    tabIndex={-1}
  >
    <div className="modal-dialog modal-dialog-centered modal-appcar">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="thanksModalLabel">
            Confirmación de Publicación
          </h5>
        </div>
        <div className="modal-body px-5" style={{ marginBottom: 10 }}>
          <div className="row justify-content-center">
            <div className="col-auto text-center">
              <img src={iconWink} width={84} />
              <h5 className="mt-3 mb-4 fs-30 fw-900">¡Tu auto ya está publicado!</h5>
              <div
                className="fs-15 fw-700 text-left mb-5"
                style={{ maxWidth: 413 }}
              >
                <p>
                  Estamos revisando que esté todo ok con tu publicación. En Mis
                  autos, podrás completar toda la información necesaria en:
                </p>
                <ul>
                  <li>Datos del auto</li>
                  <li>Datos de publicación</li>
                  <li>Actualizar tu disponibilidad en el calendario</li>
                </ul>
                Recuerda que mientras más completa esté tu publicación, más
                reservas recibirás.
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-auto">
              <a
                href="/vehicles?success=1"
                className="btn btn-appcar-primary pt-2 pb-2"
                style={{ width: 240 }}
              >
                Ir a Mis autos
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ThanksModalVehicle;
