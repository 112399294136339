import React from "react";
import { numeral } from "../../../../common/constants";

const TotalPriceContainer = ({
  pricing,
  bookingLengthText,
  showModalPricing,
}) => (
  <div className="price-container">
    <div className="total">{numeral(pricing.total).format("$0,0")}</div>
    <div className="duration">{bookingLengthText}</div>
    <div className="duration">
      <span
        className="edit-dates cursor-pointer text-underline"
        onClick={() => showModalPricing()}
      >
        Detalle precio
      </span>
    </div>
  </div>
);

export default TotalPriceContainer;
