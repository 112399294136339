import React, { Component } from "react";
import Modal from "../modal/Modal";
import MercadoPagoForm from "./MercadoPagoForm";

class MercadoPagoModal extends Component {
  state = {};

  render (){
    const modalBody = (
      <MercadoPagoForm
        mpPublicKey={this.props.mpPublicKey}
        user={this.props.user}
        actionUrl={this.props.actionUrl}
        resultUrl={this.props.resultUrl}
        callback={this.props.callback}
        type={this.props.type}
      />
    );
    const modalFooter = (
      <div className="row">
        <div className="col">
          <button 
            className="btn btn-danger" 
            type="button" 
            onClick={() => $("#modal-add-mercadopago").modal("hide")}
          >
            Cancelar
          </button>
        </div>
      </div>
    )
    return(
      <Modal
        title="Añadir a MercadoPago como opción de pago"
        id={"modal-add-mercadopago"}
        hasHeader={true}
        hasBody={true}
        hasFooter={true}
        body={modalBody}
        footer={modalFooter}
        closeable={true}
        dismissOnOutsideClick={true}
        labelledBy="md-checkout-title"
        modalClass="modal-lg modal-dialog-centered"
        bodyClass="px-4"
      />
    )
  }
}

export default MercadoPagoModal;