import React, { Component } from "react";
import dayjs from "dayjs";
import PricingDetails from "../../../../common/PricingDetails";
import Policies from "../../../../common/Policies";
import { BookingStatus } from "../../../../common/constants";
import "dayjs/locale/es";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export class Bookinginfo extends Component {
  handleCancel = () => {
    this.props.cancelBooking();
  };

  render() {
    const {
      date_from,
      date_until,
      price,
      status,
      pricing,
      owner_base_booking_status
    } = this.props.booking;
    const cancellation_policy = this.props.booking.vehicle.cancellation_policy;
    const fuel_policy = this.props.booking.vehicle.fuel_policy;
    const already_cancelled = status.id == BookingStatus.CANCELADO;
    const replaced = status.id == BookingStatus.REEMPLAZADO;
    const buttonCancel = (
      <React.Fragment>
        <div className="row">
          <div className="col">
            <button
              className="btn btn-danger fill"
              type="button"
              disabled={replaced || already_cancelled || this.props.cancelError}
              onClick={e => {
                e.preventDefault();
                if (!already_cancelled && !replaced) this.handleCancel();
              }}
            >
              {already_cancelled
                ? "RESERVA CANCELADA"
                : replaced
                  ? "RESERVA REEMPLAZADA"
                  : this.props.cancelError
                    ? "NO SE PUEDE CANCELAR"
                    : "CANCELAR RESERVA"}
            </button>
          </div>
        </div>
        <hr />
      </React.Fragment>
    );
    const { selected_pickup_location, selected_dropoff_location } = pricing;
    const pickup_location =
      selected_pickup_location && selected_pickup_location.location
        ? selected_pickup_location.location.name
        : ""
    const dropoff_location =
      selected_dropoff_location && selected_dropoff_location.location
        ? selected_dropoff_location.location.name
        : ""
    price.owner_discount = 0
    if (owner_base_booking_status && owner_base_booking_status.id == BookingStatus.ACTIVO) {
      const bookingTotal = price.total;
      const originalPrice = this.props.originalBooking ? this.props.originalBooking.price : null;
      if (originalPrice && bookingTotal > originalPrice.total) {
        price.owner_discount = (price.total - price.value_added_tax) - (originalPrice.total - originalPrice.value_added_tax);
        price.value_added_tax = originalPrice.value_added_tax;
        price.total = originalPrice.total
      }
    }
    const pricingDetails = (
      <PricingDetails
        price={price}
        dateFrom={date_from}
        dateUntil={date_until}
      />
    );
    return (
      <div className="vehicle-scheduler">
        <h3 className="mb-3">DETALLE DE LA RESERVA</h3>
        <hr />
        <div className="row charge-text-row">
          <div className="col-lg-12">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td>Lugar de entrega</td>
                  <td style={{ textAlign: "end" }}>{pickup_location}</td>
                </tr>
                <tr>
                  <td>Lugar de devolución</td>
                  <td style={{ textAlign: "end" }}>{dropoff_location}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="charge-text-row">
          <div className="start">Desde</div>
          <div className="end">
            {dayjs(date_from || Date.now())
              .locale("es")
              .format("LLL")}
          </div>
          <div style={{ clear: "both" }} />
        </div>
        <div className="charge-text-row">
          <div className="start">Hasta</div>
          <div className="end">
            {dayjs(date_until || Date.now())
              .locale("es")
              .format("LLL")}
          </div>
          <div style={{ clear: "both" }} />
        </div>
        <hr className="mb-3" />
        {pricingDetails}
        {buttonCancel}
        <Policies
          fuelPolicy={fuel_policy}
          cancellationPolicy={cancellation_policy}
          isOwner={false}
          isDriver={true}
        />
      </div>
    );
  }
}

export default Bookinginfo;
