import React from "react";
import TotalPriceContainer from "./TotalPriceContainer";
import DatesContainer from "./DatesContainer";

const SchedulerContainer = ({
  pricing,
  from,
  until,
  bookingLengthText,
  datesOverlaped,
  showModalPricing,
  showModalDates,
  btnBook,
}) => (
  <div className="scheduler-component">
    <div className="row align-items-center">
      <div className="col-6 scheduler-separator text-center">
        <TotalPriceContainer
          pricing={pricing}
          bookingLengthText={bookingLengthText}
          showModalPricing={showModalPricing}
        />
      </div>
      <div className="col-6 text-center">
        <DatesContainer
          from={from}
          until={until}
          datesOverlaped={datesOverlaped}
          showModalDates={showModalDates}
        />
      </div>
    </div>
    <div className="row justify-content-center">{btnBook}</div>
  </div>
);

export default SchedulerContainer;
