import React, { Component, Fragment } from "react";
import { Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import Spinner from "../../common/Spinner";
import FlashMessage from "../../common/FlashMessage";
import axios from "axios";
import Select from "react-select";
import { AxiosBackofficeInterceptor } from "../../common/axios_interceptor";
axios.interceptors.response.use(undefined, AxiosBackofficeInterceptor);

const LocationsSchema = Yup.object().shape({
  name: Yup.string().required("Campo requerido"),
  latitude: Yup.number().required("Campo requerido"),
  longitude: Yup.number().required("Campo requerido"),
  country: Yup.string().required("Campo requerido"),
  url_image: Yup.string()
});

class LocationsForm extends Component {
  state = {
    location: this.props.location || {
      name: "",
      latitude: "",
      longitude: "",
      url_image: "",
      country: {
        id: "",
        description: ""
      }
    },
    selected_country: this.props.location ? {
      label: this.props.location.country.description,
      value: this.props.location.country.id
    } : undefined,
    ui: {
      isSubmitting: false,
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined
    }
  };

  changeCountry = option => {
    const country = this.props.countries.find(
      country => country.id == option.value
    );
    this.setState(state => {
      state.city.country = country;
      state.selected_country = option;
      return { city: state.city, selected_country: state.selected_country };
    });
  };

  showMessage = msg => {
    this.setState(state => {
      state.ui.showMessage = msg.showMessage;
      state.ui.messageType = msg.messageType;
      state.ui.messages = msg.messages;
      state.ui.messageTitle = msg.messageTitle;
      return { ui: state.ui };
    });
  };

  render() {
    const flash_message = this.state.ui.showMessage ? (
      <FlashMessage
        type={this.state.ui.messageType}
        msg={this.state.ui.messages}
        title={this.state.ui.messageTitle}
      />
    ) : null;
    const button_label = this.props.location ? "Editar" : "Agregar nuevo";
    const countries_options = this.props.countries.map(country => {
      return { label: country.description, value: country.id };
    });
    const selected_country = this.state.selected_country;
    return (
      <Fragment>
        {flash_message}
        <Formik
          showMessage={this.showMessage}
          enableReinitialize={true}
          initialValues={this.state.location}
          validationSchema={LocationsSchema}
          onSubmit={(values, { setSubmitting }) => {
            // const token = document.getElementsByName("csrf-token")[0].content;
            const token = null;
            let url = "";
            let method = "";
            if (this.props.location) {
              url = `/backoffice/locations/${this.props.location.id}.json`;
              method = "PUT";
              values.id = parseInt(values.id);
            } else {
              url = `/backoffice/locations.json`;
              method = "POST";
            }
            axios(url, {
              method,
              url,
              responseType: "json",
              headers: {
                "Content-Type": "application/json"
              },
              data: { location: values }
            })
              .then(response => {
                if (response.data.success) {
                  window.location.assign(`/backoffice/locations?success=1`);
                } else {
                  this.showMessage({
                    showMessage: true,
                    messageType: "alert-warning",
                    messages: response.data.messages || [
                      "Ha ocurrido un error inesperado"
                    ],
                    messageTitle: "Alerta"
                  });
                }
              })
              .catch(error =>
                this.showMessage({
                  showMessage: true,
                  messageType: "alert-danger",
                  messages: ["Ha ocurrido un error inesperado"],
                  messageTitle: "Error"
                })
              )
              .then(() => setSubmitting(false));
          }}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => {
            return (
              <Fragment>
                <Spinner loading={isSubmitting || this.state.ui.isSubmitting} />
                <form onSubmit={handleSubmit} className="">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label htmlFor="name">Nombre del lugar</label>
                        <Field
                          type="text"
                          name="name"
                          className={
                            "form-control appcar-input " +
                            classNames({
                              "is-invalid": touched.name && errors.name
                            })
                          }
                        />
                        <ErrorMessage name="name">
                          {msg => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label htmlFor="country">Nombre del país</label>
                        <Select
                          value={selected_country}
                          options={countries_options}
                          placeholder="Selecciona..."
                          noOptionsMessage={() => "No existen países aún..."}
                          onChange={this.changeCountry}
                          classNamePrefix="RS-FIX"
                          className="react-select-fix"
                        />
                        <ErrorMessage name="country.description">
                          {msg => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label htmlFor="latitude">Latitud</label>
                        <Field
                          type="number"
                          name="latitude"
                          className={
                            "form-control appcar-input " +
                            classNames({
                              "is-invalid": touched.latitude && errors.latitude
                            })
                          }
                        />
                        <ErrorMessage name="latitude">
                          {msg => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label htmlFor="longitude">Longitud</label>
                        <Field
                          type="number"
                          name="longitude"
                          className={
                            "form-control appcar-input " +
                            classNames({
                              "is-invalid":
                                touched.longitude && errors.longitude
                            })
                          }
                        />
                        <ErrorMessage name="longitude">
                          {msg => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label htmlFor="url_image">URL imagen</label>
                        <Field
                          type="text"
                          name="url_image"
                          className={
                            "form-control appcar-input " +
                            classNames({
                              "is-invalid":
                                touched.url_image && errors.url_image
                            })
                          }
                        />
                        <ErrorMessage name="url_image">
                          {msg => (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-appcar-primary"
                    disabled={isSubmitting}
                  >
                    {`${button_label} lugar`}
                  </button>
                </form>
              </Fragment>
            );
          }}
        </Formik>
      </Fragment>
    );
  }
}

export default LocationsForm;
