import React, { Component, Fragment } from "react";
import { ErrorMessage } from "formik";
import Spinner from "../../common/Spinner";
import axios from "axios";
import orderBy from "lodash/orderBy";
import mapValues from "lodash/mapValues";
import groupBy from "lodash/groupBy";
import omit from "lodash/omit";
import cloneDeep from "lodash/cloneDeep";
import Map from "../../common/components/map/Map";
import SearchResultsCard from "../../common/components/cards/SearchResultsCard";
import VehicleCard from "../../common/components/cards/VehicleCard";
import Select from "react-select";
import { constants, numeral } from "../../common/constants";
import { AxiosApplicationInterceptor } from "../../common/axios_interceptor";
import RangeSlider from "../../common/RangeSlider";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/es";
import LocationSelector from "../../common/search_components/LocationSelector";
import DateTimePicker from "../../common/search_components/DateTimePicker";
import moment from "moment";
dayjs.extend(localizedFormat);
axios.interceptors.response.use(undefined, AxiosApplicationInterceptor);

class Searcn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      form_data: {
        lat: parseFloat(this.props.lat) || constants.RENTAL_SEARCH_LATITUDE,
        lon: parseFloat(this.props.lon) || constants.RENTAL_SEARCH_LONGITUDE,
        fromDate: moment(constants.RENTAL_SEARCH_FROM).format("DD/MM/YYYY"),
        untilDate: moment(constants.RENTAL_SEARCH_UNTIL).format("DD/MM/YYYY"),
        fromTime: moment(constants.RENTAL_SEARCH_FROM).format("HH:mm"),
        untilTime: moment(constants.RENTAL_SEARCH_UNTIL).format("HH:mm"),
      },
      address: this.props.address || constants.RENTAL_SEARCH_DEFAULT_ADDRESS,
      mapRentals: [],
      currentPercentageSearch: 60.0,
      ui: {
        isSubmitting: false,
        showMessage: false,
        messageType: undefined,
        messages: [],
        messageTitle: undefined,
      },
      highlightRentalId: undefined,
      showMap: true,
      latLng: null,
      rental_id: null,
      vehicle_extras: this.props.vehicleExtras,
      vehicle_features: this.props.vehicleFeatures,
      vehicle_categories: this.props.vehicleCategories,
      vehicle_transmissions: this.props.vehicleTransmissions,
      selected_extras: undefined,
      selected_features: undefined,
      selected_categories: undefined,
      selected_transmissions: undefined,
      price_min: constants.MIN_PRICE,
      price_max: constants.MAX_PRICE,
      price_min_update: constants.MIN_PRICE,
      price_max_update: constants.MAX_PRICE,
      show_filters: false,
      orderAsc: true,
      chkAirport: this.props.subfilter
        ? this.props.subfilter.indexOf("aeropuerto") > -1
        : false,
      customZoom: false,
      shouldUpdateMap: false,
    };
    this.willUnmount = false;
    this.cancelToken = null;
  }

  componentWillUnmount() {
    this.willUnmount = true;
  }

  handleCheckAirport = (chkAirport) => {
    this.setState({ chkAirport });
  };

  changeShowMap = (showMap) => this.setState({ showMap });
  changeShowFilters = (show_filters) => this.setState({ show_filters });

  componentDidMount() {
    const this_ = this;
    this_.willUnmount = false;
    dayjs.locale("es");
    this.cancelToken = axios.CancelToken.source();
    let user_date_from = localStorage.getItem("user_date_from");
    let user_date_until = localStorage.getItem("user_date_until");
    const t_date_from = dayjs(constants.RENTAL_SEARCH_FROM);
    const t_date_until = dayjs(constants.RENTAL_SEARCH_UNTIL);
    user_date_from = user_date_from
      ? dayjs(user_date_from)
      : dayjs(constants.RENTAL_SEARCH_FROM);
    user_date_until = user_date_until
      ? dayjs(user_date_until)
      : dayjs(constants.RENTAL_SEARCH_UNTIL);

    let state_from =
      t_date_from.valueOf() > user_date_from.valueOf()
        ? t_date_from
        : user_date_from;
    let state_until =
      t_date_until.valueOf() > user_date_until.valueOf()
        ? t_date_until
        : user_date_until;
    if (moment(this.props.from).isValid()) {
      state_from = moment(this.props.from);
    }
    if (moment(this.props.until).isValid()) {
      state_until = moment(this.props.until);
    }
    this.setState(
      (state) => {
        state.form_data.fromDate = state_from.format("DD/MM/YYYY");
        state.form_data.fromTime = state_from.format("HH:mm");
        state.form_data.untilDate = state_until.format("DD/MM/YYYY");
        state.form_data.untilTime = state_until.format("HH:mm");
        return { ui: state.ui, form_data: state.form_data };
      },
      () => {
        localStorage.setItem("user_date_from", state_from);
        localStorage.setItem("user_date_until", state_until);
        this.initializeCalendars(state_from, state_until);
        this.handleSubmit(true);
      }
    );

    document.addEventListener("wheel", this.mouseHandler, { passive: false });
    document.addEventListener("DOMMouseScroll", this.mouseHandler, {
      passive: false,
    });
  }

  mouseHandler = (e) => {
    if (
      (e.target.previousSibling &&
        e.target.previousSibling.className == "gm-style-pbc") ||
      (e.target.parentElement &&
        e.target.parentElement.className == "map-price-marker") ||
      e.target.className == "gm-svpc" ||
        e.target.className == "gm-control-active" ||
        e.target.tagName == "AREA" ||
        e.target.className == "map-price-marker" ||
        e.target.className == "gm-control-active gm-fullscreen-control" ||
        e.target.src == "https://maps.gstatic.com/mapfiles/transparent.png"
    ) {
      e.preventDefault();
      let element = document.getElementById("vehicleResultsContainer");
      const uAgent = navigator.userAgent.toLowerCase();
      const scroll = uAgent.indexOf("firefox") > -1 ? e.deltaY * 33 : e.deltaY;
      element.scrollTop = element.scrollTop + scroll;
    }
  };

  initializeCalendars = () => {
    const this_ = this;
    const options = {
      showDropdowns: true,
      maxYear: constants.MAX_YEAR,
      singleDatePicker: true,
      minDate: new Date(),
      locale: {
        format: "DD/MM/YYYY",
        cancelLabel: "Cancelar",
        applyLabel: "Aplicar",
        fromLabel: "Desde",
        toLabel: "Hasta",
        daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        firstDay: 1,
      },
    };

    $("#search_from").daterangepicker(options, (start) => {
      const fData = this_.state.form_data;
      const from_ = moment(start);
      const until_ = moment(fData.untilDate, "DD/MM/YYYY");
      let fromDate = from_.format("DD/MM/YYYY");
      let untilDate = until_.format("DD/MM/YYYY");
      if (until_.valueOf() < from_.valueOf()) {
        untilDate = from_.clone().format("DD/MM/YYYY");
        $("#search_until")
          .data("daterangepicker")
          .setStartDate(moment(untilDate));
        $("#search_until")
          .data("daterangepicker")
          .setEndDate(moment(untilDate));
      }
      const fromString = moment(
        fromDate + fData.fromTime,
        "DD/MM/YYYYHH:mm"
      ).format();
      const untilString = moment(
        untilDate + fData.untilTime,
        "DD/MM/YYYYHH:mm"
      ).format();
      localStorage.setItem("user_date_from", fromString);
      localStorage.setItem("user_date_until", untilString);
      if (!this_.willUnmount) {
        this_.setState(
          (state) => {
            state.form_data.fromDate = fromDate;
            state.form_data.untilDate = untilDate;
            return { form_data: state.form_data };
          },
          () => this.handleSubmit(true)
        );
      }
    });

    $("#search_until").daterangepicker(options, (start) => {
      const fData = this_.state.form_data;
      const from_ = moment(fData.fromDate, "DD/MM/YYYY");
      const until_ = moment(start);
      let fromDate = from_.format("DD/MM/YYYY");
      let untilDate = until_.format("DD/MM/YYYY");
      if (from_.valueOf() > until_.valueOf()) {
        fromDate = until_.format("DD/MM/YYYY");
        $("#search_from")
          .data("daterangepicker")
          .setStartDate(moment(fromDate));
        $("#search_from")
          .data("daterangepicker")
          .setEndDate(moment(fromDate));
      }
      const fromString = moment(
        fromDate + fData.fromTime,
        "DD/MM/YYYYHH:mm"
      ).format();
      const untilString = moment(
        untilDate + fData.untilTime,
        "DD/MM/YYYYHH:mm"
      ).format();
      localStorage.setItem("user_date_from", fromString);
      localStorage.setItem("user_date_until", untilString);
      if (!this_.willUnmount) {
        this_.setState(
          (state) => {
            state.form_data.untilDate = untilDate;
            state.form_data.fromDate = fromDate;
            return { form_data: state.form_data };
          },
          () => this.handleSubmit(true)
        );
      }
    });
  };

  disperse_rentals = (rentals) => {
    let grouped_rentals = rentals.flatMap((rental) => {
      let rentals_ar = [];
      if (Array.isArray(rental.locations)) {
        rental.locations.forEach((loc, i) => {
          let rental_t = cloneDeep(rental);
          rental_t.location.latitude = loc.location.latitude;
          rental_t.location.longitude = loc.location.longitude;
          rental_t.location.name = loc.location.name;
          rental_t.latlon_key =
            loc.location.latitude.toString() +
            loc.location.longitude.toString();
          rental_t.index = i;
          rentals_ar.push(rental_t);
        });
      }
      return rentals_ar;
    });
    grouped_rentals = orderBy(grouped_rentals, ["latlon_key"], ["asc", "asc"]);
    let mapRentals = [];
    grouped_rentals = mapValues(groupBy(grouped_rentals, "latlon_key"), (x) =>
      x.map((y) => omit(y, "latlon_key"))
    );
    for (let rentals_ in grouped_rentals) {
      for (let rental_ of grouped_rentals[rentals_]) {
        let latLng = new google.maps.LatLng(
          rental_.location.latitude,
          rental_.location.longitude
        );
        if (rentals.length > 0 && grouped_rentals[rentals_].length > 1) {
          for (let i = 0; i < rentals.length; i++) {
            let currentRental = rentals[i];
            let latLng_ = new google.maps.LatLng(
              currentRental.location.latitude,
              currentRental.location.longitude
            );
            if (latLng.equals(latLng_)) {
              let factor = 10;
              if (grouped_rentals[rentals_].length > 5) factor = 20;
              else if (grouped_rentals[rentals_].length > 12) factor = 40;
              else if (grouped_rentals[rentals_].length > 20) factor = 100;
              //Math.round(Math.random() * 400) / 100 = Un número con dos decimales entre 0 y 4
              //El número al azar obtenido luego se suma o resta con un número de 0 al 8 (suma o resta = Math.random() < .5 ? -1 : 1)
              //Finalmente se divide por 100000 para obtener un número con al menos 5 decimales (se incluyen los dos decimales anteriores)
              //La variación final es entre -0.00008 y 0.00012
              //Es posible controlar esto, por ejemplo si queremos que la variación sea menor, disminuyendo los valores de 400 y 8
              let random_factor_lat =
                (Math.round(Math.random() * 100 * factor) / 100 +
                  Math.round(Math.random() * 2 * factor) *
                    (Math.random() < 0.5 ? -1 : 1) -
                  0.5 * factor) /
                100000;
              let random_factor_lng =
                (Math.round(Math.random() * 100 * factor) / 100 +
                  Math.round(Math.random() * 2 * factor) *
                    (Math.random() < 0.5 ? -1 : 1) -
                  0.5 * factor) /
                100000;
              //Finalmente se realiza una última operación para volver a decidir si se suma o resta de forma aleatoria el número final
              //Entonces se sumará o restará el resultado a la latitud/longitud actuales
              const newLat =
                latLng_.lat() +
                (random_factor_lat == 0 ? 0.00002 : random_factor_lat) *
                  (Math.random() < 0.5 ? -1 : 1);
              const newLng =
                latLng_.lng() +
                (random_factor_lng == 0 ? 0.00002 : random_factor_lng) *
                  (Math.random() < 0.5 ? -1 : 1);
              rental_.location.latitude = newLat;
              rental_.location.longitude = newLng;
              latLng = new google.maps.LatLng(newLat, newLng);
            }
          }
        }
        mapRentals.push(rental_);
      }
    }
    return mapRentals;
  };

  goToLocation = (latLng, rental_id) => {
    this.setState({ latLng, rental_id });
  };

  reportChange = () => {
    this.setState({
      latLng: null,
      rental_id: null,
      customZoom: false,
      shouldUpdateMap: false,
    });
  };

  changeFecha = (fecha) => {
    this.setState({ fecha });
  };

  changeLugar = (location) => {
    this.setState({ location, showMap: true });
  };

  changeTimeFrom = (option) => {
    this.setState(
      (state) => {
        state.form_data.fromTime = option.value;
        return { form_data: state.form_data };
      },
      () => this.handleSubmit(true)
    );
  };

  changeTimeUntil = (option) => {
    this.setState(
      (state) => {
        state.form_data.untilTime = option.value;
        return { form_data: state.form_data };
      },
      () => this.handleSubmit(true)
    );
  };

  enterCard = (id) => this.setState({ highlightRentalId: id });

  pinClick = () => this.setState({ highlightRentalId: undefined });

  showMessage = (msg) => {
    this.setState((state) => {
      state.ui.showMessage = msg.showMessage;
      state.ui.messageType = msg.messageType;
      state.ui.messages = msg.messages;
      state.ui.messageTitle = msg.messageTitle;
      return { ui: state.ui };
    });
  };

  loadSpinner = () => {
    this.setState((state) => {
      state.ui.isSubmitting = true;
      return { ui: state.ui };
    });
  };

  handleSelectLocation = ({ success, lat, lon, address }) => {
    if (success) {
      this.setState(
        (state) => {
          state.form_data.lat = lat;
          state.form_data.lon = lon;
          state.address = address;
        },
        () => this.handleSubmit(true)
      );
    } else {
      this.setState((state) => {
        state.ui.isSubmitting = false;
        return { ui: state.ui };
      });
    }
  };

  handleSubmit = (
    shouldUpdateMap = false,
    mapLatLng = null,
    radius = constants.RENTAL_SEARCH_RADIUS
  ) => {
    this.setState((state) => {
      state.ui.isSubmitting = true;
      return { ui: state.ui };
    });
    const {
      selected_extras,
      selected_features,
      selected_categories,
      selected_transmissions,
      price_min,
      price_max,
      form_data,
      chkAirport,
    } = this.state;
    moment.locale("es");

    let from = moment(
      form_data.fromDate + form_data.fromTime,
      "DD/MM/YYYYHH:mm"
    ).format("YYYY-MM-DDTHH:mm:ssZ");
    let until = moment(
      form_data.untilDate + form_data.untilTime,
      "DD/MM/YYYYHH:mm"
    ).format("YYYY-MM-DDTHH:mm:ssZ");

    const subfilter = chkAirport ? "&subfilter=aeropuerto" : "";
    const lat = mapLatLng ? mapLatLng.lat : form_data.lat;
    const lng = mapLatLng ? mapLatLng.lng : form_data.lon;
    let encodedUri = `lat=${encodeURIComponent(lat)}&lon=${encodeURIComponent(
      lng
    )}&radius=${encodeURIComponent(radius)}&from=${encodeURIComponent(
      from
    )}&until=${encodeURIComponent(until)}${subfilter}`;
    encodedUri =
      !!price_min || price_min != constants.MIN_PRICE
        ? encodedUri + "&price_min=" + encodeURIComponent(price_min)
        : encodedUri;
    encodedUri =
      price_max && price_max != constants.MAX_PRICE
        ? encodedUri + "&price_max=" + encodeURIComponent(price_max)
        : encodedUri;
    encodedUri =
      !!selected_features && selected_features.length > 0
        ? encodedUri +
          "&features=" +
          encodeURIComponent(selected_features.map((x) => x.value))
        : encodedUri;
    encodedUri =
      !!selected_extras && selected_extras.length > 0
        ? encodedUri +
          "&extras=" +
          encodeURIComponent(selected_extras.map((x) => x.value))
        : encodedUri;
    encodedUri =
      !!selected_categories && selected_categories.length > 0
        ? encodedUri +
          "&vehicle_category=" +
          encodeURIComponent(selected_categories.map((x) => x.value))
        : encodedUri;
    encodedUri =
      !!selected_transmissions && selected_transmissions.length > 0
        ? encodedUri +
          "&vehicle_transmission=" +
          encodeURIComponent(selected_transmissions.map((x) => x.value))
        : encodedUri;
    const rentals_promise = axios({
      method: "get",
      url: `/portada/search_rentals.json?${encodedUri}`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    });
    Promise.all([rentals_promise])
      .then((values) => {
        const [rentalsData] = values;
        const rentals = (rentalsData.data || [])
          .filter(
            //Filtro de rentals sin locations
            (rental) =>
              Array.isArray(rental.locations) && rental.locations.length > 0
          )
          .map((rental) => {
            //Ahora los rentals no tienen location, para mantener mejor el código
            //dejaré el objeto con el primer resultado  , ya que por lo visto el primero es la ubicación principal
            rental.location = rental.locations[0].location;
            return rental;
          });
        const mapRentals = this.disperse_rentals(rentals);
        let customZoom = false;
        let latLng = null;
        if (mapRentals.length == 0) {
          customZoom = chkAirport ? 12 : 16;
          latLng = {
            lat,
            lng,
          };
        }

        if (!this.willUnmount)
          this.setState({ mapRentals, customZoom, latLng, shouldUpdateMap });
      })
      .catch((error) => {
        this.showMessage({
          showMessage: true,
          messageType: "alert-danger",
          messages: ["Ha ocurrido un error inesperado"],
          messageTitle: "Error",
        });
      })
      .then(() => {
        this.setState((state) => {
          state.ui.isSubmitting = false;
          return { ui: state.ui };
        });
      });
  };

  changeTransmissions = (options) => {
    // this.setState({ selected_transmissions: options }, () => this.handleSubmit());
    this.setState({ selected_transmissions: options });
  };

  changeExtras = (options) => {
    // this.setState({ selected_extras: options }, () => this.handleSubmit());
    this.setState({ selected_extras: options });
  };

  changeFeatures = (options) => {
    // this.setState({ selected_features: options }, () => this.handleSubmit());
    this.setState({ selected_features: options });
  };

  changeCategories = (options) => {
    // this.setState({ selected_categories: options }, () => this.handleSubmit());
    this.setState({ selected_categories: options });
  };

  changeRange = (values) => {
    const price_min = values[0];
    const price_max = values[1];
    // this.setState({ price_min, price_max }, () => this.handleSubmit());
    this.setState({ price_min, price_max });
  };

  updateRange = (values) => {
    const price_min_update = values[0];
    const price_max_update = values[1];
    this.setState({ price_min_update, price_max_update });
  };

  resetFilter = () =>
    this.setState({
      selected_extras: [],
      selected_features: [],
      selected_categories: [],
      selected_transmissions: [],
      price_min: constants.MIN_PRICE,
      price_max: constants.MAX_PRICE,
      // }, () => this.handleSubmit());
    });

  render() {
    dayjs.locale("es");
    const {
      showMap,
      user,
      selected_categories,
      selected_extras,
      selected_features,
      selected_transmissions,
      vehicle_categories,
      vehicle_extras,
      vehicle_features,
      vehicle_transmissions,
      price_min,
      price_max,
      price_max_update,
      price_min_update,
      orderAsc,
      mapRentals,
      form_data,
      address,
      show_filters,
      latLng,
      rental_id,
      ui,
      chkAirport,
      customZoom,
      shouldUpdateMap,
      highlightRentalId,
    } = this.state;

    const { fromDate, untilDate, fromTime, untilTime } = this.state.form_data;
    let rentals = mapRentals
      .filter((x) => x.index === 0)
      .map((rental, i) => {
        rental.vehicle.vehicle_rating = rental.vehicle.vehicle_rating || 0;
        rental.vehicle.trips = rental.vehicle.trips || 0;
        rental.vehicle.associated_users = rental.vehicle.associated_users.map(
          (user) => {
            user.evaluations = user.evaluations || 0;
            user.admin_rating = user.admin_rating || 0;
            return user;
          }
        );
        return rental;
      });

    const empty_message = (is_submitting) => (
      <div className="col">
        <div
          className="fill alert alert-info fade show pr-5"
          style={{ display: "inline-block" }}
        >
          {is_submitting
            ? "Realizando búsqueda..."
            : "No se han encontrado resultados"}
        </div>
      </div>
    );

    let vehicleCards = [];
    vehicleCards = rentals
      .sort((rentalA, rentalB) => {
        if (orderAsc == undefined) {
          const formLat = form_data.lat;
          const formLong = form_data.lon;
          const origin = new google.maps.LatLng(formLat, formLong);
          const pointA = new google.maps.LatLng(
            rentalA.location.latitude,
            rentalA.location.longitude
          );
          const pointB = new google.maps.LatLng(
            rentalB.location.latitude,
            rentalB.location.longitude
          );
          const distanceFromA = google.maps.geometry.spherical.computeDistanceBetween(
            origin,
            pointA
          );
          const distanceFromB = google.maps.geometry.spherical.computeDistanceBetween(
            origin,
            pointB
          );
          return distanceFromA - distanceFromB;
        } else {
          const rentalPriceA = rentalA.pricing.base_price;
          const rentalPriceB = rentalB.pricing.base_price;
          if (orderAsc) {
            return rentalPriceA - rentalPriceB;
          } else {
            return rentalPriceB - rentalPriceA;
          }
        }
      })
      .map((rental, i) => {
        if (showMap)
          return (
            <SearchResultsCard
              rental={rental}
              key={i}
              goToLocation={this.goToLocation}
              link={`/rentals/${rental.id}`}
              mouseEnter={() => this.enterCard(rental.id)}
            />
          );
        else {
          return (
            <div
              className="col mb-3 car-card-container px-1"
              key={i}
              style={{ maxWidth: "32rem" }}
            >
              <VehicleCard rental={rental} link={`/rentals/${rental.id}`} />
            </div>
          );
        }
      });

    const cardsContainer =
      vehicleCards.length > 0 ? (
        // <div style={{ maxHeight: "100vh", overflow: "auto" }}>
        <div
          className={
            !showMap ? "row justify-content-center" : "minicards-container"
          }
          id="vehicleResultsContainer"
          // style={{
          //   maxHeight: this.props.fromPortada ? "460px" : "none",
          //   overflowY: "auto"
          // }}
        >
          {vehicleCards}
        </div>
      ) : null;

    let countries = ["cl"];
    if (
      user &&
      user.city &&
      user.city.country &&
      user.city.country.id != "CL"
    ) {
      countries.push(user.city.country.id.toLowerCase());
    }

    const airportCheck = (
      <div className="custom-control custom-checkbox mt-2">
        <input
          type="checkbox"
          className="custom-control-input"
          id="checkAirport"
          name="checkAirport"
          checked={chkAirport}
          onChange={(e) => this.handleCheckAirport(e.target.checked)}
        />
        <label className="custom-control-label" htmlFor="checkAirport">
          Entrega / Devolución en Aeropuerto
        </label>
      </div>
    );

    const searchInput = (
      <div className="col-lg-3 col-sm-6">
        <div className="form-group">
          <LocationSelector
            countries={countries}
            handleSelectLocation={this.handleSelectLocation}
            placeholder="Dónde quieres recoger tu auto"
            label="Lugar de recogida"
            airportsFirst={chkAirport}
            loadSpinner={this.loadSpinner}
          />
        </div>
      </div>
    );

    const title = <h3 className="my-2">Buscar publicaciones</h3>;

    //SELECT
    const vehicle_features_options = vehicle_features.map((v) => ({
      label: v.a_name,
      value: v.id,
    }));

    const vehicle_extras_options = vehicle_extras.map((v) => ({
      label: v.a_name,
      value: v.id,
    }));

    const vehicle_transmissions_options = vehicle_transmissions.map((v) => ({
      label: v.description,
      value: v.id,
    }));

    const vehicle_categories_options = vehicle_categories.map((v) => ({
      label: v.description,
      value: v.id,
    }));

    const showFilterText = show_filters ? "Menos filtros" : "Más filtros";

    const filtersClass = show_filters ? "show-anc-filters" : "";

    let selectedFilters = [];
    if (price_min != constants.MIN_PRICE || price_max != constants.MAX_PRICE) {
      selectedFilters.push(
        <Fragment key={0}>
          <strong>Rango de precios:</strong>{" "}
          {numeral(price_min).format("$0,0 ")}-{" "}
          {numeral(price_max).format("$0,0")}
          {price_max == constants.MAX_PRICE ? "+" : ""}
        </Fragment>
      );
    }
    if (selected_categories && selected_categories.length > 0) {
      selectedFilters.push(
        <Fragment key={1}>
          <strong>Categorías:</strong>{" "}
          {selected_categories.map((x) => x.label).join(", ")}
        </Fragment>
      );
    }
    if (selected_transmissions && selected_transmissions.length > 0) {
      selectedFilters.push(
        <Fragment key={2}>
          <strong>Transmisiones:</strong>{" "}
          {selected_transmissions.map((x) => x.label).join(", ")}
        </Fragment>
      );
    }
    if (selected_features && selected_features.length > 0) {
      selectedFilters.push(
        <Fragment key={3}>
          <strong>Características:</strong>{" "}
          {selected_features.map((x) => x.label).join(", ")}
        </Fragment>
      );
    }
    if (selected_extras && selected_extras.length > 0) {
      selectedFilters.push(
        <Fragment key={4}>
          <strong>Extras:</strong>{" "}
          {selected_extras.map((x) => x.label).join(", ")}
        </Fragment>
      );
    }
    let containerFilterText =
      !show_filters && selectedFilters.length > 0 ? (
        <div className="row">
          <div className="col">
            <p>{selectedFilters.reduce((prev, curr) => [prev, "; ", curr])}</p>
          </div>
        </div>
      ) : null;
    let searchedLatLong = {
      lat: parseFloat(form_data.lat),
      lng: parseFloat(form_data.lon),
    };
    const spinner = <Spinner loading={ui.isSubmitting} />;
    return (
      <React.Fragment>
        {spinner}

        <div className="card">
          <div className="card-body">
            {title}
            <div className="row">
              {searchInput}
              <div className="col-lg-3 col-sm-6">
                <div className="form-group mb-3">
                  <label>Desde</label>
                  <DateTimePicker
                    changeTime={this.changeTimeFrom}
                    date={fromDate}
                    pickerId="search_from"
                    time={fromTime}
                  />
                </div>
                <ErrorMessage name="date_from">
                  {(msg) => (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="form-group mb-3">
                  <label>Hasta</label>
                  <DateTimePicker
                    changeTime={this.changeTimeUntil}
                    date={untilDate}
                    pickerId="search_until"
                    time={untilTime}
                  />
                </div>
                <ErrorMessage name="date_until">
                  {(msg) => (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              </div>
              <div
                className="col-lg-3 col-sm-6"
                style={{ display: "flex", justifyContent: "start" }}
              >
                <button
                  className="btn-airncar-yellow mr-2"
                  onClick={() => this.handleSubmit(true)}
                >
                  Ir
                </button>
                <div className="mr-2 d-none d-sm-block">
                  <label className="d-block">Mapa</label>
                  <input
                    type="checkbox"
                    id="show_map"
                    name="show_map"
                    checked={showMap}
                    onChange={(e) => this.changeShowMap(e.target.checked)}
                    data-switch="primary"
                  />
                  <label
                    htmlFor="show_map"
                    data-on-label=""
                    data-off-label=""
                    className="mb-0"
                  />
                </div>
                <div className="mr-2">
                  <label className="d-block">+ Filtros</label>
                  <input
                    type="checkbox"
                    id="show_filters"
                    name="show_filters"
                    checked={show_filters}
                    onChange={(e) => this.changeShowFilters(e.target.checked)}
                    data-switch="primary"
                  />
                  <label
                    htmlFor="show_filters"
                    data-on-label=""
                    data-off-label=""
                    className="mb-0"
                  />
                </div>
              </div>
            </div>
            {containerFilterText}
            <div className={"anc-filters " + filtersClass}>
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group mb-3 pb-4 pb-lg-0">
                    <label htmlFor="range-price" className="mb-2">
                      Rango de precios (
                      {numeral(price_min_update).format("$0,0")} -{" "}
                      {numeral(price_max_update).format("$0,0")}
                      {price_max_update == constants.MAX_PRICE ? "+" : ""})
                    </label>
                    <RangeSlider
                      domain={[constants.MIN_PRICE, constants.MAX_PRICE]}
                      step={constants.RANGE_STEPS}
                      defaultValues={[constants.MIN_PRICE, constants.MAX_PRICE]}
                      values={[price_min, price_max]}
                      onChange={this.changeRange}
                      onUpdate={this.updateRange}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="form-group mb-3">
                    <label htmlFor="range-price">Categoría</label>
                    <Select
                      value={selected_categories}
                      options={vehicle_categories_options}
                      clearable={true}
                      isMulti={true}
                      placeholder="Todas las categorías"
                      noOptionsMessage={() => "No existen categorías"}
                      onChange={this.changeCategories}
                      closeMenuOnSelect={false}
                      classNamePrefix="RS-FIX"
                      className="react-select-fix"
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="form-group mb-3">
                    <label htmlFor="range-price">Transmisión</label>
                    <Select
                      value={selected_transmissions}
                      options={vehicle_transmissions_options}
                      clearable={true}
                      isMulti={true}
                      placeholder="Todo tipo"
                      noOptionsMessage={() => "No existen tipos de transmisión"}
                      onChange={this.changeTransmissions}
                      closeMenuOnSelect={false}
                      classNamePrefix="RS-FIX"
                      className="react-select-fix"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div className="form-group mb-3">
                    <label htmlFor="características">Características</label>
                    <Select
                      value={selected_features}
                      options={vehicle_features_options}
                      clearable={true}
                      isMulti={true}
                      placeholder="Todas las características"
                      noOptionsMessage={() => "No existen características"}
                      onChange={this.changeFeatures}
                      closeMenuOnSelect={false}
                      classNamePrefix="RS-FIX"
                      className="react-select-fix"
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="form-group mb-3">
                    <label htmlFor="range-price">Extras</label>
                    <Select
                      value={selected_extras}
                      options={vehicle_extras_options}
                      clearable={true}
                      isMulti={true}
                      placeholder="Todos los extras"
                      noOptionsMessage={() => "No existen extras"}
                      onChange={this.changeExtras}
                      closeMenuOnSelect={false}
                      classNamePrefix="RS-FIX"
                      className="react-select-fix"
                    />
                  </div>
                </div>
                <div
                  className="col-lg-3 col-sm-6"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => this.resetFilter()}
                  >
                    Restablecer filtros
                  </button>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <div className="row">
                <div
                  className={
                    showMap ? "col-sm-12 col-lg-8 search-results" : "col-12"
                  }
                >
                  <div className="row mb-2">
                    <div className="col">
                      <h4>Resultados de búsqueda</h4>
                    </div>
                    <div className="col-auto">
                      <button
                        type="button"
                        onClick={() =>
                          this.setState((state) => {
                            if (state.orderAsc == undefined) {
                              state.orderAsc = true;
                            } else if (state.orderAsc) {
                              state.orderAsc = false;
                            } else {
                              state.orderAsc = undefined;
                            }
                            return { orderAsc: state.orderAsc };
                          })
                        }
                        className={`btn btn-appcar-primary btn-order${
                          orderAsc
                            ? " btn-order-asc"
                            : orderAsc == false
                            ? " btn-order-desc"
                            : ""
                        }`}
                      >
                        {orderAsc
                          ? "Orden por precio ascendente"
                          : orderAsc == false
                          ? "Orden por precio descendente"
                          : "Orden por cercanía"}
                        &nbsp;&nbsp;
                      </button>
                    </div>
                  </div>
                  {rentals.length == 0
                    ? ui.isSubmitting
                      ? empty_message(true)
                      : empty_message(false)
                    : null}
                  {cardsContainer}
                </div>
                <div
                  className="col"
                  style={{ display: `${showMap ? "" : "none"}` }}
                >
                  <div
                    className="sticky-top d-none d-sm-block"
                    style={{ zIndex: "3" }}
                    id="mapContainer"
                  >
                    <Map
                      rentals={mapRentals}
                      customLatLng={latLng}
                      reportChange={this.reportChange}
                      selectedRental={rental_id}
                      countries={countries}
                      searchedLatLong={searchedLatLong}
                      handleSubmit={this.handleSubmit}
                      customZoom={customZoom}
                      shouldUpdate={shouldUpdateMap}
                      link="/rentals/"
                      spinner={spinner}
                      highlightedRental={highlightRentalId}
                      pinClick={this.pinClick}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Searcn;
