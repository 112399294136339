import React from "react";
import TableHeader from "./TableHeader";

const TableHead = ({ columnDefinitions, onOrderClick, orderStatus }) => {
  const direction = orderStatus.direction == "desc" ? "down-bold" : "up-bold";
  const orderField = orderStatus.name;
  const columns = columnDefinitions.map((col, i) => {
    let newDirection = "desc";
    if (col.name == orderStatus.name) {
      newDirection = orderStatus.direction == "desc" ? "asc" : "desc";
    }
    const colNewOrderStatus = {
      name: col.name,
      direction: newDirection
    }
    const ev = col.orderable ? onOrderClick : null;
    const iconClass = `mdi mdi-arrow-${orderStatus.name == col.name ? direction : "up-down-bold-outline"}`
    const icon = col.orderable ? <i className={iconClass} /> : null;
    return <TableHeader headerName={col.name} text={col.label} onOrderClick={ev} icon={icon} key={i} newOrderStatus={colNewOrderStatus}/>
  });
  return (
    <thead
    >
      <tr>
        {columns}
      </tr>
    </thead>
  )
}

export default TableHead;