import React from "react";

const SelfieLoader = ({ addImage, removeImage, imageBase64, start, url = null }) => {
  const actionButton = (
    <button
      className="btn-image-loader"
      onClick={() => {
        if (imageBase64 || url) {
          removeImage();
        } else {
          addImage();
        }
      }}
      type="button"
    >
      <i className={`mdi mdi-image-${imageBase64 || url ? "off" : "plus"}`} />
    </button>
  )
  let backgroundImage = "/SelfiePlaceholder.png";
  if (imageBase64) {
    backgroundImage = start + "," + imageBase64;
  } else if (!!url) backgroundImage = url;
  return (
    <>
      <div
        className="image-container"
        style={{
          backgroundImage: `url("${backgroundImage}")`,
          backgroundSize: imageBase64 || url ? "cover" : "auto"
        }}
      >
        {actionButton}
      </div>
      <h4>Sube tu foto de perfil</h4>
    </>
  );
};

export default SelfieLoader;