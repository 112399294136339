import React, { useEffect } from "react";
import ToolTip, { LoadToolTips } from "../../../common/components/ToolTip";
import iconInfo from "images/Icons/ico-info.png";

const DiscountExplanation = () => {
  useEffect(() => {
    LoadToolTips();
  }, []);
  return (
    <>
      <ToolTip
        title={"Descuento extra"}
        tooltipHTML={"Corresponde a un descuento asociado al descuento del Owner, se aplica sobre los costos operacionales, así el total del arriendo corresponde al arriendo anterior."}
        containerHTML={<img src={iconInfo} width={12} className="ml-1 pb-1" />}
        id={`discount_explanation_ttip`}
        key={`discount_explanation_ttip`}
      />
    </>
  );
};

export default DiscountExplanation;
