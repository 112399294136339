import React from "react";

const ToolTip = ({
  containerHTML,
  title,
  tooltipHTML,
  id,
  placement = "left",
  widthClass = "wider",
  colorClass = "",
}) => (
  <div
    data-bs-toggle="tooltip"
    data-bs-placement={placement}
    data-bs-html="true"
    data-bs-title={`<div class="title">${title}</div><div class="description">${tooltipHTML}</div>`}
    data-bs-template={`<div class="tooltip" role="tooltip"><div class="tooltip-inner ${widthClass} ${colorClass} ${id}"></div></div>`}
    className="d-inline ttip"
  >
    <span onMouseLeave={() => forceClose(id)}>{containerHTML}</span>
  </div>
);

export const LoadToolTips = (options = {}) => {
  [].slice
    .call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    .map((element) => bootstrap.Tooltip.getOrCreateInstance(element, options));
};

const forceClose = (elementId) => {
  const childs = document.getElementsByClassName(elementId);
  Array.prototype.forEach.call(childs, (child) => {
    child.parentNode.classList.remove("show");
  });
};
export default ToolTip;
