import React from "react";
import VehicleData from "../../../vehicles/form/VehicleData";

const ModalVehicleData = ({
  vehicle,
  handleSubmit,
  onCancel,
  handleChange,
  handleChangeObj,
  handleChangeModel,
  brandOptions,
  modelOptions,
  categoriesOptions,
  fuelTypesOptions,
  vehicleTransmissions,
  vehicleFeatures,
  isSubmitting,
  addFeature,
  removeFeature,
}) => (
  <div
    className="modal fade"
    id="modal-vehicle-data"
    aria-hidden="true"
    aria-labelledby="modal-vehicle-data-label"
    tabIndex={-1}
  >
    <div className="modal-dialog modal-dialog-centered modal-appcar">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="modal-vehicle-data-label">
            Datos del auto
          </h5>
        </div>
        <div className="modal-body modal-body-less-padding">
          <div className="modal-dates-body">
            <VehicleData
              data={vehicle}
              handleChange={handleChange}
              handleChangeObj={handleChangeObj}
              handleChangeModel={handleChangeModel}
              brands={brandOptions}
              models={modelOptions}
              categories={categoriesOptions}
              fuelTypes={fuelTypesOptions}
              transmissions={vehicleTransmissions}
              addFeature={addFeature}
              removeFeature={removeFeature}
              features={vehicleFeatures}
              isSubmitting={isSubmitting}
              handleSubmit={handleSubmit}
              fromModal={true}
            />
          </div>
          <div className="row justify-content-center mt-3 mb-3">
            <div className="col-auto">
              <button
                type="button"
                className="btn modal-btn"
                onClick={onCancel}
              >
                Cancelar
              </button>
            </div>
            <div className="col-auto">
              <button
                type="button"
                className="btn modal-btn"
                onClick={handleSubmit}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ModalVehicleData;
