import React, { useState } from "react";
import { useEffect } from "react";

const RentalPriceInput = ({
  value,
  handleOnChange,
  label,
  labelClass,
  wrapperClass,
  inputId,
  handleOnBlur,
  inputClass,
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const labelTag = label ? (
    <label className={labelClass || ""}>{label}</label>
  ) : null;
  const colorClass = isDisabled ? "disabled-icon" : "text-appcar-primary";
  useEffect(() => {
    if (!isDisabled) document.getElementById(inputId).focus();
  }, [isDisabled]);
  return (
    <>
      {labelTag}
      <div
        className={`${wrapperClass} price-input ${
          isDisabled ? "disabled" : ""
        }`}
      >
        <input
          id={inputId}
          name={inputId}
          onBlur={(e) => handleOnBlur(e)}
          onChange={(e) => handleOnChange(e)}
          disabled={isDisabled}
          className={inputClass}
          value={value}
        />
        <i
          className={`mdi mdi-square-edit-outline cursor-pointer ${colorClass}`}
          onClick={() => {
            setIsDisabled(false);
            document.getElementById(inputId).focus();
          }}
        />
      </div>
    </>
  );
};

export default RentalPriceInput;
