import React from "react";

const GuaranteeCard = ({ principalClassName, id = "guarantee_card" }) => (
  <div id={id} className={principalClassName}>
    <h5>Garantía</h5>
    <p>
      Dos días antes del inicio del arriendo se tomará una garantía de{" "}
      <b>$100.000</b> que se anula automáticamente dos días después del término
      del arriendo, siempre que no haya inconvenientes con el estado de
      devolución del auto.
    </p>
  </div>
);
export default GuaranteeCard;
