import React, { Component } from "react";
import axios from "axios";
import { AxiosApplicationInterceptor } from "../../axios_interceptor";
import Spinner from "../../Spinner";
import { constants, OptionsMercadoPagoIdType } from "../../constants";
import SelectWithRadio from "../select/SelectWithRadio";
axios.interceptors.response.use(undefined, AxiosApplicationInterceptor);

class MercadoPagoForm extends Component {
  state = {
    data: {},
    id_number: this.props.user.id_number,
    idType: OptionsMercadoPagoIdType.find((x) => x.value === constants.RUT),
    error: null,
    isSubmitting: false,
  };

  onFormMounted = (error) => {
    this.setState(
      { isSubmitting: false },
      () => {
        if (error) {
          console.warn("Form mounted handling error: ", error);
          return this.setState({ error });
        }
        console.log("Form mounted");
      },
      () => {
        selectTags = document.getElementById("form-checkout__installments");
        if (selectTags != null) {
          selectTags.selectedIndex = 0;
        }
      }
    );
  };

  onFormSubmit = (e, cardForm) => {
    this.setState({ isSubmitting: true });
    e.preventDefault();
    const csrftoken = document.querySelector("[name=csrf-token]");
    if (csrftoken) {
      axios.defaults.headers.common["X-CSRF-TOKEN"] = csrftoken.content;
    }
    let {
      paymentMethodId: payment_method_id,
      issuerId: issuer_id,
      cardholderEmail: email,
      amount,
      token,
      installments,
      identificationNumber,
      identificationType,
    } = cardForm.getCardFormData();

    if (payment_method_id == "debvisa") {
      this.showMessage("primary", "Alerta", [
        "No es posible registrar una tarjeta VISA débito",
      ]);
      this.setState({ isSubmitting: false });
      return;
    }

    identificationNumber = identificationNumber.replace(/\.|\-/, "");

    const kh = this.props.kh;

    axios({
      method: "POST",
      url: this.props.actionUrl,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": kh,
      },
      data: JSON.stringify({
        token,
        issuer_id,
        payment_method_id,
        transaction_amount: Number(amount),
        installments: Number(installments),
        description: "Arriendo Appcar",
        payer: {
          email,
          identification: {
            type: identificationType,
            number: identificationNumber,
          },
        },
      }),
    })
      .then((response) => {
        if (!response.data.success) {
          this.setState({ error: response.data.message });
        } else {
          if (this.props.type === "url") {
            this.setState(
              { isSubmitting: true },
              () => (window.location.href = this.props.resultUrl)
            );
          } else if (this.props.type === "func") {
            this.props.callback();
          }
        }
      })
      .finally(() => this.setState({ isSubmitting: false }));
  };

  onCardTokenReceived = (error, token) => {
    if (error) {
      console.warn("Token handling error: ", error);
      let err = undefined;
      switch (typeof error) {
        case "string":
          err = error;
          break;
        case "object":
          if (Array.isArray(error))
            err = error[0].code + " - " + error[0].message;
          else err = error.code + " - " + error.message;
          break;
      }
      return this.setState({ error: err });
    }
    console.log("Token available");
  };

  onFetching = (resource) => {
    console.log("Fetching resource: ", resource);

    // const progressBar = document.querySelector(".progress-bar");
    // progressBar.removeAttribute("value");

    // return () => {
    //   progressBar.setAttribute("value", "0");
    // }
  };

  componentDidMount() {
    this.setState({ isSubmitting: true });
    const mp = new MercadoPago(this.props.mpPublicKey, {
      locale: "es-CL",
    });
    const cardForm = mp.cardForm({
      amount: "100",
      autoMount: true,
      form: {
        id: "form-checkout",
        cardholderName: {
          id: "form-checkout__cardholderName",
          placeholder: "Titular de la tarjeta",
        },
        cardholderEmail: {
          id: "form-checkout__cardholderEmail",
          placeholder: "E-mail",
        },
        cardNumber: {
          id: "form-checkout__cardNumber",
          placeholder: "Número de la tarjeta",
        },
        cardExpirationMonth: {
          id: "form-checkout__cardExpirationMonth",
          placeholder: "MM",
        },
        cardExpirationYear: {
          id: "form-checkout__cardExpirationYear",
          placeholder: "YY",
        },
        securityCode: {
          id: "form-checkout__securityCode",
          placeholder: "Código de seguridad",
        },
        installments: {
          id: "form-checkout__installments",
          placeholder: "Cuotas",
        },
        identificationType: {
          id: "form-checkout__identificationType",
          placeholder: "Tipo de documento",
        },
        identificationNumber: {
          id: "form-checkout__identificationNumber",
          placeholder: "Número de documento",
        },
        issuer: {
          id: "form-checkout__issuer",
          placeholder: "Banco emisor de tarjeta",
        },
      },
      callbacks: {
        onFormMounted: this.onFormMounted,
        onSubmit: (event) => this.onFormSubmit(event, cardForm),
        onCardTokenReceived: this.onCardTokenReceived,
        onFetching: this.onFetching,
      },
    });
  }

  maskCardInput = (value) => {
    if (value && value.length > 3) {
      const regex = /\d{4}/g;
      const res = value
        .replace(/ /g, "")
        .replace(regex, (maths) => (maths === 12 ? maths : maths + " "))
        .trim();
      let input = document.getElementById("form-checkout__cardNumber");
      input.value = res;
    }
  };

  render() {
    const displayAlert = this.state.error ? "d-block" : "d-none";
    const { id_number, idType } = this.state;
    return (
      <>
        <div className={`alert alert-danger ${displayAlert}`}>
          {this.state.error}
        </div>
        <Spinner loading={this.state.isSubmitting} />

        <form id="form-checkout">
          <h5>Mercado Pago</h5>
          <div className="form-group">
            <label className="label-inside" htmlFor="form-checkout__cardNumber">
              Número de tarjeta
            </label>
            <input
              required
              type="text"
              name="cardNumber"
              id="form-checkout__cardNumber"
              className="form-control appcar-input"
              pattern="[0-9 ]+"
              onChange={(e) => this.maskCardInput(e.target.value)}
              title="Sólo números y espacios"
              maxLength="19"
              tabIndex="1000000"
            />
          </div>
          <div className="expiration-date-input-group">
            <div className="form-group w-100">
              <label
                htmlFor="form-checkout__cardExpirationMonth"
                className="label-inside"
              >
                Caducidad
              </label>
              <div className="expiration-date-input w-100">
                <input
                  required
                  type="text"
                  name="cardExpirationMonth"
                  id="form-checkout__cardExpirationMonth"
                  maxLength="2"
                  tabIndex="1000001"
                  className="month w-50"
                  onKeyPress={(e) => {
                    if (
                      isNaN(parseFloat(e.key.trim())) ||
                      !isFinite(e.key.trim())
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
                <span className="text-appcar-primary">/</span>
                <input
                  required
                  type="text"
                  name="cardExpirationYear"
                  id="form-checkout__cardExpirationYear"
                  maxLength="2"
                  tabIndex="1000002"
                  className="year w-50"
                  onKeyPress={(e) => {
                    if (
                      isNaN(parseFloat(e.key.trim())) ||
                      !isFinite(e.key.trim())
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label
              htmlFor="form-checkout__securityCode"
              className="label-inside"
            >
              {" "}
              CVV{" "}
            </label>
            <input
              required
              type="text"
              name="securityCode"
              id="form-checkout__securityCode"
              className="form-control appcar-input ccv"
              maxLength="3"
              tabIndex="1000003"
            />
          </div>
          <div className="form-group">
            <label
              htmlFor="form-checkout__cardholderName"
              className="label-inside"
            >
              Nombre en la tarjeta
            </label>
            <input
              required
              type="text"
              name="cardholderName"
              id="form-checkout__cardholderName"
              defaultValue={`${this.props.user.name} ${this.props.user.last_name}`}
              className="form-control appcar-input"
              tabIndex="1000004"
            />
          </div>
          <div className="form-group">
            <label
              htmlFor="form-checkout__cardholderEmail"
              className="label-inside"
            >
              E-mail
            </label>
            <input
              required
              type="email"
              name="cardholderEmail"
              id="form-checkout__cardholderEmail"
              defaultValue={this.props.user.email}
              className="form-control appcar-input"
              tabIndex="1000005"
            />
          </div>
          <div className="form-group">
            <label htmlFor="form-checkout__issuer" className="label-inside">
              Tipo tarjeta
            </label>
            <select
              name="issuer"
              id="form-checkout__issuer"
              className="form-control appcar-input"
              tabIndex="1000006"
              aria-readonly={true}
              readOnly
              onClick={() => null}
            ></select>
          </div>
          <div className="form-group">
            <label
              htmlFor="form-checkout__identificationType"
              className="label-inside"
            >
              Tipo de documento
            </label>
            <SelectWithRadio
              value={idType}
              options={OptionsMercadoPagoIdType}
              placeholder="Tipo de documento"
              noOptionsMessage={() => "No hay tipos de documento"}
              onChange={(opt) => this.setState({ idType: opt })}
              classNamePrefix="RS-FIX"
              className="react-select-fix appcar-input"
              hideSelectedOptions={false}
              backspaceRemovesValue={false}
              isSearchable={false}
              isClearable={false}
              tabIndex="1000007"
            />
            <input
              type="hidden"
              name="identificationType"
              id="form-checkout__identificationType"
              value={idType?.value}
            />
          </div>
          <div className="form-group">
            <label className="label-inside">Número de {" "}{(idType?.value === constants.RUT ? "RUT" : "documento")}</label>
            <input
              required
              type="text"
              value={id_number}
              className="form-control appcar-input"
              onChange={(e) => this.setState({ id_number: e.target.value })}
              placeholder="Número de documento"
            />
            <input
              type="hidden"
              name="identificationNumber"
              id="form-checkout__identificationNumber"
              value={id_number.replace(/\.|\-/, "")}
            />
          </div>
          <div className="form-group d-none">
            <select
              name="installments"
              id="form-checkout__installments"
              className="form-control appcar-input"
              tabIndex="1000009"
            ></select>
          </div>
          <div className="text-center my-2">
            <button
              type="submit"
              className="btn btn-appcar-primary"
              id="form-checkout__submit"
            >
              Agregar tarjeta
            </button>
          </div>
        </form>
      </>
    );
  }
}

export default MercadoPagoForm;
