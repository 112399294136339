import React, { Component, Fragment } from "react";
import axios from "axios";
import DocumentLoader from "../../../common/components/image_loader/DocumentLoader";
import Alert from "../../../common/components/alert/Alert";
import Spinner from "../../../common/Spinner";
import { DefaultImageObj, generateFilename } from "../../../common/constants";
import { clone } from "lodash";
import photoPlaceholder from "images/Fillers/camera.svg";
import { AxiosBackofficeInterceptor } from "../../../common/axios_interceptor";
axios.interceptors.response.use(undefined, AxiosBackofficeInterceptor);

export class UpdateDocuments extends Component {
  state = {
    selfiePicture: clone(DefaultImageObj),
    frontalDocumentPicture: clone(DefaultImageObj),
    backDocumentPicture: clone(DefaultImageObj),
    driverLicensePicture: clone(DefaultImageObj),
    isSubmitting: false,
    objectUploading: "",
    alert: {
      showMessage: false,
      messageType: undefined,
      messages: [],
      messageTitle: undefined,
    },
  };

  componentDidMount() {
    let {
      selfiePicture,
      frontalDocumentPicture,
      backDocumentPicture,
      driverLicensePicture,
    } = this.state;
    const user = this.props.user;
    selfiePicture.url = user.selfie || "";
    frontalDocumentPicture.url =
      Array.isArray(user.document_pictures) &&
      user.document_pictures.length > 0 &&
      user.document_pictures[0];
    backDocumentPicture.url =
      Array.isArray(user.document_pictures) &&
      user.document_pictures.length > 0 &&
      user.document_pictures[1];
    driverLicensePicture.url =
      Array.isArray(user.driver_license) &&
      user.driver_license.length > 0 &&
      user.driver_license[0];
    this.setState({
      selfiePicture,
      frontalDocumentPicture,
      backDocumentPicture,
      driverLicensePicture,
    });
  }

  openFileDialog = (objectUploading) =>
    this.setState({ objectUploading }, () =>
      document.getElementById(this.props.fileInputId).click()
    );

  uploadPicture = (e) => {
    let file = e.target.files;
    if (file.length > 0) {
      const filename = generateFilename(file[0].name);
      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onloadend = function(e) {
        var base64result = reader.result;
        this.addPicture(base64result, filename);
      }.bind(this);
    }
  };

  addPicture = (picture, filename) => {
    const file = picture.split(",");
    const start = file[0];
    const base64 = file[1];
    const mime = picture.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    const mimeType = mime && mime.length ? mime[1] : "";
    const object = this.state.objectUploading;
    filename = encodeURI(filename);
    this.setState((state) => {
      state[object].filename = filename;
      state[object].start = start;
      state[object].image_base_64 = base64;
      state[object].mime_type = mimeType;
      state[object].url = "";
      return { [object]: state[object] };
    });
  };

  removePicture = (object) =>
    this.setState((state) => {
      state[object] = clone(DefaultImageObj);
      return { [object]: state[object] };
    });

  showMessage = (messageType, messageTitle, messages) =>
    this.setState(
      (state) => {
        state.alert.showMessage = true;
        state.alert.messageTitle = messageTitle;
        state.alert.messages = messages;
        state.alert.messageType = messageType;
        return { alert: state.alert };
      },
      () => setTimeout(this.handleAlertClose, 3000)
    );

  handleAlertClose = () =>
    this.setState((state) => {
      state.alert.showMessage = false;
      return { alert: state.alert };
    });

  handleSubmit = () => {
    this.setState({ isSubmitting: true });
    let user = this.props.user;
    const {
      selfiePicture,
      frontalDocumentPicture,
      backDocumentPicture,
      driverLicensePicture,
    } = this.state;
    let shouldCloseSpinner = true;
    user.selfie = selfiePicture;
    user.document_pictures = [frontalDocumentPicture, backDocumentPicture];
    user.driver_license = [driverLicensePicture];
    axios({
      method: "PATCH",
      url: `/backoffice/owners/${user.id}/update_documents.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: { user },
    })
      .then((response) => {
        if (response.data.success) {
          shouldCloseSpinner = false;
          window.location.assign(`/backoffice/owners/${user.id}?success=1`);
        } else {
          this.showMessage("primary", "Alerta", [
            "Ha ocurrido un error al cargar la imagen",
          ]);
        }
      })
      .catch((error) =>
        this.showMessage("primary", "Error", [
          "Ha ocurrido un error inesperado",
        ])
      )
      .then(() => {
        if (shouldCloseSpinner) this.setState({ isSubmitting: false });
      });
  };

  render() {
    const {
      alert,
      isSubmitting,
      selfiePicture,
      frontalDocumentPicture,
      backDocumentPicture,
      driverLicensePicture,
    } = this.state;

    const { messageTitle, messageType, messages, showMessage } = alert;

    return (
      <Fragment>
        <div className="edit-box">
          <Alert
            alertClass={messageType}
            isShowing={showMessage}
            messages={messages}
            title={messageTitle}
            onClose={this.handleAlertClose}
          />
          <Spinner loading={isSubmitting} />

          <div className="row justify-content-center">
            <div className="col-auto">
              <DocumentLoader
                addImage={this.openFileDialog}
                removeImage={this.removePicture}
                imageBase64={frontalDocumentPicture.image_base_64}
                start={frontalDocumentPicture.start}
                url={frontalDocumentPicture.url}
                title="Documento de identificación (Frontal)"
                backgroundPlaceHolder="/IDFront.png"
                objectName="frontalDocumentPicture"
                replaceImage={this.openFileDialog}
                style={{ width: 333 }}
              />
            </div>
            <div className="col-auto">
              <DocumentLoader
                addImage={this.openFileDialog}
                removeImage={this.removePicture}
                imageBase64={backDocumentPicture.image_base_64}
                start={backDocumentPicture.start}
                url={backDocumentPicture.url}
                title="Documento de identificación (Trasera)"
                backgroundPlaceHolder="/IDBack.png"
                objectName="backDocumentPicture"
                replaceImage={this.openFileDialog}
                style={{ width: 333 }}
              />
            </div>
            <div className="col-auto">
              <DocumentLoader
                addImage={this.openFileDialog}
                removeImage={this.removePicture}
                imageBase64={driverLicensePicture.image_base_64}
                start={driverLicensePicture.start}
                url={driverLicensePicture.url}
                title="Licencia de conducir (Frontal)"
                backgroundPlaceHolder="/DriverLicensePlaceholder.png"
                objectName="driverLicensePicture"
                replaceImage={this.openFileDialog}
                style={{ width: 333 }}
              />
            </div>
            <div className="col-auto">
              <DocumentLoader
                addImage={this.openFileDialog}
                removeImage={this.removePicture}
                imageBase64={selfiePicture.image_base_64}
                start={selfiePicture.start}
                url={selfiePicture.url}
                title="Selfie de usuario con documento de identidad"
                backgroundPlaceHolder={photoPlaceholder}
                objectName="selfiePicture"
                replaceImage={this.openFileDialog}
                style={{ width: 333 }}
              />
            </div>
          </div>
          <input
            type="file"
            id={this.props.fileInputId}
            className="d-none"
            onChange={(e) => this.uploadPicture(e)}
            accept="image/jpeg, image/png, image/jpg"
            onClick={(e) => {
              e.target.value = null;
            }}
          />
          <div className="row justify-content-around mt-3">
            <div className="col-auto">
              <button
                type="button"
                className="btn btn-checklist-pictures"
                disabled={isSubmitting}
                onClick={this.handleSubmit}
              >
                Guardar y continuar
              </button>
            </div>
            <div className="col-auto">
              <button
                type="button"
                className="btn btn-checklist-pictures"
                data-dismiss="modal"
                data-bs-dismiss="modal"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default UpdateDocuments;
