import xorBy from "lodash/xorBy";
import React, { Component, Fragment } from "react";
import RangeSlider from "../../../common/components/price_range/RangeSlider";
import {
  constants,
  numeral,
  OptionsSeats,
  OptionsDistance,
  OptionsOrder,
} from "../../../common/constants";
import SelectWithCheckbox from "../../../common/components/select/SelectWithCheckbox";
import SelectWithRadio from "../../../common/components/select/SelectWithRadio";
import iconInfo from "images/Icons/ico-info.png";
import ToolTip, { LoadToolTips } from "../../../common/components/ToolTip";

class FiltersMobile extends Component {
  componentDidUpdate() {
    LoadToolTips();
  }

  componentDidMount() {
    LoadToolTips();
  }
  handleChangeToggler = (name, option) => {
    const selectedOption = this.props[name];
    if (
      selectedOption &&
      selectedOption.value &&
      option.value === selectedOption.value
    ) {
      this.props.handleChange(name, null);
    } else {
      this.props.handleChange(name, option);
    }
  };

  handleChangeMulti = (name, option) => {
    let selectedOptions = this.props[name];
    if (!!selectedOptions) {
      selectedOptions = xorBy(selectedOptions, [option], "value");
    } else {
      selectedOptions = [option];
    }
    this.props.handleChange(name, selectedOptions);
  };
  render() {
    const {
      updateRange,
      changeRange,
      minPrice,
      minPriceUpdate,
      maxPrice,
      maxPriceUpdate,
      selectedExtras,
      selectedFeatures,
      selectedCategories,
      selectedTransmissions,
      selectedSeats,
      selectedKm,
      optionsExtras,
      optionsFeatures,
      optionsCategories,
      optionsTransmissions,
      handleChange,
      resetFilters,
      selectedOrder,
      showMobileMap,
    } = this.props;
    const transmissionContainer = optionsTransmissions
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
      .map((x, i) => (
        <div className="col-6 my-2" key={"mobile_transmission_" + i}>
          <button
            type="button"
            onClick={() => this.handleChangeToggler("selectedTransmissions", x)}
            className={`btn btn-appcar-${
              selectedTransmissions && selectedTransmissions.value == x.value
                ? "secondary"
                : "white"
            } w-100 btn-filters fs-15`}
          >
            {x.label}
          </button>
        </div>
      ));
    const kmsContainer = OptionsDistance.map((x, i) => (
      <div className="col-6 my-2" key={"mobile_distance_" + i}>
        <button
          type="button"
          onClick={() => this.handleChangeToggler("selectedKm", x)}
          className={`btn btn-appcar-${
            selectedKm && selectedKm.value == x.value ? "secondary" : "white"
          } w-100 btn-filters fs-15`}
        >
          {x.label}
        </button>
      </div>
    ));
    const featuresContainer = optionsFeatures
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
      .map((x, i) => (
        <div className="col-6 my-2" key={"mobile_feature_" + i}>
          <button
            type="button"
            onClick={() => this.handleChangeMulti("selectedFeatures", x)}
            className={`btn btn-appcar-${
              selectedFeatures &&
              selectedFeatures.find((y) => y.value === x.value)
                ? "secondary"
                : "white"
            } w-100 btn-filters fs-15`}
          >
            {x.label}
          </button>
        </div>
      ));
    const extrasContainer = optionsExtras
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
      .map((x, i) => (
        <div className="col-6 my-2" key={"mobile_extra_" + i}>
          <button
            type="button"
            onClick={() => this.handleChangeMulti("selectedExtras", x)}
            className={`btn btn-appcar-${
              selectedExtras && selectedExtras.find((y) => y.value === x.value)
                ? "secondary"
                : "white"
            } w-100 btn-filters fs-15`}
          >
            {x.label}
          </button>
        </div>
      ));
    const orderedCategories = optionsCategories.sort((a, b) =>
      a.label > b.label ? 1 : b.label > a.label ? -1 : 0
    );

    const orderComponent = !showMobileMap ? (
      <div className="my-3">
        <label className="fs-15 fw-800 text-appcar-primary mb-2">
          Ordenar por
        </label>
        <SelectWithRadio
          value={selectedOrder}
          options={OptionsOrder}
          isClearable={true}
          placeholder="Selecciona"
          onChange={(option) => handleChange("selectedOrder", option)}
          closeMenuOnSelect={true}
          classNamePrefix="RS-FIX"
          className="react-select-fix"
          hideSelectedOptions={false}
          backspaceRemovesValue={false}
          isSearchable={false}
        />
      </div>
    ) : null;
    const firstRowClassName = showMobileMap ? "my-3" : "my-4";
    return (
      <div
        className="modal fade"
        id="filtersModal"
        aria-hidden="true"
        aria-labelledby="filtersModalLabel"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-appcar modal-filters">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-m-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                x
              </button>
              <h5 className="modal-title" id="filtersModalLabel">
                Filtros
              </h5>
              <span
                className="clear-button"
                onClick={() => {
                  resetFilters();
                  this.forceUpdate();
                }}
              >
                LIMPIAR
                <br />
                TODO
              </span>
            </div>
            <div className="modal-body pt-0">
              {orderComponent}
              <div className={firstRowClassName}>
                <label className="fs-15 fw-800 text-appcar-primary">
                  Precio diario
                </label>
                <div className="price-range-filter">
                  <div className="label-price">
                    <span>{numeral(minPriceUpdate).format("$0,0")}</span>
                    <span>{numeral(maxPriceUpdate).format("$0,0")}</span>
                  </div>
                  <RangeSlider
                    domain={[constants.MIN_PRICE, constants.MAX_PRICE]}
                    step={constants.RANGE_STEPS}
                    defaultValues={[constants.MIN_PRICE, constants.MAX_PRICE]}
                    values={[minPrice, maxPrice]}
                    onChange={changeRange}
                    onUpdate={updateRange}
                  />
                </div>
              </div>
              <div className="my-4">
                <label className="fs-15 fw-800 text-appcar-primary my-2">
                  Categoría
                </label>
                <SelectWithCheckbox
                  value={selectedCategories}
                  isMulti
                  options={orderedCategories}
                  isClearable={true}
                  placeholder="Selecciona"
                  onChange={(option) =>
                    handleChange("selectedCategories", option)
                  }
                  closeMenuOnSelect={false}
                  classNamePrefix="RS-FIX"
                  className="react-select-fix"
                  hideSelectedOptions={false}
                  backspaceRemovesValue={false}
                  isSearchable={false}
                />
              </div>
              <div className="my-4">
                <label className="fs-15 fw-800 text-appcar-primary">
                  Transmisión
                </label>
                <div className="row">{transmissionContainer}</div>
              </div>
              <div className="my-4">
                <label className="fs-15 fw-800 text-appcar-primary mb-2">
                  Cantidad de asientos
                </label>
                <SelectWithRadio
                  value={selectedSeats}
                  options={OptionsSeats}
                  isClearable={true}
                  placeholder="Todos"
                  onChange={(option) => handleChange("selectedSeats", option)}
                  closeMenuOnSelect={true}
                  classNamePrefix="RS-FIX"
                  className="react-select-fix"
                  hideSelectedOptions={false}
                  backspaceRemovesValue={false}
                  isSearchable={false}
                />
              </div>
              <div className="my-4">
                <div className="row">
                  <div className="col">
                    <label className="fs-15 fw-800 text-appcar-primary">
                      Kilometraje diario
                    </label>
                  </div>
                  <div className="col-auto">
                    <ToolTip
                      title="Kilómetros diario"
                      tooltipHTML="Corresponde a la cantidad máxima de kilómetros que puedes recorrer diariamente. Si superas el kilometraje máximo diario, se puede establecer una multa."
                      containerHTML={
                        <img src={iconInfo} width={18} className="ml-1" />
                      }
                      id="ttip_rental_max_km"
                    />
                  </div>
                </div>
                <div className="row">{kmsContainer}</div>
              </div>
              <div className="my-4">
                <label className="fs-15 fw-800 text-appcar-primary">
                  Características
                </label>
                <div className="row">{featuresContainer}</div>
              </div>
              <div className="my-4">
                <label className="fs-15 fw-800 text-appcar-primary">
                  Extras
                </label>
                <div className="row">{extrasContainer}</div>
              </div>
              <button
                className="btn btn-appcar-primary btn-filters-show-results"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Ver resultados
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FiltersMobile;
