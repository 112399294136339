import Axios from "axios";
import React, { useState, useEffect } from "react";
import Alert from "../../common/components/alert/Alert";
import Spinner from "../../common/Spinner";

const HiddenCheckbox = ({ postId, hiddenStatus }) => {
  const [hidden, setHidden] = useState(hiddenStatus);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    showMessage: false,
    messageType: undefined,
    messages: [],
    messageTitle: undefined,
  });

  const showMessage = (messageType, messageTitle, messages) => {
    setAlert((prev) => {
      prev.showMessage = true;
      prev.messageType = messageType;
      prev.messageTitle = messageTitle;
      prev.messages = messages;
      return { ...prev };
    });
  };

  const closeAlert = () =>
    setAlert((prev) => {
      prev.showMessage = false;
      return { ...prev };
    });

  useEffect(() => {
    if (alert.showMessage) var timeoutAlert = setTimeout(closeAlert, 3000);
    return () => clearTimeout(timeoutAlert);
  }, [alert]);

  const handleChangeHidden = (checked) => {
    setLoading(true);
    Axios({
      method: "POST",
      url: `/backoffice/posts/${postId}/set_hidden.json`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data: { hidden: checked },
    })
      .then((response) => {
        if (response.data.success) {
          setHidden(checked);
          showMessage("primary", "Éxito", response.data.messages);
        } else {
          showMessage(
            "primary",
            "Alerta",
            response.data.messages || ["Ha ocurrido un error inesperado"]
          );
        }
      })
      .catch((error) => {
        console.log(error);
        showMessage("primary", "Error", ["Ha ocurrido un error inesperado"]);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Alert
        alertClass={alert.messageType}
        isShowing={alert.showMessage}
        messages={alert.messages}
        title={alert.messageTitle}
        onClose={closeAlert}
        key={postId}
      />
      <Spinner loading={loading} />
      <div className="form-group">
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="post_hidden"
            name="hidden"
            checked={hidden}
            onChange={(e) => handleChangeHidden(e.target.checked)}
          />
        </div>
      </div>
    </>
  );
};

export default HiddenCheckbox;
